import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { Button, Heading, Icon, TextButton, Tooltip } from "@maistro/components";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Banner from "components/Layout/Banner";
import SecondaryNav from "components/Layout/SecondaryNav/SecondaryNav";
import StepperNav from "components/Layout/StepperNav/StepperNav";
import TitleCta from "components/Layout/TitleCta";
import useLaunchBarStyles from "components/Layout/hooks/useLaunchBarStyles";
import SearchBar from "components/SearchBar/SearchBar";
import { ICommonProps } from "components/shared";
import useReduxSelector from "hooks/useReduxSelector";
import useScreenLayout from "hooks/useScreenLayout";
import ReactGA from "react-ga4";
import { buildPath } from "routes/helpers/RoutesHelper";
import stringService from "services/stringService";
import { IBackLayoutState } from "store/layoutSlice";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

interface ILaunchBarProps extends ICommonProps {}

const LaunchBar: React.FC<ILaunchBarProps> = (props) => {
    const classes = useLaunchBarStyles();

    const { layout } = useReduxSelector((state) => ({
        layout: state.layout,
    }));
    const { stepperNavigationItems, stepperChecklistItems } = useReduxSelector(
        (state) => state.navigationState.stepperNavigation,
    );

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { onMobile } = useScreenLayout();

    const renderBackToLink = (backTo: IBackLayoutState) => {
        const goBack = () => navigate(buildPath(backTo.route, backTo.route.params));

        return (
            <>
                <Icon className="hidden-desktop" icon={faArrowLeft} onClick={goBack} testid="back-arrow-link" />
                <TextButton
                    className="hidden-mobile"
                    label={
                        backTo.route.translationKey
                            ? t("common.backTo", {
                                  options: {
                                      page: t(`routes.${backTo.route.translationKey}`),
                                  },
                              })
                            : t("common.back")
                    }
                    onClick={() => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: "Go Back - ".concat(t(`routes.${backTo.route.translationKey}`)),
                        });
                        goBack();
                    }}
                    chevron
                    testid="back-link"
                />
            </>
        );
    };

    const renderBanner = () => {
        if (!layout.banner) return null;

        return (
            <Banner
                className={classes.banner}
                text={!onMobile ? layout.banner.desktopText : layout.banner.text}
                onClick={layout.banner.onClick}
                testid="banner"
            />
        );
    };

    const renderActions = () => {
        return (
            <div
                className={classNames(classes.actions, {
                    noContent: !layout.back && !layout.ctas,
                    onlyCTA: !layout.back && layout.ctas,
                })}
            >
                {layout.back && renderBackToLink(layout.back)}
                {layout.ctas && layout.ctas.map((cta) => <Button key={cta.label} className="hidden-mobile" {...cta} />)}
            </div>
        );
    };

    return (
        <div className={classes.launchBar} data-component="LaunchBar" data-testid={props.testid}>
            {!onMobile && renderBanner()}
            {onMobile && renderActions()}
            {layout.secondaryNavItems && (
                <SecondaryNav
                    className={classes.secondaryNav}
                    navItems={layout.secondaryNavItems}
                    testid="secondary-nav"
                />
            )}
            <div className={classes.header}>
                {onMobile && renderBanner()}
                {!onMobile && renderActions()}
                <div className={classes.title}>
                    {layout.pageTitle && (
                        <Heading className={classes.titleNav} variant="h1" testid="page-title">
                            <>
                                {!layout.tooltip && layout.pageTitle}
                                {layout.tooltip && (
                                    <Tooltip
                                        content={layout.tooltip}
                                        start
                                        iconClassName={layout.tooltipClassName ?? undefined}
                                        testid="page-title-tooltip"
                                    >
                                        {layout.pageTitle}
                                    </Tooltip>
                                )}
                            </>
                        </Heading>
                    )}
                    <div className={classes.titleNavContainer}>
                        {layout.titleCtas &&
                            layout.titleCtas.map((cta) => (
                                <TitleCta
                                    key={stringService.toKebabCase(cta.type.toString())}
                                    type={cta.type}
                                    onClick={cta.onClick}
                                />
                            ))}
                        {onMobile && (stepperNavigationItems || stepperChecklistItems) && <StepperNav />}
                    </div>
                </div>
                {layout.searchBar && (
                    <div className={classes.searchBar}>
                        <SearchBar {...layout.searchBar} testid="search-bar" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default LaunchBar;
