import { Copy } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import NotInterestedWithdrawButtons from "features/project/shared/projectResponse/NotInterestedWithdrawButtons";
import SupplierResponseForm from "features/project/supplierQuoteResponse/SupplierResponseForm";
import { IProjectResponseInformation } from "features/project/types";
import useReduxSelector from "hooks/useReduxSelector";
import dateTimeService from "services/dateTimeService";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";
import CurrencyType from "types/enums/CurrencyType";
import SupplierSubmissionStatus from "types/enums/supplierSubmissions/SupplierSubmissionStatus";

const useStyles = createUseStyles((theme: ITheme) => ({
    copy: {
        marginBottom: theme.spacing.large,
    },
    buttonControl: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: theme.spacing.medium,
    },
}));

interface ISupplierResponseDisplayProps extends ICommonProps {
    supplierSubmission: IProjectResponseInformation;
    projectFiles: FileDto[];
    setProjectFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    submitForm: (supplierPrice: number, supplierNotes?: string) => void;
    cancelProjectResponse: () => void;
    withdrawProjectResponse: () => void;
    removeFile: (file: FileDto | null) => void;
    fileIsUploading: boolean;
    setFileIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
}

const statusesAllowingEditing = [
    SupplierSubmissionStatus.Submitted,
    SupplierSubmissionStatus.Interested,
    SupplierSubmissionStatus.Invited,
    SupplierSubmissionStatus.NotInterested,
    SupplierSubmissionStatus.Responding,
];

const SupplierResponseDisplay: React.FC<ISupplierResponseDisplayProps> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const {
        supplierSubmission,
        projectFiles,
        submitForm,
        cancelProjectResponse,
        withdrawProjectResponse,
        fileIsUploading,
        setFileIsUploading,
        setProjectFiles,
        removeFile,
    } = props;

    const deadlineHasPassed =
        dateTimeService.dateIsInPast(supplierSubmission?.tenderResponseDeadline) ||
        statusesAllowingEditing.find((s) => s === supplierSubmission?.status) == null;

    const { company_uuid: companyUuid } = useReduxSelector((state) => state.authenticationState.accessToken);

    return (
        <React.Fragment>
            <Copy className={classes.copy}>{t("supplierResponse.subtitle")}</Copy>
            <SupplierResponseForm
                supplierPrice={supplierSubmission.supplierPrice}
                supplierNotes={supplierSubmission.supplierNotes}
                currency={CurrencyType.GBP}
                submit={submitForm}
                deadlineHasPassed={deadlineHasPassed}
                files={projectFiles.filter(
                    (file) => companyUuid == null || file.linkedCompanyUuid === companyUuid || file.newUpload,
                )}
                setFiles={setProjectFiles}
                removeFile={removeFile}
                fileIsUploading={fileIsUploading}
                setFileIsUploading={setFileIsUploading}
                supplierSubmission={supplierSubmission}
            />
            <div className={classes.buttonControl}>
                <NotInterestedWithdrawButtons
                    supplierSubmission={supplierSubmission}
                    declareNotInterested={cancelProjectResponse}
                    withdraw={withdrawProjectResponse}
                />
            </div>
        </React.Fragment>
    );
};

export default SupplierResponseDisplay;
