import baseApi from "store/api/baseApi";
import { DeletePostalAddressRequestDto } from "types/dtos/company/postal-addresses/DeletePostalAddressRequestDto";
import { PostalAddressDto, ListPostalAddressesResponseDto } from "types/dtos/company/postal-addresses/PostalAddressDto";
import {
    CreatePostalAddressRequestDto,
    UpdatePostalAddressRequestDto,
} from "types/dtos/company/postal-addresses/PostalAddressRequestDto";
import TagNames from "types/enums/store/TagNames";

const companyAddressesApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [TagNames.PostalAddresses],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            listPostalAddresses: build.query<ListPostalAddressesResponseDto, string>({
                query: (companyUuid) => ({
                    url: `/companies/${companyUuid}/postal-addresses`,
                    method: "GET",
                }),
            }),
            updatePostalAddress: build.mutation<PostalAddressDto, UpdatePostalAddressRequestDto>({
                query: (request) => ({
                    url: `/companies/${request.companyUuid}/postal-addresses/${request.addressUuid}`,
                    method: "PUT",
                    data: request,
                }),
            }),
            createPostalAddress: build.mutation<PostalAddressDto, CreatePostalAddressRequestDto>({
                query: (request) => ({
                    url: `/companies/${request.companyUuid}/postal-addresses`,
                    method: "POST",
                    data: request,
                }),
            }),
            deletePostalAddress: build.mutation<PostalAddressDto, DeletePostalAddressRequestDto>({
                query: (request) => ({
                    url: `/companies/${request.companyUuid}/postal-addresses/${request.addressUuid}`,
                    method: "DELETE",
                    data: request,
                }),
            }),
        }),
        overrideExisting: true,
    });

export const {
    useLazyListPostalAddressesQuery,
    useUpdatePostalAddressMutation,
    useCreatePostalAddressMutation,
    useDeletePostalAddressMutation,
} = companyAddressesApi;
