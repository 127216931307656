import { Copy, IPaginationState, ITableColumn, MeatballMenu, Pagination, Pill, Table } from "@maistro/components";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import ReactGA from "react-ga4";
import dateTimeService from "services/dateTimeService";
import { ITheme } from "styles/themes/types";
import { AdminUserDto } from "types/dtos/admin/AdminUserDto";
import { CompanyUserDto } from "types/dtos/company/CompanyUserDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import UserStatusEnum from "types/enums/users/UserStatusEnum";

const useStyles = createUseStyles((theme: ITheme) => ({
    copy: {
        marginBottom: theme.spacing.xLarge,
    },
    button: {
        margin: `${theme.spacing.medium}px 0`,
    },
    pagination: {
        marginTop: theme.spacing.small,
    },
    table: {
        marginTop: theme.spacing.medium,
    },
}));

interface IAdminUsersDisplayProps {
    users: AdminUserDto[];
    paginationState: IPaginationState;
    navigateToPage: (page: number) => void;
    onEditClick: (user: AdminUserDto) => void;
    onReInviteClick: (user: AdminUserDto) => void;
}

const AdminUsersDisplay: React.FC<IAdminUsersDisplayProps> = ({
    users,
    paginationState,
    navigateToPage,
    onEditClick,
    onReInviteClick,
}: IAdminUsersDisplayProps) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const menuItemsForUser = useCallback(
        (user: AdminUserDto) => {
            const actions = [];
            actions.push({
                label: t("common.edit"),
                action: () => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: `Admin Users - Edit - `.concat(user.fullName),
                    });
                    onEditClick(user);
                },
            });
            if (user.status === UserStatusEnum.Invited) {
                actions.push({
                    label: t("adminUsers.table.reInvite"),
                    action: () => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: `Admin Users - Re-invite - `.concat(user.fullName),
                        });
                        onReInviteClick(user);
                    },
                });
            }

            return actions;
        },
        [t, onEditClick, onReInviteClick],
    );

    const columns: ITableColumn[] = useMemo(() => {
        const columnConfiguration = [
            {
                position: 1,
                priorityLevel: 1,
                label: t("adminUsers.table.fullName"),
                name: "fullName",
                render: (user: AdminUserDto) => <span>{user.fullName}</span>,
                minWidth: 200,
                contentWrap: true,
            },
            {
                position: 2,
                priorityLevel: 3,
                label: t("adminUsers.table.emailAddress"),
                name: "emailAddress",
                minWidth: 200,
                contentWrap: true,
            },
            {
                position: 3,
                priorityLevel: 5,
                label: t("adminUsers.table.lastLoggedIn"),
                name: "lastLoggedIn",
                render: (user: CompanyUserDto) => (
                    <span>
                        {user.lastLoggedIn
                            ? dateTimeService.toShortDateFormat(user.lastLoggedIn)
                            : t("adminUsers.table.neverLoggedIn")}
                    </span>
                ),
                minWidth: 100,
            },
            {
                position: 4,
                priorityLevel: 4,
                label: t("adminUsers.table.status"),
                name: "status",
                render: (user: AdminUserDto) => (
                    <Pill
                        value={user.status ?? t("common.unknown")}
                        color={user.status === UserStatusEnum.Active ? "primary" : "gray"}
                    />
                ),
                minWidth: 75,
            },
            {
                position: 5,
                priorityLevel: 0,
                name: "menu",
                minWidth: 50,
                render: (user: AdminUserDto) => <MeatballMenu menuItems={menuItemsForUser(user)} />,
            },
        ];
        return columnConfiguration;
    }, [menuItemsForUser, t]);

    return (
        <>
            <Copy>{t("adminUsers.subTitle")}</Copy>
            <Table
                className={classes.table}
                columns={columns}
                data={users}
                noDataDisplay={t("adminUsers.table.noData")}
                testid="admin-users-table"
            />
            <Pagination
                className={classes.pagination}
                paginationState={paginationState}
                navigateToPage={navigateToPage}
                testid="admin-users-pagination"
            />
        </>
    );
};

export default AdminUsersDisplay;
