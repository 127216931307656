import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { Icon } from "@maistro/components";
import classNames from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";

import useScreenLayout from "hooks/useScreenLayout";
import { buildPath } from "routes/helpers/RoutesHelper";
import { IStepperNavigationItem } from "store/navigationSlice";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    link: {
        display: "flex",
        alignItems: "center",
        padding: `${theme.spacing.xSmall}px ${theme.spacing.small}px`,
    },
    isActive: {
        backgroundColor: theme.colors.lightGray,
    },
    mobileLink: {
        borderBottom: `1px solid ${theme.colors.gray}`,
        height: 45,
    },
    hoverLink: {
        "&:hover": {
            backgroundColor: theme.colors.lightGray,
        },
    },
    stepperIcon: {
        "&:hover": {
            color: theme.colors.highlight,
        },
    },
    activeStepperIcon: {
        color: theme.colors.highlight,
    },
    label: {
        ...theme.typography.base,
        fontWeight: 600,
        marginLeft: theme.spacing.medium,
    },
    rightIcon: {
        marginLeft: "auto",
        marginRight: theme.spacing.small,
    },
    column: {
        flexDirection: "column",
    },
}));

interface DisplayNavigationItemsProps {
    stepperNavigationItems: IStepperNavigationItem[];
    isDesktopExpanded: boolean;
    setIsStepperOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DisplayNavigationItems: React.FC<DisplayNavigationItemsProps> = ({
    stepperNavigationItems,
    isDesktopExpanded,
    setIsStepperOpen,
}) => {
    const classes = useStyles();

    const { onMobile } = useScreenLayout();

    return (
        <>
            {stepperNavigationItems.map((stepperItem) => (
                <div key={stepperItem.label}>
                    <Link
                        className={classNames(classes.link, {
                            [classes.isActive]: stepperItem.isActive,
                            [classes.mobileLink]: onMobile,
                            [classes.hoverLink]: isDesktopExpanded,
                        })}
                        to={buildPath(stepperItem.route, stepperItem.route.params)}
                        onClick={() => {
                            if (onMobile) {
                                setIsStepperOpen(false);
                            }
                        }}
                    >
                        {!isDesktopExpanded && (
                            <Icon
                                className={classNames(classes.stepperIcon, {
                                    [classes.activeStepperIcon]: stepperItem.isActive,
                                })}
                                icon={stepperItem.icon}
                                alt={stepperItem.label}
                                centered
                            />
                        )}
                        {isDesktopExpanded && <div className={classNames(classes.label)}>{stepperItem.label}</div>}
                        {onMobile && !stepperItem.isActive && (
                            <Icon className={classes.rightIcon} size="xSmall" icon={faChevronRight} />
                        )}
                    </Link>
                </div>
            ))}
        </>
    );
};

export default DisplayNavigationItems;
