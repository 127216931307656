import { ITableColumn, MeatballMenu } from "@maistro/components";
import BuyerSupplierContractDownloadIcon from "features/company/profile/suppliers/BuyerSupplierContracts/BuyerSupplierContractDownloadIcon";
import { useMemo } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import currencyService from "services/currencyService";
import dateTimeService from "services/dateTimeService";
import BuyerSupplierContractDto from "types/dtos/company/buyer-supplier-relationships/BuyerSupplierContractDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useContractsTableConfiguration = (
    setSelectedContract: React.Dispatch<React.SetStateAction<BuyerSupplierContractDto | undefined>>,
    setIsFormVisible: React.Dispatch<React.SetStateAction<boolean>>,
    setIsDeleteContractPopupOpen: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const { t } = useTranslation();

    const columns: ITableColumn[] = useMemo(
        () => [
            {
                position: 1,
                priorityLevel: 1,
                label: t("companySuppliers.contracts.labels.title"),
                name: "title",
                minWidth: 150,
                contentWrap: true,
            },
            {
                position: 2,
                priorityLevel: 5,
                label: t("companySuppliers.contracts.labels.startDate"),
                name: "startDate",
                render: (contractDto: BuyerSupplierContractDto) => {
                    if (!contractDto.startDate) return "-";
                    return dateTimeService.toShortDateFormat(contractDto.startDate);
                },
                minWidth: 125,
            },
            {
                position: 3,
                priorityLevel: 4,
                label: t("companySuppliers.contracts.labels.expiryDate"),
                name: "expiryDate",
                render: (contractDto: BuyerSupplierContractDto) => {
                    if (!contractDto.expiryDate) return "-";
                    if (dateTimeService.dateIsInPast(contractDto.expiryDate)) {
                        return t("common.expired");
                    }
                    return dateTimeService.toShortDateFormat(contractDto.expiryDate);
                },
                minWidth: 125,
            },
            {
                position: 4,
                priorityLevel: 3,
                label: t("companySuppliers.contracts.labels.contractValue"),
                name: "contractValue",
                render: (contractDto: BuyerSupplierContractDto) => (
                    <div>
                        {currencyService.toDisplayFormat(contractDto.currencyType, Number(contractDto.contractValue))}
                    </div>
                ),
                minWidth: 125,
            },
            {
                position: 5,
                priorityLevel: 3,
                label: t("companySuppliers.contracts.labels.contractOwner"),
                name: "contractOwner",
                render: (contractDto: BuyerSupplierContractDto) => (
                    <div>{contractDto.contractOwnerName ? contractDto.contractOwnerName : "-"}</div>
                ),
                minWidth: 125,
            },
            {
                position: 5,
                priorityLevel: 3,
                label: t("companySuppliers.contracts.labels.department"),
                name: "department",
                render: (contractDto: BuyerSupplierContractDto) => (
                    <div>{contractDto.department ? contractDto.department : "-"}</div>
                ),
                minWidth: 100,
            },
            {
                position: 5,
                priorityLevel: 7,
                label: t("companySuppliers.contracts.labels.nextPossibleTerminationDate"),
                name: "nextPossibleTerminationDate",
                render: (contractDto: BuyerSupplierContractDto) => {
                    if (!contractDto.nextPossibleTerminationDate) return "-";
                    return dateTimeService.toShortDateFormat(contractDto.nextPossibleTerminationDate);
                },
                minWidth: 125,
            },
            {
                position: 6,
                priorityLevel: 6,
                label: t("companySuppliers.contracts.labels.isTerminated"),
                name: "isTerminated",
                render: (contractDto: BuyerSupplierContractDto) => {
                    return contractDto.isTerminated ? t("common.yes") : t("common.no");
                },
                minWidth: 100,
            },
            {
                position: 7,
                priorityLevel: 8,
                label: t("companySuppliers.contracts.labels.terminationForConvenience"),
                name: "terminationForConvenience",
                render: (contractDto: BuyerSupplierContractDto) => {
                    return contractDto.terminationForConvenience ? t("common.yes") : t("common.no");
                },
                minWidth: 100,
            },
            {
                position: 8,
                priorityLevel: 9,
                label: t("companySuppliers.contracts.labels.liabilitiesCap"),
                name: "liabilitiesCap",
                render: (contractDto: BuyerSupplierContractDto) => (
                    <div>
                        {currencyService.toDisplayFormat(contractDto.currencyType, Number(contractDto.liabilitiesCap))}
                    </div>
                ),
                minWidth: 125,
            },
            {
                position: 8,
                priorityLevel: 2,
                name: "files",
                render: ({ files }: BuyerSupplierContractDto) => (
                    <BuyerSupplierContractDownloadIcon
                        fileId={files[0].fileId}
                        fileName={files[0].fileName}
                        fileExtension={files[0].fileExtension}
                    />
                ),
                minWidth: 50,
            },
            {
                position: 9,
                priorityLevel: 0,
                name: "menu",
                minWidth: 50,
                render: (contractDto: BuyerSupplierContractDto) => (
                    <MeatballMenu
                        menuItems={[
                            {
                                label: t("common.edit"),
                                action: () => {
                                    ReactGA.event({
                                        category: ga4Category.Button,
                                        action: ga4Action.ButtonClick,
                                        label: `Contracts - Edit Contract - `.concat(contractDto.title),
                                    });
                                    setSelectedContract(contractDto);
                                    setIsFormVisible(true);
                                },
                            },
                            {
                                label: t("common.delete"),
                                action: () => {
                                    ReactGA.event({
                                        category: ga4Category.Button,
                                        action: ga4Action.ButtonClick,
                                        label: `Contracts - Delete Contract - `.concat(contractDto.title),
                                    });
                                    setSelectedContract(contractDto);
                                    setIsDeleteContractPopupOpen(true);
                                },
                            },
                        ]}
                    />
                ),
            },
        ],
        [setIsDeleteContractPopupOpen, setIsFormVisible, setSelectedContract, t],
    );

    return {
        columns,
    };
};

export default useContractsTableConfiguration;
