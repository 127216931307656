import {
    Copy,
    IMeatballMenuItem,
    IPaginationState,
    ITableColumn,
    MeatballMenu,
    Pagination,
    Pill,
    Table,
} from "@maistro/components";
import useCurrentUser from "hooks/useCurrentUser";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import ReactGA from "react-ga4";
import dateTimeService from "services/dateTimeService";
import { ITheme } from "styles/themes/types";
import { CompanyUserDto } from "types/dtos/company/CompanyUserDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import RolesEnum from "types/enums/rolesPermissions/RolesEnum";
import UserStatusEnum from "types/enums/users/UserStatusEnum";

const useStyles = createUseStyles((theme: ITheme) => ({
    copy: {
        marginBottom: theme.spacing.xLarge,
    },
    button: {
        margin: `${theme.spacing.medium}px 0`,
    },
    pagination: {
        marginTop: theme.spacing.small,
    },
    table: {
        marginTop: theme.spacing.medium,
    },
}));

interface ICompanyUsersDisplayProps {
    users: CompanyUserDto[];
    paginationState: IPaginationState;
    navigateToPage: (page: number) => void;
    onEditClick: (user: CompanyUserDto) => void;
    onDeleteClick: (user: CompanyUserDto) => void;
    onReInviteClick: (user: CompanyUserDto) => void;
    companyIsBuyer: boolean;
    isGlobalAdmin: boolean;
    canEdit: boolean;
}

const CompanyUsersDisplay: React.FC<ICompanyUsersDisplayProps> = ({
    users,
    paginationState,
    navigateToPage,
    onEditClick,
    onDeleteClick,
    onReInviteClick,
    companyIsBuyer,
    isGlobalAdmin,
    canEdit,
}: ICompanyUsersDisplayProps) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const sortByPostion = (a: ITableColumn, b: ITableColumn) => a.position - b.position;
    const { myUuid } = useCurrentUser();

    const isLasyAdminInCompany = useCallback(
        (user: CompanyUserDto) => {
            const admins = users.filter(
                (u) => u.roleName === RolesEnum.CompanyAdmin || u.roleName === RolesEnum.Supplier,
            );
            if (admins.length === 1 && admins.includes(user)) {
                return true;
            }
            return false;
        },
        [users],
    );

    const menuItemsForUser = useCallback(
        (user: CompanyUserDto) => {
            const actions: IMeatballMenuItem[] = [];
            if (canEdit) {
                actions.push({
                    label: t("companyProfileUsers.table.edit"),
                    action: () => onEditClick(user),
                });
            }
            if (user.status === UserStatusEnum.Invited && isGlobalAdmin) {
                actions.push({
                    label: t("companyProfileUsers.table.reInvite"),
                    action: () => onReInviteClick(user),
                });
            }
            if (canEdit && user.userUuid !== myUuid && !isLasyAdminInCompany(user)) {
                actions.push({
                    label: t("companyProfileUsers.table.delete"),
                    action: () => onDeleteClick(user),
                });
            }
            return actions;
        },
        [canEdit, isGlobalAdmin, isLasyAdminInCompany, myUuid, onDeleteClick, onEditClick, onReInviteClick, t],
    );

    const columns: ITableColumn[] = useMemo(() => {
        const columnConfiguration = [
            {
                position: 1,
                priorityLevel: 1,
                label: t("companyProfileUsers.table.userName"),
                name: "userFullName",
                render: (user: CompanyUserDto) => <span>{`${user.firstName} ${user.lastName}`}</span>,
                minWidth: 200,
                contentWrap: true,
            },
            {
                position: 3,
                priorityLevel: 3,
                label: t("companyProfileUsers.table.emailAddress"),
                name: "emailAddress",
                minWidth: 200,
                contentWrap: true,
            },
            {
                position: 4,
                priorityLevel: 5,
                label: t("companyProfileUsers.table.lastLoggedIn"),
                name: "lastLoggedIn",
                render: (user: CompanyUserDto) => (
                    <span>
                        {user.lastLoggedIn
                            ? dateTimeService.toShortDateFormat(user.lastLoggedIn)
                            : t("companyProfileUsers.table.neverLoggedIn")}
                    </span>
                ),
                minWidth: 100,
            },
            {
                position: 5,
                priorityLevel: 4,
                label: t("companyProfileUsers.table.status"),
                name: "status",
                render: (user: CompanyUserDto) => (
                    <Pill
                        value={user.status ?? t("common.unknown")}
                        color={user.status === UserStatusEnum.Active ? "primary" : "gray"}
                    />
                ),
                minWidth: 75,
            },
            {
                position: 6,
                priorityLevel: 0,
                name: "menu",
                minWidth: 50,
                render: (user: CompanyUserDto) => <MeatballMenu menuItems={menuItemsForUser(user)} />,
            },
        ];

        if (companyIsBuyer) {
            columnConfiguration.push({
                position: 2,
                priorityLevel: 2,
                label: t("companyProfileUsers.table.roleName"),
                name: "roleName",
                render: (user: CompanyUserDto) => <span>{t(`roles.names.${user.roleName}`)}</span>,
                minWidth: 100,
            });
        }

        columnConfiguration.sort(sortByPostion);
        return columnConfiguration;
    }, [companyIsBuyer, menuItemsForUser, t]);

    return (
        <>
            <Copy>{t("companyProfileUsers.subTitle")}</Copy>
            <Table
                className={classes.table}
                columns={columns}
                data={users}
                rowAction={(row: CompanyUserDto) => {
                    ReactGA.event({
                        category: ga4Category.Element,
                        action: ga4Action.Element,
                        label: `Company - User Select - ${row.firstName} ${row.lastName}`,
                    });
                    onEditClick(row);
                }}
                noDataDisplay={t("companyProfileUsers.table.noData")}
                testid="company-users-table"
            />
            <Pagination
                className={classes.pagination}
                paginationState={paginationState}
                navigateToPage={navigateToPage}
                testid="company-users-pagination"
            />
        </>
    );
};

export default CompanyUsersDisplay;
