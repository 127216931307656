import { BooleanOptionsField, CheckboxField, DateField, Heading, TextField } from "@maistro/components";
import { useFormikContext } from "formik";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import classnames from "classnames";
import * as Yup from "yup";

import DisplayVettingFileUploadField from "features/company/profile/vetting/files/DisplayVettingFileUploadField";
import { IVettingQuestionnaireForm } from "features/company/profile/vetting/IVettingQuestionnaireForm";
import useSectionStyles from "features/company/profile/vetting/sections/useSectionStyles";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";
import { VettingInformationKeys } from "types/dtos/company/vettingInformation/VettingInformationDto";

const minDate = new Date();

const useStyles = createUseStyles((theme: ITheme) => ({
    columnFlex: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.small,
    },
}));

export const environmentValidationSchema = (t: TFunction<"translation", undefined>) =>
    Yup.object({
        hasEnvironmentalPolicy: Yup.boolean().nullable(),
        environmentalPolicyExpiryDate: Yup.date()
            .optional()
            .nullable()
            .min(
                minDate,
                t(
                    "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.environment.expiryDate.minError",
                ),
            ),
        includesEnvironmentalIssuesInPerformance: Yup.boolean().nullable(),
        hasAccessToEnvironmentalAdvice: Yup.boolean().nullable(),
        environmentalAdviceProviderName: Yup.string().nullable(),
        isProviderExternal: Yup.string().nullable(),
        hasCarbonEmissionsPolicy: Yup.boolean().nullable(),
        carbonEmissionsPolicyDetails: Yup.string().nullable(),
        operatesInAccordanceWithStandards: Yup.boolean().nullable(),
        hasEnvironmentalBreaches: Yup.boolean().nullable(),
        environmentalBreachesDetails: Yup.string().nullable(),
    });

interface IEnvironmentSubSectionProps {
    environmentPolicyFiles?: FileDto[];
    setEnvironmentPolicyFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
}

const EnvironmentSubSection: React.FC<IEnvironmentSubSectionProps> = ({
    environmentPolicyFiles,
    setEnvironmentPolicyFiles,
}) => {
    const sharedClasses = useSectionStyles();
    const classes = useStyles();
    const { t } = useTranslation();

    const [fileIsUploading, setFileIsUploading] = useState<boolean>(false);

    const { values } = useFormikContext<IVettingQuestionnaireForm>();

    return (
        <div id={VettingInformationKeys.environment}>
            <Heading variant="h4">
                {t("companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.environment.title")}
            </Heading>
            <ol className={sharedClasses.listStyle} type="1">
                <li>
                    <BooleanOptionsField
                        name="hasEnvironmentalPolicy"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.environment.policy.label",
                        )}
                        testid="has-environmental-policy-options-field"
                    />
                    {values.hasEnvironmentalPolicy && (
                        <div className={classnames(classes.columnFlex, sharedClasses.marginTop)}>
                            <DisplayVettingFileUploadField
                                testid="environment-policy-file-upload-field"
                                files={environmentPolicyFiles}
                                setFiles={setEnvironmentPolicyFiles}
                                fileIsUploading={fileIsUploading}
                                setFileIsUploading={setFileIsUploading}
                                name="environmentFiles"
                                multiple={false}
                                required
                            />
                            <DateField
                                testid="environmental-expiry-date-field"
                                label={t(
                                    "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.environment.expiryDate.label",
                                )}
                                name="environmentalPolicyExpiryDate"
                                required
                                minDate={minDate}
                            />
                        </div>
                    )}
                </li>
                <li>
                    <BooleanOptionsField
                        name="includesEnvironmentalIssuesInPerformance"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.environment.environmentalIssues",
                        )}
                        testid="includes-environmental-issues-options-field"
                    />
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasAccessToEnvironmentalAdvice"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.environment.accessToAdvice.label",
                        )}
                        tooltip={t(
                            "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.environment.accessToAdvice.tooltip",
                        )}
                        testid="has-environmental-advice-options-field"
                    />
                    {values.hasAccessToEnvironmentalAdvice && (
                        <div className={classnames(sharedClasses.marginTop, classes.columnFlex)}>
                            <TextField
                                name="environmentalAdviceProviderName"
                                label={t(
                                    "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.environment.accessToAdvice.details",
                                )}
                                testid="provider-field"
                            />
                            <CheckboxField
                                name="isProviderExternal"
                                label={t(
                                    "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.environment.accessToAdvice.external",
                                )}
                                testid="provider-is-external-field"
                            />
                        </div>
                    )}
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasCarbonEmissionsPolicy"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.environment.carbonEmissions.label",
                        )}
                        testid="carbon-emissions-policy-options-field"
                    />
                    {values.hasCarbonEmissionsPolicy && (
                        <div className={sharedClasses.marginTop}>
                            <TextField
                                name="carbonEmissionsPolicyDetails"
                                label={t(
                                    "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.environment.carbonEmissions.details",
                                )}
                                testid="carbon-emissions-policy-details-field"
                            />
                        </div>
                    )}
                </li>
                <li>
                    <BooleanOptionsField
                        name="operatesInAccordanceWithStandards"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.environment.standards",
                        )}
                        testid="operates-in-accordance-with-standards-options-field"
                    />
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasEnvironmentalBreaches"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.environment.environmentalBreaches.label",
                        )}
                        testid="environmental-breaches-options-field"
                    />
                    {values.hasEnvironmentalBreaches && (
                        <div className={sharedClasses.marginTop}>
                            <TextField
                                name="environmentalBreachesDetails"
                                label={t(
                                    "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.environment.environmentalBreaches.details",
                                )}
                                testid="environmental-breaches-details-field"
                            />
                        </div>
                    )}
                </li>
            </ol>
        </div>
    );
};

export default EnvironmentSubSection;
