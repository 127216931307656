import { configureStore } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { createReduxHistoryContext } from "redux-first-history";

import baseApi from "store/api/baseApi";
import authenticationReducer from "store/authenticationSlice";
import companyReducer from "store/companySlice";
import layoutReducer from "store/layoutSlice";
import signalRMiddleware from "store/middlewares/signalR";
import navigationReducer from "store/navigationSlice";
import notificationsReducer from "store/notificationsSlice";
import oidcReducer from "store/oidcSlice";
import platformReducer from "store/platformSlice";
import projectResponseReducer from "store/projectResponseSlice";
import projectReducer from "store/projectSlice";
import rolesPermissionsReducer from "store/rolesPermissionsSlice";
import sectionReducer from "store/sectionsSlice";
import supplierMatchingReducer from "store/supplierMatchingSlice";
import themeReducer from "store/themeSlice";

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
});

const store = configureStore({
    reducer: {
        [baseApi.reducerPath]: baseApi.reducer,
        router: routerReducer,
        authenticationState: authenticationReducer,
        companyState: companyReducer,
        layout: layoutReducer,
        navigationState: navigationReducer,
        notificationsState: notificationsReducer,
        oidcState: oidcReducer,
        projectResponseState: projectResponseReducer,
        projectState: projectReducer,
        rolesPermissionsState: rolesPermissionsReducer,
        sectionState: sectionReducer,
        supplierMatchingState: supplierMatchingReducer,
        themeState: themeReducer,
        platformState: platformReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat([baseApi.middleware, routerMiddleware, signalRMiddleware]),
    devTools: process.env.NODE_ENV !== "production",
});

export type AppStore = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const history = createReduxHistory(store);

export default store;
