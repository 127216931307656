import { createAxiosInstance } from "api/commonApi";
import { AxiosResponse } from "axios";
import env from "config/EnvironmentVariables";
import { ExpressionOfInterestDto } from "types/dtos/projects/expressionOfInterest/ExpressionOfInterestDto";
import { SelectExpressionOfInterestRequestDto } from "types/dtos/projects/expressionOfInterest/SelectExpressionOfInterestRequestDto";
import { ExpressionOfInterestResponseDto } from "types/dtos/projects/expressionOfInterest/ExpressionOfInterestResponseDto";
import { ListExpressionOfInterestResponseDto } from "types/dtos/projects/expressionOfInterest/ListExpressionOfInterestResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/projects";

export const createExpressionOfInterest = (
    request: ExpressionOfInterestDto,
): Promise<AxiosResponse<ExpressionOfInterestDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${request.projectUuid}/expression-of-interest`;
    return api.post(url, request);
};

export const listExpressionOfInterest = (
    projectUuid: string,
): Promise<AxiosResponse<ListExpressionOfInterestResponseDto | TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/expression-of-interest`;
    return api.get(url);
};

export const selectExpressionOfInterest = (
    request: SelectExpressionOfInterestRequestDto,
): Promise<AxiosResponse<ExpressionOfInterestResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${request.projectUuid}/expression-of-interest/${request.projectInterestUuid}`;
    return api.put(url, request);
};
