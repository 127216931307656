import { IProjectInformation } from "features/project/types";
import { useMemo } from "react";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

export interface IUseTenderPermissionsConfig {
    isScoringComplete: boolean;
    projectInformation: IProjectInformation;
    userHasProjectPermission: (permission: PermissionsEnum, projectContext: string, companyContext?: string) => boolean;
}
export const useTenderPermissions = (props: IUseTenderPermissionsConfig) => {
    const { isScoringComplete, projectInformation, userHasProjectPermission } = props;

    const canAskQuestion = useMemo(() => {
        return userHasProjectPermission(PermissionsEnum.CanAskClarificationQuestion, projectInformation.uuid);
    }, [projectInformation.uuid, userHasProjectPermission]);

    const canScoreAnswers = useMemo(
        () =>
            !isScoringComplete &&
            userHasProjectPermission(
                PermissionsEnum.CreateScore,
                projectInformation.uuid,
                projectInformation.companyUuid,
            ),
        [isScoringComplete, userHasProjectPermission, projectInformation.uuid, projectInformation.companyUuid],
    );

    const canModerateScores = useMemo(
        () =>
            isScoringComplete &&
            userHasProjectPermission(
                PermissionsEnum.OverrideScore,
                projectInformation.uuid,
                projectInformation.companyUuid,
            ),
        [isScoringComplete, userHasProjectPermission, projectInformation.uuid, projectInformation.companyUuid],
    );
    return { canAskQuestion, canScoreAnswers, canModerateScores };
};
