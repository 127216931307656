import React from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

import { ITheme } from "styles/themes/types";
import { ICommonProps } from "components/shared";

const useStyles = createUseStyles((theme: ITheme) => ({
    tableWrap: {
        overflowX: "auto", // Aim to remove this once mobile tables improve.
    },

    table: {
        width: "100%",
        borderCollapse: "separate",
        borderSpacing: `0 ${theme.spacing.xSmall}px`,

        "& thead::after": {
            content: '""',
            display: "block",
            height: theme.spacing.xSmall,
            width: "100%",
            background: "none",
        },

        "& th": {
            padding: theme.spacing.small,
            fontWeight: 500,
            borderBottom: "1px solid",
            borderBottomColor: theme.colors.gray,

            "&:first-child": {
                paddingLeft: theme.spacing.medium,
            },

            "&:not([class*='alignRight'])": {
                textAlign: "left",
            },
        },
    },
}));

interface ITableProps extends ICommonProps {
    children?: React.ReactNode;
}

const Table: React.FC<ITableProps> = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.tableWrap} data-component="Table">
            <table className={classNames(classes.table, props.className)} data-testid={props.testid}>
                {props.children}
            </table>
        </div>
    );
};

export default Table;
