import { useCallback, useEffect, useState } from "react";

import { useLazyListCompanyUsersQuery } from "store/api/companyUsersApi";
import { CompanyUserDto } from "types/dtos/company/CompanyUserDto";
import BuyerSupplierContractDto from "types/dtos/company/buyer-supplier-relationships/BuyerSupplierContractDto";
import FileDto from "types/dtos/files/FileDto";

const useContractsForm = (buyerUuid: string, initialContract?: BuyerSupplierContractDto) => {
    const [contractFiles, setContractFiles] = useState<FileDto[]>(initialContract?.files ?? []);
    const [companyUsers, setCompanyUsers] = useState<CompanyUserDto[]>([]);
    const [companyUsersSearchText, setCompanyUsersSearchText] = useState<string>("");

    const [listCompanyUsers] = useLazyListCompanyUsersQuery();

    const fetchCompanyUsers = useCallback(() => {
        listCompanyUsers({ searchQuery: companyUsersSearchText, companyUuid: buyerUuid }, true)
            .unwrap()
            .then((companyUsersResponse) => setCompanyUsers(companyUsersResponse.items));
    }, [buyerUuid, companyUsersSearchText, listCompanyUsers]);

    useEffect(() => {
        setContractFiles(initialContract?.files ?? []);
    }, [initialContract?.files]);

    useEffect(() => {
        fetchCompanyUsers();
    }, [fetchCompanyUsers]);

    return {
        contractFiles,
        setContractFiles,
        companyUsers,
        setCompanyUsersSearchText,
    };
};

export default useContractsForm;
