import { Popup, useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { linkProjectFiles } from "api/fileApi";
import { createQuestion } from "api/supplierSubmission/submissionClarificationQuestionsApi";
import useFileUpload from "features/files/useFileUpload";
import SupplierAskQuestionDisplay from "features/project/clarificationQuestions/askQuestion/SupplierAskQuestionDisplay";
import useNavTabs from "features/project/hooks/useNavTabs";
import useProjectResponse from "features/project/hooks/useProjectResponse";
import useAppDispatch from "hooks/useAppDispatch";
import useReduxSelector from "hooks/useReduxSelector";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import validationService from "services/validationService";
import { setBack, setPageTitle, setSecondaryNavItems, setTooltip } from "store/layoutSlice";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import EntityType from "types/enums/questions/EntityType";

const SupplierAskQuestionContainer: React.FC = () => {
    const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
    const [isSubmittingQuestion, setIsSubmittingQuestion] = useState(false);
    const { user_uuid: userUuid } = useReduxSelector((state) => state.authenticationState.accessToken);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const toast = useToaster();

    const { projectResponseInformation } = useProjectResponse();
    const { projectUuid, responseUuid } = projectResponseInformation;
    const { supplierTabItems } = useNavTabs();
    const { fileIsUploading, setFileIsUploading, projectFiles, setProjectFiles, removeFile } = useFileUpload(
        projectResponseInformation.projectUuid,
        EntityType.Question,
    );

    useEffect(() => {
        dispatch(setPageTitle(t("clarificationQuestions.titles.ask")));
        dispatch(setTooltip(t("clarificationQuestions.tooltips.all")));
        dispatch(
            setBack({
                route: buildRoute(routes.projectResponses.clarificationQuestions, { projectUuid, responseUuid }),
            }),
        );
        dispatch(setSecondaryNavItems(supplierTabItems));

        return () => {
            dispatch(setSecondaryNavItems(null));
        };
    }, [dispatch, projectUuid, responseUuid, t, supplierTabItems]);

    const submitQuestion = useCallback(
        async (question: string, section: string) => {
            setIsSubmittingQuestion(true);
            if (
                !(
                    projectUuid &&
                    responseUuid &&
                    userUuid &&
                    validationService.isValidUuid(projectUuid) &&
                    validationService.isValidUuid(responseUuid)
                )
            ) {
                setIsSubmittingQuestion(false);
                return;
            }

            const response = await createQuestion(responseUuid, {
                questionText: question,
                entityUuid: section !== "" ? section : projectResponseInformation.projectUuid,
                entityType: section !== "" ? EntityType.Section : EntityType.Project,
            });

            if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                toast.error(t("clarificationQuestions.api.askQuestionError"));
                setIsSubmittingQuestion(false);
                return;
            }

            const newFiles = projectFiles.filter((file) => file.newUpload);
            if (newFiles.length) {
                const fileUuids = newFiles.map((file) => file.fileId);
                await linkProjectFiles(projectUuid, fileUuids, EntityType.Question, response.data.questionUuid);
            }

            navigate(buildPath(routes.projectResponses.clarificationQuestions, { projectUuid, responseUuid }));
            toast.success(t("clarificationQuestions.api.askQuestionSuccess"));
            setIsSubmittingQuestion(false);
        },
        [navigate, projectFiles, projectResponseInformation.projectUuid, projectUuid, responseUuid, t, toast, userUuid],
    );

    const cancelQuestion = useCallback(() => {
        navigate(buildPath(routes.projectResponses.clarificationQuestions, { projectUuid, responseUuid }));
    }, [navigate, projectUuid, responseUuid]);

    return (
        <React.Fragment>
            <SupplierAskQuestionDisplay
                projectResponseInformation={projectResponseInformation}
                submitQuestion={submitQuestion}
                cancelQuestion={() => setIsCancelPopupOpen(true)}
                submittingQuestion={isSubmittingQuestion}
                files={projectFiles}
                setFiles={setProjectFiles}
                removeFile={removeFile}
                fileIsUploading={fileIsUploading}
                setFileIsUploading={setFileIsUploading}
            />
            <Popup
                title={t("popups.cancelQuestion.title")}
                message={t("popups.cancelQuestion.message")}
                isOpen={isCancelPopupOpen}
                primaryActionText={t("popups.cancelQuestion.cta.primary")}
                onPrimaryAction={cancelQuestion}
                onClose={() => setIsCancelPopupOpen(false)}
                testid="cancel-question-popup"
            />
        </React.Fragment>
    );
};

export default SupplierAskQuestionContainer;
