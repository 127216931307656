import { AxiosResponse } from "axios";
import { createAxiosInstance } from "api/commonApi";
import env from "config/EnvironmentVariables";
import EntityType from "types/enums/questions/EntityType";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { ListQuestionsResponseDto } from "types/dtos/questions/ListQuestionsResponseDto";
import { AnswerDto } from "types/dtos/questions/AnswerResponseDto";
import { CreateOrUpdateTenderCriteriaAnswerForSubmissionRequestDto } from "types/dtos/questions/CreateOrUpdateTenderCriteriaAnswerForSubmissionRequestDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/supplier-submissions";
const questionType = "tender-criteria-questions";

export const listSectionQuestions = (
    submissionUuid: string,
    sectionUuid: string,
): Promise<AxiosResponse<ListQuestionsResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${submissionUuid}/${questionType}`;
    return api.get(url, {
        params: {
            EntityType: EntityType.Section.toString(),
            EntityUuid: sectionUuid,
            IncludeAnswers: true,
        },
    });
};

export const createOrUpdateAnswer = (
    submissionUuid: string,
    questionUuid: string,
    answer: CreateOrUpdateTenderCriteriaAnswerForSubmissionRequestDto,
): Promise<AxiosResponse<AnswerDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${submissionUuid}/${questionType}/${questionUuid}/answers`;
    return api.put(url, answer);
};
