import { ICommonProps, Label, Popup } from "@maistro/components";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import FileList from "components/FileList/FileList";
import FileUploadField from "components/FileUploadField/FileUploadField";
import env from "config/EnvironmentVariables";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    fileListContainer: {
        marginTop: theme.spacing.small,
    },
    label: {
        display: "inline-block",
    },
}));

type BuyerSupplierContractUploadFieldProps = ICommonProps & {
    files: Array<FileDto>;
    setFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    name: string;
};

const BuyerSupplierContractUploadField: React.FC<BuyerSupplierContractUploadFieldProps> = ({
    files,
    setFiles,
    name,
    testid,
}) => {
    const styles = useStyles();
    const { t } = useTranslation();

    const [fileIsUploading, setFileIsUploading] = useState<boolean>(false);
    const [isRemoveFilePopupOpen, setIsRemoveFilePopupOpen] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<FileDto | null>(null);

    const displayConfirmDeleteDialog = useCallback((file: FileDto): void => {
        setSelectedFile(file);
        setIsRemoveFilePopupOpen(true);
    }, []);

    const removeFile = useCallback(() => {
        if (selectedFile) {
            setFiles((prevState) => prevState.filter((prevFile) => prevFile.fileId !== selectedFile.fileId));
        }
        setIsRemoveFilePopupOpen(false);
    }, [selectedFile, setFiles]);

    return (
        <>
            <Label
                className={styles.label}
                content={t("companySuppliers.contracts.labels.files")}
                name={name}
                required
            />
            {files && files.length > 0 && (
                <div className={styles.fileListContainer}>
                    <FileList
                        files={files}
                        setFiles={setFiles}
                        canDelete
                        canDownload
                        testid={`${testid}-documents`}
                        removeFile={displayConfirmDeleteDialog}
                    />
                </div>
            )}
            <FileUploadField
                setFiles={setFiles}
                name={name}
                acceptedExtensions={env.SupportedProjectFileExtensions}
                testid={`${testid}-upload-file`}
                fileIsUploading={fileIsUploading}
                setFileIsUploading={setFileIsUploading}
                files={files}
            />
            <Popup
                title={t("popups.removeFile.title")}
                message={t("popups.removeFile.message", { fileName: selectedFile?.fileName })}
                isOpen={isRemoveFilePopupOpen}
                primaryActionText={t("popups.removeFile.cta.primary")}
                onPrimaryAction={removeFile}
                onClose={() => setIsRemoveFilePopupOpen(false)}
                testid="confirm-remove-file-popup"
            />
        </>
    );
};

export default BuyerSupplierContractUploadField;
