import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Button, Copy } from "@maistro/components";
import { Formik } from "formik";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { IOptionProps } from "components/shared";
import CreateUserGroupForm from "features/company/buyer/userGroups/CreateUserGroupForm";
import PolicyRow from "features/company/buyer/userGroups/PolicyRow";
import SupplierTieringForm from "features/company/buyer/userGroups/SupplierTieringForm";
import ReactGA from "react-ga4";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import stringService from "services/stringService";
import { ITheme } from "styles/themes/types";
import { PolicyDto } from "types/dtos/company/policies/PolicyDto";
import { UserGroupDto } from "types/dtos/company/userGroups/UserGroupDto";
import PolicyType from "types/enums/companies/Approvals/PolicyType";
import SupplierTieringStatus from "types/enums/companies/SupplierTieringStatus";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    inlineFlex: {
        display: "inline-flex",
    },

    policies: {
        marginTop: theme.spacing.medium,
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.large,
    },

    message: {
        fontSize: 13,
        fontWeight: 500,
    },
}));

interface ICreateUserGroupDisplayProps {
    companyUuid: string;
    userGroup?: UserGroupDto;
    policies: PolicyDto[];
    companyUsers: IOptionProps[];
    saveGroup: (
        values: UserGroupDto,
        supplierTierings: SupplierTieringStatus[],
        setFieldError: (field: string, message: string) => void,
    ) => Promise<void>;
    onAddPolicy: () => void;
    onDeletePolicy: (policy: PolicyDto) => void;
    onEditPolicy: (policy: PolicyDto) => void;
    setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

const DirtySetter = (props: { dirty: boolean; setIsDirty: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const { dirty, setIsDirty } = props;
    useEffect(() => {
        setIsDirty(dirty);
    }, [setIsDirty, dirty]);
    return null;
};

const CreateUserGroupDisplay: React.FC<ICreateUserGroupDisplayProps> = ({
    companyUuid,
    userGroup,
    policies,
    companyUsers,
    saveGroup,
    onAddPolicy,
    onDeletePolicy,
    onEditPolicy,
    setIsDirty,
}) => {
    const classes = useStyles();

    const navigate = useNavigate();
    const { t } = useTranslation();

    const tieringPolicy = useMemo(() => policies.find((policy) => policy.type === PolicyType.Tiering), [policies]);
    const spendLimitPolicies = useMemo(
        () => policies.filter((policy) => policy.type === PolicyType.SpendLimit),
        [policies],
    );

    return (
        <Formik
            initialValues={{
                id: userGroup?.id ?? "",
                name: userGroup?.name ?? "",
                memberUuids: userGroup?.memberUuids ?? [],
                hasTieringPolicy: tieringPolicy !== undefined,
                supplierTierings: tieringPolicy?.supplierTierings ?? [],
            }}
            validationSchema={Yup.object({
                name: Yup.string().required(t("userGroups.create.form.name.required")),
                memberUuids: Yup.array()
                    .of(Yup.string())
                    .required(t("userGroups.create.form.members.required"))
                    .min(1, t("userGroups.create.form.members.required")),
            })}
            onSubmit={(values, { setFieldError }) => {
                // Set to false here to prevent the "unsaved Changes" prompt appearing when "isNewGroup" is set to false
                setIsDirty(false);
                saveGroup(values, values.supplierTierings, setFieldError).then(() => {
                    navigate(buildPath(routes.company.userGroups, { companyUuid }));
                });
            }}
        >
            {({ isValid, setFieldError, values, dirty }) => {
                return (
                    <div>
                        <CreateUserGroupForm companyUsers={companyUsers} />
                        <SupplierTieringForm />
                        <div className={classes.policies}>
                            <Button
                                label={t("userGroups.create.addPolicy")}
                                color="dark"
                                onClick={() => {
                                    ReactGA.event({
                                        category: ga4Category.Button,
                                        action: ga4Action.ButtonClick,
                                        label: "User Groups - Create - Add Policy - ".concat(
                                            userGroup?.name || "No Name",
                                        ),
                                    });
                                    if (userGroup?.id) {
                                        onAddPolicy();
                                        return;
                                    }
                                    saveGroup(values, values.supplierTierings, setFieldError).then(onAddPolicy);
                                }}
                                disabled={!isValid}
                                icon={faPlus}
                                testid="add-policy-button"
                            />
                            {spendLimitPolicies.length === 0 && (
                                <Copy className={classes.message}>{t("userGroups.policies.noPolicies")}</Copy>
                            )}
                            {spendLimitPolicies.length > 0 &&
                                spendLimitPolicies.map((policy) => (
                                    <PolicyRow
                                        key={policy.id}
                                        policy={policy}
                                        companyUsers={companyUsers}
                                        onDeletePolicy={onDeletePolicy}
                                        onEditPolicy={onEditPolicy}
                                        testid={stringService.toKebabCase(policy.name)}
                                    />
                                ))}
                        </div>
                        <DirtySetter dirty={dirty} setIsDirty={setIsDirty} />
                    </div>
                );
            }}
        </Formik>
    );
};

export default CreateUserGroupDisplay;
