const useTimeout = (action: () => void, delay: number) => {
    let timeout: number;

    const beginTimer = () => {
        timeout = window.setTimeout(action, delay);
    };

    const clearTimer = () => {
        window.clearTimeout(timeout);
    };

    const resetTimer = () => {
        clearTimer();
        beginTimer();
    };

    return {
        beginTimer,
        clearTimer,
        resetTimer,
    };
};

export default useTimeout;
