import { faCheck, faFlagPennant } from "@fortawesome/pro-regular-svg-icons";
import { Button, Icon, ITableColumn } from "@maistro/components";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { listCompanySizeTypes, listIndustryTypes } from "api/company/referenceDataApi";
import { IOptionProps } from "components/shared";
import dateTimeService from "services/dateTimeService";
import stringService from "services/stringService";
import { ExpressionOfInterestResponseDto } from "types/dtos/projects/expressionOfInterest/ExpressionOfInterestResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { CompanySizeV2, getCompanySizeRange } from "types/enums/companies/CompanySizeV2";

const useStyles = createUseStyles(() => ({
    alignRight: {
        textAlign: "right",
    },
    selectSupplierButton: {
        minWidth: 100,
    },
}));

const useSuppliersConfiguration = (
    deadlineHasPassed?: boolean,
    selectSupplier?: (supplier: ExpressionOfInterestResponseDto) => void,
    isSelectingSupplier?: boolean,
) => {
    const classes = useStyles();

    const [companySizes, setCompanySizes] = useState<Array<IOptionProps>>([]);
    const [industryTypes, setIndustryTypes] = useState<Array<IOptionProps>>([]);

    const { t } = useTranslation();

    const columns: ITableColumn[] = useMemo(() => {
        const columnsConfiguration = [
            {
                position: 1,
                priorityLevel: 1,
                label: t("expressionOfInterest.monitor.table.companyName"),
                name: "companyName",
                minWidth: 150,
            },
            {
                position: 2,
                priorityLevel: 3,
                label: t("expressionOfInterest.monitor.table.createdOn"),
                name: "createdOn",
                minWidth: 150,
                render: (dto: ExpressionOfInterestResponseDto) =>
                    dateTimeService.toShortDateFormat(new Date(dto.createdOn)),
            },
            {
                position: 3,
                priorityLevel: 4,
                label: t("expressionOfInterest.monitor.table.industryType"),
                name: "industryType",
                minWidth: 150,
            },
            {
                position: 4,
                priorityLevel: 5,
                label: t("expressionOfInterest.monitor.table.website"),
                name: "website",
                minWidth: 200,
            },
            {
                position: 5,
                priorityLevel: 7,
                label: t("expressionOfInterest.monitor.table.companyRegistrationNumber"),
                name: "companyRegistrationNumber",
                minWidth: 150,
            },
            {
                position: 6,
                priorityLevel: 8,
                label: t("expressionOfInterest.monitor.table.vatRegistrationNumber"),
                name: "vatRegistrationNumber",
                minWidth: 150,
            },
            {
                position: 7,
                priorityLevel: 9,
                label: t("expressionOfInterest.monitor.table.emailAddress"),
                name: "emailAddress",
                minWidth: 250,
            },
            {
                position: 8,
                priorityLevel: 10,
                label: t("expressionOfInterest.monitor.table.companyAddress"),
                name: "companyAddress",
                minWidth: 300,
            },
            {
                position: 9,
                priorityLevel: 11,
                label: t("expressionOfInterest.monitor.table.postCode"),
                name: "postCode",
                minWidth: 150,
            },
            {
                position: 10,
                priorityLevel: 12,
                label: t("expressionOfInterest.monitor.table.townCity"),
                name: "townCity",
                minWidth: 150,
            },
            {
                position: 11,
                priorityLevel: 13,
                label: t("expressionOfInterest.monitor.table.telephoneNumber"),
                name: "telephoneNumber",
                minWidth: 200,
            },
            {
                position: 12,
                priorityLevel: 14,
                label: t("expressionOfInterest.monitor.table.companySize"),
                name: "companySize",
                minWidth: 150,
            },
            {
                position: 13,
                priorityLevel: 15,
                label: t("expressionOfInterest.monitor.table.companySummary"),
                name: "companySummary",
                minWidth: 10000,
            },
            {
                position: 14,
                priorityLevel: 2,
                name: "supplierExistsOnPlatorm",
                minWidth: 50,
                render: (supplier: ExpressionOfInterestResponseDto) =>
                    supplier.supplierExistsOnPlatform && (
                        <Icon
                            icon={faFlagPennant}
                            alt={t("expressionOfInterest.monitor.supplierExists")}
                            size="small"
                            testid={`${stringService.toKebabCase(supplier.companyName)}-flag`}
                        />
                    ),
            },
        ];

        if (deadlineHasPassed && selectSupplier) {
            columnsConfiguration.push({
                position: 15,
                priorityLevel: 2,
                name: "select",
                minWidth: 150,
                render: (supplier: ExpressionOfInterestResponseDto) => (
                    <div className={classes.alignRight}>
                        {supplier.isSelected ? (
                            <Button
                                className={classes.selectSupplierButton}
                                label={t("common.selected")}
                                size="small"
                                color="success"
                                icon={faCheck}
                                onHoverIcon={null}
                                onHoverLabel={t("common.unselect")}
                                onClick={() => selectSupplier(supplier)}
                                disabled={isSelectingSupplier}
                                testid={`${stringService.toKebabCase(supplier.companyName)}-unselect-button`}
                            />
                        ) : (
                            <Button
                                label={t("common.select")}
                                size="small"
                                onClick={() => selectSupplier(supplier)}
                                disabled={isSelectingSupplier}
                                testid={`${stringService.toKebabCase(supplier.companyName)}-select-button`}
                            />
                        )}
                    </div>
                ),
            });
        }

        return columnsConfiguration;
    }, [classes.alignRight, classes.selectSupplierButton, deadlineHasPassed, isSelectingSupplier, selectSupplier, t]);

    useEffect(() => {
        const listReferenceData = async () => {
            const companySizeResponse = await listCompanySizeTypes();
            if (companySizeResponse.status === 200 && !(companySizeResponse.data instanceof TransactionErrorDto)) {
                setCompanySizes(
                    companySizeResponse.data.data.map((companySize) => ({
                        value: companySize.id.toString(),
                        label: getCompanySizeRange(companySize.name as CompanySizeV2),
                    })),
                );
            }

            const industryTypeResponse = await listIndustryTypes();
            if (industryTypeResponse.status === 200 && !(industryTypeResponse.data instanceof TransactionErrorDto)) {
                setIndustryTypes(
                    industryTypeResponse.data.data.map((industryType) => ({
                        value: industryType.id.toString(),
                        label: industryType.name,
                    })),
                );
            }
        };

        if (companySizes.length === 0 || industryTypes.length === 0) {
            listReferenceData();
        }
    }, [companySizes.length, industryTypes.length]);

    return {
        columns,
        companySizes,
        industryTypes,
    };
};

export default useSuppliersConfiguration;
