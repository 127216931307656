import { useToaster } from "@maistro/components";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
    useGetVettingInformationQuery,
    useUpdateVettingInformationMutation,
} from "store/api/companyAdditionalInformationApi";
import { VettingInformationDto } from "types/dtos/company/vettingInformation/VettingInformationDto";

const useVettingInformation = (companyUuid: string) => {
    const { t } = useTranslation();
    const toast = useToaster();

    const {
        data: vettingInformation,
        isLoading: isVettingInformationLoading,
        refetch,
    } = useGetVettingInformationQuery(companyUuid);
    const [updateVettingInformation, { isLoading: isSaving }] = useUpdateVettingInformationMutation();

    const submit = useCallback(
        (dto: VettingInformationDto) => {
            updateVettingInformation(dto).then(() => {
                toast.success(t("companyVettingQuestionnaire.updateSuccess"));
                refetch();
            });
        },
        [refetch, t, toast, updateVettingInformation],
    );

    return {
        isLoading: isVettingInformationLoading,
        isSaving,
        submit,
        vettingInformation,
    };
};

export default useVettingInformation;
