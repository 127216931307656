import { User } from "oidc-client-ts";
import React, { useCallback, useEffect } from "react";

import useAppDispatch from "hooks/useAppDispatch";
import { userError, userLoaded, userLoading, userUnloaded } from "store/oidcSlice";
import userManager from "features/auth/oidc/userManager";

interface OidcProviderProps {
    children: React.ReactNode;
}

const OidcProvider: React.FC<OidcProviderProps> = ({ children }) => {
    const dispatch = useAppDispatch();

    const onUserLoaded = useCallback(
        (user: User) => {
            dispatch(userLoaded(user));
        },
        [dispatch],
    );

    const onUserError = useCallback(
        (error: Error) => {
            dispatch(userError(error));
        },
        [dispatch],
    );

    const onUserUnloaded = useCallback(() => {
        dispatch(userUnloaded());
    }, [dispatch]);

    dispatch(userLoading());
    userManager
        .getUser()
        .then((user) => {
            if (user && !user.expired) {
                dispatch(userLoaded(user));
            } else if (!user || user.expired) {
                dispatch(userUnloaded());
            }
        })
        .catch((error) => {
            dispatch(userError(error as Error));
        });

    useEffect(() => {
        userManager.events.addUserLoaded(onUserLoaded);
        userManager.events.addSilentRenewError(onUserError);
        userManager.events.addAccessTokenExpired(onUserUnloaded);
        userManager.events.addAccessTokenExpired(onUserUnloaded);
        userManager.events.addAccessTokenExpiring(onUserUnloaded);
        userManager.events.addUserUnloaded(onUserUnloaded);
        userManager.events.addUserSignedOut(onUserUnloaded);

        return () => {
            userManager.events.removeUserLoaded(onUserLoaded);
            userManager.events.removeSilentRenewError(onUserError);
            userManager.events.removeAccessTokenExpired(onUserUnloaded);
            userManager.events.removeAccessTokenExpired(onUserUnloaded);
            userManager.events.removeAccessTokenExpiring(onUserUnloaded);
            userManager.events.removeUserUnloaded(onUserUnloaded);
            userManager.events.removeUserSignedOut(onUserUnloaded);
        };
    }, [onUserLoaded, onUserError, onUserUnloaded]);

    return children;
};

export default OidcProvider;
