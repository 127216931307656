import React from "react";
import { createUseStyles } from "react-jss";
import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";
import QuestionRow, { IQuestionRowProps } from "features/project/components/tender-questions/QuestionRow";

const useStyles = createUseStyles((theme: ITheme) => ({
    gridContainer: {
        display: "grid",
        width: "100%",
        gridTemplateAreas: '"copy copy" "icons completed"',
        alignItems: "center",
        gap: theme.spacing.xSmall,
    },
    iconsContainer: {
        display: "flex",
        gridArea: "icons",
        justifyContent: "flex-start",
        gap: theme.spacing.small,
    },
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        gridContainer: {
            gridTemplateAreas: '"completed copy copy copy copy icons"',
            gridTemplateColumns: ".20fr repeat(4,1fr) 0.5fr",
        },
        iconsContainer: {
            minWidth: 90,
            justifyContent: "space-between",
        },
    },
    [`@media (min-width: ${theme.breakpoints.xl}px)`]: {
        gridContainer: {
            gridTemplateAreas: '"completed copy copy copy copy copy copy icons"',
            gridTemplateColumns: ".15fr repeat(6, 1fr) 0.5fr",
        },
    },
}));

interface ISupplierQuestionRowProps extends ICommonProps {
    questionText: string;
    isMandatoryQuestion?: boolean;
    hasAttachments?: boolean;
    isComplete?: boolean;
}

const SupplierQuestionRow: React.FC<ISupplierQuestionRowProps> = (props) => {
    const classes = useStyles();
    const questionProps = {
        ...props,
        hideActionIcons: true,
        gridClassName: classes.gridContainer,
        iconsClassName: classes.iconsContainer,
        useTooltips: true,
    } as IQuestionRowProps;

    return <QuestionRow {...questionProps} />;
};

export default SupplierQuestionRow;
