import { BooleanOptionsField, CheckboxField, FieldLabel, Heading, SelectField, TextField } from "@maistro/components";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import DisplayVettingFileUploadField from "features/company/profile/vetting/files/DisplayVettingFileUploadField";
import { IVettingQuestionnaireForm } from "features/company/profile/vetting/IVettingQuestionnaireForm";
import useSectionStyles from "features/company/profile/vetting/sections/useSectionStyles";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";
import { VettingInformationKeys } from "types/dtos/company/vettingInformation/VettingInformationDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    checkboxLabel: {
        display: "inline-block",
        marginBottom: theme.spacing.small,
    },
    checkboxContainer: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.small,
    },
}));

export const healthAndSafetyValidationSchema = () =>
    Yup.object({
        hasHealthAndSafetyPolicy: Yup.boolean().nullable(),
        hasSpecificTargetsAndActions: Yup.string().nullable(),
        hasRegularTraining: Yup.string().nullable(),
        hasExternalReporting: Yup.string().nullable(),
        hasResponsiblePerson: Yup.boolean().nullable(),
        healthAndSafetyPersonName: Yup.string().nullable(),
        hasDrugAndAlcoholPolicy: Yup.boolean().nullable(),
        numberOfReportableAccidents: Yup.string().nullable(),
        hasOccupationalHealthArrangements: Yup.boolean().nullable(),
    });

interface IHealthAndSafetySubSectionProps {
    healthAndSafetyPolicyFiles?: FileDto[];
    drugAndAlcoholPolicyFiles?: FileDto[];
    setHealthAndSafetyPolicyFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    setDrugAndAlcoholPolicyFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
}

const HealthAndSafetySubSection: React.FC<IHealthAndSafetySubSectionProps> = ({
    healthAndSafetyPolicyFiles,
    drugAndAlcoholPolicyFiles,
    setHealthAndSafetyPolicyFiles,
    setDrugAndAlcoholPolicyFiles,
}) => {
    const sharedClasses = useSectionStyles();
    const classes = useStyles();
    const { t } = useTranslation();

    const [healthFileIsUploading, setHealthFileIsUploading] = useState<boolean>(false);
    const [drugsFileIsUploading, setDrugsFileIsUploading] = useState<boolean>(false);

    const { values } = useFormikContext<IVettingQuestionnaireForm>();

    return (
        <div id={VettingInformationKeys.healthAndSafety}>
            <Heading variant="h4">
                {t("companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.healthAndSafety.title")}
            </Heading>
            <ol className={sharedClasses.listStyle} type="1">
                <li>
                    <BooleanOptionsField
                        name="hasHealthAndSafetyPolicy"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.healthAndSafety.policy.label",
                        )}
                        testid="has-health-and-safety-policy-options-field"
                    />
                    {values.hasHealthAndSafetyPolicy && (
                        <div className={sharedClasses.marginTop}>
                            <DisplayVettingFileUploadField
                                testid="health-and-safety-policy-file-upload-field"
                                files={healthAndSafetyPolicyFiles}
                                setFiles={setHealthAndSafetyPolicyFiles}
                                fileIsUploading={healthFileIsUploading}
                                setFileIsUploading={setHealthFileIsUploading}
                                name="healthPolicyFiles"
                            />
                        </div>
                    )}
                </li>
                <li>
                    <FieldLabel
                        className={classes.checkboxLabel}
                        name="policyInclusions"
                        label={t(
                            "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.healthAndSafety.policy.policyInclusions",
                        )}
                        required
                    />
                    <div className={classes.checkboxContainer}>
                        <CheckboxField
                            name="hasSpecificTargetsAndActions"
                            label={t(
                                "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.healthAndSafety.policy.targetsAndActions",
                            )}
                            testid="policy-inclusions-targets-and-actions-option-field"
                        />
                        <CheckboxField
                            name="hasRegularTraining"
                            label={t(
                                "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.healthAndSafety.policy.regularTraining",
                            )}
                            testid="policy-inclusions-regular-training-option-field"
                        />
                        <CheckboxField
                            name="hasExternalReporting"
                            label={t(
                                "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.healthAndSafety.policy.externalReporting",
                            )}
                            testid="policy-inclusions-external-reporting-option-field"
                        />
                    </div>
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasResponsiblePerson"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.healthAndSafety.personResponsible.label",
                        )}
                        testid="has-responsible-person-options-field"
                    />
                    {values.hasResponsiblePerson && (
                        <div className={sharedClasses.marginTop}>
                            <TextField
                                name="healthAndSafetyPersonName"
                                label={t(
                                    "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.healthAndSafety.personResponsible.field",
                                )}
                                testid="person-responsible-field"
                            />
                        </div>
                    )}
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasDrugAndAlcoholPolicy"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.healthAndSafety.drugAndAlcoholPolicy.label",
                        )}
                        testid="has-drug-policy-options-field"
                    />
                    {values.hasDrugAndAlcoholPolicy && (
                        <div className={sharedClasses.marginTop}>
                            <DisplayVettingFileUploadField
                                testid="drugs-and-alcohol-policy-file-upload-field"
                                files={drugAndAlcoholPolicyFiles}
                                setFiles={setDrugAndAlcoholPolicyFiles}
                                fileIsUploading={drugsFileIsUploading}
                                setFileIsUploading={setDrugsFileIsUploading}
                                name="drugsPolicyFiles"
                            />
                        </div>
                    )}
                </li>
                <li>
                    <SelectField
                        testid="reportable-accidents-selector"
                        label={t(
                            "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.healthAndSafety.incidentsReported",
                        )}
                        name="numberOfReportableAccidents"
                        required
                        options={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10+"].map((option) => ({
                            value: option,
                            label: option,
                        }))}
                        selectClassName={sharedClasses.selectContainer}
                    />
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasOccupationalHealthArrangements"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.healthAndSafety.occupationalHealth.label",
                        )}
                        tooltip={t(
                            "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.healthAndSafety.occupationalHealth.tooltip",
                        )}
                        testid="has-occupational-health-arrangements-options-field"
                    />
                </li>
            </ol>
        </div>
    );
};

export default HealthAndSafetySubSection;
