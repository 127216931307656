import { faCheck, faFilter, faPlus, faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import {
    Button,
    Copy,
    Drawer,
    Heading,
    IOption,
    IPaginationState,
    Pagination,
    Table,
    TextButton,
    Tooltip,
    useToaster,
} from "@maistro/components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import { searchCompanySuppliers } from "api/company/companyApi";
import { BuyerSupplierRelationshipPill, MaistroRating } from "components";
import SearchSelect from "components/SearchSelect/SearchSelect";
import NoMatches from "features/project/supplierMatching/NoMatches";
import FilterList from "features/project/supplierMatching/filters/FilterList";
import { IProjectInformation } from "features/project/types";
import useCurrentUser from "hooks/useCurrentUser";
import usePolicies from "hooks/usePolicies";
import ReactGA from "react-ga4";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { IFilterValues } from "store/supplierMatchingSlice";
import { ITheme } from "styles/themes/types";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { PotentialSupplierDto } from "types/dtos/projectSuppliers/PotentialSupplierDto";
import { getCompanySizeRange } from "types/enums/companies/CompanySizeV2";
import SupplierRelationshipStatus from "types/enums/companies/SupplierRelationshipStatus";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    copy: {
        marginBottom: theme.spacing.medium,
    },
    buttonControl: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.large,
        paddingTop: theme.spacing.medium,
    },
    searchContainer: {
        display: "grid",
        gridTemplateAreas: '"searchBar searchButton filterButton"',
        gridTemplateColumns: "minmax(0, 300px)",
        gap: theme.spacing.medium,
    },
    searchBar: {
        gridArea: "searchBar",
        alignItems: "center",
        backgroundColor: theme.colors.lightGray,
    },
    searchButton: {
        gridArea: "searchButton",
        alignContent: "center",
        padding: `${theme.spacing.xSmall}px ${theme.spacing.medium}px`,
        maxHeight: "38px",
        maxWidth: "150px",
    },
    pagination: {
        marginTop: theme.spacing.small,
    },
    block: {
        display: "block",
    },
    filterButton: {
        gridArea: "filterButton",
        justifyContent: "flex-end",
    },
    alignRight: {
        textAlign: "right",
    },
    content: {
        marginTop: theme.spacing.medium,
    },
    paddingTop: {
        paddingTop: theme.spacing.small,
    },
    supplierListPills: {
        display: "flex",
        gap: theme.spacing.xSmall,
    },
    selectSupplierButton: {
        minWidth: 100,
    },
}));

interface ISupplierMatchingDisplayProps {
    hasMatches: boolean;
    suppliers: Array<PotentialSupplierDto>;
    filteredSuppliers: Array<PotentialSupplierDto>;
    selectedSuppliers: Array<string>;
    selectSupplier: (supplierUuid: string) => void;
    unselectSupplier: (supplierUuid: string) => void;
    projectUuid: string;
    cancelProject: () => void;
    projectInformation: IProjectInformation;
    updateFilters: (values: IFilterValues) => void;
    handleAddNonMatchedSupplier: (supplier?: IOption) => void;
    suppliersPagination: IPaginationState;
    onNavigateToPage: (page: number) => void;
    isSupplierListUpdating: boolean;
}

const SupplierMatchingDisplay: React.FC<ISupplierMatchingDisplayProps> = ({
    suppliers,
    filteredSuppliers,
    selectSupplier,
    unselectSupplier,
    selectedSuppliers,
    projectUuid,
    cancelProject,
    projectInformation,
    updateFilters,
    handleAddNonMatchedSupplier,
    hasMatches,
    suppliersPagination,
    onNavigateToPage,
    isSupplierListUpdating,
}) => {
    const classes = useStyles();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [initialValues, setInitialValues] = useState<IFilterValues>({});
    const [companySuppliers, setCompanySuppliers] = useState<Array<IOption>>([]);
    const [selectedSupplierSearch, setSelectedSupplierSearch] = useState<IOption>();
    const [searchText, setSearchText] = useState("");

    const { t } = useTranslation();
    const navigate = useNavigate();
    const toast = useToaster();

    const { myCompanyUuid: buyerCompanyUuid, myUuid, userIsMaistro } = useCurrentUser();

    const filter = (values: IFilterValues) => {
        setInitialValues(values);
        setIsDrawerOpen(false);
        updateFilters(values);
    };

    const [companyUuid, setCompanyUuid] = useState(buyerCompanyUuid);
    const [userUuid, setUserUuid] = useState(myUuid);

    useEffect(() => {
        if (userIsMaistro) {
            setCompanyUuid(projectInformation.clientCompanyUuid);
            setUserUuid(projectInformation.sponsorUserUuid ?? "");
        }
    }, [projectInformation.clientCompanyUuid, projectInformation.sponsorUserUuid, userIsMaistro]);

    const { unapprovedTieringStatuses } = usePolicies(companyUuid, userUuid);

    useEffect(() => {
        let projectCompanyUuid;
        if (projectInformation.clientCompanyUuid) {
            projectCompanyUuid = projectInformation.clientCompanyUuid;
        } else if (buyerCompanyUuid) {
            projectCompanyUuid = buyerCompanyUuid;
        }

        if (projectCompanyUuid) {
            searchCompanySuppliers({
                companyUuid: projectCompanyUuid,
                textSearch: "",
                relationshipStatuses: [SupplierRelationshipStatus.Approved, SupplierRelationshipStatus.Trial],
                includeInactive: false,
            }).then((response) => {
                if (response.data instanceof TransactionErrorDto) {
                    toast.error("Could not load suppliers for project");
                } else if (response.data.companySuppliers) {
                    const searchedSuppliers = response.data.companySuppliers
                        .filter((cs) => filteredSuppliers.map((s) => s.companyUuid).find((s) => s === cs.id) == null)
                        .map((s) => {
                            return { value: s.id, label: s.value };
                        });
                    setCompanySuppliers(searchedSuppliers);
                } else {
                    toast.error("Could not load suppliers for project");
                }
            });
        }
    }, [buyerCompanyUuid, projectInformation, filteredSuppliers, toast]);

    const onAddSupplier = () => {
        if (selectedSupplierSearch) {
            handleAddNonMatchedSupplier(selectedSupplierSearch);
            setSearchText("");
            setSelectedSupplierSearch(undefined);
        }
    };

    const viewSupplierProfile = (supplierUuid: string) => {
        navigate(buildPath(routes.company.supplierCompanyProfile, { companyUuid: supplierUuid }), {
            state: {
                route: buildRoute(routes.projects.matching, { projectUuid }),
                projectUuid,
            },
        });
    };

    const hideResults = !hasMatches && selectedSuppliers.length === 0;
    const columns = [
        {
            label: t("projectMatchingScreen.table.supplierName"),
            name: "companyName",
            position: 1,
            priorityLevel: 2,
            minWidth: 200,
            contentWrap: true,
            render: (supplier: PotentialSupplierDto) => (
                <>
                    <TextButton
                        label={supplier.companyName}
                        className={classes.block}
                        onClick={() => {
                            ReactGA.event({
                                category: ga4Category.Button,
                                action: ga4Action.ButtonClick,
                                label: `Match - Supplier - `.concat(supplier.companyName),
                            });
                            viewSupplierProfile(supplier.companyUuid);
                        }}
                        testid={`${supplier.companyUuid}-name`}
                    />
                    <Tooltip
                        content={t("projectMatchingScreen.tooltips.matchingStars")}
                        testid={`${supplier.companyUuid}-rating-tooltip`}
                        hideIcon
                    >
                        <MaistroRating
                            currentRating={Math.ceil(supplier.matchScore * 5)}
                            testid={`${supplier.companyUuid}-match-rating`}
                        />
                    </Tooltip>
                </>
            ),
        },
        {
            label: t("companySuppliers.table.companySize"),
            name: "companySize",
            position: 2,
            priorityLevel: 6,
            minWidth: 130,
            render: (supplier: PotentialSupplierDto) => {
                return getCompanySizeRange(supplier.companySize);
            },
        },
        {
            label: t("companySuppliers.table.relationshipStatus"),
            tooltip: t("companySuppliers.tooltips.relationshipStatus"),
            name: "supplierRelationshipStatus",
            position: 3,
            priorityLevel: 5,
            minWidth: 150,
            render: (supplier: PotentialSupplierDto) => (
                <BuyerSupplierRelationshipPill
                    status={supplier.supplierRelationshipStatus}
                    companyUuid={supplier.companyUuid}
                    type="relationship"
                />
            ),
        },
        {
            label: t("companySuppliers.table.tieringStatus"),
            tooltip: t("companySuppliers.tooltips.tieringStatus"),
            name: "supplierTieringStatus",
            position: 4,
            priorityLevel: 4,
            minWidth: 150,
            render: (supplier: PotentialSupplierDto) => (
                <BuyerSupplierRelationshipPill
                    status={supplier.supplierTieringStatus}
                    companyUuid={supplier.companyUuid}
                    type="tiering"
                />
            ),
        },
        {
            name: "supplierAdvisory",
            position: 5,
            priorityLevel: 3,
            minWidth: 100,
            render: (supplier: PotentialSupplierDto) =>
                unapprovedTieringStatuses.includes(supplier.supplierTieringStatus) && (
                    <Tooltip
                        content={t("companySuppliers.supplierAdvisory.tooltip")}
                        testid="tiering-warning-tooltip"
                        icon={faTriangleExclamation}
                        hoverIcon={faTriangleExclamation}
                        position="center"
                        color="white"
                    />
                ),
        },
        {
            name: "actions",
            position: 6,
            priorityLevel: 1,
            minWidth: 150,
            render: (supplier: PotentialSupplierDto) => (
                <div className={classes.alignRight}>
                    {supplier.isSelected ? (
                        <Button
                            className={classes.selectSupplierButton}
                            label={t("common.selected")}
                            size="small"
                            color="success"
                            icon={faCheck}
                            onHoverIcon={null}
                            onHoverLabel={t("common.unselect")}
                            onClick={() => {
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: `Match - Unselect Supplier - `.concat(supplier.companyName),
                                });
                                unselectSupplier(supplier.companyUuid);
                            }}
                            disabled={isSupplierListUpdating}
                            testid={`${supplier.companyUuid}-unselect-button`}
                        />
                    ) : (
                        <Button
                            label={t("common.select")}
                            size="small"
                            onClick={() => {
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: `Match - Select Supplier - `.concat(supplier.companyName),
                                });
                                selectSupplier(supplier.companyUuid);
                            }}
                            disabled={isSupplierListUpdating}
                            testid={`${supplier.companyUuid}-select-button`}
                        />
                    )}
                </div>
            ),
        },
    ];

    const resetFilter = () => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Match - Reset Filter`,
        });
        filter({
            matchRating: undefined,
            relationshipStatuses: undefined,
            companySizes: undefined,
        });
    };

    const noResultsDisplay = () => (
        <Heading className={classes.paddingTop} variant="h5" testid="no-results-copy">
            <>
                {t("projectMatchingScreen.noFilterResults.description")}
                &nbsp;
                <TextButton
                    label={t("projectMatchingScreen.noFilterResults.button")}
                    onClick={resetFilter}
                    testid="reset-filter-button"
                />
            </>
        </Heading>
    );

    const data = suppliers.map((supplier) => ({
        ...supplier,
        isSelected: selectedSuppliers.includes(supplier.companyUuid),
    }));
    useEffect(() => {
        if (hideResults)
            ReactGA.event({
                category: ga4Category.Search,
                action: ga4Action.Search,
                label: "NoMatches - ".concat(projectInformation.projectName || "No Name"),
            });
    }, [projectInformation.projectName, hideResults]);
    return (
        <React.Fragment>
            {hideResults ? (
                <Copy className={classes.copy}>{t("projectMatchingScreen.noMatches.description")}</Copy>
            ) : (
                <Copy className={classes.copy}>{t("projectMatchingScreen.copy")}</Copy>
            )}
            <div className={classes.searchContainer}>
                <div className={classes.searchBar}>
                    <SearchSelect
                        name="SearchSuppliers"
                        placeholder="Search Suppliers"
                        options={companySuppliers}
                        testid="company-suppliers-search-bar"
                        onSelect={setSelectedSupplierSearch}
                        searchText={searchText}
                        setSearchText={setSearchText}
                    />
                </div>
                {selectedSupplierSearch && (
                    <Button
                        className={classes.searchButton}
                        label={t("common.add")}
                        variant="outline"
                        onClick={() => {
                            ReactGA.event({
                                category: ga4Category.Button,
                                action: ga4Action.ButtonClick,
                                label: "Add supplier",
                            });
                            onAddSupplier();
                        }}
                        icon={faPlus}
                        testid="add-supplier-button"
                    />
                )}
                {!hideResults && (
                    <TextButton
                        className={classes.filterButton}
                        label={t("common.filter")}
                        icon={faFilter}
                        onClick={() => {
                            ReactGA.event({
                                category: ga4Category.Button,
                                action: ga4Action.ButtonClick,
                                label: "Supplier Matching Filter - ".concat(projectInformation.projectName),
                            });
                            setIsDrawerOpen(true);
                        }}
                        testid="open-filter-drawer-button"
                    />
                )}
            </div>
            <div className={classes.content}>
                {hideResults ? (
                    <NoMatches projectUuid={projectUuid} cancelProject={cancelProject} />
                ) : (
                    <React.Fragment>
                        <Table
                            columns={columns}
                            data={data}
                            noDataDisplay={noResultsDisplay()}
                            testid="supplier-matching-table"
                        />
                        <Pagination
                            className={classes.pagination}
                            navigateToPage={onNavigateToPage}
                            paginationState={suppliersPagination}
                            testid="supplier-matching-pagination"
                        />
                        <div className={classes.buttonControl}>
                            <TextButton
                                label={t("projectBrief.cancel")}
                                onClick={() => {
                                    ReactGA.event({
                                        category: ga4Category.Button,
                                        action: ga4Action.ButtonClick,
                                        label: `Match - Cancel Project`,
                                    });
                                    cancelProject();
                                }}
                                testid="cancel-project-button"
                            />
                        </div>
                    </React.Fragment>
                )}
            </div>
            <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} testid="filter-suppliers-drawer">
                <FilterList submit={filter} values={initialValues} />
            </Drawer>
        </React.Fragment>
    );
};

export default SupplierMatchingDisplay;
