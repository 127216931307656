import { faPlus, faSync } from "@fortawesome/pro-regular-svg-icons";
import { Button, Copy, TextButton } from "@maistro/components";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import sortingAlgorithms from "features/helpers/sortingAlgorithms";
import AmendDeadlineForm from "features/project/components/AmendDeadline/AmendDeadlineForm";
import SupplierScoreSummary from "features/project/components/supplier-score-summary/SupplierScoreSummary";
import useProject from "features/project/hooks/useProject";
import { useTenderCriteria } from "features/project/hooks/useTenderCriteria";
import SupplierTable from "features/project/monitor/SupplierTable";
import useMonitorColumns from "features/project/monitor/hooks/useMonitorColumns";
import useMonitorStyles from "features/project/monitor/hooks/useMonitorStyles";
import { IProjectInformation } from "features/project/types";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import dateTimeService from "services/dateTimeService";
import { BasicCompanyDto } from "types/dtos/company/BasicCompanyDto";
import { SubmissionAdditionalInformationDto } from "types/dtos/projects/supplierSubmissions/SubmissionAdditionalInformationDto";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import ProjectStatus from "types/enums/projects/ProjectStatus";

interface IMonitorDisplayConditions {
    isQuote: boolean;
    isScoringAvailable: boolean;
    hasDeadlinePassed: boolean;
    hasSubmittedResponse: boolean;
    canUpdateProject: boolean;
    isScoringCompleteForUser: boolean;
}

interface DisplayData extends SupplierSubmissionResponseDto {
    id: string;
    supplierCompanyName: string;
    matchRating: number;
    expandRowSummary?: boolean;
    extraContent?: JSX.Element;
}

interface IMonitorDisplayProps {
    suppliers: Array<SupplierSubmissionResponseDto>;
    project: IProjectInformation;
    refreshSuppliers: () => void;
    updateResponseDeadline: (deadline: Date) => void;
    setDocumentSupplier: Dispatch<SetStateAction<BasicCompanyDto | undefined>>;
    setAdditionalInformation: Dispatch<SetStateAction<SubmissionAdditionalInformationDto | undefined>>;
    conditions: IMonitorDisplayConditions;
    setIsAddSupplierDrawerOpen: Dispatch<SetStateAction<boolean>>;
    fetchProjectFiles: () => void;
}

const MonitorDisplay: React.FC<IMonitorDisplayProps> = (props) => {
    const sharedClasses = useMonitorStyles();
    const [displayData, setDisplayData] = useState<DisplayData[]>();
    const { sortSuppliersByMatchRatingDesc, sortSuppliersByScore } = sortingAlgorithms();

    const { myUuid: userUuid } = useCurrentUser();
    const { projectInformation } = useProject();
    const isProjectManager = userUuid === projectInformation.managerUuid;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        suppliers,
        project,
        refreshSuppliers,
        updateResponseDeadline,
        conditions,
        setAdditionalInformation,
        setDocumentSupplier,
        setIsAddSupplierDrawerOpen,
        fetchProjectFiles,
    } = props;

    const { isQuote, isScoringAvailable, hasDeadlinePassed, canUpdateProject, isScoringCompleteForUser } = conditions;

    const { questions, sections, totalContributorScorers, contributorsForSuppliers } = useTenderCriteria({
        projectUuid: projectInformation.uuid,
    });

    useEffect(() => {
        const data = suppliers.map((supplier) => {
            let extraData = {};
            if (
                supplier.status === "Submitted" &&
                (!isScoringCompleteForUser || isProjectManager) &&
                hasDeadlinePassed &&
                projectInformation.type === "Tender"
            ) {
                const filteredQuestions = questions.map((question) => ({
                    ...question,
                    answers: question.answers.filter((answer) => answer.companyUuid === supplier.supplierCompanyUuid),
                }));

                const contributorsForSupplier = contributorsForSuppliers.find(
                    (contributors) => contributors.supplierUuid === supplier.supplierCompanyUuid,
                );

                const contributorsCount = contributorsForSupplier
                    ? contributorsForSupplier.contributorsUuids.length
                    : 0;

                const summaryContent = (
                    <SupplierScoreSummary
                        sections={sections}
                        questions={filteredQuestions}
                        completedContributorScorers={contributorsCount}
                        totalContributorScorers={totalContributorScorers}
                        isProjectManager={isProjectManager}
                    />
                );

                extraData = {
                    expandRowSummary: false,
                    extraContent: summaryContent,
                };
            }

            return {
                ...supplier,
                id: supplier.supplierCompanyUuid,
                supplierCompanyName: supplier._embedded.supplierCompanyName,
                matchRating: supplier._included.matchRating * 5,
                ...extraData,
            };
        });
        setDisplayData(data);
    }, [
        contributorsForSuppliers,
        hasDeadlinePassed,
        isProjectManager,
        isScoringCompleteForUser,
        projectInformation.type,
        questions,
        sections,
        suppliers,
        totalContributorScorers,
    ]);

    const navigateToScoring = () => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: "Project Responses - Score Tender Responses",
        });
        navigate(buildPath(routes.projects.tenderSummary, { projectUuid: project.uuid }));
    };

    const navigateToSingleSupplierScoring = (id: string) => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: "Project Responses - Score Tender Responses",
        });
        navigate(buildPath(routes.projects.tenderSummaryBySupplier, { projectUuid: project.uuid, supplierUuid: id }));
    };

    const toggleExpand = (id: string) => {
        setDisplayData((prevData) =>
            (prevData || []).map((item) =>
                item.id === id ? { ...item, expandRowSummary: !item.expandRowSummary } : item,
            ),
        );
    };

    const { columnsConfiguration: columns } = useMonitorColumns({
        project,
        canAwardSupplier: false,
        setDocumentSupplier,
        setAdditionalInformation,
        navigationRoute: routes.projects.monitor,
        navigateToSingleSupplierScoring,
        toggleExpand,
        isProjectManager,
        isScoringCompleteForUser,
        hasDeadlinePassed,
    });

    const isReadOnly = project.status === ProjectStatus.Approval;
    const amendResponseDeadline = (deadline: Date) => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: "Project Responses - Amend Date",
        });
        updateResponseDeadline(deadline);
    };
    return (
        <React.Fragment>
            <Copy className={sharedClasses.copy}>
                {t(`monitorScreen.subtitleWaiting${isQuote ? "Quote" : "Tender"}`)}
            </Copy>
            <div className={sharedClasses.actions}>
                <AmendDeadlineForm
                    label={t("monitorScreen.responseDeadline")}
                    deadline={project?.tenderResponseDeadline}
                    deadlineHasPassed={hasDeadlinePassed}
                    canAmendDate={canUpdateProject && !isReadOnly}
                    canAmendToPast={!isReadOnly}
                    updateDeadline={amendResponseDeadline}
                />
                <div className={sharedClasses.actionButtons}>
                    {canUpdateProject && !hasDeadlinePassed && (
                        <Button
                            label={t("monitorScreen.addSupplier")}
                            size="small"
                            onClick={() => {
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "Project Responses - Add Supplier",
                                });
                                setIsAddSupplierDrawerOpen(true);
                            }}
                            icon={faPlus}
                            testid="add-suppliers-button"
                        />
                    )}
                    {isScoringAvailable && hasDeadlinePassed && (
                        <Button
                            label={t("monitorScreen.scoreTenderResponses")}
                            onClick={navigateToScoring}
                            size="small"
                            testid="score-responses-button"
                        />
                    )}
                </div>
            </div>
            <div className={sharedClasses.deadlineAfterContractStart}>
                {!!project?.targetProjectStartDate &&
                    dateTimeService.getTime(project?.targetProjectStartDate) <
                        dateTimeService.getTime(project?.tenderResponseDeadline) && (
                        <span>{t("monitorScreen.contractStartDateBeforeDeadline")}</span>
                    )}
            </div>
            <SupplierTable
                displayData={displayData}
                columns={columns}
                canAwardSupplier={false}
                sortingAlgorithm={isQuote ? sortSuppliersByMatchRatingDesc : sortSuppliersByScore}
            />
            <div className={sharedClasses.refresh}>
                <TextButton
                    label={t("monitorScreen.refreshTable")}
                    icon={faSync}
                    onClick={() => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: "Project Responses - Refresh Table",
                        });
                        refreshSuppliers();
                        fetchProjectFiles();
                    }}
                    testid="refresh-table-button"
                />
            </div>
        </React.Fragment>
    );
};

export default MonitorDisplay;
