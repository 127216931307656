import { faPaperclip, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Button, ITableColumn, Icon, Label, MeatballMenu, Table } from "@maistro/components";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import AddOrEditAccreditationDrawer from "features/company/profile/vetting/sections/ethicsSubSections/AddOrEditAccreditationDrawer";
import ReactGA from "react-ga4";
import dateTimeService from "services/dateTimeService";
import { ITheme } from "styles/themes/types";
import { AccreditationDto } from "types/dtos/company/vettingInformation/AccreditationDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    accreditations: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
        marginTop: theme.spacing.small,
    },
}));

interface IAccreditationsProps {
    companyAccreditations: AccreditationDto[];
    setCompanyAccreditations: React.Dispatch<React.SetStateAction<AccreditationDto[]>>;
}

const Accreditations: React.FC<IAccreditationsProps> = ({ companyAccreditations, setCompanyAccreditations }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [isAccreditationDrawerOpen, setIsAccreditationDrawerOpen] = useState(false);
    const [selectedAccreditation, setSelectedAccreditation] = useState<AccreditationDto>();

    const columns: ITableColumn[] = useMemo(
        () => [
            {
                position: 1,
                priorityLevel: 1,
                label: t("companyVettingQuestionnaire.sections.ethics.quality.accreditations.form.typeId.label"),
                name: "typeId",
                render: (accreditation: AccreditationDto) =>
                    t(
                        `companyVettingQuestionnaire.sections.ethics.quality.accreditations.form.typeId.options.${accreditation.typeId}`,
                    ),
                minWidth: 50,
            },
            {
                position: 2,
                priorityLevel: 2,
                label: t("companyVettingQuestionnaire.sections.ethics.quality.accreditations.form.otherTypeName.label"),
                name: "otherTypeName",
                minWidth: 150,
            },
            {
                position: 3,
                priorityLevel: 3,
                label: t("companyVettingQuestionnaire.sections.ethics.quality.accreditations.form.expiryDate.label"),
                name: "expiryDate",
                render: (accreditation: AccreditationDto) => {
                    if (dateTimeService.dateIsInPast(accreditation.expiryDate)) {
                        return t("common.expired");
                    }
                    return dateTimeService.toShortDateFormat(accreditation.expiryDate);
                },
                minWidth: 125,
            },
            {
                position: 4,
                priorityLevel: 4,
                name: "files",
                render: () => <Icon icon={faPaperclip} size="small" />,
                minWidth: 50,
            },
            {
                position: 5,
                priorityLevel: 0,
                name: "menu",
                minWidth: 50,
                render: (accreditation: AccreditationDto) => (
                    <MeatballMenu
                        menuItems={[
                            {
                                label: t("common.edit"),
                                action: () => {
                                    setSelectedAccreditation(accreditation);
                                    setIsAccreditationDrawerOpen(true);
                                },
                            },
                            {
                                label: t("common.delete"),
                                action: () => {
                                    setCompanyAccreditations((prevState) => {
                                        const currentAccreditations = [...prevState];
                                        const selectedAccreditationIndex = currentAccreditations.indexOf(accreditation);
                                        if (selectedAccreditationIndex > -1) {
                                            currentAccreditations.splice(selectedAccreditationIndex, 1);
                                        }
                                        return currentAccreditations;
                                    });
                                },
                            },
                        ]}
                    />
                ),
            },
        ],
        [setCompanyAccreditations, t],
    );

    return (
        <>
            <Label
                name="accreditations"
                content={t("companyVettingQuestionnaire.sections.ethics.quality.accreditations.heading")}
                required
            />
            <div className={classes.accreditations}>
                <Label
                    name="accreditation"
                    content={t("companyVettingQuestionnaire.sections.ethics.quality.accreditations.label")}
                    testid="accreditation-label"
                />
                <Button
                    testid="add-accreditation-button"
                    label={t("companyVettingQuestionnaire.sections.ethics.quality.accreditations.addAccreditation")}
                    icon={faPlus}
                    size="small"
                    onClick={() => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: `Company Vetting - Add Accreditation`,
                        });
                        setIsAccreditationDrawerOpen(true);
                    }}
                />
                <AddOrEditAccreditationDrawer
                    testid="add-or-edit-accreditation-drawer"
                    isOpen={isAccreditationDrawerOpen}
                    onClose={() => {
                        setIsAccreditationDrawerOpen(false);
                        setSelectedAccreditation(undefined);
                    }}
                    accreditation={selectedAccreditation}
                    onSaveAccreditation={(accreditation) => {
                        if (!selectedAccreditation) {
                            setCompanyAccreditations((prevState) => {
                                return [...prevState, accreditation];
                            });
                            setIsAccreditationDrawerOpen(false);
                            setSelectedAccreditation(undefined);
                            return;
                        }
                        setCompanyAccreditations((prevState) => {
                            const currentAccreditations = [...prevState];
                            const selectedAccreditationIndex = currentAccreditations.indexOf(selectedAccreditation);
                            if (selectedAccreditationIndex > -1) {
                                currentAccreditations.splice(selectedAccreditationIndex, 1);
                            }
                            currentAccreditations.push(accreditation);
                            return currentAccreditations;
                        });
                        setIsAccreditationDrawerOpen(false);
                        setSelectedAccreditation(undefined);
                    }}
                />
                <Table
                    columns={columns}
                    data={companyAccreditations}
                    noDataDisplay={t("companyVettingQuestionnaire.sections.ethics.quality.accreditations.noData")}
                    testid="accreditation-list"
                />
            </div>
        </>
    );
};

export default Accreditations;
