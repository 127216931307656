import { Heading } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import FinancialTurnoverSubSection from "features/company/profile/vetting/sections/companyDetailsSubSections/FinancialTurnoverSubSection";
import GeographicalLocationSubSection from "features/company/profile/vetting/sections/companyDetailsSubSections/GeographicalLocationSubSection";
import InsuranceSubSection from "features/company/profile/vetting/sections/companyDetailsSubSections/InsuranceSubSection";
import ParentCompanySubSection from "features/company/profile/vetting/sections/companyDetailsSubSections/ParentCompanySubSection";
import useSectionStyles from "features/company/profile/vetting/sections/useSectionStyles";
import { InsuranceDto } from "types/dtos/company/vettingInformation/InsuranceDto";

export const companyDetailsSectionValidationSchema = () =>
    Yup.object({
        parentCompanyName: Yup.string().nullable(),
        parentCompanyRegistrationNumber: Yup.string().nullable(),
        geographicalLocationTypeIds: Yup.array().of(Yup.string()).nullable(),
        financialTurnoverTypeId: Yup.string().nullable(),
        financialCurrency: Yup.string().nullable(),
    });

interface CompanyDetailsSectionProps {
    companyInsurances: InsuranceDto[];
    setCompanyInsurances: React.Dispatch<React.SetStateAction<InsuranceDto[]>>;
}

const CompanyDetailsSection: React.FC<CompanyDetailsSectionProps> = ({ companyInsurances, setCompanyInsurances }) => {
    const classes = useSectionStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.section}>
            <Heading className={classes.heading} variant="h3">
                {t("companyVettingQuestionnaire.sections.companyDetails.heading")}
            </Heading>
            <hr className={classes.hr} />
            <ParentCompanySubSection />
            <GeographicalLocationSubSection />
            <FinancialTurnoverSubSection />
            <InsuranceSubSection companyInsurances={companyInsurances} setCompanyInsurances={setCompanyInsurances} />
        </div>
    );
};

export default CompanyDetailsSection;
