import { ExpansionPanel, ReadOnlyField } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";

import { ICommonProps } from "components/shared";
import useProfileStyles from "features/company/hooks/useProfileStyles";
import { CompanySummaryDto } from "types/dtos/company/CompanySummaryDto";
import { BuyerSupplierRelationshipPill } from "components";
import { getCompanySizeRange } from "types/enums/companies/CompanySizeV2";

interface ISummarySectionProps extends ICommonProps {
    data: CompanySummaryDto;
    testid: string;
}

const SummarySection: React.FC<ISummarySectionProps> = ({ data, testid }) => {
    const classes = useProfileStyles();

    const { t } = useTranslation();

    return (
        <ExpansionPanel label={t("companyInformation.summary.title")} isExpanded testid={testid}>
            <div className={classes.row}>
                <div className={classes.column}>
                    <ReadOnlyField
                        label={t("companyInformation.summary.companyName")}
                        value={data.registeredName}
                        testid="supplier-name"
                        useHyphenForEmptyValues
                    />
                    <ReadOnlyField
                        label={t("companyInformation.summary.companySize")}
                        value={data.sizeType}
                        testid="company-size"
                        useHyphenForEmptyValues
                    />
                    <ReadOnlyField
                        label={t("companyInformation.summary.numberOfEmployees")}
                        value={getCompanySizeRange(data.sizeType)}
                        testid="employee-count"
                        useHyphenForEmptyValues
                    />
                    <ReadOnlyField
                        label={t("companyInformation.summary.netPromoterScore")}
                        value={data.netPromoterScore}
                        testid="supplier-net-promoter-score"
                        useHyphenForEmptyValues
                    />
                    <ReadOnlyField
                        label={t("companyInformation.summary.relationshipStatus")}
                        value={
                            data.buyerSupplierRelationship ? (
                                <BuyerSupplierRelationshipPill
                                    status={data.buyerSupplierRelationship.supplierRelationshipStatus}
                                    companyUuid={data.uuid}
                                    type="relationship"
                                />
                            ) : undefined
                        }
                        testid="supplier-approval-status"
                        useHyphenForEmptyValues
                    />
                    <ReadOnlyField
                        label={t("companyInformation.summary.tieringStatus")}
                        value={
                            data.buyerSupplierRelationship ? (
                                <BuyerSupplierRelationshipPill
                                    status={data.buyerSupplierRelationship.supplierTieringStatus}
                                    companyUuid={data.uuid}
                                    type="tiering"
                                />
                            ) : undefined
                        }
                        testid="supplier-tiering-status"
                        useHyphenForEmptyValues
                    />
                </div>
                <div className={classes.column}>
                    <ReadOnlyField
                        label={t("companyInformation.summary.vatRegistrationNumber")}
                        value={data.vatRegistrationNumber}
                        testid="vat-registration-number"
                        useHyphenForEmptyValues
                    />
                    <ReadOnlyField
                        label={t("companyInformation.summary.registrationNumber")}
                        value={data.companiesHouseNumber}
                        testid="registration-number"
                        useHyphenForEmptyValues
                    />
                    <ReadOnlyField
                        label={t("companyInformation.summary.industryType")}
                        value={data.industryType}
                        testid="industry-type"
                        useHyphenForEmptyValues
                    />
                </div>
            </div>
        </ExpansionPanel>
    );
};

export default SummarySection;
