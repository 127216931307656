import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "api/commonApi";
import EnvironmentVariables from "config/EnvironmentVariables";

const baseApi = createApi({
    baseQuery: axiosBaseQuery({ baseUrl: EnvironmentVariables.OrchestrationApiUrl }),
    endpoints: () => ({}),
});

export default baseApi;
