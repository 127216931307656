import { faFileChartColumn, faSync } from "@fortawesome/pro-regular-svg-icons";
import { Button, Copy, ITableColumn, TextButton } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import sortingAlgorithms from "features/helpers/sortingAlgorithms";
import AmendDeadlineForm from "features/project/components/AmendDeadline/AmendDeadlineForm";
import SupplierTable from "features/project/monitor/SupplierTable";
import useMonitorStyles from "features/project/monitor/hooks/useMonitorStyles";
import { IProjectInformation } from "features/project/types";
import ReactGA from "react-ga4";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import ProjectStatus from "types/enums/projects/ProjectStatus";
import ProjectType from "types/enums/projects/ProjectType";

interface IResultsDisplayConditions {
    isCompletingAtReview: boolean;
    hasDeadlinePassed: boolean;
    hasSubmittedResponse: boolean;
    canCreateProject: boolean;
    canCompleteWithoutAward: boolean;
    canViewScoreSummaryReport: boolean;
    canModerateScores: boolean;
    canUpdateProject: boolean;
    isQuote: boolean;
    requireTcv: boolean;
}

interface IResultsDisplayProps {
    suppliers: Array<SupplierSubmissionResponseDto>;
    project: IProjectInformation;
    refreshSuppliers: () => void;
    updateResponseDeadline: (deadline: Date) => void;
    showAwardPopup: () => void;
    showCompleteAtReviewPopup: () => void;
    selectedSupplier: SupplierSubmissionResponseDto | undefined;
    columns: ITableColumn[];
    conditions: IResultsDisplayConditions;
}

const ResultsDisplay: React.FC<IResultsDisplayProps> = (props) => {
    const sharedClasses = useMonitorStyles();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { sortSuppliersByScore } = sortingAlgorithms();

    const {
        suppliers,
        project,
        refreshSuppliers,
        updateResponseDeadline,
        showAwardPopup,
        showCompleteAtReviewPopup,
        selectedSupplier,
        columns,
        conditions,
    } = props;

    const {
        isCompletingAtReview,
        hasDeadlinePassed,
        canCreateProject,
        canUpdateProject,
        canCompleteWithoutAward,
        canModerateScores,
        canViewScoreSummaryReport,
        isQuote,
        requireTcv,
    } = conditions;

    const navigateToScoring = () => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: "Results Display - To Scoring - ".concat(project.projectName),
        });
        navigate(buildPath(routes.projects.tenderSummary, { projectUuid: project.uuid }));
    };

    const canAward =
        (project.type === ProjectType.Quote && selectedSupplier?.supplierPricing.simplePrice.price) ||
        (project.type === ProjectType.Tender && !requireTcv && selectedSupplier !== undefined) ||
        (project.type === ProjectType.Tender && selectedSupplier?.buyerPricing.simplePrice.price != null);

    const isReadOnly = project.status === ProjectStatus.Approval;

    return (
        <React.Fragment>
            <Copy className={sharedClasses.copy}>
                {t("monitorScreen.subtitleComplete", { projectType: project?.type?.toLowerCase() })}
            </Copy>
            <div className={sharedClasses.actions}>
                <AmendDeadlineForm
                    label={t("monitorScreen.responseDeadline")}
                    deadline={project?.tenderResponseDeadline}
                    deadlineHasPassed={hasDeadlinePassed}
                    canAmendDate={canUpdateProject && isQuote && !isReadOnly}
                    updateDeadline={(deadline: Date) => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: "Results Display - Reinvite Supplier",
                        });
                        updateResponseDeadline(deadline);
                    }}
                />
                <div className={sharedClasses.actionButtons}>
                    {canViewScoreSummaryReport && (
                        <Button
                            label={t("reports.scoreSummaryReport")}
                            size="small"
                            variant="outline"
                            icon={faFileChartColumn}
                            onClick={() => {
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "Results Display - Score Summary Report - ".concat(project.projectName),
                                });
                                window.open(`/projects/${project.uuid}/tender-summary/score-summary-report`, "_blank");
                            }}
                            testid="score-summary-report-button"
                        />
                    )}
                    {canModerateScores && (
                        <Button
                            label={t("monitorScreen.moderateScore")}
                            size="small"
                            variant="outline"
                            onClick={navigateToScoring}
                            testid="moderate-scores-button"
                        />
                    )}
                    {canCompleteWithoutAward && !isReadOnly && (
                        <Button
                            label={t("monitorScreen.completeWithoutAward")}
                            size="small"
                            onClick={() => {
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "Results Display - Complete Without Award Review Popup",
                                });
                                showCompleteAtReviewPopup();
                            }}
                            loading={isCompletingAtReview}
                            color="dark"
                            testid="complete-without-award-button"
                        />
                    )}
                </div>
            </div>
            <SupplierTable
                suppliers={suppliers}
                columns={columns}
                canAwardSupplier
                sortingAlgorithm={sortSuppliersByScore}
            />
            <div className={sharedClasses.refresh}>
                <TextButton
                    label={t("monitorScreen.refreshTable")}
                    icon={faSync}
                    onClick={() => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: "Results Display - Refresh Table",
                        });
                        refreshSuppliers();
                    }}
                    testid="refresh-table-button"
                />
            </div>
            <div className={sharedClasses.buttons}>
                <Button
                    label={t("monitorScreen.awardSupplier")}
                    size="large"
                    onClick={() => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: "Results Display - Award Selected Supplier Button",
                        });
                        showAwardPopup();
                    }}
                    disabled={!canAward}
                    testid="award-selected-supplier-button"
                />
                {canCreateProject && (
                    <Button
                        label={t("completeProject.startProject")}
                        onClick={() => {
                            ReactGA.event({
                                category: ga4Category.Button,
                                action: ga4Action.ButtonClick,
                                label: "Results Display - Start New Project",
                            });
                            navigate(buildPath(routes.projects.projectBrief, { projectUuid: "new" }));
                        }}
                        testid="start-new-project-button"
                    />
                )}
            </div>
        </React.Fragment>
    );
};

export default ResultsDisplay;
