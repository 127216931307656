import React from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { ICommonProps } from "components/shared";
import loadingApplication from "assets/images/loadingApplication.svg";

const useStyles = createUseStyles({
    background: {
        backgroundColor: "#184258",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 36,
        position: "fixed",
        inset: 0,
        zIndex: 1000,
    },
    image: {
        position: "relative",
        height: 212.5,
        left: 50,
        top: 20,
    },
    imageContainer: {
        backgroundColor: "#FFFFFF",
        color: "#FFFFFF",
        borderRadius: "50%",
        height: 300,
        width: 300,
    },
    text: {
        fontFamily: "Sora, sans-serif",
        lineHeight: "28px",
        fontSize: 20,
        fontWeight: 600,
        color: "#FFFFFF",
    },

    "@media (min-width: 576px)": {
        image: {
            height: 300,
        },
        imageContainer: {
            height: 400,
            width: 400,
        },
    },
});

interface ILoadingApplicationProps extends ICommonProps {}

const LoadingApplication: React.FC<ILoadingApplicationProps> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation();

    return (
        <div data-testid={props.testid} className={classNames(classes.background)}>
            <div className={classNames(classes.imageContainer)}>
                <img className={classNames(classes.image)} src={loadingApplication} alt="Loading" />
            </div>
            <p className={classNames(classes.text)}>{t("common.loading")}</p>
        </div>
    );
};

export default LoadingApplication;
