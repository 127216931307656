import React from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import * as Yup from "yup";

import { ICommonProps } from "components/shared";
import SupplierRelationshipStatus from "types/enums/companies/SupplierRelationshipStatus";
import SupplierStatusForm from "features/company/profile/suppliers/SupplierStatusForm";
import { BuyerSupplierRelationshipDto } from "types/dtos/company/buyer-supplier-relationships/BuyerSupplierRelationshipDto";

const validationSchema = (t: TFunction<"translation", undefined>) =>
    Yup.object({
        supplierRelationshipStatus: Yup.string().required(t("companySuppliers.changeStatus.required")),
    });

interface ISupplierRelationshipStatusFormProps extends ICommonProps {
    values: BuyerSupplierRelationshipDto;
    submit: (values: BuyerSupplierRelationshipDto) => void;
}

const SupplierRelationshipStatusForm: React.FC<ISupplierRelationshipStatusFormProps> = (props) => {
    const { t } = useTranslation();

    const relationshipStatusOptions = [
        {
            value: SupplierRelationshipStatus.Approved,
            label: t("companySuppliers.relationshipStatus.approved.label"),
        },
        {
            value: SupplierRelationshipStatus.Pending,
            label: t("companySuppliers.relationshipStatus.pending.label"),
        },
        {
            value: SupplierRelationshipStatus.Trial,
            label: t("companySuppliers.relationshipStatus.trial.label"),
        },
        {
            value: SupplierRelationshipStatus.Deactivated,
            label: t("companySuppliers.relationshipStatus.deactivated.label"),
        },
    ];

    return (
        <SupplierStatusForm
            copy={t("companySuppliers.changeStatus.text")}
            heading={t("companySuppliers.table.relationshipStatus")}
            label={t("companySuppliers.changeStatus.label")}
            name="supplierRelationshipStatus"
            options={relationshipStatusOptions}
            submit={(values) => props.submit(values)}
            tooltips={[
                {
                    text: t("companySuppliers.relationshipStatus.approved.tooltip"),
                    fieldName: t("companySuppliers.relationshipStatus.approved.label"),
                },
                {
                    text: t("companySuppliers.relationshipStatus.pending.tooltip"),
                    fieldName: t("companySuppliers.relationshipStatus.pending.label"),
                },
                {
                    text: t("companySuppliers.relationshipStatus.trial.tooltip"),
                    fieldName: t("companySuppliers.relationshipStatus.trial.label"),
                },
                {
                    text: t("companySuppliers.relationshipStatus.deactivated.tooltip"),
                    fieldName: t("companySuppliers.relationshipStatus.deactivated.label"),
                },
            ]}
            values={props.values}
            validationSchema={validationSchema(t)}
        />
    );
};

export default SupplierRelationshipStatusForm;
