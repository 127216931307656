import { useToaster } from "@maistro/components";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { getQuestion, listQuestions } from "api/supplierSubmission/submissionClarificationQuestionsApi";
import validationService from "services/validationService";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const useSupplierSubmissionClarificationQuestions = (
    responseUuid?: string,
    setLoading?: (isLoading: boolean) => void,
) => {
    const [clarificationQuestionDetail, setClarificationQuestionDetail] = useState<QuestionResponseDto | null>(null);
    const [clarificationQuestions, setClarificationQuestions] = useState<Array<QuestionResponseDto> | null>(null);

    const { t } = useTranslation();
    const toast = useToaster();

    const fetchQuestions = useCallback(async () => {
        if (!responseUuid || !validationService.isValidUuid(responseUuid)) return;
        const response = await listQuestions(responseUuid);

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("clarificationQuestions.api.getQuestionsError"));
            setClarificationQuestions([]);
            return;
        }

        setClarificationQuestions(response.data.items);

        if (setLoading) {
            setLoading(false);
        }
    }, [responseUuid, setLoading, t, toast]);

    const fetchQuestion = useCallback(
        async (questionUuid?: string) => {
            if (!responseUuid || !validationService.isValidUuid(responseUuid)) return;
            if (!questionUuid || !validationService.isValidUuid(questionUuid)) return;
            getQuestion(responseUuid, questionUuid, true, true).then((response) => {
                if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                    toast.error(t("clarificationQuestions.api.getQuestionsError"));
                } else {
                    setClarificationQuestionDetail(response.data);
                }
            });
        },
        [responseUuid, t, toast],
    );

    return {
        clarificationQuestions,
        clarificationQuestionDetail,
        fetchQuestions,
        fetchQuestion,
    };
};

export default useSupplierSubmissionClarificationQuestions;
