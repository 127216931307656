import { faEdit, faEye } from "@fortawesome/pro-regular-svg-icons";
import { ISortBy, ITableColumn, Icon, Pill, Table, useToaster } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { NotFoundScreen } from "components";
import EmptyDashboard from "features/dashboard/EmptyDashboard";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import dateTimeService from "services/dateTimeService";
import stringService from "services/stringService";
import { SupplierSubmissionSearchDto } from "types/dtos/supplierSubmissions/SupplierSubmissionSearchDto";
import ProjectType from "types/enums/projects/ProjectType";
import SupplierSubmissionStatus from "types/enums/supplierSubmissions/SupplierSubmissionStatus";

interface IDashboardSupplierSubmissionsDisplay {
    filteredSubmissions: SupplierSubmissionSearchDto[];
    setSortBy?: (sort: ISortBy) => void;
    sortBy: ISortBy;
    refreshData: () => void;
    shouldDisplayEmptyDashboard: boolean;
    shouldDisplayNoResultsDashboard: boolean;
    setIsFilterDrawerOpen: () => void;
}

const DashboardSupplierSubmissionsDisplay: React.FC<IDashboardSupplierSubmissionsDisplay> = ({
    filteredSubmissions,
    setSortBy,
    sortBy,
    refreshData,
    shouldDisplayEmptyDashboard,
    shouldDisplayNoResultsDashboard,
    setIsFilterDrawerOpen,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const toast = useToaster();

    const calculateProjectUrl = (
        projectUuid: string,
        responseUuid: string,
        submissionStatus: SupplierSubmissionStatus,
        projectType: ProjectType,
    ) => {
        switch (submissionStatus) {
            case SupplierSubmissionStatus.Responding:
            case SupplierSubmissionStatus.Interested:
                return projectType === ProjectType.Tender
                    ? buildPath(routes.projectResponses.tenderQuestions, { projectUuid, responseUuid })
                    : buildPath(routes.projectResponses.projectResponse, { projectUuid, responseUuid });
            case SupplierSubmissionStatus.Invited:
            case SupplierSubmissionStatus.Submitted:
            case SupplierSubmissionStatus.Shortlisted:
            case SupplierSubmissionStatus.Successful:
            case SupplierSubmissionStatus.Failed:
            case SupplierSubmissionStatus.Unsuccessful:
            default:
                return buildPath(routes.projectResponses.brief, { projectUuid, responseUuid });
        }
    };

    const navigateToProjectUrl = (
        projectUuid: string,
        responseUuid: string,
        submissionStatus: SupplierSubmissionStatus,
        projectType: ProjectType,
    ) => {
        if (submissionStatus === SupplierSubmissionStatus.Withdrawn) {
            toast.error(t("dashboard.projects.errors.withdrawn"));
            return;
        }

        navigate(calculateProjectUrl(projectUuid, responseUuid, submissionStatus, projectType));
    };

    const calculateProjectIcon = (row: SupplierSubmissionSearchDto) => {
        switch (row.supplierSubmissionStatus) {
            case SupplierSubmissionStatus.Invited:
            case SupplierSubmissionStatus.Responding:
            case SupplierSubmissionStatus.Interested:
                if (dateTimeService.dateIsInPast(row.tenderResponseDeadline)) {
                    return faEye;
                }
                return faEdit;
            default:
                return faEye;
        }
    };

    const onRowClick = (row: SupplierSubmissionSearchDto) => {
        navigateToProjectUrl(row.projectUuid, row.supplierResponseUuid, row.supplierSubmissionStatus, row.projectType);
    };

    const columns: ITableColumn[] = [
        {
            position: 1,
            priorityLevel: 1,
            label: t("dashboard.projectResponses.header.projectName"),
            name: "projectName",
            minWidth: 200,
            contentWrap: true,
            sortable: true,
        },
        {
            position: 2,
            priorityLevel: 5,
            label: t("dashboard.projectResponses.header.companyName"),
            name: "companyName",
            minWidth: 200,
            sortable: true,
        },
        {
            position: 3,
            priorityLevel: 2,
            label: t("dashboard.projectResponses.header.tenderResponseDeadline"),
            name: "tenderResponseDeadline",
            minWidth: 150,
            sortable: true,
            render: (submission: SupplierSubmissionSearchDto) =>
                dateTimeService.toShortDateFormat(submission.tenderResponseDeadline),
        },
        {
            position: 4,
            priorityLevel: 3,
            label: t("dashboard.projectResponses.header.type"),
            name: "projectType",
            minWidth: 100,
        },
        {
            position: 5,
            priorityLevel: 4,
            label: t("dashboard.projectResponses.header.status"),
            name: "submissionStatus",
            minWidth: 150,
            render: (submission: SupplierSubmissionSearchDto) => (
                <Pill value={stringService.insertSpacesInTitleCase(submission.supplierSubmissionStatus)} />
            ),
        },
        {
            position: 6,
            priorityLevel: 0,
            name: "action",
            minWidth: 50,
            render: (submission: SupplierSubmissionSearchDto) => (
                <Icon
                    icon={calculateProjectIcon(submission)}
                    alt={calculateProjectIcon(submission) === faEdit ? t("common.edit") : t("common.view")}
                    onClick={() => onRowClick(submission)}
                />
            ),
        },
    ];

    if (shouldDisplayEmptyDashboard) {
        return <EmptyDashboard createProject={() => null} canCreateProject={false} />;
    }

    if (shouldDisplayNoResultsDashboard) {
        return (
            <NotFoundScreen
                testid="no-result-dashboard"
                title={t("dashboard.noResultsDashboard.title")}
                message={t("dashboard.noResultsDashboard.copy")}
                primaryActionText={t("common.amendCriteria")}
                primaryAction={setIsFilterDrawerOpen}
                secondaryActionText={t("common.resetFilters")}
                secondaryAction={refreshData}
            />
        );
    }

    return (
        <Table
            columns={columns}
            data={filteredSubmissions}
            noDataDisplay={t("common.noResults")}
            onSort={setSortBy}
            sortBy={sortBy}
            rowAction={(row: SupplierSubmissionSearchDto) => onRowClick(row)}
            testid="projects-list"
        />
    );
};

export default DashboardSupplierSubmissionsDisplay;
