import { Grid, Heading, TextField } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";

import useSectionStyles from "features/company/profile/vetting/sections/useSectionStyles";
import { VettingInformationKeys } from "types/dtos/company/vettingInformation/VettingInformationDto";

const ParentCompanySubSection: React.FC = () => {
    const classes = useSectionStyles();
    const { t } = useTranslation();

    return (
        <div id={VettingInformationKeys.parent}>
            <Heading variant="h4">{t("companyVettingQuestionnaire.sections.companyDetails.parentCompany")}</Heading>
            <div className={classes.subSection}>
                <Grid>
                    <TextField
                        name="parentCompanyName"
                        label={t("companyVettingQuestionnaire.sections.companyDetails.parentCompanyName.label")}
                        tooltip={t("companyVettingQuestionnaire.sections.companyDetails.parentCompanyName.tooltip")}
                        testid="parent-company-name-field"
                    />
                    <TextField
                        name="parentCompanyRegistrationNumber"
                        label={t(
                            "companyVettingQuestionnaire.sections.companyDetails.parentCompanyRegistrationNumber.label",
                        )}
                        tooltip={t(
                            "companyVettingQuestionnaire.sections.companyDetails.parentCompanyRegistrationNumber.tooltip",
                        )}
                        testid="parent-company-registration-number-field"
                    />
                </Grid>
            </div>
        </div>
    );
};

export default ParentCompanySubSection;
