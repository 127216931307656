import { useState } from "react";

export interface ISupplierListFilters {
    activeStatuses: Array<string>;
    relationshipStatuses: Array<string>;
    tieringStatuses: Array<string>;
    searchTerm: string;
}

export const emptyFilters: ISupplierListFilters = {
    activeStatuses: [],
    relationshipStatuses: [],
    tieringStatuses: [],
    searchTerm: "",
};

const useSupplierListFilters = (initialFilters?: ISupplierListFilters) => {
    const [currentFilters, setCurrentFilters] = useState<ISupplierListFilters>({ ...emptyFilters, ...initialFilters });

    return {
        currentFilters,
        setCurrentFilters,
    };
};

export default useSupplierListFilters;
