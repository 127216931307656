import { faCheck, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { faCircleCheck, faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { Button, ICommonProps, Icon, Pill } from "@maistro/components";
import React from "react";

import useScreenLayout from "hooks/useScreenLayout";

interface IApprovalActionButtonProps extends ICommonProps {
    onClick: () => void;
    disabled: boolean;
    label: string;
    disabledLabel: string;
    color: "success" | "reject";
    isApproved: boolean;
}

const ApprovalActionButton: React.FC<IApprovalActionButtonProps> = ({
    onClick,
    disabled,
    disabledLabel,
    label,
    color,
    isApproved,
    testid,
}) => {
    const { onMobile } = useScreenLayout();

    const getIconColor = () => {
        if (isApproved && !disabled) {
            return "success";
        }
        if (!disabled) {
            return "error";
        }
        return "primary";
    };

    if (onMobile) {
        return (
            <Icon
                icon={isApproved ? faCircleCheck : faCircleXmark}
                onClick={disabled ? undefined : onClick}
                size="large"
                color={getIconColor()}
                testid={`${testid}-icon`}
            />
        );
    }

    if (disabled) {
        return <Pill value={disabledLabel} icon={isApproved ? faCheck : faXmark} testid={`${testid}-pill`} />;
    }

    return (
        <Button
            label={label}
            onClick={onClick}
            color={color}
            size="small"
            icon={isApproved ? faCheck : faXmark}
            testid={`${testid}-button`}
        />
    );
};

export default ApprovalActionButton;
