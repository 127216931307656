import { ISortBy, Pagination } from "@maistro/components";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

import { Loader } from "components";
import DashboardFilters from "features/dashboard/filter/DashboardFilters";
import { emptyFilters } from "features/dashboard/filter/hooks/useDashboardFilters";
import { IProjectDashboardInformation } from "features/dashboard/interfaces/IProjectDashboardInformation";
import ProjectsDashboardDisplay from "features/dashboard/projects/ProjectsDashboardDisplay";
import useDashboardContainer from "features/dashboard/projects/hooks/useDashboardContainer";
import useDashboardUserConfiguration from "features/dashboard/projects/hooks/useDashboardUserConfiguration";
import useAppDispatch from "hooks/useAppDispatch";
import { clearProjectInformation } from "store/projectSlice";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    pagination: {
        marginTop: theme.spacing.small,
    },
}));

const ProjectsDashboardContainer: React.FC = () => {
    const classes = useStyles();

    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isArchivePopupOpen, setIsArchivePopupOpen] = useState(false);
    const [projectToAction, setProjectToAction] = useState<IProjectDashboardInformation>();
    const [isDuplicateDrawerOpen, setIsDuplicateDrawerOpen] = useState(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(clearProjectInformation());
    }, [dispatch]);

    const { columnsConfiguration, sortConfiguration, filterConfiguration, optionalFilterConfiguration, onRowClick } =
        useDashboardUserConfiguration(setProjectToAction, setIsArchivePopupOpen, setIsDuplicateDrawerOpen);

    const {
        currentFilters,
        setFilters,
        options,
        getAvailableProjectManagers,
        getAvailableCompanies,
        filteredProjects,
        sortBy,
        setSortBy,
        paginationState,
        navigateToPage,
        createProject,
        userCanCreateProjects,
        isDuplicating,
        isSearching,
        shouldDisplayEmptyDashboard,
        shouldDisplayNoResultsDashboard,
        createDuplicateProject,
        disableSorting,
    } = useDashboardContainer(sortConfiguration, filterConfiguration, setIsLoading);

    useEffect(() => {
        setIsDuplicateDrawerOpen(false);
    }, [filteredProjects]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <React.Fragment>
            <DashboardFilters
                currentFilters={currentFilters}
                setCurrentFilters={setFilters}
                options={options}
                getAvailableProjectManagers={getAvailableProjectManagers}
                getAvailableCompanies={getAvailableCompanies}
                isSearching={isSearching}
                resetPage={() => navigateToPage(1)}
                resetFilters={() => setFilters(emptyFilters)}
                optionalFilters={optionalFilterConfiguration}
                isFilterDrawerOpen={isFilterDrawerOpen}
                setIsFilterDrawerOpen={setIsFilterDrawerOpen}
            />
            <ProjectsDashboardDisplay
                filteredProjects={filteredProjects}
                setSortBy={
                    disableSorting
                        ? undefined
                        : (sort: ISortBy) => {
                              setSortBy(sort);
                              navigateToPage(1);
                          }
                }
                sortBy={disableSorting ? { column: "unset" } : sortBy}
                refreshData={() => setFilters({ ...filterConfiguration })}
                columns={columnsConfiguration}
                shouldDisplayEmptyDashboard={shouldDisplayEmptyDashboard}
                shouldDisplayNoResultsDashboard={shouldDisplayNoResultsDashboard}
                createProject={createProject}
                userCanCreateProjects={userCanCreateProjects}
                setIsFilterDrawerOpen={() => setIsFilterDrawerOpen(true)}
                isDuplicating={isDuplicating}
                createDuplicateProject={createDuplicateProject}
                clearFilters={() => setFilters(emptyFilters)}
                projectToAction={projectToAction}
                isArchivePopupOpen={isArchivePopupOpen}
                setIsArchivePopupOpen={setIsArchivePopupOpen}
                isDuplicateDrawerOpen={isDuplicateDrawerOpen}
                setIsDuplicateDrawerOpen={setIsDuplicateDrawerOpen}
                onRowClick={onRowClick}
            />
            <Pagination
                className={classes.pagination}
                paginationState={paginationState}
                navigateToPage={navigateToPage}
                testid="projects-dashboard-pagination"
                disabled={isSearching}
            />
        </React.Fragment>
    );
};

export default ProjectsDashboardContainer;
