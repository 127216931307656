enum TitleCtaType {
    AskQuestion,
    CancelProject,
    Cancel,
    Export,
    ProjectPreview,
    Refresh,
}

export default TitleCtaType;
