import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { Icon } from "@maistro/components";
import React from "react";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import currencyService from "services/currencyService";
import { ITheme } from "styles/themes/types";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    column: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing.small,
    },
}));

interface ITotalContractValueColumnProps extends ICommonProps {
    supplier: SupplierSubmissionResponseDto;
    editTcv?: (supplier: SupplierSubmissionResponseDto) => void;
}

const TotalContractValueColumn: React.FC<ITotalContractValueColumnProps> = (props) => {
    const classes = useStyles();

    const { supplier, editTcv } = props;

    return (
        <div className={classes.column}>
            {currencyService.toDisplayFormat(
                supplier.buyerPricing.simplePrice.ccy,
                supplier.buyerPricing.simplePrice.price,
            )}
            {editTcv !== undefined && !!supplier.buyerPricing.simplePrice.price && (
                <Icon
                    icon={faEdit}
                    size="small"
                    onClick={() => editTcv(supplier)}
                    testid={`${supplier.supplierCompanyUuid}-edit-tcv`}
                />
            )}
        </div>
    );
};

export default TotalContractValueColumn;
