enum InsuranceType {
    Other = "Other",
    PublicLiability = "Public Liability",
    GoodsInTransit = "Goods In Transit",
    ProfessionalIndemnity = "Professional Indemnity",
    EmployeesLiability = "Employees Liability",
    CyberInsurance = "Cyber Insurance",
    ProductLiability = "Product Liability",
    AllRisks = "All Risks",
}

export default InsuranceType;
