import { useFormikContext } from "formik";
import React, { useEffect } from "react";

import { IProjectInformation } from "features/project/types";
import BudgetType from "types/enums/projects/BudgetType";

const BudgetTypeSelectorLogic: React.FC = () => {
    const { values, setFieldValue, setFieldTouched } = useFormikContext<IProjectInformation>();

    useEffect(() => {
        if (values.buyerBudget && values.buyerBudget > 0) {
            setFieldValue("budgetType", BudgetType.Budget);
            setFieldTouched("budgetType");
        }
    }, [setFieldValue, setFieldTouched, values.buyerBudget]);

    useEffect(() => {
        if (values.budgetType && values.budgetType !== BudgetType.Budget) {
            setFieldValue("buyerBudget", 0);
            setFieldTouched("buyerBudget");
        }
    }, [setFieldValue, setFieldTouched, values.budgetType]);

    return null;
};

export default BudgetTypeSelectorLogic;
