import { AutosaveForm, Button, DateField, DirtyValues, TextButton } from "@maistro/components";
import { Formik } from "formik";
import { TFunction } from "i18next";
import { isEmpty } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import { ICommonProps } from "components/shared";
import { IProjectInformation } from "features/project/types";
import ReactGA from "react-ga4";
import { ITheme } from "styles/themes/types";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import ProjectType from "types/enums/projects/ProjectType";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.large,
    },
    dateRow: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
    buttonControl: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.large,
        marginTop: theme.spacing.small,
    },
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        dateRow: {
            flexDirection: "row",
            gap: theme.spacing.large,
        },
    },
}));

interface IProjectSummaryFormProps extends ICommonProps {
    projectInformation: IProjectInformation;
    save: (values: IProjectInformation, dirtyValues: DirtyValues) => Promise<void>;
    submit: (values: IProjectInformation) => void;
    cancel?: () => void;
    disableSubmit?: boolean;
}

const minDate = new Date();

const quoteValidationSchema = (t: TFunction<"translation", undefined>) =>
    Yup.object({
        tenderResponseDeadline: Yup.date()
            .typeError(t("projectSummary.quote.fields.projectResponseDate.required"))
            .min(minDate, t("projectSummary.quote.fields.projectResponseDate.minError"))
            .required(t("projectSummary.quote.fields.projectResponseDate.required")),
    });

const tenderValidationSchema = (t: TFunction<"translation", undefined>) =>
    Yup.object({
        tenderResponseDeadline: Yup.date()
            .typeError(t("projectSummary.tender.fields.projectResponseDate.required"))
            .nullable()
            .required(t("projectSummary.tender.fields.projectResponseDate.required"))
            .min(minDate, t("projectSummary.tender.fields.projectResponseDate.minError")),
        clarificationQuestionDeadline: Yup.date()
            .nullable()
            .required(t("projectSummary.tender.fields.clarificationQuestionDeadline.required"))
            .min(minDate, t("projectSummary.tender.fields.clarificationQuestionDeadline.minError"))
            .max(
                Yup.ref("tenderResponseDeadline"),
                t("projectSummary.tender.fields.clarificationQuestionDeadline.maxError"),
            ),
        targetProjectStartDate: Yup.date()
            .nullable()
            .required(t("projectSummary.tender.fields.contractStartDate.required"))
            .min(Yup.ref("tenderResponseDeadline"), t("projectSummary.tender.fields.contractStartDate.minError")),
        targetProjectCompleteDate: Yup.date()
            .nullable()
            .min(Yup.ref("targetProjectStartDate"), t("projectSummary.tender.fields.contractEndDate.minError")),
    });

const ProjectSummaryForm: React.FC<IProjectSummaryFormProps> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const { projectInformation, submit, cancel, disableSubmit } = props;
    const isQuote = projectInformation.type === ProjectType.Quote;
    const tProjType = isQuote ? "quote" : "tender";

    const touchedHasErrors = (errors: string[], touched: string[]) => {
        return errors.some((e) => touched.includes(e));
    };

    const cancelProject = () => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: "Tender Summary - Cancel Project",
        });
        if (cancel) cancel();
    };

    return (
        <Formik
            enableReinitialize
            initialValues={{
                uuid: projectInformation.uuid,
                companyUuid: projectInformation.companyUuid,
                projectName: projectInformation.projectName,
                companyName: projectInformation.companyName,
                manager: projectInformation.manager,
                managerUuid: projectInformation.managerUuid,
                description: projectInformation.description,
                timescale: projectInformation.timescale,
                categories: projectInformation.categories,
                budgetCurrency: projectInformation.budgetCurrency,
                buyerBudget: projectInformation.buyerBudget,
                showBudgetToSupplier: projectInformation.showBudgetToSupplier,
                budgetType: projectInformation.budgetType,
                status: projectInformation.status,
                tenderResponseDeadline: projectInformation.tenderResponseDeadline,
                clarificationQuestionDeadline: projectInformation.clarificationQuestionDeadline,
                targetProjectStartDate: projectInformation.targetProjectStartDate,
                targetProjectCompleteDate: projectInformation.targetProjectCompleteDate,
                decisionSummary: projectInformation.decisionSummary,
                sponsorUserUuid: projectInformation.sponsorUserUuid,
                contributors: projectInformation.contributors,
                clientName: projectInformation.clientName,
            }}
            validationSchema={isQuote ? quoteValidationSchema(t) : tenderValidationSchema(t)}
            onSubmit={(values) => submit(values)}
            validateOnMount
        >
            {({ values, dirty, errors, touched }) => (
                <AutosaveForm<typeof values>
                    className={classes.container}
                    when={dirty && !touchedHasErrors(Object.keys(errors), Object.keys(touched))}
                    onSave={(dirtyValues) => props.save(values, dirtyValues)}
                    successMessage={t("components.autosave.success")}
                    errorMessage={t("components.autosave.error")}
                >
                    <div className={classes.dateRow}>
                        <DateField
                            label={t(`projectSummary.${tProjType}.fields.projectResponseDate.label`)}
                            tooltip={t(`projectSummary.${tProjType}.fields.projectResponseDate.tooltip`)}
                            name="tenderResponseDeadline"
                            required
                            minDate={
                                values.clarificationQuestionDeadline
                                    ? new Date(values.clarificationQuestionDeadline)
                                    : minDate
                            }
                            withTime
                            fullWidth={!isQuote}
                            testid="deadline-response-date"
                        />
                        {!isQuote && (
                            <DateField
                                label={t(`projectSummary.${tProjType}.fields.clarificationQuestionDeadline.label`)}
                                tooltip={t(`projectSummary.${tProjType}.fields.clarificationQuestionDeadline.tooltip`)}
                                name="clarificationQuestionDeadline"
                                required
                                minDate={minDate}
                                maxDate={
                                    values.tenderResponseDeadline ? new Date(values.tenderResponseDeadline) : undefined
                                }
                                fullWidth
                                testid="clarification-question-deadline"
                            />
                        )}
                    </div>
                    {!isQuote && (
                        <div className={classes.dateRow}>
                            <DateField
                                label={t(`projectSummary.${tProjType}.fields.contractStartDate.label`)}
                                tooltip={t(`projectSummary.${tProjType}.fields.contractStartDate.tooltip`)}
                                name="targetProjectStartDate"
                                required
                                minDate={
                                    values.tenderResponseDeadline ? new Date(values.tenderResponseDeadline) : minDate
                                }
                                maxDate={
                                    values.targetProjectCompleteDate
                                        ? new Date(values.targetProjectCompleteDate)
                                        : undefined
                                }
                                fullWidth
                                testid="contract-start-date"
                            />
                            <DateField
                                label={t(`projectSummary.${tProjType}.fields.contractEndDate.label`)}
                                tooltip={t(`projectSummary.${tProjType}.fields.contractEndDate.tooltip`)}
                                name="targetProjectCompleteDate"
                                minDate={
                                    values.targetProjectStartDate ? new Date(values.targetProjectStartDate) : minDate
                                }
                                fullWidth
                                testid="contract-end-date"
                            />
                        </div>
                    )}
                    <div className={classes.buttonControl}>
                        <Button
                            testid="deadline-submit-button"
                            label={t(`projectSummary.${tProjType}.submit`)}
                            size="large"
                            type="submit"
                            chevron
                            disabled={!isEmpty(errors) || disableSubmit}
                            onClick={() =>
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "Tender Summary - Invite Suppliers to Tender",
                                })
                            }
                        />
                        <TextButton
                            label={t(`projectSummary.${tProjType}.cancel`)}
                            onClick={cancelProject}
                            testid="cancel-project-button"
                        />
                    </div>
                </AutosaveForm>
            )}
        </Formik>
    );
};

export default ProjectSummaryForm;
