import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faBadgeCheck } from "@fortawesome/pro-solid-svg-icons";
import { Button, Icon } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    selectAction: {
        display: "flex",
        justifyContent: "flex-end",
        gap: theme.spacing.large,
        alignItems: "center",
    },
    selectSupplierButton: {
        minWidth: 100,
    },
    badgeIcon: {
        color: theme.colors.success,
    },
}));

interface ISelectSupplierColumnProps extends ICommonProps {
    supplier: SupplierSubmissionResponseDto;
    handleSelectSupplier: (supplier: SupplierSubmissionResponseDto) => void;
}

const SelectSupplierColumn: React.FC<ISelectSupplierColumnProps> = (props) => {
    const classes = useStyles();

    const { supplier, handleSelectSupplier } = props;

    const { t } = useTranslation();

    return (
        <div className={classes.selectAction}>
            {supplier.displayBadge && (
                <Icon
                    className={classes.badgeIcon}
                    icon={faBadgeCheck}
                    size="large"
                    alt={t("monitorScreen.tooltips.highestRated")}
                    testid={`${supplier.supplierCompanyUuid}-highest-rated`}
                />
            )}
            {supplier.isSelected ? (
                <Button
                    className={classes.selectSupplierButton}
                    label={t("common.selected")}
                    size="small"
                    color="success"
                    icon={faCheck}
                    onHoverIcon={null}
                    onHoverLabel={t("common.unselect")}
                    onClick={() => handleSelectSupplier(supplier)}
                    testid={`${supplier.supplierCompanyUuid}-unselect-button`}
                />
            ) : (
                <Button
                    label={t("common.select")}
                    size="small"
                    onClick={() => handleSelectSupplier(supplier)}
                    testid={`${supplier.supplierCompanyUuid}-select-button`}
                />
            )}
        </div>
    );
};

export default SelectSupplierColumn;
