import { Alert, Button, Copy, Popup, SelectField } from "@maistro/components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useProject from "features/project/hooks/useProject";
import ScoringDisplayHeader from "features/project/tenderCriteria/components/scoring/ScoringDisplayHeader";
import { useTenderPermissions } from "features/project/tenderCriteria/hooks/useTenderPermissions";
import useTenderStyles from "features/project/tenderCriteria/hooks/useTenderStyles";
import ScoreTenderSummarySectionRow from "features/project/tenderCriteria/scoringSummary/ScoreTenderSummarySectionRow";
import { IProjectInformation } from "features/project/types";
import { Form, Formik } from "formik";
import useCurrentUser from "hooks/useCurrentUser";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import { AnswerDto } from "types/dtos/questions/AnswerResponseDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";
import SupplierSubmissionStatus from "types/enums/supplierSubmissions/SupplierSubmissionStatus";

interface IScoreTenderSummaryDisplayProps {
    sections: SectionDto[];
    questions: QuestionResponseDto[];
    completedContributorScorers: number;
    totalContributorScorers: number;
    projectInformation: IProjectInformation;
    completeScoring: () => Promise<void>;
    isProjectManager: boolean;
    isScoringComplete: boolean;
    displayScoringClosedMessage: boolean;
    projectHasFiles: boolean;
    isScoringCompleteForUser: boolean;
}

const ScoreTenderSummaryDisplay: React.FC<IScoreTenderSummaryDisplayProps> = (props) => {
    const classes = useTenderStyles();

    const {
        projectInformation,
        sections,
        questions,
        completedContributorScorers,
        totalContributorScorers,
        completeScoring,
        isProjectManager,
        isScoringComplete,
        displayScoringClosedMessage,
        projectHasFiles,
        isScoringCompleteForUser,
    } = props;

    const { fetchProjectSuppliers } = useProject();

    const { t } = useTranslation();
    const navigate = useNavigate();
    let buttonLabel;

    const { userHasProjectPermission, myUuid } = useCurrentUser();
    const projectUuid = projectInformation.uuid;
    const [scoringCompletePopupVisible, setScoringCompletePopupVisible] = useState(false);
    const [popupMessage, setPopupMessage] = useState("popups.tenderCriteria.scoringComplete.message");
    const [popupTitleMessage, setPopupTitleMessage] = useState("popups.tenderCriteria.scoringComplete.title");

    const [projectSuppliers, setProjectSuppliers] = useState<Array<SupplierSubmissionResponseDto>>([]);

    const scoringTypes = useMemo(
        () => ({
            standard: t("tenderSummaryScreen.standard"),
            bySupplier: t("tenderSummaryScreen.bySupplier"),
        }),
        [t],
    );
    const selectSupplierMemo = useMemo(() => {
        return projectSuppliers.map((supplier) => supplier._embedded.supplierCompanyName);
    }, [projectSuppliers]);

    const [scoringType, setScoringType] = useState(scoringTypes.standard);
    const [selectSupplier, setSelectSupplier] = useState("");
    const { canScoreAnswers, canModerateScores } = useTenderPermissions({
        isScoringComplete,
        projectInformation,
        userHasProjectPermission,
    });

    useEffect(() => {
        if (selectSupplierMemo.length > 0) {
            setSelectSupplier(selectSupplierMemo[0]);
        }
    }, [selectSupplierMemo]);

    useEffect(() => {
        if (isProjectManager) {
            if (completedContributorScorers < totalContributorScorers) {
                setPopupTitleMessage("popups.tenderCriteria.scoringComplete.pmTitleWarning");
                setPopupMessage("popups.tenderCriteria.scoringComplete.pmWarningContributorsCompletion");
            } else {
                setPopupMessage("popups.tenderCriteria.scoringComplete.pmMessage");
            }
        }
    }, [completedContributorScorers, totalContributorScorers, isProjectManager]);

    const fetchSuppliers = useCallback(async () => {
        const suppliersInformation = await fetchProjectSuppliers();
        const submittedSuppliers = suppliersInformation
            .filter((supplier) => supplier.status === SupplierSubmissionStatus.Submitted)
            .sort((a, b) => a._embedded.supplierCompanyName.localeCompare(b._embedded.supplierCompanyName));

        setProjectSuppliers(submittedSuppliers);
    }, [fetchProjectSuppliers]);

    useEffect(() => {
        fetchSuppliers();
    }, [fetchSuppliers]);

    const haveScores = (answer: AnswerDto) => {
        return (
            !!answer.scores &&
            answer.scores.length > 0 &&
            answer.scores.some((score) => score.updatedByUserUuid === myUuid)
        );
    };

    const isAnyQuestionScored = questions.some((question) => question.answers.some(haveScores));

    const questionInSection = (section: SectionDto, question: QuestionResponseDto) =>
        question.entityUuid.toLowerCase() === section.id.toLowerCase();
    const answers = questions.map((question) => question.answers).flat();

    const allAnswersScored = answers.every(haveScores);
    const canCompleteScoring = !isScoringComplete && allAnswersScored;

    const onViewResults = () => {
        if (isProjectManager && isScoringComplete) {
            navigate(buildPath(routes.projects.awardSupplier, { projectUuid }));
        } else {
            navigate(buildPath(routes.projects.monitor, { projectUuid }));
        }
    };

    const findSupplierUuid = () => {
        return projectSuppliers.find((supplier) => supplier._embedded.supplierCompanyName === selectSupplier)
            ?.supplierCompanyUuid;
    };

    const navigateToScoring = () => {
        if (scoringType === scoringTypes.standard) {
            navigate(
                buildPath(routes.projects.scoreTenderQuestion, {
                    projectUuid,
                    questionUuid: questions[0].questionUuid,
                }),
            );
        } else {
            navigate(
                buildPath(routes.projects.scoreTenderQuestionsBySupplier, {
                    projectUuid,
                    questionUuid: questions[0].questionUuid,
                    supplierUuid: findSupplierUuid(),
                }),
            );
        }
    };

    if (!isScoringComplete) {
        if (isAnyQuestionScored) {
            buttonLabel = t("tenderSummaryScreen.editScores");
        } else {
            buttonLabel = t("tenderSummaryScreen.viewAnswer");
        }
    } else {
        buttonLabel = t("tenderSummaryScreen.moderateScores");
    }

    return (
        <>
            {displayScoringClosedMessage ? (
                <Alert type="error" label={t("tenderSummaryScreen.scoringClosedMessage")} testid="scoring-closed" />
            ) : (
                <Copy className={classes.copy}>{t("tenderSummaryScreen.subtitle")}</Copy>
            )}
            <div className={classes.subBanner}>
                <ScoringDisplayHeader
                    projectUuid={projectUuid}
                    questions={questions}
                    canModerateScores={false}
                    sections={sections}
                    completedContributorScorers={completedContributorScorers}
                    totalContributorScorers={totalContributorScorers}
                    isProjectManager={isProjectManager}
                    projectHasFiles={projectHasFiles}
                    isScoringComplete={isScoringComplete}
                    canCompleteScoring={canCompleteScoring}
                    setScoringCompletePopupVisible={setScoringCompletePopupVisible}
                    onViewResults={onViewResults}
                />
            </div>
            {(!isScoringCompleteForUser || canModerateScores) && (
                <div className={classes.actions}>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            scoringTypeSelect: scoringType,
                            suppliersSelect: selectSupplier,
                        }}
                        onSubmit={() => {}}
                    >
                        {({ handleSubmit }) => (
                            <Form onSubmit={handleSubmit}>
                                <div className={classes.select}>
                                    <Copy className={classes.actionsCopy}>{t("tenderSummaryScreen.scoringType")}</Copy>
                                    <SelectField
                                        name="scoringTypeSelect"
                                        options={Object.values(scoringTypes).map((type) => ({
                                            label: type,
                                            value: type,
                                        }))}
                                        multiple={false}
                                        disableSearch
                                        onChange={(value) => {
                                            setScoringType(value);
                                        }}
                                        withoutValidation
                                        testid="sections=select"
                                    />
                                    {scoringType !== scoringTypes.standard && (
                                        <div className={classes.select}>
                                            <Copy className={classes.actionsCopy}>
                                                {t("tenderSummaryScreen.supplier")}
                                            </Copy>
                                            <SelectField
                                                name="suppliersSelect"
                                                options={Object.values(selectSupplierMemo).map((supplier) => ({
                                                    label: supplier,
                                                    value: supplier,
                                                }))}
                                                multiple={false}
                                                disableSearch
                                                onChange={(value) => {
                                                    setSelectSupplier(value);
                                                }}
                                                withoutValidation
                                                testid="questions-select"
                                            />
                                        </div>
                                    )}
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <Button
                        variant={isAnyQuestionScored ? "outline" : undefined}
                        color={!isAnyQuestionScored ? "dark" : undefined}
                        label={buttonLabel}
                        onClick={navigateToScoring}
                        testid="complete-scoring"
                    />
                </div>
            )}
            {sections.map((section, index) => (
                <ScoreTenderSummarySectionRow
                    scoringType={scoringType}
                    selectedSupplierUuid={findSupplierUuid()}
                    key={section.id}
                    projectUuid={projectUuid}
                    section={section}
                    questions={questions.filter((question) => questionInSection(section, question))}
                    haveScores={haveScores}
                    canScoreAnswers={canScoreAnswers}
                    canModerateScores={canModerateScores}
                    expandedByDefault={index === 0}
                    testid={`section-${section.id}`}
                />
            ))}
            <Popup
                title={t(popupTitleMessage)}
                message={t(popupMessage)}
                isOpen={scoringCompletePopupVisible}
                primaryActionText={t("popups.tenderCriteria.scoringComplete.cta.primary")}
                onPrimaryAction={completeScoring}
                secondaryActionText={t("popups.tenderCriteria.scoringComplete.cta.secondary")}
                onSecondaryAction={() => setScoringCompletePopupVisible(false)}
                onClose={() => setScoringCompletePopupVisible(false)}
                testid="scoring-complete-popup"
            />
        </>
    );
};
export default ScoreTenderSummaryDisplay;
