import { Copy, ReadOnlyField } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { Paper } from "components";
import FileList from "components/FileList/FileList";
import { ICommonProps } from "components/shared";
import useProjectFiles from "features/project/hooks/useProjectFiles";
import dateTimeService from "services/dateTimeService";
import stringService from "services/stringService";
import { ITheme } from "styles/themes/types";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import EntityType from "types/enums/questions/EntityType";

const useStyles = createUseStyles((theme: ITheme) => ({
    grid: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
}));

interface IReadOnlyQuestionDisplayProps extends ICommonProps {
    projectUuid: string;
    question?: QuestionResponseDto;
    isAnonymous?: boolean;
    isQuestionAuthor?: boolean;
    sectionType?: string;
}

const ReadOnlyQuestionDisplay: React.FC<IReadOnlyQuestionDisplayProps> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const { question, isAnonymous, isQuestionAuthor, sectionType, projectUuid } = props;
    const { projectFiles: questionFiles } = useProjectFiles(projectUuid, EntityType.Question, question?.questionUuid);

    if (!question) return null;

    let askedByText = t("clarificationQuestions.askedBy.you");
    if (isAnonymous) {
        if (!isQuestionAuthor) {
            askedByText = t("clarificationQuestions.askedBy.otherSupplier");
        }
    }
    const companyName = isAnonymous ? askedByText : question.askingCompanyName;

    return (
        <Paper>
            <div className={classes.grid}>
                <ReadOnlyField
                    label={t("clarificationQuestions.question.label")}
                    value={question.questionText}
                    testid="clarification-question-text"
                />
                <ReadOnlyField
                    value={
                        <FileList
                            files={questionFiles.filter(
                                (file) =>
                                    file.linkedCompanyUuid === question.companyUuid || file.linkedCompanyUuid == null,
                            )}
                            canDownload
                        />
                    }
                    testid="question-documents"
                />
                {sectionType && (
                    <ReadOnlyField
                        label={t("clarificationQuestions.section.label")}
                        value={stringService.insertSpacesInTitleCase(sectionType)}
                        testid="clarification-question-text"
                    />
                )}
                <Copy variant="label">
                    <>
                        {!isAnonymous && question.askingUserFullName && (
                            <React.Fragment>{question.askingUserFullName}&emsp;|&emsp;</React.Fragment>
                        )}
                        {companyName && <React.Fragment>{companyName}&emsp;|&emsp;</React.Fragment>}
                        {dateTimeService.toShortDateTimeFormat(question.createdOn)}
                    </>
                </Copy>
            </div>
        </Paper>
    );
};

export default ReadOnlyQuestionDisplay;
