import { AxiosResponse } from "axios";

import { createAxiosInstance } from "api/commonApi";
import env from "config/EnvironmentVariables";
import { InviteSupplierSubmissionsRequestDto } from "types/dtos/projects/supplierSubmissions/InviteSupplierSubmissionsRequestDto";
import { ListSupplierSubmissionsResponseDto } from "types/dtos/projects/supplierSubmissions/ListSupplierSubmissionsResponseDto";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import { UpdateSupplierSubmissionRequestDto } from "types/dtos/projects/supplierSubmissions/UpdateSupplierSubmissionRequestDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { UpdateSupplierSubmissionTcvRequestDto } from "types/dtos/projects/supplierSubmissions/UpdateSupplierSubmissionTcvRequestDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/projects";

export const getSupplierSubmissionsForProject = (
    projectUuid: string,
    status?: string,
): Promise<AxiosResponse<ListSupplierSubmissionsResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    let url = `${baseApiUrl}/${projectUuid}/submissions`;
    if (status) {
        url += `?searchQuery=${status}`;
    }
    return api.get(url);
};

export const getSupplierSubmission = (
    projectUuid: string,
    responseUuid: string,
): Promise<AxiosResponse<SupplierSubmissionResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/submissions/${responseUuid}`;
    return api.get(url);
};

export const updateSupplierSubmission = (
    request: UpdateSupplierSubmissionRequestDto,
): Promise<AxiosResponse<SupplierSubmissionResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${request.projectUuid}/submissions/${request.responseUuid}`;
    return api.patch(url, request);
};

export const updateSupplierSubmissionTcv = (
    request: UpdateSupplierSubmissionTcvRequestDto,
): Promise<AxiosResponse<SupplierSubmissionResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${request.projectUuid}/submissions/${request.responseUuid}/buyer-price`;
    return api.put(url, request);
};

export const addInvitedSuppliers = (
    projectUuid: string,
    request: InviteSupplierSubmissionsRequestDto,
): Promise<AxiosResponse> => {
    return api.put(`${baseApiUrl}/${projectUuid}/submissions/invite`, request);
};

export const addIdentifiedSupplier = (projectUuid: string, supplierUuid: string): Promise<AxiosResponse> => {
    return api.post(`${baseApiUrl}/${projectUuid}/submissions`, { SupplierUuid: supplierUuid });
};

export const removeProjectSupplier = (projectUuid: string, supplierUuid: string): Promise<AxiosResponse> => {
    return api.delete(`${baseApiUrl}/${projectUuid}/submissions?supplierUuid=${supplierUuid}`);
};
