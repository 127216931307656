import { SelectField } from "@maistro/components";
import { ITooltipProps } from "@maistro/components/dist/esm/types/components/Tooltip/Tooltip";
import React from "react";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import ProjectUsersGrid from "features/project/shared/users/ProjectUsersGrid";
import { ITheme } from "styles/themes/types";
import { CompanyUserDto } from "types/dtos/company/CompanyUserDto";
import { ProjectUserDto } from "types/dtos/projects/users/ProjectUserDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.small,
    },
}));

interface IAddUserToProjectProps extends ICommonProps {
    name: string;
    label: string;
    tooltip?: ITooltipProps["content"];
    placeholder?: string;
    companyUsers: CompanyUserDto[];
    projectUsers: ProjectUserDto[];
    onSelect: (value: string) => void;
    onRowEdit?: (row: ProjectUserDto) => void;
    onRowDelete?: (row: ProjectUserDto) => void;
    handleUserSearch: (searchText: string) => void;
}

const AddUserToProject: React.FC<IAddUserToProjectProps> = ({
    name,
    label,
    tooltip,
    placeholder,
    companyUsers,
    projectUsers,
    onSelect,
    onRowEdit,
    onRowDelete,
    handleUserSearch,
    testid,
}) => {
    const classes = useStyles();

    const availableUsers = companyUsers.filter((companyUser) =>
        projectUsers.every((projectUser) => projectUser.userUuid !== companyUser.userUuid),
    );

    const options = availableUsers.map((user) => ({
        value: user.userUuid,
        label: `${user.firstName} ${user.lastName}`,
    }));

    return (
        <div className={classes.container}>
            <SelectField
                name={name}
                label={label}
                placeholder={placeholder}
                tooltip={tooltip}
                options={options}
                onChange={onSelect}
                onInputChange={(value) => handleUserSearch(value)}
                withoutValidation
                testid={testid}
            />
            {projectUsers.length > 0 && (
                <ProjectUsersGrid
                    projectUsers={projectUsers}
                    onEdit={onRowEdit}
                    onDelete={onRowDelete}
                    testid={`${testid}-list`}
                />
            )}
        </div>
    );
};

export default AddUserToProject;
