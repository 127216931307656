import { Button, DateField, Drawer, Heading, ICommonProps, TextField } from "@maistro/components";
import { Form, Formik } from "formik";
import { TFunction } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import DisplayVettingFileUploadField from "features/company/profile/vetting/files/DisplayVettingFileUploadField";
import { ITheme } from "styles/themes/types";
import { MembershipDto } from "types/dtos/company/vettingInformation/MembershipDto";
import FileDto from "types/dtos/files/FileDto";
import CompanyFileType from "types/enums/companies/vettingInformation/CompanyFileType";

const useStyles = createUseStyles((theme: ITheme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
        marginTop: theme.spacing.large,
    },
    buttons: {
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing.medium,
        marginTop: theme.spacing.medium,
    },
}));

const validationSchema = (t: TFunction<"translation", undefined>) =>
    Yup.object({
        membershipType: Yup.string().required(
            t(
                "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.memberships.form.membershipType.required",
            ),
        ),
        membershipNumber: Yup.string().required(
            t(
                "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.memberships.form.membershipNumber.required",
            ),
        ),
        expiryDate: Yup.date()
            .typeError(
                t(
                    "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.memberships.form.expiryDate.required",
                ),
            )
            .nullable()
            .required(
                t(
                    "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.memberships.form.expiryDate.required",
                ),
            )
            .min(
                new Date(),
                t(
                    "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.memberships.form.expiryDate.minError",
                ),
            ),
    });

interface IAddOrEditMembershipDrawerProps extends ICommonProps {
    isOpen: boolean;
    onClose: () => void;
    membership?: MembershipDto;
    onSave: (membership: MembershipDto) => void;
}

const AddOrEditMembershipDrawer: React.FC<IAddOrEditMembershipDrawerProps> = ({
    isOpen,
    onClose,
    membership,
    onSave,
}) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const [membershipFiles, setMembershipFiles] = useState<FileDto[]>(membership?.files ?? []);
    const [fileIsUploading, setFileIsUploading] = useState<boolean>(false);

    useEffect(() => {
        setMembershipFiles(membership?.files ?? []);
    }, [membership?.files, setMembershipFiles]);

    const onSubmit = useCallback(
        (values: MembershipDto) => {
            if (!membershipFiles || membershipFiles.length < 1) {
                return;
            }

            onSave({
                ...values,
                files: membershipFiles.map((file) => ({ ...file, type: CompanyFileType.ProfessionalBodyMembership })),
            });
            setMembershipFiles([]);
        },
        [membershipFiles, onSave, setMembershipFiles],
    );

    return (
        <Drawer isOpen={isOpen} onClose={onClose}>
            <Heading variant="h2">
                {t(
                    `companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.memberships.${
                        membership ? "edit" : "add"
                    }Membership`,
                )}
            </Heading>
            <Formik
                initialValues={
                    {
                        membershipType: membership?.membershipType ?? "",
                        membershipNumber: membership?.membershipNumber ?? "",
                        expiryDate: membership?.expiryDate,
                    } as MembershipDto
                }
                onSubmit={(values) => onSubmit(values)}
                validationSchema={() => validationSchema(t)}
            >
                {({ dirty, isValid }) => (
                    <Form className={classes.form}>
                        <TextField
                            testid="membership-type-field"
                            name="membershipType"
                            required
                            label={t(
                                "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.memberships.form.membershipType.label",
                            )}
                        />
                        <TextField
                            testid="membership-number-field"
                            name="membershipNumber"
                            required
                            label={t(
                                "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.memberships.form.membershipNumber.label",
                            )}
                        />
                        <DateField
                            testid="expiry-date-field"
                            label={t(
                                "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.memberships.form.expiryDate.label",
                            )}
                            name="expiryDate"
                            required
                            minDate={new Date()}
                            fullWidth
                        />
                        <DisplayVettingFileUploadField
                            testid="insurance-file-upload-field"
                            files={membershipFiles}
                            setFiles={setMembershipFiles}
                            fileIsUploading={fileIsUploading}
                            setFileIsUploading={setFileIsUploading}
                            name="membershipFiles"
                            multiple={false}
                            required
                            label={t("companyVettingQuestionnaire.fileUpload.label")}
                        />
                        <div className={classes.buttons}>
                            <Button
                                testid="cancel-button"
                                onClick={onClose}
                                variant="outline"
                                label={t("common.cancel")}
                            />
                            <Button
                                testid="save-membership-button"
                                type="submit"
                                label={t(
                                    `companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.memberships.${
                                        membership ? "edit" : "add"
                                    }Membership`,
                                )}
                                disabled={!dirty || !(isValid && membershipFiles.length > 0)}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </Drawer>
    );
};

export default AddOrEditMembershipDrawer;
