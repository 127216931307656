// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const getPortalUrl = (): string => {
    const currentBaseUrl = `${window.location.protocol}//${window.location.hostname}`;
    if (currentBaseUrl.includes("localhost")) {
        return window._env_.PORTAL_URL;
    }
    return `${currentBaseUrl}`;
};

const getIdentityServerUrl = (): string => {
    const currentBaseUrl = window.location.hostname.replace("idp.", "");
    if (currentBaseUrl.includes("localhost")) {
        return window._env_.IDP_URL;
    }
    return `${window.location.protocol}//idp.${currentBaseUrl}`;
};

const EnvironmentVariables = {
    PortalUrl: getPortalUrl(),
    ApiUrl: window._env_.API_URL ?? "",
    SignalRUrl: window._env_.SIGNALR_URL ?? "",
    OrchestrationApiUrl: window._env_.ORCHESTRATION_API_URL ?? "",
    IdentityServerUrl: getIdentityServerUrl(),
    ClientId: window._env_.CLIENT_ID ?? "",
    ClientScopes: window._env_.CLIENT_SCOPES ?? "",
    BuildVersion: window._env_.BUILD_VERSION ?? "",
    NodeEnvironment: window._env_.NODE_ENV ?? "",
    SupportedProjectFileExtensions: (window._env_.SUPPORTED_PROJECT_FILE_EXTENSIONS ?? "").split(","),
    HelpUrl: window._env_.HELP_URL ?? "",
    ReleaseVersion: window._env_.RELEASE_VERSION ?? "",
    GaMeasurementId: window._env_.GA_MEASUREMENT_ID ?? "",
};

export default EnvironmentVariables;
