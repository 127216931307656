import { faEnvelope, faMemoCircleInfo, faPaperclip, faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { Icon, Tooltip } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import ReactGA from "react-ga4";
import { ITheme } from "styles/themes/types";
import { BasicCompanyDto } from "types/dtos/company/BasicCompanyDto";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import SupplierSubmissionStatus from "types/enums/supplierSubmissions/SupplierSubmissionStatus";

const useStyles = createUseStyles((theme: ITheme) => ({
    iconContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: theme.spacing.large,
    },
    tieringWarningToolTipContent: {
        margin: `${theme.spacing.medium}px ${theme.spacing.medium}px`,
    },
}));

interface ISubmissionIconsColumnProps extends ICommonProps {
    supplier: SupplierSubmissionResponseDto;
    deadlineHasPassed: boolean;
    tieringWarning: boolean;
    openAdditionalInformationDrawer: (supplier: SupplierSubmissionResponseDto) => void;
    openDocumentDrawer: (supplier: BasicCompanyDto) => void;
    reinviteSupplier: (supplier: SupplierSubmissionResponseDto) => void;
}

const SubmissionIconsColumn: React.FC<ISubmissionIconsColumnProps> = (props) => {
    const classes = useStyles();

    const {
        supplier,
        deadlineHasPassed,
        tieringWarning,
        openAdditionalInformationDrawer,
        reinviteSupplier,
        openDocumentDrawer,
    } = props;

    const { t } = useTranslation();

    const showTieringWarningIcon = tieringWarning;
    const showDocumentsIcon = supplier._included.hasFiles;
    const showAdditionalInformationIcon = supplier.supplierNotes ? supplier.supplierNotes !== "" : false;
    const showReinviteSupplierIcon = !deadlineHasPassed && supplier.status === SupplierSubmissionStatus.Invited;

    return (
        <div className={classes.iconContainer}>
            {showTieringWarningIcon && (
                <span>
                    <Tooltip
                        content={t("monitorScreen.tooltips.tieringWarning")}
                        icon={faTriangleExclamation}
                        hoverIcon={faTriangleExclamation}
                        position="right"
                        testid="tiering-warning-tooltip"
                        color="white"
                        contentClassName={classes.tieringWarningToolTipContent}
                    />
                </span>
            )}
            {showDocumentsIcon && (
                <Icon
                    icon={faPaperclip}
                    onClick={(event: React.MouseEvent) => {
                        event.stopPropagation();
                        openDocumentDrawer({
                            companyUuid: supplier.supplierCompanyUuid,
                            companyName: supplier._embedded.supplierCompanyName,
                        });
                    }}
                    testid={`${supplier.supplierCompanyUuid}-has-documents`}
                    alt={t("monitorScreen.tooltips.submissionDocuments")}
                />
            )}
            {showAdditionalInformationIcon && (
                <Icon
                    icon={faMemoCircleInfo}
                    onClick={(event: React.MouseEvent) => {
                        event.stopPropagation();
                        openAdditionalInformationDrawer(supplier);
                    }}
                    testid={`${supplier.supplierCompanyUuid}-has-additional-information`}
                    alt={t("monitorScreen.tooltips.additionalInformation")}
                />
            )}
            {showReinviteSupplierIcon && (
                <Icon
                    icon={faEnvelope}
                    onClick={(event: React.MouseEvent) => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: "Project Responses - Reinvite Supplier",
                        });
                        event.stopPropagation();
                        reinviteSupplier(supplier);
                    }}
                    testid={`${supplier.supplierCompanyUuid}-send-invite`}
                    alt={t("monitorScreen.tooltips.reinvite")}
                />
            )}
        </div>
    );
};

export default SubmissionIconsColumn;
