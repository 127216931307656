/* eslint-disable fp/no-loops */
import { camelCase, kebabCase } from "lodash";

interface IStringService {
    toCamelCase: (string: string) => string;
    toKebabCase: (string: string) => string;
    toCommaDelimitedList: (array?: string[]) => string | undefined;
    compare: (str1: string, str2: string, ignoreCase?: boolean) => boolean;
    insertSpacesInTitleCase: (string: string) => string;
    getWordCount: (str: string) => number;
    hasWhiteSpace: (str: string) => boolean;
}

const stringService: IStringService = {
    toCamelCase: (string) => camelCase(string),
    toKebabCase: (string) => kebabCase(string),
    toCommaDelimitedList: (array?) => {
        if (!array) return undefined;
        return array.join(",");
    },
    compare: (str1, str2, ignoreCase = true) => {
        return ignoreCase ? str1.toUpperCase() === str2.toUpperCase() : str1 === str2;
    },
    insertSpacesInTitleCase: (string) => {
        return string.replace(/([A-Z]+)/g, " $1").trim();
    },
    hasWhiteSpace: (str: string) => {
        return /\s/g.test(str);
    },
    getWordCount: (str) => {
        let wordCount = 0;
        let index = 0;

        while (index < str.length && /\s/g.test(str[index])) {
            index += 1;
        }

        while (index < str.length) {
            while (index < str.length && !/\s/g.test(str[index])) {
                index += 1;
            }

            wordCount += 1;

            while (index < str.length && /\s/g.test(str[index])) {
                index += 1;
            }
        }

        return wordCount;
    },
};

export default stringService;
