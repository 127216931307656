import { Button, DateField, Heading, SelectField, TextButton } from "@maistro/components";
import { Form, Formik } from "formik";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
    IDashboardFilters,
    IDashboardOptions,
    IOptionalFilterConfiguration,
} from "features/dashboard/filter/hooks/useDashboardFilters";
import ReactGA from "react-ga4";
import { ITheme } from "styles/themes/types";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    date: {
        display: "flex",
    },
    datesContainer: {
        display: "flex",
        gap: theme.spacing.small,
        marginBottom: theme.spacing.medium,
    },
    dateField: {
        background: "transparent",
    },
    inputContainer: {
        width: "100%",
        marginBottom: theme.spacing.medium,
    },
    heading: {
        marginBottom: theme.spacing.medium,
    },
    buttonControl: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.small,
    },
}));
interface IDashboardFilterFormProps {
    optionalFilters?: IOptionalFilterConfiguration;
    currentFilters: IDashboardFilters;
    submitFilters: (filters: IDashboardFilters) => void;
    resetFilters: () => void;
    options: IDashboardOptions;
    getAvailableProjectManagers: (searchText?: string) => void;
    getAvailableCompanies: (searchText: string) => void;
}

const DashboardFilterForm: React.FC<IDashboardFilterFormProps> = ({
    optionalFilters,
    currentFilters,
    submitFilters,
    resetFilters,
    options,
    getAvailableProjectManagers,
    getAvailableCompanies,
}) => {
    const classes = useStyles();

    const [filters, setFilters] = useState(currentFilters);
    const [projectManagerSearchText, setProjectManagerSearchText] = useState("");
    const [companySearchText, setCompanySearchText] = useState("");

    const { t } = useTranslation();

    useEffect(() => {
        getAvailableProjectManagers(projectManagerSearchText);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectManagerSearchText]);

    useEffect(() => {
        getAvailableCompanies(companySearchText);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companySearchText]);

    return (
        <Formik
            initialValues={{
                ...currentFilters,
                projectTypes: currentFilters.projectTypes.map((option) => option.value),
                statuses: currentFilters.statuses.map((option) => option.value),
                projectManagers: currentFilters.projectManagers.map((option) => option.value),
                companies: currentFilters.companies.map((option) => option.value),
            }}
            onSubmit={(values) =>
                submitFilters({
                    ...values,
                    projectTypes: filters.projectTypes,
                    statuses: filters.statuses,
                    projectManagers: filters.projectManagers,
                    companies: filters.companies,
                })
            }
            onReset={() => resetFilters()}
        >
            <Form>
                <Heading className={classes.heading} variant="h2">
                    {t("dashboard.projects.filters.title")}
                </Heading>
                {optionalFilters?.estimatedDeliveryDeadline && (
                    <div className={classes.datesContainer}>
                        <DateField
                            name="estimatedDeliveryDeadlineStart"
                            label={t("dashboard.projects.filters.contractStartDate")}
                            withValidationStyling={false}
                            testid="filter-estimated-delivery-deadline-start"
                        />
                        <DateField
                            name="estimatedDeliveryDeadlineEnd"
                            label={t("dashboard.projects.filters.contractEndDate")}
                            withValidationStyling={false}
                            testid="filter-estimated-delivery-deadline-end"
                        />
                    </div>
                )}
                {optionalFilters?.tenderResponseDeadline && (
                    <div className={classes.datesContainer}>
                        <DateField
                            name="tenderResponseDeadlineStart"
                            label={t("dashboard.projectResponses.filters.responseStartDate")}
                            withValidationStyling={false}
                            testid="filter-tender-response-deadline-start"
                        />
                        <DateField
                            name="tenderResponseDeadlineEnd"
                            label={t("dashboard.projectResponses.filters.responseEndDate")}
                            withValidationStyling={false}
                            testid="filter-tender-response-deadline-end"
                        />
                    </div>
                )}
                <div className={classes.inputContainer}>
                    <SelectField
                        name="projectTypes"
                        label={t("dashboard.projects.filters.projectType")}
                        options={options.projectTypes}
                        multiple
                        disableSearch
                        withoutValidation
                        onChange={(_, projectTypes) =>
                            setFilters((prevState) => ({
                                ...prevState,
                                projectTypes,
                            }))
                        }
                        testid="filter-project-types"
                    />
                </div>
                {optionalFilters?.projectStatuses && (
                    <div className={classes.inputContainer}>
                        <SelectField
                            name="statuses"
                            label={t("dashboard.projects.filters.projectStatus")}
                            options={options.statuses}
                            multiple
                            disableSearch
                            withoutValidation
                            onChange={(_, statuses) =>
                                setFilters((prevState) => ({
                                    ...prevState,
                                    statuses,
                                }))
                            }
                            testid="filter-project-status"
                        />
                    </div>
                )}
                {optionalFilters?.supplierSubmissionStatuses && (
                    <div className={classes.inputContainer}>
                        <SelectField
                            name="statuses"
                            label={t("dashboard.projectResponses.filters.responseStatus")}
                            options={options.responseStatuses}
                            multiple
                            disableSearch
                            withoutValidation
                            onChange={(_, statuses) =>
                                setFilters((prevState) => ({
                                    ...prevState,
                                    statuses,
                                }))
                            }
                            testid="filter-response-status"
                        />
                    </div>
                )}
                {optionalFilters?.projectManagers && (
                    <div className={classes.inputContainer}>
                        <SelectField
                            name="projectManagers"
                            label={t("dashboard.projects.filters.projectManager")}
                            options={options.projectManagers}
                            onInputChange={debounce((inputValue) => setProjectManagerSearchText(inputValue), 500)}
                            multiple
                            withoutValidation
                            onChange={(_, projectManagers) =>
                                setFilters((prevState) => ({
                                    ...prevState,
                                    projectManagers,
                                }))
                            }
                            testid="filter-project-managers"
                        />
                    </div>
                )}

                {optionalFilters?.companies && (
                    <div className={classes.inputContainer}>
                        <SelectField
                            name="companies"
                            label={t("dashboard.projects.filters.companies")}
                            options={options.companies}
                            onInputChange={debounce((inputValue) => setCompanySearchText(inputValue), 500)}
                            multiple
                            withoutValidation
                            onChange={(_, companies) =>
                                setFilters((prevState) => ({
                                    ...prevState,
                                    companies,
                                }))
                            }
                            testid="filter-companies"
                        />
                    </div>
                )}
                <div className={classes.buttonControl}>
                    <Button
                        testid="filter-submit-button"
                        type="submit"
                        label={t("common.viewResults")}
                        onClick={() =>
                            ReactGA.event({
                                category: ga4Category.Button,
                                action: ga4Action.ButtonClick,
                                label: "Filter submit",
                            })
                        }
                    />
                    <TextButton
                        testid="filter-reset-button"
                        type="reset"
                        label={t("common.resetFilters")}
                        onClick={() =>
                            ReactGA.event({
                                category: ga4Category.Button,
                                action: ga4Action.ButtonClick,
                                label: "Filter reset",
                            })
                        }
                    />
                </div>
            </Form>
        </Formik>
    );
};

export default DashboardFilterForm;
