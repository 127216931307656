import { DirtyValues, Popup, useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { addInvitedSuppliers, removeProjectSupplier } from "api/project/projectSubmissionsApi";
import { getIdentifiedSuppliers } from "api/projectSuppliersApi";
import { Loader } from "components";
import TitleCtaType from "components/Layout/types/TitleCtaType";
import ProjectPreviewDrawer from "features/project/components/ProjectPreviewDrawer";
import useProject from "features/project/hooks/useProject";
import useProjectFiles from "features/project/hooks/useProjectFiles";
import ProjectSummaryDisplay from "features/project/projectSummary/ProjectSummaryDisplay";
import { IProjectInformation } from "features/project/types";
import useAppDispatch from "hooks/useAppDispatch";
import useReduxSelector from "hooks/useReduxSelector";
import ReactGA from "react-ga4";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { resetLayout, setBack, setPageTitle, setTitleCtas } from "store/layoutSlice";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { ProjectSupplierDto } from "types/dtos/projectSuppliers/ProjectSupplierDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import ProjectStatus from "types/enums/projects/ProjectStatus";
import ProjectType from "types/enums/projects/ProjectType";

const ProjectSummaryContainer: React.FC = () => {
    const [identifiedSuppliers, setIdentifiedSuppliers] = useState<Array<ProjectSupplierDto>>([]);
    const [hasIdentifiedSuppliers, setHasIdentifiedSuppliers] = useState<boolean>(false);
    const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
    const [isInvitePopupOpen, setIsInvitePopupOpen] = useState(false);
    const [inviteData, setInviteData] = useState<IProjectInformation | null>(null);
    const [isProjectPreviewOpen, setIsProjectPreviewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { user_uuid: userUuid } = useReduxSelector((state) => state.authenticationState.accessToken);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const toast = useToaster();

    const { projectInformation, createOrUpdateProject, cancelProject, isSubmitting, setIsSubmitting, isFetching } =
        useProject();
    const { projectFiles } = useProjectFiles(projectInformation.uuid);
    const isQuote = projectInformation.type === ProjectType.Quote;
    const tProjType = isQuote ? "quote" : "tender";

    const fetchData = useCallback(async () => {
        if (!projectInformation.uuid) return;
        const suppliersResponse = await getIdentifiedSuppliers(projectInformation.uuid);

        if (suppliersResponse.data instanceof TransactionErrorDto) {
            toast.error(t(`projectSummary.${tProjType}.api.supplierLoadingError`));
            return;
        }

        if (suppliersResponse.data._embedded.totalCount < 1) {
            toast.informative(t(`projectSummary.${tProjType}.api.noIdentifiedSuppliers`));
            setHasIdentifiedSuppliers(false);
        } else {
            setHasIdentifiedSuppliers(true);
        }
        setIdentifiedSuppliers(suppliersResponse.data._embedded.items);
    }, [projectInformation.uuid, t, toast, tProjType]);

    useEffect(() => {
        setIsLoading(isFetching);
    }, [isFetching, setIsLoading]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        dispatch(resetLayout());
        dispatch(setPageTitle(t(`projectSummary.${tProjType}.pageTitle`)));
        dispatch(
            setTitleCtas([
                {
                    type: TitleCtaType.ProjectPreview,
                    onClick: () => setIsProjectPreviewOpen(true),
                },
            ]),
        );

        if (isQuote) {
            dispatch(
                setBack({
                    route: buildRoute(routes.projects.matching, { projectUuid: projectInformation.uuid }),
                }),
            );
        } else {
            dispatch(
                setBack({
                    route: buildRoute(routes.projects.sectionQuestions, { projectUuid: projectInformation.uuid }),
                }),
            );
        }

        return () => {
            dispatch(setTitleCtas(null));
        };
    }, [dispatch, projectInformation.uuid, t, isQuote, projectInformation, projectFiles, tProjType]);

    const removeSupplier = async (supplierUuid: string) => {
        if (!projectInformation.uuid) return;
        removeProjectSupplier(projectInformation.uuid, supplierUuid).then(() => {
            if (identifiedSuppliers.filter((supplier) => supplier.companyUuid !== supplierUuid).length < 1) {
                toast.informative(t(`projectSummary.${tProjType}.api.noIdentifiedSuppliers`));
                setHasIdentifiedSuppliers(false);
            }
            setIdentifiedSuppliers((prevState) => {
                return prevState.filter((supplier) => supplier.companyUuid !== supplierUuid);
            });
        });
    };

    const showInvitePopup = (values: IProjectInformation) => {
        setInviteData(values);
        setIsInvitePopupOpen(true);
    };

    const inviteSuppliersAndUpdateProject = useCallback(async () => {
        if (!(projectInformation.uuid && identifiedSuppliers.length > 0 && inviteData !== null)) return;

        await createOrUpdateProject(
            { ...inviteData, status: ProjectStatus.AwaitingResponses },
            {
                tenderResponseDeadline: true,
                status: true,
                clarificationQuestionDeadline: !isQuote,
                targetProjectStartDate: !isQuote,
                targetProjectCompleteDate: !isQuote,
            },
            false,
        );

        setIsSubmitting(true);
        const response = await addInvitedSuppliers(projectInformation.uuid, {
            changedByUserUuid: userUuid,
            supplierUuids: identifiedSuppliers.map((supplier) => supplier.companyUuid),
        });

        // We are accepting 404 errors until the email work is complete.
        // Currently, an error is thrown because the project is not self service and does not have a Maistro PM assigned which the template requires.
        if (response.status !== 200 && response.status !== 404) {
            toast.error(t(`projectSummary.${tProjType}.api.inviteSelectedSuppliersError`));
            setIsSubmitting(false);
            return;
        }

        navigate(buildPath(routes.projects.monitor, { projectUuid: projectInformation.uuid }));
    }, [
        createOrUpdateProject,
        identifiedSuppliers,
        projectInformation.uuid,
        navigate,
        setIsSubmitting,
        t,
        toast,
        userUuid,
        tProjType,
        isQuote,
        inviteData,
    ]);

    const save = async (values: IProjectInformation, dirtyValues: DirtyValues): Promise<void> => {
        return createOrUpdateProject(values, dirtyValues, false).then(async (updatedProjectUuid: string | null) => {
            if (!updatedProjectUuid) return Promise.reject(new Error(t("projectSummary.api.failProjectSave")));
            return Promise.resolve();
        });
    };

    if (isLoading) return <Loader />;

    return (
        <React.Fragment>
            <ProjectSummaryDisplay
                projectInformation={projectInformation}
                identifiedSuppliers={identifiedSuppliers}
                save={save}
                submit={(values) => showInvitePopup(values)}
                cancel={() => setIsCancelPopupOpen(true)}
                disableSubmit={isSubmitting || !hasIdentifiedSuppliers}
                projectUuid={projectInformation.uuid}
                removeSupplier={removeSupplier}
            />
            <Popup
                title={t("popups.cancelProject.title")}
                message={t("popups.cancelProject.message")}
                isOpen={isCancelPopupOpen}
                primaryActionText={t("popups.cancelProject.cta.primary")}
                onPrimaryAction={cancelProject}
                onClose={() => setIsCancelPopupOpen(false)}
                testid="cancel-project-popup"
                disabled={isSubmitting}
            />
            <Popup
                title={t(`popups.projectSummary.${tProjType}Submit.title`)}
                message={t(`popups.projectSummary.${tProjType}Submit.message`)}
                isOpen={isInvitePopupOpen}
                primaryActionText={t(`popups.projectSummary.${tProjType}Submit.cta.primary`)}
                onPrimaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: "Tender Summary - Invite Suppliers - Submit",
                    });
                    inviteSuppliersAndUpdateProject();
                }}
                secondaryActionText={t(`popups.projectSummary.${tProjType}Submit.cta.secondary`)}
                onSecondaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: "Tender Summary - Invite Suppliers - Cancel",
                    });
                    setIsInvitePopupOpen(false);
                }}
                onClose={() => setIsInvitePopupOpen(false)}
                testid="invite-suppliers-popup"
                disabled={isSubmitting}
            />
            <ProjectPreviewDrawer
                projectFiles={projectFiles}
                allowProjectEdit
                isOpen={isProjectPreviewOpen}
                close={() => setIsProjectPreviewOpen(false)}
            />
        </React.Fragment>
    );
};

export default ProjectSummaryContainer;
