import { Popup, useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { linkProjectFiles } from "api/fileApi";
import { createQuestion } from "api/questions/clarificationQuestionsApi";
import { getSupplierSubmissionsForProject } from "api/project/projectSubmissionsApi";
import { Loader } from "components";
import useFileUpload from "features/files/useFileUpload";
import BuyerAskQuestionDisplay from "features/project/clarificationQuestions/askQuestion/BuyerAskQuestionDisplay";
import useNavTabs from "features/project/hooks/useNavTabs";
import useProject from "features/project/hooks/useProject";
import useAppDispatch from "hooks/useAppDispatch";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { resetLayout, setBack, setPageTitle, setSecondaryNavItems, setTooltip } from "store/layoutSlice";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import EntityType from "types/enums/questions/EntityType";

const BuyerAskQuestionContainer: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
    const [isSubmittingQuestion, setIsSubmittingQuestion] = useState(false);
    const [suppliers, setSuppliers] = useState<Array<SupplierSubmissionResponseDto>>([]);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const toast = useToaster();

    const { projectInformation } = useProject();
    const { buyerTabItems } = useNavTabs();
    const { fileIsUploading, setFileIsUploading, projectFiles, setProjectFiles, removeFile } = useFileUpload(
        projectInformation.uuid,
        EntityType.Question,
    );

    useEffect(() => {
        dispatch(setPageTitle(t("clarificationQuestions.titles.ask")));
        dispatch(setTooltip(t("clarificationQuestions.tooltips.all")));
        dispatch(
            setBack({
                route: buildRoute(routes.projects.clarificationQuestions, { projectUuid: projectInformation.uuid }),
            }),
        );
        dispatch(setSecondaryNavItems(buyerTabItems));

        return () => {
            dispatch(resetLayout());
        };
    }, [buyerTabItems, dispatch, projectInformation.uuid, t]);

    useEffect(() => {
        const fetchSuppliers = async () => {
            if (!projectInformation.uuid) {
                return;
            }
            const supplierResponse = await getSupplierSubmissionsForProject(projectInformation.uuid);

            if (supplierResponse.data instanceof TransactionErrorDto || supplierResponse.status !== 200) {
                toast.error(t("monitorScreen.api.supplierLoadingError"));
                setIsLoading(false);
                return;
            }

            setSuppliers(supplierResponse.data._embedded.items);
            setIsLoading(false);
        };

        fetchSuppliers();
    }, [projectInformation.uuid, setIsLoading, t, toast]);

    const submitQuestion = async (
        question: string,
        isRhetorical: boolean,
        supplierUuids: Array<string>,
        section: string,
    ) => {
        setIsSubmittingQuestion(true);
        const response = await createQuestion(projectInformation.uuid, {
            entityType: section !== "" ? EntityType.Section : EntityType.Project,
            entityUuid: section !== "" ? section : projectInformation.uuid,
            questionText: question,
            recipients: supplierUuids,
            isRhetorical,
        });

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("clarificationQuestions.api.askQuestionError"));
            setIsSubmittingQuestion(false);
            return;
        }

        const newFiles = projectFiles.filter((file) => file.newUpload);
        if (newFiles.length) {
            const fileUuids = newFiles.map((file) => file.fileId);
            await linkProjectFiles(projectInformation.uuid, fileUuids, EntityType.Question, response.data.questionUuid);
        }

        navigate(buildPath(routes.projects.clarificationQuestions, { projectUuid: projectInformation.uuid }));
        toast.success(t("clarificationQuestions.api.askQuestionSuccess"));

        setIsSubmittingQuestion(false);
    };

    const cancelQuestion = useCallback(() => {
        navigate(buildPath(routes.projects.clarificationQuestions, { projectUuid: projectInformation.uuid }));
    }, [navigate, projectInformation.uuid]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <React.Fragment>
            <BuyerAskQuestionDisplay
                projectInformation={projectInformation}
                submitQuestion={submitQuestion}
                cancelQuestion={() => setIsCancelPopupOpen(true)}
                submittingQuestion={isSubmittingQuestion}
                suppliers={suppliers}
                files={projectFiles}
                removeFile={removeFile}
                setFiles={setProjectFiles}
                fileIsUploading={fileIsUploading}
                setFileIsUploading={setFileIsUploading}
            />
            <Popup
                title={t("popups.cancelQuestion.title")}
                message={t("popups.cancelQuestion.message")}
                isOpen={isCancelPopupOpen}
                primaryActionText={t("popups.cancelQuestion.cta.primary")}
                onPrimaryAction={cancelQuestion}
                onClose={() => setIsCancelPopupOpen(false)}
                testid="cancel-question-popup"
            />
        </React.Fragment>
    );
};

export default BuyerAskQuestionContainer;
