import { LoadingScreen } from "@maistro/components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Loader } from "components";
import TitleCtaType from "components/Layout/types/TitleCtaType";
import BuyerClarificationQuestionsDisplay from "features/project/clarificationQuestions/buyerQuestions/BuyerClarificationQuestionsDisplay";
import ProjectPreviewDrawer from "features/project/components/ProjectPreviewDrawer";
import useNavTabs from "features/project/hooks/useNavTabs";
import useProject from "features/project/hooks/useProject";
import useClarificationQuestion from "features/project/hooks/useProjectClarificationQuestions";
import useAppDispatch from "hooks/useAppDispatch";
import useReduxSelector from "hooks/useReduxSelector";
import routes from "routes/routePaths/RoutePaths";
import { resetLayout, setBack, setPageTitle, setSecondaryNavItems, setTitleCtas, setTooltip } from "store/layoutSlice";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";

const sortDesc = (a: QuestionResponseDto, b: QuestionResponseDto) =>
    new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();

const BuyerClarificationQuestionsContainer: React.FC = () => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [isProjectPreviewOpen, setIsProjectPreviewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { projectInformation } = useProject();
    const { buyerTabItems } = useNavTabs();

    const { clarificationQuestions, downloadClarificationsReport } = useClarificationQuestion(
        projectInformation.uuid,
        undefined,
        setIsLoading,
    );

    const { sections } = useReduxSelector((state) => state.sectionState);

    useEffect(() => {
        dispatch(setPageTitle(t("clarificationQuestions.titles.list")));
        dispatch(setTooltip(t("clarificationQuestions.tooltips.all")));
        dispatch(setSecondaryNavItems(buyerTabItems));
        dispatch(
            setBack({
                route: routes.common.dashboard,
            }),
        );
        dispatch(
            setTitleCtas([
                {
                    type: TitleCtaType.ProjectPreview,
                    onClick: () => setIsProjectPreviewOpen(true),
                },
            ]),
        );
        return () => {
            dispatch(resetLayout());
            dispatch(setBack(null));
        };
    }, [dispatch, buyerTabItems, t, projectInformation]);

    const handleDownloadClarificationsReport = () => {
        setIsDownloading(true);
        downloadClarificationsReport().finally(() => setIsDownloading(false));
    };

    if (isLoading) return <Loader />;
    if (!clarificationQuestions) return null;

    clarificationQuestions.sort(sortDesc);
    return (
        <div>
            {isDownloading && (
                <LoadingScreen
                    title={t("clarificationQuestions.loading.downloadTitle")}
                    message={t("clarificationQuestions.loading.downloadText")}
                    duration={35000}
                    testid="loading-screen"
                />
            )}
            <BuyerClarificationQuestionsDisplay
                projectInformation={projectInformation}
                downloadReport={handleDownloadClarificationsReport}
                clarificationQuestions={clarificationQuestions}
                sections={sections.items}
            />
            <ProjectPreviewDrawer isOpen={isProjectPreviewOpen} close={() => setIsProjectPreviewOpen(false)} />
        </div>
    );
};

export default BuyerClarificationQuestionsContainer;
