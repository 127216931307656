import { AxiosResponse } from "axios";

import { createAxiosInstance } from "api/commonApi";
import env from "config/EnvironmentVariables";
import { AnswerDto } from "types/dtos/questions/AnswerResponseDto";
import { CreateClarificationAnswerRequestDto } from "types/dtos/questions/CreateClarificationAnswerRequestDto";
import { CreateClarificationQuestionRequestDto } from "types/dtos/questions/CreateClarificationQuestionRequestDto";
import { GetClarificationQuestionRequestDto } from "types/dtos/questions/GetClarificationQuestionRequestDto";
import { ListQuestionsResponseDto } from "types/dtos/questions/ListQuestionsResponseDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { UpdateClarificationQuestionRequestDto } from "types/dtos/questions/UpdateClarificationQuestionRequestDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/projects";
const questionType = "clarification-questions";

export const getQuestion = (
    projectUuid: string,
    questionUuid: string,
    request: GetClarificationQuestionRequestDto,
): Promise<AxiosResponse<QuestionResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/${questionType}/${questionUuid}`;
    return api.get(url, {
        params: request,
    });
};

export const createQuestion = (
    projectUuid: string,
    request: CreateClarificationQuestionRequestDto,
): Promise<AxiosResponse<QuestionResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/${questionType}`;
    return api.post(url, request);
};

export const updateQuestion = (
    projectUuid: string,
    questionUuid: string,
    questionDto: UpdateClarificationQuestionRequestDto,
) => {
    const url = `${baseApiUrl}/${projectUuid}/${questionType}/${questionUuid}`;
    return api.patch(url, questionDto);
};

export const listQuestions = (
    projectUuid: string,
): Promise<AxiosResponse<ListQuestionsResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/${questionType}`;
    return api.get(url, {
        params: {
            includeAnswers: true,
            includeCompany: true,
        },
    });
};

// Answers
export const answerQuestion = (
    projectUuid: string,
    questionUuid: string,
    request: CreateClarificationAnswerRequestDto,
): Promise<AxiosResponse<AnswerDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/${questionType}/${questionUuid}/answers`;
    return api.post(url, request);
};
