import { faSquareExclamation } from "@fortawesome/pro-regular-svg-icons";
import { faSquareExclamation as faSquareExclamationSolid } from "@fortawesome/pro-solid-svg-icons";
import {
    Button,
    Copy,
    Heading,
    RadioButtonsField,
    ReadOnlyField,
    TextAreaField,
    TextField,
    Tooltip,
} from "@maistro/components";
import classNames from "classnames";
import { Form, Formik } from "formik";
import { isEmpty } from "lodash";
import React, { SetStateAction, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import { Paper } from "components";
import FileList from "components/FileList/FileList";
import { ICommonProps } from "components/shared";
import DisplayFileUploadField from "features/files/DisplayFileUploadFields";
import { IProjectResponseInformation } from "features/project/types";
import useCurrentUser from "hooks/useCurrentUser";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";
import { AnswerDto } from "types/dtos/questions/AnswerResponseDto";
import { CreateOrUpdateTenderCriteriaAnswerForSubmissionRequestDto } from "types/dtos/questions/CreateOrUpdateTenderCriteriaAnswerForSubmissionRequestDto";
import AnswerType from "types/enums/questions/AnswerType";
import SupplierSubmissionStatus from "types/enums/supplierSubmissions/SupplierSubmissionStatus";

const useStyles = createUseStyles((theme: ITheme) => ({
    gridContainer: {
        paddingTop: theme.spacing.small,
        display: "block",
    },
    bold: {
        fontWeight: 600,
    },
    sectionText: {
        gap: theme.spacing.medium,
        display: "flex",
    },
    questionLabel: {
        marginTop: theme.spacing.xLarge,
        marginBottom: theme.spacing.medium,
        display: "flex",
    },
    questionCount: {
        textAlign: "right",
        display: "flex",
        justifyContent: "flex-end",
        marginLeft: "auto",
        minWidth: "fit-content",
    },
    questionText: {
        marginBottom: theme.spacing.medium,
    },
    isConditional: {
        alignSelf: "auto",
    },
    answer: {
        display: "block",
        flexDirection: "row",
        "& label": {
            ...theme.typography.content.copy,
            fontWeight: 600,
        },
    },
    buttonContainer: {
        marginTop: theme.spacing.medium,
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        alignItems: "center",
        gap: `${theme.spacing.small}px ${theme.spacing.medium}px`,
        justifyContent: "center",
    },
    characterCount: {
        textAlign: "right",
        "&.exceeded": {
            color: theme.colors.error,
        },
    },
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        buttonContainer: {
            flexDirection: "row",
            justifyContent: "space-between",
        },
    },
}));

export interface IQuestionInformation {
    sectionName: string;
    sectionWeighting: number;
    questionCount: number;
    questionIndex: number;
    questionUuid: string;
    questionText: string;
    answerTypeId: string;
    isConditional: boolean;
    allowAttachments: boolean;
}

interface IAnswerQuestionFormProps extends ICommonProps {
    question: IQuestionInformation | null;
    selectedAnswer: AnswerDto | null;
    nextQuestionId: string | null;
    onSubmit: (answer: CreateOrUpdateTenderCriteriaAnswerForSubmissionRequestDto, moveNext: boolean) => void;
    setEditInProgress?: (inProgress: boolean) => void;
    buyerCompanyUuid: string;
    projectUuid?: string;
    questionFiles: FileDto[];
    projectResponseInformation: IProjectResponseInformation;
    files: FileDto[];
    setFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    removeFile: (file: FileDto | null) => void;
}

const AnswerQuestionForm: React.FC<IAnswerQuestionFormProps> = (props) => {
    const classes = useStyles();

    const {
        question,
        selectedAnswer,
        onSubmit,
        nextQuestionId,
        setEditInProgress,
        projectUuid,
        buyerCompanyUuid,
        questionFiles,
        projectResponseInformation,
        files,
        setFiles,
        removeFile,
    } = props;

    const [moveNext, setMoveNext] = useState(false);
    const [fileIsUploading, setFileIsUploading] = useState(false);
    const [characterCount, setCharacterCount] = useState(0);

    const { t } = useTranslation();

    const characterLimit = 4000;

    const { myCompanyUuid } = useCurrentUser();

    const canEditAnswer = useMemo(() => {
        return (
            projectResponseInformation &&
            (!projectResponseInformation.status ||
                [
                    SupplierSubmissionStatus.Invited,
                    SupplierSubmissionStatus.Interested,
                    SupplierSubmissionStatus.Responding,
                    SupplierSubmissionStatus.Submitted,
                ].includes(projectResponseInformation.status as SupplierSubmissionStatus))
        );
    }, [projectResponseInformation]);

    const validationSchema = () =>
        Yup.object({
            answerText: Yup.string().required(t("supplierTenderQuestions.edit.errors.answerText")),
        });

    const continueIsToASection = question?.questionIndex === question?.questionCount;

    const setAnswerFiles = (answerFiles: SetStateAction<FileDto[]>) => {
        if (setEditInProgress) {
            setEditInProgress(true);
        }
        setFiles(answerFiles);
    };

    useEffect(() => {
        setCharacterCount(selectedAnswer?.answerText.length ?? 0);
    }, [selectedAnswer, question?.questionUuid]);

    return (
        <Formik
            key={question?.questionUuid}
            initialValues={{
                answerUuid: selectedAnswer?.answerUuid ?? "",
                answerText: selectedAnswer?.answerText ?? "",
                answeringUserUuid: "",
                questionUuid: question?.questionUuid ?? "",
            }}
            onSubmit={(values) => {
                onSubmit(
                    {
                        ...values,
                    },
                    moveNext,
                );
            }}
            validationSchema={validationSchema()}
            validateOnMount
        >
            {({ errors, isValid, isSubmitting }) => (
                <Form className={classes.gridContainer}>
                    <Paper color="gray" className={classes.sectionText}>
                        <Heading variant="h3">{question?.sectionName ?? ""}</Heading>
                        <Heading variant="h3">{`${question?.sectionWeighting ?? 0}%`}</Heading>
                    </Paper>
                    <div className={classes.questionLabel}>
                        <Copy className={classes.bold}>{t("supplierTenderQuestions.edit.questionLabel")}</Copy>
                        {question?.isConditional && (
                            <div className={classes.isConditional}>
                                <Tooltip
                                    content={t("supplierTenderQuestions.conditionalQuestion")}
                                    position="left"
                                    start
                                    icon={faSquareExclamationSolid}
                                    hoverIcon={faSquareExclamation}
                                />
                            </div>
                        )}
                        <div className={classes.questionCount}>
                            <Copy className={classes.bold}>
                                {t("supplierTenderQuestions.edit.questionCount", {
                                    current: question?.questionIndex ?? 0,
                                    count: question?.questionCount ?? 0,
                                })}
                            </Copy>
                        </div>
                    </div>

                    <Copy className={classes.questionText}>{question?.questionText ?? ""}</Copy>
                    {projectUuid && (
                        <ReadOnlyField
                            className={classes.buyerDocuments}
                            value={
                                <FileList
                                    files={questionFiles.filter(
                                        (file) =>
                                            file.linkedCompanyUuid === buyerCompanyUuid ||
                                            file.linkedCompanyUuid == null,
                                    )}
                                    canDownload
                                    showDocumentNameOnly
                                />
                            }
                            testid="buyer-documents"
                        />
                    )}
                    <div className={classes.answer}>
                        {question?.answerTypeId === AnswerType.Standard && (
                            <div>
                                <TextAreaField
                                    testid="edit-answer-answer-text"
                                    label={t("supplierTenderQuestions.edit.answeringText")}
                                    name="answerText"
                                    maxLength={characterLimit}
                                    required
                                    withPassIcon={false}
                                    onChangeAction={(event) => {
                                        if (setEditInProgress) {
                                            setEditInProgress(true);
                                        }
                                        setCharacterCount(event.target.value.length);
                                    }}
                                />
                                <Copy
                                    variant="label"
                                    className={classNames(classes.characterCount, {
                                        exceeded: characterCount >= characterLimit,
                                    })}
                                >
                                    {t("supplierTenderQuestions.edit.characterCount", {
                                        count: characterCount,
                                        limit: characterLimit,
                                    })}
                                </Copy>
                            </div>
                        )}
                        {question?.answerTypeId === AnswerType.Numeric && (
                            <div className={classes.questionText}>
                                <TextField
                                    name="answerText"
                                    type="number"
                                    label={t("supplierTenderQuestions.edit.answeringText")}
                                    required
                                    testid="edit-answer-answer-numeric"
                                />
                            </div>
                        )}
                        {(question?.answerTypeId === AnswerType.YesNo ||
                            question?.answerTypeId === AnswerType.TrueFalse) && (
                            <div>
                                <RadioButtonsField
                                    className={classes.questionText}
                                    name="answerText"
                                    label={t("supplierTenderQuestions.edit.answeringText")}
                                    optionClassName={classes.size}
                                    options={
                                        question?.answerTypeId === AnswerType.YesNo
                                            ? [
                                                  {
                                                      label: "Yes",
                                                      value: "Yes",
                                                  },
                                                  {
                                                      label: "No",
                                                      value: "No",
                                                  },
                                              ]
                                            : [
                                                  {
                                                      label: "True",
                                                      value: "True",
                                                  },
                                                  {
                                                      label: "False",
                                                      value: "False",
                                                  },
                                              ]
                                    }
                                    columns={0}
                                    required
                                    testid="edit-answer-answer-yes-no"
                                />
                            </div>
                        )}
                        {question?.allowAttachments && (
                            <DisplayFileUploadField
                                testid="project-brief"
                                files={files.filter(
                                    (file) =>
                                        file.linkedCompanyUuid === myCompanyUuid ||
                                        file.newUpload ||
                                        file.linkedCompanyUuid == null,
                                )}
                                canManageFiles={canEditAnswer}
                                removeFile={removeFile}
                                setFiles={setAnswerFiles}
                                fileIsUploading={fileIsUploading}
                                setFileIsUploading={setFileIsUploading}
                            />
                        )}
                    </div>

                    <div className={classes.buttonContainer}>
                        <Button
                            testid="edit-answer-save-button"
                            type="submit"
                            label={t("supplierTenderQuestions.edit.saveAndClose")}
                            disabled={!isEmpty(errors) || !isValid || isSubmitting || fileIsUploading}
                            onClick={() => setMoveNext(false)}
                        />
                        {nextQuestionId ? (
                            <Button
                                testid="edit-answer-next-button"
                                type="submit"
                                variant="outline"
                                label={
                                    continueIsToASection
                                        ? t("supplierTenderQuestions.edit.continueToNextSection")
                                        : t("supplierTenderQuestions.edit.continueToNextQuestion")
                                }
                                disabled={!isEmpty(errors) || !isValid || isSubmitting || fileIsUploading}
                                onClick={() => setMoveNext(true)}
                            />
                        ) : (
                            <span></span>
                        )}
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default AnswerQuestionForm;
