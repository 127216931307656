import { AxiosResponse } from "axios";

import env from "config/EnvironmentVariables";
import { TaxonomyItemsDto } from "types/dtos/taxonomy/TaxonomyItemsDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { ITaxonomyItemsRequestDto } from "types/dtos/taxonomy/ITaxonomyItemsRequestDto";
import { IListTaxonomyItemsByKeywordFromTextResponse } from "types/dtos/taxonomy/IListTaxonomyItemsByKeywordFromTextResponse";
import { createAxiosInstance } from "api/commonApi";
import { SetTaxonomyForCompanyRequestDto } from "types/dtos/taxonomy/SetTaxonomyForCompanyRequestDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);

export const searchTaxonomy = (
    textSearch: string,
    skip = 0,
    take = 10,
): Promise<AxiosResponse<TaxonomyItemsDto> | AxiosResponse<TransactionErrorDto>> => {
    textSearch = textSearch.replace("&", "%26");
    const url = `/taxonomy/search?textSearch=${encodeURI(textSearch)}&skip=${skip}&take=${take}`;
    return api.get(url);
};

export const getTaxonomyItemsForProject = (
    projectUuid: string,
    includeRemoved = false,
): Promise<AxiosResponse<TaxonomyItemsDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `/projects/${projectUuid}/taxonomy-items?includeRemoved=${includeRemoved}`;
    return api.get(url);
};

export const setTaxonomyItemsForProject = (
    projectUuid: string,
    request: ITaxonomyItemsRequestDto,
): Promise<AxiosResponse<TaxonomyItemsDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `/projects/${projectUuid}/taxonomy-items`;
    return api.put(url, request);
};

export const taxonomyItemsFromText = (
    text: string,
): Promise<AxiosResponse<IListTaxonomyItemsByKeywordFromTextResponse> | AxiosResponse<TransactionErrorDto>> => {
    const url = `/taxonomy/taxonomy-items-from-text`;
    const request = { text };
    return api.post(url, request);
};

export const setTaxonomyItemsForCompany = (
    companyUuid: string,
    request: SetTaxonomyForCompanyRequestDto,
): Promise<AxiosResponse<TaxonomyItemsDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `/companies/${companyUuid}/taxonomy-items`;
    return api.put(url, request);
};

export const getTaxonomyItemsForCompany = (
    companyUuid: string,
): Promise<AxiosResponse<TaxonomyItemsDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `/companies/${companyUuid}/taxonomy-items`;
    return api.get(url);
};
