import { createAxiosInstance } from "api/commonApi";
import { AxiosResponse } from "axios";
import env from "config/EnvironmentVariables";
import { GetOrchestrationSystemStatusResponseDto } from "types/dtos/systemStatus/GetOrchestrationSystemStatusResponseDto";
import SystemStatusDto from "types/dtos/systemStatus/SystemStatusDto";

export const getClientVersion = () => {
    return env.ReleaseVersion;
};

export const getOrchestrationStatus = (): Promise<AxiosResponse<GetOrchestrationSystemStatusResponseDto>> => {
    const url = `${env.OrchestrationApiUrl}/system-status`;
    const api = createAxiosInstance(url);
    return api.get(url);
};

export const getIdentityStatus = (): Promise<AxiosResponse<SystemStatusDto>> => {
    const url = `${env.IdentityServerUrl}/api/system-status`;
    const api = createAxiosInstance(url);
    return api.get(url);
};
