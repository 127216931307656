// This is a workaround until i18next fixes compatability with react18
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Copy, Heading } from "@maistro/components";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import { getCompanyEmailSettings } from "api/company/companyApi";
import errorImage from "assets/images/error.svg";
import useReduxSelector from "hooks/useReduxSelector";
import ReactGA from "react-ga4";
import routes from "routes/routePaths/RoutePaths";
import { ITheme } from "styles/themes/types";
import { CompanyEmailSettingsDto } from "types/dtos/company/CompanyEmailSettingsDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        gap: theme.spacing.small,
        padding: theme.spacing.small,
    },

    image: {
        maxWidth: 250,
        width: "50%",
        height: "50%",
        marginBottom: theme.spacing.medium,
    },

    button: {
        marginTop: theme.spacing.medium,
    },

    highlight: {
        fontWeight: "bold",
    },
}));

const ErrorDisplay: React.FC = () => {
    const classes = useStyles();

    const [emailSettings, setEmailSettings] = useState<CompanyEmailSettingsDto>({
        companyUuid: "",
        defaultFromAddress: "",
    });
    const accessToken = useReduxSelector((state) => state.authenticationState?.accessToken);

    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (accessToken?.company_uuid) {
            getCompanyEmailSettings(accessToken.company_uuid).then((response) => {
                if (response.status === 200) {
                    setEmailSettings(response.data);
                }
            });
        }
    }, [accessToken]);

    return (
        <div className={classes.container}>
            <img src={errorImage} className={classes.image} alt="Error" />
            <Heading variant="h1" testid="error-title">
                {t("error.title")}
            </Heading>
            <Copy>
                <>
                    {t("error.copy")}{" "}
                    {!emailSettings.defaultFromTelephoneNumber ? (
                        <Trans t={t} i18nKey="error.contact">
                            If this problem persists, please contact our support team at{" "}
                            <span className={classes.highlight}>
                                {{ emailAddress: emailSettings.defaultFromAddress } as any}
                            </span>
                            {/* */}.
                        </Trans>
                    ) : (
                        <Trans t={t} i18nKey="error.contactWithTelephone">
                            If this problem persists, please contact our support team at{" "}
                            <span className={classes.highlight}>
                                {{ emailAddress: emailSettings.defaultFromAddress } as any}
                            </span>{" "}
                            or call{" "}
                            <span className={classes.highlight}>
                                {{ telephoneNumber: emailSettings.defaultFromTelephoneNumber } as any}
                            </span>
                            {/* */}.
                        </Trans>
                    )}
                </>
            </Copy>
            <Button
                label={t("error.returnToDashboard")}
                onClick={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: "Error Display - Return To Dashboard",
                    });
                    navigate(routes.common.dashboard.path);
                }}
                className={classes.button}
                testid="return-dashboard-button"
            />
        </div>
    );
};

export default ErrorDisplay;
