import { Copy } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";

import { getSectionTitle } from "features/project/helpers/sectionHelpers";
import useQuestionStyles from "features/project/shared/tender-questions/useQuestionStyles";
import QuestionRow from "features/project/components/tender-questions/QuestionRow";
import SectionRow from "features/project/tenderCriteria/components/SectionRow/SectionRow";
import { ISectionQuestions } from "types/dtos/questions/ISectionQuestions";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";
import { isSectionWithMandatoryQuestions, listTenderCriteriaQuestions } from "features/project/helpers/questionHelpers";

interface ITenderQuestionsDisplayProps {
    sections: SectionDto[];
    sectionQuestions: ISectionQuestions[];
}

const TenderQuestionsSummaryDisplay: React.FC<ITenderQuestionsDisplayProps> = (props) => {
    const { sections, sectionQuestions } = props;

    const questionClasses = useQuestionStyles();
    const { t } = useTranslation();

    const renderQuestionsForSection = (section: string) => {
        const questions = listTenderCriteriaQuestions(section, sectionQuestions);
        return questions.map((q) => (
            <div className={questionClasses.questionContainer} key={q.questionUuid}>
                <QuestionRow
                    testid={`question-${q.questionUuid}`}
                    className={questionClasses.questionRow}
                    questionText={q.questionText}
                    hasAttachments={q.hasAttachments}
                    hideActionIcons
                />
            </div>
        ));
    };

    return (
        <React.Fragment>
            <Copy className={questionClasses.copy}>{t("tenderQuestions.subTitle")}</Copy>
            {sections.map((section) => (
                <div key={`${section.id}`} className={questionClasses.flexContainer}>
                    <SectionRow
                        testid={`section-${section.id}`}
                        className={questionClasses.sectionRow}
                        title={getSectionTitle(section)}
                        totalQuestions={listTenderCriteriaQuestions(section.id, sectionQuestions).length}
                        weighting={section.weighting ?? 0}
                        hasMandatoryQuestions={isSectionWithMandatoryQuestions(section.id, sectionQuestions)}
                        expanded={section.id === sections[0]?.id}
                    >
                        {renderQuestionsForSection(section.id)}
                    </SectionRow>
                </div>
            ))}
        </React.Fragment>
    );
};

export default TenderQuestionsSummaryDisplay;
