import classNames from "classnames";
import React from "react";

import defaultImage from "assets/images/default-avatar.png";
import useStyles from "components/Avatar/AvatarStyles";
import { ICommonProps } from "components/shared";

export interface IAvatarProps extends ICommonProps {
    name: string;
    src?: string;
    round?: boolean;
    size?: "small" | "medium" | "large";
    backgroundColor?: string[] | string;
}

const Avatar: React.FC<IAvatarProps> = (props) => {
    const classes = useStyles(props);

    return (
        <div
            className={classNames(classes.component, props.className)}
            data-component="Avatar"
            data-testid={props.testid}
        >
            <span
                title={props.name}
                className={classNames(
                    { [classes.round]: props.round },
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    { [classes[props.size!]]: props.size },
                )}
                style={{
                    // If there's no name or src show a default image
                    backgroundImage: `url(${props.src || (!props.name && defaultImage)})`,
                }}
            >
                {
                    props.name &&
                        !props.src &&
                        props.name
                            .split(" ")
                            .map((word) => word[0])
                            .join("") // get first characters of words
                            .toUpperCase()
                            .substring(0, 3) // for long names just take first 3 words
                }
            </span>
        </div>
    );
};

Avatar.defaultProps = {
    size: "small",
} as IAvatarProps;

export default Avatar;
