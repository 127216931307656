import { Heading } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import PublicSectorSubSection from "features/company/profile/vetting/sections/frameworkAgreementsSubSections/PublicSectorSubSection";
import useSectionStyles from "features/company/profile/vetting/sections/useSectionStyles";

export const frameworkAgreementsValidationSchema = () =>
    Yup.object({
        hasPublicSectorFrameworkAgreement: Yup.boolean().nullable(),
        frameworkAgreementDetails: Yup.string().nullable(),
    });

const FrameworkAgreementsSection: React.FC = () => {
    const classes = useSectionStyles();

    const { t } = useTranslation();

    return (
        <div className={classes.section}>
            <Heading className={classes.heading} variant="h3">
                {t("companyVettingQuestionnaire.sections.frameworkAgreements.heading")}
            </Heading>
            <hr className={classes.hr} />
            <PublicSectorSubSection />
        </div>
    );
};

export default FrameworkAgreementsSection;
