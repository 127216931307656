enum SupplierSubmissionDisplayStatus {
    NotInterested = "Not Interested",
    Waiting = "Waiting",
    Received = "Received",
    Withdrawn = "Withdrawn",
    Successful = "Successful",
    Invited = "Invited",
    Responding = "Responding",
}

export default SupplierSubmissionDisplayStatus;
