import { Button, Heading } from "@maistro/components";
import classNames from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";

import Avatar from "assets/images/help-avatar.svg";
import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    buttonOnly: {
        width: "auto",
        minWidth: "auto",
        display: "block",
        gap: theme.spacing.small,
        alignItems: "center",
        marginBottom: theme.spacing.small,
    },
    overrideButtonDisplay: {},
    overrideBannerDisplay: {},
    banner: {
        width: "auto",
        minWidth: "auto",
        display: "none",
        gap: theme.spacing.small,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.colors.primary,
        padding: theme.spacing.medium,
        paddingTop: theme.spacing.small,
        paddingBottom: theme.spacing.small,
        marginBottom: theme.spacing.small,
        borderRadius: theme.spacing.medium,
        color: theme.colors.white,
    },
    image: {
        height: 45,
    },
    imageContainer: {
        height: 45,
        width: 45,
        borderRadius: "50%",
        border: "1px solid white",
    },
    heading: {
        color: "inherit",
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        direction: "ltr",
    },
    mediumFontSize: {
        fontSize: 12,
    },
    buttonWidthFix: {
        minWidth: "inherit",
    },

    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        banner: {
            display: "flex",
        },
        buttonOnly: {
            display: "none",
        },
        overrideButtonDisplay: {
            display: "block",
        },
        overrideBannerDisplay: {
            display: "none",
        },
    },
}));

interface IHelpBannerProps extends ICommonProps {
    title: string;
    message?: string;
    buttonLabel: string;
    onClick?: () => void;
    disabled?: boolean;
    buttonOnly?: boolean;
}

const HelpBanner: React.FC<IHelpBannerProps> = (props) => {
    const classes = useStyles();

    const renderCta = () => {
        return (
            <Button
                label={props.buttonLabel}
                onClick={() => {
                    if (props.onClick) {
                        props.onClick();
                    }
                }}
                darkBackground
                disabled={props.disabled}
                testid="banner-button"
                size="small"
                className={classes.buttonWidthFix}
            />
        );
    };

    return (
        <>
            <div
                className={classNames(classes.banner, props.className, {
                    [classes.overrideBannerDisplay]: props.buttonOnly,
                })}
            >
                <div className={classes.imageContainer}>
                    <img className={classes.image} src={Avatar} alt="Icon" />
                </div>
                <div>
                    <Heading className={classes.heading} variant="h3" testid="banner-title">
                        {props.title}
                    </Heading>
                    <div className={classNames(classes.mediumFontSize)}>{props.message}</div>
                </div>
                {renderCta()}
            </div>
            <div className={classNames(classes.buttonOnly, { [classes.overrideButtonDisplay]: props.buttonOnly })}>
                {renderCta()}
            </div>
        </>
    );
};

export default HelpBanner;
