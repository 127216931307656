/* eslint-disable fp/no-class */
class TransactionErrorDto {
    constructor(errors: { [key: string]: Array<string> }) {
        // eslint-disable-next-line fp/no-this
        this.errors = errors;
    }

    errors: { [key: string]: Array<string> } = {};
}

export default TransactionErrorDto;
