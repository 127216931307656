import { useToaster } from "@maistro/components";
import { IButtonProps } from "@maistro/components/dist/esm/types/components/Button/Button";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { IOptionProps } from "components/shared";
import useAppDispatch from "hooks/useAppDispatch";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import routes from "routes/routePaths/RoutePaths";
import {
    useLazyGetCompanySizeTypesQuery,
    useLazyGetCountriesQuery,
    useLazyGetIndustryTypesQuery,
} from "store/api/referenceDataApi";
import { resetLayout, setBack, setCtas, setMobileFooterCtas, setPageTitle, setTooltip } from "store/layoutSlice";
import { ITheme } from "styles/themes/types";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

export const useCompanyProfileFormStyles = createUseStyles((theme: ITheme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.units(7),
    },
}));

const useCompanyProfileForm = (isCreateMode: boolean) => {
    const [isLoading, setIsLoading] = useState(true);
    const [countryOptions, setCountryOptions] = useState<IOptionProps[]>([]);
    const [industryOptions, setIndustryOptions] = useState<IOptionProps[]>([]);
    const [sizeOptions, setSizeOptions] = useState<IOptionProps[]>([]);
    const [getCountries] = useLazyGetCountriesQuery();
    const [getCompanySizeTypes] = useLazyGetCompanySizeTypesQuery();
    const [getIndustryTypes] = useLazyGetIndustryTypesQuery();
    const [submissionDisabled, setSubmissionDisabled] = useState(true);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const toast = useToaster();

    const { userIsMaistro } = useCurrentUser();
    const { state } = useLocation();

    useEffect(() => {
        dispatch(setPageTitle(t("companyProfile.title")));
        dispatch(setTooltip(t("companyProfile.titleTooltip")));
        if (state) {
            dispatch(
                setBack({
                    route: state.route,
                }),
            );
        } else {
            dispatch(
                setBack({
                    route: userIsMaistro ? routes.common.companies : routes.common.dashboard,
                }),
            );
        }

        const activateProfile: IButtonProps = {
            label: isCreateMode ? t("companyProfile.activateProfile") : t("companyProfile.updateProfile"),
            testid: "activate-profile-button",
            type: "submit",
            form: "company-profile-form",
            disabled: submissionDisabled,
            onClick: () =>
                isCreateMode
                    ? ReactGA.event({
                          category: ga4Category.Button,
                          action: ga4Action.ButtonClick,
                          label: "Company Create - Activate Profile",
                      })
                    : ReactGA.event({
                          category: ga4Category.Button,
                          action: ga4Action.ButtonClick,
                          label: "Company - Update Profile",
                      }),
        };
        dispatch(setCtas([activateProfile]));
        dispatch(setMobileFooterCtas([activateProfile]));

        return () => {
            dispatch(resetLayout());
        };
    }, [dispatch, isCreateMode, state, submissionDisabled, t, userIsMaistro]);

    const fetchReferenceData = useCallback(() => {
        const countriesPromise = getCountries(undefined, true).unwrap();
        const industriesPromise = getIndustryTypes(undefined, true).unwrap();
        const sizesPromise = getCompanySizeTypes(undefined, true).unwrap();

        Promise.all([countriesPromise, industriesPromise, sizesPromise])
            .then(([countriesResult, industiresResult, sizesResult]) => {
                setCountryOptions(
                    countriesResult.countryTypes.map((country) => ({
                        label: country.name,
                        value: country.id.toString(),
                    })),
                );
                setIndustryOptions(
                    industiresResult.data.map((industry) => ({
                        label: industry.name,
                        value: industry.id.toString(),
                    })),
                );
                setSizeOptions(
                    sizesResult.data.map((size) => ({
                        label: size.name,
                        value: size.id.toString(),
                    })),
                );
            })
            .catch(() => {
                toast.error(t("companyProfile.api.listReferenceDataError"));
            })
            .finally(() => setIsLoading(false));
    }, [getCompanySizeTypes, getCountries, getIndustryTypes, t, toast]);

    useEffect(() => {
        fetchReferenceData();
    }, [fetchReferenceData]);

    return {
        isReferenceDataLoading: isLoading,
        countryOptions,
        industryOptions,
        sizeOptions,
        setSubmissionDisabled,
    };
};

export default useCompanyProfileForm;
