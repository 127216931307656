import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { Icon } from "@maistro/components";
import classNames from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    passIcon: {
        color: theme.colors.success,
        marginLeft: theme.spacing.xSmall,
    },
}));

interface IMaistroFormikPassIconProps extends ICommonProps {
    isVisible: boolean;
}

const MaistroFormikPassIcon: React.FC<IMaistroFormikPassIconProps> = (props) => {
    const classes = useStyles();
    const { isVisible, className, testid } = props;

    if (!isVisible) return null;

    return <Icon className={classNames(classes.passIcon, className)} icon={faCheckCircle} testid={testid} />;
};

export default MaistroFormikPassIcon;
