import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Button, SelectField, TextAreaField, ToggleField } from "@maistro/components";
import { Form, Formik } from "formik";
import { isEmpty } from "lodash";
import React, { SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import { ICommonProps, IOptionProps } from "components/shared";
import DisplayFileUploadField from "features/files/DisplayFileUploadFields";
import { IProjectInformation } from "features/project/types";
import ReactGA from "react-ga4";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import ProjectStatus from "types/enums/projects/ProjectStatus";
import AnswerType from "types/enums/questions/AnswerType";
import EntityType from "types/enums/questions/EntityType";
import QuestionType from "types/enums/questions/QuestionType";

const useStyles = createUseStyles((theme: ITheme) => ({
    inputContainer: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.large,
    },
    buttonContainer: {
        marginTop: theme.spacing.medium,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        gap: `${theme.spacing.xSmall}px ${theme.spacing.medium}px`,
        justifyContent: "center",
    },
}));

interface IEditQuestionFormProps extends ICommonProps {
    sections: IOptionProps[];
    selectedSectionId: string;
    selectedQuestion: QuestionResponseDto | null;
    onDelete: () => void;
    onSubmit: (question: QuestionResponseDto, closeDrawer: boolean) => Promise<void>;
    onSectionChange: () => void;
    setEditInProgress?: (inProgress: boolean) => void;
    projectInformation: IProjectInformation;
    files: FileDto[];
    setFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    removeFile: (file: FileDto | null) => void;
}

const EditQuestionForm: React.FC<IEditQuestionFormProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        selectedQuestion,
        selectedSectionId,
        onSubmit,
        sections,
        onDelete,
        onSectionChange,
        setEditInProgress,
        projectInformation,
        files,
        setFiles,
        removeFile,
    } = props;
    const [closeDrawerAfterSubmit, setCloseDrawerAfterSubmit] = useState(true);
    const [fileIsUploading, setFileIsUploading] = useState(false);

    const canEditQuestion = useMemo(() => {
        return (
            projectInformation &&
            (!projectInformation.status ||
                [ProjectStatus.Create, ProjectStatus.Invite].includes(projectInformation.status as ProjectStatus))
        );
    }, [projectInformation]);

    const validationSchema = () =>
        Yup.object({
            questionText: Yup.string().required(t("tenderQuestions.edit.errors.questionText")),
        });

    const addQuestionFiles = (questionFiles: SetStateAction<FileDto[]>) => {
        if (setEditInProgress) {
            setEditInProgress(true);
        }
        setFiles(questionFiles);
    };

    return (
        <Formik
            initialValues={
                {
                    questionUuid: selectedQuestion?.questionUuid ?? "",
                    questionType: QuestionType.TenderCriteria,
                    entityType: EntityType.Section,
                    entityUuid: selectedSectionId,
                    askingUserUuid: "",
                    answerTypeId:
                        selectedQuestion?.answerTypeId !== undefined
                            ? selectedQuestion.answerTypeId.toString()
                            : AnswerType.Standard,
                    questionText: selectedQuestion?.questionText ?? "",
                    isConditional: selectedQuestion?.isConditional ?? false,
                    disableFileAttachments: selectedQuestion?.disableFileAttachments ?? false,
                } as QuestionResponseDto
            }
            onSubmit={(values, { resetForm }) => {
                onSubmit(
                    {
                        ...values,
                        answersCount: 0,
                    },
                    closeDrawerAfterSubmit,
                );
                if (!closeDrawerAfterSubmit) {
                    resetForm();
                }
            }}
            validationSchema={validationSchema()}
            validateOnMount
        >
            {({ errors, isSubmitting }) => (
                <Form className={classes.inputContainer}>
                    <SelectField
                        testid="edit-question-sectionId"
                        label={t("tenderQuestions.edit.section")}
                        name="entityUuid"
                        options={sections}
                        onChange={onSectionChange}
                        withoutValidation
                    />
                    <TextAreaField
                        testid="edit-question-questionText"
                        label={t("tenderQuestions.edit.questionText")}
                        name="questionText"
                        required
                        withPassIcon={false}
                        onChangeAction={() => {
                            if (setEditInProgress) {
                                setEditInProgress(true);
                            }
                        }}
                    />
                    <SelectField
                        testid="edit-question-answerTypeId"
                        label={t("tenderQuestions.edit.answerType")}
                        name="answerTypeId"
                        disableSearch
                        options={[
                            {
                                label: "Standard",
                                value: AnswerType.Standard,
                            },
                            {
                                label: "Numeric",
                                value: AnswerType.Numeric,
                            },
                            {
                                label: "Yes/No",
                                value: AnswerType.YesNo,
                            },
                            {
                                label: "True/False",
                                value: AnswerType.TrueFalse,
                            },
                        ]}
                        withoutValidation
                    />
                    <DisplayFileUploadField
                        testid="project-brief"
                        files={files.filter(
                            (file) =>
                                file.linkedCompanyUuid === projectInformation.clientCompanyUuid ||
                                file.newUpload ||
                                file.linkedCompanyUuid == null,
                        )}
                        canManageFiles={canEditQuestion}
                        removeFile={removeFile}
                        setFiles={addQuestionFiles}
                        fileIsUploading={fileIsUploading}
                        setFileIsUploading={setFileIsUploading}
                    />
                    <ToggleField
                        testid="edit-question-is-conditional"
                        label={t("tenderQuestions.edit.isConditional")}
                        tooltip={t("tenderQuestions.edit.tooltips.isConditional")}
                        name="isConditional"
                    />
                    <ToggleField
                        testid="edit-question-disable-file-attachments"
                        label={t("tenderQuestions.edit.disableFileAttachments")}
                        tooltip={t("tenderQuestions.edit.tooltips.disableFileAttachments")}
                        name="disableFileAttachments"
                    />

                    <div className={classes.buttonContainer}>
                        <Button
                            testid="edit-question-save-and-close-button"
                            type="submit"
                            label={t("tenderQuestions.edit.saveAndClose")}
                            disabled={!isEmpty(errors) || fileIsUploading || isSubmitting}
                            onClick={() => {
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: `Tender Questions - Edit Question - Save And Close`,
                                });
                                setCloseDrawerAfterSubmit(true);
                            }}
                        />
                        {selectedQuestion === null ? (
                            <Button
                                testid="edit-question-save-and-add-another-button"
                                variant="outline"
                                type="submit"
                                label={t("tenderQuestions.edit.saveAndAddAnother")}
                                disabled={!isEmpty(errors) || fileIsUploading || isSubmitting}
                                onClick={() => {
                                    ReactGA.event({
                                        category: ga4Category.Button,
                                        action: ga4Action.ButtonClick,
                                        label: `Tender Questions - Edit Question - Save And Add Another`,
                                    });
                                    setCloseDrawerAfterSubmit(false);
                                }}
                                icon={faPlus}
                            />
                        ) : (
                            <Button
                                testid="edit-question-delete-button"
                                variant="outline"
                                type="button"
                                label={t("tenderQuestions.edit.delete")}
                                onClick={onDelete}
                            />
                        )}
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default EditQuestionForm;
