import { useToaster } from "@maistro/components";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { getTenderResponsesReport } from "api/reportingApi";
import useAppDispatch from "hooks/useAppDispatch";
import useReduxSelector from "hooks/useReduxSelector";
import { fetchTenderCriteria, setQuestionScore } from "store/projectSlice";
import { AnswerDto } from "types/dtos/questions/AnswerResponseDto";
import { ScoreResponseDto } from "types/dtos/questions/ScoreResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

export interface ITenderCriteriaConfig {
    projectUuid: string;
    supplierUuid?: string;
    myUuid?: string;
}

export const useTenderCriteria = (props: ITenderCriteriaConfig) => {
    const { projectUuid, supplierUuid, myUuid } = props;

    const {
        tenderCriteria: {
            questions,
            sections,
            completedContributorScorers,
            totalContributorScorers,
            isScoringCompleteForProject,
            isScoringCompleteForUser,
            contributorsForSuppliers,
        },
        isFetchingTender,
    } = useReduxSelector((state) => state.projectState);

    const toast = useToaster();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const downloadTenderResponsesReport = useCallback(async () => {
        if (!projectUuid) return;

        const response = await getTenderResponsesReport(projectUuid);

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("tenderCriteria.score.api.exportTenderResponsesError"));
            return;
        }

        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.download = t("tenderCriteria.score.tenderResponsesReportFileName");
        link.click();
    }, [projectUuid, t, toast]);

    const setQuestions = useCallback(
        (score: ScoreResponseDto, questionUuid: string) => {
            dispatch(setQuestionScore({ score, questionUuid }));
        },
        [dispatch],
    );

    const getTenderCriteria = useCallback(
        (userUuid: string) => {
            dispatch(fetchTenderCriteria({ userUuid }));
        },
        [dispatch],
    );

    const haveScores = useCallback(
        (answer: AnswerDto) => !!answer.scores && answer.scores.some((score) => score.updatedByUserUuid === myUuid),
        [myUuid],
    );

    const filteredQuestions = questions
        .map((question) => ({
            ...question,
            answers: question.answers.filter((answer) => answer.companyUuid === supplierUuid),
        }))
        .filter((question) => question.answers.length > 0);

    const answers = questions.flatMap((question) => question.answers);
    const allAnswersScored = answers.every(haveScores);

    return {
        questions,
        setQuestions,
        sections,
        completedContributorScorers,
        totalContributorScorers,
        contributorsForSuppliers,
        hasAnswers: questions.some((q) => q.answers.length > 0),
        isScoringCompleteForUser,
        isScoringCompleteForProject,
        downloadTenderResponsesReport,
        isFetching: isFetchingTender,
        getTenderCriteria,
        filteredQuestions,
        allAnswersScored,
        haveScores,
    };
};
