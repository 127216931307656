import { useCallback, useMemo } from "react";

import useReduxSelector from "hooks/useReduxSelector";
import { ALL_IDENTIFIER } from "types/consts/rolesPermissionsConstants";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

const useCurrentUser = () => {
    const { userPermissions } = useReduxSelector((state) => state.rolesPermissionsState);
    const { accessToken } = useReduxSelector((state) => state.authenticationState);
    const { isActive } = useReduxSelector((state) => state.companyState.profile);

    const myCompanyUuid = accessToken.company_uuid;
    const myUuid = accessToken.user_uuid;
    const myId = accessToken.sub;
    const myName = accessToken.name;

    const userHasPermission = useCallback(
        (permission: PermissionsEnum, companyContext?: string): boolean => {
            if (companyContext) {
                return userPermissions.some(
                    (p) =>
                        p.name === permission.toString() &&
                        (p.companyContext.toLowerCase() === companyContext.toLowerCase() ||
                            p.companyContext.toLowerCase() === ALL_IDENTIFIER),
                );
            }
            return userPermissions.some((p) => p.name === permission.toString());
        },
        [userPermissions],
    );

    const userHasCompanyPermission = useCallback(
        (permission: PermissionsEnum, companyContext?: string): boolean => {
            if (!companyContext) {
                companyContext = myCompanyUuid ? myCompanyUuid.toLowerCase() : ALL_IDENTIFIER;
            }

            return userPermissions.some(
                (p) =>
                    p.name === permission.toString() &&
                    p.companyContext.toLowerCase() === companyContext &&
                    p.projectContext.toLowerCase() === ALL_IDENTIFIER,
            );
        },
        [myCompanyUuid, userPermissions],
    );

    const userHasProjectPermission = useCallback(
        (permission: PermissionsEnum, projectContext: string, companyContext?: string): boolean => {
            if (!companyContext) {
                companyContext = myCompanyUuid ? myCompanyUuid.toLowerCase() : ALL_IDENTIFIER;
            }

            return userPermissions.some(
                (p) =>
                    p.name === permission.toString() &&
                    (p.companyContext.toLowerCase() === companyContext ||
                        p.companyContext.toLowerCase() === ALL_IDENTIFIER) &&
                    (p.projectContext.toLowerCase() === projectContext ||
                        p.projectContext.toLowerCase() === ALL_IDENTIFIER ||
                        p.companyContext.toLowerCase() === ALL_IDENTIFIER),
            );
        },
        [myCompanyUuid, userPermissions],
    );

    const userIsMaistro = useMemo(() => userHasPermission(PermissionsEnum.ManageSupport), [userHasPermission]);
    const userIsSupplier = useMemo(() => userHasPermission(PermissionsEnum.CanSupply), [userHasPermission]);
    const userIsBuyer = useMemo(() => userHasPermission(PermissionsEnum.CanBuy), [userHasPermission]);

    const userCanCreateProjects = useMemo(
        () => userHasCompanyPermission(PermissionsEnum.CreateProject) && (isActive || userIsMaistro),
        [isActive, userHasCompanyPermission, userIsMaistro],
    );

    return {
        userCanCreateProjects,
        userHasPermission,
        userHasCompanyPermission,
        userHasProjectPermission,
        myCompanyUuid,
        myUuid,
        myId,
        myName,
        userIsMaistro,
        userIsSupplier,
        userIsBuyer,
    };
};

export default useCurrentUser;
