import { DragDropContext, Draggable, DraggableLocation, Droppable, DropResult } from "@hello-pangea/dnd";
import { Button, Copy, Drawer, Popup, useToaster } from "@maistro/components";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { linkProjectFiles } from "api/fileApi";
import classNames from "classnames";
import HelpBanner from "components/HelpBanner/HelpBanner";
import useFileUpload from "features/files/useFileUpload";
import { getSectionTitle } from "features/project/helpers/sectionHelpers";
import useQuestionStyles from "features/project/shared/tender-questions/useQuestionStyles";
import SectionRowContainer from "features/project/tenderCriteria/components/SectionRowContainer";
import EditQuestionForm from "features/project/tenderCriteria/questions/EditQuestionForm";
import { IProjectInformation } from "features/project/types";
import ReactGA from "react-ga4";
import { createUseStyles } from "react-jss";
import { ITheme } from "styles/themes/types";
import { ISectionQuestions } from "types/dtos/questions/ISectionQuestions";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import EntityType from "types/enums/questions/EntityType";

const useStyles = createUseStyles((theme: ITheme) => ({
    bannerContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: theme.spacing.small,
    },
    copy: {
        marginBottom: theme.spacing.small,
    },
    fullHelpBanner: {},
    removeCopyMargin: {},
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        fullHelpBanner: {
            justifyContent: "flex-start",
            marginTop: theme.spacing.xSmall,
            marginBottom: theme.spacing.large,
        },
        removeCopyMargin: {
            marginBottom: 0,
        },
    },
}));

interface ITenderQuestionsDisplayProps {
    projectUuid?: string;
    sections: SectionDto[];
    addQuestionToSection: (question: QuestionResponseDto) => Promise<string | null>;
    deleteQuestionFromSection: (section: string, question: QuestionResponseDto) => void;
    editQuestionFromSection: (section: string, question: QuestionResponseDto) => void;
    questions: ISectionQuestions[];
    inviteSuppliers: () => void;
    projectInformation: IProjectInformation;
    generateQuestions: () => void;
    enableAIAssist: boolean;
    setSectionsReordered?: Dispatch<SetStateAction<SectionDto[]>>;
    setSectionsQuestionsReordered: Dispatch<SetStateAction<QuestionResponseDto[]>>;
    setQuestions: Dispatch<SetStateAction<ISectionQuestions[]>>;
    setSections: Dispatch<SetStateAction<SectionDto[]>>;
    sectionsQuestionsReordered: QuestionResponseDto[];
    isActionButtonsRendering: boolean;
}
const TenderQuestionsDisplay: React.FC<ITenderQuestionsDisplayProps> = (props) => {
    const questionClasses = useQuestionStyles();
    const classes = useStyles();

    const {
        projectUuid,
        sections,
        addQuestionToSection,
        deleteQuestionFromSection,
        editQuestionFromSection,
        questions,
        projectInformation,
        generateQuestions,
        enableAIAssist,
        setSectionsReordered,
        setSectionsQuestionsReordered,
        setQuestions,
        setSections,
        isActionButtonsRendering,
    } = props;

    const [isDeleteQuestionPopupOpen, setIsDeleteQuestionPopupOpen] = useState(false);
    const [isQuestionDrawerOpen, setIsQuestionDrawerOpen] = useState(false);
    const [selectedSectionUuid, setSelectedSectionUuid] = useState<string>("");
    const [selectedQuestion, setSelectedQuestion] = useState<QuestionResponseDto | null>(null);
    const [editInProgress, setEditInProgress] = useState(false);
    const [isConfirmClosePopupOpen, setIsConfirmClosePopupOpen] = useState(false);

    const { t } = useTranslation();
    const toast = useToaster();

    const {
        projectFiles: questionFiles,
        setProjectFiles: setQuestionFiles,
        removeFile,
    } = useFileUpload(projectUuid, EntityType.Question, selectedQuestion?.questionUuid);

    const helpBannerButtonOnly = useMemo(() => questions.some((sq) => sq.items.length > 0), [questions]);

    const doAllSectionsHaveQuestions = () => {
        const allHaveQuestions = questions.every((section) => section.items && section.items.length > 0);

        return allHaveQuestions;
    };

    const warnOfSectionChange = () => {
        toast.informative(t("tenderQuestions.edit.sectionChanged"));
    };

    const addProjectFiles = async (questionUuid: string) => {
        const newFiles = questionFiles.filter((file) => file.newUpload);
        if (newFiles.length && projectUuid) {
            const fileUuids = newFiles.map((file) => file.fileId);
            await linkProjectFiles(projectUuid, fileUuids, EntityType.Question, questionUuid);
            setQuestionFiles([]);
        }
    };

    const submitQuestion = async (question: QuestionResponseDto, closeDrawer: boolean) => {
        if (selectedSectionUuid === "" || question.entityUuid === "") {
            return;
        }

        const foundSection = questions.find((item) => item.id === question.entityUuid);
        if (foundSection) {
            question.orderIndex = foundSection.items.length ? foundSection.items.length : 0;
        }

        if (selectedQuestion === null) {
            const questionUuid = await addQuestionToSection(question);
            if (questionUuid) {
                question.questionUuid = questionUuid;
                setQuestions((prevQuestions) =>
                    prevQuestions.map((section) =>
                        section.id === question.entityUuid
                            ? {
                                  ...section,
                                  items: [...section.items, question],
                              }
                            : section,
                    ),
                );
                await addProjectFiles(questionUuid);
            }
        } else {
            editQuestionFromSection(selectedSectionUuid, question);
            setQuestions((prevQuestions) =>
                prevQuestions.map((section) =>
                    section.id === question.entityUuid
                        ? {
                              ...section,
                              items: section.items.map((item) =>
                                  item.questionUuid === question.questionUuid ? question : item,
                              ),
                          }
                        : section,
                ),
            );
            await addProjectFiles(question.questionUuid);
        }
        setIsQuestionDrawerOpen(!closeDrawer);
        setQuestionFiles([]);
        setEditInProgress(false);
    };

    const openQuestionDrawer = (sectionUuid: string, question: QuestionResponseDto | null) => {
        setQuestionFiles([]);
        setSelectedQuestion(question);
        setSelectedSectionUuid(sectionUuid);
        setIsQuestionDrawerOpen(true);
    };

    const confirmDrawerClose = () => {
        if (editInProgress) {
            setIsConfirmClosePopupOpen(true);
        } else {
            setIsQuestionDrawerOpen(false);
            setQuestionFiles([]);
        }
    };

    const getQuestionsForSection = (section: SectionDto) => {
        const sectionQuestions = questions.find((el) => el.id === section.id);
        return sectionQuestions;
    };

    const getStartExpanded = (section: SectionDto) => {
        const sectionQuestions = getQuestionsForSection(section);
        const startExpanded = sectionQuestions === undefined ? false : sectionQuestions.items.length > 0;
        return startExpanded;
    };

    const reorder = (list: QuestionResponseDto[], startIndex: number, endIndex: number): QuestionResponseDto[] => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const move = (
        source: QuestionResponseDto[],
        destination: QuestionResponseDto[],
        droppableSource: DraggableLocation,
        droppableDestination: DraggableLocation,
    ): Record<string, QuestionResponseDto[]> => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result: Record<string, QuestionResponseDto[]> = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    const onDragEnd = (result: DropResult) => {
        const { source, destination, type } = result;

        if (!destination) return;

        if (type === "section") {
            const updatedSections = Array.from(sections);
            const [movedSection] = updatedSections.splice(source.index, 1);
            updatedSections.splice(destination.index, 0, movedSection);

            updatedSections.forEach((section, index) => {
                section.orderIndex = index;
            });

            if (setSectionsReordered) {
                setSectionsReordered(updatedSections);
                setSections(updatedSections);
            }
        } else if (type === "question") {
            const sourceSectionId = source.droppableId;
            const destinationSectionId = destination.droppableId;

            const sourceSection = questions.find((section) => section.id === sourceSectionId);
            const destinationSection = questions.find((section) => section.id === destinationSectionId);

            if (!sourceSection || !destinationSection) return;

            if (sourceSectionId === destinationSectionId) {
                const reorderedQuestions = reorder(sourceSection.items, source.index, destination.index);

                setQuestions((prev) =>
                    prev.map((section) =>
                        section.id === sourceSectionId ? { ...section, items: reorderedQuestions } : section,
                    ),
                );

                const updatedQuestions = reorderedQuestions.map((item, index) => ({
                    ...item,
                    orderIndex: index,
                    entityUuid: destinationSectionId,
                }));

                setSectionsQuestionsReordered((prev) => {
                    const newQuestions = updatedQuestions.filter(
                        (newItem) => !prev.some((prevItem) => prevItem.questionUuid === newItem.questionUuid),
                    );

                    const updatedExistingQuestions = prev.map((prevItem) => {
                        const updatedItem = updatedQuestions.find(
                            (newItem) => newItem.questionUuid === prevItem.questionUuid,
                        );
                        return updatedItem ? { ...prevItem, ...updatedItem } : prevItem;
                    });

                    return [...updatedExistingQuestions, ...newQuestions];
                });
            } else {
                const moveResult = move(sourceSection.items, destinationSection.items, source, destination);

                setQuestions((prev) =>
                    prev.map((section) => {
                        if (section.id === sourceSectionId) {
                            return { ...section, items: moveResult[sourceSectionId] };
                        }
                        if (section.id === destinationSectionId) {
                            return { ...section, items: moveResult[destinationSectionId] };
                        }
                        return section;
                    }),
                );

                const updatedSourceQuestions = moveResult[sourceSectionId].map((item, index) => ({
                    ...item,
                    orderIndex: index,
                    entityUuid: sourceSectionId,
                }));
                const updatedDestinationQuestions = moveResult[destinationSectionId].map((item, index) => ({
                    ...item,
                    orderIndex: index,
                    entityUuid: destinationSectionId,
                }));

                const updatedQuestions = [...updatedSourceQuestions, ...updatedDestinationQuestions];

                setSectionsQuestionsReordered((prev) => {
                    const newQuestions = updatedQuestions.filter(
                        (newItem) => !prev.some((prevItem) => prevItem.questionUuid === newItem.questionUuid),
                    );

                    const updatedExistingQuestions = prev.map((prevItem) => {
                        const updatedItem = updatedQuestions.find(
                            (newItem) => newItem.questionUuid === prevItem.questionUuid,
                        );
                        return updatedItem ? { ...prevItem, ...updatedItem } : prevItem;
                    });

                    return [...updatedExistingQuestions, ...newQuestions];
                });
            }
        }
    };
    return (
        <React.Fragment>
            <Copy
                className={classNames(classes.copy, {
                    [classes.removeCopyMargin]: helpBannerButtonOnly && enableAIAssist,
                })}
            >
                {t("tenderQuestions.subTitle")}
            </Copy>
            {enableAIAssist && (
                <div
                    className={classNames(classes.bannerContainer, { [classes.fullHelpBanner]: !helpBannerButtonOnly })}
                >
                    <HelpBanner
                        title={t("tenderQuestions.aiAssist.bannerTitle")}
                        message={t("tenderQuestions.aiAssist.bannerMessage")}
                        buttonLabel={t("tenderQuestions.aiAssist.bannerCta")}
                        onClick={() => {
                            ReactGA.event({
                                category: ga4Category.Button,
                                action: ga4Action.ButtonClick,
                                label: `Tender Questions - Help Banner`,
                            });
                            generateQuestions();
                        }}
                        buttonOnly={helpBannerButtonOnly}
                    />
                </div>
            )}
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="sections" direction="vertical" type="section">
                    {(droppableSectionsProvided) => (
                        <div ref={droppableSectionsProvided.innerRef} {...droppableSectionsProvided.droppableProps}>
                            {sections.map((section, index) => (
                                <Draggable key={section.id} draggableId={section.id} index={index}>
                                    {(draggableSectionsProvided) => (
                                        <div
                                            ref={draggableSectionsProvided.innerRef}
                                            {...draggableSectionsProvided.draggableProps}
                                            {...draggableSectionsProvided.dragHandleProps}
                                        >
                                            <SectionRowContainer
                                                key={section.id}
                                                section={section}
                                                questions={getQuestionsForSection(section)}
                                                startExpanded={getStartExpanded(section)}
                                                addQuestion={(sectionId: string) => openQuestionDrawer(sectionId, null)}
                                                editQuestion={(sectionId: string, question: QuestionResponseDto) =>
                                                    openQuestionDrawer(sectionId, question)
                                                }
                                                deleteQuestion={(sectionId: string, question: QuestionResponseDto) => {
                                                    setIsDeleteQuestionPopupOpen(true);
                                                    setSelectedSectionUuid(sectionId);
                                                    setSelectedQuestion(question);
                                                }}
                                                isActionButtonsRendering={isActionButtonsRendering}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {droppableSectionsProvided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <div className={questionClasses.buttonContainer}>
                <Button
                    label={`${t("tenderQuestions.reviewTender")}`}
                    disabled={!doAllSectionsHaveQuestions() || isActionButtonsRendering}
                    chevron
                    size="large"
                    onClick={() => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: `Tender Questions - Review Tender`,
                        });
                        props.inviteSuppliers();
                    }}
                    testid="invite-suppliers"
                />
            </div>
            <Popup
                title={t("tenderQuestions.deleteQuestionTitle")}
                message={t("tenderQuestions.deleteQuestionSubTitle")}
                isOpen={isDeleteQuestionPopupOpen}
                primaryActionText={t("tenderQuestions.deleteQuestionConfirm")}
                secondaryActionText={t("common.cancel")}
                onSecondaryAction={() => setIsDeleteQuestionPopupOpen(false)}
                onPrimaryAction={() => {
                    if (selectedSectionUuid && selectedQuestion) {
                        deleteQuestionFromSection(selectedSectionUuid, selectedQuestion);
                    }
                    setIsDeleteQuestionPopupOpen(false);
                    setIsQuestionDrawerOpen(false);
                }}
                onClose={() => setIsDeleteQuestionPopupOpen(false)}
                testid="delete-question-popup"
            />
            <Popup
                title={t("tenderQuestions.confirmCloseTitle")}
                message={t("tenderQuestions.confirmCloseSubTitle")}
                isOpen={isConfirmClosePopupOpen}
                primaryActionText={t("tenderQuestions.confirmCloseConfirm")}
                secondaryActionText={t("common.cancel")}
                onSecondaryAction={() => setIsConfirmClosePopupOpen(false)}
                onPrimaryAction={() => {
                    setEditInProgress(false);
                    setIsConfirmClosePopupOpen(false);
                    setIsQuestionDrawerOpen(false);
                }}
                onClose={() => setIsConfirmClosePopupOpen(false)}
                testid="confirm-edit-drawer-close-popup"
            />
            <Drawer
                className={questionClasses.editQuestionDrawer}
                isOpen={isQuestionDrawerOpen}
                onClose={() => confirmDrawerClose()}
                testid="edit-question-drawer"
            >
                <EditQuestionForm
                    selectedSectionId={selectedSectionUuid}
                    selectedQuestion={selectedQuestion}
                    sections={sections.map((section) => ({
                        value: section.id,
                        label: `${getSectionTitle(section)} ${section.weighting}%`,
                    }))}
                    onDelete={() => setIsDeleteQuestionPopupOpen(true)}
                    onSubmit={submitQuestion}
                    onSectionChange={() => warnOfSectionChange()}
                    setEditInProgress={setEditInProgress}
                    projectInformation={projectInformation}
                    files={questionFiles}
                    setFiles={setQuestionFiles}
                    removeFile={removeFile}
                />
            </Drawer>
        </React.Fragment>
    );
};

export default TenderQuestionsDisplay;
