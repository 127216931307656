import { validate as validateUuid } from "uuid";

interface IValidationService {
    isValidUrl: (url: string) => boolean;
    isValidUuid: (uuid?: string) => boolean;
}

const validationService: IValidationService = {
    isValidUrl: (url) => {
        const urlRegex =
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/g;
        return urlRegex.test(url);
    },

    isValidUuid: (uuid) => {
        return !!uuid && validateUuid(uuid);
    },
};

export default validationService;
