import { faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Button, Copy, Drawer, Heading, IOption, TextButton } from "@maistro/components";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import SearchSelect from "components/SearchSelect/SearchSelect";
import { IOptionProps } from "components/shared";
import SupplierNameColumn from "features/project/monitor/components/SupplierNameColumn";
import { IProjectInformation } from "features/project/types";
import { buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import colorService from "services/colorService";
import { ITheme } from "styles/themes/types";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import ProjectType from "types/enums/projects/ProjectType";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },

    supplier: {
        backgroundColor: colorService.toHexWithOpacity(theme.colors.gray, 0.2),
        borderRadius: theme.spacing.small,
        padding: theme.spacing.medium,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: theme.spacing.small,
    },

    inviteSuppliers: {
        marginTop: theme.spacing.xLarge,
        display: "flex",
        justifyContent: "center",
    },
}));

interface IAddSupplierDrawerProps {
    project: IProjectInformation;
    isOpen: boolean;
    setIsAddSupplierDrawerOpen: Dispatch<SetStateAction<boolean>>;
    supplierOptions: IOptionProps[];
    identifiedSuppliers: SupplierSubmissionResponseDto[];
    selectSupplier: (supplierUuid: string) => void;
    unselectSupplier: (supplierUuid: string) => void;
    inviteSuppliers: () => void;
    searchText: string;
    setSearchText: (searchText: string) => void;
}

const AddSupplierDrawer: React.FC<IAddSupplierDrawerProps> = (props) => {
    const classes = useStyles();

    const {
        project,
        isOpen,
        setIsAddSupplierDrawerOpen,
        supplierOptions,
        identifiedSuppliers,
        selectSupplier,
        unselectSupplier,
        inviteSuppliers,
        searchText,
        setSearchText,
    } = props;

    const [selectedSupplierOption, setSelectedSupplierOption] = useState<IOption>();

    const { t } = useTranslation();

    const isTender = project.type === ProjectType.Tender;

    const onAddSupplier = () => {
        if (!selectedSupplierOption) return;

        selectSupplier(selectedSupplierOption.value);
        setSearchText("");
        setSelectedSupplierOption(undefined);
    };

    const handleRemoveSupplier = (supplier: SupplierSubmissionResponseDto) => {
        unselectSupplier(supplier.supplierCompanyUuid);
    };

    return (
        <Drawer
            contentClassName={classes.container}
            isOpen={isOpen}
            onClose={() => setIsAddSupplierDrawerOpen(false)}
            testid="add-supplier-drawer"
        >
            <Heading variant="h2">{t("monitorScreen.addSupplierDrawer.title")}</Heading>
            <Copy>{t("monitorScreen.addSupplierDrawer.subtitle")}</Copy>
            <SearchSelect
                name="searchSuppliers"
                placeholder={t("monitorScreen.addSupplierDrawer.searchSuppliers")}
                options={supplierOptions}
                searchText={searchText}
                onSelect={setSelectedSupplierOption}
                setSearchText={setSearchText}
                testid="company-suppliers-search-bar"
            />
            {selectedSupplierOption && (
                <Button
                    label={t("monitorScreen.addSupplierDrawer.add")}
                    size="small"
                    variant="outline"
                    onClick={onAddSupplier}
                    icon={faPlus}
                    testid="add-supplier-button"
                />
            )}
            {[...identifiedSuppliers]
                .sort((a, b) => a._embedded.supplierCompanyName.localeCompare(b._embedded.supplierCompanyName))
                .map((supplier) => (
                    <div key={supplier.supplierCompanyUuid} className={classes.supplier}>
                        <SupplierNameColumn
                            name={supplier._embedded.supplierCompanyName}
                            companyUuid={supplier.supplierCompanyUuid}
                            matchRating={supplier._included.matchRating}
                            navigationState={{
                                route: buildRoute(routes.projects.monitor, {
                                    projectUuid: project.uuid,
                                }),
                            }}
                        />
                        <TextButton
                            label={t("common.remove")}
                            icon={faTimes}
                            onClick={() => handleRemoveSupplier(supplier)}
                            testid={`${supplier.supplierCompanyUuid}-remove-button`}
                        />
                    </div>
                ))}

            {identifiedSuppliers.length > 0 && (
                <div className={classes.inviteSuppliers}>
                    <Button
                        label={t(`monitorScreen.addSupplierDrawer.inviteTo${isTender ? "Tender" : "Quote"}`)}
                        size="large"
                        chevron
                        onClick={inviteSuppliers}
                        testid="invite-suppliers-button"
                    />
                </div>
            )}
        </Drawer>
    );
};

export default AddSupplierDrawer;
