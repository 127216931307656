import { useToaster } from "@maistro/components";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useListBuyerSupplierContractsApiQuery } from "store/api/buyerSupplierRelationshipsApi";

const useListContracts = (buyerUuid: string, supplierUuid: string) => {
    const { t } = useTranslation();
    const toast = useToaster();

    const { data, isLoading, isError } = useListBuyerSupplierContractsApiQuery({ buyerUuid, supplierUuid });

    useEffect(() => {
        if (isError) {
            toast.error(t("companySuppliers.contracts.api.fetchError"));
        }
    }, [isError, t, toast]);

    return {
        data,
        isLoading,
    };
};

export default useListContracts;
