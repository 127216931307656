import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import TitleCtaType from "components/Layout/types/TitleCtaType";
import useNavTabs from "features/project/hooks/useNavTabs";
import useAppDispatch from "hooks/useAppDispatch";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import {
    resetLayout,
    setBack,
    setCtas,
    setPageTitle,
    setSecondaryNavItems,
    setTitleCtas,
    setTooltip,
} from "store/layoutSlice";
import { BasicCompanyDto } from "types/dtos/company/BasicCompanyDto";
import { SubmissionAdditionalInformationDto } from "types/dtos/projects/supplierSubmissions/SubmissionAdditionalInformationDto";
import ProjectStatus from "types/enums/projects/ProjectStatus";

interface IMonitorLayoutProps {
    isQuote: boolean;
    isLoading: boolean;
    setIsProjectPreviewOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsDocumentDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsAdditionalInformationDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    canAskQuestion: boolean;
    projectUuid: string;
    documentSupplier?: BasicCompanyDto;
    additionalInformation?: SubmissionAdditionalInformationDto;
    projectStatus?: string;
}

const useMonitorLayout = ({
    isQuote,
    isLoading,
    setIsProjectPreviewOpen,
    setIsDocumentDrawerOpen,
    setIsAdditionalInformationDrawerOpen,
    canAskQuestion,
    projectUuid,
    documentSupplier,
    additionalInformation,
    projectStatus,
}: IMonitorLayoutProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { buyerTabItems } = useNavTabs();

    const renderTooltip = useCallback(() => {
        if (isQuote) {
            return [
                {
                    text: t("monitorScreen.tooltips.matchRating"),
                    fieldName: t("monitorScreen.table.matchRating"),
                },
                {
                    text: t("monitorScreen.tooltips.supplierPrice"),
                    fieldName: t("monitorScreen.table.supplierPrice"),
                },
                {
                    text: t("monitorScreen.tooltips.quoteScore"),
                    fieldName: t("monitorScreen.table.quoteScore"),
                },
            ];
        }

        return [
            {
                text: t("monitorScreen.tooltips.matchRating"),
                fieldName: t("monitorScreen.table.matchRating"),
            },
            {
                text: t("monitorScreen.tooltips.tenderScore"),
                fieldName: t("monitorScreen.table.quoteScore"),
            },
        ];
    }, [isQuote, t]);

    useEffect(() => {
        dispatch(setBack(null));
        dispatch(setCtas(null));

        if (!isLoading) {
            dispatch(setPageTitle(t("monitorScreen.title")));
            dispatch(setSecondaryNavItems(buyerTabItems));
            dispatch(setTooltip(renderTooltip()));

            if (projectStatus === ProjectStatus.Approval) {
                dispatch(
                    setBack({
                        route: buildRoute(routes.projects.monitorApprovals, { projectUuid }),
                    }),
                );
            }

            const titleCtas = [
                {
                    type: TitleCtaType.ProjectPreview,
                    onClick: () => setIsProjectPreviewOpen(true),
                },
            ];

            if (canAskQuestion) {
                titleCtas.push({
                    type: TitleCtaType.AskQuestion,
                    onClick: () => navigate(buildPath(routes.projects.askClarificationQuestion, { projectUuid })),
                });
            }
            dispatch(setTitleCtas(titleCtas));
        }

        return () => {
            dispatch(resetLayout());
        };
    }, [
        buyerTabItems,
        dispatch,
        navigate,
        renderTooltip,
        t,
        isLoading,
        canAskQuestion,
        setIsProjectPreviewOpen,
        projectUuid,
        projectStatus,
    ]);

    useEffect(() => {
        setIsDocumentDrawerOpen(!!documentSupplier);
    }, [documentSupplier, setIsDocumentDrawerOpen]);

    useEffect(() => {
        setIsAdditionalInformationDrawerOpen(!!additionalInformation);
    }, [additionalInformation, setIsAdditionalInformationDrawerOpen]);
};

export default useMonitorLayout;
