/* eslint-disable @typescript-eslint/no-explicit-any */
import { AutosaveForm, TextAreaField } from "@maistro/components";
import { Formik } from "formik";
import { range } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import { createAnswerScore, updateAnswerScore } from "api/questions/tenderCriteriaQuestionsApi";
import { ScoreSelectField } from "components";
import { ICommonProps } from "components/shared";
import validationService from "services/validationService";
import { ITheme } from "styles/themes/types";
import { ScoreResponseDto } from "types/dtos/questions/ScoreResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    form: {
        marginTop: theme.spacing.small,

        "& > div:not(:last-child)": {
            marginBottom: theme.spacing.medium,
        },
    },
}));

interface IScoringFormProps extends ICommonProps {
    projectUuid: string;
    questionUuid: string;
    answerUuid: string;
    score: ScoreResponseDto | undefined;
    setScore: (score: ScoreResponseDto) => void;
}

const ScoringForm: React.FC<IScoringFormProps> = (props) => {
    const classes = useStyles();

    const { projectUuid, questionUuid, answerUuid, score, setScore, testid } = props;

    const { t } = useTranslation();

    const createOrUpdateScore = (values: any) => {
        const scoreUuid = score?.scoreUuid;
        if (scoreUuid && validationService.isValidUuid(scoreUuid)) {
            return updateAnswerScore(projectUuid, questionUuid, answerUuid, { ...values, scoreUuid });
        }

        return createAnswerScore(projectUuid, questionUuid, answerUuid, values);
    };

    const saveScore = async (values: any): Promise<void> => {
        if (values.score.length === 0) {
            return Promise.reject(new Error(t("tenderCriteria.score.api.saveReasonBeforeScoreError")));
        }
        return createOrUpdateScore(values).then((response) => {
            if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                return Promise.reject(new Error("Create failed"));
            }

            setScore(response.data);
            return Promise.resolve();
        });
    };

    return (
        <Formik
            initialValues={score ?? { score: "", comment: "" }}
            validationSchema={Yup.object({
                score: Yup.number().required(),
                comment: Yup.string(),
            })}
            onSubmit={(values) => saveScore(values)}
        >
            {({ values }) => (
                <AutosaveForm<typeof values>
                    className={classes.form}
                    saveDebounceMs={500}
                    onSave={() => saveScore(values)}
                    successMessage={t("tenderCriteria.score.api.saveScoreSuccess")}
                    errorMessage={t("tenderCriteria.score.api.saveScoreError")}
                >
                    <ScoreSelectField
                        name="score"
                        label={t("tenderCriteria.score.score.label")}
                        tooltip={[
                            { text: t("tenderCriteria.score.score.tooltip.introduction") },
                            {
                                fieldName: t("tenderCriteria.score.score.tooltip.0.key"),
                                text: t("tenderCriteria.score.score.tooltip.0.text"),
                            },
                            {
                                fieldName: t("tenderCriteria.score.score.tooltip.1-3.key"),
                                text: t("tenderCriteria.score.score.tooltip.1-3.text"),
                            },
                            {
                                fieldName: t("tenderCriteria.score.score.tooltip.4-6.key"),
                                text: t("tenderCriteria.score.score.tooltip.4-6.text"),
                            },
                            {
                                fieldName: t("tenderCriteria.score.score.tooltip.7-9.key"),
                                text: t("tenderCriteria.score.score.tooltip.7-9.text"),
                            },
                            {
                                fieldName: t("tenderCriteria.score.score.tooltip.10.key"),
                                text: t("tenderCriteria.score.score.tooltip.10.text"),
                            },
                        ]}
                        required
                        values={range(0, 11)}
                        testid={`${testid}-score`}
                    />
                    <TextAreaField
                        name="comment"
                        label={t("tenderCriteria.score.reason.label")}
                        tooltip={t("tenderCriteria.score.reason.tooltip")}
                        withPassIcon={false}
                        testid={`${testid}-reason`}
                    />
                </AutosaveForm>
            )}
        </Formik>
    );
};

export default ScoringForm;
