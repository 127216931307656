import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import AddUserToProject from "features/project/shared/users/AddUserToProject";
import { IProjectInformation } from "features/project/types";
import { CompanyUserDto } from "types/dtos/company/CompanyUserDto";
import { ProjectUserDto } from "types/dtos/projects/users/ProjectUserDto";
import RolesEnum from "types/enums/rolesPermissions/RolesEnum";

interface IProjectContributorsFieldProps {
    companyUsers: CompanyUserDto[];
    handleContributorSearchTextChange: (searchText: string) => void;
}

const ProjectContributorsField: React.FC<IProjectContributorsFieldProps> = ({
    companyUsers,
    handleContributorSearchTextChange,
}) => {
    const { values, setFieldValue, setFieldTouched } = useFormikContext<IProjectInformation>();

    const { t } = useTranslation();

    const handleOnSelect = (contributorUuid: string) => {
        const contributor = companyUsers.find((user) => user.userUuid === contributorUuid);
        if (contributor) {
            const contributorToAdd = {
                ...contributor,
                role: {
                    name: RolesEnum.ProjectContributor,
                },
            };
            setFieldValue("contributors", [...values.contributors, contributorToAdd]);
        }
        setFieldTouched("contributors");
    };

    const handleOnDelete = (contributor: ProjectUserDto) => {
        setFieldValue(
            "contributors",
            values.contributors.filter((c) => c.userUuid !== contributor.userUuid),
        );
        setFieldTouched("contributors");
    };

    return (
        <AddUserToProject
            name="contributors"
            label={t("projectBrief.contributors.label")}
            placeholder={t("projectBrief.contributors.placeholder")}
            tooltip={t("projectBrief.contributors.tooltip")}
            companyUsers={companyUsers}
            handleUserSearch={handleContributorSearchTextChange}
            projectUsers={values.contributors}
            onSelect={handleOnSelect}
            onRowDelete={handleOnDelete}
            testid="project-brief-contributors"
        />
    );
};

export default ProjectContributorsField;
