import { Alert, Button, ICommonProps } from "@maistro/components";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import ProjectInformationDisplay from "features/project/clarificationQuestions/ProjectInformationDisplay";
import ReadOnlyAnswerDisplay from "features/project/clarificationQuestions/ReadOnlyAnswerDisplay";
import ReadOnlyQuestionDisplay from "features/project/clarificationQuestions/ReadOnlyQuestionDisplay";
import SupplierAnswerQuestionForm from "features/project/clarificationQuestions/answerQuestion/SupplierAnswerQuestionForm";
import { IProjectResponseInformation } from "features/project/types";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import ProjectStatus from "types/enums/projects/ProjectStatus";

const useStyles = createUseStyles((theme: ITheme) => ({
    grid: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.large,
    },
    centered: {
        margin: "auto",
    },
}));

interface ISupplierAnswerQuestionDisplayProps extends ICommonProps {
    cancelAnswer: () => void;
    project: IProjectResponseInformation;
    question?: QuestionResponseDto;
    submitAnswer: (answer: string) => void;
    sectionType?: string;
    files?: FileDto[];
    setFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    removeFile: (file: FileDto | null) => void;
    fileIsUploading: boolean;
    setFileIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
}

const SupplierAnswerQuestionDisplay: React.FC<ISupplierAnswerQuestionDisplayProps> = (props) => {
    const classes = useStyles();

    const {
        cancelAnswer,
        project,
        question,
        submitAnswer,
        sectionType,
        files,
        setFiles,
        removeFile,
        fileIsUploading,
        setFileIsUploading,
    } = props;

    const navigate = useNavigate();
    const { t } = useTranslation();

    const { myCompanyUuid } = useCurrentUser();

    const viewAllQuestions = useCallback(() => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: "Question Summary - View All Questions",
        });
        navigate(
            buildPath(routes.projectResponses.clarificationQuestions, {
                projectUuid: project.projectUuid,
                responseUuid: project.responseUuid,
            }),
        );
    }, [navigate, project.projectUuid, project.responseUuid]);

    const questionsLockedStatuses = useMemo(
        () => [ProjectStatus.Complete.toString(), ProjectStatus.CompleteAtReview.toString()],
        [],
    );

    if (!(question && project)) return null;

    const canAnswerQuestion =
        !questionsLockedStatuses.includes(project.projectStatus) &&
        !question.answersCount &&
        !question.isRhetorical &&
        question.companyUuid !== myCompanyUuid;

    return (
        <div className={classes.grid}>
            <ProjectInformationDisplay
                projectName={project.projectName}
                responseDate={project.tenderResponseDeadline}
            />
            <ReadOnlyQuestionDisplay
                question={question}
                isAnonymous={question.companyUuid !== project.buyerCompanyUuid}
                isQuestionAuthor={myCompanyUuid === question.companyUuid}
                sectionType={sectionType}
                projectUuid={project.projectUuid}
            />
            {question.answersCount > 0 && (
                <ReadOnlyAnswerDisplay
                    question={question}
                    isAnswerAuthor={question.answers.find((a) => a.companyUuid === myCompanyUuid) != null}
                    isQuestionAuthor={question?.companyUuid === myCompanyUuid}
                    projectUuid={project.projectUuid}
                />
            )}
            {question.isRhetorical && (
                <Alert
                    label={t("clarificationQuestions.noResponseRequired")}
                    type="info"
                    testid="no-response-required"
                />
            )}
            {canAnswerQuestion && (
                <SupplierAnswerQuestionForm
                    submit={submitAnswer}
                    cancel={cancelAnswer}
                    files={files}
                    removeFile={removeFile}
                    setFiles={setFiles}
                    fileIsUploading={fileIsUploading}
                    setFileIsUploading={setFileIsUploading}
                />
            )}
            {!canAnswerQuestion && (
                <Button
                    className={classes.centered}
                    label={t("clarificationQuestions.viewAllQuestions")}
                    size="large"
                    onClick={viewAllQuestions}
                    testid="view-all-questions-button"
                />
            )}
        </div>
    );
};

export default SupplierAnswerQuestionDisplay;
