import { faChevronLeft, faChevronRight, faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { Copy, Drawer, Icon, TextButton } from "@maistro/components";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles, useTheme } from "react-jss";

import DisplayChecklistItems from "components/Layout/StepperNav/DisplayChecklistItems";
import DisplayNavigationItems from "components/Layout/StepperNav/DisplayNavigationItems";
import useAppDispatch from "hooks/useAppDispatch";
import useReduxSelector from "hooks/useReduxSelector";
import useScreenLayout from "hooks/useScreenLayout";
import { setStepperNavigationDesktopWidth } from "store/navigationSlice";
import { ITheme } from "styles/themes/types";

export interface IStepperNavStyles {
    desktopWidth: number;
}

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        width: (props: IStepperNavStyles) => props.desktopWidth,
        height: "100%",
        position: "fixed",
        marginTop: theme.layout.navBarHeight,
        paddingTop: theme.spacing.small,
        transition: "width 0.1s ease-in-out",
    },
    stepper: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.colors.white,
        flexGrow: 1,
        borderRadius: `0 ${theme.spacing.medium}px 0 0`,
        boxShadow: `0 0 ${theme.spacing.xSmall}px 0 ${theme.colors.gray}`,
    },
    expandCollapseIcon: {
        textAlign: "end",
        marginTop: theme.spacing.small,
        marginRight: theme.spacing.small,
    },
    title: {
        overflowWrap: "anywhere",
        lineHeight: "20px",
    },
    titleContainer: {
        margin: theme.spacing.medium,
    },
    npsWrapper: {
        display: "inline-flex",
        gap: theme.spacing.xSmall,
        alignItems: "center",
    },
    mobileIcon: {
        display: "flex",
        alignItems: "center",
        marginRight: theme.spacing.xSmall,
    },
    content: {
        overflowY: "auto",
        paddingBottom: theme.spacing.xLarge * 2,
    },
    stepperItemsContainer: {
        display: "flex",
        flexDirection: "column",
        listStyleType: "none",
        marginTop: theme.spacing.small,
    },
    stepperActionsContainer: {
        marginTop: theme.spacing.xLarge,
        display: "flex",
        flexDirection: "column",
    },
    stepperAction: {
        padding: `${theme.spacing.xSmall}px ${theme.spacing.small}px`,
        marginLeft: theme.spacing.medium,
    },
}));

const StepperNav: React.FC = () => {
    const theme = useTheme<ITheme>();

    const [isStepperOpen, setIsStepperOpen] = useState(false);
    const {
        desktopWidth,
        stepperTitle,
        stepperSubTitle,
        stepperNavigationItems,
        stepperActions,
        stepperChecklistItems,
    } = useReduxSelector((state) => state.navigationState.stepperNavigation);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { onMobile } = useScreenLayout();

    const classes = useStyles({
        desktopWidth,
    });

    const isDesktopExpanded = useMemo(() => {
        return desktopWidth === theme.layout.navBarWidth;
    }, [desktopWidth, theme.layout.navBarWidth]);

    const content = () => {
        return (
            <div className={classes.content}>
                {stepperTitle && (
                    <div className={classes.titleContainer}>
                        {isDesktopExpanded && <Copy className={classes.title}>{stepperTitle}</Copy>}
                    </div>
                )}
                {stepperSubTitle && (
                    <div className={classes.titleContainer}>
                        {isDesktopExpanded && (
                            <Copy variant="label">
                                <span className={classes.npsWrapper}>
                                    {stepperSubTitle}
                                    <Icon
                                        size="small"
                                        icon={faInfoCircle}
                                        alt={t("companyProfile.stepperNavigation.npsTooltip")}
                                        testid="nps-tooltip"
                                    />
                                </span>
                            </Copy>
                        )}
                    </div>
                )}

                <div className={classes.stepperItemsContainer}>
                    {stepperNavigationItems && stepperNavigationItems.length > 0 && (
                        <DisplayNavigationItems
                            stepperNavigationItems={stepperNavigationItems}
                            isDesktopExpanded={isDesktopExpanded}
                            setIsStepperOpen={setIsStepperOpen}
                        />
                    )}
                    {stepperChecklistItems && isDesktopExpanded && (
                        <DisplayChecklistItems
                            stepperChecklistItems={stepperChecklistItems}
                            setIsStepperOpen={setIsStepperOpen}
                        />
                    )}
                    {stepperActions && isDesktopExpanded && (
                        <div className={classes.stepperActionsContainer}>
                            {stepperActions.map((stepperAction) => (
                                <TextButton
                                    {...stepperAction}
                                    className={classes.stepperAction}
                                    key={stepperAction.label}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    if (onMobile) {
        return (
            <>
                <Icon
                    icon={faEllipsisVertical}
                    className={classNames(classes.mobileIcon)}
                    size="large"
                    onClick={() => setIsStepperOpen(true)}
                    testid="stepper-nav-ellipsis"
                />
                <Drawer isOpen={isStepperOpen} onClose={() => setIsStepperOpen(false)} testid="stepper-nav-drawer">
                    {content()}
                </Drawer>
            </>
        );
    }

    return (
        <div className={classes.container}>
            <div className={classNames(classes.stepper)}>
                <Icon
                    className={classes.expandCollapseIcon}
                    icon={isDesktopExpanded ? faChevronLeft : faChevronRight}
                    alt={isDesktopExpanded ? "Collapse" : "Expand"}
                    onClick={() => {
                        if (isDesktopExpanded) {
                            dispatch(setStepperNavigationDesktopWidth(34));
                        } else {
                            dispatch(setStepperNavigationDesktopWidth(theme.layout.navBarWidth));
                        }
                    }}
                    size="small"
                    testid="stepper-nav-chevron"
                />
                {content()}
            </div>
        </div>
    );
};

export default StepperNav;
