import { Button, DateField, Drawer, Heading, ICommonProps, SelectField, TextField } from "@maistro/components";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import DisplayVettingFileUploadField from "features/company/profile/vetting/files/DisplayVettingFileUploadField";
import ReactGA from "react-ga4";
import { useGetAccreditationTypesQuery } from "store/api/referenceDataApi";
import { ITheme } from "styles/themes/types";
import { AccreditationDto } from "types/dtos/company/vettingInformation/AccreditationDto";
import FileDto from "types/dtos/files/FileDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

interface IAddOrEditAccreditationDrawerProps extends ICommonProps {
    isOpen: boolean;
    onClose: () => void;
    accreditation?: AccreditationDto;
    onSaveAccreditation: (accreditation: AccreditationDto) => void;
}

const useStyles = createUseStyles((theme: ITheme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
        marginTop: theme.spacing.large,
    },
    buttons: {
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing.medium,
        marginTop: theme.spacing.medium,
    },
}));

const minDate = new Date();

const AddOrEditAccreditationDrawer: React.FC<IAddOrEditAccreditationDrawerProps> = ({
    isOpen,
    onClose,
    accreditation,
    onSaveAccreditation,
}) => {
    const { t } = useTranslation();

    const validationSchema = () =>
        Yup.object({
            typeId: Yup.string().required(
                t("companyVettingQuestionnaire.sections.ethics.quality.accreditations.form.typeId.required"),
            ),
            otherTypeName: Yup.string().when("typeId", {
                is: (value: string) => value === "0",
                then: (schema) =>
                    schema.required(
                        t(
                            "companyVettingQuestionnaire.sections.ethics.quality.accreditations.form.otherTypeName.required",
                        ),
                    ),
                otherwise: (schema) => schema.nullable(),
            }),
            expiryDate: Yup.date()
                .typeError(
                    t("companyVettingQuestionnaire.sections.ethics.quality.accreditations.form.expiryDate.required"),
                )
                .nullable()
                .required(
                    t("companyVettingQuestionnaire.sections.ethics.quality.accreditations.form.expiryDate.required"),
                )
                .min(
                    minDate,
                    t("companyVettingQuestionnaire.sections.ethics.quality.accreditations.form.expiryDate.minError"),
                ),
        });

    const classes = useStyles();

    const { data: accreditationTypes } = useGetAccreditationTypesQuery(undefined);

    const [accreditationFiles, setAccreditationFiles] = useState<FileDto[]>(accreditation?.files ?? []);
    const [fileIsUploading, setFileIsUploading] = useState<boolean>(false);

    useEffect(() => {
        setAccreditationFiles(accreditation?.files ?? []);
    }, [accreditation?.files, setAccreditationFiles]);

    const onSubmit = useCallback(
        (values: AccreditationDto) => {
            if (!accreditationFiles || accreditationFiles.length < 1) {
                return;
            }

            onSaveAccreditation({
                ...values,
                files: accreditationFiles,
            });
            setAccreditationFiles([]);
        },
        [accreditationFiles, onSaveAccreditation, setAccreditationFiles],
    );
    return (
        <Drawer isOpen={isOpen} onClose={onClose}>
            <Heading variant="h2">
                {accreditation
                    ? t("companyVettingQuestionnaire.sections.ethics.quality.accreditations.editAccreditation")
                    : t("companyVettingQuestionnaire.sections.ethics.quality.accreditations.addAccreditation")}
            </Heading>
            <Formik
                initialValues={
                    {
                        typeId: accreditation?.typeId.toString() ?? "",
                        otherTypeName: accreditation?.otherTypeName,
                        expiryDate: accreditation?.expiryDate,
                    } as AccreditationDto
                }
                onSubmit={(values) => onSubmit(values)}
                validationSchema={validationSchema}
            >
                {({ dirty, isValid, values }) => (
                    <Form className={classes.form}>
                        <SelectField
                            testid="type-id-field"
                            name="typeId"
                            required
                            label={t(
                                "companyVettingQuestionnaire.sections.ethics.quality.accreditations.form.typeId.label",
                            )}
                            options={accreditationTypes?.data.map((type) => ({
                                value: type.id.toString(),
                                label: t(
                                    `companyVettingQuestionnaire.sections.ethics.quality.accreditations.form.typeId.options.${type.id}`,
                                ),
                            }))}
                        />
                        {values.typeId === "0" && (
                            <TextField
                                testid="other-type-name-field"
                                name="otherTypeName"
                                required
                                label={t(
                                    "companyVettingQuestionnaire.sections.ethics.quality.accreditations.form.otherTypeName.label",
                                )}
                            />
                        )}
                        <DateField
                            testid="expiry-date-field"
                            label={t(
                                "companyVettingQuestionnaire.sections.ethics.quality.accreditations.form.expiryDate.label",
                            )}
                            name="expiryDate"
                            required
                            minDate={minDate}
                            fullWidth
                        />
                        <DisplayVettingFileUploadField
                            testid="accreditation-file-upload-filed"
                            files={accreditationFiles}
                            setFiles={setAccreditationFiles}
                            fileIsUploading={fileIsUploading}
                            setFileIsUploading={setFileIsUploading}
                            name="accreditationFiles"
                            multiple={false}
                            required
                            label={t("companyVettingQuestionnaire.fileUpload.label")}
                        />
                        <div className={classes.buttons}>
                            <Button
                                testid="cancel-button"
                                onClick={() => {
                                    ReactGA.event({
                                        category: ga4Category.Button,
                                        action: ga4Action.ButtonClick,
                                        label: `Company Vetting - Add Accreditation - Cancel`,
                                    });
                                    onClose();
                                }}
                                variant="outline"
                                label={t("common.cancel")}
                            />
                            <Button
                                testid="save-accreditation-button"
                                type="submit"
                                label={
                                    accreditation
                                        ? t(
                                              "companyVettingQuestionnaire.sections.ethics.quality.accreditations.editAccreditation",
                                          )
                                        : t(
                                              "companyVettingQuestionnaire.sections.ethics.quality.accreditations.addAccreditation",
                                          )
                                }
                                disabled={!dirty || !(isValid && accreditationFiles.length > 0)}
                                onClick={() =>
                                    ReactGA.event({
                                        category: ga4Category.Button,
                                        action: ga4Action.ButtonClick,
                                        label: `Company Vetting - Add Accreditation - Submit`,
                                    })
                                }
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </Drawer>
    );
};

export default AddOrEditAccreditationDrawer;
