import { from, Route } from "routes/helpers/RoutesHelper";

interface CompanyParams {
    companyUuid: string;
}

interface GroupParams {
    groupUuid: string;
}

interface ContractParams {
    supplierUuid: string;
}

const companyRoot: Route<CompanyParams> = { path: "/companies/:companyUuid" };
const supplierCompanyProfile = from(companyRoot, {
    path: "/profile",
});
const companySuppliers = from(companyRoot, {
    path: "/suppliers",
    translationKey: "companySuppliers",
});
const companyContracts = from(companyRoot, {
    path: "/supplier/:supplierUuid/contracts",
} as Route<ContractParams>);
const companyAddresses = from(companyRoot, {
    path: "/addresses",
    translationKey: "companyAddresses",
});
const userGroup = from(companyRoot, {
    path: "/user-groups/:groupUuid",
} as Route<GroupParams>);
const userGroups = from(companyRoot, {
    path: "/user-groups",
});
const approvals = from(companyRoot, {
    path: "/approvals",
});

const createCompany: Route = {
    path: "/companies/create",
};
const companyProfile = from(companyRoot, { path: "", translationKey: "companyProfile" });

const companyUsers = from(companyRoot, {
    path: "/users",
});
const companyServices = from(companyRoot, {
    path: "/services",
});
const companyBuyerLists = from(companyRoot, {
    path: "/buyer-lists",
});
const companyVetting = from(companyRoot, {
    path: "/vetting",
});

export const companiesRoute = from(companyRoot, { path: "/*" });
export default {
    approvals,
    supplierCompanyProfile,
    companySuppliers,
    companyContracts,
    companyAddresses,
    createCompany,
    companyProfile,
    userGroup,
    userGroups,
    companyUsers,
    companyServices,
    companyBuyerLists,
    companyVetting,
};
