import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Button, Copy, Drawer, Heading, IOption, Popup, Table, TextButton } from "@maistro/components";
import SearchSelect from "components/SearchSelect/SearchSelect";
import sortingAlgorithms from "features/helpers/sortingAlgorithms";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ReactGA from "react-ga4";
import { createUseStyles } from "react-jss";
import { ITheme } from "styles/themes/types";
import { CompanyResultDto } from "types/dtos/company/CompanyResultDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    drawerHeading: {
        marginBottom: theme.spacing.large,
    },
    drawerContainer: {
        display: "flex",
        gap: theme.spacing.medium,
        flexDirection: "column",
    },
    alignRight: {
        textAlign: "right",
    },
    removeTableHeadingBorder: {
        "& thead": {
            "& tr": {
                "& th": {
                    borderBottomWidth: 0,
                },
            },
        },
    },
}));

interface IAddSuppliersDrawer {
    isOpen: boolean;
    onClose: () => void;
    onAdd: (suppliers: Array<IOption>) => void;
    searchForUnlistedSuppliers: (searchQuery: string) => Promise<Array<CompanyResultDto>>;
    companyName: string;
}

const AddSuppliersDrawer: React.FC<IAddSuppliersDrawer> = ({
    isOpen,
    onClose,
    onAdd,
    companyName,
    searchForUnlistedSuppliers,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [showUnsavedChangesPopup, setShowUnsavedChangesPopup] = useState(false);
    const [enableUnsavedChangesPopup, setEnableUnsavedChangesPopup] = useState(false);
    const [selectedSuppliers, setSelectedSuppliers] = useState<Array<IOption>>([]);
    const [unlistedSuppliers, setUnlistedSuppliers] = useState<Array<IOption>>([]);
    const [searchText, setSearchText] = useState("");

    const { sortSelectOptionsByLabel } = sortingAlgorithms();

    const onCloseDrawer = (): void => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Company Suppliers - Add Supplier - Cancel`,
        });
        if (enableUnsavedChangesPopup) {
            setShowUnsavedChangesPopup(true);
        } else {
            onClose();
        }
    };

    const selectSupplier = (supplier: IOption | undefined) => {
        if (supplier?.label) {
            ReactGA.event({
                category: ga4Category.Search,
                action: ga4Action.Search,
                label: `Company Suppliers - Search Suppliers - `.concat(supplier.label),
            });
        }
        if (supplier && !selectedSuppliers.map((s) => s.value).includes(supplier.value)) {
            setSelectedSuppliers([...selectedSuppliers, supplier].sort(sortSelectOptionsByLabel));
            setUnlistedSuppliers([]);
            setSearchText("");
        }
    };

    const deselectSupplier = (supplier: IOption) => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Company Suppliers - Remove Supplier - `.concat(supplier?.label || "No Name"),
        });
        setSelectedSuppliers(selectedSuppliers.filter((s) => s.value !== supplier.value));
        setUnlistedSuppliers([]);
        setSearchText("");
    };

    const addSuppliers = () => {
        onAdd(selectedSuppliers);
        setSelectedSuppliers([]);
    };

    useEffect(() => {
        setEnableUnsavedChangesPopup(selectedSuppliers.length > 0);
    }, [selectedSuppliers]);

    useEffect(() => {
        if (searchText.length < 3) {
            setUnlistedSuppliers([]);
            return;
        }
        searchForUnlistedSuppliers(searchText)
            .then((searchResults) => {
                const filteredOptions = searchResults
                    .map((r) => ({ label: r.registeredName, value: r.companyUuid }) as IOption)
                    .filter((r) => !selectedSuppliers.map((s) => s.value).includes(r.value));
                setUnlistedSuppliers(filteredOptions);
            })
            .catch(() => {
                setUnlistedSuppliers([]);
            });

        // Removed selectedSuppliers from deps otherwise it gets called when setUnlistedSuppliers is updated
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    const tableColumns = [
        {
            name: "companyName",
            priorityLevel: 1,
            position: 1,
            minWidth: 200,
            contentWrap: true,
            render: (supplier: IOption) => <Copy testid={`${supplier.value}-name`}>{supplier.label}</Copy>,
        },
        {
            name: "remove",
            priorityLevel: 2,
            position: 2,
            minWidth: 100,
            render: (supplier: IOption) => (
                <div className={classes.alignRight}>
                    <TextButton
                        label={t("common.remove")}
                        onClick={() => deselectSupplier(supplier)}
                        testid={`${supplier.value}-remove-button`}
                        icon={faTimes}
                    />
                </div>
            ),
        },
    ];

    return (
        <>
            <Drawer
                isOpen={isOpen}
                onClose={onCloseDrawer}
                contentClassName={classes.drawerContainer}
                testid="add-suppliers-drawer"
            >
                <Heading variant="h2" className={classes.drawerHeading}>
                    {t("companySuppliers.addSuppliers.title")}
                </Heading>
                <Copy>{t("companySuppliers.addSuppliers.description", { BuyerName: companyName })}</Copy>
                <SearchSelect
                    name="selectSuppliers"
                    placeholder={t("companySuppliers.search")}
                    options={unlistedSuppliers}
                    testid="select-suppliers-to-add"
                    onSelect={selectSupplier}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    updateSearchTextOnSelect={false}
                />
                {selectedSuppliers.length > 0 && (
                    <Table
                        columns={tableColumns}
                        data={selectedSuppliers}
                        testid="selected-suppliers-table"
                        noDataDisplay=""
                        className={classes.removeTableHeadingBorder}
                    />
                )}
                <Button
                    testid="add-suppliers-button"
                    label={t("companySuppliers.addSuppliers.addToBuyersList")}
                    onClick={() => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: `Company Suppliers - Add To Buyer's List`,
                        });
                        addSuppliers();
                    }}
                    disabled={selectedSuppliers.length === 0}
                />
            </Drawer>
            <Popup
                isOpen={showUnsavedChangesPopup}
                onClose={() => setShowUnsavedChangesPopup(false)}
                testid="unsaved-changes-warning-popup"
                title={t("popups.unsavedChanges.title")}
                message={t("popups.unsavedChanges.message")}
                primaryActionText={t("popups.unsavedChanges.altCta.primary")}
                secondaryActionText={t("popups.unsavedChanges.altCta.secondary")}
                onPrimaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: `Company Suppliers - Add Supplier Cancel - Submit`,
                    });
                    setShowUnsavedChangesPopup(false);
                    onClose();
                    setSelectedSuppliers([]);
                }}
                onSecondaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: `Company Suppliers - Add Supplier Cancel - Cancel`,
                    });
                    setShowUnsavedChangesPopup(false);
                }}
            />
        </>
    );
};

export default AddSuppliersDrawer;
