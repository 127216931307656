import { getProjectPublicData } from "api/projectApi";
import { useCallback } from "react";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const useProjectPublicData = () => {
    const fetchProjectPublicData = useCallback(async (projectUuid?: string) => {
        if (projectUuid) {
            const publicProjectDataResponse = await getProjectPublicData(projectUuid);
            if (
                publicProjectDataResponse.status === 200 &&
                !(publicProjectDataResponse.data instanceof TransactionErrorDto)
            ) {
                return publicProjectDataResponse.data;
            }
        }
        return null;
    }, []);

    return {
        fetchProjectPublicData,
    };
};

export default useProjectPublicData;
