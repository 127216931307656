import { useToaster } from "@maistro/components";
import { Form, Formik, FormikHelpers, useFormikContext } from "formik";
import { TFunction } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { Loader } from "components";
import Prompt from "components/Prompt/Prompt";
import { IOptionProps } from "components/shared";
import CompanyNotFound from "features/company/CompanyNotFound";
import CompanyProfileActionPopup from "features/company/profile/CompanyProfileActionPopup";
import AdditionalInformationForm, {
    additionalInformationSchema,
} from "features/company/profile/edit/AdditionalInformationForm";
import CompanyInformationForm from "features/company/profile/edit/CompanyInformationForm";
import useCompanyProfileForm, {
    useCompanyProfileFormStyles,
} from "features/company/profile/edit/hooks/useCompanyProfileForm";
import ICompanyProfileForm from "features/company/profile/edit/types/ICompanyProfileForm";
import useCompanyStepperNavigation from "features/company/profile/hooks/useCompanyStepperNavigation";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import { useGetCompanyDetailsQuery, useUpdateCompanyDetailsMutation } from "store/api/companyApi";
import { UpdateCompanyProfileRequestDto } from "types/dtos/company/profile/UpdateCompanyProfileRequestDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

export const companyInformationSchema = (t: TFunction<"translation", undefined>) =>
    Yup.object({
        registeredName: Yup.string().required(t("companyProfile.companyInformation.registeredName.required")),
        sizeTypeId: Yup.string()
            .not(["0"], t("companyProfile.companyInformation.sizeType.required"))
            .required(t("companyProfile.companyInformation.sizeType.required")),
        companyType: Yup.string()
            .oneOf(["buyer", "supplier"], t("companyProfile.companyInformation.companyType.required"))
            .required(t("companyProfile.companyInformation.companyType.required")),
        companiesHouseNumber: Yup.string().optional(),
        registrationNumber: Yup.string().optional(),
        description: Yup.string().optional(),
    });

interface IEditFormProps {
    userIsMaistro: boolean;
    countryOptions: IOptionProps[];
    sizeOptions: IOptionProps[];
    setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
    industryOptions: IOptionProps[];
    setSubmissionDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    isValid: boolean;
    isSaving: boolean;
}

const EditForm: React.FC<IEditFormProps> = ({
    userIsMaistro,
    countryOptions,
    sizeOptions,
    setIsValid,
    industryOptions,
    setSubmissionDisabled,
    isValid,
    isSaving,
}) => {
    const classes = useCompanyProfileFormStyles();

    const { dirty } = useFormikContext<ICompanyProfileForm>();

    useEffect(() => {
        setSubmissionDisabled(isSaving || !isValid || !dirty);
    }, [dirty, isSaving, isValid, setSubmissionDisabled]);

    return (
        <Form className={classes.form} id="company-profile-form">
            <CompanyInformationForm
                userIsMaistro={userIsMaistro}
                countries={countryOptions}
                sizes={sizeOptions}
                setIsValid={setIsValid}
            />
            <hr />
            <AdditionalInformationForm industries={industryOptions} />
        </Form>
    );
};

const EditCompanyProfileContainer: React.FC = () => {
    const { companyUuid } = useParams();
    const { data: companyDetails, isLoading: isCompanyDetailLoading } = useGetCompanyDetailsQuery(companyUuid ?? "");

    const [isValid, setIsValid] = useState(false);
    const [updateCompanyProfile, { isLoading: isSaving }] = useUpdateCompanyDetailsMutation();

    const { t } = useTranslation();
    const toast = useToaster();

    const { userIsMaistro } = useCurrentUser();

    const { isCompanyProfileActionPopupOpen, setIsCompanyProfileActionPopupOpen } = useCompanyStepperNavigation({
        activeStep: "companyProfile",
        title: companyDetails?.registeredName,
        subTitle: companyDetails?.netPromoterScore
            ? t("companyProfile.stepperNavigation.netPromoterScore", {
                  netPromoterScore: companyDetails?.netPromoterScore,
              })
            : "",
        companyUuid: companyUuid ?? "",
        isActive: companyDetails?.isActive,
    });

    const { isReferenceDataLoading, countryOptions, industryOptions, sizeOptions, setSubmissionDisabled } =
        useCompanyProfileForm(false);

    const isLoading = useMemo(
        () => isReferenceDataLoading || isCompanyDetailLoading,
        [isCompanyDetailLoading, isReferenceDataLoading],
    );

    if (isLoading) {
        return <Loader />;
    }
    if (!companyDetails) {
        return <CompanyNotFound />;
    }

    const onSubmit = async (values: ICompanyProfileForm, formActions: FormikHelpers<ICompanyProfileForm>) => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: "Company - Update Profile - ".concat(companyDetails.registeredName),
        });
        const request: UpdateCompanyProfileRequestDto = {
            uuid: companyDetails.uuid,
            registeredName: values.registeredName,
            tradingName: values.tradingName,
            description: values.description,
            industryTypeId: values.industryTypeId,
            sizeTypeId: values.sizeTypeId,
            contactEmail: values.contactEmail,
            companiesHouseNumber: values.companiesHouseNumber,
            registrationNumber: values.registrationNumber,
            vatRegistrationNumber: values.vatRegistrationNumber,
            websiteUrl: values.websiteUrl,
            linkedInUrl: values.linkedInUrl,
            allowBuyerUsers: values.companyType === "buyer",
            allowSupplierUsers: values.companyType === "supplier",
            isMaistroSupplier: values.companyType === "supplier" ? values.isMaistroSupplier : false,
        };
        updateCompanyProfile(request)
            .unwrap()
            .then(() => {
                toast.success(t("companyProfile.api.updateCompanySuccess"));
                formActions.resetForm({ values });
            })
            .catch(() => toast.error(t("companyProfile.api.updateCompanyError")));
    };

    return (
        <>
            <Formik
                initialValues={{
                    registeredName: companyDetails.registeredName,
                    sizeTypeId: companyDetails.sizeTypeId.toString(),
                    companyType: companyDetails.allowBuyerUsers ? "buyer" : "supplier",
                    isMaistroSupplier: companyDetails.isMaistroSupplier,
                    companiesHouseNumber: companyDetails.companiesHouseNumber,
                    registrationNumber: companyDetails.registrationNumber,
                    description: companyDetails.description,
                    tradingName: companyDetails.tradingName,
                    industryTypeId: companyDetails.industryTypeId.toString(),
                    vatRegistrationNumber: companyDetails.vatRegistrationNumber,
                    contactEmail: companyDetails.contactEmail,
                    websiteUrl: companyDetails.websiteUrl,
                    linkedInUrl: companyDetails.linkedInUrl,
                }}
                onSubmit={(values, actions) => onSubmit(values, actions)}
                validationSchema={companyInformationSchema(t).concat(additionalInformationSchema(t))}
                validateOnMount
            >
                {({ dirty }) => {
                    return (
                        <>
                            <EditForm
                                countryOptions={countryOptions}
                                industryOptions={industryOptions}
                                isSaving={isSaving}
                                isValid={isValid}
                                setIsValid={setIsValid}
                                setSubmissionDisabled={setSubmissionDisabled}
                                sizeOptions={sizeOptions}
                                userIsMaistro={userIsMaistro}
                            />
                            <Prompt when={dirty && !isSaving} />
                        </>
                    );
                }}
            </Formik>
            <CompanyProfileActionPopup
                isCompanyProfileActionPopupOpen={isCompanyProfileActionPopupOpen}
                setIsCompanyProfileActionPopupOpen={setIsCompanyProfileActionPopupOpen}
                isActive={companyDetails?.isActive}
                isBuyer={companyDetails?.allowBuyerUsers}
                companyUuid={companyDetails?.uuid}
                registeredName={companyDetails?.registeredName}
            />
        </>
    );
};

export default EditCompanyProfileContainer;
