import { Copy, useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate, useParams } from "react-router-dom";

import { createExpressionOfInterest } from "api/expressionOfInterestApi";
import { listCompanySizeTypes, listIndustryTypes } from "api/company/referenceDataApi";
import { Loading } from "components";
import { IOptionProps } from "components/shared";
import ExpressionOfInterestDisplay from "features/project/expressionOfInterest/ExpressionOfInterestDisplay";
import { IExpressionOfInterestData } from "features/project/expressionOfInterest/types";
import useProjectPublicData from "features/project/hooks/useProjectPublicData";
import useAppDispatch from "hooks/useAppDispatch";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { setPageTitle } from "store/layoutSlice";
import { ITheme } from "styles/themes/types";
import { ProjectPublicDataDto } from "types/dtos/projects/ProjectPublicDataDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { CompanySizeV2, getCompanySizeRange } from "types/enums/companies/CompanySizeV2";

const useStyles = createUseStyles((theme: ITheme) => ({
    copy: {
        marginBottom: theme.spacing.xLarge,
        maxWidth: "100%",
    },
}));

const ExpressionOfInterestContainer: React.FC = () => {
    const classes = useStyles();

    const [industryOptions, setIndustryOptions] = useState<Array<IOptionProps>>([]);
    const [companySizeOptions, setCompanySizeOptions] = useState<Array<IOptionProps>>([]);
    const [projectData, setProjectData] = useState<ProjectPublicDataDto | null>(null);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false); // possibly redundant, if only used to control EOI formik submission
    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const toast = useToaster();
    const { projectUuid } = useParams();

    const { fetchProjectPublicData } = useProjectPublicData();

    useEffect(() => {
        dispatch(setPageTitle(t("expressionOfInterest.pageTitle")));
    }, [dispatch, t]);

    const fetchData = useCallback(async () => {
        const industryTypeResponse = await listIndustryTypes();
        if (industryTypeResponse.status === 200 && !(industryTypeResponse.data instanceof TransactionErrorDto)) {
            setIndustryOptions(
                industryTypeResponse.data.data.map((industryType) => ({
                    value: industryType.id.toString(),
                    label: industryType.name,
                })),
            );
        }
        const companySizeResponse = await listCompanySizeTypes();
        if (companySizeResponse.status === 200 && !(companySizeResponse.data instanceof TransactionErrorDto)) {
            setCompanySizeOptions(
                companySizeResponse.data.data.map((companySize) => ({
                    value: companySize.id.toString(),
                    label: getCompanySizeRange(companySize.name as CompanySizeV2),
                })),
            );
        }

        const projectDataResponse = await fetchProjectPublicData(projectUuid);
        if (!projectDataResponse) {
            navigate(`/expression-of-interest/${projectUuid}/not-found`);
        } else {
            setProjectData(projectDataResponse);
        }
        setIsLoading(false);
    }, [fetchProjectPublicData, projectUuid, navigate]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onSubmit = useCallback(
        async (values: IExpressionOfInterestData) => {
            setIsFormSubmitting(true);

            const request = {
                ...values,
                projectUuid: projectUuid || "",
                industryTypeId: parseInt(values.industryTypeId, 10),
                companySizeId: parseInt(values.companySizeId, 10),
            };

            const response = await createExpressionOfInterest(request);
            if (response.status !== 200) {
                toast.error(t("expressionOfInterest.errorSubmit"));
                setIsFormSubmitting(false);
                return;
            }

            setIsFormSubmitting(false);
            navigate(buildPath(routes.public.expressionOfInterestSuccess, { projectUuid }));
        },
        [projectUuid, navigate, t, toast],
    );

    if (isLoading) {
        return <Loading />;
    }

    if (projectData && new Date(projectData.expressionOfInterestDeadline) > new Date()) {
        return (
            <ExpressionOfInterestDisplay
                projectName={projectData.projectName}
                projectFriendlyId={projectData.friendlyId}
                submit={onSubmit}
                isFormSubmitting={isFormSubmitting}
                industryOptions={industryOptions}
                companySizeOptions={companySizeOptions}
            />
        );
    }

    return <Copy className={classes.copy}>{t("expressionOfInterest.deadlinePassed")}</Copy>;
};

export default ExpressionOfInterestContainer;
