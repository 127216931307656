import { Alert, Copy, Heading, Popup } from "@maistro/components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useProject from "features/project/hooks/useProject";
import ScoreTenderBySupplierSectionRow from "features/project/tenderCriteria/components/scoring/ScoreTenderBySupplierSectionRow";
import ScoringDisplayHeader from "features/project/tenderCriteria/components/scoring/ScoringDisplayHeader";
import useTenderStyles from "features/project/tenderCriteria/hooks/useTenderStyles";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import { AnswerDto } from "types/dtos/questions/AnswerResponseDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { ScoreResponseDto } from "types/dtos/questions/ScoreResponseDto";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";
import SupplierSubmissionStatus from "types/enums/supplierSubmissions/SupplierSubmissionStatus";

interface IScoringTenderBySupplierDisplayProps {
    sections: SectionDto[];
    questions: QuestionResponseDto[];
    completedContributorScorers: number;
    totalContributorScorers: number;
    projectUuid: string;
    completeScoring: () => Promise<void>;
    isProjectManager: boolean;
    isScoringComplete: boolean;
    displayScoringClosedMessage: boolean;
    projectHasFiles: boolean;
    setScore: (score: ScoreResponseDto, questionUuid: string) => void;
    canScoreAnswers?: boolean;
    canModerateScores?: boolean;
    supplierUuid?: string;
    questionUuid?: string;
    isSubmittingScores?: boolean;
    allAnswersScored?: boolean;
    haveScores: (answer: AnswerDto) => boolean;
}

const ScoringTenderBySupplierDisplay: React.FC<IScoringTenderBySupplierDisplayProps> = ({
    sections,
    questions,
    completedContributorScorers,
    totalContributorScorers,
    projectUuid,
    completeScoring,
    isProjectManager,
    isScoringComplete,
    displayScoringClosedMessage,
    projectHasFiles,
    setScore,
    supplierUuid,
    questionUuid,
    canScoreAnswers = false,
    canModerateScores = false,
    isSubmittingScores,
    allAnswersScored,
    haveScores,
}) => {
    const classes = useTenderStyles();
    const { fetchProjectSuppliers } = useProject();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [scoringCompletePopupVisible, setScoringCompletePopupVisible] = useState(false);
    const [popupMessage, setPopupMessage] = useState("popups.tenderCriteria.scoringComplete.message");
    const [popupTitleMessage, setPopupTitleMessage] = useState("popups.tenderCriteria.scoringComplete.title");
    const [projectSuppliers, setProjectSuppliers] = useState<Array<SupplierSubmissionResponseDto>>([]);
    const [selectSupplier, setSelectSupplier] = useState("");

    const updateSupplier = useCallback(() => {
        const supplierCompanyName = projectSuppliers.find((s) => s.supplierCompanyUuid === supplierUuid)?._embedded
            .supplierCompanyName;
        if (supplierCompanyName) {
            setSelectSupplier(supplierCompanyName);
        }
    }, [projectSuppliers, supplierUuid]);

    useEffect(() => {
        updateSupplier();
    }, [updateSupplier]);

    const updatePopupMessages = useCallback(() => {
        if (isProjectManager) {
            if (completedContributorScorers < totalContributorScorers) {
                setPopupTitleMessage("popups.tenderCriteria.scoringComplete.pmTitleWarning");
                setPopupMessage("popups.tenderCriteria.scoringComplete.pmWarningContributorsCompletion");
            } else {
                setPopupMessage("popups.tenderCriteria.scoringComplete.pmMessage");
            }
        }
    }, [completedContributorScorers, totalContributorScorers, isProjectManager]);

    useEffect(() => {
        updatePopupMessages();
    }, [updatePopupMessages]);

    const fetchSuppliers = useCallback(async () => {
        const suppliersInformation = await fetchProjectSuppliers();
        const submittedSuppliers = suppliersInformation
            .filter((supplier) => supplier.status === SupplierSubmissionStatus.Submitted)
            .sort((a, b) => a._embedded.supplierCompanyName.localeCompare(b._embedded.supplierCompanyName));
        setProjectSuppliers(submittedSuppliers);
    }, [fetchProjectSuppliers]);

    useEffect(() => {
        fetchSuppliers();
    }, [fetchSuppliers]);

    const questionInSection = (section: SectionDto, question: QuestionResponseDto) =>
        question.entityUuid.toLowerCase() === section.id.toLowerCase();

    const canCompleteScoring = !isScoringComplete && allAnswersScored;

    const onViewResults = useCallback(() => {
        const path = isProjectManager && isScoringComplete ? routes.projects.awardSupplier : routes.projects.monitor;
        navigate(buildPath(path, { projectUuid }));
    }, [isProjectManager, isScoringComplete, navigate, projectUuid]);

    const supplierIndex = supplierUuid
        ? projectSuppliers.findIndex((s) => s.supplierCompanyUuid.toLowerCase() === supplierUuid.toLowerCase())
        : 0;

    const supplierNavigate = useCallback(
        (direction: "next" | "previous") => {
            const indexChange = direction === "next" ? 1 : -1;
            const destinationSupplierUuid = projectSuppliers[supplierIndex + indexChange].supplierCompanyUuid;
            navigate(
                buildPath(routes.projects.scoreTenderQuestionsBySupplier, {
                    projectUuid,
                    questionUuid: questions[0].questionUuid,
                    supplierUuid: destinationSupplierUuid,
                }),
            );
        },
        [navigate, projectSuppliers, projectUuid, questions, supplierIndex],
    );

    const selectedQuestion = questions.find((q) => q.questionUuid === questionUuid);
    const selectedSectionIndex = sections.findIndex(
        (s) => s.id.toLowerCase() === selectedQuestion?.entityUuid.toLowerCase(),
    );

    return (
        <>
            {displayScoringClosedMessage ? (
                <Alert type="error" label={t("tenderSummaryScreen.scoringClosedMessage")} testid="scoring-closed" />
            ) : (
                <Copy className={classes.copy}>{t("tenderSummaryScreen.subtitle")}</Copy>
            )}
            <div className={classes.subBanner}>
                <ScoringDisplayHeader
                    projectUuid={projectUuid}
                    questions={questions}
                    sections={sections}
                    completedContributorScorers={completedContributorScorers}
                    totalContributorScorers={totalContributorScorers}
                    isProjectManager={isProjectManager}
                    projectHasFiles={projectHasFiles}
                    isScoringComplete={isScoringComplete}
                    canCompleteScoring={canCompleteScoring}
                    setScoringCompletePopupVisible={setScoringCompletePopupVisible}
                    supplierIndex={supplierIndex}
                    projectSuppliers={projectSuppliers}
                    supplierNavigate={supplierNavigate}
                    onViewResults={onViewResults}
                    hasNavigarion
                />
            </div>
            {selectSupplier && (
                <div className={classes.supplierDropdown}>
                    <Heading className={classes.supplierName} variant="h4">
                        {selectSupplier}
                    </Heading>
                </div>
            )}
            {sections.map((section, index) => (
                <ScoreTenderBySupplierSectionRow
                    key={section.id}
                    projectUuid={projectUuid}
                    section={section}
                    questions={questions.filter((question) => questionInSection(section, question))}
                    haveScores={haveScores}
                    canScoreAnswers={canScoreAnswers}
                    canModerateScores={canModerateScores}
                    expandedByDefault={index === selectedSectionIndex}
                    testid={`section-${section.id}`}
                    setScore={setScore}
                    questionUuid={questionUuid}
                />
            ))}
            <Popup
                title={t(popupTitleMessage)}
                message={t(popupMessage)}
                isOpen={scoringCompletePopupVisible}
                primaryActionText={t("popups.tenderCriteria.scoringComplete.cta.primary")}
                onPrimaryAction={completeScoring}
                secondaryActionText={t("popups.tenderCriteria.scoringComplete.cta.secondary")}
                onSecondaryAction={() => setScoringCompletePopupVisible(false)}
                onClose={() => setScoringCompletePopupVisible(false)}
                testid="scoring-complete-popup"
                disabled={isSubmittingScores}
            />
        </>
    );
};

export default ScoringTenderBySupplierDisplay;
