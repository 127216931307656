import { Button, TextField } from "@maistro/components";
import { Form, Formik } from "formik";
import { TFunction } from "i18next";
import { isEmpty } from "lodash";
import React, { Dispatch, ReactElement, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import { ITheme } from "styles/themes/types";
import { GuidedQuestionResponseDto } from "types/dtos/questions/GuidedQuestionResponseDto";
import { GuidedQuestionAnswersDto } from "types/dtos/projects/aiAssist/GuidedQuestionAnswersDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.xSmall,
    },
    buttonControl: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.large,
    },
}));

interface IGenerateProjectDescriptionFormProps {
    setIsDisclaimerPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
    guidedQuestions: GuidedQuestionResponseDto[];
    setAnswers: Dispatch<SetStateAction<GuidedQuestionAnswersDto[]>>;
}

const minCharacterLimit = 3;
const maxCharacterLimit = 300;

const validationSchema = (t: TFunction<"translation", undefined>, questions: GuidedQuestionResponseDto[] | null) => {
    const validationObject: { [key: string]: Yup.StringSchema } = {};

    if (questions) {
        questions.forEach((q) => {
            validationObject[q.id] = Yup.string()
                .required(t("generateProjectDescription.required"))
                .trim()
                .min(minCharacterLimit, t("generateProjectDescription.minError", { minCharacterLimit }))
                .max(maxCharacterLimit, t("generateProjectDescription.maxError", { maxCharacterLimit }));
        });
    }

    return Yup.object(validationObject);
};

const initialValues = (questions: GuidedQuestionResponseDto[] | null) => {
    const initialValuesObject: { [key: string]: string } = {};

    if (questions) {
        questions.forEach((q) => {
            initialValuesObject[q.id] = "";
        });
    }

    return initialValuesObject;
};

const GenerateProjectDescriptionForm: React.FC<IGenerateProjectDescriptionFormProps> = (props) => {
    const classes = useStyles();
    const { setIsDisclaimerPopupOpen, guidedQuestions, setAnswers } = props;

    const { t } = useTranslation();

    const setValidationSchema = () => {
        return validationSchema(t, guidedQuestions);
    };

    const setInitialValues = () => {
        return initialValues(guidedQuestions);
    };

    const GuidedQuestionInputs = (): ReactElement[] | null => {
        if (guidedQuestions) {
            return guidedQuestions.map((gq, i) => (
                <TextField
                    key={gq.id}
                    name={gq.id}
                    testid={`question-${i}-input`}
                    label={`${i + 1}. ${gq.questionText}`}
                    labelSize="large"
                    required
                    withCharacterCounter
                    maxCharacterLimit={maxCharacterLimit}
                />
            ));
        }
        return null;
    };

    return (
        <Formik
            enableReinitialize
            initialValues={setInitialValues()}
            validationSchema={setValidationSchema()}
            onSubmit={(values) => {
                setIsDisclaimerPopupOpen(true);

                const answerObject: GuidedQuestionAnswersDto[] = [];
                guidedQuestions.forEach((x) =>
                    answerObject.push({
                        questionUuid: x.id,
                        answerText: values[x.id],
                    }),
                );

                setAnswers(answerObject);
            }}
        >
            {({ touched, errors }) => (
                <Form className={classes.form}>
                    <GuidedQuestionInputs />

                    <div className={classes.buttonControl}>
                        <Button
                            label={t("generateProjectDescription.submit")}
                            size="large"
                            type="submit"
                            chevron
                            disabled={isEmpty(touched) || !isEmpty(errors)}
                            testid="generate-project-description-submit-button"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default GenerateProjectDescriptionForm;
