import GuardedRoute from "features/auth/GuardedRoute";
import Logout from "features/auth/Logout";
import CompanyListContainer from "features/company/list/CompanyListContainer";
import CreateCompanyProfileContainer from "features/company/profile/edit/CreateCompanyProfileContainer";
import Dashboard from "features/dashboard/Dashboard";
import ProjectNotFound from "features/project/ProjectNotFound";
import GenerateProjectDescriptionContainer from "features/project/generateProjectDescription/GenerateProjectDescriptionContainer";
import ProjectBriefContainer from "features/project/projectBrief/ProjectBriefContainer";
import Reports from "features/reports/Reports";
import VettingReport from "features/reports/VettingReport";
import Settings from "features/settings/Settings";
import AdminUsersContainer from "features/settings/admin-users/AdminUsersContainer";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import CompaniesRouter from "routes/CompaniesRouter";
import ProjectsResponseRouter from "routes/ProjectsResponseRouter";
import ProjectsRouter from "routes/ProjectsRouter";
import PublicRouter from "routes/PublicRouter";
import BetaPaths from "routes/routePaths/BetaPaths";
import { companiesRoute } from "routes/routePaths/CompanyRoutePaths";
import { projectsResponseRoute } from "routes/routePaths/ProjectResponseRoutePaths";
import { projectsRoute } from "routes/routePaths/ProjectRoutePaths";
import { publicRoute } from "routes/routePaths/PublicRoutePaths";
import routes from "routes/routePaths/RoutePaths";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

const MainRouter: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<GuardedRoute />}>
                <Route path="/b" element={<Navigate to="/" />} />
                <Route path="/b/*" element={<BetaPaths />} />
                <Route path="" element={<Navigate to={routes.common.dashboard.path} />} />
                <Route path={routes.common.dashboard.path} element={<Dashboard />} />
                <Route path={routes.projects.projectBrief.path} element={<ProjectBriefContainer />} />
                <Route
                    path={routes.projects.generateProjectDescription.path}
                    element={<GenerateProjectDescriptionContainer />}
                />
                <Route path={routes.common.reports.path} element={<Reports />} />
                <Route path={routes.common.vettingReport.path} element={<VettingReport />} />
                <Route path={routes.projects.projectNotFound.path} element={<ProjectNotFound />} />
                <Route path={routes.users.logout.path} element={<Logout />} />
            </Route>
            <Route
                path={routes.company.createCompany.path}
                element={<GuardedRoute permission={PermissionsEnum.ManageSupport} />}
            >
                <Route path="" element={<CreateCompanyProfileContainer />} />
            </Route>
            <Route
                path={routes.common.companies.path}
                element={<GuardedRoute permission={PermissionsEnum.ManageSupport} />}
            >
                <Route path="" element={<CompanyListContainer />} />
            </Route>
            <Route
                path={routes.common.adminUsers.path}
                element={<GuardedRoute permission={PermissionsEnum.ManageSupport} />}
            >
                <Route path="" element={<AdminUsersContainer />} />
            </Route>
            <Route path={routes.common.settings.path} element={<Settings />} />

            {/* Routers */}
            <Route path={projectsResponseRoute.fullPath} element={<ProjectsResponseRouter />} />
            <Route path={projectsRoute.fullPath} element={<ProjectsRouter />} />
            <Route path={companiesRoute.fullPath} element={<CompaniesRouter />} />
            <Route path={publicRoute.fullPath} element={<PublicRouter />} />
        </Routes>
    );
};

export default MainRouter;
