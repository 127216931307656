import { AxiosResponse } from "axios";

import { createAxiosInstance } from "api/commonApi";
import env from "config/EnvironmentVariables";
import { ActionPolicyApprovalRequestDto } from "types/dtos/company/approvals/ActionPolicyApprovalRequestDto";
import { ApproverDto } from "types/dtos/company/approvals/ApproverDto";
import { ListApprovalsForProjectRequestDto } from "types/dtos/company/approvals/ListApprovalsForProjectRequestDto";
import { ListApprovalsForProjectResponseDto } from "types/dtos/company/approvals/ListApprovalsForProjectResponseDto";
import { ListApprovalsForUserRequestDto } from "types/dtos/company/approvals/ListApprovalsForUserRequestDto";
import { ListApprovalsForUserResponseDto } from "types/dtos/company/approvals/ListApprovalsForUserResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/companies";

export const listApprovalsForUser = (
    request: ListApprovalsForUserRequestDto,
): Promise<AxiosResponse<ListApprovalsForUserResponseDto | TransactionErrorDto>> => {
    return api.get(`${baseApiUrl}/approvals`, {
        params: {
            skip: request.skip,
            take: request.take,
        },
    });
};

export const listApprovalsForProject = (
    request: ListApprovalsForProjectRequestDto,
): Promise<AxiosResponse<ListApprovalsForProjectResponseDto | TransactionErrorDto>> => {
    return api.get(`${baseApiUrl}/${request.companyUuid}/approvals/${request.projectUuid}`);
};

export const actionPolicyApproval = (
    request: ActionPolicyApprovalRequestDto,
): Promise<AxiosResponse<ApproverDto | TransactionErrorDto>> => {
    return api.put(`${baseApiUrl}/${request.companyUuid}/approvals/action`, request);
};
