import { createUseStyles } from "react-jss";

import colorService from "services/colorService";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    content: {
        display: "flex",
        flexDirection: "column",
    },

    inputContainer: {
        display: "flex",
        alignItems: "center",
        width: "100%",
    },

    field: {
        borderStyle: "solid",
        borderWidth: theme.spacing.unit / 6,
        borderColor: theme.colors.primary,
        borderRadius: theme.spacing.xSmall,
        padding: theme.spacing.xSmall,
        width: "100%",
        backgroundColor: theme.colors.white,
    },

    valid: {
        borderColor: theme.colors.success,
        borderWidth: theme.spacing.unit / 3,
    },

    invalid: {
        borderColor: theme.colors.error,
        borderWidth: theme.spacing.unit / 3,
    },

    disabled: {
        // Match chromium browser styles
        borderColor: colorService.toHexWithOpacity("#767676", 0.3),
        backgroundColor: colorService.toHexWithOpacity("#efefef", 0.3),

        // Required for iOS
        opacity: 1,
        WebkitTextFillColor: colorService.toHexWithOpacity(theme.typography.base.color, 0.8),
    },
}));

const useFormikFieldStyles = () => useStyles();

export default useFormikFieldStyles;
