import { ICommonProps, SelectField } from "@maistro/components";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import stringService from "services/stringService";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";

interface ISectionDropdownProps extends ICommonProps {
    setSelectedSection: Dispatch<SetStateAction<string>>;
    sections: Array<SectionDto>;
}

const SectionDropdown: React.FC<ISectionDropdownProps> = ({ setSelectedSection, sections }) => {
    const { t } = useTranslation();

    return (
        <SelectField
            testid="clarification-question-section"
            name="clarificationQuestionSection"
            options={sections.map((section) => ({
                value: section.id,
                label: section.description
                    ? stringService.insertSpacesInTitleCase(section.description)
                    : stringService.insertSpacesInTitleCase(section.sectionType),
            }))}
            withoutValidation
            label={t("clarificationQuestions.section.label")}
            tooltip={t("clarificationQuestions.tooltips.section")}
            placeholder={t("clarificationQuestions.section.placeHolder")}
            onChange={(section) => setSelectedSection(section)}
            onInputChange={(section) => setSelectedSection(section)}
            clearable
        />
    );
};

export default SectionDropdown;
