import classNames from "classnames";
import React from "react";
import { createUseStyles, useTheme } from "react-jss";

import ProfileBar from "components/Layout/ProfileBar";
import SecondaryOptions from "components/Layout/SecondaryOptions";
import { ICommonProps } from "components/shared";
import ReactGA from "react-ga4";
import { ITheme } from "styles/themes/types";
import { ZINDEX_HEADER_BAR } from "types/consts/zIndexConstants";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    headerBar: () => ({
        display: "flex",
        justifyContent: "flex-end",
        borderTopWidth: theme.layout.accentBarWidth,
        borderTopColor: theme.colors.accentBar,
        borderTopStyle: "solid",
        zIndex: ZINDEX_HEADER_BAR,
        alignItems: "center",
        boxSizing: "content-box",
    }),

    secondaryOptions: {
        flexGrow: "1",
        justifyContent: "flex-end",
    },

    profile: {
        width: "100%",
    },

    poweredBy: {
        paddingTop: "5px",
        maxHeight: "30px",
    },

    [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        headerBar: (props: IHeaderProps) => ({
            position: "fixed",
            height: theme.layout.navBarHeight,
            backgroundColor: props.user ? theme.colors.white : theme.colors.lightGray,
            paddingLeft: theme.layout.navBarWidth + theme.spacing.small,
            top: 0,
            right: 0,
            left: 0,
        }),
        profile: {
            width: "auto",
        },
    },
}));

export interface IHeaderProps extends ICommonProps {
    setMenuIsOpen: (value: React.SetStateAction<boolean>) => void;
    user?: {
        name: string;
    };
}

const Header: React.FC<IHeaderProps> = (props) => {
    const classes = useStyles(props);
    const theme = useTheme<ITheme>();

    const { user, setMenuIsOpen, testid } = props;

    return (
        <header className={classes.headerBar} data-component="Header" data-testid={testid}>
            {theme.isWhiteLabelled && (
                <a
                    href="https://maistro.com/"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() =>
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: "Header Maistro banner",
                        })
                    }
                >
                    <img
                        className={classNames(classes.poweredBy, "hidden-mobile")}
                        alt="Logos"
                        src={theme.logos.poweredByMaistroLogo.light}
                    />
                </a>
            )}
            {user && <SecondaryOptions className={classNames(classes.secondaryOptions, "hidden-mobile")} />}
            <ProfileBar
                className={classes.profile}
                onOpenMenu={() => setMenuIsOpen(true)}
                user={user}
                testid="profile-bar"
            />
        </header>
    );
};

export default Header;
