import { AxiosRequestConfig, AxiosResponse } from "axios";

import env from "config/EnvironmentVariables";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { createAxiosInstance } from "api/commonApi";
import { ReportListResponseDto } from "types/dtos/reports/ReportListResponseDto";
import EmbeddedReportResponseDto from "types/dtos/reports/EmbeddedReportResponseDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/reports";
const baseRequestConfig: AxiosRequestConfig = { timeout: 90000 };

export const getReportList = (): Promise<AxiosResponse<ReportListResponseDto>> => {
    const url = `${baseApiUrl}`;
    return api.get(url);
};

export const getEmbeddedReport = (reportCode: string): Promise<AxiosResponse<EmbeddedReportResponseDto>> => {
    const url = `${baseApiUrl}/${reportCode}`;
    return api.get(url, baseRequestConfig);
};

export const getEmbeddedProjectReport = (
    projectUuid: string,
    reportCode: string,
): Promise<AxiosResponse<EmbeddedReportResponseDto>> => {
    const url = `/projects/${projectUuid}/reports/${reportCode}`;
    return api.get(url, baseRequestConfig);
};

export const getClarificationQuestionsReport = (
    projectUuid: string,
): Promise<AxiosResponse<Blob> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/projects/${projectUuid}/clarifications`;
    return api.get(url, { ...baseRequestConfig, responseType: "blob" });
};

export const getProjectSummaryReport = (
    projectUuid: string,
): Promise<AxiosResponse<Blob> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/projects/${projectUuid}/summary`;
    return api.get(url, { ...baseRequestConfig, responseType: "blob" });
};

export const getSupplierVettingReport = (
    supplierCompanyUuid: string,
    buyerCompanyUuid?: string,
): Promise<AxiosResponse<Blob> | AxiosResponse<TransactionErrorDto>> => {
    let url = `${baseApiUrl}/company/${supplierCompanyUuid}/vetting`;
    if (buyerCompanyUuid) {
        url += `?whitelabelCompanyUuid=${buyerCompanyUuid}`;
    }

    return api.get(url, { ...baseRequestConfig, responseType: "blob" });
};

export const getSupplierVettingEmbeddedReport = (
    supplierCompanyUuid: string,
): Promise<AxiosResponse<EmbeddedReportResponseDto>> => {
    const url = `${baseApiUrl}/company/${supplierCompanyUuid}/embedded-vetting`;
    return api.get(url, baseRequestConfig);
};

export const getStatementOfWorkReport = (
    projectUuid: string,
): Promise<AxiosResponse<Blob> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/projects/${projectUuid}/statement-of-work`;
    return api.get(url, { ...baseRequestConfig, responseType: "blob" });
};

export const getProjectShortlistingReport = (
    projectUuid: string,
): Promise<AxiosResponse<Blob> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/projects/${projectUuid}/shortlisting`;
    return api.get(url, { ...baseRequestConfig, responseType: "blob" });
};

export const getTenderResponsesReport = (
    projectUuid: string,
): Promise<AxiosResponse<Blob> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/projects/${projectUuid}/tender-questions`;
    return api.get(url, { ...baseRequestConfig, responseType: "blob" });
};
