import baseApi from "store/api/baseApi";
import { VettingInformationDto } from "types/dtos/company/vettingInformation/VettingInformationDto";

const companyAdditionalInformationApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getVettingInformation: build.query<VettingInformationDto, string>({
            query: (companyUuid) => ({
                url: `/companies/${companyUuid}/additional-information`,
                method: "GET",
            }),
        }),

        updateVettingInformation: build.mutation<VettingInformationDto, VettingInformationDto>({
            query: (dto) => ({
                url: `/companies/${dto.companyUuid}/additional-information`,
                method: "PATCH",
                data: dto,
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useGetVettingInformationQuery, useUpdateVettingInformationMutation } = companyAdditionalInformationApi;

export default companyAdditionalInformationApi;
