import { Button } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import { ICommonProps } from "components/shared";
import ProjectInformationDisplay from "features/project/clarificationQuestions/ProjectInformationDisplay";
import ReadOnlyAnswerDisplay from "features/project/clarificationQuestions/ReadOnlyAnswerDisplay";
import ReadOnlyQuestionDisplay from "features/project/clarificationQuestions/ReadOnlyQuestionDisplay";
import BuyerAnswerQuestionForm from "features/project/clarificationQuestions/answerQuestion/BuyerAnswerQuestionForm";
import { IProjectInformation } from "features/project/types";
import useCurrentUser from "hooks/useCurrentUser";
import useReduxSelector from "hooks/useReduxSelector";
import ReactGA from "react-ga4";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

const useStyles = createUseStyles((theme: ITheme) => ({
    grid: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.large,
    },
    centered: {
        margin: "auto",
    },
}));

interface IBuyerAnswerQuestionDisplayProps extends ICommonProps {
    project: IProjectInformation;
    question?: QuestionResponseDto;
    submitAnswer: (answer: string, isPrivate: string) => void;
    cancelAnswer: () => void;
    sectionType?: string;
    files?: FileDto[];
    setFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    removeFile: (file: FileDto | null) => void;
    fileIsUploading: boolean;
    setFileIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
}

const BuyerAnswerQuestionDisplay: React.FC<IBuyerAnswerQuestionDisplayProps> = (props) => {
    const classes = useStyles();

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { userHasProjectPermission } = useCurrentUser();

    const {
        project,
        question,
        submitAnswer,
        cancelAnswer,
        sectionType,
        files,
        setFiles,
        removeFile,
        fileIsUploading,
        setFileIsUploading,
    } = props;
    const { company_uuid: companyUuid } = useReduxSelector((state) => state.authenticationState.accessToken);

    if (!question || !project) return null;

    const canViewAnswers = !!question.answersCount || question.companyUuid === (companyUuid ?? project.companyUuid);
    const canAnswerQuestion =
        !question.answersCount &&
        question.companyUuid !== (companyUuid ?? project.companyUuid) &&
        userHasProjectPermission(PermissionsEnum.CanAnswerClarificationQuestion, project.uuid);

    return (
        <div className={classes.grid}>
            <ProjectInformationDisplay
                projectName={project.projectName}
                responseDate={project.tenderResponseDeadline}
            />
            <ReadOnlyQuestionDisplay
                question={question}
                isAnonymous={question.companyUuid === project.companyUuid && !!companyUuid}
                isQuestionAuthor={companyUuid === question.companyUuid}
                sectionType={sectionType}
                projectUuid={project.uuid}
            />
            {canViewAnswers && (
                <ReadOnlyAnswerDisplay
                    question={question}
                    isQuestionAuthor={companyUuid === question.companyUuid}
                    isAnswerAuthor={question.answers.find((a) => a.companyUuid === companyUuid) != null}
                    projectUuid={project.uuid}
                    buyerCompanyUuid={project.clientCompanyUuid}
                />
            )}
            {canAnswerQuestion && (
                <BuyerAnswerQuestionForm
                    submit={submitAnswer}
                    cancel={cancelAnswer}
                    canAnswerQuestion={canAnswerQuestion}
                    files={files}
                    removeFile={removeFile}
                    setFiles={setFiles}
                    fileIsUploading={fileIsUploading}
                    setFileIsUploading={setFileIsUploading}
                />
            )}
            {!canAnswerQuestion && (
                <Button
                    className={classes.centered}
                    label={t("clarificationQuestions.viewAllQuestions")}
                    size="large"
                    onClick={() => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: "Question Summary - View All Questions",
                        });
                        navigate(buildPath(routes.projects.clarificationQuestions, { projectUuid: project.uuid }));
                    }}
                    testid="view-all-questions-button"
                />
            )}
        </div>
    );
};

export default BuyerAnswerQuestionDisplay;
