import { AxiosResponse } from "axios";
import { useCallback } from "react";

import { getBrandingSettingsForCompany, getBrandingSettingsForDomain } from "api/company/companyApi";
import maistroTheme from "styles/themes/maistro";
import { ITheme } from "styles/themes/types";
import {
    ICompanyBrandingSetting,
    ListBrandingSettingsResponseDto,
} from "types/dtos/company/ListBrandingSettingsResponseDto";
import CompanyBrandingType from "types/enums/companies/CompanyBrandingType";

const useWhiteLabelThemeProvider = () => {
    const constructTheme = (companyBrandingSettings: ICompanyBrandingSetting[], companyUuid: string) => {
        const getWhiteLabelThemeValue = (key: CompanyBrandingType) => {
            return companyBrandingSettings.find((setting) => setting.key === key.toString())?.value;
        };

        const whiteLabelTheme: ITheme = {
            ...maistroTheme,
            joditLicence: getWhiteLabelThemeValue(CompanyBrandingType.JoditLicence),
            isWhiteLabelled: companyUuid !== null && companyUuid !== undefined,
            colors: {
                ...maistroTheme.colors,
                highlight:
                    getWhiteLabelThemeValue(CompanyBrandingType.SecondaryColour) ?? maistroTheme.colors.highlight,
                accentBar: getWhiteLabelThemeValue(CompanyBrandingType.AccentColour) ?? maistroTheme.colors.accentBar,
                logoBackground:
                    getWhiteLabelThemeValue(CompanyBrandingType.LogoBackgroundColour) ??
                    maistroTheme.colors.logoBackground,
            },
            layout: {
                ...maistroTheme.layout,
                accentBarWidth: parseInt(
                    getWhiteLabelThemeValue(CompanyBrandingType.AccentBarWidth) ??
                        maistroTheme.layout.accentBarWidth.toString(),
                    10,
                ),
            },
            logos: {
                ...maistroTheme.logos,
                logo: getWhiteLabelThemeValue(CompanyBrandingType.Logo) ?? maistroTheme.logos.logo,
                squareLogo: getWhiteLabelThemeValue(CompanyBrandingType.SquareLogo) ?? maistroTheme.logos.squareLogo,
            },
        };
        return { ...whiteLabelTheme, maistroTheme };
    };

    const getCompanyBranding = useCallback(async (companyUuid?: string) => {
        const hostName = window.location.hostname.toString();
        const makeBrandingApiCalls: Promise<AxiosResponse<ListBrandingSettingsResponseDto>>[] = [];

        makeBrandingApiCalls.push(getBrandingSettingsForDomain(hostName));
        if (companyUuid) {
            makeBrandingApiCalls.push(getBrandingSettingsForCompany(companyUuid));
        }

        let settings: ICompanyBrandingSetting[] = [];
        await Promise.all(makeBrandingApiCalls).then(([domainResponse, companyResponse]) => {
            if (domainResponse.status === 200 && domainResponse.data.settings.length) {
                settings = [...domainResponse.data.settings, ...settings];
            }
            if (companyResponse && companyResponse.status === 200 && companyResponse.data.settings.length) {
                settings = [...companyResponse.data.settings, ...settings];
            }
        });

        return constructTheme(settings, companyUuid || "");
    }, []);

    return {
        getCompanyBranding,
    };
};

export default useWhiteLabelThemeProvider;
