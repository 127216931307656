import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons";
import { Button, LoadingScreen } from "@maistro/components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useLocation, useParams } from "react-router-dom";

import { Loader } from "components";
import useSupplierReports from "features/company/hooks/useSupplierReports";
import AboutSection from "features/company/profile/supplier/components/AboutSection";
import CategoriesSection from "features/company/profile/supplier/components/CategoriesSection";
import ContactDetailsSection from "features/company/profile/supplier/components/ContactDetailsSection";
import InsuranceSection from "features/company/profile/supplier/components/InsuranceSection";
import SummarySection from "features/company/profile/supplier/components/SummarySection";
import useProject from "features/project/hooks/useProject";
import useAppDispatch from "hooks/useAppDispatch";
import useCurrentUser from "hooks/useCurrentUser";
import useQuery from "hooks/useQuery";
import useReportOptions from "hooks/useReportOptions";
import validationService from "services/validationService";
import { useGetVettingInformationQuery } from "store/api/companyAdditionalInformationApi";
import { useLazyGetCompanySummaryQuery } from "store/api/companyApi";
import { useGetCountriesQuery, useGetInsuranceTypesQuery } from "store/api/referenceDataApi";
import { resetLayout, setBack, setCtas, setPageTitle } from "store/layoutSlice";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    panelContainer: {
        "& > div": {
            marginBottom: theme.spacing.medium,
        },
    },

    copy: {
        ...theme.typography.base,
        fontWeight: 600,
        marginBottom: theme.spacing.medium,
    },

    topContainer: {
        display: "flex",
        flexDirection: "row",
        columnGap: theme.spacing.xSmall,
        justifyContent: "space-between",
        alignItems: "baseline",
        marginBottom: theme.spacing.small,
    },

    vettingReportButton: {},

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        topContainer: {
            flexDirection: "column",
        },
        vettingReportButton: {
            width: "100%",
        },
    },
}));

interface ICompanyProps {
    companyUuid: string;
}

const ViewCompanyProfileContainer: React.FC = () => {
    const classes = useStyles();
    const params = useParams();
    const { companyUuid: supplierUuid } = params as unknown as ICompanyProps;

    const [isDownloading, setIsDownloading] = useState(false);
    const { myCompanyUuid } = useCurrentUser();
    const [
        fetchCompanySummary,
        { data: supplierCompanySummary, isLoading: isCompanySummaryLoading, isError: isCompanySummaryError },
    ] = useLazyGetCompanySummaryQuery();
    const { data: vettingInformation, isLoading: isVettingInformationLoading } =
        useGetVettingInformationQuery(supplierUuid);
    const { data: insuranceTypes, isLoading: isInsuranceReferenceDataLoading } = useGetInsuranceTypesQuery(undefined);
    const { data: countryTypes, isLoading: isCountryReferenceDataLoading } = useGetCountriesQuery(undefined);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { state } = useLocation();

    const urlSearchParameters = useQuery();
    const backToRoute = urlSearchParameters.get("backTo");

    const { projectInformation } = useProject();
    const { downloadVettingReport } = useSupplierReports();
    const { isReportMobileLayout } = useReportOptions();

    const fetchData = useCallback(async () => {
        if (!validationService.isValidUuid(supplierUuid)) {
            return;
        }
        const buyerInRelation = projectInformation.uuid ? projectInformation.companyUuid : myCompanyUuid;
        if (!buyerInRelation) {
            return;
        }
        await fetchCompanySummary(
            {
                buyerCompanyUuid: buyerInRelation,
                supplierCompanyUuid: supplierUuid,
            },
            true,
        ).unwrap();
    }, [fetchCompanySummary, myCompanyUuid, projectInformation.companyUuid, projectInformation.uuid, supplierUuid]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleDownloadReport = () => {
        setIsDownloading(true);
        downloadVettingReport(supplierUuid, myCompanyUuid).finally(() => {
            setIsDownloading(false);
        });
    };

    useEffect(() => {
        dispatch(setCtas(null));
        dispatch(setPageTitle(t("companyProfile.supplier.title")));
        if (state) {
            dispatch(
                setBack({
                    route: state.route,
                }),
            );
        }

        return () => {
            dispatch(resetLayout());
        };
    }, [backToRoute, dispatch, fetchData, state, t]);

    const renderVettingReportButton = () => {
        if (isReportMobileLayout) {
            return (
                <Button
                    label={t("companyProfile.supplier.downloadVettingReport.title")}
                    testid="download-vetting-report-button"
                    icon={faArrowDownToLine}
                    onClick={() => handleDownloadReport()}
                    size="small"
                    className={classes.vettingReportButton}
                />
            );
        }
        return (
            <Button
                label={t("companyProfile.supplier.viewAdditionalInformation")}
                testid="view-additional-information-button"
                onClick={() => window.open(`/vetting-report/${supplierUuid}`, "_blank")}
                size="small"
                className={classes.vettingReportButton}
            />
        );
    };

    if (
        isCompanySummaryLoading ||
        isVettingInformationLoading ||
        isInsuranceReferenceDataLoading ||
        isCountryReferenceDataLoading
    ) {
        return <Loader />;
    }

    return (
        <div>
            {isDownloading && (
                <LoadingScreen
                    title={t("companyProfile.supplier.downloadVettingReport.loading.title")}
                    message={t("companyProfile.supplier.downloadVettingReport.loading.text")}
                    duration={35000}
                    testid="vetting-reporting-loading"
                />
            )}
            {isCompanySummaryError && <div>{t("companyProfile.supplier.notFound")}</div>}
            {supplierCompanySummary && (
                <div>
                    <div className={classes.topContainer}>{renderVettingReportButton()}</div>
                    <div className={classes.panelContainer}>
                        <SummarySection data={supplierCompanySummary} testid="company-summary-section" />
                        <ContactDetailsSection
                            data={supplierCompanySummary}
                            countryReferenceData={countryTypes}
                            testid="company-contact-details-section"
                        />
                        <CategoriesSection
                            data={supplierCompanySummary.categories}
                            testid="company-categories-section"
                        />
                        <AboutSection data={supplierCompanySummary.description} testid="company-about-section" />
                        <InsuranceSection
                            insurance={vettingInformation?.insurances}
                            testid="company-insurance-section"
                            insuranceReferenceData={insuranceTypes}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ViewCompanyProfileContainer;
