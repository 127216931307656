import { useToaster } from "@maistro/components";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { getSupplierVettingReport } from "api/reportingApi";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const useSupplierReports = () => {
    const { t } = useTranslation();
    const toast = useToaster();

    const downloadVettingReport = useCallback(
        async (supplierCompanyUuid: string, buyerCompanyUuid?: string) => {
            if (!supplierCompanyUuid) return;

            const response = await getSupplierVettingReport(supplierCompanyUuid, buyerCompanyUuid);

            if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                toast.error(t("companyProfile.supplier.downloadVettingReport.error"));
                return;
            }

            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement("a");
            link.href = url;
            link.download = t("companyProfile.supplier.downloadVettingReport.fileName");
            link.click();
        },
        [t, toast],
    );

    return {
        downloadVettingReport,
    };
};

export default useSupplierReports;
