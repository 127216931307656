import React from "react";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    component: {
        flexGrow: 1,
        backgroundColor: theme.colors.lightGray,
        padding: `0 ${theme.spacing.small}px ${theme.spacing.medium}px ${theme.spacing.small}px`,
        zIndex: 5, // Do we need a zIndex here? Move to zIndexConstants if required?
        maxWidth: "100%",
    },
    [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        component: {
            borderRadius: 0,
        },
    },
}));

interface IContentProps extends ICommonProps {
    children?: React.ReactNode;
}

const Content: React.FC<IContentProps> = (props) => {
    const classes = useStyles();

    return (
        <div className={props.className} data-component="Content" data-testid={props.testid}>
            <main className={classes.component}>
                <div>{props.children}</div>
            </main>
        </div>
    );
};

export default Content;
