import { AxiosResponse } from "axios";

import { createAxiosInstance } from "api/commonApi";
import env from "config/EnvironmentVariables";
import { CompanyFeaturesDto } from "types/dtos/company/feature-toggles/companyFeaturesDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/companies";

// eslint-disable-next-line import/prefer-default-export
export const getEnabledCompanyFeatures = (
    companyUuid: string,
): Promise<AxiosResponse<CompanyFeaturesDto> | AxiosResponse<TransactionErrorDto>> => {
    return api.get(`${baseApiUrl}/${companyUuid}/feature-toggles`);
};
