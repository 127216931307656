enum ReportCode {
    ClarificationQuestions = "ClarificationQuestions",
    ProjectShortListing = "ProjectShortListing",
    ProjectSummary = "ProjectSummary",
    ProjectSummaryV1 = "ProjectSummaryV1",
    SupplyChainHealthCheck = "SupplyChainHealthCheck",
    ProjectsOverview = "ProjectsOverview",
    SupplierVettingEmbedded = "SupplierVettingEmbedded",
    ClarificationQuestionsDashboard = "ClarificationQuestionsDashboard",
    ScoreSummary = "ScoreSummary",
    UserGroupsDashboard = "UserGroupsDashboard",
}

export default ReportCode;
