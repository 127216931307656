import { faCircleCheck, faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { Copy, Icon } from "@maistro/components";
import classNames from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import colorService from "services/colorService";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        backgroundColor: colorService.toHexWithOpacity(theme.colors.gray, 0.2),
        padding: `${theme.spacing.small}px ${theme.spacing.medium}px`,
        borderRadius: theme.spacing.medium,
    },
    fields: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        rowGap: theme.spacing.small,
        columnGap: theme.spacing.xLarge,
        flexGrow: 1,
    },
    iconContainer: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing.small,
    },
    [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        fields: {
            flexDirection: "row",
        },
    },
    failureText: {
        fontWeight: 600,
        color: theme.colors.error,
        fontSize: 13,
    },
}));

interface ICheckmarkedRowProps extends ICommonProps {
    isChecked: boolean;
    failureText?: string;
    onClick?: () => void;
    children?: React.ReactNode;
}

const CheckmarkedRow: React.FC<ICheckmarkedRowProps> = (props) => {
    const classes = useStyles();

    const { isChecked, onClick, testid, failureText } = props;

    return (
        <div
            className={classNames(classes.container, props.className)}
            onClick={onClick}
            data-component="CheckmarkedRow"
            data-testid={testid}
        >
            <div className={classes.fields}>{props.children}</div>
            <div className={classes.iconContainer}>
                <Icon
                    icon={isChecked ? faCircleCheck : faExclamationTriangle}
                    color={isChecked ? "success" : "error"}
                    size="large"
                    testid={`${testid}-icon`}
                />
                {!isChecked && failureText && <Copy className={classes.failureText}>{failureText}</Copy>}
            </div>
        </div>
    );
};

export default CheckmarkedRow;
