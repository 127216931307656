import { useToaster } from "@maistro/components";
import React, { useEffect } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";

import GuardedRoute from "features/auth/GuardedRoute";
import ProjectNotFound from "features/project/ProjectNotFound";
import AwardSummaryContainer from "features/project/awardSummary/AwardSummaryContainer";
import BuyerAnswerQuestionContainer from "features/project/clarificationQuestions/answerQuestion/BuyerAnswerQuestionContainer";
import BuyerAskQuestionContainer from "features/project/clarificationQuestions/askQuestion/BuyerAskQuestionContainer";
import BuyerClarificationQuestionsContainer from "features/project/clarificationQuestions/buyerQuestions/BuyerClarificationQuestionsContainer";
import CompleteProjectContainer from "features/project/completeProject/CompleteProjectContainer";
import CompleteProjectWithoutAwardContainer from "features/project/completeWithoutAward/CompleteProjectWithoutAwardContainer";
import MonitorEOIContainer from "features/project/expressionOfInterest/monitor/MonitorContainer";
import EOIResultsContainer from "features/project/expressionOfInterest/results/ResultsContainer";
import MonitorContainer from "features/project/monitor/MonitorContainer";
import MonitorApprovalsContainer from "features/project/monitorApprovals/MonitorApprovalsContainer";
import ProjectSummaryContainer from "features/project/projectSummary/ProjectSummaryContainer";
import ResultsContainer from "features/project/results/ResultsContainer";
import ProjectSettingsContainer from "features/project/settings/ProjectSettingsContainer";
import SupplierMatchingContainer from "features/project/supplierMatching/SupplierMatchingContainer";
import DocumentManagementContainer from "features/project/tenderCriteria/documentManagement/DocumentManagementContainer";
import TenderQuestionsContainer from "features/project/tenderCriteria/questions/TenderQuestionsContainer";
import ScoreTenderQuestionContainer from "features/project/tenderCriteria/scoring/ScoreTenderQuestionContainer";
import ScoringTenderBySupplierContainer from "features/project/tenderCriteria/scoringBySupplier/ScoringTenderBySupplierContainer";
import ScoreTenderSummaryContainer from "features/project/tenderCriteria/scoringSummary/ScoreTenderSummaryContainer";
import ScoreTenderSummaryBySupplierContainer from "features/project/tenderCriteria/scoringSummaryBySupplier/ScoreTenderSummaryBySupplierContainer";
import SectionSelectionContainer from "features/project/tenderCriteria/sections/SectionSelectionContainer";
import TenderQuestionsSummaryContainer from "features/project/tenderCriteria/tenderQuestionsSummary/TenderQuestionsSummaryContainer";
import SectionWeighting from "features/project/tenderCriteria/weighting/SectionWeighting";
import ScoreSummaryReport from "features/reports/ScoreSummaryReport";
import useAppDispatch from "hooks/useAppDispatch";
import useReduxSelector from "hooks/useReduxSelector";
import routes from "routes/routePaths/RoutePaths";
import validationService from "services/validationService";
import { fetchProject, fetchTenderCriteria } from "store/projectSlice";
import { fetchSections } from "store/sectionsSlice";
import ProjectStatus from "types/enums/projects/ProjectStatus";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

const ProjectsRouter: React.FC = () => {
    const { projectInformation, fetchingErrorMessage } = useReduxSelector((state) => state.projectState);
    const { user_uuid: userUuid } = useReduxSelector((state) => state.authenticationState.accessToken);

    const dispatch = useAppDispatch();
    const toaster = useToaster();
    const navigate = useNavigate();
    const { projectUuid } = useParams();

    useEffect(() => {
        if (projectUuid && validationService.isValidUuid(projectUuid) && projectUuid !== projectInformation.uuid) {
            dispatch(fetchProject(projectUuid));
            dispatch(fetchSections({ projectUuid }));
            dispatch(
                fetchTenderCriteria({
                    projectUuid,
                    userUuid,
                }),
            );
        }
    }, [projectInformation.uuid, dispatch, projectUuid, userUuid]);

    useEffect(() => {
        if (fetchingErrorMessage.length > 0) {
            toaster.error(fetchingErrorMessage);
        }
    }, [fetchingErrorMessage, toaster]);

    useEffect(() => {
        if (projectInformation.status) {
            if (
                projectInformation.status === ProjectStatus.Archived ||
                projectInformation.status === ProjectStatus.Cancelled
            ) {
                navigate(routes.projects.projectNotFound.path);
            }
        }
    }, [projectInformation.status, projectInformation.uuid, navigate]);

    return (
        <Routes>
            <Route path="" element={<GuardedRoute />}>
                <Route path={routes.projects.matching.path} element={<SupplierMatchingContainer />} />
                <Route path={routes.projects.summary.path} element={<ProjectSummaryContainer />} />
                <Route path={routes.projects.award.path} element={<AwardSummaryContainer />} />
                <Route
                    path={routes.projects.viewClarificationQuestion.path}
                    element={<BuyerAnswerQuestionContainer />}
                />
                <Route
                    path={routes.projects.clarificationQuestions.path}
                    element={<BuyerClarificationQuestionsContainer />}
                />
                <Route path={routes.projects.complete.path} element={<CompleteProjectContainer />} />
                <Route path={routes.projects.monitor.path} element={<MonitorContainer />} />
                <Route path={routes.projects.monitorApprovals.path} element={<MonitorApprovalsContainer />} />
                <Route path={routes.projects.awardSupplier.path} element={<ResultsContainer />} />
                <Route path={routes.projects.monitorExpressionOfInterest.path} element={<MonitorEOIContainer />} />
                <Route path={routes.projects.results.path} element={<EOIResultsContainer />} />
                <Route path={routes.projects.sections.path} element={<SectionSelectionContainer />} />
                <Route path={routes.projects.weighting.path} element={<SectionWeighting />} />
                <Route path={routes.projects.sectionQuestions.path} element={<TenderQuestionsContainer />} />
                <Route path={routes.projects.sectionQuestionsView.path} element={<TenderQuestionsSummaryContainer />} />
                <Route path={routes.projects.scoreTenderQuestion.path} element={<ScoreTenderQuestionContainer />} />
                <Route path={routes.projects.tenderSummary.path} element={<ScoreTenderSummaryContainer />} />
                <Route
                    path={routes.projects.tenderSummaryBySupplier.path}
                    element={<ScoreTenderSummaryBySupplierContainer />}
                />
                <Route
                    path={routes.projects.scoreTenderQuestionsBySupplier.path}
                    element={<ScoringTenderBySupplierContainer />}
                />
                <Route path={routes.projects.scoreSummaryReport.path} element={<ScoreSummaryReport />} />
                <Route path={routes.projects.tenderDocuments.path} element={<DocumentManagementContainer />} />
                <Route path={routes.projects.settings.path} element={<ProjectSettingsContainer />} />
                <Route path={routes.projects.notFound.path} element={<ProjectNotFound />} />
            </Route>
            <Route
                path={routes.projects.askClarificationQuestion.path}
                element={<GuardedRoute permission={PermissionsEnum.CanAskClarificationQuestion} />}
            >
                <Route path="" element={<BuyerAskQuestionContainer />} />
            </Route>
            <Route
                path={routes.projects.completeWithoutAward.path}
                element={<GuardedRoute permission={PermissionsEnum.ReadReport} />}
            >
                <Route path="" element={<CompleteProjectWithoutAwardContainer />} />
            </Route>
        </Routes>
    );
};

export default ProjectsRouter;
