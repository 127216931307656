enum RolesEnum {
    GlobalAdmin = "GlobalAdmin",
    CompanyAdmin = "CompanyAdmin",
    ProjectContributor = "ProjectContributor",
    User = "User",
    BuyerReadOnly = "BuyerReadOnly",
    ProjectManager = "ProjectManager",
    Supplier = "Supplier",
}

export default RolesEnum;
