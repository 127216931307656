/* eslint-disable @typescript-eslint/no-var-requires */
const enGbActivateUser = require("./activateUser.json");
const enGbAddresses = require("./addresses.json");
const enGbApprovals = require("./approvals.json");
const enGbAwardSummary = require("./awardSummary.json");
const enGbBudgetTypes = require("./budgetTypes.json");
const enGbBuyerLists = require("./buyerLists.json");
const enGbClarificationQuestions = require("./clarificationQuestions.json");
const enGbCommon = require("./common.json");
const enGbCompanyList = require("./companyList.json");
const enGbCompanyInformation = require("./companyInformation.json");
const enGbCompanyProfile = require("./companyProfile.json");
const enGbCompleteProject = require("./completeProject.json");
const enGbComponents = require("./components.json");
const enGbDashboard = require("./dashboard.json");
const enGbError = require("./error.json");
const enGbNoInternet = require("./noInternet.json");
const enGbExpressionOfInterest = require("./expressionOfInterest.json");
const enGbFileUpload = require("./fileUpload.json");
const enGbGenerateProjectDescription = require("./generateProjectDescription.json");
const enGbHelp = require("./help.json");
const enGbMonitorApprovals = require("./monitorApprovals.json");
const enGbMonitorScreen = require("./monitorScreen.json");
const enGbNotifications = require("./notifications.json");
const enGbPagination = require("./pagination.json");
const enGbPopups = require("./popups.json");
const enGbProjectBrief = require("./projectBrief.json");
const enGbProjectForbidden = require("./projectForbidden.json");
const enGbProjectMatchingScreen = require("./projectMatchingScreen.json");
const enGbProjectSummary = require("./projectSummary.json");
const enGbProjectResponse = require("./projectResponse.json");
const enGbProjectSettings = require("./projectSettings.json");
const enGbReports = require("./reports.json");
const enGbRoles = require("./roles.json");
const enGbRoutes = require("./routes.json");
const enGbSecondaryNavItems = require("./secondaryNavItems.json");
const enGbSettings = require("./settings.json");
const enGbSupplierFeedback = require("./supplierFeedback.json");
const enGbCompanySuppliers = require("./companySuppliers.json");
const enGbSupplierResponse = require("./supplierResponse.json");
const enGbSupplierTenderQuestions = require("./supplierTenderQuestions.json");
const enGbSystemStatus = require("./systemStatus.json");
const enGbTenderCriteria = require("./tenderCriteria.json");
const enGbTenderQuestions = require("./tenderQuestions.json");
const enGbTenderSummaryScreen = require("./tenderSummaryScreen.json");
const enGbUserGroups = require("./userGroups.json");
const enGbUsers = require("./users.json");
const enGbCompanyProfileUsers = require("./companyProfileUsers.json");
const enGbCompanyServices = require("./companyServices.json");
const enGbCompanyVettingQuestionnaire = require("./companyVettingQuestionnaire.json");
const enGbAdminUsers = require("./adminUsers.json");

const enGbTranslations = {
    activateUser: enGbActivateUser,
    addresses: enGbAddresses,
    adminUsers: enGbAdminUsers,
    approvals: enGbApprovals,
    awardSummary: enGbAwardSummary,
    budgetTypes: enGbBudgetTypes,
    buyerLists: enGbBuyerLists,
    clarificationQuestions: enGbClarificationQuestions,
    common: enGbCommon,
    companyList: enGbCompanyList,
    companyInformation: enGbCompanyInformation,
    companyProfile: enGbCompanyProfile,
    companyProfileUsers: enGbCompanyProfileUsers,
    completeProject: enGbCompleteProject,
    companyServices: enGbCompanyServices,
    companyVettingQuestionnaire: enGbCompanyVettingQuestionnaire,
    components: enGbComponents,
    dashboard: enGbDashboard,
    error: enGbError,
    noInternet: enGbNoInternet,
    expressionOfInterest: enGbExpressionOfInterest,
    fileUpload: enGbFileUpload,
    generateProjectDescription: enGbGenerateProjectDescription,
    help: enGbHelp,
    monitorApprovals: enGbMonitorApprovals,
    monitorScreen: enGbMonitorScreen,
    notifications: enGbNotifications,
    pagination: enGbPagination,
    popups: enGbPopups,
    projectBrief: enGbProjectBrief,
    projectForbidden: enGbProjectForbidden,
    projectMatchingScreen: enGbProjectMatchingScreen,
    projectSummary: enGbProjectSummary,
    projectResponse: enGbProjectResponse,
    projectSettings: enGbProjectSettings,
    reports: enGbReports,
    roles: enGbRoles,
    routes: enGbRoutes,
    secondaryNavItems: enGbSecondaryNavItems,
    settings: enGbSettings,
    supplierFeedback: enGbSupplierFeedback,
    companySuppliers: enGbCompanySuppliers,
    supplierResponse: enGbSupplierResponse,
    supplierTenderQuestions: enGbSupplierTenderQuestions,
    systemStatus: enGbSystemStatus,
    tenderCriteria: enGbTenderCriteria,
    tenderQuestions: enGbTenderQuestions,
    tenderSummaryScreen: enGbTenderSummaryScreen,
    userGroups: enGbUserGroups,
    users: enGbUsers,
};

export default enGbTranslations;
