import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { IOption, ISortBy, Pagination } from "@maistro/components";
import { IButtonProps } from "@maistro/components/dist/esm/types/components/Button/Button";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";

import { Loader } from "components";
import CompanyProfileActionPopup from "features/company/profile/CompanyProfileActionPopup";
import useCompanyStepperNavigation from "features/company/profile/hooks/useCompanyStepperNavigation";
import AddSuppliersDrawer from "features/company/profile/suppliers/AddSuppliersDrawer";
import CompanySupplierListDisplay from "features/company/profile/suppliers/CompanySupplierListDisplay";
import { ISupplierListFilters } from "features/company/profile/suppliers/filter/hooks/useSupplierListFilter";
import SupplierListFilters from "features/company/profile/suppliers/filter/supplierListFilters";
import useSupplierList from "features/company/profile/suppliers/hooks/useSupplierList";
import useAppDispatch from "hooks/useAppDispatch";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import { buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { useGetCompanyDetailsQuery } from "store/api/companyApi";
import {
    resetLayout,
    setBack,
    setCtas,
    setMobileFooterCtas,
    setPageTitle,
    setTooltip,
    setTooltipClassName,
} from "store/layoutSlice";
import { ITheme } from "styles/themes/types";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    pagination: {
        marginTop: theme.spacing.small,
    },
}));

const CompanySupplierListContainer: React.FC = () => {
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
    const [isAddSuppliersDrawerOpen, setIsAddSuppliersDrawerOpen] = useState(false);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { userIsMaistro } = useCurrentUser();
    const { companyUuid } = useParams();

    const {
        navigateToPage,
        paginationState,
        supplierList,
        updateSupplierRelationshipStatus,
        updateSupplierTieringStatus,
        updateSupplierExternalId,
        addSuppliersToSupplierList,
        removeSupplierFromSupplierList,
        searchForUnlistedSuppliers,
        setSortBy,
        sortBy,
        isSearching,
        disableSorting,
        currentFilters,
        setFilters,
    } = useSupplierList(setIsLoading, companyUuid);

    const { data: companyDetails } = useGetCompanyDetailsQuery(companyUuid ?? "");

    const { isCompanyProfileActionPopupOpen, setIsCompanyProfileActionPopupOpen } = useCompanyStepperNavigation({
        activeStep: "suppliers",
        title: companyDetails?.registeredName,
        subTitle: companyDetails?.netPromoterScore
            ? t("companyProfile.stepperNavigation.netPromoterScore", {
                  netPromoterScore: companyDetails?.netPromoterScore,
              })
            : "",
        companyUuid: companyUuid ?? "",
        isActive: companyDetails?.isActive,
    });

    const emptyFilters: ISupplierListFilters = {
        relationshipStatuses: [],
        tieringStatuses: [],
        activeStatuses: [],
        searchTerm: "",
    };

    const addSuppliers = useCallback(
        (suppliers: Array<IOption>) => {
            addSuppliersToSupplierList(suppliers.map((s) => s.value));
            setIsAddSuppliersDrawerOpen(false);
        },
        [addSuppliersToSupplierList],
    );

    useEffect(() => {
        const titleTooltipContent = [
            {
                text: t("companySuppliers.tooltips.supplierList"),
                fieldName: t("companySuppliers.table.supplierList"),
            },
            {
                text: t("companySuppliers.tooltips.relationshipStatus"),
                fieldName: t("companySuppliers.table.relationshipStatus"),
            },
        ];

        dispatch(setPageTitle(t("companySuppliers.title")));
        dispatch(setTooltip(titleTooltipContent));
        dispatch(setTooltipClassName("hidden-desktop"));
        dispatch(
            setBack({
                route: buildRoute(routes.company.companyProfile, { companyUuid }),
            }),
        );

        if (userIsMaistro) {
            const addSuppliersButtonProps = {
                onClick: () => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: `Company Suppliers - Add Supplier`,
                    });
                    setIsAddSuppliersDrawerOpen(true);
                },
                label: t("companySuppliers.addSuppliers.addToList"),
                testid: "add-suppliers-button",
                icon: faPlus,
            } as IButtonProps;
            dispatch(setCtas([addSuppliersButtonProps]));
            dispatch(setMobileFooterCtas([addSuppliersButtonProps]));
        }

        return () => {
            dispatch(resetLayout());
        };
    }, [dispatch, t, companyUuid, userIsMaistro, setIsAddSuppliersDrawerOpen]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <React.Fragment>
            <SupplierListFilters
                currentFilters={currentFilters}
                setFilterValues={setFilters}
                isSearching={isSearching}
                resetFilters={() => setFilters(emptyFilters)}
                resetPage={() => navigateToPage(1)}
                isFilterDrawerOpen={isFilterDrawerOpen}
                setIsFilterDrawerOpen={setIsFilterDrawerOpen}
            />
            <CompanySupplierListDisplay
                suppliers={supplierList}
                buyerCompanyUuid={companyUuid ?? ""}
                buyerCompanyName={companyDetails?.registeredName}
                updateSupplierRelationshipStatus={updateSupplierRelationshipStatus}
                updateSupplierTieringStatus={updateSupplierTieringStatus}
                updateSupplierExternalId={updateSupplierExternalId}
                removeSupplierFromSupplierList={removeSupplierFromSupplierList}
                setFilterValues={setFilters}
                sortBy={disableSorting ? { column: "unset" } : sortBy}
                setSortBy={
                    disableSorting
                        ? undefined
                        : (sort: ISortBy) => {
                              setSortBy(sort);
                              navigateToPage(1);
                          }
                }
                setIsFilterDrawerOpen={setIsFilterDrawerOpen}
            />
            <Pagination
                className={classes.pagination}
                paginationState={paginationState}
                navigateToPage={navigateToPage}
                testid="supplier-list-pagination"
            />
            <CompanyProfileActionPopup
                isCompanyProfileActionPopupOpen={isCompanyProfileActionPopupOpen}
                setIsCompanyProfileActionPopupOpen={setIsCompanyProfileActionPopupOpen}
                isActive={companyDetails?.isActive}
                isBuyer={companyDetails?.allowBuyerUsers}
                companyUuid={companyDetails?.uuid}
                registeredName={companyDetails?.registeredName}
            />
            <AddSuppliersDrawer
                isOpen={isAddSuppliersDrawerOpen}
                onAdd={addSuppliers}
                onClose={() => setIsAddSuppliersDrawerOpen(false)}
                companyName={companyDetails?.registeredName ?? ""}
                searchForUnlistedSuppliers={searchForUnlistedSuppliers}
            />
        </React.Fragment>
    );
};

export default CompanySupplierListContainer;
