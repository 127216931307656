import baseApi from "store/api/baseApi";
import { CompanyUserDto } from "types/dtos/company/CompanyUserDto";
import DeleteCompanyUserRequestDto from "types/dtos/company/DeleteCompanyUserRequestDto";
import { ListCompanyUsersRequestDto } from "types/dtos/company/ListCompanyUsersRequestDto";
import { ListCompanyUsersResponseDto } from "types/dtos/company/ListCompanyUsersResponseDto";
import { UpdateCompanyUserRequestDto } from "types/dtos/company/UpdateCompanyUserRequestDto";
import TagNames from "types/enums/store/TagNames";

const companyUsersApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [TagNames.CompanyUsers],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            listCompanyUsers: build.query<ListCompanyUsersResponseDto, ListCompanyUsersRequestDto>({
                query: (request) => ({
                    url: `/companies/${request.companyUuid}/users`,
                    method: "GET",
                    params: {
                        includeUserRoles: request.includeUserRoles,
                        searchQuery: request.searchQuery,
                        skip: request.skip ?? 0,
                        take: request.take ?? 50,
                    },
                }),
                providesTags: [TagNames.CompanyUsers],
            }),
            updateCompanyUser: build.mutation<CompanyUserDto, UpdateCompanyUserRequestDto>({
                query: (request) => ({
                    url: `/companies/${request.companyUuid}/users/${request.user.userUuid}`,
                    method: "PUT",
                    data: request.user,
                }),
            }),
            createCompanyUser: build.mutation<CompanyUserDto, UpdateCompanyUserRequestDto>({
                query: (request) => ({
                    url: `/companies/${request.companyUuid}/users`,
                    method: "POST",
                    data: request.user,
                }),
            }),
            deleteCompanyUser: build.mutation<undefined, DeleteCompanyUserRequestDto>({
                query: (request) => ({
                    url: `/companies/${request.companyUuid}/users/${request.userUuid}`,
                    method: "DELETE",
                }),
            }),
        }),
        overrideExisting: false,
    });

export const {
    useLazyListCompanyUsersQuery,
    useUpdateCompanyUserMutation,
    useCreateCompanyUserMutation,
    useDeleteCompanyUserMutation,
} = companyUsersApi;
