import classNames from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    paper: {
        width: "100%",
    },
    default: {
        backgroundColor: theme.colors.white,
        border: `1px solid ${theme.colors.gray}`,
        borderRadius: theme.spacing.large,
        padding: theme.spacing.xLarge,
    },
    gray: {
        backgroundColor: theme.colors.medLightGray,
        border: `1px solid ${theme.colors.medLightGray}`,
        borderRadius: theme.spacing.small,
        padding: `${theme.spacing.medium}px ${theme.spacing.large}px`,
    },
    white: {
        backgroundColor: theme.colors.white,
        border: `1px solid ${theme.colors.white}`,
        borderRadius: theme.spacing.small,
        padding: `${theme.spacing.medium}px ${theme.spacing.large}px`,
    },
}));

interface IPaperProps extends ICommonProps {
    color?: "default" | "gray" | "white";
    children?: React.ReactNode;
}

const Paper: React.FC<IPaperProps> = (props) => {
    const { color } = props;
    const classes = useStyles();

    return (
        <div
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            className={classNames(classes.paper, classes[color!], props.className)}
            data-component="Paper"
            data-testid={props.testid}
        >
            {props.children}
        </div>
    );
};

export default Paper;
Paper.defaultProps = {
    color: "default",
};
