import { getCompanyEmailSettings } from "api/company/companyApi";
import useReduxSelector from "hooks/useReduxSelector";
import { useEffect, useState } from "react";
import { CompanyEmailSettingsDto } from "types/dtos/company/CompanyEmailSettingsDto";

const useWhitelabelSettings = () => {
    const [emailSettings, setEmailSettings] = useState<CompanyEmailSettingsDto>({
        companyUuid: "",
        defaultFromAddress: "support@maistro.com",
    });
    const accessToken = useReduxSelector((state) => state.authenticationState?.accessToken);
    useEffect(() => {
        if (accessToken?.company_uuid) {
            getCompanyEmailSettings(accessToken.company_uuid).then((response) => {
                if (response.status === 200) {
                    setEmailSettings(response.data);
                }
            });
        }
    }, [accessToken]);

    return {
        whitelabelEmailSettings: emailSettings,
    };
};

export default useWhitelabelSettings;
