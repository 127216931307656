import { useToaster } from "@maistro/components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
    createQuestion,
    deleteQuestion,
    getProjectSections,
    listQuestionsForSection,
    updateQuestion,
    updateQuestions,
} from "api/questions/tenderCriteriaQuestionsApi";
import { getSectionTitle } from "features/project/helpers/sectionHelpers";
import useQuestionSorters from "features/project/shared/tender-questions/useQuestionSorters";
import useReduxSelector from "hooks/useReduxSelector";
import validationService from "services/validationService";
import { CreateTenderCriteriaQuestionRequestDto } from "types/dtos/questions/CreateTenderCriteriaQuestionRequestDto";
import { ISectionQuestions } from "types/dtos/questions/ISectionQuestions";
import { ListQuestionsResponseDto } from "types/dtos/questions/ListQuestionsResponseDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { GetProjectSectionsResponseDto } from "types/dtos/questions/sections/GetProjectSectionsResponseDto";
import { UpdateTenderCriteriaQuestionRequestDto } from "types/dtos/questions/UpdateTenderCriteriaQuestionRequestDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import EntityType from "types/enums/questions/EntityType";

export interface IUseTenderQuestionsConfig {
    projectUuid?: string;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useTenderQuestions = (props: IUseTenderQuestionsConfig) => {
    const { projectUuid, setIsLoading } = props;

    const [sectionsResponse, setSectionsResponse] = useState<GetProjectSectionsResponseDto>();
    const [sectionQuestions, setSectionQuestions] = useState<ISectionQuestions[]>([]);
    const userUuid = useReduxSelector((state) => state.authenticationState.accessToken.user_uuid);

    const { t } = useTranslation();
    const toast = useToaster();

    const { sortSectionsQuestionsAlphabetically, Alphabetically, byWeightingThenAlphabetically } = useQuestionSorters();

    const reloadAll = useCallback(() => {
        if (projectUuid) {
            setIsLoading(true);
            getProjectSections(projectUuid).then((response) => {
                if (response.status === 200) {
                    setSectionsResponse(response.data as GetProjectSectionsResponseDto);
                } else {
                    toast.error(t("tenderQuestions.getProjectSectionsError"));
                    setIsLoading(false);
                }
            });
        }
    }, [projectUuid, setIsLoading, toast, t]);

    useEffect(() => {
        reloadAll();
    }, [reloadAll]);

    useEffect(() => {
        if (projectUuid && sectionsResponse?.sections && sectionsResponse?.sections.length > 0) {
            const sectionsToLoad: Promise<void>[] = [];
            sectionsResponse.sections.forEach((section) => {
                sectionsToLoad.push(
                    listQuestionsForSection(projectUuid, section.id).then((response) => {
                        if (response.status === 200) {
                            const questionResponse = response.data as ListQuestionsResponseDto;
                            setSectionQuestions((state) => {
                                questionResponse.items.forEach((question, index) => {
                                    question.orderIndex = index;
                                });
                                return [
                                    ...state.filter((el) => section.id !== el.id),
                                    { id: section.id, items: questionResponse.items } as ISectionQuestions,
                                ];
                            });
                        } else {
                            toast.error(
                                t("tenderQuestions.getProjectSectionsError", {
                                    sectionName: getSectionTitle(section),
                                }),
                            );
                        }
                    }),
                );
            });
            Promise.all(sectionsToLoad).then(() => setIsLoading(false));
        } else if (sectionsResponse?.sections) {
            setIsLoading(false);
        }
    }, [sectionsResponse?.sections, projectUuid, setIsLoading, toast, t]);

    const reloadSection = useCallback(
        (sectionId: string) => {
            if (projectUuid) {
                setSectionQuestions((state) => {
                    return [
                        ...state.filter((el) => sectionId !== el.id),
                        {
                            ...state.find((el) => sectionId === el.id),
                            isLoading: true,
                        } as ISectionQuestions,
                    ];
                });
                listQuestionsForSection(projectUuid, sectionId).then((response) => {
                    if (response.status === 200) {
                        const questionResponse = response.data as ListQuestionsResponseDto;
                        setSectionQuestions((state) => {
                            return [
                                ...state.filter((el) => sectionId !== el.id),
                                {
                                    id: sectionId,
                                    items: questionResponse.items,
                                    isLoading: false,
                                } as ISectionQuestions,
                            ];
                        });
                    }
                });
            }
        },
        [projectUuid],
    );

    const submitQuestion = useCallback(
        async (question: QuestionResponseDto, orderChanged: boolean) => {
            if (!(projectUuid && userUuid && validationService.isValidUuid(projectUuid))) {
                return null;
            }

            const response = await createQuestion(projectUuid, {
                entityType: EntityType.Section,
                entityUuid: question.entityUuid,
                questionText: question.questionText ?? "",
                askingUserUuid: userUuid,
                answerTypeId: parseInt(question.answerTypeId, 10),
                isConditional: question.isConditional,
                disableFileAttachments: question.disableFileAttachments,
                orderIndex: question.orderIndex,
            } as CreateTenderCriteriaQuestionRequestDto);

            if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                toast.error(t("tenderQuestions.addQuestionError"));
                return null;
            }

            toast.success(t("tenderQuestions.addQuestionSuccess"));
            if (!orderChanged) {
                reloadSection(question.entityUuid);
            }
            return response.data.questionUuid;
        },
        [projectUuid, userUuid, toast, t, reloadSection],
    );

    const deleteQuestionFromSection = useCallback(
        (sectionId: string, questionUuid: string, orderChanged: boolean) => {
            if (!projectUuid) return;
            deleteQuestion(projectUuid, questionUuid).then(() => {
                toast.informative(t("tenderQuestions.deleteQuestionSuccess"));
                if (!orderChanged) {
                    reloadSection(sectionId);
                }
            });
        },
        [projectUuid, toast, t, reloadSection],
    );

    const editQuestion = useCallback(
        (selectedSectionId: string, question: QuestionResponseDto, orderChanged: boolean) => {
            if (!projectUuid || !userUuid) return;

            updateQuestion(projectUuid, question.questionUuid, {
                entityUuid: question.entityUuid,
                questionText: question.questionText ?? "",
                askingUserUuid: userUuid,
                isConditional: question.isConditional,
                answerTypeId: parseInt(question.answerTypeId, 10),
                disableFileAttachments: question.disableFileAttachments,
                orderIndex: question.orderIndex,
            } as UpdateTenderCriteriaQuestionRequestDto).then((response) => {
                if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                    toast.error(t("tenderQuestions.editQuestionError"));
                    return;
                }

                toast.success(t("tenderQuestions.editQuestionSuccess"));
                if (!orderChanged) {
                    reloadSection(question.entityUuid);
                }
            });
        },
        [projectUuid, userUuid, toast, t, reloadSection],
    );

    const editQuestions = useCallback(
        (questions: QuestionResponseDto[]) => {
            if (!projectUuid || !userUuid) return;

            const mappedQuestions = questions.map((question) => ({
                questionUuid: question.questionUuid,
                entityUuid: question.entityUuid,
                questionText: question.questionText || "",
                projectUuid,
                isConditional: question.isConditional,
                disableFileAttachments: question.disableFileAttachments,
                orderIndex: question.orderIndex,
            }));
            const request = { projectUuid, questions: mappedQuestions };

            updateQuestions(projectUuid, request).then((response) => {
                if (response.data instanceof TransactionErrorDto || response.status !== 204) {
                    toast.error(t("tenderQuestions.editQuestionError"));
                    return;
                }

                toast.success(t("tenderQuestions.editQuestionSuccess"));
                reloadAll();
            });
        },
        [projectUuid, userUuid, toast, t, reloadAll],
    );

    const sectionsWithoutOrdering = useMemo(() => sectionsResponse?.sections ?? [], [sectionsResponse]);

    const orderedByWeightSections = useMemo(
        () => sectionsResponse?.sections.map((section) => ({ ...section })).sort(byWeightingThenAlphabetically) ?? [],
        [byWeightingThenAlphabetically, sectionsResponse?.sections],
    );

    const orderedByAlphabetSections = useMemo(
        () => sectionsResponse?.sections.map((section) => ({ ...section })).sort(Alphabetically) ?? [],
        [Alphabetically, sectionsResponse?.sections],
    );

    const orderdByAlphabetSectionQuestions = useMemo(
        () => sortSectionsQuestionsAlphabetically(sectionQuestions),
        [sectionQuestions, sortSectionsQuestionsAlphabetically],
    );

    return {
        sectionsWithoutOrdering,
        reloadSection,
        sectionsResponse,
        sectionQuestions,
        submitQuestion,
        editQuestion,
        deleteQuestionFromSection,
        reloadAll,
        editQuestions,
        orderdByAlphabetSectionQuestions,
        orderedByWeightSections,
        orderedByAlphabetSections,
    };
};
