import baseApi from "store/api/baseApi";
import { AdminUserDto } from "types/dtos/admin/AdminUserDto";
import { CreateAdminUserRequestDto } from "types/dtos/admin/CreateAdminUserRequestDto";
import { ReinviteAdminUserRequestDto } from "types/dtos/admin/ReinviteAdminUserRequestDto";
import { SearchAdminUsersRequestDto } from "types/dtos/admin/SearchAdminUsersRequestDto";
import { SearchAdminUsersResponseDto } from "types/dtos/admin/SearchAdminUsersResponseDto";
import { UpdateAdminUserRequestDto } from "types/dtos/admin/UpdateAdminUserRequestDto";
import TagNames from "types/enums/store/TagNames";

const adminApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [TagNames.AdminUsers],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            searchAdminUsersApi: build.query<SearchAdminUsersResponseDto, SearchAdminUsersRequestDto>({
                query: (request) => ({
                    url: `/admin-users`,
                    method: "GET",
                    params: {
                        searchText: request.searchText,
                        skip: request.skip,
                        take: request.take,
                        orderByColumn: request.orderByColumn,
                        sortDescending: request.sortDescending,
                    },
                }),
                providesTags: [TagNames.AdminUsers],
            }),
            updateAdminUserApi: build.mutation<AdminUserDto, UpdateAdminUserRequestDto>({
                query: (request) => ({
                    url: `/admin-users/${request.userUuid}`,
                    method: "PUT",
                    data: request,
                }),
                invalidatesTags: [TagNames.AdminUsers],
            }),
            createAdminUserApi: build.mutation<AdminUserDto, CreateAdminUserRequestDto>({
                query: (request) => ({
                    url: `/admin-users`,
                    method: "POST",
                    data: request,
                }),
                invalidatesTags: [TagNames.AdminUsers],
            }),
            reinviteAdminUserApi: build.mutation<null, ReinviteAdminUserRequestDto>({
                query: (request) => ({
                    url: `/admin-users/${request.userUuid}/reinvite`,
                    method: "POST",
                    data: request,
                }),
            }),
        }),
        overrideExisting: false,
    });

export const {
    useSearchAdminUsersApiQuery,
    useCreateAdminUserApiMutation,
    useReinviteAdminUserApiMutation,
    useUpdateAdminUserApiMutation,
} = adminApi;
