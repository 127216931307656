import {
    createAction,
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    EntityState,
    PayloadAction,
} from "@reduxjs/toolkit";

import { listNotifications } from "api/notificationsApi";
import type { AppStore } from "store/store";
import { ListNotificationsRequestDto } from "types/dtos/notifications/ListNotificationsRequestDto";
import { NotificationDto } from "types/dtos/notifications/NotificationDto";
import { NotificationListDto } from "types/dtos/notifications/NotificationListDto";
import { NotificationStatusDto } from "types/dtos/notifications/NotificationStatusDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

export const fetchNotifications = createAsyncThunk<
    NotificationListDto,
    ListNotificationsRequestDto,
    { rejectValue: TransactionErrorDto }
>("notifications/fetchNotifications", async (request, thunkAPI) => {
    const response = await listNotifications(request);

    if (response.data instanceof TransactionErrorDto) {
        return thunkAPI.rejectWithValue(response.data);
    }

    return response.data;
});

const notificationsAdapter = createEntityAdapter<NotificationDto>({
    selectId: (notification) => notification.notificationId,
    sortComparer: (a, b) => b.notificationId - a.notificationId,
});

export interface INotificationsState extends EntityState<NotificationDto> {
    isEstablishingConnection: boolean;
    isConnected: boolean;
    unreadCount: number;
    notificationAlert: boolean;
}

const initialState: INotificationsState = notificationsAdapter.getInitialState({
    isEstablishingConnection: false,
    isConnected: false,
    unreadCount: 0,
    notificationAlert: false,
});

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        connectToNotificationsHub: (state) => {
            state.isEstablishingConnection = true;
        },
        connectionEstablished: (state) => {
            state.isConnected = true;
            state.isEstablishingConnection = false;
        },
        connectionFailed: (state) => {
            state.isConnected = false;
            state.isEstablishingConnection = false;
        },
        processNotification: (state, action: PayloadAction<NotificationDto>) => {
            const { notificationId, isArchived } = action.payload;
            const isExisting = state.ids.includes(notificationId);

            if (!isExisting) {
                notificationsAdapter.addOne(state, action.payload);
                state.notificationAlert = true;
            } else if (isArchived) {
                notificationsAdapter.removeOne(state, notificationId);
            } else {
                notificationsAdapter.setOne(state, action.payload);
            }
        },
        setUnreadCount: (state, action: PayloadAction<number>) => {
            state.unreadCount = action.payload;
        },
        dismissNotificationAlert: (state) => {
            state.notificationAlert = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchNotifications.fulfilled, (state, { payload }) => {
            const { items } = payload;
            notificationsAdapter.setAll(state, items);
        });
    },
});

const { actions, reducer } = notificationsSlice;

export const { selectAll: selectAllNotifications } = notificationsAdapter.getSelectors(
    (state: AppStore) => state.notificationsState,
);
export const selectUnreadNotificationsCount = (state: AppStore) => state.notificationsState.unreadCount;
export const selectNotificationAlert = (state: AppStore) => state.notificationsState.notificationAlert;

export const {
    connectToNotificationsHub,
    connectionEstablished,
    connectionFailed,
    processNotification,
    setUnreadCount,
    dismissNotificationAlert,
} = actions;
export const setNotificationStatus = createAction<NotificationStatusDto>("notifications/setNotificationStatus");

export default reducer;
