import { IPaginationState, ISortBy } from "@maistro/components";
import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { searchSupplierSubmissions } from "api/supplierSubmission/supplierSubmissionsApi";
import useDashboardFilters, {
    emptyFilters,
    IDashboardFilters,
} from "features/dashboard/filter/hooks/useDashboardFilters";
import useAppDispatch from "hooks/useAppDispatch";
import { setBack, setPageTitle } from "store/layoutSlice";
import { SupplierSubmissionSearchDto } from "types/dtos/supplierSubmissions/SupplierSubmissionSearchDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const useSupplierDashboardContainer = (initialSort: ISortBy, initialFilters: IDashboardFilters) => {
    const [sortBy, setSortBy] = useState<ISortBy>(initialSort);
    const [paginationState, setPaginationState] = useState<IPaginationState>({
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0,
    });
    const [filteredSubmissions, setFilteredSubmissions] = useState<SupplierSubmissionSearchDto[]>([]);
    const [isSearching, setIsSearching] = useState(false);
    const [disableSorting, setDisableSorting] = useState(false);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { currentFilters, setCurrentFilters, options, getAvailableProjectManagers, getAvailableCompanies } =
        useDashboardFilters(initialFilters);

    useEffect(() => {
        dispatch(setBack(null));
        dispatch(setPageTitle(t("dashboard.projectResponses.title")));
        return () => {
            dispatch(setPageTitle(null));
        };
    }, [dispatch, t]);

    const shouldDisplayEmptyDashboard = filteredSubmissions.length === 0 && isEqual(currentFilters, emptyFilters);
    const shouldDisplayNoResultsDashboard = filteredSubmissions.length === 0 && !isEqual(currentFilters, emptyFilters);

    const setFilters = (filters: IDashboardFilters) => {
        if (filters.searchTerm && filters.searchTerm.length > 0) {
            setDisableSorting(true);
        } else {
            setDisableSorting(false);
        }
        setCurrentFilters(filters);
    };

    const searchMySubmissisons = useCallback(async () => {
        setIsSearching(true);
        const searchSubmissionsResponse = await searchSupplierSubmissions(
            paginationState.currentPage,
            paginationState.itemsPerPage,
            sortBy,
            currentFilters,
        );
        if (
            searchSubmissionsResponse.status === 200 &&
            !(searchSubmissionsResponse.data instanceof TransactionErrorDto)
        ) {
            setFilteredSubmissions(searchSubmissionsResponse.data.submissions);
            setPaginationState({
                currentPage: paginationState.currentPage,
                itemsPerPage: paginationState.itemsPerPage,
                totalItems: searchSubmissionsResponse.data.totalCount,
            });
        }
        setIsSearching(false);
    }, [paginationState.currentPage, paginationState.itemsPerPage, sortBy, currentFilters]);

    const fetchData = useCallback(async () => {
        await searchMySubmissisons();
    }, [searchMySubmissisons]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const navigateToPage = (page: number) => {
        setPaginationState({ ...paginationState, currentPage: page });
    };

    return {
        currentFilters,
        setFilters,
        options,
        getAvailableProjectManagers,
        getAvailableCompanies,
        filteredSubmissions,
        sortBy,
        setSortBy,
        paginationState,
        navigateToPage,
        isSearching,
        shouldDisplayEmptyDashboard,
        shouldDisplayNoResultsDashboard,
        disableSorting,
    };
};

export default useSupplierDashboardContainer;
