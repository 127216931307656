import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { t } from "i18next";

import { getProjectSections } from "api/questions/tenderCriteriaQuestionsApi";
import { sortByWeightingThenAlphabetically } from "features/project/shared/tender-questions/useQuestionSorters";
import { GetProjectSectionsResponseDto } from "types/dtos/questions/sections/GetProjectSectionsResponseDto";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";
import type { AppStore } from "store/store";
import { I18Options } from "store/types/I18NextTypes";

export interface ISectionsState {
    fetchingErrorMessage: string;
    isFetchingSections: boolean;
    sections: ISections;
}

interface ISections {
    items: Array<SectionDto>;
}

interface IFetchSectionsRequest {
    projectUuid: string;
}

interface IRejectSectionsValue {
    sections: ISections;
    fetchingErrorMessage: string;
}

interface ErrorShape {
    translation: string;
    options?: I18Options;
}

const defaultSections = {
    items: [],
} as ISections;

export const fetchSections = createAsyncThunk<
    ISections,
    IFetchSectionsRequest,
    {
        state: AppStore;
        rejectValue: IRejectSectionsValue;
    }
>("project/fetchSections", async (request, { rejectWithValue }) => {
    const errors: Array<ErrorShape> = [];
    let sections = defaultSections;

    const projectUuidToFetch = request.projectUuid;

    if (!projectUuidToFetch) {
        return rejectWithValue({
            fetchingErrorMessage: t("projectBrief.api.errorFetchingProject"),
            sections,
        });
    }

    const sectionsResponse = await getProjectSections(projectUuidToFetch);
    if (sectionsResponse.status !== 200) {
        errors.push({
            translation: "tenderCriteria.score.api.listSectionsError",
        });
    } else {
        const orderedSections = [...(sectionsResponse.data as GetProjectSectionsResponseDto).sections].sort(
            sortByWeightingThenAlphabetically,
        );
        sections = {
            ...sections,
            items: orderedSections,
        };
    }

    if (errors.length > 0) {
        let fetchingErrorMessage = "";
        errors.forEach((error, index) => {
            fetchingErrorMessage = fetchingErrorMessage.concat(
                error.options ? t(error.translation, error.options) : t(error.translation),
            );
            if (index < errors.length - 1) {
                fetchingErrorMessage = fetchingErrorMessage.concat(" | ");
            }
        });
        return rejectWithValue({
            fetchingErrorMessage,
            sections,
        });
    }
    return sections;
});

const initialState: ISectionsState = {
    fetchingErrorMessage: "",
    isFetchingSections: true,
    sections: defaultSections,
};

export const sectionsSlice = createSlice({
    name: "sections",
    initialState,
    reducers: {
        setFetching: (state, action: PayloadAction<boolean>) => {
            state.isFetchingSections = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSections.pending, (state) => {
            state.isFetchingSections = true;
            state.fetchingErrorMessage = "";
        });
        builder.addCase(fetchSections.rejected, (state, action) => {
            state.isFetchingSections = false;
            if (action?.payload?.fetchingErrorMessage) {
                state.fetchingErrorMessage = action?.payload?.fetchingErrorMessage;
            }
            if (action?.payload?.sections) {
                state.sections = action?.payload?.sections;
            }
        });
        builder.addCase(fetchSections.fulfilled, (state, action) => {
            state.isFetchingSections = false;
            state.sections = action.payload;
        });
    },
});

const { actions, reducer } = sectionsSlice;

export const { setFetching } = actions;

export default reducer;
