/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { uniq } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { listPoliciesForUser } from "api/company/policyApi";
import useCompany from "hooks/useCompany";
import useCurrentUser from "hooks/useCurrentUser";
import { PoliciesForUserResponseDto } from "types/dtos/company/policies/PoliciesForUserResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import PolicyType from "types/enums/companies/Approvals/PolicyType";
import FeatureToggle from "types/enums/companies/FeatureToggle";
import CurrencyType from "types/enums/CurrencyType";
import ProjectType from "types/enums/projects/ProjectType";

const usePolicies = (companyUuid?: string, userUuid?: string) => {
    const [userPolicies, setUserPolicies] = useState<PoliciesForUserResponseDto[]>([]);

    const { companyHasFeature, clientHasFeature } = useCompany();
    const { userIsMaistro } = useCurrentUser();

    const fetchPolicies = useCallback(
        async (buyerCompanyUuid?: string, buyerUserUuid?: string) => {
            const CompanyHasApprovalsFeature = async (companyUuidToUse: string) => {
                let hasApprovals = false;
                if (userIsMaistro) {
                    hasApprovals = (await clientHasFeature(companyUuidToUse, FeatureToggle.Approvals)) ?? false;
                } else {
                    hasApprovals = companyHasFeature(FeatureToggle.Approvals);
                }
                return hasApprovals;
            };

            if (!buyerCompanyUuid || !buyerUserUuid) {
                return;
            }

            const hasApprovals = await CompanyHasApprovalsFeature(buyerCompanyUuid);

            if (!hasApprovals) {
                setUserPolicies([]);
                return;
            }

            const response = await listPoliciesForUser(buyerCompanyUuid, buyerUserUuid);

            if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                return;
            }

            setUserPolicies(response.data.userPolicies);
        },
        [userIsMaistro, clientHasFeature, companyHasFeature],
    );

    useEffect(() => {
        if (!companyUuid || !userUuid) {
            return;
        }
        fetchPolicies(companyUuid, userUuid);
    }, [fetchPolicies, companyUuid, userUuid]);

    const policies = useMemo(() => userPolicies.flatMap((userPolicy) => userPolicy.policies), [userPolicies]);

    const tieringPolicies = useMemo(
        () => policies.filter((policy) => policy.type === PolicyType.Tiering && policy.supplierTierings),
        [policies],
    );

    const unapprovedTieringStatuses = useMemo(
        () => uniq(tieringPolicies.flatMap((policy) => policy.supplierTierings!)),
        [tieringPolicies],
    );

    const spendLimitPolicies = useMemo(
        () => policies.filter((policy) => policy.type === PolicyType.SpendLimit && policy.spendLimit),
        [policies],
    );

    const getProjectSpendLimitPolicies = useCallback(
        (projectType: ProjectType | undefined, projectCurrency: CurrencyType | undefined) => {
            if (spendLimitPolicies.length === 0 || !projectType || !projectCurrency) {
                return [];
            }

            return spendLimitPolicies
                .filter((policy) => {
                    return policy.spendLimit!.currency === projectCurrency && policy.projectTypes.includes(projectType);
                })
                .sort((a, b) => a.spendLimit!.limit - b.spendLimit!.limit);
        },
        [spendLimitPolicies],
    );

    const getBreachedSpendLimitPolicies = useCallback(
        (
            projectType: ProjectType | undefined,
            projectCurrency: CurrencyType | undefined,
            projectBudget: number | undefined,
        ) => {
            const matchingPolicies = getProjectSpendLimitPolicies(projectType, projectCurrency);
            if (matchingPolicies.length === 0 || !projectBudget) {
                return [];
            }

            return matchingPolicies.filter((policy) => policy.spendLimit!.limit < projectBudget);
        },
        [getProjectSpendLimitPolicies],
    );

    const getLowestBreachedSpendLimitPolicy = useCallback(
        (
            projectType: ProjectType | undefined,
            projectCurrency: CurrencyType | undefined,
            projectBudget: number | undefined,
        ) => {
            const matchingPolicies = getBreachedSpendLimitPolicies(projectType, projectCurrency, projectBudget);

            if (matchingPolicies.length > 0) {
                return matchingPolicies[0];
            }

            return null;
        },
        [getBreachedSpendLimitPolicies],
    );

    return {
        userPolicies,
        spendLimitPolicies,
        tieringPolicies,
        unapprovedTieringStatuses,
        getProjectSpendLimitPolicies,
        getBreachedSpendLimitPolicies,
        getLowestBreachedSpendLimitPolicy,
        fetchPolicies,
    };
};

export default usePolicies;
