import { Drawer, ISortBy, Popup, Table } from "@maistro/components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NotFoundScreen } from "components";
import SupplierExternalIdForm from "features/company/profile/suppliers/SupplierExternalIdForm";
import SupplierRelationshipStatusForm from "features/company/profile/suppliers/SupplierRelationshipStatusForm";
import SupplierTieringStatusForm from "features/company/profile/suppliers/SupplierTieringStatusForm";
import { ISupplierListFilters } from "features/company/profile/suppliers/filter/hooks/useSupplierListFilter";
import useCompanySupplierListConfiguration from "features/company/profile/suppliers/hooks/useCompanySupplierListConfiguration";
import ReactGA from "react-ga4";
import { BuyerSupplierRelationshipDto } from "types/dtos/company/buyer-supplier-relationships/BuyerSupplierRelationshipDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

interface ISupplierListDisplayProps {
    suppliers: BuyerSupplierRelationshipDto[];
    buyerCompanyName: string | undefined;
    updateSupplierRelationshipStatus: (supplier: BuyerSupplierRelationshipDto) => void;
    updateSupplierTieringStatus: (supplier: BuyerSupplierRelationshipDto) => void;
    updateSupplierExternalId: (supplier: BuyerSupplierRelationshipDto) => void;
    removeSupplierFromSupplierList: (supplier: BuyerSupplierRelationshipDto) => void;
    setFilterValues: (filterValues: ISupplierListFilters) => void;
    setSortBy?: (sort: ISortBy) => void;
    sortBy: ISortBy;
    setIsFilterDrawerOpen: (isOpen: boolean) => void;
    buyerCompanyUuid: string;
}

const CompanySupplierListDisplay: React.FC<ISupplierListDisplayProps> = (props) => {
    const {
        suppliers,
        setFilterValues,
        setIsFilterDrawerOpen,
        sortBy,
        setSortBy,
        buyerCompanyName,
        buyerCompanyUuid,
        updateSupplierExternalId,
        updateSupplierRelationshipStatus,
        updateSupplierTieringStatus,
        removeSupplierFromSupplierList,
    } = props;

    const [isRelationshipStatusDrawerOpen, setIsRelationshipStatusDrawerOpen] = useState(false);
    const [isTieringDrawerOpen, setIsTieringDrawerOpen] = useState(false);
    const [isExternalIdDrawerOpen, setIsExternalIdDrawerOpen] = useState(false);
    const [showRemoveSupplierPopup, setShowRemoveSupplierPopup] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState<BuyerSupplierRelationshipDto>();

    const { t } = useTranslation();

    const { columnConfiguration, onRowClick } = useCompanySupplierListConfiguration({
        setSelectedSupplier,
        setIsRelationshipStatusDrawerOpen,
        setIsTieringDrawerOpen,
        setIsExternalIdDrawerOpen,
        setShowRemoveSupplierPopup,
        buyerCompanyUuid,
    });

    const submitSupplierRelationshipStatus = (supplier: BuyerSupplierRelationshipDto) => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Company Suppliers - Relationship Status - Submit`,
        });
        setIsRelationshipStatusDrawerOpen(false);
        updateSupplierRelationshipStatus(supplier);
    };

    const submitSupplierExternalId = (supplier: BuyerSupplierRelationshipDto) => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Company Suppliers - External Id - Submit -`.concat(supplier.companyName),
        });
        setIsExternalIdDrawerOpen(false);
        updateSupplierExternalId(supplier);
    };

    const submitSupplierTieringStatus = (supplier: BuyerSupplierRelationshipDto) => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Company Suppliers - Tiering Status - Submit`,
        });
        setIsTieringDrawerOpen(false);
        updateSupplierTieringStatus(supplier);
    };

    const removeSupplierFromList = (supplier: BuyerSupplierRelationshipDto | undefined) => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Company Suppliers - Remove - Submit`,
        });
        if (supplier) {
            setShowRemoveSupplierPopup(false);
            setSelectedSupplier(undefined);
            removeSupplierFromSupplierList(supplier);
        }
    };

    const clearFilters = () => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Company Suppliers - Clear Filters`,
        });
        setFilterValues({
            activeStatuses: [],
            relationshipStatuses: [],
            tieringStatuses: [],
            searchTerm: "",
        });
    };

    return (
        <React.Fragment>
            {suppliers.length < 1 ? (
                <NotFoundScreen
                    testid="no-result-dashboard"
                    title={t("companySuppliers.noResults.title")}
                    message={t("companySuppliers.noResults.copy")}
                    primaryActionText={t("common.amendCriteria")}
                    primaryAction={() => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: `Company Suppliers - Amend Criteria`,
                        });
                        setIsFilterDrawerOpen(true);
                    }}
                    secondaryActionText={t("common.resetFilters")}
                    secondaryAction={clearFilters}
                />
            ) : (
                <Table
                    columns={columnConfiguration}
                    data={suppliers}
                    noDataDisplay={t("common.noResults")}
                    rowAction={(row: BuyerSupplierRelationshipDto) => {
                        ReactGA.event({
                            category: ga4Category.Element,
                            action: ga4Action.Element,
                            label: `Company Suppliers - Suppliers - `.concat(row.companyName || "No Name"),
                        });
                        onRowClick(row);
                    }}
                    sortBy={sortBy}
                    onSort={setSortBy}
                />
            )}
            <Drawer
                isOpen={isRelationshipStatusDrawerOpen}
                onClose={() => setIsRelationshipStatusDrawerOpen(false)}
                testid="change-status-drawer"
            >
                {selectedSupplier && (
                    <SupplierRelationshipStatusForm
                        values={selectedSupplier}
                        submit={submitSupplierRelationshipStatus}
                    />
                )}
            </Drawer>
            <Drawer
                isOpen={isTieringDrawerOpen}
                onClose={() => setIsTieringDrawerOpen(false)}
                testid="change-tiering-drawer"
            >
                {selectedSupplier && (
                    <SupplierTieringStatusForm values={selectedSupplier} submit={submitSupplierTieringStatus} />
                )}
            </Drawer>
            <Drawer
                isOpen={isExternalIdDrawerOpen}
                onClose={() => setIsExternalIdDrawerOpen(false)}
                testid="change-external-id-drawer"
            >
                {selectedSupplier && (
                    <SupplierExternalIdForm values={selectedSupplier} submit={submitSupplierExternalId} />
                )}
            </Drawer>
            <Popup
                testid="remove-supplier-popup"
                primaryActionText={t("companySuppliers.popups.removeSupplier.primaryActionText")}
                secondaryActionText={t("companySuppliers.popups.removeSupplier.secondaryActionText")}
                onPrimaryAction={() => removeSupplierFromList(selectedSupplier)}
                onSecondaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: `Company Suppliers - Remove - Cancel`,
                    });
                    setShowRemoveSupplierPopup(false);
                }}
                title={t("companySuppliers.popups.removeSupplier.title")}
                message={t("companySuppliers.popups.removeSupplier.message", {
                    SupplierName: selectedSupplier?.companyName,
                    BuyerName: buyerCompanyName,
                })}
                isOpen={showRemoveSupplierPopup}
                onClose={() => setShowRemoveSupplierPopup(false)}
            />
        </React.Fragment>
    );
};

export default CompanySupplierListDisplay;
