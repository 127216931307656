import { t } from "i18next";

import { SectionDto } from "types/dtos/questions/sections/SectionDto";
import SectionType from "types/enums/questions/sections/SectionType";

// eslint-disable-next-line import/prefer-default-export
export const getSectionTitle = (section: SectionDto): string => {
    if (section.sectionType === SectionType.Other) {
        return section.description || "Other";
    }

    return t(`tenderCriteria.sections.selection.types.${section.sectionType}.title`);
};
