import { Popup } from "@maistro/components";
import useOnline from "hooks/useOnline";
import React from "react";
import { useTranslation } from "react-i18next";

type ModalProps = {
    isOpen: boolean;
    message: string;
};

const Modal: React.FC<ModalProps> = ({ isOpen, message }) => {
    if (!isOpen) return null;
    return <Popup isOpen={isOpen} message={message} title="No internet" testid="network-error-popup" />;
};

const NetworkErrorPopup = () => {
    const isOnline = useOnline();
    const [modalOpen, setModalOpen] = React.useState(!isOnline);
    const { t } = useTranslation();

    React.useEffect(() => {
        setModalOpen(!isOnline);
    }, [isOnline]);

    return <Modal isOpen={modalOpen} message={t("noInternet.noInternet")} />;
};

export default NetworkErrorPopup;
