import { TextButton } from "@maistro/components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ICommonProps } from "components/shared";
import { IProjectResponseInformation } from "features/project/types";
import dateTimeService from "services/dateTimeService";
import SupplierSubmissionStatus from "types/enums/supplierSubmissions/SupplierSubmissionStatus";

interface INotInterestedWithdrawButtonsProps extends ICommonProps {
    supplierSubmission: IProjectResponseInformation;
    declareNotInterested: () => void;
    withdraw: () => void;
}

const NotInterestedWithdrawButtons: React.FC<INotInterestedWithdrawButtonsProps> = (props) => {
    const { supplierSubmission, declareNotInterested, withdraw } = props;

    const { t } = useTranslation();

    const currentStatus = supplierSubmission.status as SupplierSubmissionStatus;
    const deadlineHasPassed = dateTimeService.dateIsInPast(supplierSubmission.tenderResponseDeadline);

    const canDeclareNotInterested = useMemo(() => {
        const includedStatus = [SupplierSubmissionStatus.Invited, SupplierSubmissionStatus.Interested];

        return !deadlineHasPassed && currentStatus && includedStatus.includes(currentStatus);
    }, [currentStatus, deadlineHasPassed]);

    const canWithdraw = useMemo(() => {
        const includedStatus = [SupplierSubmissionStatus.Responding, SupplierSubmissionStatus.Submitted];

        return !deadlineHasPassed && currentStatus && includedStatus.includes(currentStatus);
    }, [currentStatus, deadlineHasPassed]);

    return (
        <React.Fragment>
            {canDeclareNotInterested && (
                <TextButton
                    label={t("projectResponse.notInterested")}
                    onClick={declareNotInterested}
                    testid="not-interested-button"
                />
            )}
            {canWithdraw && (
                <TextButton label={t("projectResponse.withdraw")} onClick={withdraw} testid="withdraw-button" />
            )}
        </React.Fragment>
    );
};

export default NotInterestedWithdrawButtons;
