import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { faSquareExclamation } from "@fortawesome/pro-solid-svg-icons";
import { Heading, Icon } from "@maistro/components";
import classNames from "classnames";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import MaistroFormikPassIcon from "components/FormikFields/MaistroFormikPassIcon";
import { ICommonProps } from "components/shared";
import colorService from "services/colorService";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    bold: {
        fontWeight: 600,
    },

    container: {
        padding: `${theme.spacing.medium}px ${theme.spacing.large}px`,
        background: theme.colors.white,
        boxShadow: `${colorService.toHexWithOpacity(theme.colors.primary, 0.1)} 0 3px 6px`,
        borderRadius: theme.spacing.xSmall,
        width: "100%",
    },
    content: {
        display: "grid",
        gridTemplateAreas: '"sectionTitle weighting completed" "totalQuestions icons icons"',
        gridTemplateColumns: "minmax(0, 2fr) minmax(0, 2fr) minmax(0, 0.2fr)",
        gap: `${theme.spacing.large}px ${theme.spacing.xSmall}px`,
        alignItems: "center",
        flexGrow: 1,
    },
    button: {
        ...theme.typography.headings.h4,
        background: "inherit",
        border: "none",
        width: "100%",
        cursor: "pointer",
        justifyContent: "end",
        verticalAlign: "bottom",
    },
    title: {
        minWidth: 190,
        gridArea: "sectionTitle",
        wordBreak: "break-word",
    },
    weighting: {
        marginLeft: theme.spacing.small,
        gridArea: "weighting",
        textAlign: "right",
    },
    totalQuestions: {
        fontSize: 14,
        whiteSpace: "nowrap",
        gridArea: "totalQuestions",
    },
    icons: {
        gridArea: "icons",
        display: "flex",
        justifyContent: "space-between",
        minWidth: 95,
    },
    chevron: {
        width: 30,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    completedIconContainer: {
        gridArea: "completed",
        display: "block",
    },
    completedIcon: {
        marginLeft: 0,
        fontSize: 25,
    },
    mandatory: {
        color: theme.colors.primary,
    },
    chevronPosition: {
        display: "flex",
        justifyContent: "flex-end",
    },
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        weighting: {
            textAlign: "left",
        },
        totalQuestions: {
            fontSize: theme.typography.headings.h3.fontSize,
        },
        content: {
            gridTemplateAreas: '"completed sectionTitle sectionTitle weighting totalQuestions icons"',
            gridTemplateColumns: ".20fr repeat(4, 1fr) 0.75fr",
            gap: theme.spacing.xSmall,
        },
        chevron: {
            width: 173,
        },
        button: {
            textAlign: "right",
        },
        completedIconContainer: {
            display: "block",
        },
        mandatory: {
            marginLeft: theme.spacing.xSmall,
        },
    },
    [`@media (min-width: ${theme.breakpoints.xl}px)`]: {
        content: {
            gridTemplateAreas:
                '"completed sectionTitle sectionTitle weighting centerSpace centerSpace totalQuestions icons"',
            gridTemplateColumns: ".15fr repeat(6, 1fr) 0.75fr",
        },
    },
}));

interface ISectionRowProps extends ICommonProps {
    title: string;
    weighting: number;
    totalQuestions: number;
    isLoading?: boolean;
    hasMandatoryQuestions: boolean;
    children?: ReactElement | Array<ReactElement>;
    expanded?: boolean;
    onToggleExpand?: (expanded: boolean) => void;
    isComplete?: boolean;
}

const SectionRow: React.FC<ISectionRowProps> = (props) => {
    const classes = useStyles();

    const [isExpanded, setIsExpanded] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (!props.isLoading && props.totalQuestions === 0) {
            setIsExpanded(false);
        }
    }, [props.totalQuestions, props.isLoading]);

    useEffect(() => {
        if (props.expanded && !props.isLoading && props.totalQuestions > 0) {
            setIsExpanded(props.expanded);
        }
    }, [props.expanded, props.totalQuestions, props.isLoading]);

    return (
        <div className={props.className} data-testid={props.testid} data-component="SectionRow">
            <div
                className={classNames(classes.container, { expanded: isExpanded })}
                data-testid={`${props.testid}-expansion`}
            >
                <div className={classes.content}>
                    <div className={classNames(classes.completedIconContainer)}>
                        <MaistroFormikPassIcon
                            className={classes.completedIcon}
                            isVisible={!!props.isComplete}
                            testid={`${props.testid}-complete`}
                        />
                    </div>
                    <Heading className={classNames(classes.bold, classes.title)} variant="h3">
                        {props.title}
                    </Heading>
                    <Heading
                        className={classNames(classes.bold, classes.weighting)}
                        variant="h3"
                    >{`${props.weighting}%`}</Heading>
                    <Heading className={classNames(classes.bold, classes.totalQuestions)} variant="h3">
                        {props.totalQuestions === 1
                            ? t("tenderQuestions.questionCount", {
                                  totalQuestions: props.totalQuestions,
                              })
                            : t("tenderQuestions.questionsCount", {
                                  totalQuestions: props.totalQuestions,
                              })}
                    </Heading>
                    <div className={classNames(classes.icons)}>
                        {props.hasMandatoryQuestions && (
                            <Icon
                                className={classes.mandatory}
                                icon={faSquareExclamation}
                                alt={t("tenderQuestions.sectionConditionalQuestion")}
                            />
                        )}
                        {props.totalQuestions > 0 && (
                            <button
                                className={classes.button}
                                onClick={() => {
                                    setIsExpanded((currentState) => {
                                        if (props.onToggleExpand) {
                                            props.onToggleExpand(!currentState);
                                        }
                                        return !currentState;
                                    });
                                }}
                                type="button"
                                aria-label="expand-button"
                                data-testid={`${props.testid}-expand-button`}
                            >
                                <Icon
                                    className={classes.chevronPosition}
                                    icon={isExpanded ? faChevronUp : faChevronDown}
                                />
                            </button>
                        )}
                    </div>
                </div>
            </div>
            {isExpanded && <div>{props.children}</div>}
            {!isExpanded && <div>{props.children}</div>}
        </div>
    );
};

export default SectionRow;
