import { ISortBy, Pagination, SortOrder } from "@maistro/components";
import React, { useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";

import { Loader } from "components";
import DashboardFilters from "features/dashboard/filter/DashboardFilters";
import { emptyFilters, IDashboardFilters } from "features/dashboard/filter/hooks/useDashboardFilters";
import useSupplierDashboardContainer from "features/dashboard/supplierSubmissions/hooks/useSupplierDashboardContainer";
import DashboardSupplierSubmissionsDisplay from "features/dashboard/supplierSubmissions/SupplierSubmissionsDashboardDisplay";
import useAppDispatch from "hooks/useAppDispatch";
import { clearProjectResponseInformation } from "store/projectResponseSlice";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    pagination: {
        marginTop: theme.spacing.small,
    },
}));

const SupplierSubmissionsDashboardContainer: React.FC = () => {
    const classes = useStyles();

    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(clearProjectResponseInformation());
    }, [dispatch]);

    const initialFilters: IDashboardFilters = useMemo(() => emptyFilters, []);
    const initialSort: ISortBy = useMemo(
        () => ({
            column: "tenderResponseDeadline",
            order: SortOrder.Descending,
        }),
        [],
    );

    const {
        currentFilters,
        setFilters,
        options,
        getAvailableProjectManagers,
        getAvailableCompanies,
        filteredSubmissions,
        sortBy,
        setSortBy,
        paginationState,
        navigateToPage,
        isSearching,
        shouldDisplayEmptyDashboard,
        shouldDisplayNoResultsDashboard,
        disableSorting,
    } = useSupplierDashboardContainer(initialSort, initialFilters);

    if (isSearching) {
        return <Loader />;
    }

    return (
        <React.Fragment>
            <DashboardFilters
                currentFilters={currentFilters}
                setCurrentFilters={setFilters}
                options={options}
                getAvailableProjectManagers={getAvailableProjectManagers}
                getAvailableCompanies={getAvailableCompanies}
                isSearching={isSearching}
                resetPage={() => navigateToPage(1)}
                resetFilters={() => setFilters(initialFilters)}
                optionalFilters={{ tenderResponseDeadline: true, supplierSubmissionStatuses: true }}
                isFilterDrawerOpen={isFilterDrawerOpen}
                setIsFilterDrawerOpen={setIsFilterDrawerOpen}
            />
            <DashboardSupplierSubmissionsDisplay
                filteredSubmissions={filteredSubmissions}
                setSortBy={
                    disableSorting
                        ? undefined
                        : (sort: ISortBy) => {
                              setSortBy(sort);
                              navigateToPage(1);
                          }
                }
                sortBy={disableSorting ? { column: "unset" } : sortBy}
                refreshData={() => setFilters({ ...initialFilters })}
                shouldDisplayEmptyDashboard={shouldDisplayEmptyDashboard}
                shouldDisplayNoResultsDashboard={shouldDisplayNoResultsDashboard}
                setIsFilterDrawerOpen={() => setIsFilterDrawerOpen(true)}
            />
            <Pagination
                className={classes.pagination}
                paginationState={paginationState}
                navigateToPage={navigateToPage}
                testid="supplier-submissions-dashboard-pagination"
                disabled={isSearching}
            />
        </React.Fragment>
    );
};

export default SupplierSubmissionsDashboardContainer;
