import { Heading } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";

import useSectionStyles from "features/company/profile/vetting/sections/useSectionStyles";
import FileDto from "types/dtos/files/FileDto";
import { AccreditationDto } from "types/dtos/company/vettingInformation/AccreditationDto";
import EqualitySubSection from "features/company/profile/vetting/sections/ethicsSubSections/EqualitySubSection";
import StandardsSubSection from "features/company/profile/vetting/sections/ethicsSubSections/StandardsSubSection";
import QualitySubSection from "features/company/profile/vetting/sections/ethicsSubSections/QualitySubSection";

interface IEthicsSectionProps {
    diversityAndInclusionPolicyFiles: FileDto[];
    setDiversityAndInclusionPolicyFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;

    briberyPolicyFiles: FileDto[];
    setBriberyPolicyFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    socialResponsibilityPolicyFiles: FileDto[];
    setSocialResponsibilityPolicyFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    modernSlaveryPolicyFiles: FileDto[];
    setModernSlaveryPolicyFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    antiMoneyLaunderingPolicyFiles: FileDto[];
    setAntiMoneyLaunderingPolicyFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;

    companyAccreditations: AccreditationDto[];
    setCompanyAccreditations: React.Dispatch<React.SetStateAction<AccreditationDto[]>>;
}

const EthicsSection: React.FC<IEthicsSectionProps> = ({
    diversityAndInclusionPolicyFiles,
    setDiversityAndInclusionPolicyFiles,

    briberyPolicyFiles,
    setBriberyPolicyFiles,
    socialResponsibilityPolicyFiles,
    setSocialResponsibilityPolicyFiles,
    modernSlaveryPolicyFiles,
    setModernSlaveryPolicyFiles,
    antiMoneyLaunderingPolicyFiles,
    setAntiMoneyLaunderingPolicyFiles,

    companyAccreditations,
    setCompanyAccreditations,
}) => {
    const classes = useSectionStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.section}>
            <Heading className={classes.heading} variant="h3">
                {t("companyVettingQuestionnaire.sections.ethics.heading")}
            </Heading>
            <hr className={classes.hr} />
            <EqualitySubSection
                diversityAndInclusionPolicyFiles={diversityAndInclusionPolicyFiles}
                setDiversityAndInclusionPolicyFiles={setDiversityAndInclusionPolicyFiles}
            />
            <StandardsSubSection
                briberyPolicyFiles={briberyPolicyFiles}
                setBriberyPolicyFiles={setBriberyPolicyFiles}
                socialResponsibilityPolicyFiles={socialResponsibilityPolicyFiles}
                setSocialResponsibilityPolicyFiles={setSocialResponsibilityPolicyFiles}
                modernSlaveryPolicyFiles={modernSlaveryPolicyFiles}
                setModernSlaveryPolicyFiles={setModernSlaveryPolicyFiles}
                antiMoneyLaunderingPolicyFiles={antiMoneyLaunderingPolicyFiles}
                setAntiMoneyLaunderingPolicyFiles={setAntiMoneyLaunderingPolicyFiles}
            />
            <QualitySubSection
                companyAccreditations={companyAccreditations}
                setCompanyAccreditations={setCompanyAccreditations}
            />
        </div>
    );
};

export default EthicsSection;
