import { Drawer, Heading, Popup } from "@maistro/components";
import { Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { createUseStyles } from "react-jss";
import { ITheme } from "styles/themes/types";
import { AdminUserDto } from "types/dtos/admin/AdminUserDto";
import EditAdminUserForm from "features/settings/admin-users/EditAdminUserForm";

const useStyles = createUseStyles((theme: ITheme) => ({
    drawerHeading: {
        marginBottom: theme.spacing.large,
    },
}));

interface IEditAdminUserDrawer {
    isOpen: boolean;
    isCreateMode: boolean;
    onClose: () => void;
    onSubmit: (user: AdminUserDto) => void;
    selectedUser: AdminUserDto | null;
    isSaving: boolean;
}

const EditAdminUserDrawer: React.FC<IEditAdminUserDrawer> = ({
    onSubmit,
    isOpen,
    onClose,
    selectedUser,
    isCreateMode,
    isSaving,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [warnUnsavedChanges, setWarnUnsavedChanges] = useState(false);
    const [showUnsavedChangesPopup, setShowUnsavedChangesPopup] = useState(false);

    const onCloseDrawer = (): void => {
        if (warnUnsavedChanges) {
            setShowUnsavedChangesPopup(true);
        } else {
            onClose();
        }
    };

    const validationSchema = () =>
        Yup.object({
            firstName: Yup.string()
                .required(t("adminUsers.form.firstName.required"))
                .max(100, t("adminUsers.form.firstName.max")),
            lastName: Yup.string()
                .required(t("adminUsers.form.lastName.required"))
                .max(100, t("adminUsers.form.lastName.max")),
            emailAddress: Yup.string()
                .required(t("adminUsers.form.emailAddress.required"))
                .email(t("adminUsers.form.emailAddress.invalid"))
                .max(256, t("adminUsers.form.emailAddress.max")),
        });

    const UnsavedChangesWarning = (props: { dirty: boolean }) => {
        useEffect(() => {
            setWarnUnsavedChanges(props.dirty && !isSaving);
        }, [props]);
        return null;
    };

    return (
        <>
            <Drawer isOpen={isOpen} onClose={onCloseDrawer} testid="edit-user-drawer">
                <Heading variant="h2" className={classes.drawerHeading}>
                    {selectedUser === null ? t("companyProfileUsers.addUser") : t("companyProfileUsers.editUser")}
                </Heading>
                <Formik
                    initialValues={{
                        userUuid: selectedUser?.userUuid ?? "",
                        firstName: selectedUser?.firstName ?? "",
                        lastName: selectedUser?.lastName ?? "",
                        emailAddress: selectedUser?.emailAddress ?? "",
                        status: undefined,
                        lastLoggedIn: selectedUser?.lastLoggedIn,
                        isSuspended: selectedUser?.isSuspended ?? false,
                        fullName: "",
                    }}
                    onSubmit={(values) => {
                        onSubmit(values);
                    }}
                    onReset={onCloseDrawer}
                    validationSchema={validationSchema}
                >
                    {({ dirty }) => {
                        return (
                            <>
                                <EditAdminUserForm isCreateMode={isCreateMode} isSaving={isSaving} />
                                <UnsavedChangesWarning dirty={dirty} />
                            </>
                        );
                    }}
                </Formik>
            </Drawer>
            <Popup
                isOpen={showUnsavedChangesPopup}
                onClose={() => setShowUnsavedChangesPopup(false)}
                testid="unsaved-changes-warning-popup"
                title={t("adminUsers.popups.unsavedChanges.title")}
                message={t("adminUsers.popups.unsavedChanges.message")}
                primaryActionText={t("adminUsers.popups.unsavedChanges.primaryActionText")}
                secondaryActionText={t("adminUsers.popups.unsavedChanges.secondaryActionText")}
                onPrimaryAction={() => {
                    setShowUnsavedChangesPopup(false);
                    onClose();
                }}
                onSecondaryAction={() => setShowUnsavedChangesPopup(false)}
            ></Popup>
        </>
    );
};

export default EditAdminUserDrawer;
