enum CompanyFileType {
    Accreditation = "Accreditation",
    AntiBriberyPolicy = "AntiBriberyPolicy",
    AntiMoneyLaunderingPolicy = "AntiMoneyLaunderingPolicy",
    CorporateSocialResponsibilityPolicy = "CorporateSocialResponsibilityPolicy",
    CyberSecurityPlan = "CyberSecurityPlan",
    DataProtectionBreaches = "DataProtectionBreaches",
    DataProtectionPolicyAndPrivacyNotice = "DataProtectionPolicyAndPrivacyNotice",
    DisasterRecoveryPlan = "DisasterRecoveryPlan",
    DiversityAndInclusionPolicy = "DiversityAndInclusionPolicy",
    DrugAndAlcoholPolicy = "DrugAndAlcoholPolicy",
    EnvironmentPolicy = "EnvironmentPolicy",
    ExternalPrivacyAudit = "ExternalPrivacyAudit",
    HealthAndSafetyPolicy = "HealthAndSafetyPolicy",
    ModernSlaveryPolicy = "ModernSlaveryPolicy",
    ProfessionalBodyMembership = "ProfessionalBodyMembership",
}

export default CompanyFileType;
