import { createUseStyles } from "react-jss";
import { ITheme } from "styles/themes/types";

const useTenderStyles = createUseStyles((theme: ITheme) => ({
    copy: {
        marginBottom: theme.spacing.medium,
    },
    supplierName: {
        flexGrow: 1,
    },
    supplierDropdown: {
        display: "flex",
        alignItems: "center",
        background: theme.colors.medLightGray,
        borderRadius: theme.spacing.xSmall,
        padding: theme.spacing.medium,
        marginBottom: theme.spacing.small,
    },
    subBanner: {
        display: "flex",
        gap: theme.spacing.medium,
        alignItems: "center",
        justifyContent: "space-between",
    },
    actions: {
        display: "flex",
        gap: theme.spacing.medium,
        margin: `${theme.spacing.small}px 0`,
        alignItems: "flex-end",
        width: "100%",
        position: "relative",
    },
    actionsCopy: {
        display: "flex",
        alignSelf: "flex-end",
        paddingBottom: "10px",
    },
    select: {
        display: "flex",
        gap: theme.spacing.medium,
        flex: 1,
    },
    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        subBanner: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "baseline",
            marginBottom: theme.spacing.small,
        },
        actions: {
            flexDirection: "row",
            alignItems: "center",
        },
    },
}));

export default useTenderStyles;
