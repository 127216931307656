import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Drawer, Popup, useToaster } from "@maistro/components";
import { IButtonProps } from "@maistro/components/dist/esm/types/components/Button/Button";
import { AxiosResponse } from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Loader } from "components";
import { IOptionProps } from "components/shared";
import CompanyProfileActionPopup from "features/company/profile/CompanyProfileActionPopup";
import CompanyAddressesDisplay from "features/company/profile/addresses/CompanyAddressesDisplay";
import EditCompanyAddressForm from "features/company/profile/addresses/EditCompanyAddressForm";
import useCompanyStepperNavigation from "features/company/profile/hooks/useCompanyStepperNavigation";
import useAppDispatch from "hooks/useAppDispatch";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import { buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import validationService from "services/validationService";
import {
    useCreatePostalAddressMutation,
    useDeletePostalAddressMutation,
    useLazyListPostalAddressesQuery,
    useUpdatePostalAddressMutation,
} from "store/api/companyAddressesApi";
import { useGetCompanyDetailsQuery } from "store/api/companyApi";
import { useLazyGetCountriesQuery } from "store/api/referenceDataApi";
import { resetLayout, setBack, setCtas, setMobileFooterCtas, setPageTitle } from "store/layoutSlice";
import { PostalAddressDto } from "types/dtos/company/postal-addresses/PostalAddressDto";
import {
    CreatePostalAddressRequestDto,
    UpdatePostalAddressRequestDto,
} from "types/dtos/company/postal-addresses/PostalAddressRequestDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const CompanyAddressesContainer: React.FC = () => {
    const [addresses, setAddresses] = useState<Array<PostalAddressDto>>([]);
    const [countryOptions, setCountryOptions] = useState<IOptionProps[]>([]);
    const [selectedAddress, setSelectedAddress] = useState<PostalAddressDto>();
    const [isAddressDrawerOpen, setIsAddressDrawerOpen] = useState<boolean>(false);
    const [isRemoveAddressPopupOpen, setIsRemoveAddressPopupOpen] = useState<boolean>(false);
    const [isUnsavedChangesPopupOpen, setIsUnsavedChangesPopupOpen] = useState<boolean>(false);
    const { myUuid: userUuid } = useCurrentUser();

    const [getCountries, { isLoading: isLoadingCountries }] = useLazyGetCountriesQuery();
    const [listPostalAddresses, { isLoading: isLoadingAddresses }] = useLazyListPostalAddressesQuery();
    const [updatePostalAddress] = useUpdatePostalAddressMutation();
    const [createPostalAddress] = useCreatePostalAddressMutation();
    const [deletePostalAddress] = useDeletePostalAddressMutation();

    const [isDirty, setIsDirty] = useState<boolean>(false);

    const isLoading = useMemo(() => {
        return isLoadingCountries || isLoadingAddresses;
    }, [isLoadingCountries, isLoadingAddresses]);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const toast = useToaster();
    const { companyUuid } = useParams();

    const { data: companyDetails } = useGetCompanyDetailsQuery(companyUuid ?? "");

    const { isCompanyProfileActionPopupOpen, setIsCompanyProfileActionPopupOpen } = useCompanyStepperNavigation({
        activeStep: "addresses",
        title: companyDetails?.registeredName,
        subTitle: companyDetails?.netPromoterScore
            ? t("companyProfile.stepperNavigation.netPromoterScore", {
                  netPromoterScore: companyDetails?.netPromoterScore,
              })
            : "",
        companyUuid: companyUuid ?? "",
        isActive: companyDetails?.isActive,
    });

    useEffect(() => {
        dispatch(
            setBack({
                route: buildRoute(routes.company.companyProfile, { companyUuid }),
            }),
        );
        dispatch(setPageTitle(t("addresses.title")));
        const addAddress: IButtonProps = {
            label: t("addresses.addAddress"),
            testid: "add-address-button",
            type: "submit",
            form: "address-form",
            onClick: () => {
                ReactGA.event({
                    category: ga4Category.Button,
                    action: ga4Action.ButtonClick,
                    label: `Company Addresses - Add Address`,
                });
                setIsAddressDrawerOpen(true);
            },
            icon: faPlus,
        };
        dispatch(setCtas([addAddress]));
        dispatch(setMobileFooterCtas([addAddress]));

        return () => {
            dispatch(resetLayout());
        };
    }, [companyUuid, dispatch, t]);

    const fetchReferenceData = useCallback(() => {
        getCountries(undefined, true)
            .unwrap()
            .then((countriesResult) => {
                setCountryOptions(
                    countriesResult.countryTypes.map((country) => ({
                        label: country.name,
                        value: country.id.toString(),
                    })),
                );
            })
            .catch(() => {
                toast.error(t("companyProfile.api.listReferenceDataError"));
            });
    }, [getCountries, t, toast]);

    const fetchPostalAddresses = useCallback(async () => {
        if (!companyUuid) {
            return;
        }
        listPostalAddresses(companyUuid)
            .unwrap()
            .then((response) => {
                setAddresses(response.postalAddresses);
            })
            .catch(() => {
                toast.error(t("addresses.api.listPostalAddressesError"));
                setAddresses([]);
            });
    }, [t, toast, companyUuid, listPostalAddresses]);

    useEffect(() => {
        const fetchData = async () => {
            if (!validationService.isValidUuid(companyUuid)) {
                return;
            }
            await Promise.all([fetchReferenceData(), fetchPostalAddresses()]);
        };

        fetchData();
    }, [companyUuid, fetchPostalAddresses, fetchReferenceData]);

    const createOrUpdatePostalAddress = useCallback(
        async (values: PostalAddressDto) => {
            if (!selectedAddress || !values.uuid || values.uuid === "new") {
                const createPostalAddressRequestObj: CreatePostalAddressRequestDto = {
                    ...values,
                    userUuid,
                    companyUuid: companyUuid ?? "",
                };
                return createPostalAddress(createPostalAddressRequestObj).unwrap();
            }

            const updatePostalAddressRequestObj: UpdatePostalAddressRequestDto = {
                ...values,
                addressUuid: values.uuid,
                userUuid,
                companyUuid: companyUuid ?? "",
            };
            return updatePostalAddress(updatePostalAddressRequestObj).unwrap();
        },
        [companyUuid, createPostalAddress, selectedAddress, updatePostalAddress, userUuid],
    );

    const savePostalAddress = useCallback(
        async (values: PostalAddressDto) => {
            ReactGA.event({
                category: ga4Category.Button,
                action: ga4Action.ButtonClick,
                label: `Company Address - Save Address`,
            });
            return createOrUpdatePostalAddress(values)
                .then(() => {
                    toast.success(t("addresses.api.savePostalAddressSuccess"));
                    fetchPostalAddresses();
                    setSelectedAddress(undefined);
                    setIsAddressDrawerOpen(false);
                })
                .catch((response: AxiosResponse) => {
                    if (response.status === 409) {
                        toast.warning(t("addresses.api.savePostalAddressDuplicate"));
                    } else {
                        toast.error(t("addresses.api.savePostalAddressError"));
                    }
                });
        },
        [createOrUpdatePostalAddress, fetchPostalAddresses, t, toast],
    );

    const onDeletePopupClose = () => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Company Address - Delete Address - Cancel`,
        });
        setIsRemoveAddressPopupOpen(false);
        setSelectedAddress(undefined);
    };

    const deleteAddress = () => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Company Address - Delete Address - Submit`,
        });
        if (
            !selectedAddress ||
            !companyUuid ||
            !(
                validationService.isValidUuid(companyUuid) ||
                validationService.isValidUuid(selectedAddress.uuid) ||
                validationService.isValidUuid(userUuid)
            )
        ) {
            return;
        }

        deletePostalAddress({ companyUuid, addressUuid: selectedAddress.uuid, userUuid })
            .unwrap()
            .then(() => {
                toast.success(t("addresses.api.deletePostalAddressSuccess"));
                onDeletePopupClose();
                fetchPostalAddresses();
            })
            .catch(() => {
                toast.error(t("addresses.api.deletePostalAddressError"));
                onDeletePopupClose();
            });
    };

    const onAddressDrawerClose = () => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Company Address - Add Address - Cancel`,
        });
        if (isDirty) {
            setIsUnsavedChangesPopupOpen(true);
        } else {
            setIsAddressDrawerOpen(false);
            setSelectedAddress(undefined);
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <React.Fragment>
            <CompanyAddressesDisplay
                addresses={addresses}
                countries={countryOptions}
                setSelectedAddress={setSelectedAddress}
                setIsAddressDrawerOpen={setIsAddressDrawerOpen}
                setIsRemoveAddressPopupOpen={setIsRemoveAddressPopupOpen}
            />

            <Drawer isOpen={isAddressDrawerOpen} onClose={onAddressDrawerClose} testid="address-drawer">
                <EditCompanyAddressForm
                    addresses={addresses}
                    selectedAddress={selectedAddress}
                    countries={countryOptions}
                    onSubmit={savePostalAddress}
                    onCancel={onAddressDrawerClose}
                    setIsDirty={setIsDirty}
                />
            </Drawer>

            <Popup
                title={t("popups.deleteAddress.title")}
                message={t("popups.deleteAddress.message")}
                isOpen={isRemoveAddressPopupOpen}
                primaryActionText={t("popups.deleteAddress.cta.primary")}
                onPrimaryAction={deleteAddress}
                secondaryActionText={t("popups.deleteAddress.cta.secondary")}
                onSecondaryAction={onDeletePopupClose}
                onClose={onDeletePopupClose}
                testid="confirm-remove-address-popup"
            />
            <Popup
                title={t("popups.unsavedChanges.title")}
                message={t("popups.unsavedChanges.message")}
                isOpen={isUnsavedChangesPopupOpen}
                primaryActionText={t("popups.unsavedChanges.altCta.primary")}
                onPrimaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: `Company Address - Unsaved Changes - Leave`,
                    });
                    setIsUnsavedChangesPopupOpen(false);
                    setIsAddressDrawerOpen(false);
                    setSelectedAddress(undefined);
                }}
                secondaryActionText={t("popups.unsavedChanges.altCta.secondary")}
                onSecondaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: `Company Address - Unsaved Changes - Stay`,
                    });
                    setIsUnsavedChangesPopupOpen(false);
                }}
                onClose={() => setIsUnsavedChangesPopupOpen(false)}
                testid="unsaved-changes-address-popup"
            />
            <CompanyProfileActionPopup
                isCompanyProfileActionPopupOpen={isCompanyProfileActionPopupOpen}
                setIsCompanyProfileActionPopupOpen={setIsCompanyProfileActionPopupOpen}
                isActive={companyDetails?.isActive}
                isBuyer={companyDetails?.allowBuyerUsers}
                companyUuid={companyDetails?.uuid}
                registeredName={companyDetails?.registeredName}
            />
        </React.Fragment>
    );
};

export default CompanyAddressesContainer;
