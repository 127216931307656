enum SectionType {
    Other = "Other",
    Commercial = "Commercial",
    ServiceDelivery = "ServiceDelivery",
    Quality = "Quality",
    TechnicalSpecification = "TechnicalSpecification",
    Capabilities = "Capabilities",
    Innovation = "Innovation",
    Implementation = "Implementation",
    CompanyInformation = "CompanyInformation",
}

export default SectionType;
