/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Heading } from "@maistro/components";
import classNames from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";

import loadingImage from "assets/images/loading.gif";
import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    small: {
        height: 16,
    },

    medium: {
        height: 22,
    },

    large: {
        height: 35,
    },

    loading: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: theme.spacing.unit,
    },

    loadingContainer: {
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        backgroundColor: theme.colors.lightGray,
    },

    padding: {
        paddingTop: theme.spacing.xLarge,
    },
}));

interface ILoadingProps extends ICommonProps {
    size?: "small" | "medium" | "large";
    text?: string;
}

const Loading: React.FC<ILoadingProps> = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.loadingContainer} data-testid="loading-container">
            <div className={classes.padding}>
                <div
                    className={classNames(classes.loading, props.className)}
                    data-component="Loading"
                    data-testid={props.testid}
                >
                    <img
                        className={classNames({ [classes[props.size!]]: props.size })}
                        src={loadingImage}
                        alt="Loading..."
                    />
                    {props.text && <Heading variant="h3">{props.text}</Heading>}
                </div>
            </div>
        </div>
    );
};

Loading.defaultProps = {
    size: "medium",
};

export default Loading;
