import { Button, Copy, TextButton } from "@maistro/components";
import React from "react";
import { createUseStyles } from "react-jss";

import noResultsImage from "assets/images/noResults.svg";
import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    wrapper: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    },

    content: {
        gap: theme.spacing.small,
        width: "100%",
        padding: theme.spacing.small,
        maxWidth: 450,
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing.unit,
    },

    copy: {
        fontWeight: "bold",
    },

    subCopy: {
        marginBottom: theme.spacing.medium,
    },

    emptyDashboardImage: {
        width: "50%",
        height: "50%",
        marginBottom: theme.spacing.medium,
    },
}));

interface INoResultsDashboardProps extends ICommonProps {
    title: string;
    message?: string;
    primaryActionText?: string;
    primaryAction?: () => void;
    secondaryActionText?: string;
    secondaryAction?: () => void;
}

const NoResultsDashboard: React.FC<INoResultsDashboardProps> = ({
    title,
    message,
    primaryAction,
    primaryActionText,
    secondaryActionText,
    secondaryAction,
    testid,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <div className={classes.content}>
                <div>
                    <img src={noResultsImage} className={classes.emptyDashboardImage} alt={title} />
                </div>
                <Copy className={classes.copy} testid={`${testid}-title`}>
                    {title}
                </Copy>
                {message && (
                    <Copy className={classes.subCopy} testid={`${testid}-message`}>
                        {message}
                    </Copy>
                )}
                {primaryActionText && primaryAction && (
                    <div>
                        <Button
                            chevron
                            testid={`${testid}-primary`}
                            label={primaryActionText}
                            onClick={primaryAction}
                        />
                    </div>
                )}
                {secondaryActionText && secondaryAction && (
                    <div>
                        <TextButton
                            testid={`${testid}-secondary`}
                            label={secondaryActionText}
                            onClick={secondaryAction}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default NoResultsDashboard;
