import { AxiosResponse } from "axios";

import { createAxiosInstance } from "api/commonApi";
import env from "config/EnvironmentVariables";
import { ListCountryTypesResponseDto } from "types/dtos/company/referenceData/ListCountryTypesResponseDto";
import { ListReferenceDataResponseDto } from "types/dtos/company/referenceData/ListReferenceDataResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/companies/reference-data";

export const listCompanySizeTypes = (): Promise<AxiosResponse<ListReferenceDataResponseDto | TransactionErrorDto>> => {
    return api.get(baseApiUrl, { params: { referenceDataType: "SizeType" } });
};

export const listIndustryTypes = (): Promise<AxiosResponse<ListReferenceDataResponseDto | TransactionErrorDto>> => {
    return api.get(baseApiUrl, { params: { referenceDataType: "IndustryType" } });
};

export const listCountryTypes = (): Promise<AxiosResponse<ListCountryTypesResponseDto | TransactionErrorDto>> => {
    const url = `${baseApiUrl}/country-types`;
    return api.get(url);
};
