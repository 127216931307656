import { useCallback } from "react";

import FeatureToggle from "types/enums/companies/FeatureToggle";
import { getEnabledCompanyFeatures } from "api/company/featureTogglesApi";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import useCurrentUser from "hooks/useCurrentUser";
import { useGetCompanyFeaturesQuery } from "store/api/companyApi";

const useCompany = () => {
    const { myCompanyUuid, userIsMaistro } = useCurrentUser();
    const { data: companyFeatures, isLoading } = useGetCompanyFeaturesQuery(myCompanyUuid, { skip: !myCompanyUuid });

    const companyHasFeature = useCallback(
        (feature: FeatureToggle, hasGlobalAdminOverride?: boolean) => {
            if (hasGlobalAdminOverride && userIsMaistro) {
                return true;
            }
            if (isLoading) {
                return false;
            }
            return companyFeatures?.featureToggles?.some((companyFeature) => companyFeature.name === feature) ?? false;
        },
        [companyFeatures?.featureToggles, isLoading, userIsMaistro],
    );

    const clientHasFeature = useCallback(async (companyUuid: string, feature: FeatureToggle) => {
        if (companyUuid.length === 0) {
            return null;
        }

        const response = await getEnabledCompanyFeatures(companyUuid);
        if (response.status !== 200 || response.data instanceof TransactionErrorDto) {
            return null;
        }
        return response.data.featureToggles?.some((companyFeature) => companyFeature.name === feature);
    }, []);

    return { companyHasFeature, clientHasFeature };
};

export default useCompany;
