import { createUseStyles } from "react-jss";

import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    actions: {
        marginTop: theme.spacing.large,
        marginBottom: theme.spacing.xLarge,
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.small,
    },
    copy: {
        marginBottom: theme.spacing.xLarge,
    },
    refresh: {
        display: "block",
        direction: "rtl",
        marginTop: theme.spacing.xSmall,
        marginBottom: theme.spacing.medium,
    },
    buttons: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.small,
    },
    deadlineAfterContractStart: {
        color: theme.colors.error,
        fontWeight: 500,
        lineHeight: "24px",
        marginBottom: theme.spacing.large,
    },
    actionButtons: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: theme.spacing.small,
    },

    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        actions: {
            flexDirection: "row",
            justifyContent: "space-between",
        },
    },
}));

const useMonitorStyles = () => useStyles();

export default useMonitorStyles;
