import { useToaster } from "@maistro/components";
import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { createNpsFeedback } from "api/feedbackApi";
import { CreateFeedbackResponseDto } from "types/dtos/feedback/CreateFeedbackResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import EmailFeedbackStatus from "types/enums/feedback/EmailFeedbackStatus";

const useFeedback = (tokenUuid: string | null, score: string | null) => {
    const [feedbackResponse, setFeedbackResponse] = useState<CreateFeedbackResponseDto | null>(null);

    const { t } = useTranslation();
    const toast = useToaster();

    const recordNpsFeedback = useCallback(async () => {
        if (!tokenUuid || !score) {
            setFeedbackResponse({
                status: EmailFeedbackStatus.TokenNotRecognised,
                statusDescription: "TokenNotRecognised",
            });
            return;
        }
        const formattedScore = parseInt(score, 10);
        const response = await createNpsFeedback({
            score: formattedScore,
            token: tokenUuid,
        });
        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("supplierFeedback.api.error"));
            return;
        }
        setFeedbackResponse(response.data);
    }, [tokenUuid, score, toast, t]);

    useEffect(() => {
        recordNpsFeedback();
    }, [recordNpsFeedback]);

    return { feedbackResponse };
};

export default useFeedback;
