/* eslint-disable babel/camelcase */
import { useToaster } from "@maistro/components";
import type { AxiosResponse } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { createProject, getProject, updateProject } from "api/projectApi";
import { getProjectUsers } from "api/projectSettingsApi";
import { getProjectSummaryReport, getStatementOfWorkReport } from "api/reportingApi";
import { getTaxonomyItemsForProject, taxonomyItemsFromText } from "api/taxonomyApi";
import { ICategoryProps } from "components/shared";
import { IProjectInformation } from "features/project/types";
import useAppDispatch from "hooks/useAppDispatch";
import useCurrentUser from "hooks/useCurrentUser";
import useQuery from "hooks/useQuery";
import routes from "routes/routePaths/RoutePaths";
import validationService from "services/validationService";
import { useLazyListCompanyUsersQuery } from "store/api/companyUsersApi";
import {
    setFetching,
    setProjectInformation as setReduxProjectInformation,
    updateProjectInformation as updateReduxProjectInformation,
} from "store/projectSlice";
import { DirtyValues } from "types/dtos/DirtyValues";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { CompanyUserDto } from "types/dtos/company/CompanyUserDto";
import { constructCreateProjectRequestDto } from "types/dtos/projects/CreateProjectRequestDto";
import { ProjectResponseDto } from "types/dtos/projects/ProjectResponseDto";
import { constructUpdateProjectRequestDto } from "types/dtos/projects/UpdateProjectRequestDto";
import { ProjectUserDto } from "types/dtos/projects/users/ProjectUserDto";
import CategorySelectionStatus from "types/enums/CategorySelectionStatus";
import CurrencyType from "types/enums/CurrencyType";
import BudgetType from "types/enums/projects/BudgetType";
import ProjectStatus from "types/enums/projects/ProjectStatus";
import RolesEnum from "types/enums/rolesPermissions/RolesEnum";

export interface IUseProjectConfig {
    projectUuid?: string;
    includeRemovedCategories?: boolean;
    setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

const useProjectBrief = (props: IUseProjectConfig) => {
    const { projectUuid, includeRemovedCategories, setIsLoading } = props;

    const [currentProjectUuid, setCurrentProjectUuid] = useState(projectUuid);
    const { myUuid, myName, myCompanyUuid } = useCurrentUser();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const toast = useToaster();
    const dispatch = useAppDispatch();
    const [listCompanyUsers] = useLazyListCompanyUsersQuery();

    const updateLoadingState = useCallback(
        (loadingState: boolean) => {
            if (setIsLoading) setIsLoading(loadingState);
        },
        [setIsLoading],
    );

    const defaultValues = useMemo(
        () => ({
            uuid: currentProjectUuid ?? "",
            companyUuid: "",
            projectName: "",
            companyName: "",
            manager: myName,
            managerUuid: "",
            description: "",
            timescale: "",
            budgetCurrency: CurrencyType.GBP,
            buyerBudget: 0,
            showBudgetToSupplier: false,
            budgetType: BudgetType.Budget,
            categories: [],
            status: ProjectStatus.Create,
            decisionSummary: "",
            isLoading: true,
            clientCompanyUuid: undefined,
            sponsorUserUuid: "",
            contributors: [],
            expressionOfInterestDeadline: null as unknown as Date,
        }),
        [myName, currentProjectUuid],
    );
    const [projectError, setProjectError] = useState<number | null>(null);
    const [projectInformation, setProjectInformation] = useState<IProjectInformation>(defaultValues);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [companyUsers, setCompanyUsers] = useState<CompanyUserDto[]>([]);
    const [companyUsersSearchText, setCompanyUsersSearchText] = useState<string>();

    const fetchProjectCategories = useCallback(
        async (projectUuidToFetch: string): Promise<ICategoryProps[]> => {
            const categoriesResponse = await getTaxonomyItemsForProject(projectUuidToFetch, includeRemovedCategories);
            if (categoriesResponse.data instanceof TransactionErrorDto || categoriesResponse.status !== 200) {
                toast.error(t("projectBrief.api.failGetProjectTaxonomy"));
                return [];
            }
            return categoriesResponse.data._embedded.items.map((item) => ({
                value: item._embedded.uuid,
                label: item._embedded.hierarchyDisplayName,
                status: item.selectionStatusId as CategorySelectionStatus,
            }));
        },
        [includeRemovedCategories, toast, t],
    );

    const fetchUsersForCompany = useCallback(
        (companyUuid?: string) => {
            if (companyUuid) {
                listCompanyUsers({ searchQuery: companyUsersSearchText, companyUuid }, true)
                    .unwrap()
                    .then((companyUsersResponse) => {
                        const availableUsers = companyUsersResponse.items.filter((user) => user.userUuid !== myUuid);
                        setCompanyUsers(availableUsers);
                    })
                    .catch(() => setCompanyUsers([]));
            }
        },
        [myUuid, listCompanyUsers, companyUsersSearchText],
    );

    const urlSearchParameters = useQuery();

    const getProjectUuid = useCallback((possibleProjectUuid: string | undefined): string | undefined => {
        let projectUuidToFetch = possibleProjectUuid;
        if (!(possibleProjectUuid && validationService.isValidUuid(possibleProjectUuid))) {
            const queryProjectUuid = urlSearchParameters.get("projectUuid");
            if (queryProjectUuid && validationService.isValidUuid(queryProjectUuid)) {
                setCurrentProjectUuid(queryProjectUuid);
                projectUuidToFetch = queryProjectUuid;
            }
        }
        return projectUuidToFetch;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = useCallback(async () => {
        const projectUuidToFetch = getProjectUuid(projectUuid);
        if (!(projectUuidToFetch && validationService.isValidUuid(projectUuidToFetch))) {
            updateLoadingState(false);
            fetchUsersForCompany(myCompanyUuid);
            return;
        }
        let projectUsers: Array<ProjectUserDto> = [];

        const projectResponse = await getProject(projectUuidToFetch);
        if (projectResponse.data instanceof TransactionErrorDto || projectResponse.status !== 200) {
            updateLoadingState(false);
            setProjectInformation(defaultValues);
            setProjectError(projectResponse.status);
            return;
        }
        const categoriesResponse = await fetchProjectCategories(projectUuidToFetch);

        const projectUsersResponse = await getProjectUsers(projectUuidToFetch);
        if (!(projectUsersResponse.data instanceof TransactionErrorDto) && projectUsersResponse.status === 200) {
            projectUsers = projectUsersResponse.data.projectUsers.filter(
                (user) => user.role.name === RolesEnum.ProjectContributor.toString(),
            );
        }

        listCompanyUsers(
            {
                searchQuery: companyUsersSearchText,
                companyUuid: projectResponse.data._embedded.buyerCompanyUuid,
            },
            true,
        )
            .unwrap()
            .then((response) => {
                const availableUsers = response.items.filter((user) => user.userUuid !== myUuid);
                setCompanyUsers(availableUsers);
            })
            .catch(() => setCompanyUsers([]));
        const fetchedProjectInformation: IProjectInformation = {
            uuid: projectUuidToFetch,
            projectName: projectResponse.data.projectName,
            companyName: projectResponse.data.companyName,
            companyUuid: projectResponse.data._embedded.buyerCompanyUuid,
            manager: projectResponse.data._embedded.nameOfManager || myName,
            managerUuid: projectResponse.data.managerUserUuid,
            description: projectResponse.data.description,
            timescale: projectResponse.data.timescale,
            budgetCurrency: projectResponse.data.budget.currencyOnly?.ccy
                ? (projectResponse.data.budget.currencyOnly.ccy as CurrencyType)
                : CurrencyType.GBP,
            buyerBudget: projectResponse.data.budget.buyerBudget,
            showBudgetToSupplier: projectResponse.data.budget.showBudgetToSupplier,
            budgetType: projectResponse.data.budget.budgetType,
            categories: categoriesResponse,
            status: projectResponse.data.status,
            tenderResponseDeadline: projectResponse.data.tenderResponseDeadline,
            clarificationQuestionDeadline: projectResponse.data.clarificationQuestionDeadline,
            targetProjectStartDate: projectResponse.data.targetProjectStartDate,
            targetProjectCompleteDate: projectResponse.data.targetProjectCompleteDate,
            decisionSummary: projectResponse.data.decisionSummary,
            sponsorUserUuid: projectResponse.data.sponsorUserUuid,
            sponsorName: projectResponse.data._embedded.nameOfSponsor,
            clientCompanyUuid: projectResponse.data._embedded.buyerCompanyUuid,
            clientName: projectResponse.data._embedded.buyerCompanyName,
            type: projectResponse.data.projectType,
            contributors: projectUsers,
            expressionOfInterestDeadline: projectResponse.data.expressionOfInterestDeadline,
            imagesNames: projectResponse.data.imagesNames,
        };

        setProjectInformation(fetchedProjectInformation);
        dispatch(setReduxProjectInformation(fetchedProjectInformation));
        dispatch(setFetching(false));
        updateLoadingState(false);
    }, [
        myCompanyUuid,
        defaultValues,
        dispatch,
        fetchProjectCategories,
        getProjectUuid,
        myName,
        projectUuid,
        fetchUsersForCompany,
        updateLoadingState,
        myUuid,
        listCompanyUsers,
        companyUsersSearchText,
    ]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (projectInformation.status) {
            if (
                projectInformation.status === ProjectStatus.Archived ||
                projectInformation.status === ProjectStatus.Cancelled
            ) {
                console.log(3);
                navigate(routes.projects.projectNotFound.path);
            }
        }
    }, [navigate, projectInformation.status, projectInformation.uuid, currentProjectUuid]);

    const cancelProject = async (companyUuid?: string) => {
        if (currentProjectUuid && validationService.isValidUuid(currentProjectUuid)) {
            setIsSubmitting(true);

            const response = await updateProject(currentProjectUuid, {
                status: ProjectStatus.Cancelled,
                companyUuid,
                dirtyValues: { status: true },
            });
            if (response.data instanceof TransactionErrorDto || response.data.status !== ProjectStatus.Cancelled) {
                toast.error(t("projectBrief.api.failCancelProject"));
                setIsSubmitting(false);
                return;
            }
        }
        navigate(routes.common.dashboard.path);
    };

    const createOrUpdateProject = async (
        values: IProjectInformation,
        dirtyValues: DirtyValues,
        withToast = true,
    ): Promise<string | null> => {
        setIsSubmitting(true);

        let projectToSave = values;
        let response: AxiosResponse<ProjectResponseDto | TransactionErrorDto>;
        const projectCompanyUuid = values.clientCompanyUuid ?? values.companyUuid;
        if (currentProjectUuid && validationService.isValidUuid(currentProjectUuid)) {
            projectToSave = {
                ...values,
                type: projectInformation.type,
                companyUuid: myCompanyUuid ?? projectCompanyUuid,
            };
            response = await updateProject(
                currentProjectUuid,
                constructUpdateProjectRequestDto(projectToSave, dirtyValues),
            );
        } else {
            projectToSave = {
                ...values,
                companyUuid: projectCompanyUuid,
                managerUuid: myUuid,
                clientCompanyUuid: values.clientCompanyUuid ?? myCompanyUuid ?? "",
                type: projectInformation.type,
            };
            response = await createProject(constructCreateProjectRequestDto(projectToSave));
        }

        if (response.status === 403) {
            setIsSubmitting(false);
            toast.error(t("common.api.forbiddenText"));
            return null;
        }
        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            setIsSubmitting(false);
            toast.error(t("projectBrief.api.failProjectSave"));
            return null;
        }

        if (withToast) {
            toast.success(t("projectBrief.api.successProjectSave"));
        }
        setCurrentProjectUuid(response.data._embedded.projectUuid);
        projectToSave.uuid = response.data._embedded.projectUuid;
        projectToSave.clientName = response.data._embedded.buyerCompanyName;
        projectToSave.companyUuid = response.data._embedded.buyerCompanyUuid;
        projectToSave.managerUuid = response.data.managerUserUuid;
        dispatch(updateReduxProjectInformation(projectToSave));
        dispatch(setFetching(false));

        return response.data._embedded.projectUuid;
    };

    const downloadSummary = useCallback(async () => {
        if (!(currentProjectUuid && validationService.isValidUuid(currentProjectUuid))) return;
        const response = await getProjectSummaryReport(currentProjectUuid);

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("projectBrief.api.errorDownloadSummary"));
            return;
        }

        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.download = t("completeProject.projectSummaryFileName");
        link.click();
    }, [currentProjectUuid, t, toast]);

    const downloadStatementOfWork = useCallback(async () => {
        if (!(currentProjectUuid && validationService.isValidUuid(currentProjectUuid))) return;

        const response = await getStatementOfWorkReport(currentProjectUuid);

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("projectBrief.api.errorDownloadStatementOfWork"));
            return;
        }

        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.download = t("completeProject.statementOfWorkFileName");
        link.click();
    }, [currentProjectUuid, t, toast]);

    const autoCategorizeFromText = async (inputText: string) => {
        if (!inputText) {
            return [];
        }

        const response = await taxonomyItemsFromText(inputText);

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("projectBrief.api.errorAutoCategorizeFromText"));
            return [];
        }

        return response.data._embedded.items;
    };

    return {
        projectError,
        setProjectInformation,
        projectInformation,
        getProject: fetchData,
        isSubmitting,
        cancelProject,
        createOrUpdateProject,
        downloadSummary,
        downloadStatementOfWork,
        autoCategorizeFromText,
        setIsSubmitting,
        companyUsers,
        fetchUsersForCompany,
        setCompanyUsersSearchText,
    };
};

export default useProjectBrief;
