import { Button, CheckboxesField, RichTextEditorField, TextButton } from "@maistro/components";
import { Form, Formik, useFormikContext } from "formik";
import { TFunction } from "i18next";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import { ICommonProps } from "components/shared";
import DisplayFileUploadField from "features/files/DisplayFileUploadFields";
import SectionDropdown from "features/project/clarificationQuestions/askQuestion/SectionDropdown";
import useReduxSelector from "hooks/useReduxSelector";
import useScreenLayout from "hooks/useScreenLayout";
import ReactGA from "react-ga4";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import ProjectType from "types/enums/projects/ProjectType";

const useStyles = createUseStyles((theme: ITheme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.large,
    },
    control: {
        display: "flex",
        flexDirection: "column",
    },
    buttonControl: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.large,
    },
    bold: {
        ...theme.typography.base,
        fontWeight: 800,
    },
}));

const validationSchema = (t: TFunction<"translation", undefined>) =>
    Yup.object({
        question: Yup.string().required(t("clarificationQuestions.question.required")),
        askedSuppliers: Yup.array().of(Yup.string()).min(1, t("clarificationQuestions.selectSuppliers.required")),
    });

const askAllValue = "askAll";
const answerNotRequiredValue = "answerNotRequired";

interface IAutomaticSupplierSelectionProps {
    suppliers: Array<SupplierSubmissionResponseDto>;
}

interface IBuyerAskQuestionForm {
    question: string;
    answerNotRequired: Array<string>;
    askAllSuppliers: Array<string>;
    askedSuppliers: Array<string>;
}

const AutomaticSupplierSelection: React.FC<IAutomaticSupplierSelectionProps> = ({ suppliers }) => {
    const { values, setFieldValue, setFieldTouched } = useFormikContext<IBuyerAskQuestionForm>();

    useEffect(() => {
        if (values.askAllSuppliers[0] === askAllValue) {
            setFieldValue(
                "askedSuppliers",
                suppliers.map((supplier) => supplier.supplierCompanyUuid),
            );
            setFieldTouched("askedSuppliers", true);
        }
    }, [setFieldValue, setFieldTouched, suppliers, values.askAllSuppliers]);

    useEffect(() => {
        if (values.askedSuppliers.length < suppliers.length) {
            setFieldValue("askAllSuppliers", []);
        }
    }, [setFieldValue, suppliers.length, values.askedSuppliers.length]);

    useEffect(() => {
        if (values.askedSuppliers.length > 0) {
            setFieldValue("isExpanded", true);
        }
    }, [setFieldValue, values.askedSuppliers.length]);

    useEffect(() => {
        if (values.askedSuppliers.length === suppliers.length && values.askAllSuppliers[0] !== askAllValue) {
            setFieldValue("askedSuppliers", []);
            setFieldTouched("askedSuppliers", true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setFieldValue, setFieldTouched, values.askAllSuppliers]);

    return null;
};

interface IBuyerAskQuestionFormProps extends ICommonProps {
    submit: (question: string, isRequired: boolean, supplierUuids: Array<string>, section: string) => void;
    cancel?: () => void;
    submittingQuestion: boolean;
    suppliers: Array<SupplierSubmissionResponseDto>;
    files?: FileDto[];
    setFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    removeFile: (file: FileDto | null) => void;
    fileIsUploading: boolean;
    setFileIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
    projectType: ProjectType | undefined;
}

const BuyerAskQuestionForm: React.FC<IBuyerAskQuestionFormProps> = ({
    submit,
    cancel,
    submittingQuestion,
    suppliers,
    files,
    removeFile,
    setFiles,
    fileIsUploading,
    setFileIsUploading,
    projectType,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { onDesktop } = useScreenLayout();

    const [selectedSection, setSelectedSection] = useState("");

    const { sections } = useReduxSelector((state) => state.sectionState);
    const { theme } = useReduxSelector((state) => state.themeState);

    return (
        <Formik
            enableReinitialize
            initialValues={{
                question: "",
                answerNotRequired: [],
                askAllSuppliers: [],
                askedSuppliers: [],
                isExpanded: false,
            }}
            validationSchema={validationSchema(t)}
            onSubmit={(values) => {
                submit(values.question, values.answerNotRequired.length > 0, values.askedSuppliers, selectedSection);
            }}
        >
            {({ values, errors, isValid, isSubmitting }) => {
                return (
                    <Form className={classes.form}>
                        {projectType === ProjectType.Tender && (
                            <SectionDropdown setSelectedSection={setSelectedSection} sections={sections.items} />
                        )}
                        <RichTextEditorField
                            testid="clarification-question-text"
                            label={t("clarificationQuestions.question.label")}
                            name="question"
                            required
                            joditLicense={theme.joditLicence}
                        />
                        <DisplayFileUploadField
                            testid="clarification-question"
                            files={files}
                            removeFile={removeFile}
                            canManageFiles
                            setFiles={setFiles}
                            fileIsUploading={fileIsUploading}
                            setFileIsUploading={setFileIsUploading}
                        />
                        <CheckboxesField
                            testid="answer-not-required"
                            name="answerNotRequired"
                            options={[
                                {
                                    label: t("clarificationQuestions.answerNotRequired.label"),
                                    value: answerNotRequiredValue,
                                },
                            ]}
                            optionClassName={classes.bold}
                        />
                        <CheckboxesField
                            testid="ask-all-suppliers"
                            label={t("clarificationQuestions.askAllSuppliers.label")}
                            name="askAllSuppliers"
                            options={[
                                {
                                    label: t("clarificationQuestions.askAllSuppliers.option"),
                                    value: askAllValue,
                                },
                            ]}
                            required
                        />
                        <CheckboxesField
                            testid="select-suppliers"
                            label={t("clarificationQuestions.selectSuppliers.label")}
                            expandable
                            isExpanded={values.isExpanded}
                            options={suppliers.map((supplier) => ({
                                label: supplier._embedded.supplierCompanyName,
                                value: supplier.supplierCompanyUuid,
                            }))}
                            name="askedSuppliers"
                            columns={onDesktop ? 2 : undefined}
                        />
                        <AutomaticSupplierSelection suppliers={suppliers} />
                        <div className={classes.buttonControl}>
                            <Button
                                testid="clarification-question-submit-button"
                                label={t("clarificationQuestions.submitQuestion")}
                                type="submit"
                                size="large"
                                disabled={!isEmpty(errors) || !isValid || isSubmitting || fileIsUploading}
                                loading={submittingQuestion}
                                onClick={() =>
                                    ReactGA.event({
                                        category: ga4Category.Button,
                                        action: ga4Action.ButtonClick,
                                        label: "Clarification Questions - Ask Question - Submit",
                                    })
                                }
                            />
                            <TextButton
                                label={t("clarificationQuestions.cancelQuestion")}
                                onClick={() => {
                                    ReactGA.event({
                                        category: ga4Category.Button,
                                        action: ga4Action.ButtonClick,
                                        label: "Clarification Questions - Ask Question - Cancel",
                                    });
                                    if (cancel) cancel();
                                }}
                                testid="clarification-question-cancel-button"
                            />
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default BuyerAskQuestionForm;
