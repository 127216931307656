import { createUseStyles } from "react-jss";

import type { IAvatarProps } from "components/Avatar/Avatar";
import { ITheme } from "styles/themes/types";

const SMALL_AVATAR_SIZE = 24;
const MEDIUM_AVATAR_SIZE = 30;
const LARGE_AVATAR_SIZE = 40;
const TEXT_SIZE_RATIO = 0.4;

const getArrayIndexFromCharacterCode = (array: unknown[], str: string) => {
    const firstCharacterCode = str.toUpperCase().codePointAt(0);
    if (!firstCharacterCode) return array[0];
    const normalCode = firstCharacterCode - 64; // 1 == A etc
    const setLength = 27 / array.length;
    const set = Math.floor(normalCode / setLength);
    return array[set];
};

const getBackgroundColor = (name: string, backgroundColor?: string | string[]) => {
    if (Array.isArray(backgroundColor)) {
        return getArrayIndexFromCharacterCode(backgroundColor, name);
    }
    return backgroundColor;
};

const sizeProps = (avatarSize = SMALL_AVATAR_SIZE) => ({
    height: avatarSize,
    width: avatarSize,
    fontSize: avatarSize * TEXT_SIZE_RATIO,
});

const avatarStyles = createUseStyles((theme: ITheme) => ({
    component: (props: IAvatarProps) => ({
        display: "inline-block",
        "& span": {
            backgroundPosition: "center top",
            backgroundSize: "cover",
            color: theme.colors.white,
            backgroundColor: getBackgroundColor(props.name, props.backgroundColor) ?? theme.colors.positive,
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            borderColor: theme.colors.white,
            borderWidth: 1.5,
            borderStyle: "solid",
        },
    }),

    round: {
        borderRadius: "50%",
    },

    small: { ...sizeProps(SMALL_AVATAR_SIZE) },
    medium: { ...sizeProps(MEDIUM_AVATAR_SIZE) },
    large: { ...sizeProps(LARGE_AVATAR_SIZE) },
}));

export default avatarStyles;
