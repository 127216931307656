import React, { useCallback, useEffect, useMemo } from "react";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";

import { LoadingApplication } from "components";
import userManager from "features/auth/oidc/userManager";
import useCompany from "hooks/useCompany";
import useCurrentUser from "hooks/useCurrentUser";
import useReduxSelector from "hooks/useReduxSelector";
import { SignedInState } from "store/authenticationSlice";
import FeatureToggle from "types/enums/companies/FeatureToggle";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

export interface IGuardedRouteProps {
    permission?: PermissionsEnum;
    feature?: FeatureToggle;
}

export interface IRedirectState {
    path?: string;
}

const GuardedRoute: React.FC<IGuardedRouteProps> = (props: IGuardedRouteProps) => {
    const { permission, feature } = props;

    const { pathname, search } = useLocation();
    const { projectUuid } = useParams();

    const redirectPath = `${pathname}${search}`;

    const signedInState = useReduxSelector((state) => state.authenticationState.signedInState);
    const { userPermissions, isLoading: isPermissionsLoading } = useReduxSelector(
        (state) => state.rolesPermissionsState,
    );

    const { userHasProjectPermission } = useCurrentUser();
    const { companyHasFeature } = useCompany();

    const isAuthenticated = useMemo(() => signedInState === SignedInState.signedIn, [signedInState]);

    const hasPermission = useMemo(() => {
        if (permission && projectUuid) {
            return userHasProjectPermission(permission, projectUuid);
        }
        if (permission) {
            return userPermissions.some((userPermission) => userPermission.name === permission);
        }

        return true;
    }, [permission, projectUuid, userHasProjectPermission, userPermissions]);

    const hasFeatureEnabled = useMemo(() => {
        return feature ? companyHasFeature(feature) : true;
    }, [companyHasFeature, feature]);

    const isAuthorized = useMemo(() => {
        return hasPermission && hasFeatureEnabled;
    }, [hasFeatureEnabled, hasPermission]);

    const signIn = useCallback(() => {
        userManager.signinRedirect({ state: { path: redirectPath } as IRedirectState }).catch(() => {
            setTimeout(signIn, 5000);
        });
    }, [redirectPath]);

    useEffect(() => {
        if (!isAuthenticated) {
            signIn();
        }
    }, [isAuthenticated, signIn]);

    if (isPermissionsLoading) {
        return <LoadingApplication testid="loading-application-screen" />;
    }

    if (isAuthenticated && isAuthorized) {
        return <Outlet />;
    }

    return <Navigate to="/error" />;
};

export default GuardedRoute;
