import React from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";

import { injectStore } from "api/commonApi";
import App from "App";
import store from "store/store";
import i18n from "translations/i18n";
import OidcProvider from "features/auth/oidc/OidcProvider";

const root = createRoot(document.getElementById("app") as HTMLElement);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <OidcProvider>
                <I18nextProvider i18n={i18n}>
                    <App />
                </I18nextProvider>
            </OidcProvider>
        </Provider>
    </React.StrictMode>,
);

injectStore(store);
