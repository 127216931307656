/* eslint-disable @typescript-eslint/no-explicit-any */
export type DirtyValues = {
    [key: string]: boolean;
};

export const constructDirtyValues = (
    values: Record<string, unknown>,
    isDirty: (key: string, value: any) => boolean,
): DirtyValues => {
    return Object.entries(values).reduce((acc, [key, value]) => {
        acc[key] = isDirty(key, value);
        return acc;
    }, {} as DirtyValues);
};
