import { BooleanOptionsField, Heading, TextField } from "@maistro/components";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import DisplayVettingFileUploadField from "features/company/profile/vetting/files/DisplayVettingFileUploadField";
import { IVettingQuestionnaireForm } from "features/company/profile/vetting/IVettingQuestionnaireForm";
import useSectionStyles from "features/company/profile/vetting/sections/useSectionStyles";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";
import { VettingInformationKeys } from "types/dtos/company/vettingInformation/VettingInformationDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    conditionalQuestions: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.small,
        marginTop: theme.spacing.small,
    },
}));

export const disasterRecoveryValidationSchema = () =>
    Yup.object({
        hasCyberSecurityPlan: Yup.boolean().nullable(),
        cyberSecurityPlanDetails: Yup.string().nullable(),
        hasDisasterRecoveryPlan: Yup.boolean().nullable(),
        disasterRecoveryPlanDetails: Yup.string().nullable(),
        detailsOfCircumstances: Yup.string().nullable(),
        detailsOfRegularPractice: Yup.string().nullable(),
    });

interface IDisasterRecoverySubSectionProps {
    cyberSecurityPlanFiles?: FileDto[];
    disasterRecoveryPlanFiles?: FileDto[];
    setCyberSecurityPlanFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    setDisasterRecoveryPlanFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
}

const DisasterRecoverySubSection: React.FC<IDisasterRecoverySubSectionProps> = ({
    cyberSecurityPlanFiles,
    disasterRecoveryPlanFiles,
    setCyberSecurityPlanFiles,
    setDisasterRecoveryPlanFiles,
}) => {
    const sharedClasses = useSectionStyles();
    const classes = useStyles();

    const { t } = useTranslation();

    const [fileIsUploading, setFileIsUploading] = useState(false);

    const { values } = useFormikContext<IVettingQuestionnaireForm>();

    return (
        <div id={VettingInformationKeys.disasterRecovery}>
            <Heading variant="h4">
                {t("companyVettingQuestionnaire.sections.dataAndSecurity.disasterRecovery.title")}
            </Heading>
            <ol className={sharedClasses.listStyle} type="1">
                <li>
                    <BooleanOptionsField
                        name="hasCyberSecurityPlan"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.dataAndSecurity.disasterRecovery.cyberSecurityPlan.label",
                        )}
                        testid="has-cyber-security-plan-options-field"
                    />
                    {values.hasCyberSecurityPlan && (
                        <div className={classes.conditionalQuestions}>
                            <DisplayVettingFileUploadField
                                name="cyberSecurityPlanFiles"
                                files={cyberSecurityPlanFiles}
                                setFiles={setCyberSecurityPlanFiles}
                                fileIsUploading={fileIsUploading}
                                setFileIsUploading={setFileIsUploading}
                                multiple={false}
                                testid="cyber-security-plan-file-upload-field"
                            />
                            <TextField
                                name="cyberSecurityPlanDetails"
                                required
                                label={t(
                                    "companyVettingQuestionnaire.sections.dataAndSecurity.disasterRecovery.cyberSecurityPlan.field",
                                )}
                                testid="cyber-security-plan-details-field"
                            />
                        </div>
                    )}
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasDisasterRecoveryPlan"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.dataAndSecurity.disasterRecovery.disasterRecoveryPlan.label",
                        )}
                        testid="has-disaster-recovery-plan-options-field"
                    />
                    {values.hasDisasterRecoveryPlan && (
                        <div className={classes.conditionalQuestions}>
                            <DisplayVettingFileUploadField
                                name="disasterRecoveryPlanFiles"
                                files={disasterRecoveryPlanFiles}
                                setFiles={setDisasterRecoveryPlanFiles}
                                fileIsUploading={fileIsUploading}
                                setFileIsUploading={setFileIsUploading}
                                multiple={false}
                                testid="disaster-recovery-plan-file-upload-field"
                            />
                            <TextField
                                name="disasterRecoveryPlanDetails"
                                required
                                label={t(
                                    "companyVettingQuestionnaire.sections.dataAndSecurity.disasterRecovery.disasterRecoveryPlan.field",
                                )}
                                testid="disaster-recovery-plan-details-field"
                            />
                        </div>
                    )}
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasDetailsOfCircumstances"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.dataAndSecurity.disasterRecovery.hasDetailsOfCircumstances.label",
                        )}
                        testid="has-details-of-circumstances-options-field"
                    />
                    {values.hasDetailsOfCircumstances && (
                        <TextField
                            name="detailsOfCircumstances"
                            label={t(
                                "companyVettingQuestionnaire.sections.dataAndSecurity.disasterRecovery.detailsOfCircumstances.label",
                            )}
                            testid="details-of-circumstances-field"
                        />
                    )}
                </li>
            </ol>
        </div>
    );
};

export default DisasterRecoverySubSection;
