import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getIdentifiedSuppliers, getPotentialSuppliers } from "api/projectSuppliersApi";
import { PotentialSupplierDto } from "types/dtos/projectSuppliers/PotentialSupplierDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { CompanySizeV2 } from "types/enums/companies/CompanySizeV2";
import SupplierRelationshipStatus from "types/enums/companies/SupplierRelationshipStatus";
import SupplierTieringStatus from "types/enums/companies/SupplierTieringStatus";

export interface IFilterValues {
    matchRating?: string;
    relationshipStatuses?: Array<SupplierRelationshipStatus>;
    companySizes?: Array<CompanySizeV2>;
    tieringStatuses?: Array<SupplierTieringStatus>;
}

export interface ISupplierMatchingState {
    filters?: IFilterValues;
    potentialSuppliers: Array<PotentialSupplierDto>;
    selectedSuppliers: Array<string>;
    unselectedSuppliers: Array<PotentialSupplierDto>;
}

export const fetchPotentialSuppliers = createAsyncThunk<Array<PotentialSupplierDto>, string>(
    "supplierMatching/fetchPotentialSuppliers",
    async (projectUuid, thunkAPI) => {
        const suppliersResponse = await getPotentialSuppliers(projectUuid);

        if (suppliersResponse.data instanceof TransactionErrorDto || suppliersResponse.status !== 200) {
            return thunkAPI.rejectWithValue(suppliersResponse.data);
        }

        return suppliersResponse.data._embedded.items;
    },
);

export const fetchIdentifiedSuppliers = createAsyncThunk<Array<string>, string>(
    "supplierMatching/fetchIdentifiedSuppliers",
    async (projectUuid, thunkAPI) => {
        const suppliersResponse = await getIdentifiedSuppliers(projectUuid);

        if (suppliersResponse.data instanceof TransactionErrorDto || suppliersResponse.status !== 200) {
            return thunkAPI.rejectWithValue(suppliersResponse.data);
        }

        return suppliersResponse.data._embedded.items.map((supplier) => supplier.companyUuid);
    },
);

export const supplierMatchingSlice = createSlice({
    name: "supplierMatching",
    initialState: {
        filters: {
            matchRating: "0",
        } as IFilterValues,
        potentialSuppliers: [] as Array<PotentialSupplierDto>,
        selectedSuppliers: [] as Array<string>,
        unselectedSuppliers: [] as Array<PotentialSupplierDto>,
    },
    reducers: {
        setFilters: (state, action: PayloadAction<IFilterValues>) => {
            state.filters = action.payload;
        },
        unselectSupplier: (state, action: PayloadAction<string>) => {
            const affectedSupplier = state.potentialSuppliers.find(
                (supplier) => supplier.companyUuid === action.payload,
            );
            if (affectedSupplier) {
                state.unselectedSuppliers.push(affectedSupplier);
                state.selectedSuppliers = state.selectedSuppliers.filter((item) => item !== action.payload);
            }
        },
        clearUnSelected: (state) => {
            state.unselectedSuppliers = [];
        },
        selectSupplier: (state, action: PayloadAction<string>) => {
            const affectedSupplier = state.potentialSuppliers.find(
                (supplier) => supplier.companyUuid === action.payload,
            );
            if (affectedSupplier) {
                state.selectedSuppliers.push(action.payload);
                state.unselectedSuppliers = state.unselectedSuppliers.filter(
                    (item) => item.companyUuid !== action.payload,
                );
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPotentialSuppliers.fulfilled, (state, action) => {
            state.potentialSuppliers = action.payload;
        });
        builder.addCase(fetchIdentifiedSuppliers.fulfilled, (state, action) => {
            state.selectedSuppliers = action.payload;
        });
    },
});

export const { setFilters, unselectSupplier, clearUnSelected, selectSupplier } = supplierMatchingSlice.actions;

export default supplierMatchingSlice.reducer;
