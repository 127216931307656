import { useEffect, useState } from "react";

/**
 * Track the user's internet connection status.
 */
const useOnline = () => {
    // .....................................................
    // Local State Hooks

    const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

    // .....................................................
    // Functions

    useEffect(() => {
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
        };

        window.addEventListener("online", handleStatusChange);
        window.addEventListener("offline", handleStatusChange);

        return () => {
            window.removeEventListener("online", handleStatusChange);
            window.removeEventListener("offline", handleStatusChange);
        };
    }, [isOnline]);

    // .....................................................
    // Render

    return isOnline;
};
export default useOnline;
