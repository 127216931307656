import { Button, Copy, Heading, RadioButtonsField, TextButton } from "@maistro/components";
import { Form, Formik } from "formik";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";
import { ProjectUserDto } from "types/dtos/projects/users/ProjectUserDto";
import { UpdateProjectUserDto } from "types/dtos/projects/users/UpdateProjectUserDto";
import { RoleDto } from "types/dtos/rolesPermissions/RoleDto";
import RolesEnum from "types/enums/rolesPermissions/RolesEnum";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },

    buttonControl: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.small,
    },
}));

interface IEditProjectUserProps extends ICommonProps {
    user?: ProjectUserDto;
    submit: (dto: UpdateProjectUserDto) => void;
    roles: RoleDto[];
    cancel: () => void;
}

const EditProjectUser: React.FC<IEditProjectUserProps> = (props) => {
    const classes = useStyles();

    const { roles, user, submit } = props;

    const { t } = useTranslation();

    const projectManager = useMemo(() => roles.find((role) => role.name === RolesEnum.ProjectManager), [roles]);
    const isProjectManager = useMemo(() => user?.role.roleId === projectManager?.id, [projectManager, user]);
    const projectManagerSelected = useCallback((roleId: string) => roleId === projectManager?.id, [projectManager]);

    const options = roles.map((role) => ({
        label: t(`roles.names.${role.name}`),
        tooltip: t(`roles.tooltips.${role.name}`),
        value: role.id.toString(),
    }));

    if (!user || roles.length === 0) return null;

    return (
        <Formik
            initialValues={{
                userUuid: user.userUuid,
                projectUuid: "",
                projectRoleUuid: user.role.roleId,
            }}
            onSubmit={(values) => submit(values)}
        >
            {({ values, isValid }) => (
                <Form className={classes.container}>
                    <Heading variant="h2">{t("projectSettings.edit.title")}</Heading>
                    <RadioButtonsField
                        label={t("projectSettings.edit.roles.label")}
                        name="projectRoleUuid"
                        options={options}
                        testid="edit-project-user-role-options"
                        required
                    />
                    {!isProjectManager && projectManagerSelected(values.projectRoleUuid) && (
                        <Copy variant="sublabel">{t("projectSettings.edit.warnings.pmChange")}</Copy>
                    )}
                    {isProjectManager && !projectManagerSelected(values.projectRoleUuid) && (
                        <Copy variant="sublabel">{t("projectSettings.edit.warnings.pmRequired")}</Copy>
                    )}
                    <div className={classes.buttonControl}>
                        <Button
                            type="submit"
                            label={t("common.update")}
                            disabled={(isProjectManager && !projectManagerSelected(values.projectRoleUuid)) || !isValid}
                            testid="edit-project-user-update-button"
                        />
                        <TextButton
                            label={t("common.cancel")}
                            onClick={props.cancel}
                            testid="edit-project-user-cancel-button"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default EditProjectUser;
