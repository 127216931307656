import classNames from "classnames";
import { useField } from "formik";
import React from "react";
import { createUseStyles } from "react-jss";

import MaistroFormikInputError from "components/FormikFields/MaistroFormikInputError";
import MaistroFormikLabel from "components/FormikFields/MaistroFormikLabel";
import useFormikFieldStyles from "components/FormikFields/hooks/useFormikFieldStyles";
import { ICommonProps, IFieldProps } from "components/shared";
import ReactGA from "react-ga4";
import colorService from "services/colorService";
import { ITheme } from "styles/themes/types";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    spacer: {
        marginTop: theme.spacing.small,
    },

    inputArea: {
        position: "relative",
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        rowGap: theme.spacing.small,
        columnGap: theme.spacing.medium,
    },

    button: {
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.colors.white,
        boxShadow: `${colorService.toHexWithOpacity(theme.colors.primary, 0.1)} 0 3px 6px`,
        border: "none",
        borderRadius: 100,
        width: 40,
        height: 40,

        ...theme.typography.base,
        fontSize: 16,
        fontWeight: 700,

        "&:hover": {
            boxShadow: "none",
        },
    },

    selected: {
        backgroundColor: theme.colors.highlight,
        color: theme.colors.white,
    },
}));

export interface IScoreSelectFieldProps extends IFieldProps, ICommonProps {
    values: number[];
}

const ScoreSelectField: React.FC<IScoreSelectFieldProps> = (props) => {
    const classes = useStyles();
    const sharedClasses = useFormikFieldStyles();

    const [field, meta, { setValue }] = useField(props.name);
    const hasError = meta.touched && !!meta.error;

    const { label, tooltip, values, className, testid, ...other } = props;

    const renderScore = (score: number) => (
        <button
            key={`${testid}-${score}`}
            className={classNames(classes.button, { [classes.selected]: field.value === score })}
            {...other}
            type="button"
            onClick={() => {
                ReactGA.event({
                    category: ga4Category.Button,
                    action: ga4Action.ButtonClick,
                    label: "Score Select Field - Render Score - ".concat(score.toString()),
                });
                setValue(score);
            }}
            data-testid={`${testid}-${score}`}
        >
            {score}
        </button>
    );

    return (
        <div className={classNames(sharedClasses.content)} data-component="ScoreSelectField" data-testid={testid}>
            <MaistroFormikLabel
                label={label}
                tooltip={tooltip}
                name={props.name}
                required={props.required}
                testid={`${testid}-label`}
            />
            <div className={classNames(sharedClasses.inputContainer, classes.spacer)}>
                <input type="hidden" name={field.name} value={field.value} />
                <div className={classes.inputArea}>{values.map((score) => renderScore(score))}</div>
            </div>
            <MaistroFormikInputError text={meta.error} isVisible={hasError} testid={`${testid}-error`} />
        </div>
    );
};

export default ScoreSelectField;
