import { BooleanOptionsField, CheckboxField, FieldLabel, Heading, TextField } from "@maistro/components";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import DisplayVettingFileUploadField from "features/company/profile/vetting/files/DisplayVettingFileUploadField";
import { IVettingQuestionnaireForm } from "features/company/profile/vetting/IVettingQuestionnaireForm";
import useSectionStyles from "features/company/profile/vetting/sections/useSectionStyles";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";
import { VettingInformationKeys } from "types/dtos/company/vettingInformation/VettingInformationDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    checkboxLabel: {
        display: "inline-block",
        marginBottom: theme.spacing.small,
    },
    checkboxContainer: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.small,
    },
    conditionalQuestions: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.small,
        marginTop: theme.spacing.small,
    },
}));

export const equalityValidationSchema = () =>
    Yup.object({
        hasDiversityAndInclusionPolicy: Yup.boolean().nullable(),
        hasResponsiblePersonEquality: Yup.string().nullable(),
        hasCommitmentToAntiDiscrimination: Yup.string().nullable(),
        identifiesIncidentsOfDiscrimination: Yup.string().nullable(),
        allWorkersPaidFairly: Yup.boolean().nullable(),
        hasUnlawfulDiscriminationFindings: Yup.boolean().nullable(),
        findingsDetails: Yup.string().nullable(),
        promoteGoodPracticeForAntiDiscrimination: Yup.boolean().nullable(),
        hasHumanRightsPolicy: Yup.boolean().nullable(),
    });

interface IEqualitySubSectionProps {
    diversityAndInclusionPolicyFiles: FileDto[];
    setDiversityAndInclusionPolicyFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
}

const EqualitySubSection: React.FC<IEqualitySubSectionProps> = ({
    diversityAndInclusionPolicyFiles,
    setDiversityAndInclusionPolicyFiles,
}) => {
    const sharedClasses = useSectionStyles();
    const classes = useStyles();
    const { t } = useTranslation();

    const [fileIsUploading, setFileIsUploading] = useState<boolean>(false);

    const { values } = useFormikContext<IVettingQuestionnaireForm>();

    return (
        <div id={VettingInformationKeys.equality}>
            <Heading variant="h4">{t("companyVettingQuestionnaire.sections.ethics.equality.title")}</Heading>
            <ol className={sharedClasses.listStyle} type="1">
                <li>
                    <BooleanOptionsField
                        name="hasDiversityAndInclusionPolicy"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.ethics.equality.hasDiversityAndInclusionPolicy.label",
                        )}
                        testid="has-diversity-and-inclusion-policy-options-field"
                    />
                    {values.hasDiversityAndInclusionPolicy && (
                        <div className={classes.conditionalQuestions}>
                            <DisplayVettingFileUploadField
                                testid="diversity-and-inclusion-policy-file-upload-field"
                                files={diversityAndInclusionPolicyFiles}
                                setFiles={setDiversityAndInclusionPolicyFiles}
                                fileIsUploading={fileIsUploading}
                                setFileIsUploading={setFileIsUploading}
                                name="diversityAndInclusionPolicyFiles"
                            />
                        </div>
                    )}
                </li>
                <li>
                    <FieldLabel
                        className={classes.checkboxLabel}
                        name="policy"
                        required
                        label={t("companyVettingQuestionnaire.sections.ethics.equality.policy.label")}
                    />
                    <div className={classes.checkboxContainer}>
                        <CheckboxField
                            name="hasResponsiblePersonEquality"
                            label={t(
                                "companyVettingQuestionnaire.sections.ethics.equality.policy.hasResponsiblePerson.label",
                            )}
                            testid="policy-inclusions-has-responsible-person-option-field"
                        />
                        <CheckboxField
                            name="hasCommitmentToAntiDiscrimination"
                            label={t(
                                "companyVettingQuestionnaire.sections.ethics.equality.policy.hasCommitmentToAntiDiscrimination.label",
                            )}
                            testid="policy-inclusions-has-commitment-to-anti-discrimination-option-field"
                        />
                        <CheckboxField
                            name="identifiesIncidentsOfDiscrimination"
                            label={t(
                                "companyVettingQuestionnaire.sections.ethics.equality.policy.identifiesIncidentsOfDiscrimination.label",
                            )}
                            testid="policy-inclusions-identifies-incidents-of-discrimination-option-field"
                        />
                    </div>
                </li>
                <li>
                    <BooleanOptionsField
                        name="allWorkersPaidFairly"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t("companyVettingQuestionnaire.sections.ethics.equality.allWorkersPaidFairly.label")}
                        testid="all-workers-paid-fairly-options-field"
                    />
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasUnlawfulDiscriminationFindings"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.ethics.equality.hasUnlawfulDiscriminationFindings.label",
                        )}
                        testid="has-unlawful-discrimination-findings-options-field"
                    />
                    {values.hasUnlawfulDiscriminationFindings && (
                        <div className={classes.conditionalQuestions}>
                            <TextField
                                name="findingsDetails"
                                label={t("companyVettingQuestionnaire.sections.ethics.equality.findingsDetails.label")}
                                testid="findings-details-field"
                            />
                        </div>
                    )}
                </li>
                <li>
                    <BooleanOptionsField
                        name="promoteGoodPracticeForAntiDiscrimination"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.ethics.equality.promoteGoodPracticeForAntiDiscrimination.label",
                        )}
                        testid="promote-good-practice-for-anti-discrimination-options-field"
                    />
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasHumanRightsPolicy"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t("companyVettingQuestionnaire.sections.ethics.equality.hasHumanRightsPolicy.label")}
                        testid="has-human-rights-policy-options-field"
                    />
                </li>
            </ol>
        </div>
    );
};

export default EqualitySubSection;
