import { faEdit, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { ITableColumn, Icon } from "@maistro/components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import ReactGA from "react-ga4";
import { UserGroupDto } from "types/dtos/company/userGroups/UserGroupDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useUserGroupConfiguration = (
    onEdit: (row: UserGroupDto) => void,
    onDelete: (row: UserGroupDto) => void,
    setSelectedUserGroupUuid: React.Dispatch<React.SetStateAction<string | undefined>>,
) => {
    const { t } = useTranslation();

    const columns: ITableColumn[] = useMemo(
        () => [
            {
                position: 1,
                priorityLevel: 1,
                label: t("userGroups.table.userGroupName"),
                name: "name",
                minWidth: 175,
                contentWrap: true,
            },
            {
                position: 2,
                priorityLevel: 2,
                label: t("userGroups.table.noOfUsers"),
                name: "memberUuids",
                render: (userGroup: UserGroupDto) => userGroup.memberUuids.length,
                minWidth: 100,
            },
            {
                position: 3,
                priorityLevel: 0,
                name: "edit",
                minWidth: 50,
                render: (userGroup: UserGroupDto) => (
                    <Icon
                        icon={faEdit}
                        alt={t("common.edit")}
                        onClick={() => {
                            ReactGA.event({
                                category: ga4Category.Button,
                                action: ga4Action.ButtonClick,
                                label: "User Group - Edit - ".concat(userGroup.name),
                            });
                            setSelectedUserGroupUuid(userGroup.id);
                            onEdit(userGroup);
                        }}
                    />
                ),
            },
            {
                position: 4,
                priorityLevel: 0,
                name: "delete",
                minWidth: 50,
                render: (userGroup: UserGroupDto) => (
                    <Icon
                        icon={faTrashCan}
                        alt={t("common.delete")}
                        onClick={() => {
                            ReactGA.event({
                                category: ga4Category.Button,
                                action: ga4Action.ButtonClick,
                                label: "User Group - Delete - ".concat(userGroup.name),
                            });
                            onDelete(userGroup);
                        }}
                    />
                ),
            },
        ],
        [onDelete, onEdit, setSelectedUserGroupUuid, t],
    );

    return {
        columns,
    };
};

export default useUserGroupConfiguration;
