import { Label, Popup } from "@maistro/components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import FileList from "components/FileList/FileList";
import FileUploadField from "components/FileUploadField/FileUploadField";
import { ICommonProps } from "components/shared";
import env from "config/EnvironmentVariables";
import FileDto from "types/dtos/files/FileDto";

interface IDisplayFileUploadFieldProps extends ICommonProps {
    canManageFiles: boolean;
    setFileIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
    fileIsUploading: boolean;
    setFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    removeFile: (file: FileDto | null) => void;
    files?: Array<FileDto>;
}

const DisplayFileUploadField: React.FC<IDisplayFileUploadFieldProps> = ({
    files,
    setFiles,
    removeFile,
    fileIsUploading,
    setFileIsUploading,
    canManageFiles,
    testid,
}) => {
    const { t } = useTranslation();

    const [isRemoveFilePopupOpen, setIsRemoveFilePopupOpen] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<FileDto | null>(null);

    const displayConfirmDeleteDialog = (file: FileDto): void => {
        setSelectedFile(file);
        setIsRemoveFilePopupOpen(true);
    };

    const removeProjectFile = () => {
        removeFile(selectedFile);
        setIsRemoveFilePopupOpen(false);
    };

    return (
        <div>
            <Label content={t("projectBrief.fileUpload.label")} name="files" />
            {files && files.length > 0 && (
                <FileList
                    files={files}
                    setFiles={setFiles}
                    canDelete={canManageFiles}
                    testid={`${testid}-documents`}
                    removeFile={displayConfirmDeleteDialog}
                />
            )}
            <FileUploadField
                setFiles={setFiles}
                name="files"
                acceptedExtensions={env.SupportedProjectFileExtensions}
                disabled={!canManageFiles}
                testid={`${testid}-upload-file`}
                fileIsUploading={fileIsUploading}
                setFileIsUploading={setFileIsUploading}
            />
            <Popup
                title={t("popups.removeProjectFile.title")}
                message={t("popups.removeProjectFile.message", { fileName: selectedFile?.fileName })}
                isOpen={isRemoveFilePopupOpen}
                primaryActionText={t("popups.removeProjectFile.cta.primary")}
                onPrimaryAction={removeProjectFile}
                onClose={() => setIsRemoveFilePopupOpen(false)}
                testid="confirm-remove-projectfile-popup"
            />
        </div>
    );
};

export default DisplayFileUploadField;
