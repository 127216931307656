import { faSearch, faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { Icon } from "@maistro/components";
import classNames from "classnames";
import React, { ChangeEvent } from "react";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import baseSearchStyles from "components/sharedStyles/baseSearchStyles";

const useStyles = createUseStyles(baseSearchStyles);

export interface ISearchBarProps extends ICommonProps {
    placeholder: string;
    disabled?: boolean;
    value?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    isLoading?: boolean;
}

const SearchBar: React.FC<ISearchBarProps> = (props) => {
    const classes = useStyles();

    const { placeholder, disabled, isLoading, testid, className, ...other } = props;

    return (
        <div className={classNames(classes.container, className)} data-component="SearchBar" data-testid={testid}>
            <input
                disabled={disabled}
                className={classes.input}
                placeholder={placeholder}
                data-testid={`${testid}-input`}
                {...other}
            />
            <Icon className={classes.icon} icon={isLoading ? faSpinner : faSearch} spin={isLoading} />
        </div>
    );
};

export default SearchBar;
