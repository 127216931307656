import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";

import { getEmbeddedProjectReport } from "api/reportingApi";
import useAppDispatch from "hooks/useAppDispatch";
import { buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { resetLayout, setBack, setPageTitle } from "store/layoutSlice";
import EmbeddedReportResponseDto from "types/dtos/reports/EmbeddedReportResponseDto";
import ReportCode from "types/enums/reports/ReportCode";

const useStyles = createUseStyles({
    reportContainer: {
        width: "100%",
        height: "80vh",
    },
});

interface IScoreSummaryReport {
    projectUuid: string;
}

const ScoreSummaryReport: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const params = useParams();
    const { projectUuid } = params as unknown as IScoreSummaryReport;

    const [report, setReport] = useState<EmbeddedReportResponseDto>();

    useEffect(() => {
        dispatch(resetLayout());
        dispatch(setPageTitle(t("reports.title")));
        dispatch(
            setBack({
                route: buildRoute(routes.projects.awardSupplier, { projectUuid }),
            }),
        );
    }, [t, dispatch, projectUuid]);

    const fetchEmbeddedProjectReport = () => {
        getEmbeddedProjectReport(projectUuid, ReportCode.ScoreSummary).then((embeddedReport) => {
            setReport(embeddedReport.data);
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => fetchEmbeddedProjectReport(), []);

    if (!report) return null;

    const keyString = report.reportName.replace(/\s+/g, "");

    return (
        <PowerBIEmbed
            key={keyString}
            embedConfig={{
                type: "report",
                id: report.reportId,
                embedUrl: report.embedUrl,
                accessToken: report.token,
                tokenType: models.TokenType.Embed,
                settings: {
                    panes: {
                        filters: {
                            expanded: false,
                            visible: false,
                        },
                    },
                    layoutType: models.LayoutType.Master,
                },
            }}
            cssClassName={classes.reportContainer}
        />
    );
};

export default ScoreSummaryReport;
