import baseApi from "store/api/baseApi";
import { ListCountryTypesResponseDto } from "types/dtos/company/referenceData/ListCountryTypesResponseDto";
import { ListReferenceDataResponseDto } from "types/dtos/company/referenceData/ListReferenceDataResponseDto";
import GeographicalLocationTypeDto from "types/dtos/company/vettingInformation/GeographicalLocationTypeDto";
import GeographicalLocationTypesResponseDto from "types/dtos/company/vettingInformation/GeographicalLocationTypesResponseDto";

const referenceDataApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getCountries: build.query<ListCountryTypesResponseDto, undefined>({
            query: () => ({ url: "/companies/reference-data/country-types", method: "GET" }),
        }),
        searchGeographicLocations: build.query<GeographicalLocationTypeDto[], string>({
            query: (searchQuery) => ({
                url: `/companies/reference-data/geographic-location-types`,
                method: "GET",
                params: {
                    searchQuery,
                },
            }),
            transformResponse: (response: GeographicalLocationTypesResponseDto) => response.geographicLocationTypes,
        }),
        getCompanySizeTypes: build.query<ListReferenceDataResponseDto, undefined>({
            query: () => ({
                url: "/companies/reference-data",
                method: "GET",
                params: { referenceDataType: "SizeType" },
            }),
        }),
        getIndustryTypes: build.query<ListReferenceDataResponseDto, undefined>({
            query: () => ({
                url: "/companies/reference-data",
                method: "GET",
                params: { referenceDataType: "IndustryType" },
            }),
        }),
        getInsuranceTypes: build.query<ListReferenceDataResponseDto, undefined>({
            query: () => ({
                url: "/companies/reference-data",
                method: "GET",
                params: { referenceDataType: "InsuranceType" },
            }),
        }),
        getFinancialTurnoverTypes: build.query<ListReferenceDataResponseDto, undefined>({
            query: () => ({
                url: "/companies/reference-data",
                method: "GET",
                params: { referenceDataType: "FinancialTurnoverType" },
            }),
        }),
        getAccreditationTypes: build.query<ListReferenceDataResponseDto, undefined>({
            query: () => ({
                url: "/companies/reference-data",
                method: "GET",
                params: { referenceDataType: "AccreditationType" },
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useLazyGetCompanySizeTypesQuery,
    useLazyGetCountriesQuery,
    useGetCountriesQuery,
    useGetIndustryTypesQuery,
    useLazyGetIndustryTypesQuery,
    useGetFinancialTurnoverTypesQuery,
    useLazySearchGeographicLocationsQuery,
    useGetAccreditationTypesQuery,
    useGetInsuranceTypesQuery,
} = referenceDataApi;

export default referenceDataApi;
