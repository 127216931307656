import React from "react";
import { Route, Routes } from "react-router-dom";

import Callback from "features/auth/Callback";
import PublicRoute from "features/auth/PublicRoute";
import SupplierFeedback from "features/feedback/emailsurveys/SupplierFeedback";
import ExpressionOfInterestContainer from "features/project/expressionOfInterest/ExpressionOfInterestContainer";
import ExpressionOfInterestSuccess from "features/project/expressionOfInterest/ExpressionOfInterestSuccess";
import ErrorDisplay from "features/shared/ErrorDisplay";
import NotFound from "features/shared/NotFound";
import SystemStatusContainer from "features/systemStatus/SystemStatusContainer";
import ActivateUser from "features/user/ActivateUser";
import routes from "routes/routePaths/RoutePaths";
import EnvironmentVariables from "config/EnvironmentVariables";

const PublicRouter: React.FC = () => {
    return (
        <Routes>
            <Route path="" element={<PublicRoute />}>
                <Route path={routes.public.activateUser.path} element={<ActivateUser />} />
                <Route path={routes.public.supplierFeedback.path} element={<SupplierFeedback />} />
                <Route path={routes.public.expressionOfInterest.path} element={<ExpressionOfInterestContainer />} />
                <Route
                    path={routes.public.expressionOfInterestSuccess.path}
                    element={<ExpressionOfInterestSuccess />}
                />
                <Route path={routes.public.systemStatus.path} element={<SystemStatusContainer />} />
                <Route
                    path={routes.public.userGuide.path}
                    Component={() => {
                        window.location.href = `${EnvironmentVariables.HelpUrl}`;
                        return null;
                    }}
                />
            </Route>
            <Route path={routes.public.error.path} element={<ErrorDisplay />} />
            <Route path={routes.public.callback.path} element={<Callback />} />
            <Route path={routes.public.notFound.path} element={<NotFound />} />
        </Routes>
    );
};

export default PublicRouter;
