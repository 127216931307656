import { useToaster, htmlService } from "@maistro/components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { listAllProjectFiles } from "api/fileApi";
import { getSectionTitle } from "features/project/helpers/sectionHelpers";
import useProject from "features/project/hooks/useProject";
import useProjectClarificationQuestions from "features/project/hooks/useProjectClarificationQuestions";
import useProjectFiles from "features/project/hooks/useProjectFiles";
import { useTenderCriteria } from "features/project/hooks/useTenderCriteria";
import DocumentManagementDisplay, {
    FileAndText,
} from "features/project/tenderCriteria/documentManagement/DocumentManagementDisplay";
import useAppDispatch from "hooks/useAppDispatch";
import { buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { setBack, setPageTitle, resetLayout } from "store/layoutSlice";
import FileDto from "types/dtos/files/FileDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import EntityType from "types/enums/questions/EntityType";

interface IProjectParams {
    projectUuid: string;
}

const DocumentManagementContainer: React.FC = () => {
    const [files, setFiles] = useState<FileDto[]>([]);
    const [tenderFiles, setTenderFiles] = useState<FileAndText[]>([]);
    const [clarificationFiles, setClarificationFiles] = useState<FileAndText[]>([]);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const toast = useToaster();
    const params = useParams();
    const { projectUuid } = params as unknown as IProjectParams;

    const { projectInformation } = useProject();
    const {
        isArchiving,
        isArchiveAvailable,
        createArchive,
        downloadArchive,
        projectHasFilesOfAnyKind,
        isFetchingFiles,
    } = useProjectFiles(projectUuid);
    const { sections, questions: tenderCriteriaQuestions } = useTenderCriteria({ projectUuid });
    const { clarificationQuestions } = useProjectClarificationQuestions(projectUuid);

    useEffect(() => {
        dispatch(
            setBack({
                route: buildRoute(routes.projects.tenderSummary, { projectUuid }),
            }),
        );
        dispatch(setPageTitle(t("fileUpload.archive.documents")));

        return () => {
            dispatch(resetLayout());
        };
    }, [dispatch, projectInformation, projectUuid, t]);

    useEffect(() => {
        const getFiles = async () => {
            const filesResponse = await listAllProjectFiles(projectUuid);
            if (filesResponse.data instanceof TransactionErrorDto) {
                toast.error(t("fileUpload.fetchFilesError"));
                return;
            }

            const fileDtos: FileDto[] = filesResponse.data.projectMedia.map((dto) => ({
                fileName: dto.fileName,
                fileId: dto.fileUuid,
                fileExtension: dto.fileExtension,
                uploadedDateTime: dto.uploadedOn,
                uploadedUser: {
                    id: dto.uploadedByUserId,
                    value: dto.linkedCompanyName,
                },
                newUpload: false,
                linkedCompanyUuid: dto.linkedCompanyUuid,
                linkedCompanyName: dto.linkedCompanyName,
                entityType: dto.entityType,
                entityUuid: dto.entityUuid,
            }));
            setFiles(fileDtos);
        };

        if (!files.length) {
            getFiles();
        }
    }, [files.length, projectUuid, t, toast]);

    useEffect(() => {
        const getQuestionData = (questionsToFilter: QuestionResponseDto[]) => {
            const questionsWithFiles = questionsToFilter.filter(
                (y) => y.hasAttachments || y.answers.some((z) => z.hasAttachments),
            );

            const filesWithQuestionText: FileAndText[] = [];

            questionsWithFiles.forEach((q) => {
                const section = sections.find((x) => x.id === q.entityUuid);

                filesWithQuestionText.push({
                    id: q.questionUuid,
                    text: htmlService.convertHtmlToString(q.questionText),
                    sectionName: section ? getSectionTitle(section) : "",
                    askedBy: q.askingCompanyName,
                    askedByUuid: q.companyUuid,
                    files: files.filter((f) => f.entityUuid === q.questionUuid),
                    questionType: q.questionType,
                });
            });
            return filesWithQuestionText;
        };

        setTenderFiles(getQuestionData(tenderCriteriaQuestions));

        if (clarificationQuestions) {
            setClarificationFiles(getQuestionData(clarificationQuestions));
        }
    }, [clarificationQuestions, files, tenderCriteriaQuestions, sections, tenderFiles.length]);

    if (isFetchingFiles) {
        return null;
    }

    return (
        <DocumentManagementDisplay
            projectInformation={projectInformation}
            projectHasFiles={projectHasFilesOfAnyKind}
            isArchiving={isArchiving}
            isArchiveAvailable={isArchiveAvailable}
            createArchive={createArchive}
            downloadArchive={downloadArchive}
            projectFiles={files.filter((x) => x.entityType === EntityType.Project)}
            tenderFiles={tenderFiles}
            clarificationFiles={clarificationFiles}
            isFetchingFiles={isFetchingFiles}
        />
    );
};

export default DocumentManagementContainer;
