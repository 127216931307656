import { AxiosResponse } from "axios";

import { createAxiosInstance } from "api/commonApi";
import env from "config/EnvironmentVariables";
import { ListGuidedQuestionsResponseDto } from "types/dtos/questions/ListGuidedQuestionsResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/projects";

// eslint-disable-next-line import/prefer-default-export
export const listGuidedQuestions = (
    projectUuid: string,
): Promise<AxiosResponse<ListGuidedQuestionsResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/guided-questions`;
    return api.get(url);
};
