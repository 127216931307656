import { CategoriesField, Copy } from "@maistro/components";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { ICategoryProps } from "components/shared";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    copy: {
        marginTop: theme.spacing.small,
        marginBottom: theme.spacing.xLarge,
    },
}));

interface ICompanyServicesDisplayProps {
    fetchTaxonomyOptions: (searchParameter: string) => Promise<ICategoryProps[]>;
    setIsDirty: (isDirty: boolean) => void;
    setIsValid: (isValid: boolean) => void;
}

const CompanyServicesDisplay: React.FC<ICompanyServicesDisplayProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { fetchTaxonomyOptions, setIsDirty, setIsValid } = props;

    const { dirty, isValid } = useFormikContext();

    useEffect(() => {
        setIsDirty(dirty);
        setIsValid(isValid);
    }, [dirty, isValid, setIsDirty, setIsValid]);

    return (
        <>
            <div className={classes.copy}>
                <Copy>{t("companyServices.subTitle")}</Copy>
            </div>

            <CategoriesField
                label={t("companyServices.services.title")}
                tooltip={t("companyServices.tooltip")}
                name="companyServices"
                onSearch={fetchTaxonomyOptions}
                testid="company-services"
                placeholder="Type..."
            />
            <Copy className={classes.copy}>{t("companyServices.missingService")}</Copy>
        </>
    );
};

export default CompanyServicesDisplay;
