import { getClientVersion, getIdentityStatus, getOrchestrationStatus } from "api/systemStatusApi";
import { Loading } from "components";
import env from "config/EnvironmentVariables";
import SystemStatusDisplay from "features/systemStatus/SystemStatusDisplay";
import useAppDispatch from "hooks/useAppDispatch";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "store/layoutSlice";
import ConnectivityStatusDto from "types/dtos/systemStatus/ConnectivityStatusDto";
import { SystemStatusCollectionDto } from "types/dtos/systemStatus/SystemStatusCollectionDto";

const SystemStatusContainer: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [clientVersion, setClientVersion] = useState("Unknown");
    const [connectivityStatus, setConnectivityStatus] = useState<Array<ConnectivityStatusDto>>([]);
    const [servicesStatus, setServicesStatus] = useState<SystemStatusCollectionDto | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch(setPageTitle(t("systemStatus.pageTitle")));
    }, [t, dispatch]);

    useEffect(() => {
        setClientVersion(getClientVersion());

        const statusChecks: Promise<void>[] = [];
        const connectionResults: Array<ConnectivityStatusDto> = [];

        statusChecks.push(
            getOrchestrationStatus().then((response) => {
                connectionResults.push({
                    name: "Orchestration",
                    url: env.OrchestrationApiUrl,
                    connected: response.status === 200,
                });

                if (response.status === 200) {
                    setServicesStatus(response.data.statuses);
                }
            }),
        );

        statusChecks.push(
            getIdentityStatus().then((response) => {
                connectionResults.push({
                    name: "Identity",
                    url: env.IdentityServerUrl,
                    connected: response.status === 200,
                });
            }),
        );

        Promise.all(statusChecks).then(() => {
            setConnectivityStatus(connectionResults);
            setIsLoading(false);
        });
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    return <SystemStatusDisplay client={clientVersion} services={servicesStatus} connectivity={connectivityStatus} />;
};

export default SystemStatusContainer;
