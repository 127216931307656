export enum CompanySizeV2 {
    None = "None",
    Micro = "Micro",
    Small = "Small",
    Medium = "Medium",
    Large = "Large",
}

export const getCompanySizeRange = (size: CompanySizeV2): string => {
    switch (size) {
        case CompanySizeV2.Large:
            return "249+";
        case CompanySizeV2.Medium:
            return "50 - 249";
        case CompanySizeV2.Small:
            return "10 - 49";
        case CompanySizeV2.Micro:
            return "1 - 9";
        default:
            return "0";
    }
};
