import { faBell } from "@fortawesome/pro-regular-svg-icons";
import { Badge, Copy, Heading, IMeatballMenuItem, Icon, MeatballMenu, htmlService } from "@maistro/components";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { updateNotification } from "api/notificationsApi";
import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";
import { NotificationDto } from "types/dtos/notifications/NotificationDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    card: {
        display: "flex",
        gap: theme.spacing.medium,
        padding: theme.spacing.medium,
        borderRadius: theme.spacing.small,

        "&:hover": {
            backgroundColor: theme.colors.lightGray,
        },
    },

    information: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.xSmall,
        flexGrow: 1,
        cursor: "pointer",
    },

    reduceLineHeight: {
        lineHeight: "18px",
    },

    relative: {
        position: "relative",
    },
}));

interface INotificationCardProps extends ICommonProps {
    notification: NotificationDto;
    goToAction: (notification: NotificationDto) => void;
}

const NotificationCard: React.FC<INotificationCardProps> = (props) => {
    const classes = useStyles();

    const { notification, testid, goToAction } = props;
    const { notificationId, isRead, isArchived, title, body, additionalInformation } = notification;
    const { t } = useTranslation();

    const buildMenuItems = (): IMeatballMenuItem[] => {
        const menuItems: IMeatballMenuItem[] = [];
        if (!isRead) {
            menuItems.push({
                label: t("notifications.actions.read"),
                action: () => {
                    updateNotification({ notificationId, markAsRead: true });
                },
            });
        }
        if (isRead) {
            menuItems.push({
                label: t("notifications.actions.unread"),
                action: () => {
                    updateNotification({ notificationId, markAsUnread: true });
                },
            });
        }
        if (!isArchived) {
            menuItems.push({
                label: t("notifications.actions.archive"),
                action: () => {
                    updateNotification({ notificationId, archive: true });
                },
            });
        }
        return menuItems;
    };

    const markAsRead = () => {
        if (isRead) return;

        updateNotification({ notificationId, markAsRead: true });
    };

    return (
        <div
            className={classNames(classes.card, props.className)}
            data-component="NotificationCard"
            data-testid={testid}
        >
            <div className={classes.relative}>
                <Icon icon={faBell} onClick={markAsRead} />
                {!isRead && <Badge size="xSmall" />}
            </div>
            <div className={classes.information} onClick={() => goToAction(notification)}>
                <Heading variant="h5" className={classes.reduceLineHeight}>
                    {t(title, { projectName: notification.data.projectName })}
                </Heading>
                <Heading variant="h6">{body}</Heading>
                {additionalInformation && (
                    <Copy variant="sublabel">{htmlService.convertHtmlToString(additionalInformation)}</Copy>
                )}
            </div>
            <MeatballMenu menuItems={buildMenuItems()} testid={`${testid}-options`} />
        </div>
    );
};

export default NotificationCard;
