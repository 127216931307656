import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { t } from "i18next";

import { getSupplierSubmission } from "api/project/projectSubmissionsApi";
import { IProjectResponseInformation } from "features/project/types";
import type { AppStore } from "store/store";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import CurrencyType from "types/enums/CurrencyType";
import ProjectType from "types/enums/projects/ProjectType";

export interface IProjectResponseState {
    isFetching: boolean;
    projectResponseInformation: IProjectResponseInformation;
    fetchingErrorMessage: string;
}

const defaultProjectResponseInformation = {
    projectUuid: "",
    responseUuid: "",
    buyerCompanyUuid: "",
    budgetCurrency: CurrencyType.GBP,
    status: "",
    projectStatus: "",
    buyerBudget: 0,
    timescale: "",
    projectName: "",
    manager: "",
    description: "",
    projectType: ProjectType.Quote,
    tenderResponseDeadline: null as unknown as Date,
    clarificationQuestionDeadline: null as unknown as Date,
    targetProjectStartDate: null as unknown as Date,
    targetProjectCompleteDate: null as unknown as Date,
} as IProjectResponseInformation;

interface IRejectedValue {
    fetchingErrorMessage: string;
}

interface IFetchProjectResponseRequest {
    projectUuid?: string;
    responseUuid?: string;
}

export const fetchProjectResponse = createAsyncThunk<
    IProjectResponseInformation,
    IFetchProjectResponseRequest | undefined,
    {
        state: AppStore;
        rejectValue: IRejectedValue;
    }
>("projectResponse/fetchProjectResponse", async (request, { rejectWithValue, getState }) => {
    const state = getState();
    const projectUuidToFetch =
        request?.projectUuid || state.projectResponseState.projectResponseInformation.projectUuid;
    const responseUuidToFetch =
        request?.responseUuid || state.projectResponseState.projectResponseInformation.responseUuid;

    if (!projectUuidToFetch || !responseUuidToFetch) {
        return rejectWithValue({
            fetchingErrorMessage: t("projectResponse.api.errorFetchingProjectResponse"),
        });
    }

    const submissionResponse = await getSupplierSubmission(projectUuidToFetch, responseUuidToFetch);
    if (submissionResponse.data instanceof TransactionErrorDto || submissionResponse.status !== 200) {
        return rejectWithValue({
            fetchingErrorMessage: t("projectResponse.api.errorFetchingProjectResponse"),
        });
    }

    return {
        projectUuid: submissionResponse.data.projectUuid,
        responseUuid: submissionResponse.data._embedded.responseUuid,
        buyerCompanyUuid: submissionResponse.data.buyerCompanyUuid,
        budgetCurrency:
            submissionResponse.data.supplierPricing.simplePrice.ccy ?? defaultProjectResponseInformation.budgetCurrency,
        status: submissionResponse.data.status,
        projectStatus: submissionResponse.data.projectStatus,
        buyerBudget: submissionResponse.data.buyerBudget,
        showBudgetToSupplier: submissionResponse.data.showBudgetToSupplier,
        budgetType: submissionResponse.data.budgetType,
        timescale: submissionResponse.data.projectTimescale,
        projectName: submissionResponse.data._included.projectName,
        companyName: submissionResponse.data._included.companyName,
        manager: submissionResponse.data._included.nameOfManager,
        description: submissionResponse.data._included.projectDescription,
        projectType: submissionResponse.data._included.projectType,
        tenderResponseDeadline: submissionResponse.data._included.tenderResponseDeadline,
        clarificationQuestionDeadline: submissionResponse.data._included.clarificationQuestionDeadline,
        targetProjectStartDate: submissionResponse.data._included.targetProjectStartDate,
        targetProjectCompleteDate: submissionResponse.data._included.targetProjectCompleteDate,
        supplierPrice: submissionResponse.data.supplierPricing.simplePrice.price,
        supplierNotes: submissionResponse.data.supplierNotes,
        buyerPrice: submissionResponse.data.buyerPricing.simplePrice.price,
        buyerNotes: submissionResponse.data.buyerNotes,
    };
});

const initialState: IProjectResponseState = {
    isFetching: true,
    projectResponseInformation: defaultProjectResponseInformation,
    fetchingErrorMessage: "",
};

export const projectResponseSlice = createSlice({
    name: "projectResponse",
    initialState,
    reducers: {
        clearProjectResponseInformation: () => {
            return {
                ...initialState,
            };
        },
        setProjectResponseInformation: {
            reducer: (state, action: PayloadAction<IProjectResponseInformation>) => {
                const response = action.payload;
                const responseWithIncludedData = {
                    ...response,
                    projectName: response.projectName || state.projectResponseInformation.projectName,
                    companyName: response.companyName || state.projectResponseInformation.companyName,
                    manager: response.manager || state.projectResponseInformation.manager,
                    description: response.description || state.projectResponseInformation.description,
                    projectType: response.projectType ?? state.projectResponseInformation.projectType,
                    tenderResponseDeadline:
                        response.tenderResponseDeadline ?? state.projectResponseInformation.tenderResponseDeadline,
                    clarificationQuestionDeadline:
                        response.clarificationQuestionDeadline ??
                        state.projectResponseInformation.clarificationQuestionDeadline,
                    targetProjectStartDate:
                        response.targetProjectStartDate ?? state.projectResponseInformation.targetProjectStartDate,
                    targetProjectCompleteDate:
                        response.targetProjectCompleteDate ??
                        state.projectResponseInformation.targetProjectCompleteDate,
                };

                state.projectResponseInformation = responseWithIncludedData;
            },
            prepare: (responseDto: SupplierSubmissionResponseDto) => {
                return {
                    payload: {
                        projectUuid: responseDto.projectUuid,
                        responseUuid: responseDto._embedded.responseUuid,
                        buyerCompanyUuid: responseDto.buyerCompanyUuid,
                        budgetCurrency: responseDto.supplierPricing.simplePrice.ccy,
                        status: responseDto.status,
                        projectStatus: responseDto.projectStatus,
                        buyerBudget: responseDto.buyerBudget,
                        showBudgetToSupplier: responseDto.showBudgetToSupplier,
                        budgetType: responseDto.budgetType,
                        timescale: responseDto.projectTimescale,
                        projectName: responseDto._included.projectName,
                        companyName: responseDto._included.companyName,
                        manager: responseDto._included.nameOfManager,
                        description: responseDto._included.projectDescription,
                        projectType: responseDto._included.projectType,
                        tenderResponseDeadline: responseDto._included.tenderResponseDeadline,
                        clarificationQuestionDeadline: responseDto._included.clarificationQuestionDeadline,
                        targetProjectStartDate: responseDto._included.targetProjectStartDate,
                        targetProjectCompleteDate: responseDto._included.targetProjectCompleteDate,
                        supplierPrice: responseDto.supplierPricing.simplePrice.price,
                        supplierNotes: responseDto.supplierNotes,
                        buyerPrice: responseDto.buyerPricing.simplePrice.price,
                        buyerNotes: responseDto.buyerNotes,
                    },
                };
            },
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProjectResponse.pending, (state) => {
            state.isFetching = true;
            state.fetchingErrorMessage = "";
        });
        builder.addCase(fetchProjectResponse.rejected, (state, action) => {
            state.isFetching = false;
            if (action?.payload?.fetchingErrorMessage) {
                state.fetchingErrorMessage = action?.payload?.fetchingErrorMessage;
            }
            state.projectResponseInformation = defaultProjectResponseInformation;
        });
        builder.addCase(fetchProjectResponse.fulfilled, (state, action) => {
            state.isFetching = false;
            state.projectResponseInformation = action.payload;
        });
    },
});

const { actions, reducer } = projectResponseSlice;

export const { clearProjectResponseInformation, setProjectResponseInformation } = actions;

export default reducer;
