import React from "react";
import { createUseStyles } from "react-jss";
import { ITheme } from "styles/themes/types";
import { ZINDEX_LOADING_OVERLAY } from "types/consts/zIndexConstants";
import { ICommonProps } from "components/shared";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading/Loading";

const useStyles = createUseStyles((theme: ITheme) => ({
    loadingContainer: {
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: theme.colors.lightGray,
        zIndex: ZINDEX_LOADING_OVERLAY,
    },
    relative: {
        position: "relative",
    },
    padding: {
        paddingTop: theme.spacing.xLarge,
    },
}));

interface ILoaderProps extends ICommonProps {
    message?: string;
}

const Loader: React.FC<ILoaderProps> = ({ message }) => {
    const classes = useStyles();

    const { t } = useTranslation();

    return (
        <div className={classes.relative}>
            <div className={classes.loadingContainer} data-testid="loading-container">
                <div className={classes.padding}>
                    <Loading size="medium" text={message ?? t("common.loading")} />
                </div>
            </div>
        </div>
    );
};

export default Loader;
