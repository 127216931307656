import { Button, CheckboxesField, FieldLabel, SelectField, TextButton } from "@maistro/components";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { SearchBar } from "components";
import { ICompanyListOptions } from "features/company/list/filter/hooks/useCompanyListFilters";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import { ITheme } from "styles/themes/types";
import { CompanyListFilterDto } from "types/dtos/company/CompanyListFilterDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

const useStyles = createUseStyles((theme: ITheme) => ({
    h2: {
        ...theme.typography.headings.h2,
        marginBottom: theme.spacing.medium,
    },
    container: {
        color: theme.typography.base.color,
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
    inputContainer: {
        width: "100%",
    },
    searchBarContainer: {
        width: "100%",
    },
    searchBar: {
        marginTop: theme.spacing.xSmall,
    },
    buttonControl: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.small,
    },
    supplierListCheckbox: {
        marginTop: theme.spacing.xSmall,
    },
    supplierListTitle: {
        fontWeight: 600,
    },
}));

interface ICompanyListFilterFormProps {
    currentFilters: CompanyListFilterDto;
    submitFilters: (filters: CompanyListFilterDto) => void;
    resetFilters: () => void;
    options: ICompanyListOptions;
}

const CompanyListFilterForm: React.FC<ICompanyListFilterFormProps> = ({
    currentFilters,
    submitFilters,
    resetFilters,
    options,
}) => {
    const classes = useStyles();

    const [filters, setFilters] = useState<CompanyListFilterDto>(currentFilters);
    const { userHasPermission } = useCurrentUser();
    const isGlobalAdmin = userHasPermission(PermissionsEnum.ManageSupport);

    const { t } = useTranslation();

    const onSubmit = (newFilters: CompanyListFilterDto) => {
        submitFilters(newFilters);
    };

    return (
        <Formik
            initialValues={{
                ...filters,
                industryTypes: filters.industryTypes.map((option) => option.value),
            }}
            onSubmit={(values) =>
                onSubmit({
                    ...values,
                    industryTypes: filters.industryTypes,
                    searchByEmail: filters.searchByEmail,
                })
            }
            onReset={() => resetFilters()}
        >
            <Form>
                <h2 className={classes.h2}>{t("companyList.companies.filters.title")}</h2>
                <div className={classes.container}>
                    <div className={classes.inputContainer}>
                        <SelectField
                            name="industryTypes"
                            label={t("companyList.companies.filters.industryTypes")}
                            options={options.industryTypes}
                            multiple
                            disableSearch
                            withoutValidation
                            onChange={(_, industryTypes) =>
                                setFilters((prevState) => ({
                                    ...prevState,
                                    industryTypes,
                                }))
                            }
                            testid="filter-industry-types"
                        />
                    </div>
                    {isGlobalAdmin && (
                        <div className={classes.searchBarContainer}>
                            <FieldLabel
                                label={t("companyList.companies.filters.userEmail")}
                                name="searchByEmail"
                                testid="filter-email-search"
                            />
                            <SearchBar
                                className={classes.searchBar}
                                placeholder={t("companyList.companies.searchByUserEmail")}
                                value={filters.searchByEmail}
                                onChange={(e) => {
                                    setFilters((prevFilters) => ({
                                        ...prevFilters,
                                        searchByEmail: e.target.value,
                                    }));
                                }}
                            />
                        </div>
                    )}
                    <div>
                        <CheckboxesField
                            name="companyTypes"
                            options={options.companyTypes}
                            label={t("companyList.companies.filters.companyTypes")}
                            testid="filter-company-types"
                        />
                    </div>
                    <div>
                        <CheckboxesField
                            name="statuses"
                            options={options.statuses}
                            label={t("companyList.companies.filters.status")}
                            testid="filter-statuses"
                            tooltip={t("companyList.companies.tooltips.status")}
                        />
                    </div>
                    <div className={classes.buttonControl}>
                        <Button
                            testid="filter-submit-button"
                            type="submit"
                            label={t("common.viewResults")}
                            onClick={() =>
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: `Companies - Filter Drawer - Submit`,
                                })
                            }
                        />
                        <TextButton
                            testid="filter-reset-button"
                            type="reset"
                            label={t("common.resetFilters")}
                            onClick={() =>
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: `Companies - Filter Drawer - Reset`,
                                })
                            }
                        />
                    </div>
                </div>
            </Form>
        </Formik>
    );
};

export default CompanyListFilterForm;
