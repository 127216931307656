import React, { useCallback, useEffect } from "react";
import useCurrentUser from "hooks/useCurrentUser";
import useReduxSelector from "hooks/useReduxSelector";
import userManager from "features/auth/oidc/userManager";
import { logoffUser } from "api/usersApi";
import { LogoffUserRequestDto } from "types/dtos/users/LogoffUserRequestDto";
import env from "config/EnvironmentVariables";

const Logout: React.FC = () => {
    const { myUuid } = useCurrentUser();
    const { previousAccessTokenRaw } = useReduxSelector((state) => state.authenticationState);

    const handleLogOut = useCallback(() => {
        logoffUser({ userUuid: myUuid, clientAccessToken: previousAccessTokenRaw } as LogoffUserRequestDto).then(() => {
            userManager.signoutRedirect({
                post_logout_redirect_uri: env.PortalUrl,
            });
        });
    }, [myUuid, previousAccessTokenRaw]);

    useEffect(() => {
        handleLogOut();
    }, [handleLogOut]);

    return null;
};

export default Logout;
