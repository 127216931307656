import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import type { AppStore } from "store/store";
import { ListPlatformFeaturesDto } from "types/dtos/platform/ListPlatformFeaturesDto";
import { listPlatformFeatures } from "api/platformApi";
import { PlatformFeatureDto } from "types/dtos/platform/PlatformFeatureDto";

interface ListPlatformFeaturesRequest {}

export const fetchPlatformFeatures = createAsyncThunk<
    ListPlatformFeaturesDto,
    ListPlatformFeaturesRequest,
    { rejectValue: TransactionErrorDto }
>("platform/fetchPlatformFeatures", async (_, thunkAPI) => {
    const response = await listPlatformFeatures();

    if (response.data instanceof TransactionErrorDto) {
        return thunkAPI.rejectWithValue(response.data);
    }

    return response.data;
});

export interface IPlatformState {
    features: PlatformFeatureDto[];
    isLoading: boolean;
}

const initialState: IPlatformState = {
    features: [],
    isLoading: true,
};

export const platformSlice = createSlice({
    name: "platform",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchPlatformFeatures.fulfilled, (state, { payload }) => {
            state.features = payload.items;
            state.isLoading = false;
        });
    },
});

export const selectFeatures = (state: AppStore) => state.platformState.features;

export default platformSlice.reducer;
