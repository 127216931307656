import { faBuildings, faLayerGroup, faThumbsUp, faUser } from "@fortawesome/pro-regular-svg-icons";
import { Card, Grid } from "@maistro/components";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useAppDispatch from "hooks/useAppDispatch";
import useCompany from "hooks/useCompany";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { resetLayout, setBack, setPageTitle } from "store/layoutSlice";
import FeatureToggle from "types/enums/companies/FeatureToggle";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

const Settings: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { myCompanyUuid, userHasPermission } = useCurrentUser();
    const { companyHasFeature } = useCompany();
    const showUserGroups = userHasPermission(PermissionsEnum.CanReadUserGroup, myCompanyUuid);
    const isGlobalAdmin = userHasPermission(PermissionsEnum.ManageSupport);
    const hasApprovalsEnabled = companyHasFeature(FeatureToggle.Approvals);

    useEffect(() => {
        dispatch(setPageTitle(t("settings.title")));
        dispatch(
            setBack({
                route: routes.common.dashboard,
            }),
        );

        return () => {
            dispatch(resetLayout());
        };
    }, [dispatch, t]);

    const renderCompanyCard = useMemo(() => {
        if (isGlobalAdmin) {
            return (
                <>
                    <Card
                        title={t("settings.companies.title")}
                        text={t("settings.companies.text")}
                        icon={faBuildings}
                        onClick={() => {
                            ReactGA.event({
                                category: ga4Category.Button,
                                action: ga4Action.ButtonClick,
                                label: "Settings - Companies Card",
                            });
                            navigate(routes.common.companies.path);
                        }}
                        testid="companies-card"
                    />
                    <Card
                        title={t("settings.adminUsers.title")}
                        text={t("settings.adminUsers.text")}
                        icon={faUser}
                        onClick={() => {
                            ReactGA.event({
                                category: ga4Category.Button,
                                action: ga4Action.ButtonClick,
                                label: "Settings - Users Card",
                            });
                            navigate(routes.common.adminUsers.path);
                        }}
                        testid="admin-users-card"
                    />
                </>
            );
        }

        return null;
    }, [navigate, isGlobalAdmin, t]);

    return (
        <Grid columns={3} width={340} centered testid="settings-grid">
            <React.Fragment>
                {renderCompanyCard}
                {hasApprovalsEnabled && (
                    <Card
                        title={t("settings.approvals.title")}
                        text={t("settings.approvals.text")}
                        icon={faThumbsUp}
                        onClick={() =>
                            navigate(buildPath(routes.company.approvals, { companyUuid: myCompanyUuid ?? "" }))
                        }
                        testid="approvals-card"
                    />
                )}
                {hasApprovalsEnabled && showUserGroups && (
                    <Card
                        title={t("settings.userGroups.title")}
                        text={t("settings.userGroups.text")}
                        icon={faLayerGroup}
                        onClick={() =>
                            navigate(buildPath(routes.company.userGroups, { companyUuid: myCompanyUuid ?? "" }))
                        }
                        testid="user-groups-card"
                    />
                )}
            </React.Fragment>
        </Grid>
    );
};

export default Settings;
