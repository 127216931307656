import React from "react";

import { IStepperChecklistItem } from "store/navigationSlice";
import useScreenLayout from "hooks/useScreenLayout";
import { createUseStyles, useTheme } from "react-jss";
import { ITheme } from "styles/themes/types";
import classnames from "classnames";
import { Icon } from "@maistro/components";
import { faCircle, faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";

const useStyles = createUseStyles((theme: ITheme) => ({
    parentContainer: {
        display: "flex",
        flexDirection: "column",
    },
    label: {
        ...theme.typography.base,
        fontWeight: 800,
        padding: `${theme.spacing.xSmall}px ${theme.spacing.medium}px`,
    },
    subLabel: {
        fontSize: 11,
        fontWeight: 600,
        display: "flex",
        padding: `${theme.spacing.xSmall}px ${theme.spacing.large}px`,

        "&:hover": {
            backgroundColor: theme.colors.lightGray,
        },
    },
    icon: {
        marginRight: theme.spacing.xSmall,
    },
    defaultIcon: {
        color: theme.colors.medLightGray,
    },
    hr: {
        marginTop: theme.spacing.small,
        marginBottom: theme.spacing.small,
        backgroundColor: theme.colors.medLightGray,
        height: 1,
        border: "none",
    },
    rightIcon: {
        marginLeft: "auto",
        marginRight: theme.spacing.small,
    },
    mobileLink: {
        borderBottom: `1px solid ${theme.colors.gray}`,
        height: 45,
        alignItems: "center",
    },
    mobileLabel: {
        marginTop: theme.spacing.small,
    },
}));

interface DisplayChecklistItemsProps {
    stepperChecklistItems: IStepperChecklistItem[];
    setIsStepperOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DisplayChecklistItems: React.FC<DisplayChecklistItemsProps> = ({ stepperChecklistItems, setIsStepperOpen }) => {
    const classes = useStyles();
    const theme = useTheme<ITheme>();

    const { onMobile } = useScreenLayout();

    return (
        <>
            {stepperChecklistItems.map((stepperItem, index) => (
                <div key={stepperItem.label} className={classes.parentContainer}>
                    {!stepperItem.hideLabel && (
                        <div
                            className={classnames(classes.label, {
                                [classes.mobileLabel]: onMobile && index !== 0,
                            })}
                        >
                            {stepperItem.label}
                        </div>
                    )}
                    {stepperItem.children.map((stepperChild) => (
                        <a
                            key={stepperChild.id}
                            onClick={(e) => {
                                const element = document.getElementById(stepperChild.id);
                                e.preventDefault();
                                if (element) {
                                    window.scrollTo({
                                        behavior: "smooth",
                                        top:
                                            element.getBoundingClientRect().top -
                                            document.body.getBoundingClientRect().top -
                                            theme.layout.navBarHeight,
                                    });
                                }
                                if (onMobile) {
                                    setIsStepperOpen(false);
                                }
                            }}
                            className={classnames(classes.subLabel, {
                                [classes.mobileLink]: onMobile,
                            })}
                            href="/"
                        >
                            {stepperChild.isComplete && (
                                <Icon className={classes.icon} size="xSmall" icon={faCircleCheck} color="success" />
                            )}
                            {!stepperChild.isComplete && (
                                <Icon
                                    className={classnames(classes.icon, classes.defaultIcon)}
                                    size="xSmall"
                                    icon={faCircle}
                                    color="inherit"
                                />
                            )}
                            {stepperChild.label}
                            {onMobile && <Icon className={classes.rightIcon} size="xSmall" icon={faChevronRight} />}
                        </a>
                    ))}
                    {index < stepperChecklistItems.length - 1 && !onMobile && <hr className={classes.hr} />}
                </div>
            ))}
        </>
    );
};

export default DisplayChecklistItems;
