import { SelectField, TextField } from "@maistro/components";
import { Form } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { IOptionProps } from "components/shared";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.large,
    },
}));

interface ICreateUserGroupFormProps {
    companyUsers: IOptionProps[];
}

const CreateUserGroupForm: React.FC<ICreateUserGroupFormProps> = ({ companyUsers }) => {
    const classes = useStyles();

    const { t } = useTranslation();

    return (
        <Form className={classes.form} id="user-group-form">
            <TextField
                name="name"
                label={t("userGroups.create.form.name.label")}
                required
                tooltip={t("userGroups.create.form.name.tooltip")}
                testid="user-group-name"
            />
            <SelectField
                name="memberUuids"
                label={t("userGroups.create.form.members.label")}
                required
                tooltip={t("userGroups.create.form.members.tooltip")}
                options={companyUsers}
                multiple
                testid="user-group-members"
            />
        </Form>
    );
};

export default CreateUserGroupForm;
