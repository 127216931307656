import { LoadingScreen, useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { setScorerScoringComplete } from "api/questions/tenderCriteriaQuestionsApi";
import { Loader } from "components";
import ProjectPreviewDrawer from "features/project/components/ProjectPreviewDrawer";
import useNavTabs from "features/project/hooks/useNavTabs";
import useProject from "features/project/hooks/useProject";
import useProjectFiles from "features/project/hooks/useProjectFiles";
import { useTenderCriteria } from "features/project/hooks/useTenderCriteria";
import { useLayout } from "features/project/tenderCriteria/hooks/useLayout";
import { useTenderPermissions } from "features/project/tenderCriteria/hooks/useTenderPermissions";
import ScoreTenderSummaryDisplay from "features/project/tenderCriteria/scoringSummary/ScoreTenderSummaryDisplay";
import useAppDispatch from "hooks/useAppDispatch";
import useCurrentUser from "hooks/useCurrentUser";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { resetLayout } from "store/layoutSlice";
import { setTenderScoringComplete } from "store/projectSlice";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const ScoreTenderSummaryContainer: React.FC = () => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [isProjectPreviewOpen, setIsProjectPreviewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const toast = useToaster();

    const { buyerTabItems } = useNavTabs();
    const { myUuid: userUuid, userHasProjectPermission } = useCurrentUser();
    const { projectInformation, isFetching: isFetchingProject } = useProject();
    const userIsProjectManager = userUuid === projectInformation.managerUuid;
    const { projectFiles, projectHasFilesOfAnyKind, isFetchingFiles } = useProjectFiles(projectInformation.uuid);
    const {
        questions,
        sections,
        completedContributorScorers,
        totalContributorScorers,
        isScoringCompleteForUser,
        isScoringCompleteForProject,
        downloadTenderResponsesReport,
        isFetching: isFetchingTender,
    } = useTenderCriteria({
        projectUuid: projectInformation.uuid,
    });
    const isScoringComplete = isScoringCompleteForUser || isScoringCompleteForProject;
    const { canAskQuestion, canModerateScores } = useTenderPermissions({
        isScoringComplete,
        projectInformation,
        userHasProjectPermission,
    });
    const displayScoringClosedMessage = !isScoringCompleteForUser && isScoringCompleteForProject;

    const handleDownload = useCallback(() => {
        setIsDownloading(true);
        downloadTenderResponsesReport().finally(() => setIsDownloading(false));
    }, [downloadTenderResponsesReport]);

    useEffect(() => {
        setIsLoading(isFetchingTender || isFetchingProject || isFetchingFiles);
    }, [isFetchingProject, isFetchingTender, isFetchingFiles]);

    const { setLayout } = useLayout({
        canModerateScores,
        canAskQuestion,
        projectInformation,
        handleDownload,
        setIsProjectPreviewOpen,
    });

    useEffect(() => {
        setLayout();
        return () => {
            dispatch(resetLayout());
        };
    }, [buyerTabItems, canAskQuestion, canModerateScores, dispatch, handleDownload, projectInformation, setLayout, t]);

    const completeScoring = async () => {
        if (!projectInformation.uuid) return;

        const response = await setScorerScoringComplete(projectInformation.uuid, userUuid);

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("tenderCriteria.score.api.submitScoresError"));
            return;
        }

        dispatch(
            setTenderScoringComplete({
                isProjectManager: userIsProjectManager,
            }),
        );
        if (userIsProjectManager) {
            navigate(buildPath(routes.projects.awardSupplier, { projectUuid: projectInformation.uuid }));
        } else {
            navigate(buildPath(routes.projects.monitor, { projectUuid: projectInformation.uuid }));
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            {isDownloading && (
                <LoadingScreen
                    title={t("tenderCriteria.score.export.title")}
                    message={t("tenderCriteria.score.export.message")}
                    duration={35000}
                    testid="loading-screen"
                />
            )}
            <ScoreTenderSummaryDisplay
                projectInformation={projectInformation}
                completedContributorScorers={completedContributorScorers}
                totalContributorScorers={totalContributorScorers}
                questions={questions}
                sections={sections}
                completeScoring={completeScoring}
                isProjectManager={userIsProjectManager}
                isScoringComplete={isScoringComplete}
                displayScoringClosedMessage={displayScoringClosedMessage}
                projectHasFiles={projectHasFilesOfAnyKind}
                isScoringCompleteForUser={isScoringCompleteForUser}
            />
            <ProjectPreviewDrawer
                projectFiles={projectFiles}
                isOpen={isProjectPreviewOpen}
                close={() => setIsProjectPreviewOpen(false)}
            />
        </>
    );
};

export default ScoreTenderSummaryContainer;
