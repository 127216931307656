import { Copy, IPaginationState, Pagination, Table } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import useApprovalsConfiguration from "features/company/buyer/approvals/hooks/useApprovalsConfiguration";
import { ITheme } from "styles/themes/types";
import { UserApprovalDto } from "types/dtos/company/approvals/UserApprovalDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    button: {
        margin: `${theme.spacing.medium}px 0`,
    },
    pagination: {
        marginTop: theme.spacing.small,
    },
    table: {
        marginTop: theme.spacing.medium,
    },
}));

interface IApprovalsDisplayProps {
    approvals: UserApprovalDto[];
    paginationState: IPaginationState;
    onInformation: (row: UserApprovalDto) => void;
    onApproval: (row: UserApprovalDto) => void;
    onRejection: (row: UserApprovalDto) => void;
    navigateToPage: (page: number) => void;
}

const ApprovalsDisplay: React.FC<IApprovalsDisplayProps> = ({
    approvals,
    paginationState,
    navigateToPage,
    onInformation,
    onApproval,
    onRejection,
}: IApprovalsDisplayProps) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const { columns } = useApprovalsConfiguration(onInformation, onApproval, onRejection);

    return (
        <>
            <Copy>{t("approvals.subtitle")}</Copy>
            <Table
                className={classes.table}
                columns={columns}
                data={approvals}
                noDataDisplay={t("approvals.table.noApprovals")}
                testid="approvals-list"
            />
            <Pagination
                className={classes.pagination}
                paginationState={paginationState}
                navigateToPage={navigateToPage}
                testid="approvals-pagination"
            />
        </>
    );
};

export default ApprovalsDisplay;
