import React, { useEffect, useMemo } from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";
import Help from "components/Layout/Help/Help";
import Notifications from "components/Layout/Notifications/Notifications";
import useCurrentUser from "hooks/useCurrentUser";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";
import { connectToNotificationsHub, fetchNotifications } from "store/notificationsSlice";
import useAppDispatch from "hooks/useAppDispatch";

const useStyles = createUseStyles((theme: ITheme) => ({
    content: {
        gap: theme.spacing.medium,
        display: "flex",
        height: "100%",
        alignItems: "center",
        color: theme.colors.white,
        paddingRight: theme.spacing.small,
    },

    [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        content: {
            color: theme.colors.primary,
        },
    },
}));

const SecondaryOptions: React.FC<ICommonProps> = ({ className }) => {
    const classes = useStyles();

    const dispatch = useAppDispatch();

    const { userHasPermission } = useCurrentUser();
    const canAccessNotifications = useMemo(
        () => userHasPermission(PermissionsEnum.ReadNotification),
        [userHasPermission],
    );

    useEffect(() => {
        if (canAccessNotifications) {
            dispatch(connectToNotificationsHub());
            dispatch(fetchNotifications({ queryParameters: { includeRead: true } }));
        }
    }, [canAccessNotifications, dispatch]);

    return (
        <div className={classNames(className, classes.content)} data-component="SecondaryOptions">
            {canAccessNotifications && <Notifications />}
            <Help />
        </div>
    );
};

export default SecondaryOptions;
