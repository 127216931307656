import { Alert, Copy, ExpansionPanel, ReadOnlyField } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { Paper } from "components";
import FileList from "components/FileList/FileList";
import { ICommonProps } from "components/shared";
import useProjectFiles from "features/project/hooks/useProjectFiles";
import dateTimeService from "services/dateTimeService";
import { ITheme } from "styles/themes/types";
import { ListDto } from "types/dtos/ListDto";
import { AnswerDto } from "types/dtos/questions/AnswerResponseDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import EntityType from "types/enums/questions/EntityType";
import useCurrentUser from "hooks/useCurrentUser";

const useStyles = createUseStyles((theme: ITheme) => ({
    grid: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
    shownTo: {
        fontSize: 13,
    },
    supplierInfo: {
        ...theme.typography.base,
    },
}));

interface IReadOnlyAnswerDisplayProps extends ICommonProps {
    isQuestionAuthor?: boolean;
    isAnswerAuthor?: boolean;
    question?: QuestionResponseDto;
    projectUuid: string;
    buyerCompanyUuid?: string;
}

const ReadOnlyAnswerDisplay: React.FC<IReadOnlyAnswerDisplayProps> = (props) => {
    const classes = useStyles();

    const { isQuestionAuthor, question, isAnswerAuthor, projectUuid, buyerCompanyUuid } = props;
    const { userIsMaistro } = useCurrentUser();
    const { projectFiles: answerFiles } = useProjectFiles(
        projectUuid,
        EntityType.Answer,
        question?.answers[0]?.answerUuid,
    );

    const { t } = useTranslation();

    if (!question || (question.answers.length < 1 && question.recipients.length < 2)) return null;

    let shownToText = t("clarificationQuestions.isPrivate.options.all");
    if (question.recipientCount < 2) {
        if (isQuestionAuthor || isAnswerAuthor) {
            shownToText = t("clarificationQuestions.isPrivate.options.you");
        } else {
            shownToText = t("clarificationQuestions.isPrivate.options.private");
        }
    }

    const isQuestionRhetorical = question.isRhetorical;

    const displayAnswer = (answer?: AnswerDto, isExpandedLabel = false) => {
        return (
            <div className={classes.grid}>
                <ReadOnlyField
                    label={isExpandedLabel ? undefined : t("clarificationQuestions.answer.label")}
                    value={answer?.answerText}
                    testid="clarification-answer-text"
                />
                <ReadOnlyField value={<FileList files={answerFiles} canDownload />} testid="question-documents" />
                <Copy variant="label">
                    <>
                        {answer?.updatedByUserFullName && <>{answer?.updatedByUserFullName}&emsp;|&emsp;</>}
                        {answer?.respondingCompanyName && <>{answer?.respondingCompanyName}&emsp;|&emsp;</>}
                        {dateTimeService.toShortDateTimeFormat(answer?.createdOn)}
                    </>
                </Copy>
            </div>
        );
    };

    const hasSupplierAnswered = (recipient: ListDto, answers: Array<AnswerDto>) => {
        return answers.find((answer) => answer.companyUuid === recipient.id);
    };

    const displayRecipients = () => {
        return (
            <div className={classes.grid}>
                {isQuestionRhetorical && (
                    <Alert
                        label={t("clarificationQuestions.noResponseRequired")}
                        type="info"
                        testid="no-response-required"
                    />
                )}
                <ReadOnlyField label={t("clarificationQuestions.isPrivate.readonlyLabel")} testid="shown-to" />
                {question.recipients.map((recipient) => (
                    <div className={classes.grid} key={recipient.id}>
                        {!isQuestionRhetorical && (
                            <>
                                <ReadOnlyField label={recipient.value} testid={`supplier-name-${recipient.value}`} />
                                <ExpansionPanel
                                    label={
                                        hasSupplierAnswered(recipient, question.answers)
                                            ? t("clarificationQuestions.answer.label")
                                            : t("clarificationQuestions.awaitingResponse")
                                    }
                                    disabled={!hasSupplierAnswered(recipient, question.answers)}
                                >
                                    {displayAnswer(
                                        question.answers.find((answer) => answer.companyUuid === recipient.id),
                                        true,
                                    )}
                                </ExpansionPanel>
                            </>
                        )}
                        {isQuestionRhetorical && <div className={classes.supplierInfo}>{recipient.value}</div>}
                    </div>
                ))}
            </div>
        );
    };

    if (
        (question.companyUuid !== buyerCompanyUuid && userIsMaistro) ||
        question.recipients.length === 1 ||
        isAnswerAuthor
    ) {
        return (
            <div className={classes.grid}>
                {isQuestionRhetorical && (
                    <Alert
                        label={t("clarificationQuestions.noResponseRequired")}
                        type="info"
                        testid="no-response-required"
                    />
                )}
                {!isQuestionRhetorical && <Paper>{displayAnswer(question.answers[0])}</Paper>}
                <ReadOnlyField
                    label={t("clarificationQuestions.isPrivate.readonlyLabel")}
                    value={<span className={classes.shownTo}>{shownToText}</span>}
                    testid="clarification-answer-is-private"
                />
            </div>
        );
    }
    return displayRecipients();
};

export default ReadOnlyAnswerDisplay;
