interface IColorService {
    toHexWithOpacity: (hexColour: string, opacity: number) => string;
}

const colorService: IColorService = {
    toHexWithOpacity: (hexColour: string, opacity: number) => {
        const hexOpacity = Math.floor(opacity * 255).toString(16);
        return `${hexColour}${hexOpacity}`;
    },
};

export default colorService;
