import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons";
import { Button } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import routes from "routes/routePaths/RoutePaths";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    buttonGroup: {
        display: "flex",
        gap: theme.spacing.medium,
        marginTop: theme.spacing.medium,
    },
}));

interface ICompleteProjectWithoutAwardDisplayProps {
    downloadShortlistingReport: () => void;
}

const CompleteProjectWithoutAwardDisplay: React.FC<ICompleteProjectWithoutAwardDisplayProps> = ({
    downloadShortlistingReport,
}) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className={classes.buttonGroup}>
            <Button
                label={t("completeProject.withoutAward.button.downloadShortlistingReport")}
                onClick={downloadShortlistingReport}
                variant="outline"
                icon={faArrowDownToLine}
                testid="download-shortlisting-report-button"
                size="small"
            />
            <Button
                label={t("completeProject.withoutAward.button.backToDashboard")}
                onClick={() => navigate(routes.common.dashboard.path)}
                testid="back-to-dashboard-button"
                size="small"
            />
        </div>
    );
};

export default CompleteProjectWithoutAwardDisplay;
