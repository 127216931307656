import { getSectionTitle } from "features/project/helpers/sectionHelpers";
import { useCallback } from "react";
import { ISectionQuestions } from "types/dtos/questions/ISectionQuestions";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";

export const sortByWeightingThenAlphabetically = (a: SectionDto, b: SectionDto) => {
    const aSectionTitle = getSectionTitle(a);
    const bSectionTitle = getSectionTitle(b);

    if (a.weighting === b.weighting) return aSectionTitle.localeCompare(bSectionTitle);
    return (b.weighting ?? 0) - (a.weighting ?? 0);
};

export const sortAlphabetically = (a: SectionDto, b: SectionDto) => {
    const aSectionTitle = getSectionTitle(a);
    const bSectionTitle = getSectionTitle(b);

    return aSectionTitle.localeCompare(bSectionTitle);
};

export const sortByConditionalThenAlphabetically = (a: QuestionResponseDto, b: QuestionResponseDto) => {
    if (a.isConditional === b.isConditional) return a.questionText.localeCompare(b.questionText);
    return Number(b.isConditional) - Number(a.isConditional);
};

const sortQuestionsByOrderIndex = (section: ISectionQuestions): ISectionQuestions => {
    const sortedItems = [...section.items].sort((a, b) => a.orderIndex - b.orderIndex);
    return {
        ...section,
        items: sortedItems,
    };
};

const sortSectionsQuestionsByOrderIndex = (sections: ISectionQuestions[]): ISectionQuestions[] => {
    return sections.map(sortQuestionsByOrderIndex);
};

const sortQuestionsAlphabetically = (section: ISectionQuestions): ISectionQuestions => {
    const sortedItems = [...section.items].sort((a, b) => a.questionText.localeCompare(b.questionText));

    const updatedItems = sortedItems.map((item, index) => ({
        ...item,
        orderIndex: index,
    }));

    return {
        ...section,
        items: updatedItems,
    };
};

const sortSectionsQuestionsAlphabetically = (sections: ISectionQuestions[]): ISectionQuestions[] => {
    return sections.map(sortQuestionsAlphabetically);
};

export const sortByOrderIndex = (a: SectionDto, b: SectionDto) => {
    return a.orderIndex - b.orderIndex;
};

const useQuestionSorter = () => {
    const byWeightingThenAlphabetically = useCallback((a: SectionDto, b: SectionDto) => {
        return sortByWeightingThenAlphabetically(a, b);
    }, []);

    const Alphabetically = useCallback((a: SectionDto, b: SectionDto) => {
        return sortAlphabetically(a, b);
    }, []);

    const byConditionalThenAlphabetically = useCallback((a: QuestionResponseDto, b: QuestionResponseDto) => {
        return sortByConditionalThenAlphabetically(a, b);
    }, []);

    return {
        Alphabetically,
        byWeightingThenAlphabetically,
        byConditionalThenAlphabetically,
        sortByOrderIndex,
        sortSectionsQuestionsAlphabetically,
        sortSectionsQuestionsByOrderIndex,
    };
};

export default useQuestionSorter;
