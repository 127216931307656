import { IVettingQuestionnaireForm } from "features/company/profile/vetting/IVettingQuestionnaireForm";
import { useFormikContext } from "formik";
import useAppDispatch from "hooks/useAppDispatch";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import dateTimeService from "services/dateTimeService";
import { useGetCompanyDetailsQuery } from "store/api/companyApi";
import { setStepperChecklistItems, setStepperTitle } from "store/navigationSlice";
import { AccreditationDto } from "types/dtos/company/vettingInformation/AccreditationDto";
import { InsuranceDto } from "types/dtos/company/vettingInformation/InsuranceDto";
import { MembershipDto } from "types/dtos/company/vettingInformation/MembershipDto";
import { VettingInformationKeys } from "types/dtos/company/vettingInformation/VettingInformationDto";
import FileDto from "types/dtos/files/FileDto";

interface VettingNavigationProps {
    companyUuid: string;
    insurances: InsuranceDto[];
    healthAndSafetyPolicyFiles: FileDto[];
    drugAndAlcoholPolicyFiles: FileDto[];
    environmentPolicyFiles: FileDto[];
    diversityAndInclusionPolicyFiles: FileDto[];
    briberyPolicyFiles: FileDto[];
    socialResponsibilityPolicyFiles: FileDto[];
    modernSlaveryPolicyFiles: FileDto[];
    antiMoneyLaunderingPolicyFiles: FileDto[];
    accreditations: AccreditationDto[];
    cyberSecurityPlanFiles: FileDto[];
    disasterRecoveryPlanFiles: FileDto[];
    dataProtectionBreachesFiles: FileDto[];
    dataProtectionPolicyFiles: FileDto[];
    externalAuditFiles: FileDto[];
    memberships: MembershipDto[];
}

const validateBooleanField = (value?: boolean) => {
    return value || value === false;
};

const VettingNavigation: React.FC<VettingNavigationProps> = ({
    companyUuid,
    insurances,
    healthAndSafetyPolicyFiles,
    drugAndAlcoholPolicyFiles,
    environmentPolicyFiles,
    diversityAndInclusionPolicyFiles,
    briberyPolicyFiles,
    socialResponsibilityPolicyFiles,
    modernSlaveryPolicyFiles,
    antiMoneyLaunderingPolicyFiles,
    accreditations,
    cyberSecurityPlanFiles,
    disasterRecoveryPlanFiles,
    dataProtectionBreachesFiles,
    dataProtectionPolicyFiles,
    externalAuditFiles,
    memberships,
}) => {
    const { data: companyDetails } = useGetCompanyDetailsQuery(companyUuid);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { values: vettingInformation } = useFormikContext<IVettingQuestionnaireForm>();

    useEffect(() => {
        const isHealthAndSafetySectionComplete = () => {
            return (
                validateBooleanField(vettingInformation.hasHealthAndSafetyPolicy) &&
                (vettingInformation.hasSpecificTargetsAndActions ||
                    vettingInformation.hasRegularTraining ||
                    vettingInformation.hasExternalReporting) &&
                validateBooleanField(vettingInformation.hasResponsiblePerson) &&
                validateBooleanField(vettingInformation.hasDrugAndAlcoholPolicy) &&
                !!vettingInformation.numberOfReportableAccidents &&
                validateBooleanField(vettingInformation.hasOccupationalHealthArrangements)
            );
        };

        const isEnvironmentSectionComplete = () => {
            return (
                ((vettingInformation.hasEnvironmentalPolicy &&
                    environmentPolicyFiles.length > 0 &&
                    vettingInformation.environmentalPolicyExpiryDate &&
                    !dateTimeService.dateIsInPast(vettingInformation.environmentalPolicyExpiryDate)) ||
                    vettingInformation.hasEnvironmentalPolicy === false) &&
                validateBooleanField(vettingInformation.includesEnvironmentalIssuesInPerformance) &&
                validateBooleanField(vettingInformation.hasAccessToEnvironmentalAdvice) &&
                validateBooleanField(vettingInformation.hasCarbonEmissionsPolicy) &&
                validateBooleanField(vettingInformation.operatesInAccordanceWithStandards) &&
                validateBooleanField(vettingInformation.hasEnvironmentalBreaches)
            );
        };

        const isEqualitySectionComplete = () => {
            return (
                validateBooleanField(vettingInformation.hasDiversityAndInclusionPolicy) &&
                (vettingInformation.hasResponsiblePersonEquality ||
                    vettingInformation.hasCommitmentToAntiDiscrimination ||
                    vettingInformation.identifiesIncidentsOfDiscrimination) &&
                validateBooleanField(vettingInformation.allWorkersPaidFairly) &&
                validateBooleanField(vettingInformation.hasUnlawfulDiscriminationFindings) &&
                validateBooleanField(vettingInformation.promoteGoodPracticeForAntiDiscrimination) &&
                validateBooleanField(vettingInformation.hasHumanRightsPolicy)
            );
        };

        const isStandardsSectionComplete = () => {
            return (
                validateBooleanField(vettingInformation.hasCompliancePolicy) &&
                validateBooleanField(vettingInformation.hasSocialResponsibilityPolicy) &&
                validateBooleanField(vettingInformation.hasModernSlaveryPolicy) &&
                validateBooleanField(vettingInformation.hasIdentifiedModernSlaveryRisks) &&
                validateBooleanField(vettingInformation.hasSupplierTermsAndConditions) &&
                validateBooleanField(vettingInformation.hasWhistleblowingPolicy) &&
                validateBooleanField(vettingInformation.hasStaffTraining) &&
                validateBooleanField(vettingInformation.hasAntiMoneyLaunderingPolicy) &&
                validateBooleanField(vettingInformation.hasAntiSlaveryAndHumanTraffickingPolicy)
            );
        };

        const isQualitySectionComplete = () => {
            return (
                validateBooleanField(vettingInformation.hasResponsiblePersonQuality) &&
                validateBooleanField(vettingInformation.registeredWithConstructionIndustryScheme) &&
                accreditations?.length > 0 &&
                !!accreditations.find((accreditation) => !dateTimeService.dateIsInPast(accreditation.expiryDate)) &&
                ((vettingInformation.registeredWithConstructionIndustryScheme &&
                    validateBooleanField(vettingInformation.registeredAsGrossPaymentStatus)) ||
                    vettingInformation.registeredWithConstructionIndustryScheme === false)
            );
        };

        const isDisasterRecoveryComplete = () => {
            return (
                validateBooleanField(vettingInformation.hasCyberSecurityPlan) &&
                validateBooleanField(vettingInformation.hasDisasterRecoveryPlan) &&
                validateBooleanField(vettingInformation.hasDetailsOfCircumstances)
            );
        };

        const isDataProtectionComplete = () => {
            return (
                validateBooleanField(vettingInformation.hasDataProtectionBreaches) &&
                validateBooleanField(vettingInformation.hadExternalPrivacyAudit) &&
                validateBooleanField(vettingInformation.hasDataProtectionPolicy) &&
                validateBooleanField(vettingInformation.hasIndividualRightsProtection) &&
                validateBooleanField(vettingInformation.hasDataProtectionOfficer) &&
                validateBooleanField(vettingInformation.hasStaffTrainingDataProtection) &&
                validateBooleanField(vettingInformation.ensuresSuppliersImplementDataProtection) &&
                ((vettingInformation.hasTradeAssociationMembership &&
                    memberships.length > 0 &&
                    !!memberships.find((membership) => !dateTimeService.dateIsInPast(membership.expiryDate))) ||
                    vettingInformation.hasTradeAssociationMembership === false) &&
                validateBooleanField(vettingInformation.documentsPersonalData)
            );
        };

        dispatch(setStepperTitle(companyDetails?.registeredName));
        dispatch(
            setStepperChecklistItems([
                {
                    label: t("companyVettingQuestionnaire.sections.companyDetails.heading"),
                    children: [
                        {
                            id: VettingInformationKeys.parent.toString(),
                            label: t("companyVettingQuestionnaire.sections.companyDetails.parentCompany"),
                            isComplete:
                                !!vettingInformation?.parentCompanyName &&
                                !!vettingInformation?.parentCompanyRegistrationNumber,
                        },
                        {
                            id: VettingInformationKeys.geographicLocationsTypeIds.toString(),
                            label: t(
                                "companyVettingQuestionnaire.sections.companyDetails.geographicalLocations.heading",
                            ),
                            isComplete:
                                vettingInformation?.geographicalLocationsTypeIds &&
                                vettingInformation.geographicalLocationsTypeIds.length > 0,
                        },
                        {
                            id: VettingInformationKeys.companyFinance.toString(),
                            label: t("companyVettingQuestionnaire.sections.companyDetails.financialTurnover.heading"),
                            isComplete:
                                !!vettingInformation?.financialCurrency &&
                                !!vettingInformation?.financialTurnoverTypeId,
                        },
                        {
                            id: VettingInformationKeys.insurances.toString(),
                            label: t("companyVettingQuestionnaire.sections.companyDetails.insurance.heading"),
                            isComplete: !!insurances.find(
                                (insurance) => !dateTimeService.dateIsInPast(insurance.expiryDate),
                            ),
                        },
                    ],
                },
                {
                    label: t("companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.heading"),
                    children: [
                        {
                            id: VettingInformationKeys.healthAndSafety.toString(),
                            label: t(
                                "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.healthAndSafety.title",
                            ),
                            isComplete: isHealthAndSafetySectionComplete(),
                        },
                        {
                            id: VettingInformationKeys.environment.toString(),
                            label: t(
                                "companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.environment.title",
                            ),
                            isComplete: isEnvironmentSectionComplete(),
                        },
                    ],
                },
                {
                    label: t("companyVettingQuestionnaire.sections.ethics.heading"),
                    children: [
                        {
                            id: VettingInformationKeys.equality.toString(),
                            label: t("companyVettingQuestionnaire.sections.ethics.equality.title"),
                            isComplete: isEqualitySectionComplete(),
                        },
                        {
                            id: VettingInformationKeys.standards.toString(),
                            label: t("companyVettingQuestionnaire.sections.ethics.standards.title"),
                            isComplete: isStandardsSectionComplete(),
                        },
                        {
                            id: VettingInformationKeys.quality.toString(),
                            label: t("companyVettingQuestionnaire.sections.ethics.quality.title"),
                            isComplete: isQualitySectionComplete(),
                        },
                    ],
                },
                {
                    label: t("companyVettingQuestionnaire.sections.dataAndSecurity.heading"),
                    children: [
                        {
                            id: VettingInformationKeys.disasterRecovery.toString(),
                            label: t("companyVettingQuestionnaire.sections.dataAndSecurity.disasterRecovery.title"),
                            isComplete: isDisasterRecoveryComplete(),
                        },
                        {
                            id: VettingInformationKeys.dataProtection.toString(),
                            label: t("companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.title"),
                            isComplete: isDataProtectionComplete(),
                        },
                    ],
                },
                {
                    label: t("companyVettingQuestionnaire.sections.frameworkAgreements.heading"),
                    children: [
                        {
                            id: VettingInformationKeys.publicSector.toString(),
                            label: t("companyVettingQuestionnaire.sections.frameworkAgreements.publicSector.title"),
                            isComplete: validateBooleanField(vettingInformation.hasPublicSectorFrameworkAgreement),
                        },
                    ],
                },
            ]),
        );

        return () => {
            dispatch(setStepperTitle(undefined));
            dispatch(setStepperChecklistItems(undefined));
        };
    }, [
        companyDetails?.registeredName,
        vettingInformation,
        dispatch,
        t,
        insurances,
        healthAndSafetyPolicyFiles.length,
        drugAndAlcoholPolicyFiles.length,
        environmentPolicyFiles.length,
        diversityAndInclusionPolicyFiles.length,
        briberyPolicyFiles.length,
        socialResponsibilityPolicyFiles.length,
        modernSlaveryPolicyFiles.length,
        antiMoneyLaunderingPolicyFiles.length,
        accreditations,
        cyberSecurityPlanFiles.length,
        disasterRecoveryPlanFiles.length,
        dataProtectionBreachesFiles.length,
        externalAuditFiles.length,
        dataProtectionPolicyFiles.length,
        memberships,
    ]);

    return null;
};

export default VettingNavigation;
