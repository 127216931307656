import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Button, Copy, IPaginationState, Pagination, Table } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import useUserGroupConfiguration from "features/company/buyer/userGroups/hooks/useUserGroupConfiguration";
import { ITheme } from "styles/themes/types";
import { UserGroupDto } from "types/dtos/company/userGroups/UserGroupDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    header: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        gap: theme.spacing.small,
        marginBottom: theme.spacing.small,
    },
    pagination: {
        marginTop: theme.spacing.small,
    },

    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        header: {
            flexDirection: "row",
        },
    },
}));

interface IUserGroupsDisplayProps {
    userGroups: UserGroupDto[];
    onCreate: () => void;
    onEdit: (row: UserGroupDto) => void;
    onDelete: (row: UserGroupDto) => void;
    paginationState: IPaginationState;
    navigateToPage: (page: number) => void;
    setSelectedUserGroupUuid: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const UserGroupsDisplay: React.FC<IUserGroupsDisplayProps> = ({
    userGroups,
    onCreate,
    onEdit,
    onDelete,
    navigateToPage,
    paginationState,
    setSelectedUserGroupUuid,
}) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const { columns } = useUserGroupConfiguration(onEdit, onDelete, setSelectedUserGroupUuid);

    return (
        <>
            <div className={classes.header}>
                <Copy>{t("userGroups.subtitle")}</Copy>
                <Button
                    label={t("userGroups.createUserGroup")}
                    onClick={onCreate}
                    icon={faPlus}
                    testid="create-user-group"
                />
            </div>
            <Table
                columns={columns}
                data={userGroups}
                noDataDisplay={t("userGroups.table.noUserGroups")}
                testid="user-groups-list"
            />
            <Pagination
                className={classes.pagination}
                paginationState={paginationState}
                navigateToPage={navigateToPage}
                testid="supplier-submissions-dashboard-pagination"
            />
        </>
    );
};

export default UserGroupsDisplay;
