import { useToaster } from "@maistro/components";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
    useAddBuyerSupplierContractApiMutation,
    useEditBuyerSupplierContractApiMutation,
} from "store/api/buyerSupplierRelationshipsApi";
import BuyerSupplierContractDto from "types/dtos/company/buyer-supplier-relationships/BuyerSupplierContractDto";

const useSaveContract = (
    buyerUuid: string,
    supplierUuid: string,
    setIsFormVisible: React.Dispatch<React.SetStateAction<boolean>>,
    setSelectedContract: React.Dispatch<React.SetStateAction<BuyerSupplierContractDto | undefined>>,
) => {
    const { t } = useTranslation();
    const toast = useToaster();

    const [addBuyerSupplierContract, { isError: isAddError, isLoading: isAddLoading, isSuccess: isAddSuccess }] =
        useAddBuyerSupplierContractApiMutation();
    const [editBuyerSupplierContract, { isError: isEditError, isLoading: isEditLoading, isSuccess: isEditSuccess }] =
        useEditBuyerSupplierContractApiMutation();

    useEffect(() => {
        if (isAddError || isEditError) {
            toast.error(t("companySuppliers.contracts.api.saveError"));
            return;
        }
        if (isAddSuccess || isEditSuccess) {
            toast.success(t("companySuppliers.contracts.api.saveSuccess"));
            setIsFormVisible(false);
            setSelectedContract(undefined);
        }
    }, [isAddError, isAddSuccess, isEditError, isEditSuccess, setIsFormVisible, setSelectedContract, t, toast]);

    const handleSaveContract = useCallback(
        async (contract: BuyerSupplierContractDto) => {
            if (contract.uuid) {
                await editBuyerSupplierContract({
                    buyerUuid,
                    supplierUuid,
                    uuid: contract.uuid,
                    ...contract,
                });
                return;
            }
            await addBuyerSupplierContract({
                buyerUuid,
                supplierUuid,
                ...contract,
            });
        },
        [addBuyerSupplierContract, buyerUuid, editBuyerSupplierContract, supplierUuid],
    );

    return {
        isSaving: isAddLoading || isEditLoading,
        handleSaveContract,
    };
};

export default useSaveContract;
