import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { ICommonProps, Icon, Pill } from "@maistro/components";
import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";

import { Paper } from "components";
import { useTranslation } from "react-i18next";
import currencyService from "services/currencyService";
import dateTimeService from "services/dateTimeService";
import stringService from "services/stringService";
import { ITheme } from "styles/themes/types";
import { ApproverDto } from "types/dtos/company/approvals/ApproverDto";
import { EntityApprovalDto } from "types/dtos/company/approvals/EntityApprovalDto";
import PolicyApprovalStatusType from "types/enums/companies/Approvals/PolicyApprovalStatusType";

const useStyles = createUseStyles((theme: ITheme) => ({
    gridContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: theme.spacing.small,
        marginTop: theme.spacing.medium,
        ...theme.typography.base,
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
        gap: theme.spacing.small,
    },
    approverRow: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.small,
    },
    approver: {
        flexBasis: "50%",
    },
    reason: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: theme.colors.medLightGray,
        padding: `${theme.spacing.xSmall}px ${theme.spacing.small}px`,
        borderRadius: theme.spacing.xSmall,
        marginTop: theme.spacing.small,
        fontWeight: "600",
    },
    comment: {
        padding: `${theme.spacing.xSmall}px ${theme.spacing.small}px`,
    },
    label: {
        ...theme.typography.base,
        fontWeight: 600,
    },
    field: {},
    policyName: {
        display: "block",
    },

    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        approverRow: {
            flexDirection: "row",
            justifyContent: "space-between",
        },
        policyName: {
            display: "inline",
        },
    },
}));

interface IApprovalsRowProps extends ICommonProps {
    approval: EntityApprovalDto;
}

const ApprovalsRow: React.FC<IApprovalsRowProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { approval } = props;

    const [isExpanded, setIsExpanded] = useState(false);

    const actionedApprover = approval.approvers.find((a) => a.status !== PolicyApprovalStatusType.Unset);
    const pillColor = useCallback((approver: ApproverDto) => {
        switch (approver.status) {
            case "Approved":
                return "success";
            case "Rejected":
                return "error";
            default:
                return "primary";
        }
    }, []);

    const getStatusPill = () => {
        if (actionedApprover) {
            return (
                <Pill
                    value={t(`monitorApprovals.status.${actionedApprover.status}`)}
                    color={pillColor(actionedApprover)}
                    testid={`approval-status-${approval.uuid}`}
                />
            );
        }

        return (
            <Pill
                testid={`approval-status-${approval.uuid}`}
                value={t(`monitorApprovals.status.${PolicyApprovalStatusType.Unset}`)}
            />
        );
    };

    const getApproverDisplay = () => {
        return (
            <div>
                <div className={classes.label}>{t("monitorApprovals.approvalRow.approvers")}</div>
                {actionedApprover && (
                    <div>
                        <div className={classes.row}>
                            <div className={classes.field}>{actionedApprover.name}</div>
                            <div className={classes.field}>
                                {dateTimeService.toShortDateTimeFormat(actionedApprover.actionedOn)}
                            </div>
                        </div>
                        <div className={classes.reason}>
                            {t("monitorApprovals.approvalRow.reason")}
                            <Icon
                                icon={isExpanded ? faChevronUp : faChevronDown}
                                onClick={() => setIsExpanded(!isExpanded)}
                                size="small"
                                testid={`expand-approval-reason-${approval.uuid}`}
                            />
                        </div>
                        {isExpanded && (
                            <div className={classes.comment} data-testid={`approval-reason-${approval.uuid}`}>
                                {actionedApprover.message}
                            </div>
                        )}
                    </div>
                )}
                {!actionedApprover && (
                    <div className={classes.approverRow}>
                        {approval.approvers.map((approver) => (
                            <div
                                key={approver.userUuid}
                                className={classes.approver}
                                data-testid={`approver-${approver.userUuid}-for-${approval.uuid}`}
                            >
                                <div>{approver.name}</div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    return (
        <Paper color="white" className={classNames(classes.gridContainer)}>
            <div>
                <div className={classes.row}>
                    <div className={classes.label}>{t("monitorApprovals.approvalRow.policy")}</div>

                    {getStatusPill()}
                </div>
                <div className={classes.field}>
                    <span className={classes.policyName} data-testid={`policy-name-${approval.uuid}`}>
                        {approval.policyName}:{" "}
                    </span>
                    <span className={classes.policyName} data-testid={`policy-type-${approval.uuid}`}>
                        {t(`monitorApprovals.approvalRow.${stringService.toCamelCase(approval.policyType)}.subTitle`, {
                            spendLimit: currencyService.toDisplayFormat(
                                approval.spendLimit?.currency,
                                approval.spendLimit?.limit,
                            ),
                        })}
                    </span>
                </div>
            </div>

            {getApproverDisplay()}
        </Paper>
    );
};

export default ApprovalsRow;
