// Need to cast as any in order to get over the issues of immutability when it comes to using HtmlButtonElement as props
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IButtonProps } from "@maistro/components/dist/esm/types/components/Button/Button";
import { ITooltipProps } from "@maistro/components/dist/esm/types/components/Tooltip/Tooltip";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import IProfileBarItem from "components/Layout/types/ProfileBarItem";
import ISecondaryNavItem from "components/Layout/types/SecondaryNavItem";
import TitleCtaType from "components/Layout/types/TitleCtaType";
import { ISearchBarProps } from "components/SearchBar/SearchBar";
import { Route } from "routes/helpers/RoutesHelper";

export interface IBackLayoutState {
    route: Route;
}

export interface IBannerLayoutState {
    text: string;
    desktopText?: string;
    onClick?: () => void;
}

export interface ITitleCtaLayoutState {
    type: TitleCtaType;
    onClick?: () => void;
}

export interface ILayoutState {
    pageTitle?: string | null;
    tooltip?: ITooltipProps["content"] | null;
    tooltipClassName?: string | null;
    back?: IBackLayoutState | null;
    ctas?: IButtonProps[] | null;
    titleCtas?: ITitleCtaLayoutState[] | null;
    mobileFooterCtas?: IButtonProps[] | null;
    searchBar?: ISearchBarProps | null;
    secondaryNavItems?: ISecondaryNavItem[] | null;
    profileBarItems?: IProfileBarItem[] | null;
    banner?: IBannerLayoutState | null;
}

const initialState: ILayoutState = {
    pageTitle: null,
    tooltip: null,
    tooltipClassName: null,
    back: null,
    ctas: null,
    titleCtas: null,
    mobileFooterCtas: null,
    searchBar: null,
    secondaryNavItems: null,
    profileBarItems: null,
    banner: null,
};

export const layoutSlice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        setPageTitle: (state, action: PayloadAction<string | null>) => {
            state.pageTitle = action.payload;
        },
        setTooltip: (state, action: PayloadAction<ITooltipProps["content"] | null>) => {
            state.tooltip = action.payload;
        },
        setTooltipClassName: (state, action: PayloadAction<string | null>) => {
            state.tooltipClassName = action.payload;
        },
        setBack: (state, action: PayloadAction<IBackLayoutState | null>) => {
            state.back = action.payload;
        },
        setCtas: (state, action: PayloadAction<IButtonProps[] | null>) => {
            state.ctas = action.payload as any;
        },
        setTitleCtas: (state, action: PayloadAction<ITitleCtaLayoutState[] | null>) => {
            state.titleCtas = action.payload;
        },
        setMobileFooterCtas: (state, action: PayloadAction<IButtonProps[] | null>) => {
            state.mobileFooterCtas = action.payload as any;
        },
        setSearchBar: (state, action: PayloadAction<ISearchBarProps | null>) => {
            state.searchBar = action.payload;
        },
        setSecondaryNavItems: (state, action: PayloadAction<ISecondaryNavItem[] | null>) => {
            state.secondaryNavItems = action.payload;
        },
        setProfileBarItems: (state, action: PayloadAction<IProfileBarItem[] | null>) => {
            state.profileBarItems = action.payload;
        },
        setBanner: (state, action: PayloadAction<IBannerLayoutState | null>) => {
            state.banner = action.payload;
        },
        resetLayout: (state) => {
            return { ...initialState, back: state.back, banner: state.banner, profileBarItems: state.profileBarItems };
        },
    },
});

const { actions, reducer } = layoutSlice;

export const {
    setPageTitle,
    setTooltip,
    setTooltipClassName,
    setBack,
    setCtas,
    setTitleCtas,
    setMobileFooterCtas,
    setSearchBar,
    setSecondaryNavItems,
    setProfileBarItems,
    setBanner,
    resetLayout,
} = actions;

export default reducer;
