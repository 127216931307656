import { faFolderArrowDown } from "@fortawesome/pro-regular-svg-icons";
import { Button, ExpansionPanel, ReadOnlyField, SelectField, ToggleButton } from "@maistro/components";
import classNames from "classnames";
import { Form, Formik } from "formik";
import React, { useState, useEffect, useMemo, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import FileList from "components/FileList/FileList";
import { IOptionProps } from "components/shared";
import { IProjectInformation } from "features/project/types";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";
import EntityType from "types/enums/questions/EntityType";
import QuestionType from "types/enums/questions/QuestionType";

export interface FileAndText {
    text: string | ReactElement;
    files: FileDto[];
    id: string;
    questionType: string;
    sectionName: string;
    askedBy: string;
    askedByUuid: string;
}

const useStyles = createUseStyles((theme: ITheme) => ({
    textLimit: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "box",
        boxOrient: "vertical",
        textAlign: "left",
    },
    lineBreak: {
        display: "none",
    },
    sectionText: {
        marginRight: theme.spacing.xSmall,
        lineClamp: 1,
    },
    questionText: {
        lineClamp: 3,
    },
    copy: {
        ...theme.typography.content.copy,
        fontWeight: 600,
        marginRight: theme.spacing.xSmall,
    },
    h4: {
        ...theme.typography.headings.h4,
    },
    subBanner: {
        display: "flex",
        flexDirection: "column-reverse",
        gap: theme.spacing.medium,
        marginTop: theme.spacing.small,
        marginBottom: theme.spacing.xLarge,
    },
    subBannerButtons: {
        display: "flex",
        gap: theme.spacing.small,
    },
    documentTypeSelect: {
        width: "350px",
        marginBottom: theme.spacing.xLarge,
    },
    buttons: {
        marginBottom: theme.spacing.xLarge,
    },
    toggle: {
        "& > *": {
            flexBasis: "50%",
        },
    },
    fileDisplay: {
        marginBottom: theme.spacing.medium,
    },
    flexGrow: { flexGrow: 1 },
    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        subBanner: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "baseline",
            marginBottom: theme.spacing.small,
        },
        textLimit: {
            marginRight: theme.spacing.units(10),
        },
        sectionText: {
            lineClamp: 1,
        },
        questionText: {
            lineClamp: 1,
        },
    },
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        documentTypeSelect: {
            width: "100%",
        },
        lineBreak: {
            display: "inline-block",
        },
    },
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        toggle: {
            "& > *": {
                flexBasis: "auto",
                minWidth: 150,
            },
        },
    },
}));

interface IDocumentManagementDisplay {
    projectInformation: IProjectInformation;
    projectHasFiles: boolean;
    isArchiving: boolean;
    isArchiveAvailable: boolean;
    createArchive: () => Promise<void>;
    downloadArchive: () => Promise<void>;
    projectFiles: FileDto[];
    tenderFiles: FileAndText[];
    clarificationFiles: FileAndText[];
    isFetchingFiles: boolean;
}

const DocumentManagementDisplay: React.FC<IDocumentManagementDisplay> = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        projectInformation,
        projectHasFiles,
        isArchiving,
        isArchiveAvailable,
        createArchive,
        downloadArchive,
        projectFiles,
        tenderFiles,
        isFetchingFiles,
        clarificationFiles,
    } = props;

    const project = EntityType.Project.toString();
    const question = EntityType.Question.toString();
    const answer = EntityType.Answer.toString();
    const tenderCriteria = QuestionType.TenderCriteria.toString();
    const clarification = QuestionType.Clarification.toString();
    const projectDocuments = t("fileUpload.projectBriefDocuments");
    const supplierDocuments = t("fileUpload.supplierDocuments");

    const buyerDocumentOptions = useMemo(
        () =>
            [
                { label: t("fileUpload.projectBriefDocuments"), value: project },
                { label: t("fileUpload.tenderDocuments"), value: tenderCriteria },
                { label: t("fileUpload.clarificationDocuments"), value: clarification },
            ] as IOptionProps[],
        [clarification, project, t, tenderCriteria],
    );

    const supplierDocumentOptions = useMemo(
        () =>
            [
                { label: t("fileUpload.tenderResponseDocuments"), value: tenderCriteria },
                { label: t("fileUpload.clarificationDocuments"), value: clarification },
            ] as IOptionProps[],
        [clarification, t, tenderCriteria],
    );

    const initialProjectFilesState = projectFiles.some((x) => x.entityType === project) ? project : question;
    const initialQuestionTypeState = projectFiles.some((x) => x.entityType === project) ? project : tenderCriteria;

    const [displayProjectFiles, setDisplayProjectFiles] = useState(initialProjectFilesState);
    const [activeToggleButton, setActiveToggleButton] = useState(projectDocuments);
    const [selectedQuestionType, setSelectedQuestionType] = useState(initialQuestionTypeState);

    useEffect(() => {
        setDisplayProjectFiles(initialProjectFilesState);
    }, [initialProjectFilesState]);

    const setBuyerFileType = () => {
        if (projectFiles.length > 0) {
            setDisplayProjectFiles(project);
            return project;
        }

        setDisplayProjectFiles(question);
        if (tenderFiles.length > 0) {
            return tenderCriteria;
        }
        if (clarificationFiles.length > 0) {
            return clarification;
        }

        return project;
    };

    const setSupplierFileType = () => {
        setDisplayProjectFiles(answer);
        if (tenderFiles.length > 0) {
            return tenderCriteria;
        }
        if (clarificationFiles.length > 0) {
            return clarification;
        }
        return tenderCriteria;
    };

    const renderDownloadFilesButton = () => {
        if (!projectHasFiles) return null;

        if (isArchiveAvailable)
            return (
                <Button
                    className="hidden-tablet"
                    label={t("fileUpload.archive.downloadAllDocuments")}
                    icon={faFolderArrowDown}
                    variant="outline"
                    onClick={downloadArchive}
                    testid="documents"
                />
            );

        return (
            <Button
                className="hidden-tablet"
                label={t("fileUpload.archive.packageDocuments")}
                icon={faFolderArrowDown}
                variant="outline"
                disabled={isArchiving}
                onClick={createArchive}
                testid="package-documents"
            />
        );
    };

    const setQuestionText = (details: FileAndText) => {
        return details.sectionName !== "" ? (
            <React.Fragment>
                <span className={classNames(classes.textLimit, classes.sectionText)}>{details.sectionName} :</span>
                <br />
                <span className={classNames(classes.textLimit, classes.questionText)}>{details.text}</span>
            </React.Fragment>
        ) : (
            details.text
        );
    };

    const createDocumentList = () => {
        let documentList: FileAndText[] = [];

        if (selectedQuestionType === tenderCriteria) {
            documentList = tenderFiles
                .map((x) => ({
                    ...x,
                    text: setQuestionText(x),
                    files: x.files.filter((y) => y.entityType === displayProjectFiles),
                }))
                .filter((x) => x.files.length > 0);
        }
        if (selectedQuestionType === clarification) {
            documentList = clarificationFiles
                .map((x) => ({
                    ...x,
                    text: setQuestionText(x),
                    files:
                        activeToggleButton === projectDocuments
                            ? x.files.filter((y) => y.linkedCompanyUuid === projectInformation.companyUuid)
                            : x.files.filter((y) => y.linkedCompanyUuid !== projectInformation.companyUuid),
                }))
                .filter((x) => x.files.length > 0);
        }
        return documentList;
    };

    const renderTenderDocumentList = () => {
        const documentList = createDocumentList();
        if (!isFetchingFiles && documentList.length === 0) {
            if (selectedQuestionType === tenderCriteria) {
                return t("fileUpload.noFiles.tender", {
                    response: activeToggleButton === projectDocuments ? "" : "response",
                });
            }
            return t("fileUpload.noFiles.clarifications");
        }

        return documentList.map((y) => {
            return (
                <ExpansionPanel
                    key={y.id}
                    className={classes.fileDisplay}
                    isExpanded={y.id === documentList[0].id}
                    label={y.text}
                    testid="project-description-panel"
                >
                    <ReadOnlyField
                        testid="project-files"
                        value={<FileList files={y.files} canDownload testid="project-documents" separateFileRows />}
                    />
                </ExpansionPanel>
            );
        });
    };

    const renderProjectBriefDocumentList = () => {
        const filesToList = projectFiles.filter((x) => x.entityType === displayProjectFiles);
        if (!isFetchingFiles && filesToList.length === 0) return t("fileUpload.noFiles.projectBrief");
        return (
            <ReadOnlyField
                testid="project-files"
                value={<FileList files={filesToList} canDownload testid="project-documents" separateFileRows />}
            />
        );
    };

    return (
        <React.Fragment>
            <div className={classes.subBanner}>
                <div>
                    <span className={classes.copy}>{t("common.projectName")}: </span>
                    <span className={classes.h4}>{projectInformation.projectName}</span>
                </div>
                <div className={classes.subBannerButtons}>{renderDownloadFilesButton()}</div>
            </div>
            <Formik
                initialValues={{
                    displayFileType: selectedQuestionType,
                }}
                onSubmit={(values) => {
                    if (activeToggleButton === projectDocuments) {
                        setDisplayProjectFiles(values.displayFileType === project ? project : question);
                        setSelectedQuestionType(values.displayFileType);
                    } else {
                        setDisplayProjectFiles(answer);
                        setSelectedQuestionType(values.displayFileType);
                    }
                }}
            >
                {({ handleSubmit, setFieldValue }) => (
                    <Form className={classes.documentTypeSelect}>
                        <div className={classes.buttons}>
                            <ToggleButton
                                className={classNames(classes.toggle, classes.flexGrow)}
                                options={[
                                    {
                                        label: t("fileUpload.projectDocuments"),
                                        value: t("fileUpload.projectDocuments"),
                                        active: activeToggleButton === projectDocuments,
                                        disabled: activeToggleButton === projectDocuments,
                                    },
                                    {
                                        label: t("fileUpload.supplierDocuments"),
                                        value: t("fileUpload.supplierDocuments"),
                                        active: activeToggleButton === supplierDocuments,
                                        disabled: activeToggleButton === supplierDocuments,
                                    },
                                ]}
                                onClick={() => {
                                    if (activeToggleButton === projectDocuments) {
                                        const fileType = setSupplierFileType();
                                        setFieldValue("displayFileType", fileType);
                                        setSelectedQuestionType(fileType);
                                        setActiveToggleButton(supplierDocuments);
                                    } else {
                                        const fileType = setBuyerFileType();
                                        setFieldValue("displayFileType", fileType);
                                        setSelectedQuestionType(fileType);
                                        setActiveToggleButton(projectDocuments);
                                    }
                                }}
                                testid="scoring-mode-toggle"
                            />
                        </div>
                        <SelectField
                            testid="scoring-file-type"
                            name="displayFileType"
                            options={
                                activeToggleButton === projectDocuments ? buyerDocumentOptions : supplierDocumentOptions
                            }
                            withoutValidation
                            onChange={() => {
                                handleSubmit();
                            }}
                        />
                    </Form>
                )}
            </Formik>
            {displayProjectFiles === project && activeToggleButton === projectDocuments
                ? renderProjectBriefDocumentList()
                : renderTenderDocumentList()}
        </React.Fragment>
    );
};

export default DocumentManagementDisplay;
