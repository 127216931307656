import { Label, Tooltip } from "@maistro/components";
import { ITooltipProps } from "@maistro/components/dist/esm/types/components/Tooltip/Tooltip";
import classNames from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    spacing: {
        marginBottom: theme.spacing.xSmall,
    },
}));

interface IMaistroFormikLabelProps extends ICommonProps {
    label?: string;
    labelInformation?: string;
    tooltip?: ITooltipProps["content"];
    tooltipPosition?: ITooltipProps["position"];
    name: string;
    required?: boolean;
    expandable?: boolean;
}

const MaistroFormikLabel: React.FC<IMaistroFormikLabelProps> = (props) => {
    const classes = useStyles();

    const { label, labelInformation, tooltip, tooltipPosition, name, required, testid, expandable } = props;

    if (!label) return null;
    const renderLabel = () => (
        <Label
            className={classNames(props.className, { [classes.spacing]: !tooltip && !expandable })}
            content={label}
            labelInformation={labelInformation}
            name={name}
            required={required}
            testid={testid}
        />
    );

    return tooltip ? (
        <Tooltip
            className={classNames({ [classes.spacing]: !expandable })}
            content={tooltip}
            position={tooltipPosition}
            testid={`${testid}-tooltip`}
            start={expandable}
        >
            {renderLabel()}
        </Tooltip>
    ) : (
        renderLabel()
    );
};

MaistroFormikLabel.defaultProps = {
    tooltipPosition: "right",
};

export default MaistroFormikLabel;
