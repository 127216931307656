import { User } from "oidc-client-ts";
import { useEffect, useRef } from "react";

import useAppDispatch from "hooks/useAppDispatch";
import { userError } from "store/oidcSlice";
import userManager from "features/auth/oidc/userManager";

interface CallbackHookProps {
    successCallback: (user: User) => void;
    errorCallback?: (error: Error) => void;
}

const useCallbackHook = ({ successCallback, errorCallback }: CallbackHookProps) => {
    const shouldCancel = useRef(false);
    const dispatch = useAppDispatch();
    useEffect(() => {
        const onRedirectCallback = async () => {
            try {
                const user = await userManager.signinRedirectCallback();
                if (user) {
                    successCallback(user);
                } else {
                    dispatch(userError(new Error("User is not authenticated")));
                }
            } catch (error) {
                dispatch(userError(error as Error));
                if (errorCallback) {
                    errorCallback(error as Error);
                }
            }
        };
        if (!shouldCancel.current) {
            onRedirectCallback();
        }

        return () => {
            shouldCancel.current = true;
        };
    }, [dispatch, errorCallback, successCallback]);
};

export default useCallbackHook;
