/* eslint-disable jsx-a11y/no-autofocus */
import { faPenField } from "@fortawesome/pro-regular-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { Button, Heading, Icon, TextField } from "@maistro/components";
import classNames from "classnames";
import { Form, Formik } from "formik";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import type { ISectionCard } from "features/project/tenderCriteria/sections/SectionSelectionDisplay";
import ReactGA from "react-ga4";
import { ITheme } from "styles/themes/types";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import SectionType from "types/enums/questions/sections/SectionType";

const useStyles = createUseStyles((theme: ITheme) => ({
    card: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.colors.white,
        justifyContent: "center",
        alignItems: "center",
        border: `2px dashed ${theme.colors.primary}`,
        borderRadius: theme.spacing.xSmall,
        padding: `${theme.spacing.medium}px ${theme.spacing.large}px`,
        minHeight: 172,
    },
    addSection: {
        cursor: "pointer",
        textAlign: "center",
    },
    icon: {
        height: "2em",
        width: "2em",
        borderRadius: "50%",
        color: theme.colors.white,
        background: theme.colors.highlight,
    },
    title: {
        marginTop: theme.spacing.medium,
        marginBottom: theme.spacing.small,
    },
    form: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing.small,
    },
    input: {
        width: "100%",
        background: "none",
        border: "none",
        ...theme.typography.headings.h2,
        lineHeight: "30px",

        "&:focus": {
            outline: "none",
        },
    },
}));

interface IAddOrUpdateSectionCardProps {
    editingSection?: ISectionCard;
    sections: ISectionCard[];
    isEditing?: boolean;
    isAddingSection: boolean;
    setIsAddingSection: Dispatch<SetStateAction<boolean>>;
    closeEditing?: () => void;
    updateSection?: (selectedSection: ISectionCard) => void;
    addSection?: (selectedSection: ISectionCard) => void;
}

const AddOrUpdateSectionCard: React.FC<IAddOrUpdateSectionCardProps> = (props) => {
    const classes = useStyles();

    const {
        sections,
        isAddingSection,
        setIsAddingSection,
        editingSection,
        isEditing,
        closeEditing,
        updateSection,
        addSection,
    } = props;

    const { t } = useTranslation();
    const buttonLabel = !isEditing ? t("common.add") : t("common.submit");

    const inputRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = useCallback(
        async (event: MouseEvent) => {
            const target = event.target as Node;
            if (
                inputRef.current &&
                !inputRef.current.contains(target) &&
                buttonRef.current &&
                !buttonRef.current.contains(target) &&
                closeEditing
            ) {
                closeEditing();
            }
        },
        [closeEditing],
    );

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [handleClickOutside]);

    const handleBlur = (title: string) => {
        if (!title || title.length === 0) {
            setIsAddingSection(false);
        }
        if (editingSection && title === editingSection.previousDescription && closeEditing) {
            closeEditing();
        }
    };

    const onAddSection = (title: string) => {
        if (addSection) {
            addSection({
                sectionType: SectionType.Other,
                title,
                text: "",
                previousDescription: "",
                isEditing: false,
                icon: faPenField,
                isSelected: true,
                isNewSection: true,
            });
        }
    };

    const onUpdateSection = (title: string) => {
        if (editingSection && title !== editingSection.previousDescription && closeEditing) {
            editingSection.title = title;
            editingSection.isEditing = undefined;
            if (updateSection) {
                updateSection(editingSection);
            }
            closeEditing();
        }
    };

    if (!isAddingSection && !isEditing) {
        return (
            <div
                className={classNames(classes.card, classes.addSection, "hidden-mobile")}
                onClick={() => setIsAddingSection(true)}
                data-testid="add-section-card"
            >
                <Icon className={classes.icon} icon={faPlus} size="large" centered />
                <Heading className={classes.title} variant="h2">
                    {t("tenderCriteria.sections.selection.addSection")}
                </Heading>
            </div>
        );
    }

    return (
        <Formik
            initialValues={editingSection ? { title: editingSection.title } : { title: "" }}
            validationSchema={Yup.object({
                title: Yup.string()
                    .required(t("tenderCriteria.sections.selection.sectionNameRequired"))
                    .max(60, t("tenderCriteria.sections.selection.sectionNameLength"))
                    .test(
                        "unique-title",
                        t("tenderCriteria.sections.selection.sectionNameExists"),
                        (value) =>
                            !!value &&
                            !sections.map((section) => section.title.toLowerCase()).includes(value.toLowerCase()),
                    ),
            })}
            onSubmit={(values, { resetForm }) => {
                ReactGA.event({
                    category: ga4Category.Button,
                    action: ga4Action.ButtonClick,
                    label: `Tender Criteria - Add Selection - `.concat(values.title),
                });
                if (!editingSection) {
                    onAddSection(values.title);
                } else {
                    onUpdateSection(values.title);
                }
                setIsAddingSection(false);
                resetForm();
            }}
        >
            {({ values }) => (
                <div
                    id="new-section-card"
                    className={classes.card}
                    onBlur={() => handleBlur(values.title)}
                    data-testid="new-section-card"
                >
                    <Form className={classes.form}>
                        <div ref={inputRef}>
                            <TextField
                                className={classes.input}
                                name="title"
                                placeholder="Enter name"
                                autoFocus
                                withPassIcon={false}
                                testid="section-name"
                            />
                        </div>
                        <div ref={buttonRef}>
                            <Button label={buttonLabel} type="submit" />
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
};

export default AddOrUpdateSectionCard;
