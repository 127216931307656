import { ICommonProps, SortOrder, Table } from "@maistro/components";
import { useTranslation } from "react-i18next";

import { Loader } from "components";
import useContractsTableConfiguration from "features/company/profile/suppliers/BuyerSupplierContracts/hooks/useContractsTableConfiguration";
import useListContracts from "features/company/profile/suppliers/BuyerSupplierContracts/hooks/useListContracts";
import BuyerSupplierContractDto from "types/dtos/company/buyer-supplier-relationships/BuyerSupplierContractDto";

type BuyerSupplierContractsTableProps = ICommonProps & {
    setSelectedContract: React.Dispatch<React.SetStateAction<BuyerSupplierContractDto | undefined>>;
    setIsFormVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsDeleteContractPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
    buyerUuid: string;
    supplierUuid: string;
};

const BuyerSupplierContractsTable: React.FC<BuyerSupplierContractsTableProps> = ({
    setSelectedContract,
    setIsFormVisible,
    setIsDeleteContractPopupOpen,
    buyerUuid,
    supplierUuid,
}) => {
    const { t } = useTranslation();
    const { columns } = useContractsTableConfiguration(
        setSelectedContract,
        setIsFormVisible,
        setIsDeleteContractPopupOpen,
    );

    const { data, isLoading } = useListContracts(buyerUuid, supplierUuid);

    if (isLoading || !data) {
        return <Loader />;
    }

    return (
        <Table
            columns={columns}
            data={data}
            noDataDisplay={t("companySuppliers.contracts.noData")}
            testid="contracts-table"
            sortBy={{
                column: "expiryDate",
                order: SortOrder.Ascending,
            }}
        />
    );
};

export default BuyerSupplierContractsTable;
