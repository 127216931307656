import { BooleanOptionsField, Heading, TextField } from "@maistro/components";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { IVettingQuestionnaireForm } from "features/company/profile/vetting/IVettingQuestionnaireForm";
import useSectionStyles from "features/company/profile/vetting/sections/useSectionStyles";
import { VettingInformationKeys } from "types/dtos/company/vettingInformation/VettingInformationDto";

const PublicSectorSubSection: React.FC = () => {
    const sharedClasses = useSectionStyles();

    const { t } = useTranslation();

    const { values } = useFormikContext<IVettingQuestionnaireForm>();

    return (
        <div id={VettingInformationKeys.publicSector}>
            <Heading variant="h4">
                {t("companyVettingQuestionnaire.sections.frameworkAgreements.publicSector.title")}
            </Heading>
            <ol className={sharedClasses.listStyle} type="1">
                <li>
                    <BooleanOptionsField
                        name="hasPublicSectorFrameworkAgreement"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.frameworkAgreements.publicSector.frameworkAgreementDetails.label",
                        )}
                        testid="has-public-sector-framework-agreement-options-field"
                    />
                    {values.hasPublicSectorFrameworkAgreement && (
                        <div className={sharedClasses.marginTop}>
                            <TextField
                                name="frameworkAgreementDetails"
                                label={t(
                                    "companyVettingQuestionnaire.sections.frameworkAgreements.publicSector.frameworkAgreementDetails.field",
                                )}
                                testid="framework-agreement-details-field"
                            />
                        </div>
                    )}
                </li>
            </ol>
        </div>
    );
};

export default PublicSectorSubSection;
