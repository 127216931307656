import { Button } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    buttonGroup: {
        "& > button": {
            margin: theme.spacing.xSmall,
        },
    },
    noResponse: {
        color: theme.colors.error,
        fontWeight: 500,
        lineHeight: "24px",
        marginRight: theme.spacing.small,
    },
}));

interface IAmendDeadlineButtonsProps {
    canAmendDate: boolean;
    deadlineHasPassed: boolean;
    isAmendingDate: boolean;
    setIsAmendingDate: (value: boolean) => void;
    dirty: boolean;
    isValid: boolean;
}

const AmendDeadlineButtons: React.FC<IAmendDeadlineButtonsProps> = (props) => {
    const classes = useStyles();

    const { canAmendDate, deadlineHasPassed, isAmendingDate, setIsAmendingDate, dirty, isValid } = props;

    const { t } = useTranslation();

    return (
        <div className={classes.buttonGroup}>
            {!isAmendingDate && canAmendDate && (
                <React.Fragment>
                    {deadlineHasPassed && (
                        <span className={classes.noResponse}>{t("components.amendDeadline.noResponse")}</span>
                    )}
                    <Button
                        testid="change-deadline-button"
                        variant="outline"
                        label={
                            deadlineHasPassed
                                ? t("components.amendDeadline.extend")
                                : t("components.amendDeadline.amend")
                        }
                        onClick={() => setIsAmendingDate(true)}
                        size="small"
                        type="button"
                    />
                </React.Fragment>
            )}

            {isAmendingDate && (
                <React.Fragment>
                    <Button
                        testid="change-deadline-submit-button"
                        label={t("common.save")}
                        disabled={!dirty || !isValid}
                        size="small"
                        type="submit"
                    />
                    <Button
                        testid="change-deadline-cancel-button"
                        variant="outline"
                        label={t("common.cancel")}
                        size="small"
                        type="reset"
                    />
                </React.Fragment>
            )}
        </div>
    );
};

export default AmendDeadlineButtons;
