import { Button, Copy, Heading, TextField } from "@maistro/components";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";
import { BuyerSupplierRelationshipDto } from "types/dtos/company/buyer-supplier-relationships/BuyerSupplierRelationshipDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    copy: {
        marginTop: theme.spacing.small,
        marginBottom: theme.spacing.small,
    },
    submitButton: {
        marginTop: theme.spacing.small,
    },
}));

interface ISupplierExternalIdProps extends ICommonProps {
    values: BuyerSupplierRelationshipDto;
    submit: (values: BuyerSupplierRelationshipDto) => void;
}

const SupplierExternalIdForm: React.FC<ISupplierExternalIdProps> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Heading variant="h2">{t("companySuppliers.labels.externalId")}</Heading>
            <Copy className={classes.copy}>{t("companySuppliers.externalId.copy")}</Copy>
            <Formik
                initialValues={{
                    ...props.values,
                    externalId: props.values.externalId ? props.values.externalId : "",
                }}
                onSubmit={(values) => props.submit(values)}
            >
                <Form>
                    <TextField
                        testid="supplier-external-id"
                        label={t("companySuppliers.labels.externalId")}
                        name="externalId"
                    />
                    <Button
                        className={classes.submitButton}
                        label={t("common.submit")}
                        type="submit"
                        testid="supplier-external-id-submit-button"
                    />
                </Form>
            </Formik>
        </React.Fragment>
    );
};

export default SupplierExternalIdForm;
