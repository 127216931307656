import { IPaginationState, Popup, useToaster } from "@maistro/components";
import { uniqBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { addIdentifiedSupplier, removeProjectSupplier } from "api/project/projectSubmissionsApi";
import { IOptionProps } from "components/shared";
import useProject from "features/project/hooks/useProject";
import SupplierMatchingDisplay from "features/project/supplierMatching/SupplierMatchingDisplay";
import {
    excludeByCompanySize,
    excludeByMatchRating,
    excludeByRelationshipStatus,
    excludeByTieringStatus,
} from "features/project/supplierMatching/filters/FilterList";
import useSupplierMatching from "features/project/supplierMatching/hooks/useSupplierMatching";
import WithLoadingScreen from "hoc/WithLoadingScreen";
import useAppDispatch from "hooks/useAppDispatch";
import useReduxSelector from "hooks/useReduxSelector";
import { resetLayout } from "store/layoutSlice";
import {
    IFilterValues,
    fetchIdentifiedSuppliers,
    fetchPotentialSuppliers,
    selectSupplier,
    setFilters,
    unselectSupplier,
} from "store/supplierMatchingSlice";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { PotentialSupplierDto } from "types/dtos/projectSuppliers/PotentialSupplierDto";

const SupplierMatchingContainer: React.FC = () => {
    const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
    const [isTieringPopupOpen, setIsTieringPopupOpen] = useState(false);
    const [isSupplierListUpdating, setIsSupplierListUpdating] = useState(false);
    const [suppliersPagination, setSuppliersPagination] = useState<IPaginationState>({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: 10,
    });
    const [totalSuppliers, setTotalSuppliers] = useState<PotentialSupplierDto[]>([]);

    const { projectInformation } = useReduxSelector((state) => state.projectState);
    const { filters } = useReduxSelector((state) => state.supplierMatchingState);
    const { potentialSuppliers, selectedSuppliers, unselectedSuppliers } = useReduxSelector(
        (state) => state.supplierMatchingState,
    );

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const toast = useToaster();

    const { cancelProject, isSubmitting } = useProject();
    const { dispatchLayout, continueWithInvite, tieringsToReview } = useSupplierMatching(
        projectInformation,
        projectInformation.uuid,
    );

    useEffect(() => {
        const suppliers = uniqBy([...potentialSuppliers, ...unselectedSuppliers], "companyUuid");
        suppliers.sort((prevSupplier, nextSupplier) => {
            if (nextSupplier.matchScore === prevSupplier.matchScore) {
                return prevSupplier.companyName.localeCompare(nextSupplier.companyName);
            }
            return nextSupplier.matchScore - prevSupplier.matchScore;
        });
        setTotalSuppliers(suppliers);
    }, [potentialSuppliers, unselectedSuppliers]);

    useEffect(() => {
        dispatchLayout(totalSuppliers, selectedSuppliers, setIsTieringPopupOpen);

        return () => {
            dispatch(resetLayout());
        };
    }, [dispatchLayout, totalSuppliers, selectedSuppliers, dispatch]);

    const fetchData = useCallback(async () => {
        if (!projectInformation.uuid) return;
        dispatch(fetchPotentialSuppliers(projectInformation.uuid)).catch(() =>
            toast.error(t("projectMatchingScreen.api.supplierLoadingError")),
        );
        dispatch(fetchIdentifiedSuppliers(projectInformation.uuid)).catch(() =>
            toast.error(t("projectMatchingScreen.api.supplierLoadingError")),
        );
    }, [projectInformation.uuid, dispatch, toast, t]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const filter = useCallback(
        (values: IFilterValues) => {
            dispatch(setFilters(values));
        },
        [dispatch],
    );

    const filteredSuppliers = useMemo(() => {
        if (!filters) return totalSuppliers;

        return totalSuppliers.filter((supplier) => {
            if (
                excludeByMatchRating(supplier, filters.matchRating) ||
                excludeByRelationshipStatus(supplier, filters.relationshipStatuses) ||
                excludeByTieringStatus(supplier, filters.tieringStatuses) ||
                excludeByCompanySize(supplier, filters.companySizes)
            ) {
                return false;
            }

            return true;
        });
    }, [totalSuppliers, filters]);

    const onNavigateToPage = (page: number) => {
        setSuppliersPagination({ ...suppliersPagination, currentPage: page });
    };

    useEffect(() => {
        setSuppliersPagination((s) => {
            const totalPages = Math.ceil(filteredSuppliers.length / s.itemsPerPage);
            return {
                ...s,
                totalItems: filteredSuppliers.length,
                currentPage: s.currentPage <= totalPages ? s.currentPage : Math.max(totalPages, 1),
            };
        });
    }, [filteredSuppliers]);

    const reSelectSupplier = async (supplierUuid: string) => {
        if (!projectInformation.uuid) return;

        setIsSupplierListUpdating(true);

        const response = await addIdentifiedSupplier(projectInformation.uuid, supplierUuid);

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("projectMatchingScreen.api.selectSupplierError"));
            setIsSupplierListUpdating(false);
            return;
        }

        dispatch(selectSupplier(supplierUuid));
        setIsSupplierListUpdating(false);
    };

    const unselectSelectedSupplier = async (supplierUuid: string) => {
        if (!projectInformation.uuid) return;

        setIsSupplierListUpdating(true);
        await removeProjectSupplier(projectInformation.uuid, supplierUuid).then(() => {
            dispatch(unselectSupplier(supplierUuid));
            setIsSupplierListUpdating(false);
        });
    };

    const handleAddNonMatchedSupplier = async (supplier?: IOptionProps) => {
        if (projectInformation.uuid && supplier) {
            setIsSupplierListUpdating(true);
            const response = await addIdentifiedSupplier(projectInformation.uuid, supplier.value);
            if (response instanceof TransactionErrorDto) {
                toast.error(t("projectMatchingScreen.api.addSupplierError"));
            } else {
                toast.success(t("projectMatchingScreen.api.addSupplierSuccess"));
                fetchData();
            }
            setIsSupplierListUpdating(false);
        }
    };

    return (
        <React.Fragment>
            <SupplierMatchingDisplay
                hasMatches={totalSuppliers.length > 0}
                suppliers={filteredSuppliers.slice(
                    (suppliersPagination.currentPage - 1) * suppliersPagination.itemsPerPage,
                    suppliersPagination.currentPage * suppliersPagination.itemsPerPage,
                )}
                filteredSuppliers={filteredSuppliers}
                selectSupplier={reSelectSupplier}
                unselectSupplier={unselectSelectedSupplier}
                selectedSuppliers={selectedSuppliers}
                projectUuid={projectInformation.uuid}
                cancelProject={() => setIsCancelPopupOpen(true)}
                projectInformation={projectInformation}
                updateFilters={filter}
                handleAddNonMatchedSupplier={handleAddNonMatchedSupplier}
                onNavigateToPage={onNavigateToPage}
                suppliersPagination={suppliersPagination}
                isSupplierListUpdating={isSupplierListUpdating}
            />
            <Popup
                title={t("popups.cancelProject.title")}
                message={t("popups.cancelProject.message")}
                isOpen={isCancelPopupOpen}
                primaryActionText={t("popups.cancelProject.cta.primary")}
                onPrimaryAction={cancelProject}
                onClose={() => setIsCancelPopupOpen(false)}
                testid="cancel-project-popup"
                disabled={isSubmitting}
            />
            <Popup
                title={t("popups.approvals.tieringWarning.title")}
                message={t("popups.approvals.tieringWarning.message", {
                    tierings: tieringsToReview.join(", "),
                })}
                isOpen={isTieringPopupOpen}
                primaryActionText={t("popups.approvals.tieringWarning.cta.primary")}
                secondaryActionText={t("popups.approvals.tieringWarning.cta.secondary")}
                onPrimaryAction={() => continueWithInvite(projectInformation.type)}
                onSecondaryAction={() => setIsTieringPopupOpen(false)}
                onClose={() => setIsTieringPopupOpen(false)}
                disabled={isSubmitting}
                variant="warning"
                testid="tiering-warning-popup"
            />
        </React.Fragment>
    );
};

export default WithLoadingScreen(SupplierMatchingContainer, {
    titleKey: "projectMatchingScreen.loading.title",
    messageKey: "projectMatchingScreen.loading.text",
    duration: 5000,
});
