import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { Icon } from "@maistro/components";
import React from "react";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    error: {
        ...theme.typography.content.error,
        display: "flex",
        alignItems: "center",
        gap: theme.spacing.units(0.5),
        marginTop: theme.spacing.units(0.5),
    },
}));

interface IMaistroFormikInputErrorProps extends ICommonProps {
    text: string | undefined;
    isVisible: boolean;
}

const MaistroFormikInputError: React.FC<IMaistroFormikInputErrorProps> = (props) => {
    const classes = useStyles();

    const { text, isVisible, testid } = props;

    if (!isVisible) return null;

    return (
        <div className={classes.error} data-testid={testid}>
            <Icon icon={faExclamationTriangle} size="small" />
            {text}
        </div>
    );
};

export default MaistroFormikInputError;
