import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getCompanyDetails } from "api/company/companyApi";
import { getEnabledCompanyFeatures } from "api/company/featureTogglesApi";
import { CompanyDetailsDto } from "types/dtos/company/CompanyDetailsDto";
import { CompanyListFilterDto } from "types/dtos/company/CompanyListFilterDto";
import { CompanyFeaturesDto } from "types/dtos/company/feature-toggles/companyFeaturesDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

export interface ICompanyState {
    features: CompanyFeaturesDto;
    details: CompanyDetailsDto;
    filter: CompanyListFilterDto;
}

const emptyFilters: CompanyListFilterDto = {
    industryTypes: [],
    searchByEmail: "",
    companyTypes: [],
    statuses: [],
    searchTerm: "",
    excludeSupplierListBuyerUuid: null,
};

export const fetchCompanyFeatures = createAsyncThunk<CompanyFeaturesDto, string | undefined>(
    "company/fetchCompanyFeatures",
    async (companyUuid, thunkAPI) => {
        if (!companyUuid) {
            return thunkAPI.rejectWithValue("No company uuid.");
        }

        const response = await getEnabledCompanyFeatures(companyUuid);
        if (response.status !== 200 || response.data instanceof TransactionErrorDto) {
            return thunkAPI.rejectWithValue(response.data);
        }
        return response.data;
    },
);

export const fetchCompanyDetails = createAsyncThunk<CompanyDetailsDto, string | undefined>(
    "company/fetchCompanyProfile",
    async (companyUuid, thunkAPI) => {
        if (!companyUuid) {
            return thunkAPI.rejectWithValue("No company uuid.");
        }

        const response = await getCompanyDetails(companyUuid);
        if (response.status !== 200) {
            return thunkAPI.rejectWithValue(response.data);
        }
        return response.data;
    },
);

export const companySlice = createSlice({
    name: "company",
    initialState: {
        features: {} as CompanyFeaturesDto,
        profile: {} as CompanyDetailsDto,
        filters: emptyFilters,
    },
    reducers: {
        setCurrentFilters: (state, action: PayloadAction<CompanyListFilterDto>) => {
            state.filters = action.payload;
        },
        resetCurrentFilters: (state) => {
            state.filters = {
                ...emptyFilters,
                searchTerm: state.filters.searchTerm,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCompanyFeatures.fulfilled, (state, action) => {
            state.features = action.payload;
        });
        builder.addCase(fetchCompanyDetails.fulfilled, (state, action) => {
            state.profile = action.payload;
        });
    },
});

const { actions, reducer } = companySlice;

export const { setCurrentFilters, resetCurrentFilters } = actions;

export default reducer;
