import { from, Route } from "routes/helpers/RoutesHelper";

interface ProjectResponseParams {
    projectUuid: string;
    responseUuid: string;
}

interface QuestionParams {
    questionUuid: string;
}

const projectResponseRoot: Route<ProjectResponseParams> = {
    path: "/projects/:projectUuid/response/:responseUuid",
    translationKey: "projectResponse",
};

const viewClarificationQuestion = from(projectResponseRoot, {
    path: "/questions/:questionUuid",
} as Route<QuestionParams>);

const askClarificationQuestion = from(projectResponseRoot, {
    path: "/questions/ask",
});
const brief = from(projectResponseRoot, {
    path: "/brief",
    translationKey: "projectBrief",
});
const clarificationQuestions = from(projectResponseRoot, {
    path: "/questions",
    translationKey: "clarificationQuestions",
});
const projectNotFound = from(projectResponseRoot, { path: "/*" });
const tenderQuestions = from(projectResponseRoot, {
    path: "/tender-questions",
    translationKey: "tenderQuestions",
});
const thankYou = from(projectResponseRoot, { path: "/thank-you" });

export const projectsResponseRoute = from(projectResponseRoot, { path: "/*" });
export default {
    projectResponse: projectResponseRoot,
    askClarificationQuestion,
    viewClarificationQuestion,
    brief,
    clarificationQuestions,
    projectNotFound,
    tenderQuestions,
    thankYou,
};
