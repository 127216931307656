import { createUseStyles } from "react-jss";

import { ITheme } from "styles/themes/types";

const useQuestionStyles = createUseStyles((theme: ITheme) => ({
    copy: {
        marginBottom: theme.spacing.xLarge,
    },
    flexContainer: {
        display: "block",
    },
    sectionRow: {
        flex: "auto",
        marginBottom: theme.spacing.small,
        "& .expanded": {
            marginBottom: theme.spacing.medium,
        },
    },
    questionContainer: {
        display: "flex",
        gap: theme.spacing.small,
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: theme.spacing.xSmall,
        marginBottom: theme.spacing.small,
    },
    questionRow: {
        width: "100%",
    },
    questionText: {
        gridArea: "copy",
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineClamp: 2,
        display: "box",
        boxOrient: "vertical",
        marginBottom: theme.spacing.medium,
        marginRight: theme.spacing.medium,
        fontWeight: 500,
    },
    buttonContainer: {
        width: "100%",
        textAlign: "center",
        marginTop: theme.spacing.medium,
    },
    button: {
        marginLeft: theme.spacing.small,
        marginBottom: theme.spacing.small,
        height: theme.layout.navBarHeight,
        minWidth: 145,
        flexGrow: 0,
        flexShrink: 0,
    },
    editQuestionDrawer: {},

    [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        editQuestionDrawer: {
            width: 460,
        },
    },

    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        questionContainer: {
            flexDirection: "row",
            alignItems: "center",
            marginBottom: theme.spacing.xSmall,
        },
        questionText: {
            display: "block",
            lineClamp: 1,
            whiteSpace: "nowrap",
            marginBottom: 0,
        },
    },

    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        flexContainer: {
            display: "flex",
        },
    },
}));

export default useQuestionStyles;
