import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const BetaPaths: React.FC = () => {
    const { pathname } = useLocation();
    const targetPath = pathname.substring(2);

    return <Navigate to={targetPath} />;
};

export default BetaPaths;
