import { faStar as faStarOutline } from "@fortawesome/pro-regular-svg-icons";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";
import { v4 as uuidv4 } from "uuid";

import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";

interface IMaistroRatingProps extends ICommonProps {
    testid: string;
    currentRating: number;
    showEmptyStars?: boolean;
}

const useStyles = createUseStyles((theme: ITheme) => ({
    star: {
        fontSize: 14,

        "&:not(:last-child)": {
            marginRight: 2,
        },
    },

    filled: {
        color: theme.colors.stars,
    },

    outline: {
        color: theme.colors.gray,
    },
}));

const MaistroRating: React.FC<IMaistroRatingProps> = (props) => {
    const classes = useStyles();

    const { currentRating, showEmptyStars, testid } = props;

    const stars = Math.ceil(currentRating);

    return (
        <span data-component="MaistroRating" data-testid={testid}>
            {[...Array(stars)].map(() => {
                return (
                    <FontAwesomeIcon
                        key={`${uuidv4()}`}
                        className={classNames(classes.star, classes.filled)}
                        icon={faStar}
                    />
                );
            })}
            {showEmptyStars &&
                [...Array(5 - stars)].map(() => {
                    return (
                        <FontAwesomeIcon
                            key={`${uuidv4()}`}
                            className={classNames(classes.star, classes.outline)}
                            icon={faStarOutline}
                        />
                    );
                })}
        </span>
    );
};

MaistroRating.defaultProps = {
    showEmptyStars: true,
};

export default MaistroRating;
