import { faPenToSquare, faPaperclip, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { Copy, Icon } from "@maistro/components";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { Paper } from "components";
import MaistroFormikPassIcon from "components/FormikFields/MaistroFormikPassIcon";
import { ICommonProps } from "components/shared";
import useQuestionStyles from "features/project/shared/tender-questions/useQuestionStyles";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    gridContainer: {
        display: "grid",
        width: "100%",
        gridTemplateAreas: '"copy copy" "icons completed"',
        alignItems: "center",
        gap: theme.spacing.xSmall,
    },
    iconsContainer: {
        display: "flex",
        gridArea: "icons",
        justifyContent: "flex-start",
        gap: theme.spacing.small,
    },
    icon: {
        display: "inline-block",
        color: theme.colors.primary,
    },
    mandatoryIcon: {
        display: "flex",
        color: theme.colors.error,
        paddingLeft: 0,
        paddingRight: theme.spacing.medium,
    },
    completedIconContainer: {
        gridArea: "completed",
        textAlign: "right",
    },
    completedIcon: {
        marginLeft: 0,
        fontSize: 25,
    },
    tooltipIcon: {
        color: theme.colors.error,
    },
    tooltipContainer: {
        position: "relative",
        "& div": {
            "& span": {
                marginLeft: 0,
            },
        },
    },
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        gridContainer: {
            gridTemplateAreas: '"completed copy copy copy copy icons"',
            gridTemplateColumns: ".20fr repeat(4,1fr) 0.75fr",
        },
        iconsContainer: {
            minWidth: 135,
            justifyContent: "flex-end",
        },
        icon: {
            "&:first-child": {
                paddingLeft: theme.spacing.small,
            },
        },
        completedIconContainer: {
            textAlign: "initial",
        },
        mandatoryIcon: {
            flexGrow: 1,
            paddingRight: 0,
            marginLeft: theme.spacing.xSmall,
        },
        tooltipContainer: {
            "& div": {
                "& span": {
                    marginLeft: theme.spacing.xSmall,
                },
            },
        },
    },
    [`@media (min-width: ${theme.breakpoints.xl}px)`]: {
        gridContainer: {
            gridTemplateAreas: '"completed copy copy copy copy copy copy icons"',
            gridTemplateColumns: ".15fr repeat(6, 1fr) 0.75fr",
        },
    },
}));

export interface IQuestionRowProps extends ICommonProps {
    questionText: string;
    onEdit?: () => void;
    onDelete?: () => void;
    hasAttachments?: boolean;
    disabled?: boolean;
    isComplete?: boolean;
    hideActionIcons?: boolean;
    gridClassName?: string;
    iconsClassName?: string;
}

const QuestionRow: React.FC<IQuestionRowProps> = (props) => {
    const classes = useStyles();

    const questionClasses = useQuestionStyles();
    const {
        onDelete,
        onEdit,
        questionText,
        className,
        hasAttachments,
        disabled,
        hideActionIcons,
        gridClassName,
        iconsClassName,
        testid,
    } = props;
    const { t } = useTranslation();

    const gridContainer = gridClassName ?? classes.gridContainer;
    const iconsContainer = iconsClassName ?? classes.iconsContainer;

    return (
        <div className={className} data-testid={props.testid} data-component="QuestionRow">
            <Paper color="gray" className={classNames(gridContainer)}>
                <div className={classes.completedIconContainer}>
                    <MaistroFormikPassIcon
                        className={classes.completedIcon}
                        isVisible={!!props.isComplete}
                        testid={`${props.testid}-complete`}
                    />
                </div>
                <Copy className={classNames(questionClasses.questionText)}>{questionText}</Copy>
                <div className={classNames(iconsContainer)}>
                    {hasAttachments && (
                        <Icon
                            icon={faPaperclip}
                            className={classNames(classes.icon)}
                            alt={t("tenderQuestions.documentAttached")}
                            testid={`${testid}-has-attachments-icon`}
                        />
                    )}
                    {!hideActionIcons && (
                        <React.Fragment>
                            <Icon
                                icon={faPenToSquare}
                                className={classNames(classes.icon)}
                                onClick={onEdit}
                                disabled={disabled}
                                testid={`${testid}-edit-question-icon`}
                            />
                            <Icon
                                icon={faTrashCan}
                                className={classNames(classes.icon)}
                                onClick={onDelete}
                                disabled={disabled}
                                testid={`${testid}-delete-question-icon`}
                            />
                        </React.Fragment>
                    )}
                </div>
            </Paper>
        </div>
    );
};

export default QuestionRow;
