import { faPaperclip, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Button, Heading, ITableColumn, Icon, Label, MeatballMenu, Table } from "@maistro/components";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import AddOrEditInsuranceDrawer from "features/company/profile/vetting/sections/companyDetailsSubSections/AddOrEditInsuranceDrawer";
import useSectionStyles from "features/company/profile/vetting/sections/useSectionStyles";
import ReactGA from "react-ga4";
import currencyService from "services/currencyService";
import dateTimeService from "services/dateTimeService";
import { InsuranceDto } from "types/dtos/company/vettingInformation/InsuranceDto";
import { VettingInformationKeys } from "types/dtos/company/vettingInformation/VettingInformationDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

interface InsuranceSubSectionProps {
    companyInsurances: InsuranceDto[];
    setCompanyInsurances: React.Dispatch<React.SetStateAction<InsuranceDto[]>>;
}

const InsuranceSubSection: React.FC<InsuranceSubSectionProps> = ({ companyInsurances, setCompanyInsurances }) => {
    const sharedClasses = useSectionStyles();

    const { t } = useTranslation();

    const [isInsuranceDrawerOpen, setIsInsuranceDrawerOpen] = useState(false);
    const [selectedInsurance, setSelectedInsurance] = useState<InsuranceDto>();

    const columns: ITableColumn[] = useMemo(
        () => [
            {
                position: 1,
                priorityLevel: 1,
                label: t("companyVettingQuestionnaire.sections.companyDetails.insurance.form.insuredEntity.label"),
                name: "insuredEntity",
                minWidth: 150,
            },
            {
                position: 2,
                priorityLevel: 2,
                label: t("companyVettingQuestionnaire.sections.companyDetails.insurance.form.insuranceType.label"),
                name: "typeId",
                render: (insurance: InsuranceDto) =>
                    t(
                        `companyVettingQuestionnaire.sections.companyDetails.insurance.form.insuranceType.options.${insurance.typeId}`,
                    ),
                minWidth: 200,
            },
            {
                position: 3,
                priorityLevel: 6,
                label: t("companyVettingQuestionnaire.sections.companyDetails.insurance.form.insuranceProvider.label"),
                name: "provider",
                minWidth: 150,
            },
            {
                position: 4,
                priorityLevel: 7,
                label: t("companyVettingQuestionnaire.sections.companyDetails.insurance.form.policyNumber.label"),
                name: "policyNumber",
                minWidth: 150,
            },
            {
                position: 5,
                priorityLevel: 4,
                label: t("companyVettingQuestionnaire.sections.companyDetails.insurance.form.expiryDate.label"),
                name: "expiryDate",
                render: (insurance: InsuranceDto) => {
                    if (dateTimeService.dateIsInPast(insurance.expiryDate)) {
                        return t("common.expired");
                    }
                    return dateTimeService.toShortDateFormat(insurance.expiryDate);
                },
                minWidth: 125,
            },
            {
                position: 6,
                priorityLevel: 3,
                label: t("companyVettingQuestionnaire.sections.companyDetails.insurance.form.limit.label"),
                name: "limit",
                render: (insurance: InsuranceDto) => (
                    <div>{currencyService.toDisplayFormat(insurance.currencyType, Number(insurance.limit))}</div>
                ),
                minWidth: 125,
            },
            {
                position: 7,
                priorityLevel: 5,
                name: "files",
                render: () => <Icon icon={faPaperclip} size="small" />,
                minWidth: 50,
            },
            {
                position: 8,
                priorityLevel: 0,
                name: "menu",
                minWidth: 50,
                render: (insurance: InsuranceDto) => (
                    <MeatballMenu
                        menuItems={[
                            {
                                label: t("common.edit"),
                                action: () => {
                                    setSelectedInsurance(insurance);
                                    setIsInsuranceDrawerOpen(true);
                                },
                            },
                            {
                                label: t("common.delete"),
                                action: () => {
                                    setCompanyInsurances((prevState) => {
                                        const currentInsurances = [...prevState];
                                        const selectedInsuranceIndex = currentInsurances.indexOf(insurance);
                                        if (selectedInsuranceIndex > -1) {
                                            currentInsurances.splice(selectedInsuranceIndex, 1);
                                        }
                                        return currentInsurances;
                                    });
                                },
                            },
                        ]}
                    />
                ),
            },
        ],
        [setCompanyInsurances, t],
    );

    return (
        <div id={VettingInformationKeys.insurances}>
            <Heading variant="h4">{t("companyVettingQuestionnaire.sections.companyDetails.insurance.heading")}</Heading>
            <div className={sharedClasses.subSection}>
                <Label
                    name="insurance"
                    content={t("companyVettingQuestionnaire.sections.companyDetails.insurance.label")}
                    required
                    testid="insurance-label"
                />
                <Button
                    testid="add-insurance-button"
                    label={t("companyVettingQuestionnaire.sections.companyDetails.insurance.addInsurance")}
                    icon={faPlus}
                    size="small"
                    onClick={() => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: `Company Vetting - Add Insurance`,
                        });
                        setIsInsuranceDrawerOpen(true);
                    }}
                />
                <AddOrEditInsuranceDrawer
                    testid="add-or-edit-insurance-drawer"
                    isOpen={isInsuranceDrawerOpen}
                    onClose={() => {
                        setIsInsuranceDrawerOpen(false);
                        setSelectedInsurance(undefined);
                    }}
                    insurance={selectedInsurance}
                    onSaveInsurance={(insurance) => {
                        if (!selectedInsurance) {
                            setCompanyInsurances((prevState) => {
                                return [...prevState, insurance];
                            });
                            setIsInsuranceDrawerOpen(false);
                            setSelectedInsurance(undefined);
                            return;
                        }
                        setCompanyInsurances((prevState) => {
                            const currentInsurances = [...prevState];
                            const selectedInsuranceIndex = currentInsurances.indexOf(selectedInsurance);
                            if (selectedInsuranceIndex > -1) {
                                currentInsurances.splice(selectedInsuranceIndex, 1);
                            }
                            currentInsurances.push(insurance);
                            return currentInsurances;
                        });
                        setIsInsuranceDrawerOpen(false);
                        setSelectedInsurance(undefined);
                    }}
                />
                <Table
                    columns={columns}
                    data={companyInsurances}
                    noDataDisplay={t("companyVettingQuestionnaire.sections.companyDetails.insurance.noData")}
                    testid="insurance-list"
                />
            </div>
        </div>
    );
};

export default InsuranceSubSection;
