import { ExpansionPanel, ReadOnlyField } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import useProfileStyles from "features/company/hooks/useProfileStyles";

interface ISummarySectionProps extends ICommonProps {
    data?: string;
}

const useStyles = createUseStyles({
    text: () => ({
        whiteSpace: "pre-wrap",
    }),
});

const AboutSection: React.FC<ISummarySectionProps> = ({ data, testid }) => {
    const componentClasses = useStyles();
    const classes = useProfileStyles();

    const { t } = useTranslation();

    return (
        <ExpansionPanel label={t("companyInformation.about.title")} testid={testid}>
            <div className={classes.row}>
                <div className={classes.column}>
                    <ReadOnlyField
                        value={data}
                        className={componentClasses.text}
                        testid="about-company"
                        useHyphenForEmptyValues
                    />
                </div>
            </div>
        </ExpansionPanel>
    );
};

export default AboutSection;
