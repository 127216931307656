import { Drawer } from "@maistro/components";
import React, { useEffect, useState } from "react";

import { getProjectUsers } from "api/projectSettingsApi";
import { ICommonProps } from "components/shared";
import useQuestionStyles from "features/project/shared/tender-questions/useQuestionStyles";
import QuestionRowForScoringBySupplier from "features/project/tenderCriteria/components/scoring/QuestionRowForScoringBySupplier";
import AnswerAccordion from "features/project/tenderCriteria/scoring/AnswerAccordion";
import ScoreBreakdownDisplay from "features/project/tenderCriteria/scoring/ScoreBreakdownDisplay";
import { AnswerDto } from "types/dtos/questions/AnswerResponseDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { ScoreResponseDto } from "types/dtos/questions/ScoreResponseDto";
import { UserRoleDto } from "types/dtos/rolesPermissions/UserRoleDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

interface IScoreTenderBySupplierQuestionRowProps extends ICommonProps {
    question: QuestionResponseDto;
    projectUuid: string;
    haveScores: (answer: AnswerDto) => boolean;
    canScoreAnswers: boolean;
    canModerateScores: boolean;
    setScore: (score: ScoreResponseDto, questionUuid: string) => void;
    expandedByDefault: boolean;
}

const ScoreTenderBySupplierQuestionRow: React.FC<IScoreTenderBySupplierQuestionRowProps> = (props) => {
    const questionClasses = useQuestionStyles();
    const { question, projectUuid, haveScores, canScoreAnswers, canModerateScores, setScore, expandedByDefault } =
        props;

    const [isExpanded, setIsExpanded] = useState(false);
    const [isScoreBreakdownDrawerOpen, setIsScoreBreakdownDrawerOpen] = useState(false);
    const [answerForBreakdown, setAnswerForBreakdown] = useState<AnswerDto>();
    const [userProjectRoles, setUserProjectRoles] = useState<UserRoleDto[]>([]);

    useEffect(() => {
        setIsExpanded(expandedByDefault);
        const getProjectUsersAsync = async () => {
            const projectUsersResponse = await getProjectUsers(projectUuid);
            if (projectUsersResponse.data instanceof TransactionErrorDto || projectUsersResponse.status !== 200) {
                return;
            }
            setUserProjectRoles(
                projectUsersResponse.data.projectUsers.map((pu) => ({ ...pu.role, userUuid: pu.userUuid })),
            );
        };

        if (canModerateScores) {
            getProjectUsersAsync();
        }
    }, [canModerateScores, expandedByDefault, projectUuid]);

    const isComplete = question.answers.every(haveScores);

    const showScoreBreakdown = (answer: AnswerDto) => {
        setAnswerForBreakdown(answer);
        setIsScoreBreakdownDrawerOpen(true);
    };

    return (
        <div className={questionClasses.sectionRow}>
            <QuestionRowForScoringBySupplier
                className={questionClasses.questionRow}
                testid={`question-${question.questionUuid}`}
                title={question.questionText}
                isComplete={isComplete}
                onToggleExpand={setIsExpanded}
                expandedByDefault={isExpanded}
            />
            {isExpanded &&
                (canScoreAnswers || canModerateScores) &&
                question.answers.map((answer) => (
                    <AnswerAccordion
                        key={answer.answerUuid}
                        projectUuid={projectUuid}
                        questionUuid={question.questionUuid}
                        answer={answer}
                        setScore={(value) => {
                            setScore(value, question.questionUuid);
                        }}
                        expandedByDefault
                        canScore={canScoreAnswers}
                        canModerateScore={canModerateScores}
                        showScoreBreakdown={showScoreBreakdown}
                        hideSupplierHeaderVisible
                    />
                ))}

            <Drawer
                isOpen={isScoreBreakdownDrawerOpen}
                onClose={() => setIsScoreBreakdownDrawerOpen(false)}
                withCloseFooter
                testid="score-breakdown-drawer"
            >
                {answerForBreakdown && (
                    <ScoreBreakdownDisplay
                        scores={answerForBreakdown.scores ?? []}
                        supplierName={answerForBreakdown.respondingCompanyName}
                        userProjectRoles={userProjectRoles}
                    />
                )}
            </Drawer>
        </div>
    );
};
export default ScoreTenderBySupplierQuestionRow;
