import { BooleanOptionsField, Heading, TextField } from "@maistro/components";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import DisplayVettingFileUploadField from "features/company/profile/vetting/files/DisplayVettingFileUploadField";
import { IVettingQuestionnaireForm } from "features/company/profile/vetting/IVettingQuestionnaireForm";
import ProfessionalBodyMemberships from "features/company/profile/vetting/sections/dataAndSecuritySubSections/ProfessionalBodyMemberships";
import useSectionStyles from "features/company/profile/vetting/sections/useSectionStyles";
import { ITheme } from "styles/themes/types";
import { MembershipDto } from "types/dtos/company/vettingInformation/MembershipDto";
import FileDto from "types/dtos/files/FileDto";
import { VettingInformationKeys } from "types/dtos/company/vettingInformation/VettingInformationDto";
import { Link } from "react-router-dom";
import { TFunction } from "i18next";
import { RegexEmail } from "types/consts/regexConstants";

const useStyles = createUseStyles((theme: ITheme) => ({
    conditionalQuestions: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.small,
        marginTop: theme.spacing.small,
    },
    action: {
        textDecoration: "underline",
        cursor: "pointer",
        gap: theme.spacing.xSmall,
        "&:hover": {
            color: theme.colors.highlight,
        },
    },
    guideLink: {
        display: "block",
        marginTop: theme.spacing.small,
    },
}));

export const dataProtectionValidationSchema = (t: TFunction<"translation", undefined>) =>
    Yup.object({
        hasDataProtectionBreaches: Yup.boolean().nullable(),
        breachesDetails: Yup.string().nullable(),
        hadExternalPrivacyAudit: Yup.boolean().nullable(),
        auditDetails: Yup.string().nullable(),
        hasDataProtectionPolicy: Yup.boolean().nullable(),
        microBusinessDataProtectionStatement: Yup.string().nullable(),
        hasIndividualRightsProtection: Yup.boolean().nullable(),
        hasDataProtectionOfficer: Yup.boolean().nullable(),
        dataProtectionOfficerName: Yup.string().nullable(),
        dataProtectionOfficerEmail: Yup.string()
            .matches(
                new RegExp(RegexEmail),
                t("companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.dataProtectionOfficer.invalid"),
            )
            .nullable(),
        hasStaffTrainingDataProtection: Yup.boolean().nullable(),
        ensuresSuppliersImplementDataProtection: Yup.boolean().nullable(),
        hasTradeAssociationMembership: Yup.boolean().nullable(),
        documentsPersonalData: Yup.boolean().nullable(),
    });

interface IDataProtectionSubSectionProps {
    dataProtectionBreachesFiles?: FileDto[];
    dataProtectionPolicyFiles?: FileDto[];
    externalAuditFiles?: FileDto[];
    setDataProtectionBreachesFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    setDataProtectionPolicyFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    setExternalAuditFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    memberships: MembershipDto[];
    setMemberships: React.Dispatch<React.SetStateAction<MembershipDto[]>>;
}

const DataProtectionSubSection: React.FC<IDataProtectionSubSectionProps> = ({
    dataProtectionBreachesFiles,
    dataProtectionPolicyFiles,
    externalAuditFiles,
    setDataProtectionBreachesFiles,
    setDataProtectionPolicyFiles,
    setExternalAuditFiles,
    memberships,
    setMemberships,
}) => {
    const sharedClasses = useSectionStyles();
    const classes = useStyles();

    const { t } = useTranslation();

    const [fileIsUploading, setFileIsUploading] = useState(false);

    const { values } = useFormikContext<IVettingQuestionnaireForm>();

    return (
        <div id={VettingInformationKeys.dataProtection}>
            <Heading variant="h4">
                {t("companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.title")}
            </Heading>
            <ol className={sharedClasses.listStyle} type="1">
                <li>
                    <BooleanOptionsField
                        name="hasDataProtectionBreaches"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.dataProtectionBreaches.label",
                        )}
                        testid="has-data-protection-breaches-options-field"
                    />
                    {values.hasDataProtectionBreaches && (
                        <div className={classes.conditionalQuestions}>
                            <TextField
                                name="breachesDetails"
                                label={t(
                                    "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.dataProtectionBreaches.field",
                                )}
                                testid="breaches-details-field"
                            />
                            <DisplayVettingFileUploadField
                                name="breachesFiles"
                                files={dataProtectionBreachesFiles}
                                setFiles={setDataProtectionBreachesFiles}
                                fileIsUploading={fileIsUploading}
                                setFileIsUploading={setFileIsUploading}
                                multiple={false}
                                testid="data-protection-breaches-file-upload-field"
                            />
                        </div>
                    )}
                </li>
                <li>
                    <BooleanOptionsField
                        name="hadExternalPrivacyAudit"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.externalPrivacyAudit.label",
                        )}
                        testid="had-external-privacy-audit-options-field"
                    />
                    {values.hadExternalPrivacyAudit && (
                        <div className={classes.conditionalQuestions}>
                            <TextField
                                name="auditDetails"
                                label={t(
                                    "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.externalPrivacyAudit.field",
                                )}
                                testid="audit-details-field"
                            />
                            <DisplayVettingFileUploadField
                                name="externalAuditFiles"
                                files={externalAuditFiles}
                                setFiles={setExternalAuditFiles}
                                fileIsUploading={fileIsUploading}
                                setFileIsUploading={setFileIsUploading}
                                multiple={false}
                                testid="external-audit-file-upload-field"
                            />
                        </div>
                    )}
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasDataProtectionPolicy"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.dataProtectionPolicy.label",
                        )}
                        testid="has-data-protection-policy-options-field"
                    />
                    {values.hasDataProtectionPolicy && (
                        <div className={classes.conditionalQuestions}>
                            <DisplayVettingFileUploadField
                                name="dataProtectionPolicyFiles"
                                label={
                                    <>
                                        {t(
                                            "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.dataProtectionPolicy.files",
                                        )}
                                        <span className={classes.guideLink}>
                                            <Trans
                                                i18nKey={t(
                                                    "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.dataProtectionPolicy.guide",
                                                )}
                                                components={{
                                                    1: (
                                                        <Link
                                                            className={classes.action}
                                                            to="https://www.gov.uk/data-protection"
                                                            target="_blank"
                                                        />
                                                    ),
                                                }}
                                            />
                                        </span>
                                    </>
                                }
                                files={dataProtectionPolicyFiles}
                                setFiles={setDataProtectionPolicyFiles}
                                fileIsUploading={fileIsUploading}
                                setFileIsUploading={setFileIsUploading}
                                testid="data-protection-policy-file-upload-field"
                            />
                            <TextField
                                name="microBusinessDataProtectionStatement"
                                label={t(
                                    "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.dataProtectionPolicy.field",
                                )}
                                testid="micro-business-data-protection-statement-field"
                            />
                        </div>
                    )}
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasIndividualRightsProtection"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.rightsProtection.label",
                        )}
                        testid="has-individual-rights-protection-options-field"
                    />
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasDataProtectionOfficer"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.dataProtectionOfficer.label",
                        )}
                        testid="has-data-protection-officer-options-field"
                    />
                    {values.hasDataProtectionOfficer && (
                        <div className={classes.conditionalQuestions}>
                            <TextField
                                name="dataProtectionOfficerName"
                                label={t(
                                    "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.dataProtectionOfficer.field",
                                )}
                                placeholder={t(
                                    "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.dataProtectionOfficer.name",
                                )}
                                testid="data-protection-officer-name-field"
                            />
                            <TextField
                                name="dataProtectionOfficerEmail"
                                placeholder={t(
                                    "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.dataProtectionOfficer.emailAddress",
                                )}
                                testid="data-protection-officer-email-field"
                            />
                        </div>
                    )}
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasStaffTrainingDataProtection"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.staffTraining.label",
                        )}
                        testid="has-staff-training-options-field"
                    />
                </li>
                <li>
                    <BooleanOptionsField
                        name="ensuresSuppliersImplementDataProtection"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.suppliersImplementProcedures.label",
                        )}
                        testid="ensures-suppliers-implement-data-protection-field"
                    />
                </li>
                <li>
                    <ProfessionalBodyMemberships
                        hasTradeAssociationMembership={values.hasTradeAssociationMembership}
                        memberships={memberships}
                        setMemberships={setMemberships}
                    />
                </li>
                <li>
                    <BooleanOptionsField
                        name="documentsPersonalData"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.documentsPersonalData.label",
                        )}
                        testid="documents-personal-data-field"
                    />
                </li>
            </ol>
        </div>
    );
};

export default DataProtectionSubSection;
