import classNames from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import colorService from "services/colorService";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    row: {
        ...theme.typography.base,
    },

    tHead: {},

    tBody: {
        backgroundColor: colorService.toHexWithOpacity(theme.colors.gray, 0.2),

        "& td": {
            verticalAlign: "middle",
            padding: theme.spacing.small,
            fontWeight: 500,

            "&:first-child": {
                paddingLeft: theme.spacing.medium,
                borderTopLeftRadius: theme.spacing.medium,
                borderBottomLeftRadius: theme.spacing.medium,
            },

            "&:last-child": {
                paddingRight: theme.spacing.medium,
                borderTopRightRadius: theme.spacing.medium,
                borderBottomRightRadius: theme.spacing.medium,
            },
        },
    },
}));

interface ITableRowProps extends ICommonProps {
    tHead?: boolean;
    onClick?: () => void;
    children?: React.ReactNode;
}

const TableRow: React.FC<ITableRowProps> = (props) => {
    const classes = useStyles();

    const { tHead, onClick, testid } = props;

    return (
        <tr
            className={classNames(
                classes.row,
                props.className,
                { [classes.tHead]: tHead },
                { [classes.tBody]: !tHead },
            )}
            onClick={onClick}
            data-component="TableRow"
            data-testid={testid}
        >
            {props.children}
        </tr>
    );
};

TableRow.defaultProps = {
    tHead: false,
};

export default TableRow;
