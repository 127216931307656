import { SelectField, useToaster } from "@maistro/components";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useLocation } from "react-router-dom";

import { getEmbeddedReport, getReportList } from "api/reportingApi";
import { IOptionProps } from "components/shared";
import EmbedContainer from "features/reports/EmbedContainer";
import useAppDispatch from "hooks/useAppDispatch";
import useReportOptions from "hooks/useReportOptions";
import ReactGA from "react-ga4";
import { resetLayout, setBack, setPageTitle } from "store/layoutSlice";
import { ITheme } from "styles/themes/types";
import EmbeddedReportResponseDto from "types/dtos/reports/EmbeddedReportResponseDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import ReportCode from "types/enums/reports/ReportCode";

const useStyles = createUseStyles((theme: ITheme) => ({
    selectContainer: {
        maxWidth: 350,
        marginBottom: theme.spacing.large,
    },
}));

const Reports: React.FC = () => {
    const classes = useStyles();

    const [reportOptions, setReportOptions] = useState<Array<IOptionProps>>([]);
    const [report, setReport] = useState<EmbeddedReportResponseDto>();
    const [initialReportCode, setInitialReportCode] = useState("");

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const toast = useToaster();
    const { state } = useLocation();

    const { isReportMobileLayout } = useReportOptions();

    useEffect(() => {
        dispatch(resetLayout());
        dispatch(setPageTitle(t("reports.title")));
        dispatch(
            setBack(
                state
                    ? {
                          route: state.route,
                      }
                    : null,
            ),
        );
    }, [t, dispatch, state]);

    const fetchReportOptions = useCallback(async () => {
        const response = await getReportList();

        if (response.status !== 200) {
            toast.error(t("reports.api.reportListError"));
            setReportOptions([]);
            return;
        }

        const reports = response.data._embedded.items.map((option) => ({
            value: option.reportCode,
            label: option.reportName,
        }));

        setReportOptions(reports);
        setInitialReportCode(ReportCode.SupplyChainHealthCheck);
    }, [t, toast]);

    const fetchEmbeddedReport = (reportCode: string) => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Reports - `.concat(reportCode),
        });
        if (reportCode === "") return;

        getEmbeddedReport(reportCode).then((embeddedReport) => {
            setReport(embeddedReport.data);
        });
    };

    useEffect(() => {
        fetchReportOptions();
    }, [fetchReportOptions]);

    useEffect(() => fetchEmbeddedReport(initialReportCode), [initialReportCode]);

    return (
        <>
            <Formik
                initialValues={{
                    report: initialReportCode,
                }}
                onSubmit={(values) => fetchEmbeddedReport(values.report)}
                enableReinitialize
            >
                <Form className={classes.selectContainer}>
                    <SelectField
                        testid="report-select"
                        name="report"
                        options={reportOptions}
                        onChange={(value) => fetchEmbeddedReport(value)}
                        withoutValidation
                    />
                </Form>
            </Formik>
            {report && <EmbedContainer report={report} isMobileLayoutScreensize={isReportMobileLayout} />}
        </>
    );
};

export default Reports;
