import { createUseStyles } from "react-jss";
import { ITheme } from "styles/themes/types";

const useDrawerStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
    buttons: {
        display: "flex",
        justifyContent: "center",
    },
}));

export default useDrawerStyles;
