import { faArrowToBottom } from "@fortawesome/pro-regular-svg-icons";
import { Button, Icon, ITableColumn } from "@maistro/components";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { IProjectInformation } from "features/project/types";
import dateTimeService from "services/dateTimeService";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    content: {
        marginTop: theme.spacing.small,
    },
    alignRight: {
        textAlign: "right",
    },
    button: {
        display: "none",
    },
    icon: {},

    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        button: {
            display: "revert",
        },
        icon: {
            display: "none",
        },
    },
}));

const useCompleteProjectConfiguration = (openDownloadDrawer: () => void) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const columns: ITableColumn[] = [
        {
            position: 1,
            priorityLevel: 2,
            label: t("completeProject.table.projectName"),
            name: "projectName",
            minWidth: 100,
            contentWrap: true,
        },
        {
            position: 2,
            priorityLevel: 5,
            label: t("completeProject.table.projectManager"),
            name: "manager",
            minWidth: 100,
        },
        {
            position: 3,
            priorityLevel: 4,
            label: t("completeProject.table.responseDate"),
            name: "tenderResponseDeadline",
            minWidth: 100,
            render: (project: IProjectInformation) => (
                <div>{dateTimeService.toShortDateTimeFormatWithoutTz(project.tenderResponseDeadline)}</div>
            ),
        },
        {
            position: 4,
            priorityLevel: 3,
            label: t("completeProject.table.projectStatus"),
            name: "status",
            minWidth: 100,
        },
        {
            position: 5,
            priorityLevel: 1,
            name: "downloadSummary",
            minWidth: 150,
            render: () => (
                <div className={classes.alignRight}>
                    <Button
                        className={classes.button}
                        size="small"
                        variant="outline"
                        label={t("completeProject.reports")}
                        onClick={openDownloadDrawer}
                        testid="open-reports-drawer-button"
                    />

                    <Icon className={classes.icon} icon={faArrowToBottom} onClick={openDownloadDrawer} size="small" />
                </div>
            ),
        },
    ];

    return { columns };
};

export default useCompleteProjectConfiguration;
