import { Button, Drawer, Heading, Pill, ReadOnlyField } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { faCheck, faXmark } from "@fortawesome/pro-regular-svg-icons";

import { ICommonProps } from "components/shared";
import currencyService from "services/currencyService";
import { UserApprovalDto } from "types/dtos/company/approvals/UserApprovalDto";
import ProjectType from "types/enums/projects/ProjectType";
import useDrawerStyles from "features/company/buyer/approvals/styles/useDrawerStyles";
import dateTimeService from "services/dateTimeService";

interface IPolicyInformationDrawerProps extends ICommonProps {
    approval: UserApprovalDto | undefined;
    isOpen: boolean;
    onClose: () => void;
}

const PolicyInformationDrawer: React.FC<IPolicyInformationDrawerProps> = ({ approval, isOpen, onClose }) => {
    const classes = useDrawerStyles();

    const { t } = useTranslation();

    return (
        <Drawer
            contentClassName={classes.container}
            isOpen={isOpen}
            onClose={onClose}
            testid="policy-information-drawer"
        >
            <Heading variant="h2">{t("approvals.policyInformationDrawer.title")}</Heading>
            {approval?.isActioned && (
                <Pill
                    value={
                        approval?.isApproved
                            ? t("approvals.policyInformationDrawer.approved")
                            : t("approvals.policyInformationDrawer.rejected")
                    }
                    icon={approval?.isApproved ? faCheck : faXmark}
                    testid="decision-pill"
                />
            )}
            <ReadOnlyField
                label={t("approvals.policyInformationDrawer.projectName")}
                value={approval?.projectName}
                testid="project-manager"
            />
            <ReadOnlyField
                label={t("approvals.policyInformationDrawer.policyName")}
                value={t("approvals.table.spendLimitName", {
                    options: {
                        name: approval?.policy.name,
                        limit: currencyService.toDisplayFormat(
                            approval?.policy.spendLimit?.currency,
                            approval?.policy.spendLimit?.limit,
                        ),
                    },
                })}
                testid="policy-name"
            />
            <ReadOnlyField
                label={t(
                    `approvals.policyInformationDrawer.${
                        approval?.projectType === ProjectType.Tender ? "totalContractValue" : "totalPrice"
                    }`,
                )}
                value={
                    <div>
                        {currencyService.toDisplayFormat(
                            approval?.policy.spendLimit?.currency,
                            approval?.submissionPrice,
                        )}
                        <br />
                        <ReadOnlyField value={approval?.submissionNotes} testid="submission-notes" />
                    </div>
                }
                testid="submission-price"
            />
            {approval?.isActioned && (
                <>
                    <ReadOnlyField
                        label={t("approvals.policyInformationDrawer.reason")}
                        value={approval?.message}
                        testid="reason"
                    />
                    <ReadOnlyField
                        label={t("approvals.policyInformationDrawer.date")}
                        value={dateTimeService.toShortDateTimeFormat(approval?.updatedOn)}
                        testid="actionedOn"
                    />
                </>
            )}
            <div className={classes.buttons}>
                <Button label={t("common.close")} onClick={onClose} testid="close-button" />
            </div>
        </Drawer>
    );
};

export default PolicyInformationDrawer;
