import { Heading, IOption, SelectField, useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useSectionStyles from "features/company/profile/vetting/sections/useSectionStyles";
import { useLazySearchGeographicLocationsQuery } from "store/api/referenceDataApi";
import { VettingInformationKeys } from "types/dtos/company/vettingInformation/VettingInformationDto";

type TreeOptionItem = IOption & { parentId?: string };

const buildTreeOptions = (array: TreeOptionItem[]): TreeOptionItem[] => {
    const options = [] as TreeOptionItem[];
    array.forEach((element) => {
        if (element.parentId) {
            const parent = array.filter((elem) => elem.value === element.parentId).pop() as TreeOptionItem;
            if (!parent.options) {
                parent.options = [];
            }
            parent.options.push(element);
        } else {
            options.push(element);
        }
    });
    return options;
};

const GeographicalLocationSubSection: React.FC = () => {
    const classes = useSectionStyles();
    const { t } = useTranslation();
    const toast = useToaster();

    const [listGeographicalLocationTypes] = useLazySearchGeographicLocationsQuery();
    const [geographicalLocationTimeoutId, setGeographicalLocationTimeoutId] = useState<number>();

    const getOptionLabel = useCallback(
        (label: string) =>
            t(`companyVettingQuestionnaire.sections.companyDetails.geographicalLocations.regions.${label}`),
        [t],
    );

    const loadGeographicalLocationsPromise = useCallback(
        (searchQuery: string) =>
            new Promise<IOption[]>((resolve, reject) => {
                if (geographicalLocationTimeoutId) {
                    clearTimeout(geographicalLocationTimeoutId);
                }
                const timeoutId = window.setTimeout(() => {
                    listGeographicalLocationTypes(searchQuery, true)
                        .unwrap()
                        .then((geographicalLocationTypes) => {
                            const treeOptions = geographicalLocationTypes.map(
                                (location) =>
                                    ({
                                        label: getOptionLabel(location.name),
                                        value: location.id.toString(),
                                        parentId: location.parentId?.toString(),
                                    }) as TreeOptionItem,
                            );
                            const options = buildTreeOptions(treeOptions);
                            const parents = options
                                .filter(
                                    (option) =>
                                        option.options &&
                                        option.label.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()),
                                )
                                .map(
                                    (parent) =>
                                        ({
                                            label: parent.label,
                                            value: parent.value,
                                            parentId: parent.parentId,
                                        }) as TreeOptionItem,
                                );
                            resolve(parents.concat(options));
                        })
                        .catch(() => {
                            const errorMessage = t(
                                "companyVettingQuestionnaire.sections.companyDetails.geographicalLocations.apiError",
                            );
                            toast.error(errorMessage);
                            reject(new Error(errorMessage));
                        });
                }, 500);
                setGeographicalLocationTimeoutId(timeoutId);
            }),
        [geographicalLocationTimeoutId, getOptionLabel, listGeographicalLocationTypes, t, toast],
    );

    useEffect(() => {
        return () => {
            if (geographicalLocationTimeoutId) {
                clearTimeout(geographicalLocationTimeoutId);
            }
        };
    }, [geographicalLocationTimeoutId]);

    return (
        <div id={VettingInformationKeys.geographicLocationsTypeIds}>
            <Heading variant="h4">
                {t("companyVettingQuestionnaire.sections.companyDetails.geographicalLocations.heading")}
            </Heading>
            <div className={classes.subSection}>
                <SelectField
                    name="geographicalLocationsTypeIds"
                    label={t("companyVettingQuestionnaire.sections.companyDetails.geographicalLocations.label")}
                    tooltip={t("companyVettingQuestionnaire.sections.companyDetails.geographicalLocations.tooltip")}
                    testid="geographical-location-select"
                    isAsync
                    defaultOptions
                    onSearch={loadGeographicalLocationsPromise}
                    multiple
                    clearable
                    required
                />
            </div>
        </div>
    );
};

export default GeographicalLocationSubSection;
