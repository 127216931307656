import { Button, Copy, Label, TextAreaField } from "@maistro/components";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import { IProjectInformation } from "features/project/types";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
    },

    copy: {
        ...theme.typography.base,
        marginBottom: 10,
        maxWidth: "100%",
    },

    button: {
        marginTop: theme.spacing.large,
    },

    label: {
        marginBottom: 10,
    },

    buttons: {
        display: "flex",
        justifyContent: "center",
    },
}));

interface IAwardSummaryFormProps {
    projectInformation: IProjectInformation;
    onSubmit: (values: IProjectInformation) => void;
}

const AwardSummaryForm: React.FC<IAwardSummaryFormProps> = (props) => {
    const classes = useStyles();

    const { projectInformation, onSubmit } = props;

    const { t } = useTranslation();

    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...projectInformation,
                decisionSummary: projectInformation.decisionSummary ?? "",
            }}
            validationSchema={Yup.object({
                decisionSummary: Yup.string().required(t("awardSummary.decisionSummary.required")),
            })}
            onSubmit={onSubmit}
        >
            {({ dirty, isValid }) => (
                <Form className={classes.form}>
                    <Label
                        className={classes.label}
                        content={t("awardSummary.decisionSummary.label")}
                        name="decisionSummary"
                        required
                    />
                    <Copy className={classes.copy}>{t("awardSummary.decisionSummary.description-one")}</Copy>
                    <Copy className={classes.copy}>{t("awardSummary.decisionSummary.description-two")}</Copy>
                    <TextAreaField testid="award-summary-summary" name="decisionSummary" required />
                    <div className={classes.buttons}>
                        <Button
                            className={classes.button}
                            testid="award-summary-submit-button"
                            label={t("awardSummary.decisionSummary.complete")}
                            size="large"
                            type="submit"
                            disabled={!dirty || !isValid}
                            chevron
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default AwardSummaryForm;
