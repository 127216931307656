import {
    faAddressCard,
    faAward,
    faHandHoldingBox,
    faLocationDot,
    faPeopleSimple,
    faSquareList,
    faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useMemo, useState } from "react";

import useAppDispatch from "hooks/useAppDispatch";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { useGetCompanyDetailsQuery } from "store/api/companyApi";
import {
    IStepperNavigationItem,
    setStepperActions,
    setStepperNavigationItems,
    setStepperSubTitle,
    setStepperTitle,
} from "store/navigationSlice";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

interface ICompanyStepperNavigationProps {
    companyUuid: string;
    activeStep:
        | "companyProfile"
        | "users"
        | "addresses"
        | "services"
        | "suppliers"
        | "vettingQuestionnaire"
        | "buyerLists";
    title?: string;
    subTitle?: string;
    isActive?: boolean;
}

const useCompanyStepperNavigation = ({
    activeStep,
    title,
    subTitle,
    companyUuid,
    isActive,
}: ICompanyStepperNavigationProps) => {
    const [isCompanyProfileActionPopupOpen, setIsCompanyProfileActionPopupOpen] = useState(false);
    const { userIsMaistro, userIsSupplier } = useCurrentUser();
    const { data: companyDetails } = useGetCompanyDetailsQuery(companyUuid ?? "");

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const defaultItems: Array<IStepperNavigationItem> = useMemo(
        () => [
            {
                icon: faAddressCard,
                label: t("companyProfile.stepperNavigation.companyProfile"),
                route: buildRoute(routes.company.companyProfile, { companyUuid }),
                isActive: activeStep === "companyProfile",
            },
            {
                icon: faUser,
                label: t("companyProfile.stepperNavigation.users"),
                route: buildRoute(routes.company.companyUsers, { companyUuid }),
                isActive: activeStep === "users",
            },
            {
                icon: faLocationDot,
                label: t("companyProfile.stepperNavigation.addresses"),
                route: buildRoute(routes.company.companyAddresses, { companyUuid }),
                isActive: activeStep === "addresses",
            },
            {
                icon: faHandHoldingBox,
                label: t("companyProfile.stepperNavigation.services"),
                route: buildRoute(routes.company.companyServices, { companyUuid }),
                isActive: activeStep === "services",
            },
        ],
        [activeStep, t, companyUuid],
    );

    useEffect(() => {
        if (title) {
            dispatch(setStepperTitle(title));
        }
        if (subTitle) {
            dispatch(setStepperSubTitle(subTitle));
        }
        if (userIsMaistro) {
            let maistroStepperItems;
            if (companyDetails?.allowBuyerUsers) {
                maistroStepperItems = [
                    ...defaultItems,
                    {
                        icon: faPeopleSimple,
                        label: t("companyProfile.stepperNavigation.suppliers"),
                        route: buildRoute(routes.company.companySuppliers, { companyUuid }),
                        isActive: activeStep === "suppliers",
                    },
                ];
            } else {
                maistroStepperItems = [
                    ...defaultItems,
                    {
                        icon: faAward,
                        label: t("companyProfile.stepperNavigation.vettingQuestionnaire"),
                        route: buildRoute(routes.company.companyVetting, { companyUuid }),
                        isActive: activeStep === "vettingQuestionnaire",
                    },
                    {
                        icon: faSquareList,
                        label: t("companyProfile.stepperNavigation.buyerLists"),
                        route: buildRoute(routes.company.companyBuyerLists, { companyUuid }),
                        isActive: activeStep === "buyerLists",
                    },
                ];
            }
            dispatch(setStepperNavigationItems(maistroStepperItems));

            dispatch(
                setStepperActions([
                    {
                        label: isActive ? t("companyProfile.deactivateProfile") : t("companyProfile.activateProfile"),
                        testid: isActive ? "deactivate-company-button" : "activate-company-button",
                        onClick: () => {
                            ReactGA.event({
                                category: ga4Category.Button,
                                action: ga4Action.ButtonClick,
                                label: "Company - Deactivate Profile - ".concat(
                                    companyDetails?.registeredName || "No Name",
                                ),
                            });
                            setIsCompanyProfileActionPopupOpen(true);
                        },
                    },
                ]),
            );
        } else if (companyDetails?.allowBuyerUsers && !userIsSupplier) {
            dispatch(
                setStepperNavigationItems([
                    ...defaultItems,
                    {
                        icon: faPeopleSimple,
                        label: t("companyProfile.stepperNavigation.suppliers"),
                        route: buildRoute(routes.company.companySuppliers, { companyUuid }),
                        isActive: activeStep === "suppliers",
                    },
                ]),
            );
        } else if (companyDetails?.allowSupplierUsers) {
            dispatch(
                setStepperNavigationItems([
                    ...defaultItems,
                    {
                        icon: faAward,
                        label: t("companyProfile.stepperNavigation.vettingQuestionnaire"),
                        route: buildRoute(routes.company.companyVetting, { companyUuid }),
                        isActive: activeStep === "vettingQuestionnaire",
                    },
                ] as Array<IStepperNavigationItem>),
            );
        }
        return () => {
            dispatch(setStepperTitle());
            dispatch(setStepperSubTitle());
            dispatch(setStepperNavigationItems());
            dispatch(setStepperActions());
        };
    }, [
        activeStep,
        defaultItems,
        dispatch,
        t,
        title,
        subTitle,
        userIsMaistro,
        companyUuid,
        isActive,
        companyDetails?.allowBuyerUsers,
        companyDetails?.allowSupplierUsers,
        userIsSupplier,
        companyDetails?.registeredName,
    ]);

    return {
        isCompanyProfileActionPopupOpen,
        setIsCompanyProfileActionPopupOpen,
    };
};

export default useCompanyStepperNavigation;
