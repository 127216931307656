import { Route, from } from "routes/helpers/RoutesHelper";

const usersRoot: Route = { path: "/users" };
const logout: Route = from(usersRoot, {
    path: "/logout",
});

const users = Object.assign(usersRoot, {
    logout,
});

export default users;
