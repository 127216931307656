import { faArrowToBottom, faSpinner, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { Icon, useToaster } from "@maistro/components";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { downloadMedia } from "api/fileApi";
import { ICommonProps } from "components/shared";
import ReactGA from "react-ga4";
import colorService from "services/colorService";
import dateTimeService from "services/dateTimeService";
import fileService from "services/fileService";
import { ITheme } from "styles/themes/types";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import FileDto from "types/dtos/files/FileDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: theme.spacing.small,
    },

    separateFilesContainer: {
        display: "block",
    },

    separateFileRows: {
        marginBottom: theme.spacing.small,
    },

    row: {
        display: "flex",
        alignItems: "center",
        backgroundColor: colorService.toHexWithOpacity(theme.colors.gray, 0.2),
        padding: theme.spacing.small,
        borderRadius: theme.spacing.small,
        flexGrow: 1,
        width: "49%",
    },

    list: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
    },

    entry: {
        display: "flex",
        marginRight: theme.spacing.small,
        ...theme.typography.base,
    },

    name: {
        minWidth: "110px",
        marginRight: theme.spacing.small,
        fontWeight: 600,
    },

    value: {
        wordBreak: "break-word",
    },

    fileActions: {
        display: "flex",
        gap: theme.spacing.small,
    },

    "@media (max-width: 400px)": {
        entry: {
            flexDirection: "column",
            marginBottom: theme.spacing.small,
        },
    },
}));

export interface IFileListProps extends ICommonProps {
    files: FileDto[];
    setFiles?: React.Dispatch<React.SetStateAction<FileDto[]>>;
    canDownload?: boolean;
    canDelete?: boolean;
    removeFile?: (file: FileDto) => void;
    showDocumentNameOnly?: boolean;
    showDocumentLinkOnly?: boolean;
    separateFileRows?: boolean;
}

const FileList: React.FC<IFileListProps> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const toast = useToaster();

    const {
        files,
        setFiles,
        canDownload,
        canDelete,
        testid,
        removeFile,
        showDocumentNameOnly,
        showDocumentLinkOnly,
        separateFileRows,
    } = props;

    const [isDownloadingFile, setIsDownloadingFile] = useState<boolean>(false);
    const [downloadingFileName, setDownloadingFileName] = useState<string>("");

    const downloadFile = (file: FileDto) => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: "File List - Download File - ".concat(file.fileName),
        });
        setDownloadingFileName(file.fileName);
        setIsDownloadingFile(true);
        downloadMedia(file.fileId).then((response) => {
            if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                setIsDownloadingFile(false);
                toast.error(t("fileUpload.individualDownloadError", { fileName: file.fileName }));
                return;
            }
            const objectUrl = window.URL.createObjectURL(response.data);
            const anchor = document.createElement("a");
            anchor.href = objectUrl;
            anchor.download = `${file.fileName}${file.fileExtension}`;
            anchor.click();
            window.URL.revokeObjectURL(objectUrl);
            setDownloadingFileName("");
            setIsDownloadingFile(false);
        });
    };

    const renderRow = (file: FileDto) => {
        if (showDocumentLinkOnly && canDownload && !file.newUpload) {
            return isDownloadingFile && downloadingFileName === file.fileName ? (
                <Icon icon={faSpinner} size="medium" spin />
            ) : (
                <Icon icon={faArrowToBottom} size="medium" onClick={() => downloadFile(file)} />
            );
        }

        return (
            <div
                key={file.fileId}
                className={classNames({ [classes.separateFileRows]: separateFileRows }, classes.row)}
            >
                <ul className={classes.list}>
                    <li className={classes.entry}>
                        <span className={classes.name}>Document Name</span>
                        <span className={classes.value}>{file.fileName}</span>
                    </li>
                    {!showDocumentNameOnly && !showDocumentLinkOnly && (
                        <div>
                            {file.uploadedUser && (
                                <li className={classes.entry}>
                                    <span className={classes.name}>Uploaded By</span>
                                    <span className={classes.value}>{file.uploadedUser.value}</span>
                                </li>
                            )}
                            {file.fileExtension && (
                                <li className={classes.entry}>
                                    <span className={classes.name}>File Type</span>
                                    <span className={classes.value}>{fileService.toFileType(file.fileExtension)}</span>
                                </li>
                            )}
                            <li className={classes.entry}>
                                <span className={classes.name}>Uploaded Date</span>
                                <span className={classes.value}>
                                    {dateTimeService.toShortDateFormat(file.uploadedDateTime)}
                                </span>
                            </li>
                        </div>
                    )}
                </ul>
                <div className={classes.fileActions}>
                    {canDownload &&
                        !file.newUpload &&
                        (isDownloadingFile && downloadingFileName === file.fileName ? (
                            <Icon icon={faSpinner} spin />
                        ) : (
                            <Icon icon={faArrowToBottom} onClick={() => downloadFile(file)} />
                        ))}
                    {canDelete && setFiles && removeFile && (
                        <Icon
                            icon={faTrashAlt}
                            onClick={() => {
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "File List - Remove File - ".concat(file.fileName),
                                });
                                removeFile(file);
                            }}
                        />
                    )}
                </div>
            </div>
        );
    };

    return (
        <div
            className={classNames(props.className, {
                [classes.separateFilesContainer]: separateFileRows,
                [classes.container]: !separateFileRows,
            })}
            data-component="FileList"
            data-testid={testid}
        >
            {files && files.map((file) => renderRow(file))}
        </div>
    );
};

FileList.defaultProps = {
    canDownload: false,
    canDelete: false,
} as IFileListProps;

export default FileList;
