import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ITheme } from "styles/themes/types";
import maistroTheme from "styles/themes/maistro";

export interface IThemeState {
    themeLoaded: boolean;
    theme: ITheme;
}

const initialState = {
    themeLoaded: false,
    theme: maistroTheme,
};

export const themeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {
        setTheme: (state, action: PayloadAction<ITheme>) => {
            state.theme = action.payload;
            state.themeLoaded = true;
        },
        unloadTheme: (state) => {
            state.themeLoaded = false;
        },
    },
});

const { actions, reducer } = themeSlice;

export const { setTheme, unloadTheme } = actions;

export default reducer;
