import { Drawer, Popup, useToaster } from "@maistro/components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import useProjectSettings from "features/project/hooks/useProjectSettings";
import ProjectSettingsDisplay from "features/project/settings/ProjectSettingsDisplay";
import EditProjectUser from "features/project/shared/users/EditProjectUser";
import useAppDispatch from "hooks/useAppDispatch";
import routes from "routes/routePaths/RoutePaths";
import { setBack, setPageTitle, setSecondaryNavItems } from "store/layoutSlice";
import { ProjectUserDto } from "types/dtos/projects/users/ProjectUserDto";
import { UpdateProjectUserDto } from "types/dtos/projects/users/UpdateProjectUserDto";
import RolesEnum from "types/enums/rolesPermissions/RolesEnum";

const ProjectSettingsContainer: React.FC = () => {
    const [selectedUser, setSelectedUser] = useState<ProjectUserDto>();
    const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
    const [cannotDeleteUserIsOpen, setCannotDeleteUserIsOpen] = useState(false);
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
    const [isRemovingUser, setIsRemovingUser] = useState(false);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const toast = useToaster();
    const { projectUuid } = useParams();

    const {
        addUser,
        availableRoles,
        companyUsers,
        editUser,
        projectInformation,
        projectUsers,
        removeUser,
        setSearchText,
    } = useProjectSettings(projectUuid);

    useEffect(() => {
        dispatch(setPageTitle(t("projectSettings.pageTitle")));
        dispatch(
            setBack({
                route: routes.common.dashboard,
            }),
        );

        return () => {
            dispatch(setSecondaryNavItems(null));
        };
    }, [companyUsers, dispatch, projectUsers, t]);

    const handleAddUser = (userValue: string) => {
        if (userValue) {
            addUser(userValue);
        }
    };

    const handleDeleteClicked = (user: ProjectUserDto) => {
        if (user.role.name === RolesEnum.ProjectManager || projectUsers.length === 1) {
            setCannotDeleteUserIsOpen(true);
            return;
        }

        setSelectedUser(user);
        setConfirmDeleteIsOpen(true);
    };

    const handleDeleteUser = () => {
        if (!selectedUser) {
            toast.error(t("projectSettings.noUserSelected"));
            return;
        }
        setIsRemovingUser(true);
        removeUser(selectedUser.userUuid).then(() => {
            setConfirmDeleteIsOpen(false);
            setSelectedUser(undefined);
            setIsRemovingUser(false);
        });
    };

    const handleEditClicked = (user: ProjectUserDto) => {
        if (!user) {
            toast.error(t("projectSettings.noUserSelected"));
            return;
        }

        setSelectedUser(user);
        setIsEditDrawerOpen(true);
    };

    const handleEditUser = (dto: UpdateProjectUserDto) => {
        editUser(dto).then(() => {
            setIsEditDrawerOpen(false);
            setSelectedUser(undefined);
        });
    };

    const closeConfirmDelete = () => {
        setConfirmDeleteIsOpen(false);
    };

    const closeCannotDelete = () => {
        setCannotDeleteUserIsOpen(false);
    };

    const handleUserSearch = (searchText: string) => {
        setSearchText(searchText);
    };

    if (!projectUuid) return null;

    return (
        <React.Fragment>
            <ProjectSettingsDisplay
                companyUsers={companyUsers}
                projectUsers={projectUsers}
                onSelect={(user) => handleAddUser(user)}
                onRowDelete={handleDeleteClicked}
                onRowEdit={handleEditClicked}
                handleUserSearch={handleUserSearch}
            />
            <Drawer
                isOpen={isEditDrawerOpen}
                onClose={() => setIsEditDrawerOpen(false)}
                testid="edit-project-user-drawer"
            >
                <EditProjectUser
                    user={selectedUser}
                    submit={handleEditUser}
                    roles={availableRoles}
                    cancel={() => setIsEditDrawerOpen(false)}
                />
            </Drawer>
            <Popup
                isOpen={confirmDeleteIsOpen}
                title={t("popups.projectSettings.deleteUser.title")}
                message={t("popups.projectSettings.deleteUser.message", {
                    userFullName: `${selectedUser?.firstName} ${selectedUser?.lastName}`,
                    projectName: projectInformation?.projectName,
                })}
                primaryActionText={t("popups.projectSettings.deleteUser.confirm")}
                secondaryActionText={t("common.cancel")}
                onPrimaryAction={handleDeleteUser}
                onSecondaryAction={closeConfirmDelete}
                onClose={closeConfirmDelete}
                testid="confirm-delete-user-popup"
                disabled={isRemovingUser}
            />
            <Popup
                isOpen={cannotDeleteUserIsOpen}
                title={t("popups.projectSettings.cannotDeleteUser.title")}
                message={t("popups.projectSettings.cannotDeleteUser.message")}
                primaryActionText={t("common.cancel")}
                onPrimaryAction={closeCannotDelete}
                onClose={closeCannotDelete}
                testid="cannot-delete-user-popup"
            />
        </React.Fragment>
    );
};

export default ProjectSettingsContainer;
