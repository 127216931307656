import { Heading, RadioButtonsField, SelectField } from "@maistro/components";
import classnames from "classnames";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { IVettingQuestionnaireForm } from "features/company/profile/vetting/IVettingQuestionnaireForm";
import useSectionStyles from "features/company/profile/vetting/sections/useSectionStyles";
import currencyService from "services/currencyService";
import { useGetFinancialTurnoverTypesQuery } from "store/api/referenceDataApi";
import { VettingInformationKeys } from "types/dtos/company/vettingInformation/VettingInformationDto";
import CurrencyType from "types/enums/CurrencyType";

const useStyles = createUseStyles({
    financialContainer: {
        display: "flex",
        flexDirection: "column",
    },
    bold: {
        fontWeight: 600,
    },
});

const FinancialTurnoverSubSection: React.FC = () => {
    const sharedClasses = useSectionStyles();
    const classes = useStyles();
    const { t } = useTranslation();

    const { data: financialTurnoverTypes } = useGetFinancialTurnoverTypesQuery(undefined);

    const { values } = useFormikContext<IVettingQuestionnaireForm>();

    return (
        <div id={VettingInformationKeys.companyFinance}>
            <Heading variant="h4">
                {t("companyVettingQuestionnaire.sections.companyDetails.financialTurnover.heading")}
            </Heading>
            <div className={classnames(sharedClasses.subSection, classes.financialContainer)}>
                <div className={sharedClasses.selectContainer}>
                    <SelectField
                        testid="financial-currency-selector"
                        label={t("common.currency")}
                        name="financialCurrency"
                        required
                        options={[
                            {
                                value: CurrencyType.GBP.toString(),
                                label: "£ (GBP)",
                            },
                            {
                                value: CurrencyType.EUR.toString(),
                                label: "€ (EUR)",
                            },
                            {
                                value: CurrencyType.USD.toString(),
                                label: "$ (USD)",
                            },
                        ]}
                    />
                </div>
                <RadioButtonsField
                    testid="financial-radio-buttons"
                    label={t("companyVettingQuestionnaire.sections.companyDetails.financialTurnover.finance.label")}
                    tooltip={t("companyVettingQuestionnaire.sections.companyDetails.financialTurnover.finance.tooltip")}
                    required
                    name="financialTurnoverTypeId"
                    options={
                        financialTurnoverTypes?.data.map((type) => ({
                            value: type.id.toString(),
                            label: t(
                                `companyVettingQuestionnaire.sections.companyDetails.financialTurnover.finance.values.${type.id}`,
                                { currency: currencyService.toCurrencyIcon(values.financialCurrency as CurrencyType) },
                            ),
                        })) ?? []
                    }
                    columns={0}
                />
            </div>
        </div>
    );
};

export default FinancialTurnoverSubSection;
