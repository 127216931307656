import { useMemo } from "react";
import { useTheme } from "react-jss";

import useWindowSize from "hooks/useWindowSize";
import { ITheme } from "styles/themes/types";

const useScreenLayout = () => {
    const theme = useTheme<ITheme>();
    const { width } = useWindowSize();

    const onExtraSmallScreen = useMemo(() => width <= theme.breakpoints.xs, [theme.breakpoints.xs, width]);
    const onSmallScreen = useMemo(
        () => width > theme.breakpoints.xs && width <= theme.breakpoints.sm,
        [theme.breakpoints.sm, theme.breakpoints.xs, width],
    );
    const onMediumScreen = useMemo(
        () => width > theme.breakpoints.sm && width <= theme.breakpoints.md,
        [theme.breakpoints.md, theme.breakpoints.sm, width],
    );

    const onMobile = onExtraSmallScreen;
    const onTablet = onSmallScreen || onMediumScreen;
    const onDesktop = !onMobile && !onTablet;

    return { onMobile, onTablet, onDesktop };
};

export default useScreenLayout;
