interface ValidationErrorDto {
    property: string;
    errorText: string;
}

// eslint-disable-next-line fp/no-class
class ValidationErrorsDto {
    constructor(validationErrors: ValidationErrorDto[]) {
        // eslint-disable-next-line fp/no-this
        this.validationErrors = validationErrors;
    }

    validationErrors: ValidationErrorDto[] = [];
}

export default ValidationErrorsDto;
