enum SupplierSubmissionStatus {
    Unset = "Unset",
    Identified = "Identified",
    Invited = "Invited",
    NotInterested = "NotInterested",
    Unsuccessful = "Unsuccessful",
    Interested = "Interested",
    Withdrawn = "Withdrawn",
    Responding = "Responding",
    Failed = "Failed",
    Submitted = "Submitted",
    Shortlisted = "Shortlisted",
    Successful = "Successful",
}

export default SupplierSubmissionStatus;
