import { AxiosResponse } from "axios";

import env from "config/EnvironmentVariables";
import { UserResponseDto } from "types/dtos/users/UserResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { createAxiosInstance } from "api/commonApi";
import { ActivateUserRequestDto } from "types/dtos/users/ActivateUserRequestDto";
import apiErrorService from "services/apiErrorService";
import { PersonalProfileDto } from "types/dtos/users/PersonalProfileDto";
import { UpdateUserProfileRequestDto } from "types/dtos/users/UpdateUserProfileRequestDto";
import { ChangePasswordReqestDto } from "types/dtos/users/ChangePasswordReqestDto";
import { SearchUsersResponseDto } from "types/dtos/users/SearchUsersResponseDto";
import { LogoffUserRequestDto } from "types/dtos/users/LogoffUserRequestDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/users";

export const getUser = (
    userUuid: string,
): Promise<AxiosResponse<UserResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${userUuid}`;
    return api.get(url);
};

export const activateUser = (request: ActivateUserRequestDto): Promise<AxiosResponse> => {
    const url = `${baseApiUrl}/activate`;
    return api.post(url, request).then((response) => {
        return apiErrorService.transformResponseTypeOnError(response);
    });
};

export const getUserProfile = (
    userUuid: string,
): Promise<AxiosResponse<PersonalProfileDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${userUuid}/profile`;
    return api.get(url);
};

export const updateUserProfile = (request: UpdateUserProfileRequestDto): Promise<AxiosResponse> => {
    const url = `${baseApiUrl}/${request.userUuid}/profile`;
    return api.put(url, request).then((response) => {
        return apiErrorService.transformResponseTypeOnError(response);
    });
};

export const changeUserPassword = (request: ChangePasswordReqestDto): Promise<AxiosResponse> => {
    const url = `${baseApiUrl}/${request.userUuid}/password`;
    return api.put(url, request).then((response) => {
        return apiErrorService.transformResponseTypeOnError(response);
    });
};

export const searchUsers = (searchText?: string): Promise<AxiosResponse<SearchUsersResponseDto>> => {
    let url = `${baseApiUrl}/search`;

    if (searchText) {
        url += `?searchText=${searchText}`;
    }

    return api.get(url).then((response) => {
        return apiErrorService.transformResponseTypeOnError(response);
    });
};

export const logoffUser = async (request: LogoffUserRequestDto): Promise<AxiosResponse> => {
    const url = `${baseApiUrl}/${request.userUuid}/logoff`;

    return api.put(url, request).then((response) => {
        return apiErrorService.transformResponseTypeOnError(response);
    });
};
