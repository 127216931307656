import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { listRoles, listUserPermissions, listUserRoles } from "api/rolesPermissionsApi";
import { RoleDto } from "types/dtos/rolesPermissions/RoleDto";
import { UserRoleDto } from "types/dtos/rolesPermissions/UserRoleDto";
import { PermissionDto } from "types/dtos/rolesPermissions/PermissionDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { ListRolesRequestDto } from "types/dtos/rolesPermissions/ListRolesRequestDto";
import { ListUserRolesRequestDto } from "types/dtos/rolesPermissions/ListUserRolesRequestDto";
import { ListUserPermissionsRequestDto } from "types/dtos/rolesPermissions/ListUserPermissionsRequestDto";
import { ListRolesResponseDto } from "types/dtos/rolesPermissions/ListRolesResponseDto";
import { ListUserRolesResponseDto } from "types/dtos/rolesPermissions/ListUserRolesResponseDto";
import { ListUserPermissionsResponseDto } from "types/dtos/rolesPermissions/ListUserPermissionsResponseDto";

export interface IRolesPermissionsState {
    availableRoles: RoleDto[];
    userRoles: UserRoleDto[];
    userPermissions: PermissionDto[];
    isLoading: boolean;
}

// Create standard export for async thunks with error handling. Can we match this to the base handler in Framework.Core?

export const fetchAvailableRoles = createAsyncThunk<
    ListRolesResponseDto,
    ListRolesRequestDto,
    { rejectValue: TransactionErrorDto }
>("rolesPermissions/fetchAvailableRoles", async (request, thunkAPI) => {
    const response = await listRoles(request);

    if (response.data instanceof TransactionErrorDto) {
        return thunkAPI.rejectWithValue(response.data);
    }

    return response.data;
});

export const fetchUserRoles = createAsyncThunk<
    ListUserRolesResponseDto,
    ListUserRolesRequestDto,
    { rejectValue: TransactionErrorDto }
>("rolesPermissions/fetchUserRoles", async (request, thunkAPI) => {
    const response = await listUserRoles(request);

    if (response.data instanceof TransactionErrorDto) {
        return thunkAPI.rejectWithValue(response.data);
    }

    return response.data;
});

export const fetchUserPermissions = createAsyncThunk<
    ListUserPermissionsResponseDto,
    ListUserPermissionsRequestDto,
    { rejectValue: TransactionErrorDto }
>("rolesPermissions/fetchUserPermissions", async (request, thunkAPI) => {
    const response = await listUserPermissions(request);

    if (response.data instanceof TransactionErrorDto) {
        return thunkAPI.rejectWithValue(response.data);
    }

    return response.data;
});

export const rolesPermissionsSlice = createSlice({
    name: "rolesPermissions",
    initialState: {
        availableRoles: [] as RoleDto[],
        userRoles: [] as UserRoleDto[],
        userPermissions: [] as PermissionDto[],
        isLoading: true,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAvailableRoles.fulfilled, (state, action) => {
            state.availableRoles = action.payload.items;
        });

        builder.addCase(fetchUserRoles.fulfilled, (state, { payload }) => {
            state.userRoles = payload.items;
        });

        builder.addCase(fetchUserPermissions.fulfilled, (state, { payload }) => {
            state.userPermissions = payload.items;
            state.isLoading = false;
        });
    },
});

export default rolesPermissionsSlice.reducer;
