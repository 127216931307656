import React from "react";
import { HistoryRouter as Router } from "redux-first-history/rr6";

import { ScrollToTop } from "components";
import ThemedRoutes from "components/Layout/ThemedRoutes";
import env from "config/EnvironmentVariables";
import ReactGA from "react-ga4";
import { history } from "store/store";

ReactGA.initialize(env.GaMeasurementId);
ReactGA.event({
    category: "Page visit",
    action: "Initial visit",
});
const App: React.FC = () => {
    return (
        <Router history={history}>
            <ScrollToTop />
            <ThemedRoutes />
        </Router>
    );
};

export default App;
