import { Copy } from "@maistro/components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Loader } from "components";
import TitleCtaType from "components/Layout/types/TitleCtaType";
import ProjectPreviewDrawer from "features/project/components/ProjectPreviewDrawer";
import useNavTabs from "features/project/hooks/useNavTabs";
import useProject from "features/project/hooks/useProject";
import useProjectFiles from "features/project/hooks/useProjectFiles";
import { useTenderQuestions } from "features/project/tenderCriteria/hooks/useTenderQuestions";
import TenderQuestionsSummaryDisplay from "features/project/tenderCriteria/tenderQuestionsSummary/TenderQuestionsSummaryDisplay";
import useAppDispatch from "hooks/useAppDispatch";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { resetLayout, setBack, setPageTitle, setSecondaryNavItems, setTitleCtas } from "store/layoutSlice";

const TenderQuestionsSummaryContainer: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isProjectPreviewOpen, setIsProjectPreviewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { projectInformation } = useProject();
    const { projectFiles } = useProjectFiles(projectInformation.uuid);

    const { buyerTabItems } = useNavTabs();

    const { sectionsResponse, sectionQuestions, sectionsWithoutOrdering } = useTenderQuestions({
        projectUuid: projectInformation.uuid,
        setIsLoading,
    });

    if (sectionsResponse) {
        const percentage = sectionsResponse.sections.reduce((total, section) => total + (section.weighting ?? 0), 0);
        if (percentage !== 100) {
            navigate(buildPath(routes.projects.weighting, { projectUuid: projectInformation.uuid }));
        }
    }

    useEffect(() => {
        dispatch(resetLayout());
        dispatch(setBack(null));

        dispatch(
            setBack({
                route: buildRoute(routes.projects.monitor, { projectUuid: projectInformation.uuid }),
            }),
        );
        dispatch(setPageTitle(t("common.pages.projectCriteriaQuestions")));
        dispatch(setSecondaryNavItems(buyerTabItems));

        dispatch(
            setTitleCtas([
                {
                    type: TitleCtaType.ProjectPreview,
                    onClick: () => setIsProjectPreviewOpen(true),
                },
            ]),
        );

        return () => {
            dispatch(resetLayout());
            dispatch(setBack(null));
        };
    }, [buyerTabItems, dispatch, projectInformation, projectInformation.uuid, t]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <React.Fragment>
            {sectionsResponse?.sections && sectionsResponse?.sections?.length > 0 ? (
                <TenderQuestionsSummaryDisplay sections={sectionsWithoutOrdering} sectionQuestions={sectionQuestions} />
            ) : (
                <Copy variant="error" testid="no-sections-error">
                    {t("tenderQuestions.noSections")}
                </Copy>
            )}
            <ProjectPreviewDrawer
                projectFiles={projectFiles}
                isOpen={isProjectPreviewOpen}
                close={() => setIsProjectPreviewOpen(false)}
            />
        </React.Fragment>
    );
};

export default TenderQuestionsSummaryContainer;
