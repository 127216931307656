import type { AxiosResponse } from "axios";

import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import ValidationErrorsDto from "types/dtos/ValidationErrorsDto";

export type ApiResponse<TResponse> = AxiosResponse<TResponse | TransactionErrorDto | ValidationErrorsDto>;

interface IApiErrorService {
    transformResponseTypeOnError: <TResponse>(response: AxiosResponse<TResponse, unknown>) => ApiResponse<TResponse>;
    getFirstErrorFromResponse: (response: TransactionErrorDto | ValidationErrorsDto) => string;
    isSuccessfulResponse: <TResponse>(response: ApiResponse<TResponse>) => boolean;
}

function transformResponseTypeOnError<TResponse>(response: ApiResponse<TResponse>): ApiResponse<TResponse> {
    if (response.status === 400) {
        const transactionErrorResponse = response.data as unknown as TransactionErrorDto;
        return {
            ...response,
            data: new TransactionErrorDto(transactionErrorResponse.errors),
        } as AxiosResponse<TransactionErrorDto>;
    }
    if (response.status === 422) {
        const validationErrorResponse = response.data as unknown as ValidationErrorsDto;
        return {
            ...response,
            data: new ValidationErrorsDto(validationErrorResponse.validationErrors),
        } as AxiosResponse<ValidationErrorsDto>;
    }

    return response;
}

const getFirstErrorFromResponse = (errorsResponse: TransactionErrorDto | ValidationErrorsDto) => {
    if (errorsResponse instanceof TransactionErrorDto) {
        const errorTypes = Object.keys(errorsResponse.errors);
        if (errorTypes.length > 0 && errorsResponse.errors[errorTypes[0]].length > 0) {
            return errorsResponse.errors[errorTypes[0]][0];
        }
    }

    if (errorsResponse instanceof ValidationErrorsDto) {
        if (errorsResponse.validationErrors.length > 0) {
            return errorsResponse.validationErrors[0].errorText;
        }
    }

    return "An error occurred";
};

const apiErrorService: IApiErrorService = {
    transformResponseTypeOnError,
    getFirstErrorFromResponse,
    isSuccessfulResponse: (response) => {
        return (
            response.data instanceof TransactionErrorDto ||
            response.data instanceof ValidationErrorsDto ||
            (response.status >= 200 && response.status < 300)
        );
    },
};

export default apiErrorService;
