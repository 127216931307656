import { Button, ICommonProps, RichTextEditorField, TextButton } from "@maistro/components";
import { Form, Formik } from "formik";
import { TFunction } from "i18next";
import { isEmpty } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import DisplayFileUploadField from "features/files/DisplayFileUploadFields";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";
import useReduxSelector from "hooks/useReduxSelector";

const useStyles = createUseStyles((theme: ITheme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.large,

        "& input[type=radio] + label": {
            ...theme.typography.base,
            fontSize: 13,
        },
    },
    buttonControl: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.large,
    },
}));

const validationSchema = (t: TFunction<"translation", undefined>) =>
    Yup.object({
        answer: Yup.string().required(t("clarificationQuestions.answer.required")),
    });

interface ISupplierAnswerQuestionFormProps extends ICommonProps {
    submit: (answer: string) => void;
    cancel?: () => void;
    files?: FileDto[];
    setFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    removeFile: (file: FileDto | null) => void;
    fileIsUploading: boolean;
    setFileIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
}

const SupplierAnswerQuestionForm: React.FC<ISupplierAnswerQuestionFormProps> = (props) => {
    const { files, setFiles, removeFile, fileIsUploading, setFileIsUploading } = props;
    const classes = useStyles();
    const { theme } = useReduxSelector((state) => state.themeState);

    const { t } = useTranslation();

    return (
        <Formik
            enableReinitialize
            initialValues={{ answer: "" }}
            validationSchema={validationSchema(t)}
            onSubmit={(values) => props.submit(values.answer)}
        >
            {({ errors, isValid, isSubmitting }) => (
                <Form className={classes.form}>
                    <RichTextEditorField
                        testid="clarification-question-answer"
                        label={t("clarificationQuestions.answer.label")}
                        name="answer"
                        required
                        joditLicense={theme.joditLicence}
                    />
                    <DisplayFileUploadField
                        testid="clarification-question"
                        files={files}
                        canManageFiles
                        removeFile={removeFile}
                        setFiles={setFiles}
                        fileIsUploading={fileIsUploading}
                        setFileIsUploading={setFileIsUploading}
                    />
                    <div className={classes.buttonControl}>
                        <Button
                            testid="clarification-question-answer-submit-button"
                            label={t("clarificationQuestions.submitAnswer")}
                            type="submit"
                            size="large"
                            disabled={!isEmpty(errors) || !isValid || isSubmitting || fileIsUploading}
                        />
                        <TextButton
                            label={t("clarificationQuestions.cancelAnswer")}
                            onClick={props.cancel}
                            testid="clarification-question-cancel-answer-button"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default SupplierAnswerQuestionForm;
