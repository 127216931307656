import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import ProjectStatus from "types/enums/projects/ProjectStatus";

const calculateEoiUrl = (projectUuid: string, projectStatus: ProjectStatus) => {
    switch (projectStatus) {
        case ProjectStatus.Complete:
            return buildPath(routes.projects.results, { projectUuid });
        case ProjectStatus.AwaitingResponses:
        case ProjectStatus.Review:
        case ProjectStatus.Results:
            return buildPath(routes.projects.monitorExpressionOfInterest, { projectUuid });
        case ProjectStatus.Create:
        default:
            return `/project-brief/${projectUuid}`;
    }
};

export default calculateEoiUrl;
