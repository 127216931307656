import { faFolderArrowDown } from "@fortawesome/pro-regular-svg-icons";
import { Button, Copy, Table } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import useSuppliersConfiguration from "features/project/expressionOfInterest/hooks/useSuppliersConfiguration";
import ReactGA from "react-ga4";
import { ITheme } from "styles/themes/types";
import { ExpressionOfInterestResponseDto } from "types/dtos/projects/expressionOfInterest/ExpressionOfInterestResponseDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

interface IResultsDisplayProps {
    suppliers: ExpressionOfInterestResponseDto[];
    exportSupplierData: (supplierData: ExpressionOfInterestResponseDto[]) => void;
}

const useStyles = createUseStyles((theme: ITheme) => ({
    table: {
        marginTop: theme.spacing.xLarge,
        marginBottom: theme.spacing.xLarge,
    },
}));

const ResultsDisplay: React.FC<IResultsDisplayProps> = (props: IResultsDisplayProps) => {
    const classes = useStyles();

    const { suppliers, exportSupplierData } = props;

    const { t } = useTranslation();
    const { columns } = useSuppliersConfiguration(false);

    return (
        <React.Fragment>
            <Copy>{t("expressionOfInterest.results.copy")}</Copy>
            <Table
                className={classes.table}
                columns={columns}
                data={suppliers}
                noDataDisplay={t("common.noResults")}
                testid="suppliers-table"
            />
            <Button
                icon={faFolderArrowDown}
                label={t("expressionOfInterest.results.exportSupplierData")}
                onClick={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: "Expression Of Interest - Results - Export Supplier Data",
                    });
                    exportSupplierData(suppliers);
                }}
                testid="export-supplier-data-button"
            />
        </React.Fragment>
    );
};

export default ResultsDisplay;
