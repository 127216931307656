import { AxiosResponse } from "axios";

import env from "config/EnvironmentVariables";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { ListRolesRequestDto } from "types/dtos/rolesPermissions/ListRolesRequestDto";
import { ListRolesResponseDto } from "types/dtos/rolesPermissions/ListRolesResponseDto";
import { ListUserRolesRequestDto } from "types/dtos/rolesPermissions/ListUserRolesRequestDto";
import { ListUserRolesResponseDto } from "types/dtos/rolesPermissions/ListUserRolesResponseDto";
import { ListUserPermissionsRequestDto } from "types/dtos/rolesPermissions/ListUserPermissionsRequestDto";
import { ListUserPermissionsResponseDto } from "types/dtos/rolesPermissions/ListUserPermissionsResponseDto";
import { createAxiosInstance } from "api/commonApi";

const api = createAxiosInstance(env.OrchestrationApiUrl);

export const listRoles = (
    request: ListRolesRequestDto,
): Promise<AxiosResponse<ListRolesResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    return api.get("authorisation/roles", {
        params: { name: request.queryParameters.name },
    });
};

export const listUserRoles = (
    request: ListUserRolesRequestDto,
): Promise<AxiosResponse<ListUserRolesResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    return api.get(`users/${request.userUuid}/roles`);
};

export const listUserPermissions = (
    request: ListUserPermissionsRequestDto,
): Promise<AxiosResponse<ListUserPermissionsResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    return api.get(`users/${request.userUuid}/permissions`);
};
