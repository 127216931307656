import useWindowSize from "hooks/useWindowSize";
import { useMemo } from "react";

const useReportOptions = () => {
    const { width } = useWindowSize();

    const isReportMobileLayout = useMemo(() => width <= 1200, [width]);

    return { isReportMobileLayout };
};

export default useReportOptions;
