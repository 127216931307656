import { BooleanOptionsField, Heading, TextField } from "@maistro/components";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import DisplayVettingFileUploadField from "features/company/profile/vetting/files/DisplayVettingFileUploadField";
import { IVettingQuestionnaireForm } from "features/company/profile/vetting/IVettingQuestionnaireForm";
import useSectionStyles from "features/company/profile/vetting/sections/useSectionStyles";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";
import { VettingInformationKeys } from "types/dtos/company/vettingInformation/VettingInformationDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    conditionalQuestions: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.small,
        marginTop: theme.spacing.small,
    },
}));

export const standardsValidationSchema = () =>
    Yup.object({
        hasCompliancePolicy: Yup.boolean().nullable(),
        complianceStatement: Yup.string().nullable(),
        hasSocialResponsibilityPolicy: Yup.boolean().nullable(),
        hasModernSlaveryPolicy: Yup.boolean().nullable(),
        hasIdentifiedModernSlaveryRisks: Yup.boolean().nullable(),
        hasSupplierTermsAndConditions: Yup.boolean().nullable(),
        hasWhistleblowingPolicy: Yup.boolean().nullable(),
        hasStaffTraining: Yup.boolean().nullable(),
        hasAntiMoneyLaunderingPolicy: Yup.boolean().nullable(),
        hasAntiSlaveryAndHumanTraffickingPolicy: Yup.boolean().nullable(),
    });

interface IStandardsSubSectionProps {
    briberyPolicyFiles: FileDto[];
    setBriberyPolicyFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    socialResponsibilityPolicyFiles: FileDto[];
    setSocialResponsibilityPolicyFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    modernSlaveryPolicyFiles: FileDto[];
    setModernSlaveryPolicyFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    antiMoneyLaunderingPolicyFiles: FileDto[];
    setAntiMoneyLaunderingPolicyFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
}

const StandardsSubSection: React.FC<IStandardsSubSectionProps> = ({
    briberyPolicyFiles,
    setBriberyPolicyFiles,
    socialResponsibilityPolicyFiles,
    setSocialResponsibilityPolicyFiles,
    modernSlaveryPolicyFiles,
    setModernSlaveryPolicyFiles,
    antiMoneyLaunderingPolicyFiles,
    setAntiMoneyLaunderingPolicyFiles,
}) => {
    const sharedClasses = useSectionStyles();
    const classes = useStyles();
    const { t } = useTranslation();

    const [briberyFileIsUploading, setBriberyFileIsUploading] = useState<boolean>(false);
    const [socialResponsibilityFileIsUploading, setSocialResponsibilityFileIsUploading] = useState<boolean>(false);
    const [modernSlaveryFileIsUploading, setModernSlaveryFileIsUploading] = useState<boolean>(false);
    const [antiMoneyLaunderingFileIsUploading, setAntiMoneyLaunderingFileIsUploading] = useState<boolean>(false);

    const { values } = useFormikContext<IVettingQuestionnaireForm>();

    return (
        <div id={VettingInformationKeys.standards}>
            <Heading variant="h4">{t("companyVettingQuestionnaire.sections.ethics.standards.title")}</Heading>
            <ol className={sharedClasses.listStyle} type="1">
                <li>
                    <BooleanOptionsField
                        name="hasCompliancePolicy"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t("companyVettingQuestionnaire.sections.ethics.standards.hasCompliancePolicy.label")}
                        testid="has-compliance-policy-options-field"
                    />
                    {values.hasCompliancePolicy && (
                        <div className={classes.conditionalQuestions}>
                            <DisplayVettingFileUploadField
                                label={t(
                                    "companyVettingQuestionnaire.sections.ethics.standards.hasCompliancePolicy.field",
                                )}
                                testid="bribery-policy-file-upload-field"
                                files={briberyPolicyFiles}
                                setFiles={setBriberyPolicyFiles}
                                fileIsUploading={briberyFileIsUploading}
                                setFileIsUploading={setBriberyFileIsUploading}
                                name="briberyPolicyFiles"
                            />
                            <TextField
                                name="complianceStatement"
                                label={t(
                                    "companyVettingQuestionnaire.sections.ethics.standards.complianceStatement.label",
                                )}
                                testid="compliance-statement-field"
                            />
                        </div>
                    )}
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasSocialResponsibilityPolicy"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.ethics.standards.hasSocialResponsibilityPolicy.label",
                        )}
                        testid="has-social-responsibility-policy-options-field"
                    />
                    {values.hasSocialResponsibilityPolicy && (
                        <div className={classes.conditionalQuestions}>
                            <DisplayVettingFileUploadField
                                testid="social-responsibility-policy-file-upload-field"
                                files={socialResponsibilityPolicyFiles}
                                setFiles={setSocialResponsibilityPolicyFiles}
                                fileIsUploading={socialResponsibilityFileIsUploading}
                                setFileIsUploading={setSocialResponsibilityFileIsUploading}
                                name="socialResponsibilityPolicyFiles"
                            />
                        </div>
                    )}
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasModernSlaveryPolicy"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t("companyVettingQuestionnaire.sections.ethics.standards.hasModernSlaveryPolicy.label")}
                        testid="has-modern-slavery-policy-options-field"
                    />
                    {values.hasModernSlaveryPolicy && (
                        <div className={classes.conditionalQuestions}>
                            <DisplayVettingFileUploadField
                                testid="modern-slavery-policy-file-upload-field"
                                files={modernSlaveryPolicyFiles}
                                setFiles={setModernSlaveryPolicyFiles}
                                fileIsUploading={modernSlaveryFileIsUploading}
                                setFileIsUploading={setModernSlaveryFileIsUploading}
                                name="modernSlaveryPolicyFiles"
                            />
                        </div>
                    )}
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasIdentifiedModernSlaveryRisks"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.ethics.standards.hasIdentifiedModernSlaveryRisks.label",
                        )}
                        testid="has-identified-modern-slavery-risks-options-field"
                    />
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasSupplierTermsAndConditions"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.ethics.standards.hasSupplierTermsAndConditions.label",
                        )}
                        testid="has-supplier-terms-and-conditions-options-field"
                    />
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasWhistleblowingPolicy"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t("companyVettingQuestionnaire.sections.ethics.standards.hasWhistleblowingPolicy.label")}
                        testid="has-whistleblowing-policy-options-field"
                    />
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasStaffTraining"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t("companyVettingQuestionnaire.sections.ethics.standards.hasStaffTraining.label")}
                        testid="has-staff-training-options-field"
                    />
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasAntiMoneyLaunderingPolicy"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.ethics.standards.hasAntiMoneyLaunderingPolicy.label",
                        )}
                        testid="has-anti-money-laundering-policy-options-field"
                    />
                    {values.hasAntiMoneyLaunderingPolicy && (
                        <div className={classes.conditionalQuestions}>
                            <DisplayVettingFileUploadField
                                testid="social-responsibility-policy-file-upload-field"
                                files={antiMoneyLaunderingPolicyFiles}
                                setFiles={setAntiMoneyLaunderingPolicyFiles}
                                fileIsUploading={antiMoneyLaunderingFileIsUploading}
                                setFileIsUploading={setAntiMoneyLaunderingFileIsUploading}
                                name="antiMoneyLaunderingPolicyFiles"
                            />
                        </div>
                    )}
                </li>
                <li>
                    <BooleanOptionsField
                        name="hasAntiSlaveryAndHumanTraffickingPolicy"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.ethics.standards.hasAntiSlaveryAndHumanTraffickingPolicy.label",
                        )}
                        testid="has-anti-slavery-and-human-trafficking-policy-options-field"
                    />
                </li>
            </ol>
        </div>
    );
};

export default StandardsSubSection;
