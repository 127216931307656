import { faArrowToBottom, faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { Icon, useToaster } from "@maistro/components";
import { downloadMedia } from "api/fileApi";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

type BuyerSupplierContractDownloadIconProps = {
    fileExtension?: string;
    fileId: string;
    fileName: string;
};

const BuyerSupplierContractDownloadIcon: React.FC<BuyerSupplierContractDownloadIconProps> = ({
    fileExtension,
    fileId,
    fileName,
}) => {
    const [isDownloadingFile, setIsDownloadingFile] = useState<boolean>(false);

    const { t } = useTranslation();
    const toast = useToaster();

    const downloadFile = useCallback(() => {
        setIsDownloadingFile(true);
        downloadMedia(fileId).then((response) => {
            if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                setIsDownloadingFile(false);
                toast.error(t("fileUpload.individualDownloadError", { fileName }));
                return;
            }
            const objectUrl = window.URL.createObjectURL(response.data);
            const anchor = document.createElement("a");
            anchor.href = objectUrl;
            anchor.download = `${fileName}${fileExtension}`;
            anchor.click();
            window.URL.revokeObjectURL(objectUrl);
            setIsDownloadingFile(false);
        });
    }, [fileExtension, fileId, fileName, t, toast]);

    if (isDownloadingFile) {
        return <Icon icon={faSpinner} spin />;
    }

    return <Icon icon={faArrowToBottom} size="small" onClick={downloadFile} />;
};

export default BuyerSupplierContractDownloadIcon;
