import { Button } from "@maistro/components";
import classNames from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import useReduxSelector from "hooks/useReduxSelector";

const useStyles = createUseStyles({
    footer: {
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
    },
    ctaContainer: {
        display: "flex",
        flexWrap: "wrap",
    },
    cta: {
        flex: 1,
        height: 52,
        borderRadius: 0,
    },
});

interface IFooterProps extends ICommonProps {}

const Footer: React.FC<IFooterProps> = (props) => {
    const classes = useStyles();

    const { layout } = useReduxSelector((state) => state);

    return (
        <footer className={classNames(props.className, classes.footer)} data-testid={props.testid}>
            <div className={classes.ctaContainer}>
                {layout.mobileFooterCtas &&
                    layout.mobileFooterCtas.map((cta) => (
                        <Button key={cta.label} className={classes.cta} {...cta} notificationsPosition="inline" />
                    ))}
            </div>
        </footer>
    );
};

export default Footer;
