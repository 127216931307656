import { ExpansionPanel, Pill, ReadOnlyField } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import useProfileStyles from "features/company/hooks/useProfileStyles";
import { ITheme } from "styles/themes/types";
import { TaxonomyItemDto } from "types/dtos/taxonomy/TaxonomyItemDto";
import CategorySelectionStatus from "types/enums/CategorySelectionStatus";

const useStyles = createUseStyles((theme: ITheme) => ({
    categories: {
        margin: `${theme.spacing.xSmall / 3}px ${theme.spacing.xSmall / 3}px`,
    },

    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        categories: {
            width: "100%",
        },
    },
}));

interface ICategoriesSectionProps extends ICommonProps {
    data: Array<TaxonomyItemDto>;
}

const CategoriesSection: React.FC<ICategoriesSectionProps> = ({ data }) => {
    const classes = useStyles();
    const profileClasses = useProfileStyles();

    const { t } = useTranslation();

    const dataToDisplay = data
        .filter((category) => category.selectionStatusId !== CategorySelectionStatus.Removed)
        .map((category) => (
            <Pill
                key={`category-${category._embedded.uuid}`}
                className={classes.categories}
                value={category._embedded.hierarchyDisplayName}
                testid={`category-${category._embedded.uuid}`}
            />
        ));

    return (
        <ExpansionPanel label={t("companyInformation.categories.title")}>
            <div className={profileClasses.row}>
                <div className={profileClasses.column}>
                    <ReadOnlyField
                        testid="company-categories"
                        value={data.length > 0 ? dataToDisplay : null}
                        useHyphenForEmptyValues
                    />
                </div>
            </div>
        </ExpansionPanel>
    );
};

export default CategoriesSection;
