import baseApi from "store/api/baseApi";
import { CompanySummaryDto } from "types/dtos/company/CompanySummaryDto";
import GetCompanySummaryRequestDto from "types/dtos/company/GetCompanySummaryRequestDto";
import { CompanyFeaturesDto } from "types/dtos/company/feature-toggles/companyFeaturesDto";
import { CompanyDetailDto } from "types/dtos/company/profile/CompanyDetailDto";
import { UpdateCompanyProfileRequestDto } from "types/dtos/company/profile/UpdateCompanyProfileRequestDto";
import { UpdateCompanyStatusRequestDto } from "types/dtos/company/profile/UpdateCompanyStatusRequestDto";
import { ISupplierListMembershipsResponseDto } from "types/dtos/company/supplier-list-memberships/ISupplierListMembershipsResponseDto";

const companyApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getCompanyFeatures: build.query<CompanyFeaturesDto, string | undefined>({
            query: (companyUuid) => ({ url: `/companies/${companyUuid}/feature-toggles`, method: "GET" }),
        }),
        getCompanySupplierListMemberships: build.query<ISupplierListMembershipsResponseDto, string>({
            query: (companyUuid) => ({ url: `/companies/${companyUuid}/supplier-list-memberships`, method: "GET" }),
        }),
        getCompanyDetails: build.query<CompanyDetailDto, string>({
            query: (companyUuid) => ({ url: `/companies/${companyUuid}`, method: "GET" }),
        }),
        deleteCompanyDetails: build.mutation<CompanyDetailDto, string>({
            query: (companyUuid) => ({ url: `/companies/${companyUuid}`, method: "DELETE" }),
        }),
        getCompanySummary: build.query<CompanySummaryDto, GetCompanySummaryRequestDto>({
            query: (request) => ({
                url: `/companies/${request.buyerCompanyUuid}/summary/${request.supplierCompanyUuid}`,
                method: "GET",
            }),
        }),
        updateCompanyDetails: build.mutation<CompanyDetailDto, UpdateCompanyProfileRequestDto>({
            query: ({ uuid, ...data }) => ({
                url: `/companies/${uuid}`,
                method: "PUT",
                data,
            }),
            onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
                try {
                    const { uuid } = data;
                    const { data: updatedCompanyDetails } = await queryFulfilled;
                    dispatch(
                        companyApi.util.updateQueryData("getCompanyDetails", uuid, (draft) => {
                            Object.assign(draft, updatedCompanyDetails);
                        }),
                    );
                } catch {
                    /* empty */
                }
            },
        }),
        updateCompanyStatus: build.mutation<undefined, UpdateCompanyStatusRequestDto>({
            query: ({ companyUuid, ...data }) => ({
                url: `/companies/${companyUuid}/status`,
                method: "PUT",
                data,
            }),
            onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
                try {
                    const { companyUuid } = data;
                    await queryFulfilled;
                    dispatch(
                        companyApi.util.updateQueryData("getCompanyDetails", companyUuid, (draft) => {
                            draft.isActive = data.isActive;
                        }),
                    );
                } catch {
                    /* empty */
                }
            },
        }),
    }),
    overrideExisting: false,
});

export const {
    useLazyGetCompanyFeaturesQuery,
    useLazyGetCompanySupplierListMembershipsQuery,
    useLazyGetCompanySummaryQuery,
    useGetCompanySummaryQuery,
    useGetCompanyFeaturesQuery,
    useGetCompanyDetailsQuery,
    useDeleteCompanyDetailsMutation,
    useUpdateCompanyDetailsMutation,
    useUpdateCompanyStatusMutation,
} = companyApi;

export default companyApi;
