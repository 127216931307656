import { Popup, htmlService, useToaster } from "@maistro/components";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { linkProjectFiles } from "api/fileApi";
import { searchTaxonomy } from "api/taxonomyApi";
import { Loader, ScrollToTop } from "components";
import { ICategoryProps, IOptionProps } from "components/shared";
import useFileUpload from "features/files/useFileUpload";
import calculateProjectUrl from "features/helpers/calculateProjectUrl";
import ChooseProjectTypeDisplay from "features/project/projectBrief/ChooseProjectTypeDisplay";
import ProjectBriefError from "features/project/projectBrief/ProjectBriefError";
import ProjectBriefForm from "features/project/projectBrief/ProjectBriefForm";
import useProjectBrief from "features/project/projectBrief/hooks/useProjectBrief";
import { IProjectInformation } from "features/project/types";
import useAppDispatch from "hooks/useAppDispatch";
import useCurrentUser from "hooks/useCurrentUser";
import usePolicies from "hooks/usePolicies";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import currencyService from "services/currencyService";
import stringService from "services/stringService";
import validationService from "services/validationService";
import { useLazySearchCompaniesQuery } from "store/api/companiesApi";
import { useLazyListCompanyUsersQuery } from "store/api/companyUsersApi";
import { resetLayout, setBack, setPageTitle } from "store/layoutSlice";
import { fetchUserPermissions } from "store/rolesPermissionsSlice";
import editableProjectStatuses from "types/consts/editableProjectStatuses";
import { DirtyValues } from "types/dtos/DirtyValues";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { CompanyUserDto } from "types/dtos/company/CompanyUserDto";
import { PolicyDto } from "types/dtos/company/policies/PolicyDto";
import CategorySelectionStatus from "types/enums/CategorySelectionStatus";
import ErrorCode from "types/enums/ErrorCode";
import ProjectStatus from "types/enums/projects/ProjectStatus";
import ProjectType from "types/enums/projects/ProjectType";

const ProjectBriefContainer: React.FC = () => {
    const [clientList, setClientList] = useState<Array<IOptionProps>>([]);
    const [sponsorList, setSponsorList] = useState<Array<IOptionProps>>([]);
    const [sponsorSearchText, setSponsorSearchText] = useState<string>();
    const [newProjectUuid, setNewProjectUuid] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [breachedPolicy, setBreachedPolicy] = useState<PolicyDto>();
    const [isSpendLimitApprovalPopupOpen, setIsSpendLimitApprovalPopupOpen] = useState(false);
    const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
    const [companyUuidToCancel, setCompanyUuidToCancel] = useState<string | undefined>(undefined);

    const { projectUuid } = useParams();
    const [searchParams] = useSearchParams();
    const { state } = useLocation();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const toast = useToaster();

    const [searchCompanies] = useLazySearchCompaniesQuery();
    const [listCompanyUsers] = useLazyListCompanyUsersQuery();

    const {
        projectError,
        projectInformation,
        cancelProject,
        createOrUpdateProject,
        isSubmitting,
        autoCategorizeFromText,
        setProjectInformation,
        companyUsers,
        fetchUsersForCompany,
        setIsSubmitting,
        setCompanyUsersSearchText,
    } = useProjectBrief({
        projectUuid,
        includeRemovedCategories: true,
        setIsLoading,
    });
    const { projectFiles, setProjectFiles, removeFile } = useFileUpload(searchParams.get("projectUuid") ?? projectUuid);
    const { myCompanyUuid, myUuid, userIsMaistro } = useCurrentUser();
    const [companyUuid, setCompanyUuid] = useState<string | undefined>(undefined);
    const [userUuid, setUserUuid] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (userIsMaistro) {
            setCompanyUuid(projectInformation.clientCompanyUuid);
            setUserUuid(projectInformation.sponsorUserUuid);
        } else {
            setCompanyUuid(myCompanyUuid);
            setUserUuid(myUuid);
        }
    }, [
        projectInformation.clientCompanyUuid,
        projectInformation.sponsorUserUuid,
        userIsMaistro,
        myUuid,
        myCompanyUuid,
    ]);

    const { getLowestBreachedSpendLimitPolicy, fetchPolicies } = usePolicies(companyUuid, userUuid);

    useEffect(() => {
        dispatch(resetLayout());
        if (!isLoading && !projectError) {
            dispatch(
                projectInformation.type
                    ? setPageTitle(t("projectBrief.pageTitle"))
                    : setPageTitle(t("projectBrief.chooseType.title")),
            );
        }
        if (state) {
            dispatch(
                setBack({
                    route: state.route,
                }),
            );
        } else {
            dispatch(
                setBack({
                    route: routes.common.dashboard,
                }),
            );
        }
    }, [t, dispatch, state, projectInformation.type, isLoading, projectError]);

    useEffect(() => {
        if (projectInformation.uuid && projectInformation.status) {
            const projectStatus = ProjectStatus[projectInformation.status as keyof typeof ProjectStatus];
            if (editableProjectStatuses.find((status) => projectStatus === status) == null) {
                const currentProjectRoute = calculateProjectUrl(projectInformation.uuid, projectStatus);
                navigate(currentProjectRoute);
            }
        }
    }, [navigate, projectInformation.status, projectInformation.uuid, projectUuid]);

    const fetchClientOptions = async (searchQuery: string) => {
        searchCompanies({ companyTypes: ["Buyer"], searchQuery }, true)
            .unwrap()
            .then((response) => {
                setClientList(
                    response.items
                        .toSorted((a, b) => a.registeredName.localeCompare(b.registeredName))
                        .map((company) => ({
                            value: company.companyUuid,
                            label: company.registeredName,
                        })),
                );
            });
    };

    useEffect(() => {
        if (isLoading) return;

        searchCompanies({ companyTypes: ["Buyer"] }, true)
            .unwrap()
            .then((response) => {
                if (response.totalCount === 0) return;

                const companies = response.items
                    .toSorted((a, b) => a.registeredName.localeCompare(b.registeredName))
                    .map((company) => ({
                        value: company.companyUuid,
                        label: company.registeredName,
                    }));

                const { clientCompanyUuid, clientName } = projectInformation;
                if (
                    clientCompanyUuid &&
                    clientName &&
                    companies.every((company) => company.value !== clientCompanyUuid)
                ) {
                    companies.unshift({ value: clientCompanyUuid, label: clientName });
                }

                setClientList(companies);
            });
    }, [isLoading, projectInformation, searchCompanies]);

    const createSponsorList = (users: CompanyUserDto[]) => {
        const options: IOptionProps[] = [
            {
                value: "",
                label: "",
            },
        ];

        users.forEach((user) => {
            const fullName = `${user.firstName} ${user.lastName}`;
            if (fullName.trim()) {
                options.push({
                    value: user.userUuid,
                    label: fullName,
                });
            }
        });

        return options;
    };

    const handleClientSelected = useCallback(
        async (clientUuid?: string) => {
            setCompanyUuid(clientUuid);
            if (userIsMaistro) {
                fetchUsersForCompany(clientUuid);
            }
        },
        [fetchUsersForCompany, userIsMaistro],
    );

    useEffect(() => {
        if (companyUuid) {
            listCompanyUsers({ searchQuery: sponsorSearchText, companyUuid }, true)
                .unwrap()
                .then((response) => {
                    const sponsorOptions = createSponsorList(response.items);
                    setSponsorList(sponsorOptions);
                })
                .catch(() => {
                    toast.error(t("projectBrief.api.failCompanyPeople"));
                    setSponsorList([]);
                });
        } else {
            setSponsorList([]);
        }
    }, [companyUuid, listCompanyUsers, sponsorSearchText, t, toast]);

    useEffect(() => {
        const updateProjectFiles = async () => {
            const uuid = searchParams.get("projectUuid") ?? projectUuid;
            if (!uuid) return;
            const newFiles = projectFiles.filter((file) => file.newUpload);

            if (newFiles.length) {
                const fileUuids = newFiles.map((file) => file.fileId);
                await linkProjectFiles(uuid, fileUuids);
                setProjectFiles((prevState) =>
                    prevState.map((file) => ({
                        ...file,
                        newUpload: false,
                    })),
                );
            }
        };
        updateProjectFiles();
    }, [projectUuid, projectFiles, searchParams, setProjectFiles]);

    const fetchCategoryOptions = async (searchParameter?: string) => {
        if (searchParameter && searchParameter.length > 0) {
            const response = await searchTaxonomy(searchParameter);

            if (response.data instanceof TransactionErrorDto) {
                toast.error(t("projectBrief.api.failSearchTaxonomy"));
                return [];
            }

            return response.data._embedded.items.map((item) => ({
                value: item._embedded.uuid,
                label: item._embedded.hierarchyDisplayName,
                status: CategorySelectionStatus.Manual,
            }));
        }

        return [];
    };

    const autoCategorizeProject = async (
        inputText: string,
        setFieldValue: (field: string, value: ICategoryProps[], shouldValidate?: boolean) => void,
        categories: Array<ICategoryProps>,
    ) => {
        const returnedItems = await autoCategorizeFromText(inputText);
        let newCategoryState = categories.filter((item) => item.status !== CategorySelectionStatus.Automatic);

        if (returnedItems && returnedItems.length > 0) {
            // only add new entries if they are not already present to prevent overriding of manual/removed
            const itemsToAdd: ICategoryProps[] = returnedItems
                .filter(
                    (item) =>
                        newCategoryState.find((c) => stringService.compare(c.value, item.taxonomyItemUuid)) ===
                        undefined,
                )
                .map((item) => ({
                    value: item.taxonomyItemUuid,
                    label: item.hierarchyDisplayName,
                    status: CategorySelectionStatus.Automatic,
                }));

            newCategoryState = newCategoryState.concat(itemsToAdd);
        }

        setFieldValue("categories", newCategoryState);
    };

    const processProjectBrief = debounce(
        (
            inputText: string,
            setFieldValue: (field: string, value: ICategoryProps[], shouldValidate?: boolean) => void,
            categories: Array<ICategoryProps>,
        ) => {
            const cleanText = htmlService.convertHtmlToString(inputText);
            autoCategorizeProject(cleanText, setFieldValue, categories);
        },
        500,
    );

    const submit = async (updatedProjectUuid: string) => {
        dispatch(fetchUserPermissions({ userUuid: myUuid }));

        if (projectInformation.type === ProjectType.ExpressionOfInterest) {
            navigate(buildPath(routes.projects.monitorExpressionOfInterest, { projectUuid: updatedProjectUuid }));
        } else {
            navigate(buildPath(routes.projects.matching, { projectUuid: updatedProjectUuid }));
        }
    };

    const checkForApprovals = async (updatedProjectUuid: string, values: IProjectInformation) => {
        const breachedSpendLimitPolicy = getLowestBreachedSpendLimitPolicy(
            projectInformation.type,
            values.budgetCurrency,
            values.buyerBudget,
        );

        if (!breachedSpendLimitPolicy) {
            await submit(updatedProjectUuid);
            return;
        }

        setBreachedPolicy(breachedSpendLimitPolicy);
        setIsSpendLimitApprovalPopupOpen(true);
    };

    const checkForUpdatedPolicies = async (values: IProjectInformation) => {
        if (userIsMaistro) {
            await fetchPolicies(values.clientCompanyUuid ?? companyUuid, values.sponsorUserUuid ?? userUuid);
        }
    };

    const save = async (values: IProjectInformation, dirtyValues: DirtyValues, autoSave = false): Promise<void> => {
        await checkForUpdatedPolicies(values);

        setNewProjectUuid(null);
        if (
            !autoSave &&
            values.status === ProjectStatus.Create &&
            projectInformation.type === ProjectType.ExpressionOfInterest
        ) {
            values.status = ProjectStatus.AwaitingResponses;
        }

        return createOrUpdateProject(values, dirtyValues, !autoSave).then(async (updatedProjectUuid: string | null) => {
            if (!updatedProjectUuid) return Promise.reject(new Error("Invalid ProjectUuid"));
            setNewProjectUuid(updatedProjectUuid);

            if (!(projectUuid && validationService.isValidUuid(projectUuid))) {
                navigate(
                    buildPath(routes.projects.projectBrief, {
                        projectUuid: `new?projectUuid=${updatedProjectUuid}`,
                    }),
                    { replace: true },
                );
            }

            if (!autoSave) {
                const shouldCheckForApprovals =
                    values.buyerBudget > 0 && projectInformation.type !== ProjectType.ExpressionOfInterest;

                if (shouldCheckForApprovals) {
                    await checkForApprovals(updatedProjectUuid, values);
                } else {
                    await submit(updatedProjectUuid);
                }
            }

            setIsSubmitting(false);

            return Promise.resolve();
        });
    };

    const handleSelectProjectType = (projectType: ProjectType) => {
        setProjectInformation({
            ...projectInformation,
            type: projectType,
        });
    };

    const handleCancel = (clientCompanyUuid?: string) => {
        setCompanyUuidToCancel(clientCompanyUuid ?? companyUuid);
        setIsCancelPopupOpen(true);
    };

    const handleSponsorSearchTextChange = (searchText: string) => {
        setSponsorSearchText(searchText);
    };

    const handleContributorSearchTextChange = (searchText: string) => {
        setCompanyUsersSearchText(searchText);
    };

    if (isLoading) {
        return <Loader />;
    }

    if (projectError === ErrorCode.Forbidden) {
        return <ProjectBriefError />;
    }

    if (!projectInformation.type) {
        return <ChooseProjectTypeDisplay setProjectType={handleSelectProjectType} />;
    }

    return (
        <React.Fragment>
            <ScrollToTop />
            <ProjectBriefForm
                values={projectInformation}
                fetchCategoryOptions={fetchCategoryOptions}
                save={save}
                disableSubmit={isSubmitting}
                cancel={handleCancel}
                processText={processProjectBrief}
                showClientOptions={userIsMaistro}
                clientOptions={clientList}
                fetchClientOptions={fetchClientOptions}
                onClientSelect={handleClientSelected}
                sponsorOptions={sponsorList}
                companyUsers={companyUsers}
                files={projectFiles}
                setFiles={setProjectFiles}
                removeFile={removeFile}
                newProjectUuid={newProjectUuid}
                handleContributorSearchTextChange={handleContributorSearchTextChange}
                handleSponsorSearchTextChange={handleSponsorSearchTextChange}
            />
            <Popup
                title={t("popups.cancelProject.title")}
                message={t("popups.cancelProject.message")}
                isOpen={isCancelPopupOpen}
                primaryActionText={t("popups.cancelProject.cta.primary")}
                onPrimaryAction={() => cancelProject(companyUuidToCancel)}
                onClose={() => setIsCancelPopupOpen(false)}
                testid="cancel-project-popup"
                disabled={isSubmitting}
            />
            <Popup
                title={t("popups.approvals.budgetWarning.title")}
                message={t("popups.approvals.budgetWarning.message", {
                    spendLimit: currencyService.toDisplayFormat(
                        breachedPolicy?.spendLimit?.currency,
                        breachedPolicy?.spendLimit?.limit,
                    ),
                })}
                isOpen={isSpendLimitApprovalPopupOpen}
                primaryActionText={t("popups.approvals.budgetWarning.cta.primary")}
                onPrimaryAction={() => {
                    if (newProjectUuid) {
                        submit(newProjectUuid);
                    }
                    setIsSpendLimitApprovalPopupOpen(false);
                }}
                secondaryActionText={t("popups.approvals.budgetWarning.cta.secondary")}
                onSecondaryAction={() => setIsSpendLimitApprovalPopupOpen(false)}
                onClose={() => setIsSpendLimitApprovalPopupOpen(false)}
                variant="warning"
                testid="budget-warning-popup"
            />
        </React.Fragment>
    );
};

export default ProjectBriefContainer;
