import { useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getSupplierSubmissionsForProject } from "api/project/projectSubmissionsApi";
import { getSuppliersTenderScores } from "api/projectSuppliersApi";
import { Loader } from "components";
import AwardSummaryDisplay from "features/project/awardSummary/AwardSummaryDisplay";
import useProject from "features/project/hooks/useProject";
import { IProjectInformation } from "features/project/types";
import useAppDispatch from "hooks/useAppDispatch";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { setBack, setPageTitle } from "store/layoutSlice";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import ProjectStatus from "types/enums/projects/ProjectStatus";
import ProjectType from "types/enums/projects/ProjectType";
import SupplierSubmissionStatus from "types/enums/supplierSubmissions/SupplierSubmissionStatus";

const AwardSummaryContainer: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [selectedSupplier, setSelectedSupplier] = useState<SupplierSubmissionResponseDto>();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const toast = useToaster();

    const { projectInformation, createOrUpdateProject } = useProject();

    const getSelected = useCallback(async () => {
        if (!projectInformation.uuid) return;
        const response = await getSupplierSubmissionsForProject(
            projectInformation.uuid,
            SupplierSubmissionStatus.Successful,
        );

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("projectBrief.api.errorGettingSelectedSupplier"));
            return;
        }

        const selectedSupplierResponse = response.data._embedded.items[0];

        if (projectInformation.type === ProjectType.Tender) {
            const supplierScoreResponse = await getSuppliersTenderScores(
                projectInformation.uuid,
                response.data._embedded.items[0].supplierCompanyUuid,
            );
            if (supplierScoreResponse.status !== 200) {
                toast.error(t("monitorScreen.api.supplierScoresError"));
            } else {
                selectedSupplierResponse.scoring =
                    supplierScoreResponse.data.submissionScores[0].calculatedSubmissionScore;
            }
        }

        setSelectedSupplier(selectedSupplierResponse);

        setIsLoading(false);
    }, [projectInformation.type, projectInformation.uuid, setIsLoading, t, toast]);

    useEffect(() => {
        dispatch(setBack(null));
        dispatch(setPageTitle(t("awardSummary.title")));
        getSelected();
    }, [dispatch, getSelected, projectInformation.uuid, t, projectInformation]);

    const completeProject = useCallback(
        async (values: IProjectInformation) => {
            await createOrUpdateProject(
                { ...values, status: ProjectStatus.Complete },
                { decisionSummary: true, status: true },
                false,
            );
            navigate(buildPath(routes.projects.complete, { projectUuid: projectInformation.uuid }));
        },
        [createOrUpdateProject, navigate, projectInformation.uuid],
    );

    if (isLoading) {
        return <Loader />;
    }

    return (
        <AwardSummaryDisplay
            projectInformation={projectInformation}
            supplier={selectedSupplier}
            completeProject={completeProject}
        />
    );
};

export default AwardSummaryContainer;
