import { ITableColumn } from "@maistro/components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import ApprovalDecisionActions from "features/company/buyer/approvals/components/ApprovalDecisionActions";
import currencyService from "services/currencyService";
import { UserApprovalDto } from "types/dtos/company/approvals/UserApprovalDto";

const useApprovalsConfiguration = (
    onInformation: (row: UserApprovalDto) => void,
    onApproval: (row: UserApprovalDto) => void,
    onRejection: (row: UserApprovalDto) => void,
) => {
    const { t } = useTranslation();

    const columns: ITableColumn[] = useMemo(
        () => [
            {
                position: 1,
                priorityLevel: 1,
                label: t("approvals.table.policyName"),
                name: "policyName",
                render: (approval: UserApprovalDto) => {
                    return t("approvals.table.spendLimitName", {
                        options: {
                            name: approval.policy.name,
                            limit: currencyService.toDisplayFormat(
                                approval.policy.spendLimit?.currency,
                                approval.policy.spendLimit?.limit,
                            ),
                        },
                    });
                },
                minWidth: 175,
                contentWrap: true,
            },
            {
                position: 2,
                priorityLevel: 3,
                label: t("approvals.table.projectName"),
                name: "projectName",
                minWidth: 175,
                contentWrap: true,
            },
            {
                position: 3,
                priorityLevel: 6,
                label: t("approvals.table.projectType"),
                name: "projectType",
                minWidth: 175,
                contentWrap: true,
            },

            {
                position: 4,
                priorityLevel: 4,
                label: t("approvals.table.projectSponsor"),
                name: "projectSponsor",
                minWidth: 175,
                contentWrap: true,
            },
            {
                position: 5,
                priorityLevel: 5,
                label: t("approvals.table.projectManager"),
                name: "projectManager",
                minWidth: 175,
            },
            {
                position: 6,
                priorityLevel: 2,
                name: "decision",
                render: (approval: UserApprovalDto) => (
                    <ApprovalDecisionActions
                        onInformation={onInformation}
                        onApproval={onApproval}
                        onRejection={onRejection}
                        approval={approval}
                    />
                ),
                minWidth: 260,
            },
        ],
        [t, onInformation, onApproval, onRejection],
    );

    return {
        columns,
    };
};

export default useApprovalsConfiguration;
