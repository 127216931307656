import { faArrowToBottom } from "@fortawesome/pro-regular-svg-icons";
import { Button, Copy, Drawer, Heading, Table } from "@maistro/components";
import React, { useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import useCompleteProjectConfiguration from "features/project/completeProject/hooks/useCompleteProjectConfiguration";
import { IProjectInformation } from "features/project/types";
import { ITheme } from "styles/themes/types";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    table: {
        margin: `${theme.spacing.large}px 0`,
    },
    sow: {
        marginTop: theme.spacing.medium,
    },
    copy: {
        maxWidth: "100%",
    },
    button: {
        marginTop: theme.spacing.medium,
    },
    heading: {
        marginBottom: theme.spacing.medium,
    },
    buttonContainer: {
        width: "100%",
        marginBottom: theme.spacing.medium,
    },
    drawerButton: {
        width: "100%",
    },
}));

interface ICompleteProjectDisplayProps {
    clarificationQuestions: QuestionResponseDto[] | null;
    projectInformation: IProjectInformation;
    downloadProjectSummary: () => void;
    downloadStatementOfWork: () => void;
    downloadTenderResponseReport: () => void;
    downloadClarificationQuestionsReport: () => void;
}

const CompleteProjectDisplay: React.FC<ICompleteProjectDisplayProps> = (props) => {
    const [isDownloadDrawerOpen, setIsDownloadDrawerOpen] = useState(false);

    const classes = useStyles();

    const {
        clarificationQuestions,
        projectInformation,
        downloadProjectSummary,
        downloadStatementOfWork,
        downloadTenderResponseReport,
        downloadClarificationQuestionsReport,
    } = props;

    const openDownloadDrawer = () => {
        setIsDownloadDrawerOpen(true);
    };

    const { t } = useTranslation();
    const { columns } = useCompleteProjectConfiguration(openDownloadDrawer);

    return (
        <>
            <Copy>{t("completeProject.information")}</Copy>

            <Table
                className={classes.table}
                columns={columns}
                data={[projectInformation]}
                noDataDisplay={t("common.noResults")}
                testid="project-summary-table"
            />

            <div className={classes.sow}>
                <Copy className={classes.copy}>{t("completeProject.sow.copy")}</Copy>
                <Button
                    label={t("completeProject.sow.button")}
                    variant="outline"
                    className={classes.button}
                    icon={faArrowToBottom}
                    onClick={downloadStatementOfWork}
                    testid="download-sow-button"
                />
            </div>

            <Drawer
                isOpen={isDownloadDrawerOpen}
                onClose={() => setIsDownloadDrawerOpen(false)}
                testid="download-drawer"
            >
                <div>
                    <div className={classes.heading}>
                        <Heading variant="h2" testid="reports-drawer-title">
                            {t("completeProject.reports")}
                        </Heading>
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button
                            label={t("completeProject.downloadProjectReport")}
                            variant="outline"
                            className={classes.drawerButton}
                            icon={faArrowToBottom}
                            onClick={downloadProjectSummary}
                            testid="download-project-summary-button"
                        />
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button
                            label={t("completeProject.scoreSummaryReport")}
                            variant="outline"
                            className={classes.drawerButton}
                            icon={faArrowToBottom}
                            onClick={() => {
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "Results Display - Score Summary Report - ".concat(
                                        projectInformation.projectName,
                                    ),
                                });
                                window.open(
                                    `/projects/${projectInformation.uuid}/tender-summary/score-summary-report`,
                                    "_blank",
                                );
                            }}
                            testid="download-score-summary-button"
                        />
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button
                            label={t("completeProject.tenderResponseReport")}
                            variant="outline"
                            className={classes.drawerButton}
                            icon={faArrowToBottom}
                            onClick={downloadTenderResponseReport}
                            testid="download-tender-response-report-button"
                        />
                    </div>
                    {clarificationQuestions !== null && clarificationQuestions.length > 0 && (
                        <div className={classes.buttonContainer}>
                            <Button
                                label={t("completeProject.clarificationQuestionsReport")}
                                variant="outline"
                                className={classes.drawerButton}
                                icon={faArrowToBottom}
                                onClick={() => {
                                    ReactGA.event({
                                        category: ga4Category.Button,
                                        action: ga4Action.ButtonClick,
                                        label: "Clarification Questions - Download Report",
                                    });
                                    downloadClarificationQuestionsReport();
                                }}
                                testid="download-clarification-questions-report-button"
                            />
                        </div>
                    )}
                </div>
            </Drawer>
        </>
    );
};

export default CompleteProjectDisplay;
