enum NotificationType {
    BuyerCreatedProjectNotification = "BuyerCreatedProjectNotification",
    ClarificationAnswerToReviewNotification = "ClarificationAnswerToReviewNotification",
    ClarificationQuestionToReviewNotification = "ClarificationQuestionToReviewNotification",
    ProjectAllocatedToMaistroPmNotification = "ProjectAllocatedToMaistroPmNotification",
    ProjectChangedToMaistroManagedNotification = "ProjectChangedToMaistroManagedNotification",
    SorApprovedByBuyerNotification = "SorApprovedByBuyerNotification",
    SorRejectedByBuyerNotification = "SorRejectedByBuyerNotification",
    TenderResponseDeadlineExpiredScheduledNotification = "TenderResponseDeadlineExpiredScheduledNotification",
    ApprovalRequestNotification = "ApprovalRequestNotification",
}

export default NotificationType;
