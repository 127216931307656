import { faFloppyDisk } from "@fortawesome/pro-regular-svg-icons";
import { Button, Icon, Popup, ToggleButton, useToaster } from "@maistro/components";
import classNames from "classnames";
import { Form, useFormikContext } from "formik";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { deleteProjectSection } from "api/questions/tenderCriteriaQuestionsApi";
import { ICommonProps } from "components/shared";
import { getSectionTitle } from "features/project/helpers/sectionHelpers";
import PercentageTotal from "features/project/tenderCriteria/weighting/PercentageTotal";
import SectionWeightingRow from "features/project/tenderCriteria/weighting/SectionWeightingRow";
import ScoringMode from "features/project/tenderCriteria/weighting/types/ScoringMode";
import ReactGA from "react-ga4";
import stringService from "services/stringService";
import { ITheme } from "styles/themes/types";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { ProjectSectionWeightings } from "types/dtos/questions/sections/ProjectSectionWeightings";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";
import { SectionImportanceLevel } from "types/dtos/questions/sections/SectionImportanceLevel";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import ImportanceLevel from "types/enums/questions/sections/ImportanceLevel";

const useStyles = createUseStyles((theme: ITheme) => ({
    actions: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
        margin: `${theme.spacing.large}px 0`,
    },

    toggle: {
        "& > *": {
            flexBasis: "50%",
        },
    },

    actionsGroup: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: theme.spacing.large,
    },

    splitButton: {
        padding: `${theme.spacing.xSmall}px ${theme.spacing.large}px`,
        fontSize: 14,
        fontWeight: 500,
    },

    continue: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing.units(6),
    },

    flexGrow: { flexGrow: 1 },
    flexEnd: {},
    buttonCentering: {},

    showMd: {},
    hideMd: {},

    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        toggle: {
            "& > *": {
                flexBasis: "auto",
                minWidth: 150,
            },
        },
    },

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
        showMd: { display: "none !important" },
    },

    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        actions: {
            flexDirection: "row",
            alignItems: "center",
        },

        hideMd: { display: "none !important" },
    },

    [`@media (min-width: ${theme.breakpoints.xl}px)`]: {
        flexEnd: {
            justifyContent: "flex-end",
        },

        buttonCentering: {
            textAlign: "center",
            width: `calc(250px * 3 + 2 * ${theme.spacing.medium}px)`, // Card Buttons
            marginRight: `calc(50px + ${theme.spacing.medium}px)`, // % + flex-gap
        },
    },
}));

interface ISectionWeightingFormProps extends ICommonProps {
    projectUuid?: string;
    sections: SectionDto[];
    importanceLevels: SectionImportanceLevel[];
    submit: (values: ProjectSectionWeightings, goToNextPage: boolean) => Promise<void>;
    isSubmitting: boolean;
    setSections: (newSections: SectionDto[]) => void;
}

const SectionWeightingForm: React.FC<ISectionWeightingFormProps> = ({
    projectUuid,
    sections,
    importanceLevels,
    submit,
    isSubmitting,
    setSections,
}) => {
    const classes = useStyles();

    const [isManualTotalWarningOpen, setIsManualTotalWarningOpen] = useState(false);
    const [isRemoveSectionWarningOpen, setIsRemoveSectionWarningOpen] = useState(false);
    const [sectionToRemove, setSectionToRemove] = useState("");
    const [isRemovingSection, setIsRemovingSection] = useState(false);

    const { t } = useTranslation();
    const toast = useToaster();

    const { errors, setFieldValue, setValues, values } = useFormikContext<ProjectSectionWeightings>();

    const singleSectionSelected = sections.length === 1;

    const constructValues = useCallback(
        (
            calculateImportanceLevel: (importanceLevel: ImportanceLevel | undefined) => ImportanceLevel | undefined,
            calculateWeighting: (weighting: number | undefined) => number | undefined,
            sectionsToMap: SectionDto[],
        ): ProjectSectionWeightings => {
            return {
                sectionWeightings: sectionsToMap.map((section) => ({
                    id: section.id,
                    importanceLevel: calculateImportanceLevel(section.importanceLevel),
                    weighting: calculateWeighting(section.weighting),
                })),
            };
        },
        [],
    );

    const defaultImportance: ImportanceLevel | undefined = useMemo(
        () =>
            singleSectionSelected
                ? importanceLevels.find((value) => value.id === ImportanceLevel.VeryImportant)?.id
                : importanceLevels.find((value) => value.id === ImportanceLevel.Important)?.id,
        [importanceLevels, singleSectionSelected],
    );

    const defaultScoringMode: ScoringMode = useMemo(() => {
        if (singleSectionSelected) {
            return "automatic";
        }

        const sectionsHaveWeightings = sections.some((s) => !(s.weighting === undefined || s.weighting === null));
        const sectionsHaveImportanceLevels = sections.some(
            (s) => !(s.importanceLevel === undefined || s.importanceLevel === null),
        );

        if (sectionsHaveWeightings && !sectionsHaveImportanceLevels) {
            return "manual";
        }

        return "automatic";
    }, [sections, singleSectionSelected]);
    const [scoringMode, setScoringMode] = useState<ScoringMode>(defaultScoringMode);

    const setDefaultValuesOnLoad = useCallback(() => {
        if (isRemovingSection) return;

        if (defaultScoringMode === "automatic") {
            const defaultValues = constructValues(
                (importanceLevel) => (singleSectionSelected ? defaultImportance : importanceLevel ?? defaultImportance),
                (weighting) => weighting,
                sections,
            );
            setValues(defaultValues);
            return;
        }

        const sectionsMissingWeightings = sections.some((s) => s.weighting === undefined || s.weighting === null);
        if (sectionsMissingWeightings) {
            const defaultValues = constructValues(
                (importanceLevel) => importanceLevel,
                (weighting) => weighting ?? 0,
                sections,
            );
            setValues(defaultValues);
            setIsManualTotalWarningOpen(true);
        }
    }, [
        constructValues,
        defaultImportance,
        defaultScoringMode,
        isRemovingSection,
        sections,
        setValues,
        singleSectionSelected,
    ]);

    useEffect(() => {
        setDefaultValuesOnLoad();
    }, [setDefaultValuesOnLoad]);

    const toggleScoringMode = (mode: ScoringMode) => {
        if (mode === scoringMode) return;

        setScoringMode(mode);

        if (mode === "manual") {
            const resetValues = constructValues(
                () => undefined,
                () => 0,
                sections,
            );
            setValues(resetValues);
        }

        if (mode === "automatic") {
            const resetValues = constructValues(
                () => defaultImportance,
                () => undefined,
                sections,
            );
            setValues(resetValues);
        }
    };

    const getEqualValues = useCallback(
        (sectionsToSplit: SectionDto[]) => {
            const percentage = 100 / sectionsToSplit.length;
            const flooredPercentage = Math.floor(percentage);
            let integerRemainder = 100 - flooredPercentage * sectionsToSplit.length;

            return constructValues(
                () => undefined,
                () => {
                    if (integerRemainder > 0) {
                        integerRemainder -= 1;
                        return flooredPercentage + 1;
                    }
                    return flooredPercentage;
                },
                sectionsToSplit,
            );
        },
        [constructValues],
    );

    const splitEqually = () => {
        const splitValues = getEqualValues(sections);
        setValues(splitValues);
    };

    const calculateImportance = useCallback(
        (importanceLevel?: ImportanceLevel): number => {
            if (!importanceLevel) return 0;

            const matchingImportanceLevel = importanceLevels.find((value) => value.id === importanceLevel);
            return matchingImportanceLevel?.weighting ?? 0;
        },
        [importanceLevels],
    );

    const calculatePercentages = useCallback(
        (sectionWeightings: ProjectSectionWeightings) => {
            if (scoringMode === "manual") return;

            const denominator = sectionWeightings.sectionWeightings.reduce(
                (total, section) => total + calculateImportance(section.importanceLevel),
                0,
            );
            const cumulativeFlooredPercentage = sectionWeightings.sectionWeightings.reduce(
                (total, section) =>
                    total + Math.floor((calculateImportance(section.importanceLevel) / denominator) * 100),
                0,
            );
            let integerRemainder = 100 - cumulativeFlooredPercentage;

            sectionWeightings.sectionWeightings.forEach(({ importanceLevel }, index) => {
                if (!importanceLevel) return;

                const percentage = (calculateImportance(importanceLevel) / denominator) * 100;
                let roundedPercentage = Math.floor(percentage);
                if (integerRemainder > 0) {
                    integerRemainder -= 1;
                    roundedPercentage += 1;
                }
                setFieldValue(`sectionWeightings.${index}.weighting`, roundedPercentage);
            });
        },
        [calculateImportance, scoringMode, setFieldValue],
    );

    const getSelectedSectionName = () => {
        if (!sectionToRemove) return "";

        const section = sections.find((s) => s.id === sectionToRemove);

        if (!section) return "";

        return getSectionTitle(section);
    };

    const recalculateValues = useCallback(
        (uuid: string) => {
            const oldValues = values.sectionWeightings;
            const persistedSections = sections.filter((s) => s.id !== uuid);
            setSections(persistedSections);

            if (scoringMode === "manual") {
                const newValues = { sectionWeightings: oldValues.filter((ov) => ov.id !== uuid) };

                const equalValue = 100 / (persistedSections.length + 1);
                const hasBeenSplitEqually =
                    oldValues.filter(
                        (sw) =>
                            sw.weighting &&
                            sw.weighting >= Math.floor(equalValue) &&
                            sw.weighting <= Math.ceil(equalValue),
                    ).length ===
                    persistedSections.length + 1;

                if (hasBeenSplitEqually) {
                    const splitValues = getEqualValues(persistedSections);
                    setValues(splitValues);
                    return;
                }

                setValues(newValues);
                return;
            }

            const newWeightings = oldValues.filter((ov) => ov.id !== uuid);
            const newValues = { sectionWeightings: newWeightings };
            setValues(newValues);
            calculatePercentages(newValues);
        },
        [calculatePercentages, getEqualValues, scoringMode, sections, setSections, setValues, values.sectionWeightings],
    );

    const deleteSection = useCallback(
        (uuid: string) => {
            if (!uuid) return;

            const section = sections.find((s) => s.id === uuid);
            const sectionName = section === undefined ? "" : getSectionTitle(section);

            setIsRemovingSection(true);
            setIsRemoveSectionWarningOpen(false);
            deleteProjectSection({ projectUuid: projectUuid ?? "", sectionUuid: uuid })
                .then((response) => {
                    if (response instanceof TransactionErrorDto) {
                        toast.error(t("tenderCriteria.weighting.removeSection.error", { sectionName }));
                        return;
                    }

                    recalculateValues(uuid);
                })
                .finally(() => {
                    toast.success(t("tenderCriteria.weighting.removeSection.success", { sectionName }));
                });
        },
        [projectUuid, recalculateValues, sections, t, toast],
    );

    const confirmDeleteSection = useCallback(() => {
        deleteSection(sectionToRemove);
    }, [deleteSection, sectionToRemove]);

    useEffect(() => {
        calculatePercentages(values);
    }, [calculatePercentages, values]);

    return (
        <>
            <Form>
                <div className={classes.actions}>
                    {!singleSectionSelected && (
                        <div className={classNames(classes.actionsGroup, classes.flexGrow)}>
                            <ToggleButton
                                className={classNames(classes.toggle, classes.flexGrow)}
                                options={[
                                    {
                                        label: t("tenderCriteria.weighting.automatic"),
                                        value: "automatic",
                                        active: scoringMode === "automatic",
                                    },
                                    {
                                        label: t("tenderCriteria.weighting.manual"),
                                        value: "manual",
                                        active: scoringMode === "manual",
                                    },
                                ]}
                                onClick={(value: ScoringMode) => {
                                    ReactGA.event({
                                        category: ga4Category.Button,
                                        action: ga4Action.ButtonClick,
                                        label: "Tender Criteria - Weighting - ".concat(value),
                                    });
                                    toggleScoringMode(value);
                                }}
                                testid="scoring-mode-toggle"
                            />
                            <Icon
                                className={classNames({ [classes.hideMd]: scoringMode === "manual" })}
                                icon={faFloppyDisk}
                                alt="Save"
                                onClick={() => {
                                    ReactGA.event({
                                        category: ga4Category.Button,
                                        action: ga4Action.ButtonClick,
                                        label: "Tender Criteria - Weighting - Save",
                                    });
                                    submit(values, false);
                                }}
                            />
                        </div>
                    )}

                    {scoringMode === "manual" && (
                        <div className={classes.actionsGroup}>
                            <PercentageTotal />
                            <Button
                                className={classes.splitButton}
                                variant="outline"
                                size="small"
                                label={t("tenderCriteria.weighting.splitEqually")}
                                onClick={() => {
                                    ReactGA.event({
                                        category: ga4Category.Button,
                                        action: ga4Action.ButtonClick,
                                        label: `Sections Weighting - Split Equally`,
                                    });
                                    splitEqually();
                                }}
                                testid="split-equally-button"
                            />
                            <Icon
                                className={classes.showMd}
                                icon={faFloppyDisk}
                                alt="Save"
                                onClick={() => submit(values, false)}
                            />
                        </div>
                    )}
                </div>
                <div>
                    {sections.map((section, index) => (
                        <SectionWeightingRow
                            key={section.id}
                            uuid={section.id}
                            index={index}
                            title={getSectionTitle(section)}
                            scoringMode={scoringMode}
                            importanceLevels={importanceLevels}
                            disabled={singleSectionSelected}
                            testid={stringService.toKebabCase(getSectionTitle(section))}
                            setSectionToRemove={setSectionToRemove}
                            setIsRemoveSectionWarningOpen={setIsRemoveSectionWarningOpen}
                            deleteSection={deleteSection}
                        />
                    ))}
                </div>
                <div className={classNames(classes.continue, { [classes.flexEnd]: scoringMode === "automatic" })}>
                    <div className={classNames({ [classes.buttonCentering]: scoringMode === "automatic" })}>
                        <Button
                            label={t("tenderCriteria.weighting.submitButton")}
                            size="large"
                            type="submit"
                            disabled={!isEmpty(errors)}
                            chevron={isEmpty(errors)}
                            loading={isEmpty(errors) && isSubmitting}
                            testid="submit-weightings-button"
                            onClick={() =>
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "Tender Criteria - Weighting - Submit",
                                })
                            }
                        />
                    </div>
                </div>
            </Form>
            <Popup
                title={t("popups.tenderCriteria.manualTotalWarning.title")}
                message={t("popups.tenderCriteria.manualTotalWarning.message")}
                isOpen={isManualTotalWarningOpen}
                primaryActionText={t("popups.tenderCriteria.manualTotalWarning.cta.primary")}
                onPrimaryAction={() => setIsManualTotalWarningOpen(false)}
                onClose={() => setIsManualTotalWarningOpen(false)}
                testid="manual-total-warning-popup"
            />
            <Popup
                title={t("popups.tenderCriteria.removeSectionWarning.title", { sectionName: getSelectedSectionName() })}
                message={t("popups.tenderCriteria.removeSectionWarning.message")}
                isOpen={isRemoveSectionWarningOpen}
                primaryActionText={t("popups.tenderCriteria.removeSectionWarning.cta.primary")}
                secondaryActionText={t("popups.tenderCriteria.removeSectionWarning.cta.secondary")}
                onPrimaryAction={confirmDeleteSection}
                onSecondaryAction={() => setIsRemoveSectionWarningOpen(false)}
                onClose={() => setIsRemoveSectionWarningOpen(false)}
                testid="remove-section-warning-popup"
            />
        </>
    );
};

export default SectionWeightingForm;
