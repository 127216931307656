import { useCallback, useMemo } from "react";

import useReduxSelector from "hooks/useReduxSelector";
import { selectFeatures } from "store/platformSlice";
import PlatformFeatureType from "types/enums/platform/PlatformFeatureType";

const usePlatformFeatures = () => {
    const features = useReduxSelector(selectFeatures);

    const isPlatformFeatureEnabled = useCallback(
        (name: PlatformFeatureType) => {
            const feature = features.find((f) => f.name === name);
            return feature === undefined ? false : feature.isEnabled;
        },
        [features],
    );

    const isAIAssistAvailable = useMemo(() => {
        return isPlatformFeatureEnabled(PlatformFeatureType.AIAssist);
    }, [isPlatformFeatureEnabled]);

    return {
        isAIAssistAvailable,
    };
};

export default usePlatformFeatures;
