import { Drawer, Heading, ReadOnlyField } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { ITheme } from "styles/themes/types";
import { SubmissionAdditionalInformationDto } from "types/dtos/projects/supplierSubmissions/SubmissionAdditionalInformationDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
    supplierName: {
        fontSize: 14,
    },
}));

interface ISubmissionAdditionalInformationProps {
    isOpen: boolean;
    additionalInformation: SubmissionAdditionalInformationDto | undefined;
    setAdditionalInformation: React.Dispatch<React.SetStateAction<SubmissionAdditionalInformationDto | undefined>>;
}

const SubmissionAdditionalInformationDrawer: React.FC<ISubmissionAdditionalInformationProps> = (props) => {
    const classes = useStyles();

    const { isOpen, additionalInformation, setAdditionalInformation } = props;

    const { t } = useTranslation();

    return (
        <Drawer
            isOpen={isOpen}
            onClose={() => setAdditionalInformation(undefined)}
            withCloseFooter
            testid="submission-additional-information-drawer"
        >
            <div className={classes.container}>
                <Heading variant="h2">{t("common.additionalInformation")}</Heading>
                <ReadOnlyField
                    label={t("common.supplier")}
                    value={<span className={classes.supplierName}>{additionalInformation?.supplierName}</span>}
                    testid="supplier-name"
                />
                <ReadOnlyField
                    label={t("common.additionalInformation")}
                    value={additionalInformation?.additionalInformation}
                    testid="supplier-documents"
                />
            </div>
        </Drawer>
    );
};

export default SubmissionAdditionalInformationDrawer;
