import { faPeopleGroup } from "@fortawesome/pro-duotone-svg-icons";
import { faBallotCheck, faForwardFast } from "@fortawesome/pro-regular-svg-icons";
import { Card, Grid } from "@maistro/components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import useCurrentUser from "hooks/useCurrentUser";
import useReduxSelector from "hooks/useReduxSelector";
import useWhitelabelSettings from "hooks/useWhitelabelSettings";
import ReactGA from "react-ga4";
import colorService from "services/colorService";
import { ITheme } from "styles/themes/types";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import ProjectType from "types/enums/projects/ProjectType";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

const useStyles = createUseStyles((theme: ITheme) => ({
    content: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: theme.spacing.large,
        marginTop: theme.spacing.medium,
    },
    boldText: {
        fontWeight: 800,
    },
    copy: {
        padding: theme.spacing.xLarge,
        backgroundColor: colorService.toHexWithOpacity(theme.colors.gray, 0.1),
        borderRadius: theme.spacing.xSmall,
        textAlign: "center",
    },
    [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        content: {
            marginTop: theme.spacing.xLarge,
        },
    },
}));

interface IChooseProjectTypeProps {
    setProjectType: (type: ProjectType) => void;
}

const ChooseProjectTypeDisplay: React.FC<IChooseProjectTypeProps> = ({ setProjectType }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { whitelabelEmailSettings } = useWhitelabelSettings();
    const { userHasPermission } = useCurrentUser();
    const { theme } = useReduxSelector((state) => state.themeState);

    const displayCopy = useMemo(() => {
        if (userHasPermission(PermissionsEnum.ManageSupport)) {
            return false;
        }
        return !theme.isWhiteLabelled;
    }, [theme.isWhiteLabelled, userHasPermission]);

    return (
        <div className={classes.content}>
            <Grid width={340} centered>
                <Card
                    title={t("common.tender")}
                    icon={faBallotCheck}
                    text={t("projectBrief.chooseType.tender")}
                    hoverIsSelectedState
                    isActive={false}
                    onClick={() => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: "Create Project - ".concat(ProjectType.Tender),
                        });
                        setProjectType(ProjectType.Tender);
                    }}
                    testid="tender-type-card"
                />
                <Card
                    title={t("common.quote")}
                    icon={faForwardFast}
                    text={t("projectBrief.chooseType.quote")}
                    hoverIsSelectedState
                    isActive={false}
                    onClick={() => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: "Create Project - ".concat(ProjectType.Quote),
                        });
                        setProjectType(ProjectType.Quote);
                    }}
                    testid="quote-type-card"
                />
                <Card
                    title={t("common.expressionOfInterest")}
                    icon={faPeopleGroup}
                    text={t("projectBrief.chooseType.expressionOfInterest")}
                    hoverIsSelectedState
                    isActive={false}
                    onClick={() => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: "Create Project - ".concat(ProjectType.ExpressionOfInterest),
                        });
                        setProjectType(ProjectType.ExpressionOfInterest);
                    }}
                    testid="eoi-type-card"
                />
            </Grid>
            {displayCopy && (
                <div className={classes.copy} data-testid="type-choose-information">
                    <span className={classes.boldText}>{t("projectBrief.chooseType.information.question")}</span>
                    <br />
                    {t("projectBrief.chooseType.information.answer", {
                        options: {
                            telephoneNumber: whitelabelEmailSettings.defaultFromTelephoneNumber,
                        },
                    })}
                </div>
            )}
        </div>
    );
};

export default ChooseProjectTypeDisplay;
