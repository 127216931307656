import { Drawer, ISortBy, ITableColumn, Popup, Table, useToaster } from "@maistro/components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { archiveProject, unarchiveProject } from "api/projectApi";
import { NotFoundScreen } from "components";
import EmptyDashboard from "features/dashboard/EmptyDashboard";
import DuplicateProjectForm from "features/dashboard/duplicate/DuplicateProjectForm";
import { IDuplicateProjectInformation } from "features/dashboard/interfaces/IDuplicateProjectInformation";
import { IProjectDashboardInformation } from "features/dashboard/interfaces/IProjectDashboardInformation";
import ReactGA from "react-ga4";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import ProjectStatus from "types/enums/projects/ProjectStatus";

interface IProjectsDashboardDisplayProps {
    filteredProjects: Array<IProjectDashboardInformation>;
    setSortBy?: (sort: ISortBy) => void;
    sortBy: ISortBy;
    refreshData: () => void;
    columns: ITableColumn[];
    shouldDisplayEmptyDashboard: boolean;
    shouldDisplayNoResultsDashboard: boolean;
    createProject: () => void;
    userCanCreateProjects: boolean;
    setIsFilterDrawerOpen: () => void;
    isDuplicating: boolean;
    createDuplicateProject: (projectToDuplicate: IDuplicateProjectInformation) => void;
    clearFilters: () => void;
    projectToAction: IProjectDashboardInformation | undefined;
    isArchivePopupOpen: boolean;
    setIsArchivePopupOpen: (isOpen: boolean) => void;
    isDuplicateDrawerOpen: boolean;
    setIsDuplicateDrawerOpen: (isOpen: boolean) => void;
    onRowClick: (row: IProjectDashboardInformation) => void;
}

const ProjectsDashboardDisplay: React.FC<IProjectsDashboardDisplayProps> = ({
    filteredProjects,
    setSortBy,
    sortBy,
    refreshData,
    columns,
    shouldDisplayEmptyDashboard,
    shouldDisplayNoResultsDashboard,
    createProject,
    userCanCreateProjects,
    setIsFilterDrawerOpen,
    isDuplicating,
    createDuplicateProject,
    clearFilters,
    projectToAction,
    isArchivePopupOpen,
    setIsArchivePopupOpen,
    isDuplicateDrawerOpen,
    setIsDuplicateDrawerOpen,
    onRowClick,
}) => {
    const [isUpdatingProjectArchiveStatus, setIsUpdatingProjectArchiveStatus] = useState(false);

    const { t } = useTranslation();
    const toast = useToaster();

    const archiveOrRestoreProject = () => {
        if (projectToAction) {
            ReactGA.event({
                category: ga4Category.Button,
                action: ga4Action.ButtonClick,
                label: "Project Archive submit - ".concat(projectToAction.projectName),
            });
        }
        if (!projectToAction) {
            toast.error(t("dashboard.archivePopup.api.noProject"));
            setIsArchivePopupOpen(false);
            return;
        }
        setIsUpdatingProjectArchiveStatus(true);
        if (projectToAction?.projectStatus === ProjectStatus.Archived) {
            unarchiveProject(projectToAction.projectUuid).then((response) => {
                if (response.status !== 204) {
                    toast.error(t("dashboard.archivePopup.api.error"));
                    setIsArchivePopupOpen(false);
                    return;
                }
                toast.success(t("dashboard.archivePopup.api.restoreSuccess"));
                setIsArchivePopupOpen(false);
                refreshData();
            });
            setIsUpdatingProjectArchiveStatus(false);
            return;
        }
        archiveProject(projectToAction.projectUuid).then((response) => {
            if (response.status !== 204) {
                toast.error(t("dashboard.archivePopup.api.error"));
                setIsArchivePopupOpen(false);
                return;
            }
            toast.success(t("dashboard.archivePopup.api.archiveSuccess"));
            setIsArchivePopupOpen(false);
            setIsUpdatingProjectArchiveStatus(false);
            refreshData();
        });
    };

    if (shouldDisplayEmptyDashboard) {
        return <EmptyDashboard createProject={createProject} canCreateProject={userCanCreateProjects} />;
    }

    if (shouldDisplayNoResultsDashboard) {
        return (
            <NotFoundScreen
                testid="no-result-dashboard"
                title={t("dashboard.noResultsDashboard.title")}
                message={t("dashboard.noResultsDashboard.copy")}
                primaryActionText={t("common.amendCriteria")}
                primaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: "NotFoundScreen: Filter drawer open",
                    });
                    setIsFilterDrawerOpen();
                }}
                secondaryActionText={t("common.resetFilters")}
                secondaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: "NotFoundScreen: Reset filter",
                    });
                    clearFilters();
                }}
            />
        );
    }

    return (
        <>
            <Table
                columns={columns}
                data={filteredProjects}
                noDataDisplay={t("common.noResults")}
                onSort={setSortBy}
                sortBy={sortBy}
                rowAction={(row: IProjectDashboardInformation) => {
                    ReactGA.event({
                        category: ga4Category.Element,
                        action: ga4Action.Element,
                        label: "Project: ".concat(row.projectName.toString()),
                    });
                    onRowClick(row);
                }}
                testid="projects-list"
            />
            <Popup
                isOpen={isArchivePopupOpen}
                title={t("dashboard.archivePopup.title")}
                message={
                    projectToAction?.projectStatus === ProjectStatus.Archived
                        ? t("dashboard.archivePopup.unarchiveMessage")
                        : t("dashboard.archivePopup.archiveMessage")
                }
                primaryActionText={
                    projectToAction?.projectStatus === ProjectStatus.Archived
                        ? t("dashboard.archivePopup.confirmUnarchive")
                        : t("dashboard.archivePopup.confirmArchive")
                }
                onPrimaryAction={archiveOrRestoreProject}
                onClose={() => setIsArchivePopupOpen(false)}
                testid={`${projectToAction?.projectUuid}-archive-popup`}
                disabled={isUpdatingProjectArchiveStatus}
            />
            <Drawer
                isOpen={isDuplicateDrawerOpen}
                onClose={() => setIsDuplicateDrawerOpen(false)}
                testid="duplicate-project-drawer"
            >
                {projectToAction && (
                    <DuplicateProjectForm
                        duplicateFromProject={projectToAction}
                        onCreate={createDuplicateProject}
                        isDuplicating={isDuplicating}
                        onCancel={() => setIsDuplicateDrawerOpen(false)}
                    />
                )}
            </Drawer>
        </>
    );
};

export default ProjectsDashboardDisplay;
