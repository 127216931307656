import React, { useEffect, useState } from "react";

import { ICommonProps } from "components/shared";
import { getSectionTitle } from "features/project/helpers/sectionHelpers";
import useQuestionStyles from "features/project/shared/tender-questions/useQuestionStyles";
import ScoreTenderBySupplierQuestionRow from "features/project/tenderCriteria/components/scoring/ScoreTenderBySupplierQuestionsRow";
import SectionRow from "features/project/tenderCriteria/components/SectionRow/SectionRow";
import { AnswerDto } from "types/dtos/questions/AnswerResponseDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { ScoreResponseDto } from "types/dtos/questions/ScoreResponseDto";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";

interface IScoreTenderBySupplierSectionRowProps extends ICommonProps {
    projectUuid: string;
    section: SectionDto;
    questions: QuestionResponseDto[];
    haveScores: (answer: AnswerDto) => boolean;
    canScoreAnswers: boolean;
    canModerateScores: boolean;
    expandedByDefault: boolean;
    setScore: (score: ScoreResponseDto, questionUuid: string) => void;
    questionUuid?: string;
}

const ScoreTenderBySupplierSectionRow: React.FC<IScoreTenderBySupplierSectionRowProps> = (props) => {
    const questionClasses = useQuestionStyles();

    const {
        projectUuid,
        section,
        questions,
        haveScores,
        canScoreAnswers,
        expandedByDefault,
        testid,
        canModerateScores,
        setScore,
        questionUuid,
    } = props;

    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    useEffect(() => {
        setIsExpanded(expandedByDefault);
    }, [expandedByDefault]);

    const containsConditionalQuestion = questions.some((question) => question.isConditional);
    const isSectionComplete = questions.every((question) => question.answers?.every(haveScores));
    const selectedQuestionIndex = questionUuid
        ? questions.findIndex((q) => q.questionUuid.toLowerCase() === questionUuid.toLowerCase())
        : 0;

    return (
        <SectionRow
            className={questionClasses.sectionRow}
            title={getSectionTitle(section)}
            totalQuestions={questions.length}
            weighting={section.weighting ?? 0}
            hasMandatoryQuestions={containsConditionalQuestion}
            expanded={isExpanded}
            isComplete={isSectionComplete}
            testid={testid}
            onToggleExpand={setIsExpanded}
        >
            <div>
                {isExpanded &&
                    questions.map((question, index) => (
                        <ScoreTenderBySupplierQuestionRow
                            key={question.questionUuid}
                            projectUuid={projectUuid}
                            question={question}
                            haveScores={haveScores}
                            expandedByDefault={index === selectedQuestionIndex}
                            canScoreAnswers={canScoreAnswers}
                            canModerateScores={canModerateScores}
                            testid={`question-${question.questionUuid}`}
                            setScore={setScore}
                        />
                    ))}
            </div>
        </SectionRow>
    );
};
export default ScoreTenderBySupplierSectionRow;
