import { LoadingScreen } from "@maistro/components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import CompleteProjectDisplay from "features/project/completeProject/CompleteProjectDisplay";
import useProject from "features/project/hooks/useProject";
import useClarificationQuestion from "features/project/hooks/useProjectClarificationQuestions";
import { useTenderCriteria } from "features/project/hooks/useTenderCriteria";
import useAppDispatch from "hooks/useAppDispatch";
import useCurrentUser from "hooks/useCurrentUser";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { resetLayout, setBack, setCtas, setMobileFooterCtas, setPageTitle } from "store/layoutSlice";

const CompleteProjectContainer: React.FC = () => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [loadingTitle, setLoadingTitle] = useState("");
    const [loadingMessage, setLoadingMessage] = useState("");

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { projectInformation, downloadSummary, downloadStatementOfWork } = useProject();
    const { userCanCreateProjects } = useCurrentUser();

    const { clarificationQuestions, downloadClarificationsReport } = useClarificationQuestion(
        projectInformation.uuid,
        undefined,
        setIsDownloading,
    );

    const { downloadTenderResponsesReport } = useTenderCriteria({
        projectUuid: projectInformation.uuid,
    });

    const handleDownloadSummary = useCallback(() => {
        setLoadingTitle(t("awardSummary.projectSummary.title"));
        setLoadingMessage(t("awardSummary.projectSummary.text"));
        setIsDownloading(true);
        downloadSummary().finally(() => setIsDownloading(false));
    }, [downloadSummary, t]);

    const handleDownloadStatementOfWork = useCallback(() => {
        setLoadingTitle(t("awardSummary.statementOfWork.title"));
        setLoadingMessage(t("awardSummary.statementOfWork.text"));
        setIsDownloading(true);
        downloadStatementOfWork().finally(() => setIsDownloading(false));
    }, [downloadStatementOfWork, t]);

    const handleDownloadClarificationQuestionsReport = () => {
        setLoadingTitle(t("awardSummary.clarificationQuestionsReport.title"));
        setLoadingMessage(t("awardSummary.clarificationQuestionsReport.text"));
        setIsDownloading(true);
        downloadClarificationsReport().finally(() => setIsDownloading(false));
    };
    const handledDownloadTenderResponseReport = () => {
        setLoadingTitle(t("awardSummary.tenderResponse.title"));
        setLoadingMessage(t("awardSummary.tenderResponse.text"));
        setIsDownloading(true);
        downloadTenderResponsesReport().finally(() => setIsDownloading(false));
    };

    const createProject = useCallback(() => {
        navigate(buildPath(routes.projects.projectBrief, { projectUuid: "new" }));
    }, [navigate]);

    useEffect(() => {
        dispatch(setPageTitle(t("completeProject.title")));
        dispatch(setBack(null));

        if (userCanCreateProjects) {
            const createProjectButtonProps = {
                onClick: createProject,
                label: t("dashboard.projects.cta"),
                testid: "new-project-button",
                chevron: true,
            };
            dispatch(setCtas([createProjectButtonProps]));
            dispatch(setMobileFooterCtas([createProjectButtonProps]));
        }

        return () => {
            dispatch(resetLayout());
        };
    }, [createProject, dispatch, handleDownloadSummary, t, userCanCreateProjects]);

    return (
        <>
            {isDownloading && (
                <LoadingScreen title={loadingTitle} message={loadingMessage} duration={35000} testid="loading-screen" />
            )}
            <CompleteProjectDisplay
                projectInformation={projectInformation}
                clarificationQuestions={clarificationQuestions}
                downloadProjectSummary={handleDownloadSummary}
                downloadStatementOfWork={handleDownloadStatementOfWork}
                downloadTenderResponseReport={handledDownloadTenderResponseReport}
                downloadClarificationQuestionsReport={handleDownloadClarificationQuestionsReport}
            />
        </>
    );
};

export default CompleteProjectContainer;
