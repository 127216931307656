import { BooleanOptionsField, Heading, TextField } from "@maistro/components";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { IVettingQuestionnaireForm } from "features/company/profile/vetting/IVettingQuestionnaireForm";
import useSectionStyles from "features/company/profile/vetting/sections/useSectionStyles";
import { VettingInformationKeys } from "types/dtos/company/vettingInformation/VettingInformationDto";
import { createUseStyles } from "react-jss";
import { ITheme } from "styles/themes/types";
import Accreditations from "features/company/profile/vetting/sections/ethicsSubSections/Accreditations";
import { AccreditationDto } from "types/dtos/company/vettingInformation/AccreditationDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    conditionalQuestions: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.small,
        marginTop: theme.spacing.small,
    },
}));

interface IQualitySectionProps {
    companyAccreditations: AccreditationDto[];
    setCompanyAccreditations: React.Dispatch<React.SetStateAction<AccreditationDto[]>>;
}

export const qualityValidationSchema = () =>
    Yup.object({
        hasResponsiblePersonQuality: Yup.string().nullable(),
        personResponsible: Yup.string().nullable(),
        registeredWithConstructionIndustryScheme: Yup.boolean().nullable(),
        registeredAsGrossPaymentStatus: Yup.boolean().nullable(),
    });

const QualitySubSection: React.FC<IQualitySectionProps> = ({ companyAccreditations, setCompanyAccreditations }) => {
    const sharedClasses = useSectionStyles();
    const classes = useStyles();

    const { t } = useTranslation();

    const { values } = useFormikContext<IVettingQuestionnaireForm>();

    return (
        <div id={VettingInformationKeys.quality}>
            <Heading variant="h4">{t("companyVettingQuestionnaire.sections.ethics.quality.title")}</Heading>
            <ol className={sharedClasses.listStyle} type="1">
                <li>
                    <BooleanOptionsField
                        name="hasResponsiblePersonQuality"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t("companyVettingQuestionnaire.sections.ethics.quality.hasResponsiblePerson.label")}
                        testid="has-responsible-person-options-field"
                    />
                    {values.hasResponsiblePersonQuality && (
                        <div className={classes.conditionalQuestions}>
                            <TextField
                                name="personResponsible"
                                label={t("companyVettingQuestionnaire.sections.ethics.quality.personResponsible.label")}
                                testid="person-responsible-field"
                            />
                        </div>
                    )}
                </li>
                <li>
                    <Accreditations
                        companyAccreditations={companyAccreditations}
                        setCompanyAccreditations={setCompanyAccreditations}
                    />
                </li>
                <li>
                    <BooleanOptionsField
                        name="registeredWithConstructionIndustryScheme"
                        falseOptionLabel={t("common.no")}
                        trueOptionLabel={t("common.yes")}
                        required
                        label={t(
                            "companyVettingQuestionnaire.sections.ethics.quality.registeredWithConstructionIndustryScheme.label",
                        )}
                        testid="registered-with-construction-industry-scheme-options-field"
                    />
                    {values.registeredWithConstructionIndustryScheme && (
                        <div className={classes.conditionalQuestions}>
                            <BooleanOptionsField
                                name="registeredAsGrossPaymentStatus"
                                falseOptionLabel={t("common.no")}
                                trueOptionLabel={t("common.yes")}
                                required
                                label={t(
                                    "companyVettingQuestionnaire.sections.ethics.quality.registeredAsGrossPaymentStatus.label",
                                )}
                                testid="registered-as-gross-payment-status-options-field"
                            />
                        </div>
                    )}
                </li>
            </ol>
        </div>
    );
};

export default QualitySubSection;
