import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { INotificationData } from "types/dtos/notifications/NotificationDto";
import NotificationType from "types/enums/notifications/NotificationType";

const getBuyerActionUrl = (notificationType: NotificationType, data: INotificationData): string => {
    switch (notificationType) {
        case NotificationType.ClarificationAnswerToReviewNotification:
        case NotificationType.ClarificationQuestionToReviewNotification:
            return buildPath(routes.projects.clarificationQuestions, { projectUuid: data.projectUuid });
        case NotificationType.ApprovalRequestNotification:
            return buildPath(routes.company.approvals, { companyUuid: data.companyUuid });
        default:
            return buildPath(routes.common.dashboard);
    }
};

const getSupplierActionUrl = (notificationType: NotificationType, data: INotificationData): string => {
    switch (notificationType) {
        case NotificationType.ClarificationAnswerToReviewNotification:
        case NotificationType.ClarificationQuestionToReviewNotification:
            return buildPath(routes.projectResponses.clarificationQuestions, {
                projectUuid: data.projectUuid,
                responseUuid: data.submissionUuid,
            });
        case NotificationType.ApprovalRequestNotification:
            return buildPath(routes.company.approvals, { companyUuid: data.companyUuid });
        default:
            return buildPath(routes.common.dashboard);
    }
};

const generateActionUrl = (notificationType: NotificationType, data: INotificationData): string => {
    if (data.submissionUuid) {
        return getSupplierActionUrl(notificationType, data);
    }

    return getBuyerActionUrl(notificationType, data);
};

export default generateActionUrl;
