import { Alert, Button, Copy, Drawer, Heading, TextAreaField, TextField } from "@maistro/components";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import currencyService from "services/currencyService";
import { ITheme } from "styles/themes/types";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import { UpdateSupplierSubmissionTcvRequestDto } from "types/dtos/projects/supplierSubmissions/UpdateSupplierSubmissionTcvRequestDto";
import CurrencyType from "types/enums/CurrencyType";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.large,
    },
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.small,
    },
    warning: {
        ...theme.typography.content.error,
    },
    buttons: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing.small,
    },
}));

interface ITotalContractValueDrawerProps {
    supplier: SupplierSubmissionResponseDto | undefined;
    currencyType: CurrencyType;
    spendLimit: number | undefined;
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (request: UpdateSupplierSubmissionTcvRequestDto) => void;
}

const TotalContractValueDrawer: React.FC<ITotalContractValueDrawerProps> = (props) => {
    const classes = useStyles();

    const { supplier, currencyType, spendLimit, isOpen, onClose, onSubmit } = props;

    const { t } = useTranslation();

    const overSpendLimit = (tcv: number) => {
        if (spendLimit === undefined) {
            return false;
        }
        return tcv > spendLimit;
    };

    return (
        <Drawer contentClassName={classes.container} isOpen={isOpen} onClose={onClose} testid="tcv-drawer">
            <Heading variant="h2">{t("monitorScreen.tcv.title")}</Heading>
            <Copy>{t("monitorScreen.tcv.text")}</Copy>
            {supplier && (
                <Formik
                    initialValues={{
                        projectUuid: supplier.projectUuid,
                        supplierUuid: supplier.supplierCompanyUuid,
                        responseUuid: supplier._embedded.responseUuid,
                        buyerPrice: supplier.buyerPricing.simplePrice.price ?? 0,
                        buyerNotes: supplier.buyerNotes ?? "",
                    }}
                    validationSchema={Yup.object({
                        buyerPrice: Yup.number()
                            .required(t("monitorScreen.tcv.buyerPrice.required"))
                            .min(0, t("monitorScreen.tcv.buyerPrice.required")),
                        buyerNotes: Yup.string(),
                    })}
                    enableReinitialize
                    onSubmit={onSubmit}
                >
                    {({ dirty, isValid, values }) => (
                        <Form className={classes.form}>
                            <TextField
                                name="buyerPrice"
                                required
                                type="price"
                                currencyType={currencyType}
                                testid="tcv-buyer-price"
                            />
                            {overSpendLimit(values.buyerPrice) && (
                                <Alert
                                    className={classes.warning}
                                    label={t("monitorScreen.tcv.warning", {
                                        spendLimit: currencyService.toDisplayFormat(currencyType, spendLimit),
                                    })}
                                    type="error"
                                    testid="spend-limit-warning"
                                />
                            )}
                            <TextAreaField
                                label={t("monitorScreen.tcv.buyerNotes.label")}
                                name="buyerNotes"
                                testid="tcv-buyer-notes"
                            />
                            <div className={classes.buttons}>
                                <Button
                                    label={t("common.submit")}
                                    type="submit"
                                    disabled={!dirty || !isValid}
                                    testid="tcv-submit"
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </Drawer>
    );
};

export default TotalContractValueDrawer;
