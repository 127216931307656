import { Copy, Heading, Popup, TextButton } from "@maistro/components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import noMatchesImage from "assets/images/noMatches-color.svg";
import ReactGA from "react-ga4";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { ITheme } from "styles/themes/types";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    wrapper: {
        position: "block",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    },

    content: {
        width: "100%",
        padding: theme.spacing.small,
        maxWidth: 450,
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.xLarge,
        marginTop: theme.spacing.unit,
    },

    moreOptionsContainer: {
        marginTop: theme.spacing.xLarge,
    },

    copy: {
        marginTop: theme.spacing.xSmall,
    },

    moreOptionsLink: {
        ...theme.typography.content.copy,
    },
}));

interface INoMatchesProps {
    projectUuid?: string;
    cancelProject: () => void;
}

const NoMatches: React.FC<INoMatchesProps> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { projectUuid, cancelProject } = props;
    const [isContactSupportOpen, setIsContactSupportOpen] = useState(false);

    const sendEmail = () => {
        window.location.href = "mailto:platformmonitoring@maistro.com";
    };

    const handleAmendCategories = () => {
        navigate(buildPath(routes.projects.projectBrief, { projectUuid: projectUuid ?? "new" }));
    };

    return (
        <>
            <div className={classes.moreOptionsContainer}>
                <Heading variant="h4">{t("projectMatchingScreen.noMatches.moreOptions.title")}</Heading>
                <Copy className={classes.copy}>
                    <>
                        {t("projectMatchingScreen.noMatches.moreOptions.amendCategories.text")}
                        <TextButton
                            className={classes.moreOptionsLink}
                            label={t("projectMatchingScreen.noMatches.moreOptions.amendCategories.link")}
                            onClick={() => {
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "NoMatches - Amend Categories",
                                });
                                handleAmendCategories();
                            }}
                            testid="no-matches-amend-categories-button"
                        />
                    </>
                </Copy>
                <Copy className={classes.copy}>
                    <>
                        {t("projectMatchingScreen.noMatches.moreOptions.contactSupport.text")}
                        <TextButton
                            className={classes.moreOptionsLink}
                            label={t("projectMatchingScreen.noMatches.moreOptions.contactSupport.link")}
                            onClick={() => {
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "NoMatches - Contact Support",
                                });
                                setIsContactSupportOpen(true);
                            }}
                            testid="no-matches-contact-support-button"
                        />
                    </>
                </Copy>
                <Copy className={classes.copy}>
                    <TextButton
                        className={classes.moreOptionsLink}
                        label={t("projectMatchingScreen.noMatches.moreOptions.cancelProject")}
                        onClick={() => {
                            ReactGA.event({
                                category: ga4Category.Button,
                                action: ga4Action.ButtonClick,
                                label: "NoMatches - Cancel Project",
                            });
                            cancelProject();
                        }}
                        testid="cancel-project-button"
                    />
                </Copy>
            </div>
            <div className={classes.wrapper}>
                <div className={classes.content}>
                    <img src={noMatchesImage} alt={t("projectMatchingScreen.noMatches.title")} />
                </div>
            </div>
            <Popup
                title={t("popups.contactSupport.title")}
                message={t("popups.contactSupport.message")}
                isOpen={isContactSupportOpen}
                primaryActionText={t("popups.contactSupport.cta.primary")}
                onPrimaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: "NoMatches - Email Support",
                    });
                    sendEmail();
                }}
                onClose={() => setIsContactSupportOpen(false)}
                testid="contact-support-popup"
            />
        </>
    );
};

export default NoMatches;
