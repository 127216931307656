import { Button, CheckboxField, SelectField, TextField } from "@maistro/components";
import { Form, Formik } from "formik";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import { ICommonProps, IOptionProps } from "components/shared";
import { ITheme } from "styles/themes/types";
import { PostalAddressDto } from "types/dtos/company/postal-addresses/PostalAddressDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    inputContainer: {
        width: "100%",
        marginBottom: theme.spacing.medium,
    },
    checkboxContainer: {
        width: "100%",
    },
    h2: {
        ...theme.typography.headings.h2,
        marginBottom: theme.spacing.medium,
    },
    buttonContainer: {
        marginTop: theme.spacing.medium,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        gap: `${theme.spacing.xSmall}px ${theme.spacing.medium}px`,
        justifyContent: "center",
    },
}));

interface ICompanyAddressFormProps extends ICommonProps {
    addresses: PostalAddressDto[];
    selectedAddress: PostalAddressDto | undefined;
    countries: IOptionProps[];
    onSubmit: (values: PostalAddressDto) => Promise<void>;
    onCancel: () => void;
    setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

const DirtySetter = (props: { dirty: boolean; setIsDirty: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const { dirty, setIsDirty } = props;
    useEffect(() => {
        setIsDirty(dirty);
    }, [setIsDirty, dirty]);
    return null;
};

const EditCompanyAddressForm: React.FC<ICompanyAddressFormProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { addresses, selectedAddress, countries, onSubmit, onCancel, setIsDirty } = props;

    const validationSchema = () =>
        Yup.object({
            streetAddress: Yup.string().required(t("addresses.addressDrawer.streetAddress.required")),
            city: Yup.string().required(t("addresses.addressDrawer.city.required")),
            county: Yup.string().optional(),
            countryTypeId: Yup.string().required(t("addresses.addressDrawer.countryTypeId.required")),
            postcode: Yup.string().required(t("addresses.addressDrawer.postcode.required")),
            isPrimaryAddress: Yup.boolean().when([], {
                is: () => {
                    return addresses
                        .filter((address) => address !== selectedAddress || !selectedAddress)
                        .every((address) => !address.isPrimaryAddress);
                },
                then: (schema) => schema.oneOf([true], t("addresses.addressDrawer.isPrimaryAddress.required")),
                otherwise: (schema) => schema.oneOf([true, false]),
            }),
        });

    return (
        <Formik
            enableReinitialize
            initialValues={{
                uuid: selectedAddress?.uuid ?? "",
                streetAddress: selectedAddress?.streetAddress ?? "",
                city: selectedAddress?.city ?? "",
                county: selectedAddress?.county ?? "",
                postcode: selectedAddress?.postcode ?? "",
                countryTypeId: selectedAddress?.countryTypeId.toString() ?? "",
                isPrimaryAddress: selectedAddress?.isPrimaryAddress ?? false,
                isInvoicingAddress: selectedAddress?.isInvoicingAddress ?? false,
            }}
            onSubmit={(values) => onSubmit(values)}
            validationSchema={validationSchema()}
        >
            {({ values, errors, isValid, dirty, isSubmitting }) => (
                <Form>
                    <h2 className={classes.h2}>
                        {values.uuid ? t("addresses.addressDrawer.editTitle") : t("addresses.addressDrawer.addTitle")}
                    </h2>

                    <div className={classes.inputContainer}>
                        <TextField
                            testid="address-streetAddress"
                            label={t("addresses.addressDrawer.streetAddress.label")}
                            name="streetAddress"
                            required
                        />
                    </div>

                    <div className={classes.inputContainer}>
                        <TextField
                            testid="address-city"
                            label={t("addresses.addressDrawer.city.label")}
                            name="city"
                            required
                        />
                    </div>

                    <div className={classes.inputContainer}>
                        <TextField
                            testid="address-county"
                            label={t("addresses.addressDrawer.county.label")}
                            name="county"
                        />
                    </div>

                    <div className={classes.inputContainer}>
                        <SelectField
                            testid="address-countryTypeId"
                            label={t("addresses.addressDrawer.countryTypeId.label")}
                            name="countryTypeId"
                            required
                            options={countries}
                        />
                    </div>

                    <div className={classes.inputContainer}>
                        <TextField
                            testid="address-postcode"
                            label={t("addresses.addressDrawer.postcode.label")}
                            name="postcode"
                            required
                        />
                    </div>

                    <div className={classes.checkboxContainer}>
                        <CheckboxField
                            testid="address-isPrimaryAddress"
                            name="isPrimaryAddress"
                            label={t("addresses.addressDrawer.isPrimaryAddress.label")}
                            tooltip={t("companyProfile.companyInformation.streetAddress.tooltip")}
                        />
                    </div>

                    <div className={classes.checkboxContainer}>
                        <CheckboxField
                            testid="address-isInvoicingAddress"
                            name="isInvoicingAddress"
                            label={t("addresses.addressDrawer.isInvoicingAddress.label")}
                            tooltip={t("addresses.addressDrawer.isInvoicingAddress.tooltip")}
                        />
                    </div>

                    <div className={classes.buttonContainer}>
                        <Button
                            testid="add-address-create-button"
                            type="submit"
                            label={t("addresses.addressDrawer.submit")}
                            disabled={!isEmpty(errors) || !isValid || !dirty || isSubmitting}
                        />
                        <Button
                            testid="add-address-cancel-button"
                            label={t("common.cancel")}
                            variant="outline"
                            onClick={() => onCancel()}
                        />
                    </div>
                    <DirtySetter dirty={dirty} setIsDirty={setIsDirty} />
                </Form>
            )}
        </Formik>
    );
};

export default EditCompanyAddressForm;
