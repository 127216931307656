import { faMemoCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { Icon } from "@maistro/components";
import React from "react";
import { createUseStyles } from "react-jss";

import ApprovalActionButton from "features/company/buyer/approvals/components/ApprovalActionButton";
import { ITheme } from "styles/themes/types";
import { UserApprovalDto } from "types/dtos/company/approvals/UserApprovalDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    actions: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing.small,
        justifyContent: "end",
    },
}));

interface IApprovalDecisionActionsProps {
    onInformation: (row: UserApprovalDto) => void;
    onApproval: (row: UserApprovalDto) => void;
    onRejection: (row: UserApprovalDto) => void;
    approval: UserApprovalDto;
}

const ApprovalDecisionActions: React.FC<IApprovalDecisionActionsProps> = ({
    approval,
    onInformation,
    onApproval,
    onRejection,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.actions}>
            <Icon icon={faMemoCircleInfo} onClick={() => onInformation(approval)} />
            {(!approval.isActioned || approval.isApproved) && (
                <ApprovalActionButton
                    label="Accept"
                    disabledLabel="Approved"
                    onClick={() => onApproval(approval)}
                    color="success"
                    disabled={approval.isActioned}
                    isApproved
                    testid={`accept-approval-${approval.projectUuid}`}
                />
            )}
            {!approval.isApproved && (
                <ApprovalActionButton
                    label="Reject"
                    disabledLabel="Rejected"
                    onClick={() => onRejection(approval)}
                    color="reject"
                    disabled={approval.isActioned}
                    isApproved={false}
                    testid={`reject-approval-${approval.projectUuid}`}
                />
            )}
        </div>
    );
};

export default ApprovalDecisionActions;
