import { TextButton, Tooltip } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import { MaistroRating } from "components";
import { ICommonProps } from "components/shared";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { IBackLayoutState } from "store/layoutSlice";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "grid",
        gridTemplateAreas: '"name" "matchRating"',
        alignItems: "center",
        justifyContent: "space-between",
        minWidth: 90,
    },
    name: {
        gridArea: "name",
    },
    matchRating: {
        gridArea: "matchRating",
    },

    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        container: {
            display: "flex",
        },
    },
}));

interface ISupplierNameColumnProps extends ICommonProps {
    name: string;
    companyUuid: string;
    matchRating: number;
    navigationState: IBackLayoutState;
}

const SupplierNameColumn: React.FC<ISupplierNameColumnProps> = (props) => {
    const classes = useStyles();

    const { name, companyUuid, matchRating, navigationState } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const viewSupplierProfile = () => {
        navigate(buildPath(routes.company.supplierCompanyProfile, { companyUuid }), {
            state: navigationState,
        });
    };

    return (
        <div className={classes.container}>
            <div>
                <TextButton
                    label={name}
                    className={classes.name}
                    onClick={viewSupplierProfile}
                    testid={`${companyUuid}-name`}
                />
                <Tooltip
                    content={t("projectMatchingScreen.tooltips.matchingStars")}
                    hideIcon
                    testid={`${companyUuid}-rating-tooltip`}
                >
                    <MaistroRating
                        className={classes.matchRating}
                        currentRating={matchRating * 5}
                        testid={`${companyUuid}-match-rating`}
                    />
                </Tooltip>
            </div>
        </div>
    );
};

export default SupplierNameColumn;
