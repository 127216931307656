import { SortOrder } from "@maistro/components";

import baseApi from "store/api/baseApi";
import { SearchCompaniesRequestDto } from "types/dtos/company/SearchCompaniesRequestDto";
import { SearchCompaniesResponseDto } from "types/dtos/company/SearchCompaniesResponseDto";

const companiesApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        searchCompanies: build.query<SearchCompaniesResponseDto, SearchCompaniesRequestDto>({
            query: (request) => ({
                url: "/companies/search",
                method: "GET",
                params: {
                    skip: request.skip ?? 0,
                    take: request.take ?? 10,
                    orderByColumn: request.sortColumn ? encodeURI(request.sortColumn) : undefined,
                    sortDescending: request.sortColumn ? request.direction === SortOrder.Descending : undefined,
                    searchQuery: request.searchQuery ? encodeURI(request.searchQuery) : undefined,
                    searchByEmail: request.searchByEmail ? encodeURI(request.searchByEmail) : undefined,
                    excludeSupplierListBuyerUuid: request.excludeSupplierListBuyerUuid
                        ? request.excludeSupplierListBuyerUuid
                        : undefined,
                    industryTypeIds: request.industryTypeIds
                        ? request.industryTypeIds.join("&industryTypeIds=")
                        : undefined,
                    companyTypes: request.companyTypes
                        ? request.companyTypes.map((companyType) => encodeURI(companyType)).join("&companyTypes=")
                        : undefined,
                    statuses: request.statuses
                        ? request.statuses.map((status) => encodeURI(status)).join("&statuses=")
                        : undefined,
                },
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useLazySearchCompaniesQuery } = companiesApi;

export default companiesApi;
