import { ITheme } from "styles/themes/types";

const baseSearchStyles = (theme: ITheme) => ({
    container: {
        position: "relative",
    },
    input: {
        ...theme.typography.content.input,
        border: "1px solid",
        borderColor: theme.colors.gray,
        borderRadius: theme.spacing.medium,
        width: "100%",
        padding: `${theme.spacing.xSmall}px ${theme.spacing.medium}px`,
        paddingRight: 45,
        textOverflow: "ellipsis",
        appearance: "none",
    },
    icon: {
        position: "absolute",
        right: theme.spacing.medium,
        top: "50%",
        transform: "translateY(-50%)",
    },
});

export default baseSearchStyles;
