import { UserManager, UserManagerSettings } from "oidc-client-ts";

import env from "config/EnvironmentVariables";

const userManagerSettings: UserManagerSettings = {
    client_id: "client",
    redirect_uri: `${env.PortalUrl}/callback`,
    response_type: "code",
    scope: "openid profile application.resource idp.api.resource offline_access",
    authority: env.IdentityServerUrl,
    silent_redirect_uri: `${env.PortalUrl}/silentrenew.html`,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    monitorSession: true,
    includeIdTokenInSilentRenew: false,
};

const userManager = new UserManager(userManagerSettings);

function retrySignin() {
    userManager.signinSilent().catch(() => {
        setTimeout(retrySignin, 5000);
    });
}

userManager.events.addSilentRenewError(function addSilentRenewError() {
    setTimeout(retrySignin, 5000);
});

export default userManager;
