import { baseTheme } from "@maistro/components";

import logo from "assets/external/images/logos/maistro/logo.png";
import squareLogo from "assets/external/images/logos/maistro/logo-square.png";
import poweredByDarkBackground from "assets/images/poweredByDarkBackground.png";
import poweredByWhiteBackground from "assets/images/poweredByWhiteBackground.png";
import { ITheme, IThemeColors, IThemeLayout, IThemeLogos } from "styles/themes/types";

const themeColors: IThemeColors = {
    ...baseTheme.colors,

    accentBar: "transparent",
    logoBackground: "transparent",
};

const themeLayout: IThemeLayout = {
    accentBarWidth: 0,
    navBarWidth: 200,
    navBarHeight: 52,
};

const themeLogos: IThemeLogos = {
    logo,
    squareLogo,
    poweredByMaistroLogo: {
        light: poweredByWhiteBackground,
        dark: poweredByDarkBackground,
    },
};

const maistroTheme: ITheme = {
    ...baseTheme,
    colors: themeColors,
    layout: themeLayout,
    logos: themeLogos,
};

export default maistroTheme;
