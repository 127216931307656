import { Grid, Heading, TextField } from "@maistro/components";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import { ITheme } from "styles/themes/types";
import { RegexEmail } from "types/consts/regexConstants";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
    heading: {
        fontWeight: 600,
    },
}));

export const primaryUserSchema = (t: TFunction<"translation", undefined>) =>
    Yup.object({
        firstName: Yup.string().required(t("companyProfile.primaryUser.firstName.required")),
        lastName: Yup.string().required(t("companyProfile.primaryUser.lastName.required")),
        emailAddress: Yup.string()
            .required(t("companyProfile.primaryUser.emailAddress.required"))
            .matches(new RegExp(RegexEmail), t("companyProfile.primaryUser.emailAddress.invalid")),
    });

const PrimaryUserForm: React.FC = () => {
    const classes = useStyles();

    const { t } = useTranslation();

    return (
        <div className={classes.container}>
            <Heading className={classes.heading} variant="h4">
                {t("companyProfile.primaryUser.title")}
            </Heading>
            <Grid columns={2}>
                <TextField
                    name="firstName"
                    label={t("companyProfile.primaryUser.firstName.label")}
                    tooltip={t("companyProfile.primaryUser.firstName.tooltip")}
                    required
                    testid="company-information-first-name"
                />
                <TextField
                    name="lastName"
                    label={t("companyProfile.primaryUser.lastName.label")}
                    tooltip={t("companyProfile.primaryUser.lastName.tooltip")}
                    required
                    testid="company-information-last-name"
                />
                <TextField
                    name="emailAddress"
                    label={t("companyProfile.primaryUser.emailAddress.label")}
                    tooltip={t("companyProfile.primaryUser.emailAddress.tooltip")}
                    required
                    testid="company-information-email-address"
                />
            </Grid>
        </div>
    );
};

export default PrimaryUserForm;
