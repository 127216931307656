import {
    Button,
    DateField,
    Drawer,
    Grid,
    Heading,
    ICommonProps,
    SelectField,
    TextAreaField,
    TextField,
} from "@maistro/components";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import DisplayVettingFileUploadField from "features/company/profile/vetting/files/DisplayVettingFileUploadField";
import ReactGA from "react-ga4";
import { useGetInsuranceTypesQuery } from "store/api/referenceDataApi";
import { ITheme } from "styles/themes/types";
import { InsuranceDto } from "types/dtos/company/vettingInformation/InsuranceDto";
import FileDto from "types/dtos/files/FileDto";
import CurrencyType from "types/enums/CurrencyType";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

interface IAddOrEditInsuranceDrawerProps extends ICommonProps {
    isOpen: boolean;
    onClose: () => void;
    insurance?: InsuranceDto;
    onSaveInsurance: (insurance: InsuranceDto) => void;
}

const useStyles = createUseStyles((theme: ITheme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
        marginTop: theme.spacing.large,
    },
    buttons: {
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing.medium,
        marginTop: theme.spacing.medium,
    },
}));

const minDate = new Date();

const AddOrEditInsuranceDrawer: React.FC<IAddOrEditInsuranceDrawerProps> = ({
    isOpen,
    onClose,
    insurance,
    onSaveInsurance,
}) => {
    const { t } = useTranslation();

    const validationSchema = () =>
        Yup.object({
            insuredEntity: Yup.string().required(
                t("companyVettingQuestionnaire.sections.companyDetails.insurance.form.insuredEntity.required"),
            ),
            typeId: Yup.string().required(
                t("companyVettingQuestionnaire.sections.companyDetails.insurance.form.insuranceType.required"),
            ),
            provider: Yup.string().required(
                t("companyVettingQuestionnaire.sections.companyDetails.insurance.form.insuranceProvider.required"),
            ),
            policyNumber: Yup.string().required(
                t("companyVettingQuestionnaire.sections.companyDetails.insurance.form.policyNumber.required"),
            ),
            expiryDate: Yup.date()
                .typeError(t("companyVettingQuestionnaire.sections.companyDetails.insurance.form.expiryDate.required"))
                .nullable()
                .required(t("companyVettingQuestionnaire.sections.companyDetails.insurance.form.expiryDate.required"))
                .min(
                    minDate,
                    t("companyVettingQuestionnaire.sections.companyDetails.insurance.form.expiryDate.minError"),
                ),
            currencyType: Yup.string().required(
                t("companyVettingQuestionnaire.sections.companyDetails.insurance.form.limitCurrency.required"),
            ),
            limit: Yup.number()
                .required(t("companyVettingQuestionnaire.sections.companyDetails.insurance.form.limit.required"))
                .min(1, t("companyVettingQuestionnaire.sections.companyDetails.insurance.form.limit.min")),
        });

    const classes = useStyles();

    const { data: insuranceTypes } = useGetInsuranceTypesQuery(undefined);

    const [insuranceFiles, setInsuranceFiles] = useState<FileDto[]>(insurance?.files ?? []);
    const [fileIsUploading, setFileIsUploading] = useState<boolean>(false);

    useEffect(() => {
        setInsuranceFiles(insurance?.files ?? []);
    }, [insurance?.files, setInsuranceFiles]);

    const onSubmit = useCallback(
        (values: InsuranceDto) => {
            if (!insuranceFiles || insuranceFiles.length < 1) {
                return;
            }

            onSaveInsurance({
                ...values,
                files: insuranceFiles,
            });
            setInsuranceFiles([]);
        },
        [insuranceFiles, onSaveInsurance, setInsuranceFiles],
    );

    return (
        <Drawer isOpen={isOpen} onClose={onClose}>
            <Heading variant="h2">
                {insurance
                    ? t("companyVettingQuestionnaire.sections.companyDetails.insurance.editInsurance")
                    : t("companyVettingQuestionnaire.sections.companyDetails.insurance.addInsurance")}
            </Heading>
            <Formik
                initialValues={
                    {
                        insuredEntity: insurance?.insuredEntity ?? "",
                        typeId: insurance?.typeId.toString() ?? "",
                        provider: insurance?.provider ?? "",
                        policyNumber: insurance?.policyNumber ?? "",
                        expiryDate: insurance?.expiryDate,
                        currencyType: insurance?.currencyType ?? CurrencyType.GBP,
                        limit: insurance?.limit ?? 0,
                        notes: insurance?.notes,
                    } as InsuranceDto
                }
                onSubmit={(values) => onSubmit(values)}
                validationSchema={validationSchema}
            >
                {({ dirty, isValid, values }) => (
                    <Form className={classes.form}>
                        <TextField
                            testid="insured-entity-field"
                            name="insuredEntity"
                            required
                            label={t(
                                "companyVettingQuestionnaire.sections.companyDetails.insurance.form.insuredEntity.label",
                            )}
                        />
                        <SelectField
                            testid="insurance-type-field"
                            name="typeId"
                            required
                            label={t(
                                "companyVettingQuestionnaire.sections.companyDetails.insurance.form.insuranceType.label",
                            )}
                            options={insuranceTypes?.data?.map((type) => ({
                                value: type.id.toString(),
                                label: t(
                                    `companyVettingQuestionnaire.sections.companyDetails.insurance.form.insuranceType.options.${type.id}`,
                                ),
                            }))}
                        />
                        <TextField
                            testid="insurance-provider-field"
                            name="provider"
                            required
                            label={t(
                                "companyVettingQuestionnaire.sections.companyDetails.insurance.form.insuranceProvider.label",
                            )}
                        />
                        <Grid columns={2}>
                            <TextField
                                testid="policy-number-field"
                                name="policyNumber"
                                required
                                label={t(
                                    "companyVettingQuestionnaire.sections.companyDetails.insurance.form.policyNumber.label",
                                )}
                            />
                            <DateField
                                testid="expiry-date-field"
                                label={t(
                                    "companyVettingQuestionnaire.sections.companyDetails.insurance.form.expiryDate.label",
                                )}
                                name="expiryDate"
                                required
                                minDate={minDate}
                                fullWidth
                            />
                        </Grid>
                        <Grid columns={2}>
                            <SelectField
                                testid="limit-currency-selector"
                                label={t("common.currency")}
                                name="currencyType"
                                options={[
                                    {
                                        value: CurrencyType.GBP.toString(),
                                        label: "£ (GBP)",
                                    },
                                    {
                                        value: CurrencyType.EUR.toString(),
                                        label: "€ (EUR)",
                                    },
                                    {
                                        value: CurrencyType.USD.toString(),
                                        label: "$ (USD)",
                                    },
                                ]}
                                required
                            />
                            <TextField
                                testid="insurance-limit-field"
                                label={t(
                                    "companyVettingQuestionnaire.sections.companyDetails.insurance.form.limit.label",
                                )}
                                name="limit"
                                type="price"
                                currencyType={values.currencyType}
                                required
                            />
                        </Grid>
                        <TextAreaField
                            name="notes"
                            label={t("companyVettingQuestionnaire.sections.companyDetails.insurance.form.notes.label")}
                            testid="notes-field"
                            rows={2}
                        />
                        <DisplayVettingFileUploadField
                            testid="insurance-file-upload-field"
                            files={insuranceFiles}
                            setFiles={setInsuranceFiles}
                            fileIsUploading={fileIsUploading}
                            setFileIsUploading={setFileIsUploading}
                            name="insuranceFiles"
                            multiple={false}
                            required
                            label={t("companyVettingQuestionnaire.fileUpload.label")}
                        />
                        <div className={classes.buttons}>
                            <Button
                                testid="cancel-button"
                                onClick={() => {
                                    ReactGA.event({
                                        category: ga4Category.Button,
                                        action: ga4Action.ButtonClick,
                                        label: `Company Vetting - Add Insurance - Cancel`,
                                    });
                                    onClose();
                                }}
                                variant="outline"
                                label={t("common.cancel")}
                            />
                            <Button
                                testid="save-insurance-button"
                                type="submit"
                                label={
                                    insurance
                                        ? t(
                                              "companyVettingQuestionnaire.sections.companyDetails.insurance.editInsurance",
                                          )
                                        : t(
                                              "companyVettingQuestionnaire.sections.companyDetails.insurance.addInsurance",
                                          )
                                }
                                disabled={!dirty || !(isValid && insuranceFiles.length > 0)}
                                onClick={() =>
                                    ReactGA.event({
                                        category: ga4Category.Button,
                                        action: ga4Action.ButtonClick,
                                        label: `Company Vetting - Add Insurance - Submit`,
                                    })
                                }
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </Drawer>
    );
};

export default AddOrEditInsuranceDrawer;
