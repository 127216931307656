import { ExpansionPanel, ReadOnlyField } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { CheckmarkedRow } from "components";
import FileList from "components/FileList/FileList";
import { ICommonProps } from "components/shared";
import currencyService from "services/currencyService";
import dateTimeService from "services/dateTimeService";
import stringService from "services/stringService";
import { ITheme } from "styles/themes/types";
import { ListReferenceDataResponseDto } from "types/dtos/company/referenceData/ListReferenceDataResponseDto";
import { InsuranceDto } from "types/dtos/company/vettingInformation/InsuranceDto";
import InsuranceType from "types/enums/companies/InsuranceType";

const useStyles = createUseStyles((theme: ITheme) => ({
    row: {
        gap: theme.spacing.medium,
    },
    list: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
    insuranceType: {
        minWidth: 150,
    },
    coverLimit: {
        minWidth: 100,
    },
    downloadFile: {
        flexGrow: 1,
        justifyContent: "flex-end",
        alignItems: "center",
    },
}));

interface IInsuranceSectionProps extends ICommonProps {
    insurance?: InsuranceDto[];
    insuranceReferenceData?: ListReferenceDataResponseDto;
}

const InsuranceSection: React.FC<IInsuranceSectionProps> = ({ insurance, testid, insuranceReferenceData }) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const displayedInsuranceTypes = [
        InsuranceType.Other,
        InsuranceType.PublicLiability,
        InsuranceType.GoodsInTransit,
        InsuranceType.ProfessionalIndemnity,
        InsuranceType.EmployeesLiability,
        InsuranceType.CyberInsurance,
    ];

    const renderRow = (insuranceType: InsuranceType) => {
        const insuranceTestid = stringService.toKebabCase(insuranceType);
        const matchingInsurance = insurance?.find(
            (entry) =>
                insuranceReferenceData?.data.find((ird) => ird.id.toString() === entry.typeId.toString())?.name ===
                insuranceType,
        );
        const isExpired = dateTimeService.dateIsInPast(matchingInsurance?.expiryDate);

        return (
            <CheckmarkedRow
                className={classes.row}
                isChecked={!!matchingInsurance && !isExpired}
                testid={insuranceTestid}
                key={insuranceTestid}
                failureText={isExpired ? t("companyInformation.insurance.expiredInsuranceWarning") : undefined}
            >
                <ReadOnlyField
                    className={classes.insuranceType}
                    label={t(`companyInformation.insurance.${stringService.toCamelCase(insuranceType)}`)}
                    value={matchingInsurance ? t("common.yes") : t("common.no")}
                    testid={`${insuranceTestid}-yes`}
                />
                <ReadOnlyField
                    className={classes.coverLimit}
                    label={t("companyInformation.insurance.coverLimit")}
                    value={currencyService.toDisplayFormat(matchingInsurance?.currencyType, matchingInsurance?.limit)}
                    testid={`${insuranceTestid}-cover-limit`}
                    useHyphenForEmptyValues
                />
                <ReadOnlyField
                    label={t("companyInformation.insurance.expiryDate")}
                    value={dateTimeService.toShortDateFormat(matchingInsurance?.expiryDate) || "-"}
                    testid={`${insuranceTestid}-expiry-date`}
                    useHyphenForEmptyValues
                />
                {matchingInsurance && matchingInsurance.files && matchingInsurance.files.length > 0 && (
                    <FileList
                        className={classes.downloadFile}
                        files={matchingInsurance.files}
                        canDownload
                        showDocumentLinkOnly
                        testid="insurance-documents"
                    />
                )}
            </CheckmarkedRow>
        );
    };

    return (
        <ExpansionPanel label={t("companyInformation.insurance.title")} testid={testid}>
            <div className={classes.list}>
                {displayedInsuranceTypes.map((insuranceType) => renderRow(insuranceType))}
            </div>
        </ExpansionPanel>
    );
};

export default InsuranceSection;
