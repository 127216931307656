import { Popup, useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { createPolicyApprovals } from "api/company/policyApi";
import { updateSupplierSubmissionTcv } from "api/project/projectSubmissionsApi";
import { awardSupplier } from "api/projectApi";
import { Loader } from "components";
import ProjectPreviewDrawer from "features/project/components/ProjectPreviewDrawer";
import useProject from "features/project/hooks/useProject";
import useProjectFiles from "features/project/hooks/useProjectFiles";
import { useTenderCriteria } from "features/project/hooks/useTenderCriteria";
import SubmissionAdditionalInformationDrawer from "features/project/monitor/SubmissionAdditionalInformationDrawer";
import SubmissionDocumentsDrawer from "features/project/monitor/SubmissionDocumentsDrawer";
import useMonitorColumns from "features/project/monitor/hooks/useMonitorColumns";
import useMonitorLayout from "features/project/monitor/hooks/useMonitorLayout";
import ResultsDisplay from "features/project/results/ResultsDisplay";
import TotalContractValueDrawer from "features/project/results/TotalContractValueDrawer";
import useCurrentUser from "hooks/useCurrentUser";
import usePolicies from "hooks/usePolicies";
import ReactGA from "react-ga4";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import currencyService from "services/currencyService";
import { setProjectInformation } from "store/projectSlice";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { BasicCompanyDto } from "types/dtos/company/BasicCompanyDto";
import { SubmissionAdditionalInformationDto } from "types/dtos/projects/supplierSubmissions/SubmissionAdditionalInformationDto";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import { UpdateSupplierSubmissionTcvRequestDto } from "types/dtos/projects/supplierSubmissions/UpdateSupplierSubmissionTcvRequestDto";
import ApprovalEntityType from "types/enums/companies/Approvals/ApprovalEntityType";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import ProjectStatus from "types/enums/projects/ProjectStatus";
import ProjectType from "types/enums/projects/ProjectType";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

const ResultsContainer: React.FC = () => {
    const [suppliers, setSuppliers] = useState<Array<SupplierSubmissionResponseDto>>([]);
    const [selectedSupplier, setSelectedSupplier] = useState<SupplierSubmissionResponseDto>();
    const [tcvSupplier, setTcvSupplier] = useState<SupplierSubmissionResponseDto>();
    const [isAwardingSupplier, setIsAwardingSupplier] = useState(false);
    const [isCompletingAtReview, setIsCompletingAtReview] = useState(false);
    const [highestScore, setHighestScore] = useState<number>();
    const [documentSupplier, setDocumentSupplier] = useState<BasicCompanyDto>();
    const [additionalInformation, setAdditionalInformation] = useState<SubmissionAdditionalInformationDto>();
    const [projectSpendLimit, setProjectSpendLimit] = useState<number>();
    const [isProjectPreviewOpen, setIsProjectPreviewOpen] = useState(false);
    const [isDocumentDrawerOpen, setIsDocumentDrawerOpen] = useState(false);
    const [isAdditionalInformationDrawerOpen, setIsAdditionalInformationDrawerOpen] = useState(false);
    const [isTcvDrawerOpen, setIsTcvDrawerOpen] = useState(false);
    const [isCompleteAtReviewPopupOpen, setIsCompleteAtReviewPopupOpen] = useState(false);
    const [isAwardProjectPopupOpen, setIsAwardProjectPopupOpen] = useState(false);
    const [isSpendLimitWarningPopupOpen, setIsSpendLimitWarningPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToaster();

    const {
        projectInformation,
        updateProjectDeadline,
        completeAtReview,
        isQuote,
        isTender,
        canUpdateProject,
        canAskQuestion,
        deadlineHasPassed,
        isProjectCompleted,
        fetchProjectSuppliers,
        checkSuppliersResponded,
    } = useProject();
    const { projectFiles } = useProjectFiles(projectInformation.uuid);
    const { myUuid, userCanCreateProjects, userHasProjectPermission, myCompanyUuid, userIsMaistro } = useCurrentUser();
    const { isScoringCompleteForProject, isScoringCompleteForUser, getTenderCriteria } = useTenderCriteria({
        projectUuid: projectInformation.uuid,
    });

    const [companyUuid, setCompanyUuid] = useState<string | undefined>(undefined);
    const [userUuid, setUserUuid] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (userIsMaistro) {
            setCompanyUuid(projectInformation.clientCompanyUuid);
            setUserUuid(projectInformation.sponsorUserUuid);
        } else {
            setCompanyUuid(myCompanyUuid);
            setUserUuid(myUuid);
        }
    }, [
        projectInformation.clientCompanyUuid,
        projectInformation.sponsorUserUuid,
        userIsMaistro,
        myUuid,
        myCompanyUuid,
    ]);

    const { getProjectSpendLimitPolicies, getBreachedSpendLimitPolicies } = usePolicies(companyUuid, userUuid);

    useEffect(() => {
        const projectSpentLimitPolicies = getProjectSpendLimitPolicies(
            projectInformation.type,
            projectInformation.budgetCurrency,
        );
        if (projectSpentLimitPolicies.length > 0) {
            setProjectSpendLimit(projectSpentLimitPolicies[0].spendLimit?.limit);
        } else {
            setProjectSpendLimit(undefined);
        }
    }, [getProjectSpendLimitPolicies, projectInformation.budgetCurrency, projectInformation.type]);

    const requireTcv = useMemo(() => {
        const projectSpendLimitPolicies = getProjectSpendLimitPolicies(
            projectInformation.type,
            projectInformation.budgetCurrency,
        );
        return projectInformation.type === ProjectType.Tender && projectSpendLimitPolicies.length > 0;
    }, [getProjectSpendLimitPolicies, projectInformation.budgetCurrency, projectInformation.type]);

    const getValueFromPercentage = useCallback((score: string | undefined) => {
        if (!score) return 0;
        return parseInt(score.slice(0, -1), 10);
    }, []);

    const selectSupplier = useCallback(
        (supplier: SupplierSubmissionResponseDto) => {
            if (selectedSupplier && selectedSupplier.supplierCompanyUuid === supplier.supplierCompanyUuid) {
                setSelectedSupplier(undefined);
                setSuppliers(
                    suppliers.map((currentSupplier) => ({
                        ...currentSupplier,
                        isSelected: false,
                        displayBadge: getValueFromPercentage(currentSupplier.scoring) === highestScore,
                    })),
                );
                return;
            }

            setSelectedSupplier(supplier);
            setSuppliers(
                suppliers.map((currentSupplier) => ({
                    ...currentSupplier,
                    isSelected: currentSupplier.supplierCompanyUuid === supplier.supplierCompanyUuid,
                    displayBadge: false,
                })),
            );

            if (requireTcv) {
                setTcvSupplier(supplier);
                setIsTcvDrawerOpen(true);
            }
        },
        [getValueFromPercentage, highestScore, requireTcv, selectedSupplier, suppliers],
    );

    const editTotalContractValue = useCallback((supplier: SupplierSubmissionResponseDto) => {
        setTcvSupplier(supplier);
        setIsTcvDrawerOpen(true);
    }, []);

    const { columnsConfiguration: columns } = useMonitorColumns({
        project: projectInformation,
        canAwardSupplier: true,
        setDocumentSupplier,
        setAdditionalInformation,
        navigationRoute: routes.projects.awardSupplier,
        editTcv: editTotalContractValue,
        selectSupplier,
        hasUserCompletedScoring: isQuote ? true : isScoringCompleteForUser,
    });

    useMonitorLayout({
        isQuote,
        isLoading,
        setIsProjectPreviewOpen,
        setIsDocumentDrawerOpen,
        setIsAdditionalInformationDrawerOpen,
        canAskQuestion,
        projectUuid: projectInformation.uuid,
        documentSupplier,
        additionalInformation,
    });

    const canModerateScores = useMemo(
        () =>
            isTender &&
            isScoringCompleteForProject &&
            userHasProjectPermission(
                PermissionsEnum.OverrideScore,
                projectInformation.uuid,
                projectInformation.companyUuid,
            ),
        [
            isTender,
            isScoringCompleteForProject,
            userHasProjectPermission,
            projectInformation.uuid,
            projectInformation.companyUuid,
        ],
    );

    const getMaxScore = useCallback(
        (suppliersInformation: SupplierSubmissionResponseDto[]) => {
            const scores = suppliersInformation.map((supplier) => getValueFromPercentage(supplier.scoring));
            return Math.max(...scores);
        },
        [getValueFromPercentage],
    );

    const fetchSuppliers = useCallback(async () => {
        setIsLoading(true);
        const suppliersInformation = await fetchProjectSuppliers();
        const currentHighestScore = getMaxScore(suppliersInformation);

        setHighestScore(currentHighestScore);
        setSuppliers(
            suppliersInformation.map((supplier) => ({
                ...supplier,
                displayBadge: getValueFromPercentage(supplier.scoring) === currentHighestScore,
            })),
        );
        setIsLoading(false);
    }, [fetchProjectSuppliers, getMaxScore, getValueFromPercentage]);

    const refreshSuppliersData = useCallback(async () => {
        getTenderCriteria(myUuid);
        await fetchSuppliers();
    }, [fetchSuppliers, getTenderCriteria, myUuid]);

    const hasSubmittedResponse = useMemo(
        () => checkSuppliersResponded(suppliers),
        [checkSuppliersResponded, suppliers],
    );

    const canCreateProject = useMemo(
        () => isQuote && deadlineHasPassed && userCanCreateProjects && !hasSubmittedResponse,
        [deadlineHasPassed, hasSubmittedResponse, isQuote, userCanCreateProjects],
    );

    const fetchData = useCallback(async () => {
        if (!projectInformation.uuid) return;

        await fetchSuppliers();
        setIsLoading(false);
    }, [projectInformation.uuid, fetchSuppliers]);

    const updateResponseDeadline = useCallback(
        async (deadline: Date) => {
            setIsLoading(true);
            await updateProjectDeadline(deadline);
            setIsLoading(false);
        },
        [updateProjectDeadline],
    );

    const closeTcvDrawer = useCallback(() => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: "Results Container - Total Contract Value Drawer - Close",
        });
        setTcvSupplier(undefined);
        setIsTcvDrawerOpen(false);
    }, []);

    const updateSuppliersListWithTcv = useCallback((supplier: SupplierSubmissionResponseDto) => {
        setSuppliers((prevState) =>
            prevState.map((existingSupplier) => {
                if (existingSupplier.supplierCompanyUuid === supplier.supplierCompanyUuid) {
                    return {
                        ...existingSupplier,
                        buyerPricing: supplier.buyerPricing,
                        buyerNotes: supplier.buyerNotes,
                    };
                }

                return existingSupplier;
            }),
        );
    }, []);

    const updateTotalContractValue = useCallback(
        async (request: UpdateSupplierSubmissionTcvRequestDto) => {
            ReactGA.event({
                category: ga4Category.Button,
                action: ga4Action.ButtonClick,
                label: "Results Container - Total Contract Value Drawer - Submit",
            });
            const response = await updateSupplierSubmissionTcv(request);
            if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                toast.error(t("monitorScreen.api.updateTcvError"));
                setIsAwardingSupplier(false);
                return;
            }

            toast.success(t("monitorScreen.api.updateTcvSuccess"));
            updateSuppliersListWithTcv(response.data);
            closeTcvDrawer();
        },
        [closeTcvDrawer, t, toast, updateSuppliersListWithTcv],
    );

    useEffect(() => {
        const keepSelectedSupplierInSync = () => {
            setSelectedSupplier((prevState) => {
                if (prevState) {
                    return suppliers.find((supplier) => supplier.supplierCompanyUuid === prevState.supplierCompanyUuid);
                }

                return prevState;
            });
        };

        keepSelectedSupplierInSync();
    }, [suppliers]);

    const awardProjectToSupplier = useCallback(async () => {
        if (!projectInformation.uuid || !selectedSupplier) return;
        setIsAwardingSupplier(true);

        const response = await awardSupplier(projectInformation.uuid, {
            supplierUuid: selectedSupplier.supplierCompanyUuid,
        });
        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("monitorScreen.api.awardSupplierError"));
            setIsAwardingSupplier(false);
            return;
        }

        navigate(buildPath(routes.projects.award, { projectUuid: projectInformation.uuid }));
    }, [navigate, projectInformation.uuid, selectedSupplier, t, toast]);

    const calculateTotalCostForApproval = useCallback(() => {
        if (!selectedSupplier) {
            return undefined;
        }

        if (projectInformation.type === ProjectType.Quote) {
            return selectedSupplier.supplierPricing.simplePrice.price;
        }

        if (requireTcv) {
            return selectedSupplier.buyerPricing.simplePrice.price;
        }

        return undefined;
    }, [projectInformation.type, requireTcv, selectedSupplier]);

    const triggerApprovals = useCallback(async () => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: "Results Container - Approvals Spend Limit Warning - Submit",
        });
        if (!selectedSupplier) return;

        setIsAwardingSupplier(true);
        const totalCost = calculateTotalCostForApproval();
        const breachedPolicies = getBreachedSpendLimitPolicies(
            projectInformation.type,
            projectInformation.budgetCurrency,
            totalCost,
        );

        const response = await createPolicyApprovals({
            companyUuid: projectInformation.companyUuid,
            projectUuid: projectInformation.uuid,
            entityType: ApprovalEntityType.SupplierSubmission,
            entityUuid: selectedSupplier?._embedded.responseUuid,
            breachedPolicies: breachedPolicies.map((bp) => {
                return { groupUuid: bp.groupUuid, policyUuid: bp.id };
            }),
        });
        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("monitorScreen.api.awardSupplierError"));
            setIsAwardingSupplier(false);
            return;
        }

        dispatch(setProjectInformation({ ...projectInformation, status: ProjectStatus.Approval }));
        navigate(buildPath(routes.projects.monitorApprovals, { projectUuid: projectInformation.uuid }));
    }, [
        calculateTotalCostForApproval,
        dispatch,
        getBreachedSpendLimitPolicies,
        navigate,
        projectInformation,
        selectedSupplier,
        t,
        toast,
    ]);

    const showAwardPopup = useCallback(() => {
        const totalCost = calculateTotalCostForApproval();
        const breachedPolicies = getBreachedSpendLimitPolicies(
            projectInformation.type,
            projectInformation.budgetCurrency,
            totalCost,
        );

        if (breachedPolicies.length > 0) {
            setIsSpendLimitWarningPopupOpen(true);
        } else {
            setIsAwardProjectPopupOpen(true);
        }
    }, [
        calculateTotalCostForApproval,
        getBreachedSpendLimitPolicies,
        projectInformation.budgetCurrency,
        projectInformation.type,
    ]);

    const completeWithoutAward = useCallback(async () => {
        setIsCompletingAtReview(true);
        await completeAtReview();
        setIsCompletingAtReview(false);
        setIsCompleteAtReviewPopupOpen(false);
    }, [completeAtReview]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <ResultsDisplay
                suppliers={suppliers}
                project={projectInformation}
                refreshSuppliers={refreshSuppliersData}
                updateResponseDeadline={updateResponseDeadline}
                showAwardPopup={showAwardPopup}
                showCompleteAtReviewPopup={() => setIsCompleteAtReviewPopupOpen(true)}
                selectedSupplier={selectedSupplier}
                columns={columns}
                conditions={{
                    isCompletingAtReview,
                    hasDeadlinePassed: deadlineHasPassed,
                    hasSubmittedResponse,
                    canCreateProject,
                    canCompleteWithoutAward: !isProjectCompleted && canUpdateProject,
                    canViewScoreSummaryReport: isTender,
                    canModerateScores,
                    canUpdateProject,
                    isQuote,
                    requireTcv,
                }}
            />
            <SubmissionDocumentsDrawer
                projectFiles={projectFiles}
                isOpen={isDocumentDrawerOpen}
                supplier={documentSupplier}
                setSupplier={setDocumentSupplier}
            />
            <SubmissionAdditionalInformationDrawer
                isOpen={isAdditionalInformationDrawerOpen}
                additionalInformation={additionalInformation}
                setAdditionalInformation={setAdditionalInformation}
            />
            <ProjectPreviewDrawer
                projectFiles={projectFiles}
                isOpen={isProjectPreviewOpen}
                close={() => setIsProjectPreviewOpen(false)}
            />
            <TotalContractValueDrawer
                supplier={tcvSupplier}
                currencyType={projectInformation.budgetCurrency}
                spendLimit={projectSpendLimit}
                isOpen={isTcvDrawerOpen}
                onClose={closeTcvDrawer}
                onSubmit={updateTotalContractValue}
            />
            <Popup
                title={t("popups.awardProject.title")}
                message={t("popups.awardProject.message", {
                    selectedSupplierName: selectedSupplier?._embedded.supplierCompanyName,
                })}
                isOpen={isAwardProjectPopupOpen}
                primaryActionText={t("popups.awardProject.cta.primary")}
                onPrimaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: "Results Container - Award Project - Submit",
                    });
                    awardProjectToSupplier();
                }}
                secondaryActionText={t("popups.awardProject.cta.secondary")}
                onSecondaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: "Results Container - Award Project - Cancel",
                    });
                    setIsAwardProjectPopupOpen(false);
                }}
                onClose={() => setIsAwardProjectPopupOpen(false)}
                disabled={isAwardingSupplier}
                testid="award-project-to-supplier-popup"
            />
            <Popup
                title={t("popups.completeProjectWithoutAward.title")}
                message={t("popups.completeProjectWithoutAward.message")}
                isOpen={isCompleteAtReviewPopupOpen}
                primaryActionText={t("popups.completeProjectWithoutAward.primary")}
                onPrimaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: "Results Container - Complete Project Without Award - Submit",
                    });
                    completeWithoutAward();
                }}
                secondaryActionText={t("common.cancel")}
                onSecondaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: "Results Container - Complete Project Without Award - Cancel",
                    });
                    setIsCompleteAtReviewPopupOpen(false);
                }}
                onClose={() => setIsCompleteAtReviewPopupOpen(false)}
                testid="complete-at-review-project-popup"
            />
            <Popup
                title={t("popups.approvals.spendLimitWarning.title")}
                message={t("popups.approvals.spendLimitWarning.message", {
                    spendLimit: currencyService.toDisplayFormat(projectInformation.budgetCurrency, projectSpendLimit),
                })}
                isOpen={isSpendLimitWarningPopupOpen}
                primaryActionText={t("popups.approvals.spendLimitWarning.cta.primary")}
                onPrimaryAction={triggerApprovals}
                secondaryActionText={t("popups.approvals.spendLimitWarning.cta.secondary")}
                onSecondaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: "Results Container - Approvals Spend Limit Warning - Cancel",
                    });
                    setIsSpendLimitWarningPopupOpen(false);
                }}
                onClose={() => setIsSpendLimitWarningPopupOpen(false)}
                disabled={isAwardingSupplier}
                variant="warning"
                testid="spend-limit-warning-popup"
            />
        </>
    );
};

export default ResultsContainer;
