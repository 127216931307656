import { Popup } from "@maistro/components";
import usePrompt from "components/Prompt/hooks/usePrompt";
import { t } from "i18next";
import React from "react";

interface IPromptProps {
    when: boolean;
}

const Prompt: React.FC<IPromptProps> = ({ when }) => {
    const { showPrompt, confirmNavigation, cancelNavigation } = usePrompt(when);

    return (
        <Popup
            testid="prompt"
            title={t("common.prompt.title")}
            message={t("common.prompt.message")}
            primaryActionText={t("common.prompt.primary")}
            secondaryActionText={t("common.prompt.secondary")}
            isOpen={showPrompt}
            onPrimaryAction={confirmNavigation}
            onSecondaryAction={cancelNavigation}
            onClose={cancelNavigation}
        />
    );
};

export default Prompt;
