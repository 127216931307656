import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
    createProjectFilesArchive,
    getProjectFilesArchive,
    isProjectFilesArchiveAvailable,
    listProjectFiles,
    projectHasAnyFiles,
} from "api/fileApi";
import validationService from "services/validationService";
import FileDto from "types/dtos/files/FileDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import EntityType from "types/enums/questions/EntityType";
import { useToaster } from "@maistro/components";

const useProjectFiles = (projectUuid?: string, entityType = EntityType.Project, entityUuid?: string) => {
    const [projectFiles, setProjectFiles] = useState<FileDto[]>([]);
    const [isArchiving, setIsArchiving] = useState(false);
    const [isArchiveAvailable, setIsArchiveAvailable] = useState(false);
    const [projectHasFilesOfAnyKind, setProjectHasFilesOfAnyKind] = useState(false);
    const [isFetchingFiles, setIsFetchingFiles] = useState(true);

    const { t } = useTranslation();
    const toast = useToaster();

    const getArchiveStatus = useCallback(async () => {
        if (!(projectUuid && validationService.isValidUuid(projectUuid))) return false;

        const archiveResponse = await isProjectFilesArchiveAvailable(projectUuid);
        if (archiveResponse.data instanceof TransactionErrorDto) {
            toast.error(t("fileUpload.archive.fetchArchiveStatusError"));
            return false;
        }

        return archiveResponse.data.isAvailable;
    }, [projectUuid, t, toast]);

    const checkForFilesOfAnyKind = useCallback(async () => {
        if (!(projectUuid && validationService.isValidUuid(projectUuid))) return false;

        const response = await projectHasAnyFiles(projectUuid);
        if (response.data instanceof TransactionErrorDto) {
            toast.error(t("fileUpload.archive.fetchArchiveStatusError"));
            return false;
        }

        return response.data.hasFiles;
    }, [projectUuid, t, toast]);

    const fetchProjectFiles = useCallback(async () => {
        setIsFetchingFiles(true);
        if (!(projectUuid && validationService.isValidUuid(projectUuid))) return;

        if (entityType !== EntityType.Project && !entityUuid) {
            setProjectFiles([]);
            return;
        }

        const filesResponse = await listProjectFiles(projectUuid, entityType, entityUuid);
        if (filesResponse.data instanceof TransactionErrorDto) {
            toast.error(t("fileUpload.fetchFilesError"));
            return;
        }

        const files: FileDto[] = filesResponse.data.projectMedia.map((dto) => ({
            fileName: dto.fileName,
            fileId: dto.fileUuid,
            fileExtension: dto.fileExtension,
            uploadedDateTime: dto.uploadedOn,
            uploadedUser: {
                id: dto.uploadedByUserId,
                value: dto.uploadedBy,
            },
            newUpload: false,
            linkedCompanyUuid: dto.linkedCompanyUuid,
            linkedCompanyName: dto.linkedCompanyName,
        }));
        setProjectFiles(files);

        const archiveStatus = await getArchiveStatus();
        setIsArchiveAvailable(archiveStatus);

        const filesAvailableAtAll = await checkForFilesOfAnyKind();
        setProjectHasFilesOfAnyKind(filesAvailableAtAll);
        setIsFetchingFiles(false);
    }, [entityType, entityUuid, getArchiveStatus, projectUuid, t, toast, checkForFilesOfAnyKind]);

    useEffect(() => {
        fetchProjectFiles();
    }, [fetchProjectFiles]);

    const pollForArchiveStatus = useCallback(async () => {
        if (!isArchiving) return;

        const archiveStatus = await getArchiveStatus();
        if (archiveStatus) {
            setIsArchiveAvailable(archiveStatus);
            setIsArchiving(false);
            toast.success(t("fileUpload.archive.readyForDownload"), { id: "preparingDocuments", duration: 4000 });
        }
    }, [getArchiveStatus, isArchiving, t, toast]);

    useEffect(() => {
        const interval = setInterval(pollForArchiveStatus, 3000);

        return () => clearInterval(interval);
    }, [pollForArchiveStatus]);

    useEffect(() => {
        return () => toast.dismiss("preparingDocuments");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createArchive = async () => {
        if (!(projectUuid && validationService.isValidUuid(projectUuid))) return;

        setIsArchiving(true);

        const response = await createProjectFilesArchive(projectUuid);
        if (response.status !== 204) {
            toast.error(t("fileUpload.archive.createArchiveError"));
            setIsArchiving(false);
            return;
        }

        toast.loading(t("fileUpload.archive.preparingDocuments"), { id: "preparingDocuments", duration: 180000 });
    };

    const downloadArchive = async () => {
        if (!(projectUuid && validationService.isValidUuid(projectUuid))) return;

        if (!isArchiveAvailable) {
            toast.error(t("fileUpload.archive.notAvailableToDownload"));
            return;
        }

        const response = await getProjectFilesArchive(projectUuid);

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("fileUpload.archive.archiveDownloadError"));
            return;
        }

        const objectUrl = window.URL.createObjectURL(response.data);
        const anchor = document.createElement("a");
        anchor.href = objectUrl;
        anchor.download = t("fileUpload.archive.archiveFileName");
        anchor.click();
        window.URL.revokeObjectURL(objectUrl);
    };

    return {
        fetchProjectFiles,
        projectFiles,
        setProjectFiles,
        isArchiving,
        isArchiveAvailable,
        createArchive,
        downloadArchive,
        projectHasFilesOfAnyKind,
        isFetchingFiles,
    };
};

export default useProjectFiles;
