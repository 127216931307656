import { faFilter } from "@fortawesome/pro-regular-svg-icons";
import { Drawer, TextButton } from "@maistro/components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { SearchBar } from "components";
import DashboardFilterForm from "features/dashboard/filter/DashboardFilterForm";
import {
    IDashboardFilters,
    IDashboardOptions,
    IOptionalFilterConfiguration,
} from "features/dashboard/filter/hooks/useDashboardFilters";
import useDebouncedEffect from "hooks/useDebouncedEffect";
import ReactGA from "react-ga4";
import { ITheme } from "styles/themes/types";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    searchBar: {
        display: "flex",
    },
    searchBarContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        gap: theme.spacing.medium,
        marginBottom: theme.spacing.medium,
    },
}));

interface IDashboardFiltersProps {
    currentFilters: IDashboardFilters;
    setCurrentFilters: (filters: IDashboardFilters) => void;
    options: IDashboardOptions;
    getAvailableProjectManagers: (searchText?: string) => void;
    getAvailableCompanies: (searchText: string) => void;
    isSearching: boolean;
    resetPage: () => void;
    resetFilters: () => void;
    optionalFilters?: IOptionalFilterConfiguration;
    isFilterDrawerOpen: boolean;
    setIsFilterDrawerOpen: (value: boolean) => void;
}

const DashboardFilters: React.FC<IDashboardFiltersProps> = ({
    currentFilters,
    setCurrentFilters,
    options,
    getAvailableProjectManagers,
    getAvailableCompanies,
    isSearching,
    resetPage,
    resetFilters,
    optionalFilters,
    isFilterDrawerOpen,
    setIsFilterDrawerOpen,
}) => {
    const classes = useStyles();

    const [currentSearchText, setCurrentSearchText] = useState("");

    const { t } = useTranslation();

    const onSubmitFilters = (filters: IDashboardFilters) => {
        resetPage();
        setCurrentFilters(filters);
        setIsFilterDrawerOpen(false);
    };

    useDebouncedEffect(
        () => {
            if (currentFilters.searchTerm !== currentSearchText) {
                resetPage();
                setCurrentFilters({ ...currentFilters, searchTerm: currentSearchText });
            }
        },
        [currentSearchText],
        500,
    );

    useEffect(() => {
        setCurrentSearchText(currentFilters.searchTerm ?? "");
    }, [currentFilters.searchTerm]);

    const onResetFilters = () => {
        resetPage();
        resetFilters();
        setIsFilterDrawerOpen(false);
    };

    return (
        <React.Fragment>
            <Drawer
                isOpen={isFilterDrawerOpen}
                onClose={() => setIsFilterDrawerOpen(false)}
                testid="dashboard-filter-drawer"
            >
                <DashboardFilterForm
                    optionalFilters={optionalFilters}
                    currentFilters={currentFilters}
                    submitFilters={onSubmitFilters}
                    resetFilters={onResetFilters}
                    options={options}
                    getAvailableProjectManagers={getAvailableProjectManagers}
                    getAvailableCompanies={getAvailableCompanies}
                />
            </Drawer>

            <div className={classes.searchBarContainer}>
                <SearchBar
                    isLoading={isSearching}
                    className={classes.searchBar}
                    placeholder={t("dashboard.projects.searchProjects")}
                    value={currentSearchText}
                    onChange={(e) => {
                        if (currentFilters.searchTerm) {
                            ReactGA.event({
                                category: ga4Category.Search,
                                action: ga4Action.Search,
                                label: "Projects: ".concat(currentFilters.searchTerm),
                            });
                        }
                        setCurrentSearchText(e.target.value);
                    }}
                />
                <TextButton
                    label={t("common.filter")}
                    icon={faFilter}
                    onClick={() => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: "Filter",
                        });
                        setIsFilterDrawerOpen(true);
                    }}
                    disabled={isSearching}
                    testid="open-filter-drawer-button"
                />
            </div>
        </React.Fragment>
    );
};

export default DashboardFilters;
