import { faIdBadge, faArrowRightFromBracket, faLockKeyhole } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import IProfileBarItem from "components/Layout/types/ProfileBarItem";
import useAppDispatch from "hooks/useAppDispatch";
import useReduxSelector from "hooks/useReduxSelector";
import { setProfileBarItems } from "store/layoutSlice";
import routes from "routes/routePaths/RoutePaths";

const useProfileBar = (
    setIsProfileOpen: (isOpen: boolean) => void,
    setIsChangePasswordOpen: (isOpen: boolean) => void,
) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { profileBarItems: itemsInState } = useReduxSelector((state) => state.layout);

    const visibleItems = useMemo(() => {
        return itemsInState ?? ([] as IProfileBarItem[]);
    }, [itemsInState]);

    const items: IProfileBarItem[] = useMemo(
        () => [
            {
                label: t("common.personalProfile"),
                icon: faIdBadge,
                onClick: () => setIsProfileOpen(true),
                route: "#",
            },
            {
                label: t("common.changePassword"),
                icon: faLockKeyhole,
                onClick: () => setIsChangePasswordOpen(true),
                route: "#",
            },
            {
                label: t("common.logout"),
                icon: faArrowRightFromBracket,
                route: routes.users.logout.path,
            },
        ],
        [setIsChangePasswordOpen, setIsProfileOpen, t],
    );

    useEffect(() => {
        const filteredItems = items.filter((item) => !item.isVisible || item.isVisible());

        dispatch(setProfileBarItems(filteredItems));
    }, [dispatch, items]);

    return { visibleItems };
};

export default useProfileBar;
