import { faChevronDown, faChevronUp, faSquareExclamation } from "@fortawesome/pro-regular-svg-icons";
import { faSquareExclamation as faSquareExclamationSolid } from "@fortawesome/pro-solid-svg-icons";
import { Heading, Icon, Tooltip } from "@maistro/components";
import classNames from "classnames";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import MaistroFormikPassIcon from "components/FormikFields/MaistroFormikPassIcon";
import { ICommonProps } from "components/shared";
import colorService from "services/colorService";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        padding: `${theme.spacing.medium}px ${theme.spacing.large}px`,
        background: theme.colors.white,
        boxShadow: `${colorService.toHexWithOpacity(theme.colors.primary, 0.1)} 0 3px 6px`,
        borderRadius: theme.spacing.xSmall,
        width: "100%",
    },
    content: {
        display: "grid",
        gridTemplateAreas: '"sectionTitle weighting completed" "totalQuestions icons icons"',
        gridTemplateColumns: "minmax(0, 4fr) minmax(0, 1.5fr) minmax(0, 0.1fr)",
        gap: `${theme.spacing.xLarge}px ${theme.spacing.xSmall}px`,
        alignItems: "center",
        flexGrow: 1,
    },
    button: {
        ...theme.typography.headings.h4,
        background: "inherit",
        border: "none",
        width: "100%",
        cursor: "pointer",
        justifyContent: "end",
        verticalAlign: "bottom",
    },
    title: {
        minWidth: 190,
        gridArea: "sectionTitle",
        wordBreak: "break-word",
    },
    weighting: {
        marginLeft: theme.spacing.small,
        gridArea: "weighting",
        textAlign: "right",
    },
    totalQuestions: {
        fontSize: 14,
        whiteSpace: "nowrap",
        gridArea: "totalQuestions",
    },
    icons: {
        gridArea: "icons",
        display: "flex",
        justifyContent: "space-between",
        minWidth: 90,
    },
    chevron: {
        width: 30,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    mobileCompletedIcon: {
        display: "block",
        marginLeft: theme.spacing.medium,
    },
    completedIconContainer: {
        gridArea: "completed",
        display: "none",
    },
    completedIcon: {
        marginLeft: 0,
        fontSize: 25,
    },
    mandatoryIcon: {
        color: theme.colors.primary,
    },
    sectionContainer: {
        display: "flex",
    },
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        weighting: {
            textAlign: "left",
        },
        totalQuestions: {
            fontSize: theme.typography.headings.h3.fontSize,
        },
        content: {
            gridTemplateAreas: '"completed sectionTitle sectionTitle weighting totalQuestions icons"',
            gridTemplateColumns: ".20fr repeat(4, 1fr) 0.5fr",
            gap: theme.spacing.xSmall,
        },
        chevron: {
            width: 173,
        },
        sectionContainer: {
            alignItems: "flex-start",
        },
        button: {
            textAlign: "right",
        },
        completedIconContainer: {
            display: "block",
        },
        mobileCompletedIcon: {
            display: "none",
        },
    },
    [`@media (min-width: ${theme.breakpoints.xl}px)`]: {
        content: {
            gridTemplateAreas:
                '"completed sectionTitle sectionTitle weighting centerSpace centerSpace totalQuestions icons"',
            gridTemplateColumns: ".15fr repeat(6, 1fr) 0.5fr",
        },
    },
}));

interface ISectionRowProps extends ICommonProps {
    title: string;
    weighting: number;
    totalQuestions: number;
    isLoading?: boolean;
    hasMandatoryQuestions: boolean;
    children?: ReactElement | Array<ReactElement>;
    startExpanded?: boolean;
    isComplete?: boolean;
}

const SectionRow: React.FC<ISectionRowProps> = (props) => {
    const classes = useStyles();

    const [isExpanded, setIsExpanded] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (!props.isLoading && props.totalQuestions === 0) {
            setIsExpanded(false);
        }
    }, [props.totalQuestions, props.isLoading]);

    useEffect(() => {
        if (props.startExpanded === true && !props.isLoading && props.totalQuestions > 0) {
            setIsExpanded(true);
        }
    }, [props.startExpanded, props.totalQuestions, props.isLoading]);

    return (
        <div className={props.className} data-testid={props.testid} data-component="SectionRow">
            <div
                className={classNames(classes.container, { expanded: isExpanded })}
                data-testid={`${props.testid}-expansion`}
            >
                <div className={classes.sectionContainer}>
                    <div className={classes.content}>
                        <div className={classes.completedIconContainer}>
                            <MaistroFormikPassIcon
                                className={classes.completedIcon}
                                isVisible={!!props.isComplete}
                                testid={`${props.testid}-complete`}
                            />
                        </div>
                        <Heading className={classes.title} variant="h3">
                            {props.title}
                        </Heading>

                        <div>
                            <Tooltip content={t("supplierTenderQuestions.scoring")} position="right" hideIcon start>
                                <Heading className={classes.weighting} variant="h3">{`${props.weighting}%`}</Heading>
                            </Tooltip>
                        </div>
                        <Heading className={classes.totalQuestions} variant="h3">
                            {props.totalQuestions === 1
                                ? t("tenderQuestions.questionCount", {
                                      totalQuestions: props.totalQuestions,
                                  })
                                : t("tenderQuestions.questionsCount", {
                                      totalQuestions: props.totalQuestions,
                                  })}
                        </Heading>
                        <div className={classes.icons}>
                            {props.hasMandatoryQuestions && (
                                <div>
                                    <Tooltip
                                        content={t("supplierTenderQuestions.conditionalQuestionSection")}
                                        position="right"
                                        start
                                        iconClassName={classes.mandatoryIcon}
                                        icon={faSquareExclamationSolid}
                                        hoverIcon={faSquareExclamation}
                                    >
                                        <div></div>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={classes.chevron}>
                        <div className={classes.mobileCompletedIcon}>
                            <MaistroFormikPassIcon
                                className={classes.completedIcon}
                                isVisible={!!props.isComplete}
                                testid={`${props.testid}-complete`}
                            />
                        </div>
                        {props.totalQuestions > 0 && (
                            <button
                                className={classes.button}
                                onClick={() => setIsExpanded(!isExpanded)}
                                type="button"
                                aria-label="expand-button"
                                data-testid={`${props.testid}-expand-button`}
                            >
                                <Icon icon={isExpanded ? faChevronUp : faChevronDown} />
                            </button>
                        )}
                    </div>
                </div>
            </div>
            {isExpanded && <div>{props.children}</div>}
        </div>
    );
};

export default SectionRow;
