import React, { useEffect, useState } from "react";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { createUseStyles } from "react-jss";

import { getSupplierVettingEmbeddedReport } from "api/reportingApi";
import { resetLayout, setBack, setPageTitle } from "store/layoutSlice";
import EmbeddedReportResponseDto from "types/dtos/reports/EmbeddedReportResponseDto";
import useAppDispatch from "hooks/useAppDispatch";

const useStyles = createUseStyles({
    reportContainer: {
        width: "100%",
        height: "80vh",
    },
});

interface IVettingReport {
    companyUuid: string;
}

const VettingReport: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const params = useParams();
    const { companyUuid } = params as unknown as IVettingReport;
    const { state } = useLocation();

    const [report, setReport] = useState<EmbeddedReportResponseDto>();

    useEffect(() => {
        dispatch(resetLayout());
        dispatch(setPageTitle(t("reports.title")));
        dispatch(
            setBack(
                state
                    ? {
                          route: state.route,
                      }
                    : null,
            ),
        );
    }, [t, dispatch, state]);

    useEffect(() => {
        getSupplierVettingEmbeddedReport(companyUuid).then((embeddedReport) => {
            setReport(embeddedReport.data);
        });
    }, [companyUuid]);

    if (!report) return null;

    const keyString = report.reportName.replace(/\s+/g, "");

    return (
        <PowerBIEmbed
            key={keyString}
            embedConfig={{
                type: "report",
                id: report.reportId,
                embedUrl: report.embedUrl,
                accessToken: report.token,
                tokenType: models.TokenType.Embed,
                settings: {
                    panes: {
                        filters: {
                            expanded: false,
                            visible: false,
                        },
                    },
                    layoutType: models.LayoutType.Master,
                },
                filters: [
                    {
                        $schema: "https://powerbi.com/product/schema#basic",
                        target: {
                            column: "CompanyUuid",
                            table: "Company",
                        },
                        filterType: models.FilterType.Basic,
                        operator: "In",
                        requireSingleSelection: true,
                        values: [companyUuid],
                    },
                ],
            }}
            cssClassName={classes.reportContainer}
        />
    );
};

export default VettingReport;
