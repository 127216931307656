import { Grid, Heading, SelectField, TextField } from "@maistro/components";
import { useFormikContext } from "formik";
import { TFunction } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import { IOptionProps } from "components/shared";
import ICompanyProfileForm from "features/company/profile/edit/types/ICompanyProfileForm";
import { ITheme } from "styles/themes/types";
import { RegexEmail, RegexLinkedInProfileUrl, RegexWebUrl } from "types/consts/regexConstants";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
        marginBottom: theme.spacing.units(7),
    },
    heading: {
        fontWeight: 600,
    },
}));

export const additionalInformationSchema = (t: TFunction<"translation", undefined>) =>
    Yup.object({
        tradingName: Yup.string().optional(),
        industryTypeId: Yup.string().required(),
        vatRegistrationNumber: Yup.string().optional(),
        contactEmail: Yup.string()
            .optional()
            .matches(new RegExp(RegexEmail), t("companyProfile.additionalInformation.contactEmail.invalid")),
        websiteUrl: Yup.string()
            .optional()
            .matches(new RegExp(RegexWebUrl), t("companyProfile.additionalInformation.websiteUrl.invalid")),
        linkedInUrl: Yup.string()
            .optional()
            .matches(
                new RegExp(RegexLinkedInProfileUrl),
                t("companyProfile.additionalInformation.linkedInUrl.invalid"),
            ),
    });

interface IAdditionalInformationFormProps {
    industries: IOptionProps[];
}

const AdditionalInformationForm: React.FC<IAdditionalInformationFormProps> = ({ industries }) => {
    const classes = useStyles();

    const [tradingNameTouched, setTradingNameTouched] = useState(false);

    const { t } = useTranslation();

    const { initialValues, values, setFieldValue } = useFormikContext<ICompanyProfileForm>();

    useEffect(() => {
        if (values.registeredName.length > 1 && initialValues.tradingName.length === 0 && !tradingNameTouched) {
            setFieldValue("tradingName", values.registeredName);
        }
    }, [
        initialValues.tradingName.length,
        setFieldValue,
        tradingNameTouched,
        values.registeredName,
        values.tradingName,
    ]);

    return (
        <div className={classes.container}>
            <Heading className={classes.heading} variant="h4">
                {t("companyProfile.additionalInformation.title")}
            </Heading>
            <Grid columns={2}>
                <TextField
                    name="tradingName"
                    label={t("companyProfile.additionalInformation.tradingName.label")}
                    tooltip={t("companyProfile.additionalInformation.tradingName.tooltip")}
                    onFocus={() => setTradingNameTouched(true)}
                    testid="company-information-trading-name"
                />
                <SelectField
                    name="industryTypeId"
                    label={t("companyProfile.additionalInformation.industryType.label")}
                    tooltip={t("companyProfile.additionalInformation.industryType.tooltip")}
                    options={industries}
                    testid="company-information-industry-type"
                />
                <TextField
                    name="vatRegistrationNumber"
                    label={t("companyProfile.additionalInformation.vatRegistrationNumber.label")}
                    tooltip={t("companyProfile.additionalInformation.vatRegistrationNumber.tooltip")}
                    testid="company-information-vat-registration-number"
                />
                <TextField
                    name="contactEmail"
                    label={t("companyProfile.additionalInformation.contactEmail.label")}
                    tooltip={t("companyProfile.additionalInformation.contactEmail.tooltip")}
                    testid="company-information-contact-email"
                />
                <TextField
                    name="websiteUrl"
                    label={t("companyProfile.additionalInformation.websiteUrl.label")}
                    tooltip={t("companyProfile.additionalInformation.websiteUrl.tooltip")}
                    testid="company-information-website-url"
                />
                <TextField
                    name="linkedInUrl"
                    label={t("companyProfile.additionalInformation.linkedInUrl.label")}
                    tooltip={t("companyProfile.additionalInformation.linkedInUrl.tooltip")}
                    testid="company-information-linkedin-url"
                />
            </Grid>
        </div>
    );
};

export default AdditionalInformationForm;
