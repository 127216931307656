import { Copy, Heading, RadioButtonsField } from "@maistro/components";
import { Form, Formik } from "formik";
import React from "react";
import { createUseStyles } from "react-jss";
import { ObjectSchema, AnyObject } from "yup";

import { ICommonProps, IOptionProps } from "components/shared";
import { ITheme } from "styles/themes/types";
import { BuyerSupplierRelationshipDto } from "types/dtos/company/buyer-supplier-relationships/BuyerSupplierRelationshipDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        color: theme.typography.base.color,
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
}));

interface IToolTip {
    text: string;
    fieldName: string;
}

interface ISupplierStatusFormProps extends ICommonProps {
    values: BuyerSupplierRelationshipDto;
    submit: (values: BuyerSupplierRelationshipDto) => void;
    tooltips: IToolTip[];
    options: IOptionProps[];
    heading: string;
    label: string;
    name: string;
    copy: string;
    validationSchema: ObjectSchema<AnyObject>;
}

const SupplierStatusForm: React.FC<ISupplierStatusFormProps> = (props) => {
    const classes = useStyles();

    return (
        <Formik
            initialValues={{
                ...props.values,
            }}
            onSubmit={(values) => props.submit(values)}
            validationSchema={props.validationSchema}
        >
            {({ handleSubmit }) => (
                <Form className={classes.container} onChange={handleSubmit}>
                    <Heading variant="h2">{props.heading}</Heading>
                    <Copy>{props.copy}</Copy>
                    <RadioButtonsField
                        label={props.label}
                        name={props.name}
                        options={props.options}
                        tooltip={props.tooltips}
                        required
                        testid={props.testid}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default SupplierStatusForm;
