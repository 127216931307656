import { Button, CheckboxesField, Heading, Pill, RadioButtonsField, TextButton } from "@maistro/components";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { MaistroRating } from "components";
import MaistroFormikLabel from "components/FormikFields/MaistroFormikLabel";
import { ICommonProps } from "components/shared";
import ReactGA from "react-ga4";
import { IFilterValues } from "store/supplierMatchingSlice";
import { ITheme } from "styles/themes/types";
import { PotentialSupplierDto } from "types/dtos/projectSuppliers/PotentialSupplierDto";
import { CompanySizeV2, getCompanySizeRange } from "types/enums/companies/CompanySizeV2";
import SupplierRelationshipStatus from "types/enums/companies/SupplierRelationshipStatus";
import SupplierTieringStatus from "types/enums/companies/SupplierTieringStatus";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        color: theme.typography.base.color,
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },

    appliedFilters: {
        display: "flex",
        flexDirection: "column",
    },

    appliedFiltersContainer: {
        marginTop: theme.spacing.small,
        display: "inline-flex",
        gap: theme.spacing.xSmall,
        flexWrap: "wrap",
    },

    buttons: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.medium,
    },
}));

interface IFilterListProps extends ICommonProps {
    values: IFilterValues;
    submit: (values: IFilterValues) => void;
}

export const excludeByMatchRating = (supplier: PotentialSupplierDto, matchRating?: string) => {
    if (Math.ceil(supplier.matchScore * 5) < parseFloat(matchRating ?? "0")) {
        return true;
    }
    return false;
};

export const excludeByRelationshipStatus = (
    supplier: PotentialSupplierDto,
    relationshipStatuses?: Array<SupplierRelationshipStatus>,
) => {
    if (!relationshipStatuses) {
        return false;
    }
    if (!relationshipStatuses.find((status) => supplier.supplierRelationshipStatus === status)) {
        return true;
    }
    return false;
};

export const excludeByTieringStatus = (
    supplier: PotentialSupplierDto,
    tieringStatuses?: Array<SupplierTieringStatus>,
) => {
    if (!tieringStatuses) {
        return false;
    }
    if (!tieringStatuses.find((status) => supplier.supplierTieringStatus === status)) {
        return true;
    }
    return false;
};

export const excludeByCompanySize = (supplier: PotentialSupplierDto, companySizes?: Array<CompanySizeV2>) => {
    if (!companySizes) {
        return false;
    }
    if (!companySizes.find((size) => supplier.companySize === size)) {
        return true;
    }
    return false;
};

const FilterList: React.FC<IFilterListProps> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const displayAppliedFilters = (
        appliedFilters: IFilterValues,
        setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void,
    ) => {
        if (
            !appliedFilters.relationshipStatuses &&
            !appliedFilters.companySizes &&
            !appliedFilters.matchRating &&
            !appliedFilters.tieringStatuses
        ) {
            return null;
        }
        let noOfAppliedFilters = appliedFilters.matchRating ? 1 : 0;
        noOfAppliedFilters += appliedFilters.relationshipStatuses?.length ?? 0;
        noOfAppliedFilters += appliedFilters.companySizes?.length ?? 0;
        noOfAppliedFilters += appliedFilters.tieringStatuses?.length ?? 0;

        return (
            <div className={classes.appliedFilters}>
                <legend>
                    <MaistroFormikLabel
                        label={t("projectMatchingScreen.filter.appliedFilters", {
                            options: {
                                noOfAppliedFilters,
                            },
                        })}
                        name="appliedFilters"
                        testid="applied-filters-label"
                    />
                </legend>
                <div className={classes.appliedFiltersContainer}>
                    {appliedFilters.matchRating && (
                        <Pill
                            key={appliedFilters.matchRating}
                            value={`${t("common.matchRating")} - ${appliedFilters.matchRating} ${t(
                                "projectMatchingScreen.filter.andUp",
                            )}`}
                            canRemove
                            onRemove={() => setFieldValue("matchRating", undefined)}
                            testid={`applied-filter-${appliedFilters.matchRating}`}
                        />
                    )}
                    {appliedFilters.relationshipStatuses?.map((relationshipStatus) => (
                        <Pill
                            key={relationshipStatus}
                            value={`${t("companySuppliers.table.relationshipStatus")} - ${relationshipStatus}`}
                            canRemove
                            onRemove={() =>
                                setFieldValue(
                                    "relationshipStatuses",
                                    appliedFilters.relationshipStatuses?.filter((as) => as !== relationshipStatus),
                                )
                            }
                            testid={`applied-filter-${relationshipStatus}`}
                        />
                    ))}
                    {appliedFilters.tieringStatuses?.map((tieringStatus) => (
                        <Pill
                            key={tieringStatus}
                            value={`${t("companySuppliers.table.tieringStatus")} - ${tieringStatus}`}
                            canRemove
                            onRemove={() =>
                                setFieldValue(
                                    "tieringStatuses",
                                    appliedFilters.tieringStatuses?.filter((ts) => ts !== tieringStatus),
                                )
                            }
                            testid={`applied-filter-${tieringStatus}`}
                        />
                    ))}
                    {appliedFilters.companySizes?.map((companySize) => (
                        <Pill
                            key={companySize}
                            value={`${t("companySuppliers.table.companySize")} - ${getCompanySizeRange(companySize)}`}
                            canRemove
                            onRemove={() =>
                                setFieldValue(
                                    "companySizes",
                                    appliedFilters.companySizes?.filter((cs) => cs !== companySize),
                                )
                            }
                            testid={`applied-filter-${companySize}`}
                        />
                    ))}
                </div>
            </div>
        );
    };

    return (
        <Formik
            initialValues={{
                matchRating: props.values.matchRating,
                relationshipStatuses: props.values.relationshipStatuses,
                companySizes: props.values.companySizes,
                tieringStatuses: props.values.tieringStatuses,
            }}
            onSubmit={(values) => props.submit(values)}
        >
            {(formik) => (
                <Form className={classes.container}>
                    <Heading variant="h2">{t("common.filter")}</Heading>
                    {displayAppliedFilters(formik.values, formik.setFieldValue)}
                    <RadioButtonsField
                        label={t("common.matchRating")}
                        name="matchRating"
                        options={[5, 4, 3, 2, 1].map((rating) => {
                            return {
                                label: (
                                    <React.Fragment>
                                        <MaistroRating
                                            currentRating={rating}
                                            showEmptyStars={false}
                                            testid={`filter-match-rating-${rating}`}
                                        />
                                        {rating < 5 && t("projectMatchingScreen.filter.andUp")}
                                    </React.Fragment>
                                ),
                                value: rating.toString(),
                            };
                        })}
                        tooltip={t("projectMatchingScreen.tooltips.matchRating")}
                        expandable
                        isField
                        isExpanded
                        testid="filter-match-rating"
                    />
                    <CheckboxesField
                        label={t("companySuppliers.table.relationshipStatus")}
                        tooltip={[
                            {
                                text: t("companySuppliers.relationshipStatus.approved.tooltip"),
                                fieldName: t("companySuppliers.relationshipStatus.approved.label"),
                            },
                            {
                                text: t("companySuppliers.relationshipStatus.trial.tooltip"),
                                fieldName: t("companySuppliers.relationshipStatus.trial.label"),
                            },
                        ]}
                        name="relationshipStatuses"
                        expandable
                        isField
                        options={[
                            {
                                label: t("companySuppliers.relationshipStatus.approved.label"),
                                value: SupplierRelationshipStatus.Approved,
                            },
                            {
                                label: t("companySuppliers.relationshipStatus.trial.label"),
                                value: SupplierRelationshipStatus.Trial,
                            },
                        ]}
                    />
                    <CheckboxesField
                        label={t("companySuppliers.table.tieringStatus")}
                        name="tieringStatuses"
                        expandable
                        isField
                        options={[
                            {
                                label: t("companySuppliers.tieringStatus.strategic.label"),
                                value: SupplierTieringStatus.Strategic.toString(),
                            },
                            {
                                label: t("companySuppliers.tieringStatus.preferred.label"),
                                value: SupplierTieringStatus.Preferred.toString(),
                            },
                            {
                                label: t("companySuppliers.tieringStatus.occasional.label"),
                                value: SupplierTieringStatus.Occasional.toString(),
                            },
                            {
                                label: t("companySuppliers.tieringStatus.undefined.label"),
                                value: SupplierTieringStatus.Undefined.toString(),
                            },
                        ]}
                        tooltip={[
                            {
                                text: t("companySuppliers.tieringStatus.strategic.tooltip"),
                                fieldName: t("companySuppliers.tieringStatus.strategic.label"),
                            },
                            {
                                text: t("companySuppliers.tieringStatus.preferred.tooltip"),
                                fieldName: t("companySuppliers.tieringStatus.preferred.label"),
                            },
                            {
                                text: t("companySuppliers.tieringStatus.occasional.tooltip"),
                                fieldName: t("companySuppliers.tieringStatus.occasional.label"),
                            },
                            {
                                text: t("companySuppliers.tieringStatus.undefined.tooltip"),
                                fieldName: t("companySuppliers.tieringStatus.undefined.label"),
                            },
                        ]}
                        testid="tiering-status-options"
                    />
                    <CheckboxesField
                        label={t("companySuppliers.table.companySize")}
                        tooltip={t("companySuppliers.tooltips.companySize")}
                        name="companySizes"
                        expandable
                        isField
                        options={[
                            {
                                label: `${getCompanySizeRange(CompanySizeV2.Micro)}`,
                                value: CompanySizeV2.Micro,
                            },
                            {
                                label: `${getCompanySizeRange(CompanySizeV2.Small)}`,
                                value: CompanySizeV2.Small,
                            },
                            {
                                label: `${getCompanySizeRange(CompanySizeV2.Medium)}`,
                                value: CompanySizeV2.Medium,
                            },
                            {
                                label: `${getCompanySizeRange(CompanySizeV2.Large)}`,
                                value: CompanySizeV2.Large,
                            },
                        ]}
                    />
                    <div className={classes.buttons}>
                        <Button
                            type="submit"
                            label={t("common.viewResults")}
                            testid="view-results-button"
                            onClick={() =>
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: `Match - Filter - Submit`,
                                })
                            }
                        />
                        <TextButton
                            label={t("common.resetFilters")}
                            onClick={() => {
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: `Match - Filter - Cancel`,
                                });
                                formik.resetForm({
                                    values: {
                                        matchRating: undefined,
                                        relationshipStatuses: undefined,
                                        companySizes: undefined,
                                        tieringStatuses: undefined,
                                    },
                                });
                                formik.handleSubmit();
                            }}
                            testid="reset-filter-button"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default FilterList;
