import classNames from "classnames";
import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";

import ISecondaryNavItem from "components/Layout/types/SecondaryNavItem";
import { ICommonProps } from "components/shared";
import stringService from "services/stringService";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    scrollContainer: {
        borderBottom: `1px solid ${theme.colors.highlight}`,
        marginBottom: theme.spacing.xSmall,
    },

    navItems: {
        display: "flex",
        minWidth: "100%",
        gap: theme.spacing.small,
        padding: `${theme.spacing.xSmall}px ${theme.spacing.xSmall}px`,
    },

    navItem: {
        ...theme.typography.content.copy,
        fontWeight: 500,
        padding: `${theme.spacing.xSmall}px ${theme.spacing.units(3.5)}px`,
        height: "fit-content",
        color: theme.colors.white,
        cursor: "pointer",
        whiteSpace: "nowrap",

        "&:hover": {
            color: theme.colors.white,

            "& a": {
                display: "block",
                position: "relative",
            },
        },
    },

    isActive: {
        borderRadius: 100,
        backgroundColor: theme.colors.highlight,
        color: theme.colors.white,
    },

    isDisabled: {
        color: theme.colors.primaryShadow,
        "&:hover": {
            color: theme.colors.primaryShadow,
        },
    },

    [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        scrollContainer: {
            borderBottom: `1px solid ${theme.colors.primary}`,
            marginBottom: 0,
        },

        navItem: {
            color: theme.colors.mediumGray,

            "&:hover": {
                color: theme.colors.primary,

                "& a::after": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    bottom: -theme.spacing.units(1),
                    background: theme.colors.primary,
                    height: 5,
                    width: "100%",
                    borderRadius: 100,
                },
            },
        },

        isActive: {
            color: theme.colors.white,

            "&:hover": {
                color: theme.colors.white,

                "& a::after": {
                    display: "none",
                },
            },
        },

        isDisabled: {
            color: theme.colors.disabled,
            cursor: "default",

            "&:hover": {
                color: theme.colors.disabled,
            },
        },
    },
}));

interface ISecondaryNavProps extends ICommonProps {
    navItems: ISecondaryNavItem[];
}

const SecondaryNav: React.FC<ISecondaryNavProps> = (props) => {
    const classes = useStyles();

    const { navItems, className, testid } = props;

    return (
        <nav className={classNames(className)} data-component="SecondaryNav" data-testid={testid}>
            <ScrollContainer vertical={false} className={classes.scrollContainer}>
                <ul className={classes.navItems}>
                    {navItems.map((item) => (
                        <li
                            className={classNames(classes.navItem, {
                                [classes.isActive]: item.isActive,
                                [classes.isDisabled]: item.isDisabled,
                            })}
                            aria-label={item.label}
                            key={item.label}
                            data-testid={`${testid}-${stringService.toKebabCase(item.label)}`}
                        >
                            {item.isDisabled ? <span>{item.label}</span> : <Link to={item.linkTo}>{item.label}</Link>}
                        </li>
                    ))}
                </ul>
            </ScrollContainer>
        </nav>
    );
};

export default SecondaryNav;
