import { Button, RichTextEditorField, TextButton } from "@maistro/components";
import { Form, Formik } from "formik";
import { TFunction } from "i18next";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import { ICommonProps } from "components/shared";
import DisplayFileUploadField from "features/files/DisplayFileUploadFields";
import SectionDropdown from "features/project/clarificationQuestions/askQuestion/SectionDropdown";
import useReduxSelector from "hooks/useReduxSelector";
import ReactGA from "react-ga4";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import ProjectType from "types/enums/projects/ProjectType";

const useStyles = createUseStyles((theme: ITheme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.large,
    },
    control: {
        display: "flex",
        flexDirection: "column",
    },
    buttonControl: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.large,
    },
}));

interface ISupplierAskQuestionFormProps extends ICommonProps {
    submit: (question: string, section: string) => void;
    cancel?: () => void;
    submittingQuestion: boolean;
    files?: FileDto[];
    setFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    removeFile: (file: FileDto | null) => void;
    fileIsUploading: boolean;
    setFileIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
    projectUuid: string;
    projectType: ProjectType | undefined;
}

const validationSchema = (t: TFunction<"translation", undefined>) =>
    Yup.object({
        question: Yup.string().required(t("clarificationQuestions.question.required")),
    });

const SupplierAskQuestionForm: React.FC<ISupplierAskQuestionFormProps> = (props) => {
    const { files, setFiles, removeFile, fileIsUploading, setFileIsUploading } = props;
    const classes = useStyles();

    const { submit, cancel, submittingQuestion, projectUuid, projectType } = props;

    const { t } = useTranslation();

    const [selectedSection, setSelectedSection] = useState("");

    const { sections } = useReduxSelector((state) => state.sectionState);
    const { theme } = useReduxSelector((state) => state.themeState);

    return (
        <Formik
            enableReinitialize
            initialValues={{
                question: "",
            }}
            validationSchema={validationSchema(t)}
            onSubmit={(values) => submit(values.question, selectedSection ?? projectUuid)}
        >
            {({ errors, isValid, isSubmitting }) => (
                <Form className={classes.form}>
                    {projectType === ProjectType.Tender && (
                        <SectionDropdown setSelectedSection={setSelectedSection} sections={sections.items} />
                    )}
                    <RichTextEditorField
                        testid="clarification-question-text"
                        label={t("clarificationQuestions.question.label")}
                        name="question"
                        required
                        joditLicense={theme.joditLicence}
                    />
                    <DisplayFileUploadField
                        testid="clarification-question"
                        files={files}
                        canManageFiles
                        removeFile={removeFile}
                        setFiles={setFiles}
                        fileIsUploading={fileIsUploading}
                        setFileIsUploading={setFileIsUploading}
                    />
                    <div className={classes.buttonControl}>
                        <Button
                            testid="clarification-question-submit-button"
                            label={t("clarificationQuestions.submitQuestion")}
                            type="submit"
                            size="large"
                            disabled={!isEmpty(errors) || !isValid || isSubmitting || fileIsUploading}
                            loading={submittingQuestion}
                            onClick={() =>
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "Clarification Questions - Ask Question - Submit",
                                })
                            }
                        />
                        <TextButton
                            label={t("clarificationQuestions.cancelQuestion")}
                            onClick={() => {
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "Clarification Questions - Ask Question - Submit",
                                });
                                if (cancel) cancel();
                            }}
                            testid="clarification-question-cancel-button"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default SupplierAskQuestionForm;
