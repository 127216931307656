import { EUR_IDENTIFIER, GBP_IDENTIFIER, USD_IDENTIFIER } from "types/consts/currencySymbolConstants";
import CurrencyType from "types/enums/CurrencyType";

interface ICurrencyService {
    toCurrencyIcon: (currency?: CurrencyType) => string;
    toDisplayFormat: (currency?: CurrencyType, price?: number) => string;
}

const currencyService: ICurrencyService = {
    toCurrencyIcon: (currency) => {
        switch (currency) {
            case CurrencyType.EUR:
                return EUR_IDENTIFIER;
            case CurrencyType.GBP:
                return GBP_IDENTIFIER;
            case CurrencyType.USD:
                return USD_IDENTIFIER;
            default:
                return GBP_IDENTIFIER;
        }
    },
    toDisplayFormat: (currency, price) => {
        if (price == null) return "-";

        const currencyIcon = currencyService.toCurrencyIcon(currency);
        return `${currencyIcon}${price.toLocaleString()}`;
    },
};

export default currencyService;
