import { useToaster } from "@maistro/components";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDeleteBuyerSupplierContractApiMutation } from "store/api/buyerSupplierRelationshipsApi";

const useDeleteContract = (contractUuid: string | undefined, buyerUuid: string, supplierUuid: string) => {
    const [isDeleteContractPopupOpen, setIsDeleteContractPopupOpen] = useState<boolean>(false);

    const [deleteContract, { isError, isSuccess }] = useDeleteBuyerSupplierContractApiMutation();
    const toast = useToaster();
    const { t } = useTranslation();

    const handleDeleteSelectedContract = useCallback(async () => {
        if (contractUuid) {
            await deleteContract({
                uuid: contractUuid,
                buyerUuid,
                supplierUuid,
            });
            setIsDeleteContractPopupOpen(false);
        }
    }, [buyerUuid, deleteContract, contractUuid, supplierUuid]);

    useEffect(() => {
        if (isError) {
            toast.error(t("companySuppliers.contracts.api.deleteError"));
        }
        if (isSuccess) {
            toast.success(t("companySuppliers.contracts.api.deleteSuccess"));
        }
    }, [isError, isSuccess, toast, t]);

    return {
        isDeleteContractPopupOpen,
        setIsDeleteContractPopupOpen,
        handleDeleteSelectedContract,
    };
};

export default useDeleteContract;
