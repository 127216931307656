import React, { useEffect } from "react";

import useAppDispatch from "hooks/useAppDispatch";
import { setIsCurrentPagePublic } from "store/navigationSlice";
import { Outlet } from "react-router-dom";

const PublicRoute: React.FC = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setIsCurrentPagePublic(true));
        return () => {
            dispatch(setIsCurrentPagePublic(false));
        };
    }, [dispatch]);

    return <Outlet />;
};

export default PublicRoute;
