import { ISortBy, SortOrder } from "@maistro/components";
import { AxiosResponse } from "axios";
import { isValid } from "date-fns";

import { createAxiosInstance } from "api/commonApi";
import env from "config/EnvironmentVariables";
import { IDashboardFilters } from "features/dashboard/filter/hooks/useDashboardFilters";
import SupplierSubmissionSearchRequestDto from "types/dtos/supplierSubmissions/SupplierSubmissionSearchRequestDto";
import SupplierSubmissionsSearchResponseDto from "types/dtos/supplierSubmissions/SupplierSubmissionsSearchResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/supplier-submissions";

const buildUrlForSupplierSubmissionSearch = (request: SupplierSubmissionSearchRequestDto) => {
    let url = `${baseApiUrl}/search?skip=${request.skip}&take=${request.take}`;

    if (request.sortColumn) {
        url += `&orderByColumn=${encodeURI(request.sortColumn)}&sortDescending=
        ${request.direction === SortOrder.Descending}`;
    }

    if (request.searchTerm && request.searchTerm.length > 0) {
        url += `&searchQuery=${encodeURI(request.searchTerm)}`;
    }

    if (request.responseStatus) {
        request.responseStatus.forEach((status) => {
            url += `&SelectedProjectSupplierStatuses=${encodeURI(status)}`;
        });
    }
    if (request.selectedProjectTypes) {
        request.selectedProjectTypes.forEach((v) => {
            url += `&selectedProjectTypes=${encodeURI(v)}`;
        });
    }
    if (request.tenderResponseDeadlineStart && isValid(request.tenderResponseDeadlineStart)) {
        url += `&tenderResponseDeadlineStart=${encodeURI(request.tenderResponseDeadlineStart.toISOString())}`;
    }

    if (request.tenderResponseDeadlineEnd && isValid(request.tenderResponseDeadlineEnd)) {
        url += `&tenderResponseDeadlineEnd=${encodeURI(request.tenderResponseDeadlineEnd.toISOString())}`;
    }

    return url;
};

// eslint-disable-next-line import/prefer-default-export
export const searchSupplierSubmissions = (
    currentPage: number,
    itemsPerPage: number,
    sortParameters: ISortBy,
    filterParameters: IDashboardFilters,
): Promise<AxiosResponse<SupplierSubmissionsSearchResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const request: SupplierSubmissionSearchRequestDto = {
        skip: (currentPage - 1) * itemsPerPage,
        take: itemsPerPage,
        sortColumn: sortParameters.column,
        direction: sortParameters.order || SortOrder.Descending,
        responseStatus: filterParameters.statuses.map((s) => s.value),
        tenderResponseDeadlineStart: filterParameters.tenderResponseDeadlineStart
            ? new Date(filterParameters.tenderResponseDeadlineStart)
            : undefined,
        tenderResponseDeadlineEnd: filterParameters.tenderResponseDeadlineEnd
            ? new Date(filterParameters.tenderResponseDeadlineEnd)
            : undefined,
        searchTerm: filterParameters.searchTerm,
        selectedProjectTypes: filterParameters.projectTypes.map((pt) => pt.value),
    };
    const url = buildUrlForSupplierSubmissionSearch(request);
    return api.get(url);
};
