import { faSquareExclamation } from "@fortawesome/pro-solid-svg-icons";
import { Icon, TextButton } from "@maistro/components";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import colorService from "services/colorService";
import { ITheme } from "styles/themes/types";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    dropdown: {
        display: "block",
        alignItems: "center",
        gap: theme.spacing.small,
        background: theme.colors.white,
        boxShadow: `${colorService.toHexWithOpacity(theme.colors.primary, 0.1)} 0 3px 6px`,
        borderRadius: theme.spacing.xSmall,
        padding: theme.spacing.small,
        margin: `${theme.spacing.medium}px 0`,
    },
    contents: {
        display: "inline-grid",
        gridTemplateColumns: "1fr min-content",
        gridTemplateAreas: '"text text" "icons icons"',
        gap: theme.spacing.small,
        overflow: "hidden",
        width: "100%",
    },
    shortQuestionText: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    questionHeader: {
        display: "block",
        gridArea: "text",
        ...theme.typography.base,
        lineHeight: "22px",
        fontWeight: 800,
        fontSize: "14px",
        paddingBottom: theme.spacing.xSmall,
        paddingRight: theme.spacing.xSmall,
    },
    questionText: {
        gridArea: "text",
        ...theme.typography.base,
        lineHeight: "22px",
        fontWeight: 500,
        fontSize: "14px",
    },
    readMore: {
        width: "100%",
    },
    icons: {
        gridArea: "icons",
        display: "flex",
        gap: theme.spacing.small,
    },
    expanded: {
        alignItems: "flex-start",

        "& $questionText": {
            whiteSpace: "initial",
        },
    },
    conditional: {
        color: theme.colors.error,
    },
    chevron: {
        fontSize: 14,
        marginRight: theme.spacing.xSmall,
    },

    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        contents: {
            gridTemplateAreas: '"text icons" "text icons"',
        },
    },
    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        questionHeader: {
            display: "inline",
        },
        readMore: {
            width: "8%",
            paddingLeft: theme.spacing.xLarge,
        },
        dropdown: {
            display: "flex",
        },
    },
}));

interface IQuestionDisplayProps extends ICommonProps {
    question: QuestionResponseDto;
}

const QuestionDisplay: React.FC<IQuestionDisplayProps> = ({ question, testid }) => {
    const classes = useStyles();

    const [isExpanded, setIsExpanded] = useState(false);
    const { t } = useTranslation();
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [shouldExpand, setShouldExpand] = useState(false);

    useEffect(() => {
        const questionElement = document.getElementById(`question-${question.questionUuid}`);
        if (questionElement !== null) {
            setIsOverflowing(questionElement.offsetWidth < questionElement.scrollWidth);
        }
    }, [isExpanded, question]);

    useEffect(() => {
        if (shouldExpand && isOverflowing) {
            setIsExpanded(true);
        }
        if (!shouldExpand) {
            setIsExpanded(false);
        }
    }, [isOverflowing, shouldExpand]);

    useEffect(() => {
        setIsExpanded(false);
    }, [question]);

    return (
        <div className={classNames(classes.dropdown, { [classes.expanded]: isExpanded })} data-testid={testid}>
            <div className={classes.contents}>
                <span
                    id={`question-${question.questionUuid}`}
                    className={classNames(classes.questionText, { [classes.shortQuestionText]: !isExpanded })}
                >
                    <span className={classes.questionHeader}>Question:</span>
                    <span>{question.questionText}</span>
                </span>

                <div className={classes.icons}>
                    {question.isConditional && (
                        <Icon
                            icon={faSquareExclamation}
                            className={classes.conditional}
                            alt={t("tenderQuestions.conditionalQuestion")}
                        />
                    )}
                </div>
            </div>
            {(isOverflowing || isExpanded) && (
                <TextButton
                    label={!isExpanded ? t("tenderSummaryScreen.readMore") : t("tenderSummaryScreen.close")}
                    type="button"
                    className={classes.readMore}
                    onClick={() => {
                        setShouldExpand(!shouldExpand);
                    }}
                />
            )}
        </div>
    );
};

export default QuestionDisplay;
