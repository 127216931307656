import { Button, Copy, RadioButtonsField, SelectField, TextAreaField, TextField } from "@maistro/components";
import { Form, Formik } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import { IOptionProps } from "components/shared";
import { IExpressionOfInterestData } from "features/project/expressionOfInterest/types";
import { ITheme } from "styles/themes/types";
import { RegexEmail, RegexPostCode, RegexTelephoneCharacters, RegexWebUrl } from "types/consts/regexConstants";

const useStyles = createUseStyles((theme: ITheme) => ({
    copy: {
        marginBottom: theme.spacing.xLarge,
        maxWidth: "100%",
    },
    bold: {
        fontWeight: 800,
    },
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.large,
    },
    buttonControl: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.large,
    },
    inline: {
        display: "flex",
        flexDirection: "row",
        gap: theme.spacing.large,
    },
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
        inline: {
            flexDirection: "column",
        },
    },
}));

interface IExpressionOfInterestDisplayProps {
    projectName: string;
    projectFriendlyId: string;
    submit: (values: IExpressionOfInterestData) => void;
    isFormSubmitting: boolean;
    industryOptions: Array<IOptionProps>;
    companySizeOptions: Array<IOptionProps>;
}

const validationSchema = (t: TFunction<"translation", undefined>) =>
    Yup.object({
        companyName: Yup.string().required(t("expressionOfInterest.companyName.required")),
        industryTypeId: Yup.string().required(t("expressionOfInterest.industry.required")),
        emailAddress: Yup.string()
            .required(t("expressionOfInterest.emailAddress.required"))
            .matches(new RegExp(RegexEmail), t("expressionOfInterest.emailAddress.error")),
        website: Yup.string()
            .required(t("expressionOfInterest.website.required"))
            .matches(new RegExp(RegexWebUrl), t("expressionOfInterest.website.error")),
        companyAddress: Yup.string().required(t("expressionOfInterest.companyAddress.required")),
        townCity: Yup.string().required(t("expressionOfInterest.townCity.required")),
        county: Yup.string().required(t("expressionOfInterest.county.required")),
        postCode: Yup.string()
            .required(t("expressionOfInterest.postcode.required"))
            .matches(new RegExp(RegexPostCode), t("expressionOfInterest.postcode.error")),
        telephoneNumber: Yup.string()
            .required(t("expressionOfInterest.telephoneNumber.required"))
            .matches(RegexTelephoneCharacters, t("expressionOfInterest.telephoneNumber.error"))
            .max(16, t("expressionOfInterest.telephoneNumber.max")),
        companySizeId: Yup.string().required(t("expressionOfInterest.companySize.required")),
        companySummary: Yup.string().required(t("expressionOfInterest.companySummary.required")),
        companyRegistrationNumber: Yup.string()
            .required(t("expressionOfInterest.companyRegistrationNumber.required"))
            .max(16, t("expressionOfInterest.companyRegistrationNumber.max")),
        vatRegistrationNumber: Yup.string()
            .required(t("expressionOfInterest.vatRegistrationNumber.required"))
            .max(16, t("expressionOfInterest.vatRegistrationNumber.max")),
    });

const ExpressionOfInterestDisplay: React.FC<IExpressionOfInterestDisplayProps> = ({
    projectName,
    projectFriendlyId,
    submit,
    isFormSubmitting,
    industryOptions,
    companySizeOptions,
}) => {
    const classes = useStyles();

    const { t } = useTranslation();

    return (
        <>
            <Copy className={classes.copy}>
                <>
                    {t("expressionOfInterest.pageCopy")}
                    <span className={classes.bold}>{projectName}</span>
                    {` (ref. ${projectFriendlyId}).`}
                </>
            </Copy>
            <Formik
                initialValues={{
                    companyName: "",
                    industryTypeId: "",
                    emailAddress: "",
                    website: "",
                    companyAddress: "",
                    townCity: "",
                    county: "",
                    postCode: "",
                    companySizeId: "",
                    companySummary: "",
                    companyRegistrationNumber: "",
                    vatRegistrationNumber: "",
                }}
                validationSchema={validationSchema(t)}
                onSubmit={(values: IExpressionOfInterestData) => submit(values)}
            >
                {({ errors }) => (
                    <Form className={classes.form}>
                        <TextField
                            testid="expression-of-interest-company-name"
                            label={t("expressionOfInterest.companyName.label")}
                            name="companyName"
                            required
                        />
                        <SelectField
                            testid="expression-of-interest-industry"
                            label={t("expressionOfInterest.industry.label")}
                            name="industryTypeId"
                            options={industryOptions}
                            required
                        />
                        <div className={classes.inline}>
                            <TextField
                                testid="expression-of-interest-email-address"
                                label={t("expressionOfInterest.emailAddress.label")}
                                labelInformation={t("expressionOfInterest.emailAddress.copy")}
                                name="emailAddress"
                                required
                                fullWidth
                            />
                            <TextField
                                testid="expression-of-interest-website"
                                label={t("expressionOfInterest.website.label")}
                                name="website"
                                required
                                fullWidth
                            />
                        </div>
                        <div className={classes.inline}>
                            <TextField
                                testid="expression-of-interest-company-address"
                                label={t("expressionOfInterest.companyAddress.label")}
                                name="companyAddress"
                                required
                                fullWidth
                            />
                            <TextField
                                testid="expression-of-interest-town-city"
                                label={t("expressionOfInterest.townCity.label")}
                                name="townCity"
                                required
                                fullWidth
                            />
                        </div>
                        <div className={classes.inline}>
                            <TextField
                                testid="expression-of-interest-county"
                                label={t("expressionOfInterest.county.label")}
                                name="county"
                                required
                                fullWidth
                            />
                            <TextField
                                testid="expression-of-interest-postcode"
                                label={t("expressionOfInterest.postcode.label")}
                                name="postCode"
                                required
                                fullWidth
                            />
                        </div>
                        <TextField
                            testid="expression-of-interest-telephonenumber"
                            label={t("expressionOfInterest.telephoneNumber.label")}
                            name="telephoneNumber"
                            required
                            fullWidth
                        />
                        <RadioButtonsField
                            label={t("expressionOfInterest.companySize.label")}
                            name="companySizeId"
                            required
                            options={companySizeOptions}
                            columns={0}
                            testid="expression-of-interest-company-size"
                        />
                        <TextAreaField
                            testid="expression-of-interest-company-summary"
                            label={t("expressionOfInterest.companySummary.label")}
                            copy={t("expressionOfInterest.companySummary.copy")}
                            name="companySummary"
                            required
                        />
                        <div className={classes.inline}>
                            <TextField
                                testid="expression-of-interest-company-registration-number"
                                label={t("expressionOfInterest.companyRegistrationNumber.label")}
                                copy={t("expressionOfInterest.companyRegistrationNumber.copy")}
                                name="companyRegistrationNumber"
                                required
                                fullWidth
                            />
                            <TextField
                                testid="expression-of-interest-vat-registration-number"
                                label={t("expressionOfInterest.vatRegistrationNumber.label")}
                                copy={t("expressionOfInterest.vatRegistrationNumber.copy")}
                                name="vatRegistrationNumber"
                                required
                                fullWidth
                            />
                        </div>
                        <div className={classes.buttonControl}>
                            <Button
                                label={t("expressionOfInterest.submit")}
                                type="submit"
                                size="large"
                                disabled={isFormSubmitting || !isEmpty(errors)}
                                testid="expression-of-interest-submit-button"
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ExpressionOfInterestDisplay;
