import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { ISortBy, MeatballMenu, Pagination, Popup, useToaster } from "@maistro/components";
import { IButtonProps } from "@maistro/components/dist/esm/types/components/Button/Button";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import { Loader } from "components";
import CompanyListDisplay from "features/company/list/CompanyListDisplay";
import CompanyListFilters from "features/company/list/filter/CompanyListFilters";
import useCompanyListConfiguration from "features/company/list/hooks/useCompanyListConfiguration";
import useCompanyListContainer from "features/company/list/hooks/useCompanyListContainer";
import useAppDispatch from "hooks/useAppDispatch";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import routes from "routes/routePaths/RoutePaths";
import { useDeleteCompanyDetailsMutation } from "store/api/companyApi";
import { setBack, setCtas, setPageTitle } from "store/layoutSlice";
import { ITheme } from "styles/themes/types";
import { CompanyDetailsDto } from "types/dtos/company/CompanyDetailsDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

const useStyles = createUseStyles((theme: ITheme) => ({
    pagination: {
        marginTop: theme.spacing.small,
    },
}));

const CompanyListContainer: React.FC = () => {
    const classes = useStyles();
    const [isDeleteCompanyPopupOpen, setIsDeleteCompanyPopupOpen] = useState(false);

    const { userHasPermission } = useCurrentUser();
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { columnConfiguration, sortConfiguration } = useCompanyListConfiguration();

    const {
        createCompany,
        currentFilters,
        filteredCompanies,
        isSearching,
        navigateToPage,
        options,
        paginationState,
        setFilters,
        setSortBy,
        sortBy,
    } = useCompanyListContainer(sortConfiguration, setIsLoading);

    const toast = useToaster();
    const [deleteCompanyDetails, { isLoading: isDeletingCompany, isError: isDeleteError, isSuccess: isDeleteSuccess }] =
        useDeleteCompanyDetailsMutation();
    useEffect(() => {
        if (isDeleteError) toast.error("Delete Error");
        if (isDeleteSuccess) toast.success("Delete Success");
    }, [isDeleteError, isDeleteSuccess, t, toast]);

    useEffect(() => {
        const canViewCompanyList = userHasPermission(PermissionsEnum.ManageSupport);
        if (!canViewCompanyList) {
            navigate(routes.common.dashboard.path);
        }
        dispatch(
            setBack({
                route: routes.common.settings,
            }),
        );
        dispatch(setPageTitle(t("companyList.title")));
        const createCompanyButtonProps: IButtonProps = {
            onClick: () => {
                ReactGA.event({
                    category: ga4Category.Button,
                    action: ga4Action.ButtonClick,
                    label: "Companies - Create Company",
                });
                createCompany();
            },
            label: t("companyList.cta"),
            testid: "new-company-button",
            icon: faPlus,
            iconPosition: "left",
        };
        dispatch(setCtas([createCompanyButtonProps]));
    }, [createCompany, dispatch, navigate, t, userHasPermission]);

    const [selectedCompany, setSelectedCompany] = useState<CompanyDetailsDto | null>(null);

    const deleteCompany = () => {
        if (selectedCompany !== null) {
            const { companyUuid } = selectedCompany;
            deleteCompanyDetails(companyUuid).then(() => {
                setIsDeleteCompanyPopupOpen(false);

                const indexOfObject = filteredCompanies.findIndex((obj) => obj.companyUuid === companyUuid);

                if (indexOfObject !== -1) {
                    filteredCompanies.splice(indexOfObject, 1);
                }
            });
        }
    };

    const hideDeleteCompanyPopup = () => {
        setSelectedCompany(null);
        setIsDeleteCompanyPopupOpen(false);
    };

    const showDeleteCompanyPopup = (company: CompanyDetailsDto) => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Company - Delete - ${company.registeredName}`,
        });
        setSelectedCompany(company);
        setIsDeleteCompanyPopupOpen(true);
    };
    const meatballMenuItem = {
        position: 5,
        priorityLevel: 0,
        name: "menu",
        minWidth: 50,
        render: (company: CompanyDetailsDto) => (
            <MeatballMenu
                menuItems={[
                    {
                        label: t("companyList.companies.actions.delete"),
                        // eslint-disable-next-line no-console
                        action: () => {
                            showDeleteCompanyPopup(company);
                        },
                    },
                ]}
            />
        ),
    };

    columnConfiguration[4] = meatballMenuItem;

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <React.Fragment>
                <CompanyListFilters
                    currentFilters={currentFilters}
                    options={options}
                    setCurrentFilters={setFilters}
                    isFilterDrawerOpen={isFilterDrawerOpen}
                    isSearching={isSearching}
                    resetFilters={() => setFilters()}
                    resetPage={() => navigateToPage(1)}
                    setIsFilterDrawerOpen={setIsFilterDrawerOpen}
                />
                <CompanyListDisplay
                    filteredCompanies={filteredCompanies}
                    sortBy={sortBy}
                    columns={columnConfiguration}
                    setSortBy={(sort: ISortBy) => {
                        setSortBy(sort);
                        navigateToPage(1);
                    }}
                />
                <Pagination
                    className={classes.pagination}
                    paginationState={paginationState}
                    navigateToPage={navigateToPage}
                    testid="company-list-pagination"
                    disabled={isSearching}
                />
            </React.Fragment>
            <Popup
                isOpen={isDeleteCompanyPopupOpen}
                onClose={hideDeleteCompanyPopup}
                testid="delete-company-popup"
                title="Delete company"
                message={`Delete ${selectedCompany?.registeredName}`}
                primaryActionText={t("Delete company")}
                secondaryActionText={t("Cancel")}
                onPrimaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: `Company - Delete Submit - ${selectedCompany?.registeredName}`,
                    });
                    deleteCompany();
                }}
                onSecondaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: `Company - Delete Cancel - ${selectedCompany?.registeredName}`,
                    });
                    hideDeleteCompanyPopup();
                }}
                disabled={isDeletingCompany}
            />
        </>
    );
};

export default CompanyListContainer;
