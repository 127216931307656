import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faArrowsTurnToDots,
    faBadgeCheck,
    faCalculator,
    faClipboardList,
    faClock,
    faLightbulb,
    faMemoCircleInfo,
    faPenField,
    faThumbsUp,
} from "@fortawesome/pro-regular-svg-icons";
import { useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import {
    getProjectSections,
    getScoringSectionTypes,
    updateSelectedProjectSections,
} from "api/questions/tenderCriteriaQuestionsApi";
import { Loader } from "components";
import TitleCtaType from "components/Layout/types/TitleCtaType";
import ProjectPreviewDrawer from "features/project/components/ProjectPreviewDrawer";
import { getSectionTitle } from "features/project/helpers/sectionHelpers";
import useProjectFiles from "features/project/hooks/useProjectFiles";
import SectionSelectionDisplay, {
    ISectionCard,
} from "features/project/tenderCriteria/sections/SectionSelectionDisplay";
import useAppDispatch from "hooks/useAppDispatch";
import ReactGA from "react-ga4";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import validationService from "services/validationService";
import { resetLayout, setBack, setMobileFooterCtas, setPageTitle, setTitleCtas, setTooltip } from "store/layoutSlice";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import SectionType from "types/enums/questions/sections/SectionType";

const SectionSelectionContainer: React.FC = () => {
    const [sections, setSections] = useState<ISectionCard[]>([]);
    const [isSaving, setIsSaving] = useState(false);
    const [isAddingSection, setIsAddingSection] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isProjectPreviewOpen, setIsProjectPreviewOpen] = useState(false);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const toast = useToaster();
    const navigate = useNavigate();
    const { projectUuid } = useParams();

    const { projectFiles } = useProjectFiles(projectUuid);

    const selectedSections = useMemo(() => sections.filter((section) => section.isSelected), [sections]);
    const canContinue = useMemo(() => selectedSections.length <= 0 || isSaving, [isSaving, selectedSections.length]);

    const enterSectionDetails = useCallback(() => {
        setIsAddingSection(true);
        setTimeout(() => {
            const newSectionCard = document.getElementById("new-section-card");
            if (newSectionCard) {
                newSectionCard.scrollIntoView({ behavior: "smooth" });
            }
        }, 100);
    }, []);

    const updateSelectedSections = useCallback(async () => {
        if (!projectUuid) return;

        setIsSaving(true);
        const response = await updateSelectedProjectSections({
            projectUuid,
            selectedSections: sections
                .filter((section) => section.isSelected)
                .map((section) => ({
                    sectionType: section.sectionType,
                    previousDescription: section.previousDescription,
                    description: section.title,
                    orderIndex: section.orderIndex,
                })),
        });

        if (response.data instanceof TransactionErrorDto || response.status !== 204) {
            toast.error(t("tenderCriteria.sections.selection.sectionTypesSaveError"));
            setIsSaving(false);
            return;
        }

        localStorage.removeItem(projectUuid);

        toast.success(t("tenderCriteria.sections.selection.sectionTypesSaveSuccess"));
        setIsSaving(false);
        navigate(buildPath(routes.projects.weighting, { projectUuid }));
    }, [navigate, projectUuid, sections, t, toast]);

    useEffect(() => {
        dispatch(setPageTitle(t("tenderCriteria.sections.selection.pageTitle")));
        dispatch(setTooltip(t("tenderCriteria.sections.selection.tooltip")));
        dispatch(
            setBack({
                route: buildRoute(routes.projects.matching, { projectUuid }),
            }),
        );
        dispatch(
            setTitleCtas([
                {
                    type: TitleCtaType.ProjectPreview,
                    onClick: () => setIsProjectPreviewOpen(true),
                },
            ]),
        );
        dispatch(
            setMobileFooterCtas([
                {
                    label: t("tenderCriteria.sections.selection.addSection"),
                    color: "dark",
                    disabled: isAddingSection,
                    onClick: enterSectionDetails,
                    testid: "add-section-button",
                },
                {
                    label: t("tenderCriteria.sections.selection.confirmMobile"),
                    chevron: true,
                    disabled: canContinue,
                    onClick: updateSelectedSections,
                    testid: "confirm-selection-button",
                },
            ]),
        );

        return () => {
            dispatch(resetLayout());
        };
    }, [canContinue, dispatch, enterSectionDetails, projectUuid, t, updateSelectedSections, isAddingSection]);

    const iconForSectionType = useCallback((sectionType: SectionType): IconProp => {
        switch (sectionType) {
            case SectionType.Commercial:
                return faCalculator;
            case SectionType.ServiceDelivery:
                return faClock;
            case SectionType.Quality:
                return faBadgeCheck;
            case SectionType.TechnicalSpecification:
                return faClipboardList;
            case SectionType.Capabilities:
                return faThumbsUp;
            case SectionType.Innovation:
                return faLightbulb;
            case SectionType.Implementation:
                return faArrowsTurnToDots;
            case SectionType.CompanyInformation:
                return faMemoCircleInfo;
            case SectionType.Other:
            default:
                return faPenField;
        }
    }, []);

    const resetSectionsInLocalStorage = (projectid: string, updatedSections: ISectionCard[]) => {
        localStorage.removeItem(projectid);
        localStorage.setItem(projectid, JSON.stringify(updatedSections));
    };

    const setSectionsFromLocalStorage = () => {
        if (!(projectUuid && validationService.isValidUuid(projectUuid))) return;
        const savedData = localStorage.getItem(projectUuid);
        if (savedData) {
            setSections(JSON.parse(savedData));
        }
    };

    const getSections = useCallback(async () => {
        if (!(projectUuid && validationService.isValidUuid(projectUuid))) return;
        const sectionTypesResponse = await getScoringSectionTypes();
        if (sectionTypesResponse.data instanceof TransactionErrorDto || sectionTypesResponse.status !== 200) {
            toast.error(t("tenderCriteria.sections.selection.sectionTypesLoadingError"));
            return;
        }

        const projectSectionsResponse = await getProjectSections(projectUuid);
        if (projectSectionsResponse.data instanceof TransactionErrorDto || projectSectionsResponse.status !== 200) {
            toast.error(t("tenderCriteria.sections.selection.projectSectionsLoadingError"));
            return;
        }

        const { sectionTypes } = sectionTypesResponse.data;
        const projectSections = projectSectionsResponse.data.sections;
        const updatedSections = [
            ...sectionTypes
                .filter((section) => section.description !== SectionType.Other)
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((sectionType) => {
                    const matchingProjectSection = projectSections.find(
                        (projectSection) => projectSection.sectionType === sectionType.description,
                    );

                    return {
                        sectionType: sectionType.description,
                        title: t(`tenderCriteria.sections.selection.types.${sectionType.description}.title`),
                        previousDescription: "",
                        text: t(`tenderCriteria.sections.selection.types.${sectionType.description}.text`),
                        icon: iconForSectionType(sectionType.description),
                        isSelected: !!matchingProjectSection,
                        orderIndex: matchingProjectSection ? matchingProjectSection.orderIndex : undefined,
                    };
                }),
            ...projectSections
                .filter((projectSection) => projectSection.sectionType === SectionType.Other)
                .sort((a, b) => {
                    if (!a.description) return -1;
                    if (!b.description) return 1;
                    return a.description.toLowerCase().localeCompare(b.description.toLowerCase());
                })
                .map((projectSection) => ({
                    sectionType: projectSection.sectionType,
                    previousDescription: "",
                    title: getSectionTitle(projectSection),
                    text: "",
                    icon: faPenField,
                    isSelected: true,
                    orderIndex: projectSection.orderIndex,
                })),
        ];
        setSections(updatedSections);
        localStorage.setItem(projectUuid, JSON.stringify(updatedSections));
    }, [iconForSectionType, projectUuid, t, toast]);

    const fetchData = useCallback(async () => {
        if (!(projectUuid && validationService.isValidUuid(projectUuid))) return;

        const savedData = localStorage.getItem(projectUuid);
        if (!savedData) {
            await getSections();
        } else {
            const savedSections = JSON.parse(savedData);
            setSections(savedSections);
        }

        setIsLoading(false);
    }, [getSections, projectUuid]);

    useEffect(() => {
        if (sections.length < 1) {
            fetchData();
        }
    }, [fetchData, sections.length]);

    const selectSection = (selectedSection: ISectionCard) => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Section Selection - Select - `.concat(selectedSection.title),
        });
        const updatedSections = sections.map((section) =>
            section.title === selectedSection.title
                ? {
                      ...section,
                      isSelected: !section.isSelected,
                      orderIndex: section.orderIndex === undefined ? selectedSections.length : section.orderIndex,
                  }
                : section,
        );
        if (projectUuid) {
            resetSectionsInLocalStorage(projectUuid, updatedSections);
            setSectionsFromLocalStorage();
        }
    };

    const addSection = (addedSection: ISectionCard) => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Section Selection - Add - `.concat(addedSection.title),
        });
        const updatedSections = [
            ...sections,
            {
                ...addedSection,
                orderIndex: selectedSections.length,
            },
        ];
        if (projectUuid) {
            resetSectionsInLocalStorage(projectUuid, updatedSections);
            setSectionsFromLocalStorage();
        }
    };

    const deleteSection = (sectionForDeletion: ISectionCard) => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Section Selection - Delete - `.concat(sectionForDeletion.title),
        });
        const updatedSections = sections.filter((section) => section.title !== sectionForDeletion.title);
        if (projectUuid) {
            resetSectionsInLocalStorage(projectUuid, updatedSections);
            setSectionsFromLocalStorage();
        }
    };

    const updateSection = (sectionForUpdate: ISectionCard) => {
        const updatedSections = sections.map((section) => {
            if (section.title === sectionForUpdate.previousDescription) {
                return {
                    ...section,

                    sectionType: sectionForUpdate.sectionType,
                    title: sectionForUpdate.title,
                    text: sectionForUpdate.text,
                    previousDescription: sectionForUpdate.previousDescription,
                    icon: faPenField,
                    isNewSection: sectionForUpdate.isNewSection,
                    orderIndex: sectionForUpdate.orderIndex,
                };
            }

            return section;
        });

        if (projectUuid) {
            resetSectionsInLocalStorage(projectUuid, updatedSections);
            setSectionsFromLocalStorage();
        }
    };

    if (isLoading) return <Loader />;

    return (
        <div>
            <SectionSelectionDisplay
                sections={sections}
                isAddingSection={isAddingSection}
                setIsAddingSection={setIsAddingSection}
                updateSelectedSections={updateSelectedSections}
                canContinue={canContinue}
                selectSection={selectSection}
                deleteSection={deleteSection}
                addSection={addSection}
                updateSection={updateSection}
            />
            <ProjectPreviewDrawer
                projectFiles={projectFiles}
                isOpen={isProjectPreviewOpen}
                close={() => setIsProjectPreviewOpen(false)}
                allowProjectEdit
                navigationState={{
                    route: buildRoute(routes.projects.sections, { projectUuid }),
                }}
            />
        </div>
    );
};

export default SectionSelectionContainer;
