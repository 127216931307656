import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Icon } from "@maistro/components";
import classNames from "classnames";
import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Link } from "react-router-dom";

import { ICommonProps } from "components/shared";
import stringService from "services/stringService";
import { ITheme } from "styles/themes/types";
import { ZINDEX_NAV } from "types/consts/zIndexConstants";

import ReactGA from "react-ga4";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    nav: {
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,
        width: theme.layout.navBarWidth,
        zIndex: ZINDEX_NAV,
    },
    navLogo: {},
    maistroLogo: {},
    navItems: {
        listStyleType: "none",
        marginTop: 80,
    },

    navItem: {
        padding: theme.spacing.small,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        gap: theme.spacing.small,
        fontSize: 14,

        "&:hover": {
            backgroundColor: theme.colors.highlight,
        },
    },

    [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        nav: {
            borderTop: `${theme.layout.accentBarWidth}px solid ${theme.colors.accentBar}`,
            right: "auto",
            left: 0,
            width: theme.layout.navBarWidth,
            backgroundColor: theme.colors.primary,
        },
        navLogo: {
            maxWidth: "65%",
            backgroundColor: theme.colors.logoBackground,
            paddingLeft: theme.spacing.xLarge,
            paddingTop: theme.spacing.large,
        },
        maistroLogo: {
            maxWidth: "100%",
            padding: theme.spacing.large,
            backgroundColor: theme.colors.logoBackground,
            maxHeight: 135,
        },
        navItems: {
            marginTop: "20px !important",
            paddingLeft: 20,
        },

        navItem: {
            marginTop: theme.spacing.xSmall,
            borderTopLeftRadius: 100,
            borderBottomLeftRadius: 100,
        },
    },

    active: {
        backgroundColor: theme.colors.highlight,
    },

    mobileLogo: {
        maxHeight: "30px",
        position: "absolute",
        bottom: 20,
        right: 20,
    },

    navMenuClose: {
        fontSize: 24,
        position: "absolute",
        top: 0,
        right: 0,
        "& span": {
            display: "inline-block",
            padding: theme.spacing.small,
            "&:hover": {
                color: theme.colors.highlight,
                cursor: "pointer",
            },
        },
    },

    centreLogo: {
        textAlign: "center",
    },
}));

export interface INavItem {
    label: string;
    icon: IconProp;
    route: string;
    isActive?: boolean;
    isVisible?: () => boolean;
}

interface INavProps extends ICommonProps {
    onCloseMenu: () => void;
    navItems: INavItem[];
}

const Nav: React.FC<INavProps> = (props) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles();

    const { onCloseMenu, navItems, className, testid } = props;

    return (
        <div className={className} data-component="Nav" data-testid={testid}>
            <nav className={classes.nav} aria-label="Main Navigation">
                <header className={classes.centreLogo}>
                    <span
                        className={classNames(classes.navMenuClose, "hidden-desktop")}
                        onClick={onCloseMenu}
                        data-testid="nav-menu-close"
                    >
                        <span>
                            <Icon className="hidden-desktop" icon={faTimes} />
                        </span>
                    </span>
                    <img
                        className={classNames(classes.maistroLogo, "hidden-mobile")}
                        alt="Logo"
                        src={theme.logos.logo}
                    />
                </header>
                <ul className={classes.navItems}>
                    {navItems?.map((item) => (
                        <li key={item.label}>
                            <Link
                                className={classNames(classes.navItem, { [classes.active]: item.isActive })}
                                to={item.route}
                                onClick={() => {
                                    ReactGA.event({
                                        category: ga4Category.Menu,
                                        action: ga4Action.MenuElement,
                                        label: "NavMenu - ".concat(item.label),
                                    });
                                    onCloseMenu();
                                }}
                                data-testid={`nav-item-${stringService.toKebabCase(item.label)}`}
                            >
                                <Icon icon={item.icon} centered />
                                {item.label}
                            </Link>
                        </li>
                    ))}
                </ul>
                <a href="https://maistro.com/" target="_blank" rel="noreferrer">
                    <img
                        className={classNames(classes.mobileLogo, "hidden-desktop")}
                        alt="Logo"
                        src={theme.logos.poweredByMaistroLogo.dark}
                    />
                </a>
            </nav>
        </div>
    );
};

export default Nav;
