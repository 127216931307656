import {
    CategoriesField,
    Grid,
    RadioButtonsField,
    ReadOnlyField,
    SelectField,
    TextAreaField,
    TextField,
} from "@maistro/components";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { ICategoryProps, IOptionProps } from "components/shared";
import ICompanyProfileForm from "features/company/profile/edit/types/ICompanyProfileForm";
import { ITheme } from "styles/themes/types";
import { CompanySizeV2, getCompanySizeRange } from "types/enums/companies/CompanySizeV2";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },

    size: {
        display: "inline-flex",
        marginBottom: theme.spacing.xSmall,
    },

    sizeLabel: {
        display: "inline-flex",
        "& > div": {
            lineHeight: "16px",
        },
    },
}));

interface ICompanyInformationFormProps {
    userIsMaistro: boolean;
    countries: IOptionProps[];
    sizes: IOptionProps[];
    setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
    isCreateMode?: boolean;
    fetchTaxonomyOptions?: (searchParameter: string) => Promise<ICategoryProps[]>;
}

const CompanyInformationForm: React.FC<ICompanyInformationFormProps> = ({
    userIsMaistro,
    countries,
    sizes,
    setIsValid,
    isCreateMode,
    fetchTaxonomyOptions,
}) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const { isValid, errors, setFieldTouched } = useFormikContext<ICompanyProfileForm>();

    useEffect(() => {
        setIsValid(isValid);
    }, [isValid, setIsValid]);

    useEffect(() => {
        if (!isCreateMode && errors) {
            Object.keys(errors).forEach((field) => setFieldTouched(field));
        }
    }, [setFieldTouched, errors, isCreateMode]);

    return (
        <div className={classes.container}>
            <TextField
                name="registeredName"
                label={t("companyProfile.companyInformation.registeredName.label")}
                tooltip={t("companyProfile.companyInformation.registeredName.tooltip")}
                required
                testid="company-information-registered-name"
                disabled={!userIsMaistro}
            />
            {isCreateMode && (
                <>
                    <TextField
                        name="streetAddress"
                        label={t("companyProfile.companyInformation.streetAddress.label")}
                        tooltip={t("companyProfile.companyInformation.streetAddress.tooltip")}
                        required
                        testid="company-information-street-address"
                    />
                    <Grid columns={2}>
                        <TextField
                            name="city"
                            label={t("companyProfile.companyInformation.city.label")}
                            required
                            testid="company-information-city"
                        />
                        <TextField
                            name="county"
                            label={t("companyProfile.companyInformation.county.label")}
                            testid="company-information-county"
                        />
                        <SelectField
                            name="countryTypeId"
                            label={t("companyProfile.companyInformation.country.label")}
                            options={countries}
                            required
                            testid="company-information-country"
                        />
                        <TextField
                            name="postcode"
                            label={t("companyProfile.companyInformation.postcode.label")}
                            required
                            testid="company-information-postcode"
                        />
                    </Grid>
                </>
            )}
            <RadioButtonsField
                name="sizeTypeId"
                label={t("companyProfile.companyInformation.sizeType.label")}
                tooltip={t("companyProfile.companyInformation.sizeType.tooltip")}
                optionClassName={classes.size}
                options={sizes
                    .filter((size) => size.label !== CompanySizeV2.None)
                    .map((size) => ({
                        label: (
                            <ReadOnlyField
                                className={classes.sizeLabel}
                                label={size.label}
                                value={getCompanySizeRange(size.label as CompanySizeV2)}
                            />
                        ),
                        value: size.value,
                    }))}
                columns={0}
                required
                testid="company-information-size-type"
            />
            <RadioButtonsField
                name="companyType"
                label={t("companyProfile.companyInformation.companyType.label")}
                tooltip={t("companyProfile.companyInformation.companyType.tooltip")}
                options={[
                    { label: t("common.buyer"), value: "buyer" },
                    { label: t("common.supplier"), value: "supplier" },
                ]}
                columns={0}
                required
                testid="company-information-company-type"
                disabled={!userIsMaistro}
            />

            {isCreateMode && fetchTaxonomyOptions && (
                <CategoriesField
                    label={t("companyProfile.companyInformation.companyServices.label")}
                    tooltip={t("companyServices.tooltip")}
                    name="companyServices"
                    onSearch={fetchTaxonomyOptions}
                    testid="company-information-categories"
                />
            )}
            <Grid columns={2}>
                <TextField
                    name="companiesHouseNumber"
                    label={t("companyProfile.companyInformation.companiesHouseNumber.label")}
                    tooltip={t("companyProfile.companyInformation.companiesHouseNumber.tooltip")}
                    testid="company-information-companies-house-number"
                />
                <TextField
                    name="registrationNumber"
                    label={t("companyProfile.companyInformation.registrationNumber.label")}
                    tooltip={t("companyProfile.companyInformation.registrationNumber.tooltip")}
                    testid="company-information-registration-number"
                />
            </Grid>
            <TextAreaField
                name="description"
                label={t("companyProfile.companyInformation.description.label")}
                tooltip={t("companyProfile.companyInformation.description.tooltip")}
                testid="company-information-description"
            />
        </div>
    );
};

export default CompanyInformationForm;
