import { Copy } from "@maistro/components";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import AddUserToProject from "features/project/shared/users/AddUserToProject";
import { ITheme } from "styles/themes/types";
import { CompanyUserDto } from "types/dtos/company/CompanyUserDto";
import { ProjectUserDto } from "types/dtos/projects/users/ProjectUserDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    copy: {
        marginBottom: theme.spacing.large,
    },
}));

interface IProjectSettingsDisplayProps {
    companyUsers: CompanyUserDto[];
    projectUsers: ProjectUserDto[];
    onSelect: (value: string) => void;
    onRowEdit: (row: ProjectUserDto) => void;
    onRowDelete: (row: ProjectUserDto) => void;
    handleUserSearch: (searchText: string) => void;
}

const ProjectSettingsDisplay: React.FC<IProjectSettingsDisplayProps> = (props) => {
    const classes = useStyles();

    const { companyUsers, projectUsers, onSelect, onRowEdit, onRowDelete, handleUserSearch } = props;

    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Copy className={classes.copy}>{t("projectSettings.subtitle")}</Copy>
            <Formik
                initialValues={{
                    contributors: projectUsers,
                }}
                onSubmit={() => Promise.resolve()}
                enableReinitialize
            >
                <Form>
                    <AddUserToProject
                        name="contributors"
                        label={t("projectSettings.contributors.label")}
                        placeholder={t("projectSettings.contributors.placeholder")}
                        companyUsers={companyUsers}
                        projectUsers={projectUsers}
                        onSelect={onSelect}
                        onRowDelete={onRowDelete}
                        onRowEdit={onRowEdit}
                        handleUserSearch={handleUserSearch}
                        testid="project-settings-contributors"
                    />
                </Form>
            </Formik>
        </React.Fragment>
    );
};

export default ProjectSettingsDisplay;
