import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "oidc-client-ts";

export interface OidcState {
    user: User | null;
    isLoading: boolean;
    error: Error | null;
}

const initialState: OidcState = {
    user: null,
    isLoading: false,
    error: null,
};

export const oidcSlice = createSlice({
    name: "oidc",
    initialState,
    reducers: {
        userLoading: (state) => {
            state.isLoading = true;
        },
        userLoaded: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
            state.isLoading = false;
        },
        userUnloaded: (state) => {
            state.user = null;
            state.isLoading = false;
        },
        userError: (state, action: PayloadAction<Error>) => {
            state.user = null;
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});

export const { userLoading, userLoaded, userUnloaded, userError } = oidcSlice.actions;

export default oidcSlice.reducer;
