import { faBell } from "@fortawesome/pro-regular-svg-icons";
import { faBell as faBellSolid } from "@fortawesome/pro-solid-svg-icons";
import { Badge, Copy, Drawer, Heading, Icon, TextButton } from "@maistro/components";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import { updateNotification, updateNotifications } from "api/notificationsApi";
import NotificationCard from "components/Layout/Notifications/NotificationCard";
import getActionUrl from "components/Layout/Notifications/helpers/notificationsHelper";
import useAppDispatch from "hooks/useAppDispatch";
import useReduxSelector from "hooks/useReduxSelector";
import ReactGA from "react-ga4";
import {
    dismissNotificationAlert,
    selectAllNotifications,
    selectNotificationAlert,
    selectUnreadNotificationsCount,
} from "store/notificationsSlice";
import { ITheme } from "styles/themes/types";
import { NotificationDto } from "types/dtos/notifications/NotificationDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    bell: {
        ...theme.typography.base,
        background: "inherit",
        border: "none",
        position: "relative",
        cursor: "pointer",
    },

    headingContainer: {
        display: "flex",
        gap: theme.spacing.xSmall,
        justifyContent: "space-between",
        marginBottom: theme.spacing.medium,
    },

    alert: {
        animation: "$ring 1s ease-in-out",
        transformOrigin: "50% 4px",
    },

    archiveAll: {
        marginRight: theme.spacing.large,
    },

    "@keyframes ring": {
        "0%": { transform: "rotate(0)" },
        "4%": { transform: "rotate(12deg)" },
        "12%": { transform: "rotate(-24deg)" },
        "20%": { transform: "rotate(32deg)" },
        "28%": { transform: "rotate(-26deg)" },
        "36%": { transform: "rotate(20deg)" },
        "44%": { transform: "rotate(-16deg)" },
        "52%": { transform: "rotate(12deg)" },
        "60%": { transform: "rotate(-9deg)" },
        "68%": { transform: "rotate(6deg)" },
        "76%": { transform: "rotate(-4deg)" },
        "84%": { transform: "rotate(2deg)" },
        "92%": { transform: "rotate(-1deg)" },
        "100%": { transform: "rotate(0)" },
    },
}));

const Notifications: React.FC = () => {
    const classes = useStyles();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const notifications = useReduxSelector(selectAllNotifications);
    const unreadCount = useReduxSelector(selectUnreadNotificationsCount);
    const notificationAlert = useReduxSelector(selectNotificationAlert);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const openNotifications = () => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: "Notification bell",
        });
        dispatch(dismissNotificationAlert());
        setIsDrawerOpen(true);
    };

    const goToAction = (notification: NotificationDto) => {
        ReactGA.event({
            category: ga4Category.Element,
            action: ga4Action.Element,
            label: "Notification - ".concat(notification.title),
        });
        if (!notification.isRead) {
            updateNotification({ notificationId: notification.notificationId, markAsRead: true });
        }

        const actionUrl = getActionUrl(notification.notificationType, notification.data);
        navigate(actionUrl);
        setIsDrawerOpen(false);
    };

    const archiveAll = () => {
        const toArchive = notifications.map((n) => {
            return {
                notificationId: n.notificationId,
                archive: true,
            };
        });
        updateNotifications({
            notifications: toArchive,
        });
    };

    return (
        <>
            <button className={classes.bell} onClick={openNotifications} data-testid="notification-bell">
                <Icon
                    icon={notificationAlert ? faBellSolid : faBell}
                    className={classNames({ [classes.alert]: notificationAlert })}
                />
                {unreadCount > 0 && <Badge quantity={unreadCount} size="small" />}
            </button>
            <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} testid="notifications-drawer">
                <div className={classes.headingContainer}>
                    <Heading variant="h2" testid="notifications-drawer-title">
                        {t("notifications.notifications")}
                    </Heading>
                    {notifications.length > 0 && (
                        <TextButton
                            className={classes.archiveAll}
                            onClick={() => archiveAll()}
                            label={t("notifications.archiveAll")}
                        />
                    )}
                </div>
                {notifications.length === 0 && (
                    <Copy testid="no-notifications-label">{t("notifications.noNotifications")}</Copy>
                )}
                {notifications.map((notification) => (
                    <NotificationCard
                        key={notification.notificationId}
                        notification={notification}
                        goToAction={goToAction}
                    />
                ))}
            </Drawer>
        </>
    );
};

export default Notifications;
