import { Button, CheckboxField, TextField } from "@maistro/components";
import { Form, useFormikContext } from "formik";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { ITheme } from "styles/themes/types";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
    buttons: {
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing.medium,
        marginTop: theme.spacing.medium,
    },
}));

interface IEditUserForm {
    isCreateMode: boolean;
    isSaving: boolean;
}

const EditUserForm: React.FC<IEditUserForm> = ({ isCreateMode, isSaving }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { dirty, isValid, setTouched, errors } = useFormikContext();

    useEffect(() => {
        if (!isCreateMode) {
            setTouched(errors);
        }
    }, [setTouched, errors, isCreateMode]);

    return (
        <Form className={classes.container}>
            <TextField
                name="firstName"
                label={t("adminUsers.form.firstName.label")}
                required
                testid="user-first-name"
            />
            <TextField name="lastName" label={t("adminUsers.form.lastName.label")} required testid="user-last-name" />
            <TextField
                name="emailAddress"
                label={t("adminUsers.form.emailAddress.label")}
                required
                testid="user-email-address"
                disabled={!isCreateMode}
            />
            {!isCreateMode && (
                <CheckboxField
                    name="isSuspended"
                    label={t("adminUsers.form.isSuspended.label")}
                    testid="user-is-suspended"
                />
            )}
            <div className={classes.buttons}>
                <Button
                    testid="cancel-button"
                    type="reset"
                    variant="outline"
                    label={t("common.cancel")}
                    onClick={() =>
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: `Admin Users - Edit User - Cancel`,
                        })
                    }
                />
                <Button
                    testid="save-user-button"
                    type="submit"
                    label={t("adminUsers.form.save.label")}
                    disabled={!dirty || !isValid || isSaving}
                    onClick={() =>
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: `Admin Users - Edit User - Save User`,
                        })
                    }
                />
            </div>
        </Form>
    );
};

export default EditUserForm;
