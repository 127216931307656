import { TextButton } from "@maistro/components";
import useAppDispatch from "hooks/useAppDispatch";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ReactGA from "react-ga4";
import routes from "routes/routePaths/RoutePaths";
import { setIsCurrentPagePublic } from "store/navigationSlice";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const NotFound: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(setIsCurrentPagePublic(true));
        return () => {
            dispatch(setIsCurrentPagePublic(false));
        };
    }, [dispatch]);

    return (
        <React.Fragment>
            <div>Page Not Found</div>
            <TextButton
                label={t("common.backTo", {
                    options: {
                        page: t("common.pages.dashboard"),
                    },
                })}
                onClick={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: "Not Found - Back To Dashboard",
                    });
                    navigate(routes.common.dashboard.path);
                }}
                testid="back-to-dashboard-link"
            />
        </React.Fragment>
    );
};

export default NotFound;
