import apiErrorService, { ApiResponse } from "services/apiErrorService";
import env from "config/EnvironmentVariables";
import { createAxiosInstance } from "api/commonApi";
import { EmailDataForInvitationTokenResponseDto } from "types/dtos/email/EmailDataForInvitationTokenResponseDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/email-data";

// eslint-disable-next-line import/prefer-default-export
export const getEmailDataForInvitationToken = (
    encodedToken: string,
): Promise<ApiResponse<EmailDataForInvitationTokenResponseDto>> => {
    const url = `${baseApiUrl}/invitation/${encodedToken}`;
    return api.get(url).then((response) => {
        return apiErrorService.transformResponseTypeOnError(response);
    });
};
