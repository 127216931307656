import { BackToTop, Copy, ExpansionPanel } from "@maistro/components";
import { IButtonProps } from "@maistro/components/dist/esm/types/components/Button/Button";
import classNames from "classnames";
import { Form, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";

import { Loader } from "components";
import Prompt from "components/Prompt/Prompt";
import { IVettingQuestionnaireForm } from "features/company/profile/vetting/IVettingQuestionnaireForm";
import VettingNavigation from "features/company/profile/vetting/VettingNavigation";
import useVettingInformation from "features/company/profile/vetting/hooks/useVettingInformation";
import CompanyDetailsSection, {
    companyDetailsSectionValidationSchema,
} from "features/company/profile/vetting/sections/CompanyDetailsSection";
import DataAndSecuritySection, {
    dataAndSecurityValidationSchema,
} from "features/company/profile/vetting/sections/DataAndSecuritySection";
import EnvironmentalSocialAndGovernanceSection, {
    esgValidationSchema,
} from "features/company/profile/vetting/sections/EnvironmentalSocialAndGovernanceSection";
import EthicsSection from "features/company/profile/vetting/sections/EthicsSection";
import FrameworkAgreementsSection, {
    frameworkAgreementsValidationSchema,
} from "features/company/profile/vetting/sections/FrameworkAgreementsSection";
import { equalityValidationSchema } from "features/company/profile/vetting/sections/ethicsSubSections/EqualitySubSection";
import { qualityValidationSchema } from "features/company/profile/vetting/sections/ethicsSubSections/QualitySubSection";
import { standardsValidationSchema } from "features/company/profile/vetting/sections/ethicsSubSections/StandardsSubSection";
import useAppDispatch from "hooks/useAppDispatch";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import { buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { resetLayout, setBack, setCtas, setMobileFooterCtas, setPageTitle, setTooltip } from "store/layoutSlice";
import { ITheme } from "styles/themes/types";
import { AccreditationDto } from "types/dtos/company/vettingInformation/AccreditationDto";
import { InsuranceDto } from "types/dtos/company/vettingInformation/InsuranceDto";
import { MembershipDto } from "types/dtos/company/vettingInformation/MembershipDto";
import { VettingInformationDto } from "types/dtos/company/vettingInformation/VettingInformationDto";
import FileDto from "types/dtos/files/FileDto";
import CurrencyType from "types/enums/CurrencyType";
import CompanyFileType from "types/enums/companies/vettingInformation/CompanyFileType";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

const useStyles = createUseStyles((theme: ITheme) => ({
    marginTop: { marginTop: theme.spacing.medium },
    maxWidth: { maxWidth: theme.breakpoints.lg },
    infoPanel: {
        display: "inline-block",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
    list: {
        listStyle: "square",
        marginBottom: theme.spacing.medium,
        marginLeft: theme.spacing.medium,
    },
    copy: {
        fontSize: 13,
        fontWeight: 500,
    },
    boldCopy: {
        fontSize: 13,
        fontWeight: 700,
    },
}));

const convertNumberOfReportableAccidentsToString = (no?: number) => {
    if (no === undefined || no === null) {
        return undefined;
    }
    return no > 9 ? "10+" : no.toString();
};

const convertNumberOfReportableAccidentsToNumber = (no?: string) => {
    if (!no) {
        return undefined;
    }
    if (no === "10+") {
        return 10;
    }
    return parseInt(no, 10);
};

const CompanyVettingQuestionnaireContainer: React.FC = () => {
    const classes = useStyles();

    const { companyUuid } = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { userHasCompanyPermission, userIsMaistro } = useCurrentUser();

    const userCanUpdateVettingQuestionnaire = useMemo(() => {
        return userIsMaistro || userHasCompanyPermission(PermissionsEnum.UpdateCompany);
    }, [userIsMaistro, userHasCompanyPermission]);

    const {
        isLoading,
        isSaving,
        vettingInformation,
        submit: submitVettingQuestionnaire,
    } = useVettingInformation(companyUuid ?? "");

    const [environmentPolicyFiles, setEnvironmentPolicyFiles] = useState<FileDto[]>([]);
    const [cyberSecurityPlanFiles, setCyberSecurityPlanFiles] = useState<FileDto[]>([]);
    const [disasterRecoveryPlanFiles, setDisasterRecoveryPlanFiles] = useState<FileDto[]>([]);
    const [healthAndSafetyPolicyFiles, setHealthAndSafetyPolicyFiles] = useState<FileDto[]>([]);
    const [drugAndAlcoholPolicyFiles, setDrugAndAlcoholPolicyFiles] = useState<FileDto[]>([]);
    const [diversityAndInclusionPolicyFiles, setDiversityAndInclusionPolicyFiles] = useState<FileDto[]>([]);
    const [briberyPolicyFiles, setBriberyPolicyFiles] = useState<FileDto[]>([]);
    const [socialResponsibilityPolicyFiles, setSocialResponsibilityPolicyFiles] = useState<FileDto[]>([]);
    const [modernSlaveryPolicyFiles, setModernSlaveryPolicyFiles] = useState<FileDto[]>([]);
    const [antiMoneyLaunderingPolicyFiles, setAntiMoneyLaunderingPolicyFiles] = useState<FileDto[]>([]);
    const [companyAccreditations, setCompanyAccreditations] = useState<AccreditationDto[]>(
        vettingInformation?.accreditations ?? [],
    );
    const [dataProtectionBreachesFiles, setDataProtectionBreachesFiles] = useState<FileDto[]>([]);
    const [dataProtectionPolicyFiles, setDataProtectionPolicyFiles] = useState<FileDto[]>([]);
    const [externalAuditFiles, setExternalAuditFiles] = useState<FileDto[]>([]);

    const [companyInsurances, setCompanyInsurances] = useState<InsuranceDto[]>(vettingInformation?.insurances ?? []);
    const [memberships, setMemberships] = useState<MembershipDto[]>(vettingInformation?.memberships ?? []);

    useEffect(() => {
        dispatch(setPageTitle(t("companyVettingQuestionnaire.title")));
        dispatch(setTooltip(t("companyVettingQuestionnaire.titleTooltip")));
        dispatch(
            setBack({
                route: buildRoute(routes.company.companyProfile, { companyUuid }),
            }),
        );
        if (userCanUpdateVettingQuestionnaire) {
            const saveVettingFormButtonProps = {
                label: t("companyVettingQuestionnaire.saveButton"),
                testid: "save-vetting-form-button",
                type: "submit",
                form: "company-vetting-form",
                disabled: isSaving,
            } as IButtonProps;
            dispatch(setCtas([saveVettingFormButtonProps]));
            dispatch(setMobileFooterCtas([saveVettingFormButtonProps]));
        }
        return () => {
            dispatch(resetLayout());
        };
    }, [companyUuid, dispatch, t, userCanUpdateVettingQuestionnaire, isSaving]);

    const onSubmit = (values: IVettingQuestionnaireForm) => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Company Vetting - Save Vetting Form`,
        });
        const submitVettingQuestionnaireDto = {
            companyUuid: companyUuid ?? "",
            parent: {
                name: values.parentCompanyName,
                registrationNumber: values.parentCompanyRegistrationNumber,
            },
            insurances: companyInsurances,
            geographicLocationsTypeIds: values.geographicalLocationsTypeIds,
            companyFinance: {
                currencyType: values.financialCurrency,
                turnoverId: values.financialTurnoverTypeId ? parseInt(values.financialTurnoverTypeId, 10) : null,
            },
            environment: {
                hasEnvironmentalPolicy: values.hasEnvironmentalPolicy,
                environmentalPolicyExpiryDate: values.environmentalPolicyExpiryDate,
                includesEnvironmentalIssuesInPerformance: values.includesEnvironmentalIssuesInPerformance,
                hasAccessToEnvironmentalAdvice: values.hasAccessToEnvironmentalAdvice,
                provider: values.environmentalAdviceProviderName,
                isExternal: values.isProviderExternal,
                hasCarbonEmissionsPolicy: values.hasCarbonEmissionsPolicy,
                carbonEmissionsPolicyDetails: values.carbonEmissionsPolicyDetails,
                operatesInAccordanceWithStandards: values.operatesInAccordanceWithStandards,
                hasEnvironmentalBreaches: values.hasEnvironmentalBreaches,
                environmentalBreachesDetails: values.environmentalBreachesDetails,
                environmentPolicyFiles: environmentPolicyFiles.map((file) => ({
                    ...file,
                    type: CompanyFileType.EnvironmentPolicy,
                })),
            },
            healthAndSafety: {
                hasHealthAndSafetyPolicy: values.hasHealthAndSafetyPolicy,
                hasSpecificTargetsAndActions: values.hasSpecificTargetsAndActions,
                hasRegularTraining: values.hasRegularTraining,
                hasExternalReporting: values.hasExternalReporting,
                hasResponsiblePerson: values.hasResponsiblePerson,
                personName: values.healthAndSafetyPersonName,
                hasDrugAndAlcoholPolicy: values.hasDrugAndAlcoholPolicy,
                numberOfReportableAccidents: convertNumberOfReportableAccidentsToNumber(
                    values.numberOfReportableAccidents,
                ),
                hasOccupationalHealthArrangements: values.hasOccupationalHealthArrangements,
                healthAndSafetyPolicyFiles: healthAndSafetyPolicyFiles.map((file) => ({
                    ...file,
                    type: CompanyFileType.HealthAndSafetyPolicy,
                })),
                drugAndAlcoholPolicyFiles: drugAndAlcoholPolicyFiles.map((file) => ({
                    ...file,
                    type: CompanyFileType.DrugAndAlcoholPolicy,
                })),
            },
            disasterRecovery: {
                hasCyberSecurityPlan: values.hasCyberSecurityPlan,
                cyberSecurityPlanDetails: values.cyberSecurityPlanDetails,
                hasDisasterRecoveryPlan: values.hasDisasterRecoveryPlan,
                disasterRecoveryPlanDetails: values.disasterRecoveryPlanDetails,
                detailsOfCircumstances: values.detailsOfCircumstances,
                hasDetailsOfCircumstances: values.hasDetailsOfCircumstances,
                cyberSecurityPlanFiles: cyberSecurityPlanFiles.map((file) => ({
                    ...file,
                    type: CompanyFileType.CyberSecurityPlan,
                })),
                disasterRecoveryPlanFiles: disasterRecoveryPlanFiles.map((file) => ({
                    ...file,
                    type: CompanyFileType.DisasterRecoveryPlan,
                })),
            },
            dataProtection: {
                hasDataProtectionBreaches: values.hasDataProtectionBreaches,
                breachesDetails: values.breachesDetails,
                hadExternalPrivacyAudit: values.hadExternalPrivacyAudit,
                auditDetails: values.auditDetails,
                hasDataProtectionPolicy: values.hasDataProtectionPolicy,
                microBusinessDataProtectionStatement: values.microBusinessDataProtectionStatement,
                hasIndividualRightsProtection: values.hasIndividualRightsProtection,
                hasDataProtectionOfficer: values.hasDataProtectionOfficer,
                dataProtectionOfficerName: values.dataProtectionOfficerName,
                dataProtectionOfficerEmail: values.dataProtectionOfficerEmail,
                hasStaffTraining: values.hasStaffTrainingDataProtection,
                ensuresSuppliersImplementDataProtection: values.ensuresSuppliersImplementDataProtection,
                hasTradeAssociationMembership: values.hasTradeAssociationMembership,
                documentsPersonalData: values.documentsPersonalData,
                breachesFiles: dataProtectionBreachesFiles.map((file) => ({
                    ...file,
                    type: CompanyFileType.DataProtectionBreaches,
                })),
                externalAuditFiles: externalAuditFiles.map((file) => ({
                    ...file,
                    type: CompanyFileType.ExternalPrivacyAudit,
                })),
                dataProtectionPolicyFiles: dataProtectionPolicyFiles.map((file) => ({
                    ...file,
                    type: CompanyFileType.DataProtectionPolicyAndPrivacyNotice,
                })),
            },
            memberships,
            publicSector: {
                hasPublicSectorFrameworkAgreement: values.hasPublicSectorFrameworkAgreement,
                frameworkAgreementDetails: values.frameworkAgreementDetails,
            },
            equality: {
                hasDiversityAndInclusionPolicy: values.hasDiversityAndInclusionPolicy,
                hasResponsiblePerson: values.hasResponsiblePersonEquality,
                hasCommitmentToAntiDiscrimination: values.hasCommitmentToAntiDiscrimination,
                identifiesIncidentsOfDiscrimination: values.identifiesIncidentsOfDiscrimination,
                allWorkersPaidFairly: values.allWorkersPaidFairly,
                hasUnlawfulDiscriminationFindings: values.hasUnlawfulDiscriminationFindings,
                findingsDetails: values.findingsDetails,
                promoteGoodPracticeForAntiDiscrimination: values.promoteGoodPracticeForAntiDiscrimination,
                hasHumanRightsPolicy: values.hasHumanRightsPolicy,
                diversityAndInclusionPolicyFiles: diversityAndInclusionPolicyFiles.map((file) => ({
                    ...file,
                    type: CompanyFileType.DiversityAndInclusionPolicy,
                })),
            },
            standards: {
                hasCompliancePolicy: values.hasCompliancePolicy,
                complianceStatement: values.complianceStatement,
                hasSocialResponsibilityPolicy: values.hasSocialResponsibilityPolicy,
                hasModernSlaveryPolicy: values.hasModernSlaveryPolicy,
                hasIdentifiedModernSlaveryRisks: values.hasIdentifiedModernSlaveryRisks,
                hasSupplierTermsAndConditions: values.hasSupplierTermsAndConditions,
                hasWhistleblowingPolicy: values.hasWhistleblowingPolicy,
                hasStaffTraining: values.hasStaffTraining,
                hasAntiMoneyLaunderingPolicy: values.hasAntiMoneyLaunderingPolicy,
                hasAntiSlaveryAndHumanTraffickingPolicy: values.hasAntiSlaveryAndHumanTraffickingPolicy,
                briberyPolicyFiles: briberyPolicyFiles.map((file) => ({
                    ...file,
                    type: CompanyFileType.AntiBriberyPolicy,
                })),
                socialResponsibilityPolicyFiles: socialResponsibilityPolicyFiles.map((file) => ({
                    ...file,
                    type: CompanyFileType.CorporateSocialResponsibilityPolicy,
                })),
                modernSlaveryPolicyFiles: modernSlaveryPolicyFiles.map((file) => ({
                    ...file,
                    type: CompanyFileType.ModernSlaveryPolicy,
                })),
                antiMoneyLaunderingPolicyFiles: antiMoneyLaunderingPolicyFiles.map((file) => ({
                    ...file,
                    type: CompanyFileType.AntiMoneyLaunderingPolicy,
                })),
            },
            quality: {
                hasResponsiblePerson: values.hasResponsiblePersonQuality,
                personResponsible: values.personResponsible,
                registeredWithConstructionIndustryScheme: values.registeredWithConstructionIndustryScheme,
                registeredAsGrossPaymentStatus: values.registeredAsGrossPaymentStatus,
            },
            accreditations: companyAccreditations,
        } as VettingInformationDto;

        submitVettingQuestionnaire(submitVettingQuestionnaireDto);
    };

    useEffect(() => {
        setEnvironmentPolicyFiles(vettingInformation?.environment?.environmentPolicyFiles ?? []);
        setCyberSecurityPlanFiles(vettingInformation?.disasterRecovery?.cyberSecurityPlanFiles ?? []);
        setDisasterRecoveryPlanFiles(vettingInformation?.disasterRecovery?.disasterRecoveryPlanFiles ?? []);
        setHealthAndSafetyPolicyFiles(vettingInformation?.healthAndSafety?.healthAndSafetyPolicyFiles ?? []);
        setDrugAndAlcoholPolicyFiles(vettingInformation?.healthAndSafety?.drugAndAlcoholPolicyFiles ?? []);
        setDiversityAndInclusionPolicyFiles(vettingInformation?.equality?.diversityAndInclusionPolicyFiles ?? []);
        setBriberyPolicyFiles(vettingInformation?.standards?.briberyPolicyFiles ?? []);
        setSocialResponsibilityPolicyFiles(vettingInformation?.standards?.socialResponsibilityPolicyFiles ?? []);
        setModernSlaveryPolicyFiles(vettingInformation?.standards?.modernSlaveryPolicyFiles ?? []);
        setAntiMoneyLaunderingPolicyFiles(vettingInformation?.standards?.antiMoneyLaunderingPolicyFiles ?? []);
        setCompanyAccreditations(vettingInformation?.accreditations ?? []);
        setDataProtectionBreachesFiles(vettingInformation?.dataProtection?.breachesFiles ?? []);
        setDataProtectionPolicyFiles(vettingInformation?.dataProtection?.dataProtectionPolicyFiles ?? []);
        setExternalAuditFiles(vettingInformation?.dataProtection?.externalAuditFiles ?? []);
        setCompanyInsurances(vettingInformation?.insurances ?? []);
        setMemberships(vettingInformation?.memberships ?? []);
    }, [vettingInformation]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <Copy testid="vetting-questionnaire-copy">{t("companyVettingQuestionnaire.copy")}</Copy>

            <ExpansionPanel
                label=""
                className={classNames(classes.marginTop, classes.maxWidth, classes.infoPanel)}
                isExpanded
                useAltStyle
                testid="vetting-dialog"
            >
                <Copy className={classes.copy}>{t("companyVettingQuestionnaire.infoPanel.topMessage")}</Copy>

                <ul className={classNames(classes.marginTop, classes.list)}>
                    <li>
                        <Copy className={classes.copy}>
                            {t("companyVettingQuestionnaire.infoPanel.companyPolicies")}
                        </Copy>
                    </li>
                    <li>
                        <Copy className={classes.copy}>
                            {t("companyVettingQuestionnaire.infoPanel.insurancePolicies")}
                        </Copy>
                    </li>
                    <li>
                        <Copy className={classes.copy}>
                            {t("companyVettingQuestionnaire.infoPanel.healthAndSafetyInformation")}
                        </Copy>
                    </li>
                    <li>
                        <Copy className={classes.copy}>
                            {t("companyVettingQuestionnaire.infoPanel.qualityAssuranceAccreditations")}
                        </Copy>
                    </li>
                </ul>

                <Copy className={classes.copy}>{t("companyVettingQuestionnaire.infoPanel.lowerMessage")}</Copy>

                <Copy className={classNames(classes.marginTop, classes.boldCopy)}>
                    {t("companyVettingQuestionnaire.infoPanel.bottomMessage")}
                </Copy>
            </ExpansionPanel>

            <Formik
                enableReinitialize
                validateOnChange={false}
                initialValues={
                    {
                        parentCompanyName: vettingInformation?.parent?.name ?? "",
                        parentCompanyRegistrationNumber: vettingInformation?.parent?.registrationNumber ?? "",

                        geographicalLocationsTypeIds:
                            vettingInformation?.geographicLocationsTypeIds?.map((id) => id.toString()) ?? [],

                        financialTurnoverTypeId: vettingInformation?.companyFinance?.turnoverId.toString() ?? "",
                        financialCurrency:
                            vettingInformation?.companyFinance?.currencyType.toString() ?? CurrencyType.GBP,

                        hasHealthAndSafetyPolicy: vettingInformation?.healthAndSafety?.hasHealthAndSafetyPolicy,
                        hasSpecificTargetsAndActions: vettingInformation?.healthAndSafety?.hasSpecificTargetsAndActions,
                        hasRegularTraining: vettingInformation?.healthAndSafety?.hasRegularTraining,
                        hasExternalReporting: vettingInformation?.healthAndSafety?.hasExternalReporting,
                        hasResponsiblePerson: vettingInformation?.healthAndSafety?.hasResponsiblePerson,
                        healthAndSafetyPersonName: vettingInformation?.healthAndSafety?.personName ?? "",
                        hasDrugAndAlcoholPolicy: vettingInformation?.healthAndSafety?.hasDrugAndAlcoholPolicy,
                        numberOfReportableAccidents:
                            convertNumberOfReportableAccidentsToString(
                                vettingInformation?.healthAndSafety?.numberOfReportableAccidents,
                            ) ?? "",
                        hasOccupationalHealthArrangements:
                            vettingInformation?.healthAndSafety?.hasOccupationalHealthArrangements,

                        hasEnvironmentalPolicy: vettingInformation?.environment?.hasEnvironmentalPolicy,
                        environmentalPolicyExpiryDate: vettingInformation?.environment?.environmentalPolicyExpiryDate,
                        includesEnvironmentalIssuesInPerformance:
                            vettingInformation?.environment?.includesEnvironmentalIssuesInPerformance,
                        hasAccessToEnvironmentalAdvice: vettingInformation?.environment?.hasAccessToEnvironmentalAdvice,
                        environmentalAdviceProviderName: vettingInformation?.environment?.provider ?? "",
                        isProviderExternal: vettingInformation?.environment?.isExternal,
                        hasCarbonEmissionsPolicy: vettingInformation?.environment?.hasCarbonEmissionsPolicy,
                        carbonEmissionsPolicyDetails:
                            vettingInformation?.environment?.carbonEmissionsPolicyDetails ?? "",
                        operatesInAccordanceWithStandards:
                            vettingInformation?.environment?.operatesInAccordanceWithStandards,
                        hasEnvironmentalBreaches: vettingInformation?.environment?.hasEnvironmentalBreaches,
                        environmentalBreachesDetails:
                            vettingInformation?.environment?.environmentalBreachesDetails ?? "",

                        hasCyberSecurityPlan: vettingInformation?.disasterRecovery?.hasCyberSecurityPlan,
                        cyberSecurityPlanDetails: vettingInformation?.disasterRecovery?.cyberSecurityPlanDetails ?? "",
                        hasDisasterRecoveryPlan: vettingInformation?.disasterRecovery?.hasDisasterRecoveryPlan,
                        disasterRecoveryPlanDetails:
                            vettingInformation?.disasterRecovery?.disasterRecoveryPlanDetails ?? "",
                        detailsOfCircumstances: vettingInformation?.disasterRecovery?.detailsOfCircumstances ?? "",
                        hasDetailsOfCircumstances:
                            vettingInformation?.disasterRecovery?.hasDetailsOfCircumstances ?? "",

                        hasDataProtectionBreaches: vettingInformation?.dataProtection?.hasDataProtectionBreaches,
                        breachesDetails: vettingInformation?.dataProtection?.breachesDetails ?? "",
                        hadExternalPrivacyAudit: vettingInformation?.dataProtection?.hadExternalPrivacyAudit,
                        auditDetails: vettingInformation?.dataProtection?.auditDetails ?? "",
                        hasDataProtectionPolicy: vettingInformation?.dataProtection?.hasDataProtectionPolicy,
                        microBusinessDataProtectionStatement:
                            vettingInformation?.dataProtection?.microBusinessDataProtectionStatement ?? "",
                        hasIndividualRightsProtection:
                            vettingInformation?.dataProtection?.hasIndividualRightsProtection,
                        hasDataProtectionOfficer: vettingInformation?.dataProtection?.hasDataProtectionOfficer,
                        dataProtectionOfficerName: vettingInformation?.dataProtection?.dataProtectionOfficerName ?? "",
                        dataProtectionOfficerEmail:
                            vettingInformation?.dataProtection?.dataProtectionOfficerEmail ?? "",
                        hasStaffTrainingDataProtection: vettingInformation?.dataProtection?.hasStaffTraining,
                        ensuresSuppliersImplementDataProtection:
                            vettingInformation?.dataProtection?.ensuresSuppliersImplementDataProtection,
                        hasTradeAssociationMembership:
                            vettingInformation?.dataProtection?.hasTradeAssociationMembership,
                        documentsPersonalData: vettingInformation?.dataProtection?.documentsPersonalData,

                        hasPublicSectorFrameworkAgreement:
                            vettingInformation?.publicSector?.hasPublicSectorFrameworkAgreement,
                        frameworkAgreementDetails: vettingInformation?.publicSector?.frameworkAgreementDetails ?? "",

                        hasDiversityAndInclusionPolicy: vettingInformation?.equality?.hasDiversityAndInclusionPolicy,
                        hasResponsiblePersonEquality: vettingInformation?.equality?.hasResponsiblePerson,
                        hasCommitmentToAntiDiscrimination:
                            vettingInformation?.equality?.hasCommitmentToAntiDiscrimination,
                        identifiesIncidentsOfDiscrimination:
                            vettingInformation?.equality?.identifiesIncidentsOfDiscrimination,
                        allWorkersPaidFairly: vettingInformation?.equality?.allWorkersPaidFairly,
                        hasUnlawfulDiscriminationFindings:
                            vettingInformation?.equality?.hasUnlawfulDiscriminationFindings,
                        findingsDetails: vettingInformation?.equality?.findingsDetails ?? "",
                        promoteGoodPracticeForAntiDiscrimination:
                            vettingInformation?.equality?.promoteGoodPracticeForAntiDiscrimination,
                        hasHumanRightsPolicy: vettingInformation?.equality?.hasHumanRightsPolicy,

                        hasCompliancePolicy: vettingInformation?.standards?.hasCompliancePolicy,
                        complianceStatement: vettingInformation?.standards?.complianceStatement ?? "",
                        hasSocialResponsibilityPolicy: vettingInformation?.standards?.hasSocialResponsibilityPolicy,
                        hasModernSlaveryPolicy: vettingInformation?.standards?.hasModernSlaveryPolicy,
                        hasIdentifiedModernSlaveryRisks: vettingInformation?.standards?.hasIdentifiedModernSlaveryRisks,
                        hasSupplierTermsAndConditions: vettingInformation?.standards?.hasSupplierTermsAndConditions,
                        hasWhistleblowingPolicy: vettingInformation?.standards?.hasWhistleblowingPolicy,
                        hasStaffTraining: vettingInformation?.standards?.hasStaffTraining,
                        hasAntiMoneyLaunderingPolicy: vettingInformation?.standards?.hasAntiMoneyLaunderingPolicy,
                        hasAntiSlaveryAndHumanTraffickingPolicy:
                            vettingInformation?.standards?.hasAntiSlaveryAndHumanTraffickingPolicy,

                        hasResponsiblePersonQuality: vettingInformation?.quality?.hasResponsiblePerson,
                        personResponsible: vettingInformation?.quality?.personResponsible ?? "",
                        registeredWithConstructionIndustryScheme:
                            vettingInformation?.quality?.registeredWithConstructionIndustryScheme,
                        registeredAsGrossPaymentStatus: vettingInformation?.quality?.registeredAsGrossPaymentStatus,
                    } as IVettingQuestionnaireForm
                }
                onSubmit={(values) => onSubmit(values)}
                validationSchema={companyDetailsSectionValidationSchema()
                    .concat(esgValidationSchema(t))
                    .concat(dataAndSecurityValidationSchema(t))
                    .concat(equalityValidationSchema())
                    .concat(standardsValidationSchema())
                    .concat(qualityValidationSchema())
                    .concat(frameworkAgreementsValidationSchema())}
            >
                {({ dirty }) => {
                    return (
                        <>
                            <Form
                                className={classNames(classes.marginTop, classes.maxWidth, classes.form)}
                                id="company-vetting-form"
                            >
                                <VettingNavigation
                                    companyUuid={companyUuid ?? ""}
                                    environmentPolicyFiles={environmentPolicyFiles}
                                    healthAndSafetyPolicyFiles={healthAndSafetyPolicyFiles}
                                    drugAndAlcoholPolicyFiles={drugAndAlcoholPolicyFiles}
                                    diversityAndInclusionPolicyFiles={diversityAndInclusionPolicyFiles}
                                    briberyPolicyFiles={briberyPolicyFiles}
                                    socialResponsibilityPolicyFiles={socialResponsibilityPolicyFiles}
                                    modernSlaveryPolicyFiles={modernSlaveryPolicyFiles}
                                    antiMoneyLaunderingPolicyFiles={antiMoneyLaunderingPolicyFiles}
                                    cyberSecurityPlanFiles={cyberSecurityPlanFiles}
                                    disasterRecoveryPlanFiles={disasterRecoveryPlanFiles}
                                    dataProtectionBreachesFiles={dataProtectionBreachesFiles}
                                    dataProtectionPolicyFiles={dataProtectionPolicyFiles}
                                    externalAuditFiles={externalAuditFiles}
                                    insurances={companyInsurances}
                                    accreditations={companyAccreditations}
                                    memberships={memberships}
                                />
                                <CompanyDetailsSection
                                    companyInsurances={companyInsurances}
                                    setCompanyInsurances={setCompanyInsurances}
                                />
                                <EnvironmentalSocialAndGovernanceSection
                                    environmentPolicyFiles={environmentPolicyFiles}
                                    setEnvironmentPolicyFiles={setEnvironmentPolicyFiles}
                                    healthAndSafetyPolicyFiles={healthAndSafetyPolicyFiles}
                                    setHealthAndSafetyPolicyFiles={setHealthAndSafetyPolicyFiles}
                                    drugAndAlcoholPolicyFiles={drugAndAlcoholPolicyFiles}
                                    setDrugAndAlcoholPolicyFiles={setDrugAndAlcoholPolicyFiles}
                                />
                                <EthicsSection
                                    diversityAndInclusionPolicyFiles={diversityAndInclusionPolicyFiles}
                                    setDiversityAndInclusionPolicyFiles={setDiversityAndInclusionPolicyFiles}
                                    briberyPolicyFiles={briberyPolicyFiles}
                                    setBriberyPolicyFiles={setBriberyPolicyFiles}
                                    socialResponsibilityPolicyFiles={socialResponsibilityPolicyFiles}
                                    setSocialResponsibilityPolicyFiles={setSocialResponsibilityPolicyFiles}
                                    modernSlaveryPolicyFiles={modernSlaveryPolicyFiles}
                                    setModernSlaveryPolicyFiles={setModernSlaveryPolicyFiles}
                                    antiMoneyLaunderingPolicyFiles={antiMoneyLaunderingPolicyFiles}
                                    setAntiMoneyLaunderingPolicyFiles={setAntiMoneyLaunderingPolicyFiles}
                                    companyAccreditations={companyAccreditations}
                                    setCompanyAccreditations={setCompanyAccreditations}
                                />
                                <DataAndSecuritySection
                                    cyberSecurityPlanFiles={cyberSecurityPlanFiles}
                                    dataProtectionBreachesFiles={dataProtectionBreachesFiles}
                                    dataProtectionPolicyFiles={dataProtectionPolicyFiles}
                                    disasterRecoveryPlanFiles={disasterRecoveryPlanFiles}
                                    externalAuditFiles={externalAuditFiles}
                                    setCyberSecurityPlanFiles={setCyberSecurityPlanFiles}
                                    setDataProtectionBreachesFiles={setDataProtectionBreachesFiles}
                                    setDataProtectionPolicyFiles={setDataProtectionPolicyFiles}
                                    setDisasterRecoveryPlanFiles={setDisasterRecoveryPlanFiles}
                                    setExternalAuditFiles={setExternalAuditFiles}
                                    memberships={memberships}
                                    setMemberships={setMemberships}
                                />
                                <FrameworkAgreementsSection />
                            </Form>
                            <Prompt when={dirty} />
                        </>
                    );
                }}
            </Formik>
            <BackToTop
                label={t("common.backTo", {
                    options: {
                        page: t("common.top"),
                    },
                })}
                hideMobile
            />
        </>
    );
};

export default CompanyVettingQuestionnaireContainer;
