import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Button, Card, Copy, Grid, Popup } from "@maistro/components";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import AddOrUpdateSectionCard from "features/project/tenderCriteria/sections/AddOrUpdateSectionCard";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import stringService from "services/stringService";
import { ITheme } from "styles/themes/types";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import SectionType from "types/enums/questions/sections/SectionType";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

const useStyles = createUseStyles((theme: ITheme) => ({
    copy: {
        marginBottom: theme.spacing.xLarge,
    },
    buttons: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: theme.spacing.xLarge,
    },
}));

export interface ISectionCard {
    sectionType: SectionType;
    title: string;
    text: string;
    icon: IconProp;
    isSelected: boolean;
    previousDescription: string;
    isNewSection?: boolean;
    isEditing?: boolean;
    orderIndex?: number;
}

interface ISectionSelectionDisplayProps {
    canContinue: boolean;
    sections: ISectionCard[];
    isAddingSection: boolean;
    setIsAddingSection: Dispatch<SetStateAction<boolean>>;
    updateSelectedSections: () => Promise<void>;
    selectSection: (selectedSection: ISectionCard) => void;
    deleteSection: (selectedSection: ISectionCard) => void;
    addSection: (selectedSection: ISectionCard) => void;
    updateSection: (selectedSection: ISectionCard) => void;
}

const SectionSelectionDisplay: React.FC<ISectionSelectionDisplayProps> = (props) => {
    const classes = useStyles();

    const {
        canContinue,
        sections,
        isAddingSection,
        setIsAddingSection,
        updateSelectedSections,
        selectSection,
        deleteSection,
        addSection,
        updateSection,
    } = props;

    const [sectionForDeletion, setSectionForDeletion] = useState<ISectionCard | undefined>();
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [isSectionEditing, setIsSectionEditing] = useState(false);

    const { t } = useTranslation();
    const { userHasPermission } = useCurrentUser();

    const closeSection = (selectedSection: ISectionCard) => {
        setSectionForDeletion(selectedSection);
        setIsDeletePopupOpen(true);
    };

    const editSection = (selectedSection: ISectionCard) => {
        if (!selectedSection.isNewSection) {
            selectedSection.previousDescription = selectedSection.title;
        }
        selectedSection.isEditing = true;
        setIsSectionEditing(true);
    };

    const onCloseEditing = (selectedSection: ISectionCard) => {
        if (selectedSection.previousDescription === selectedSection.title) {
            selectedSection.previousDescription = "";
        }
        selectedSection.isEditing = undefined;
        setIsSectionEditing(false);
    };

    const onDeleteSection = () => {
        if (!sectionForDeletion) return;

        deleteSection(sectionForDeletion);

        setIsDeletePopupOpen(false);
        setSectionForDeletion(undefined);
    };

    if (!sections) return null;

    return (
        <React.Fragment>
            <Copy className={classes.copy}>{t("tenderCriteria.sections.selection.subTitle")}</Copy>
            <Grid columns={3} width={340} centered testid="select-sections-grid">
                <React.Fragment>
                    {sections.map((section) =>
                        !section.isEditing ? (
                            <Card
                                key={section.title}
                                title={section.title}
                                text={section.text}
                                isActive={section.isSelected}
                                icon={section.icon}
                                disabled={!userHasPermission(PermissionsEnum.UpdateScoringCriteria)}
                                canClose={section.sectionType === SectionType.Other}
                                canEdit={section.sectionType === SectionType.Other}
                                onClose={() => closeSection(section)}
                                onEdit={() => editSection(section)}
                                onClick={() => selectSection(section)}
                                testid={`select-sections-card-${stringService.toKebabCase(section.title)}`}
                            />
                        ) : (
                            <AddOrUpdateSectionCard
                                key={section.title}
                                sections={sections}
                                editingSection={section}
                                isEditing={isSectionEditing}
                                closeEditing={() => onCloseEditing(section)}
                                isAddingSection={isAddingSection}
                                setIsAddingSection={setIsAddingSection}
                                updateSection={updateSection}
                            />
                        ),
                    )}
                    <AddOrUpdateSectionCard
                        sections={sections}
                        isAddingSection={isAddingSection}
                        setIsAddingSection={setIsAddingSection}
                        addSection={addSection}
                    />
                </React.Fragment>
            </Grid>
            <div className={classes.buttons}>
                <Button
                    className="hidden-mobile"
                    label={t("tenderCriteria.sections.selection.confirmDesktop")}
                    size="large"
                    chevron
                    disabled={canContinue}
                    onClick={() => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: `Tender Criteria - Confirm Selection`,
                        });
                        updateSelectedSections();
                    }}
                    testid="confirm-selection-button"
                />
            </div>
            <Popup
                title={t("popups.deleteSection.title")}
                message={t("popups.deleteSection.message", { sectionName: sectionForDeletion?.title })}
                isOpen={isDeletePopupOpen}
                primaryActionText={t("popups.deleteSection.cta.primary")}
                onPrimaryAction={onDeleteSection}
                secondaryActionText={t("common.cancel")}
                onSecondaryAction={() => setIsDeletePopupOpen(false)}
                onClose={() => setIsDeletePopupOpen(false)}
                testid="cancel-answer-popup"
            />
        </React.Fragment>
    );
};

export default SectionSelectionDisplay;
