import { Copy, Grid } from "@maistro/components";
import { IOptionProps } from "components/shared";
import { t } from "i18next";
import React from "react";
import ReactGA from "react-ga4";
import { createUseStyles } from "react-jss";
import { ITheme } from "styles/themes/types";
import { PostalAddressDto } from "types/dtos/company/postal-addresses/PostalAddressDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    copy: {
        marginBottom: theme.spacing.xLarge,
    },

    header: {
        flexDirection: "column",
        gap: theme.spacing.small,
        marginBottom: theme.spacing.small,
    },
    footer: {
        marginTop: theme.spacing.small,
    },
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        header: {
            flexDirection: "row",
        },
    },
    addressCard: {
        display: "grid",
        position: "relative",
        flexDirection: "column",
        backgroundColor: theme.colors.white,
        width: "100%",
        borderRadius: theme.spacing.xSmall,
        padding: `${theme.spacing.medium}px ${theme.spacing.large}px`,
    },
    value: {
        minWidth: "110px",
        marginRight: theme.spacing.small,
        fontWeight: 600,
        fontSize: 14,
        wordBreak: "break-word",
    },
    cardControl: {
        cursor: "pointer",
        gap: theme.spacing.xSmall,

        "&:hover": {
            color: theme.colors.highlight,
        },
    },
}));

interface IAddressesDisplayProps {
    addresses: PostalAddressDto[];
    countries: IOptionProps[];
    setSelectedAddress: React.Dispatch<React.SetStateAction<PostalAddressDto | undefined>>;
    setIsAddressDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsRemoveAddressPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompanyAddressesDisplay: React.FC<IAddressesDisplayProps> = (props) => {
    const classes = useStyles();

    const { addresses, countries, setSelectedAddress, setIsAddressDrawerOpen, setIsRemoveAddressPopupOpen } = props;

    const renderAddressCard = (address: PostalAddressDto) => {
        return (
            <div key={address.uuid} className={classes.addressCard}>
                <div className={classes.header}>
                    {!address.isPrimaryAddress && !address.isInvoicingAddress && t("addresses.address")}
                    {address.isPrimaryAddress && <span>{t("addresses.primaryAddress")}</span>}
                    {!address.isPrimaryAddress && address.isInvoicingAddress && (
                        <span>{t("addresses.invoiceAddress")}</span>
                    )}
                </div>
                <span className={classes.value}>{address.streetAddress}</span>
                <span className={classes.value}>{address.city}</span>
                <span className={classes.value}>{address.county}</span>
                <span className={classes.value}>
                    {/* Doesn't work without .toString() as it's a number, even though it's supposed to be one... */}
                    {/* Probably needs to be returned from the server as a string (Ben's idea) */}
                    {countries.find((country) => country.value === address.countryTypeId.toString())?.label}
                </span>
                <span className={classes.value}>{address.postcode}</span>
                <div className={classes.footer}>
                    <span
                        className={classes.cardControl}
                        onClick={() => {
                            ReactGA.event({
                                category: ga4Category.Button,
                                action: ga4Action.ButtonClick,
                                label: `Company Address - Edit Address`,
                            });
                            setSelectedAddress(address);
                            setIsAddressDrawerOpen(true);
                        }}
                        data-testid="open-edit-drawer-button"
                    >
                        {t("common.edit")}
                    </span>
                    {!address.isPrimaryAddress && (
                        <>
                            {" | "}
                            <span
                                className={classes.cardControl}
                                onClick={() => {
                                    ReactGA.event({
                                        category: ga4Category.Button,
                                        action: ga4Action.ButtonClick,
                                        label: `Company Address - Delete Address`,
                                    });
                                    setSelectedAddress(address);
                                    setIsRemoveAddressPopupOpen(true);
                                }}
                                data-testid="open-delete-popup-button"
                            >
                                {t("common.delete")}
                            </span>
                        </>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className={classes.copy}>
                <Copy>{t("addresses.subtitle")}</Copy>
            </div>

            <Grid columns={2} testid="address-grid">
                <React.Fragment>
                    {addresses && addresses.map(renderAddressCard)}
                    <div></div>
                </React.Fragment>
            </Grid>
        </>
    );
};

export default CompanyAddressesDisplay;
