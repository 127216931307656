import { useToaster } from "@maistro/components";
import { getProject } from "api/projectApi";
import { addProjectUser, getProjectUsers, removeProjectUser, updateProjectUser } from "api/projectSettingsApi";
import { listRoles } from "api/rolesPermissionsApi";
import useAppDispatch from "hooks/useAppDispatch";
import useCurrentUser from "hooks/useCurrentUser";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import apiErrorService from "services/apiErrorService";
import { useLazyListCompanyUsersQuery } from "store/api/companyUsersApi";
import { fetchUserPermissions } from "store/rolesPermissionsSlice";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import ValidationErrorsDto from "types/dtos/ValidationErrorsDto";
import { CompanyUserDto } from "types/dtos/company/CompanyUserDto";
import { ProjectResponseDto } from "types/dtos/projects/ProjectResponseDto";
import { ProjectUserDto } from "types/dtos/projects/users/ProjectUserDto";
import { UpdateProjectUserDto } from "types/dtos/projects/users/UpdateProjectUserDto";
import { RoleDto } from "types/dtos/rolesPermissions/RoleDto";
import RolesEnum from "types/enums/rolesPermissions/RolesEnum";

const useProjectSettings = (projectUuid?: string) => {
    const [projectInformation, setProjectInformation] = useState<ProjectResponseDto>();
    const [projectUsers, setProjectUsers] = useState<Array<ProjectUserDto>>([]);
    const [companyUsers, setCompanyUsers] = useState<Array<CompanyUserDto>>([]);
    const [availableRoles, setAvailableRoles] = useState<Array<RoleDto>>([]);
    const [searchText, setSearchText] = useState<string>();

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const toast = useToaster();
    const [listCompanyUsers] = useLazyListCompanyUsersQuery();
    const { myUuid } = useCurrentUser();

    const fetchData = useCallback(async () => {
        if (!projectUuid) return;

        const projectResponse = await getProject(projectUuid);
        if (projectResponse.data instanceof TransactionErrorDto || projectResponse.status !== 200) {
            return;
        }
        setProjectInformation(projectResponse.data);

        const projectUsersResponse = await getProjectUsers(projectUuid);
        if (projectUsersResponse.data instanceof TransactionErrorDto || projectUsersResponse.status !== 200) {
            return;
        }
        const currentUsers = projectUsersResponse.data.projectUsers;
        setProjectUsers(currentUsers);

        listCompanyUsers(
            { searchQuery: searchText, companyUuid: projectResponse.data._embedded.buyerCompanyUuid },
            true,
        )
            .unwrap()
            .then((response) => {
                const userList = response.items.filter(
                    (user) => currentUsers.find((currentUser) => currentUser.userUuid === user.userUuid) == null,
                );
                setCompanyUsers(userList);
            })
            .catch(() => setCompanyUsers([]));

        const rolesResponse = await listRoles({ queryParameters: {} });
        if (rolesResponse.data instanceof TransactionErrorDto || rolesResponse.status !== 200) {
            return;
        }

        const allowedProjectRoles = [RolesEnum.ProjectContributor, RolesEnum.ProjectManager, RolesEnum.BuyerReadOnly];
        const roles = rolesResponse.data.items.filter((r) => allowedProjectRoles.find((pr) => pr === r.name) != null);
        setAvailableRoles(roles);
    }, [projectUuid, listCompanyUsers, searchText]);

    const addUser = useCallback(
        async (userUuid: string) => {
            if (!projectUuid) return;

            const addUserResponse = await addProjectUser(projectUuid, userUuid);
            if (addUserResponse.data instanceof TransactionErrorDto || addUserResponse.status !== 200) {
                toast.error(t("projectSettings.api.generalError"));
                return;
            }

            toast.success(t("projectSettings.api.addUserSuccess"));

            if (userUuid === myUuid) {
                dispatch(fetchUserPermissions({ userUuid: myUuid }));
            }

            await fetchData();
        },
        [fetchData, projectUuid, t, toast, dispatch, myUuid],
    );

    const editUser = useCallback(
        async (dto: UpdateProjectUserDto) => {
            if (!projectUuid) return;

            const updateUserResponse = await updateProjectUser({
                projectRoleUuid: dto.projectRoleUuid,
                projectUuid,
                userUuid: dto.userUuid,
            });
            if (
                updateUserResponse.data instanceof TransactionErrorDto ||
                updateUserResponse.data instanceof ValidationErrorsDto
            ) {
                toast.error(apiErrorService.getFirstErrorFromResponse(updateUserResponse.data));
                return;
            }
            if (updateUserResponse.status !== 200) {
                toast.error(t("projectSettings.api.generalError"));
                return;
            }

            toast.success(t("projectSettings.api.editUserSuccess"));

            if (dto.userUuid === myUuid) {
                dispatch(fetchUserPermissions({ userUuid: myUuid }));
            }

            await fetchData();
        },
        [fetchData, projectUuid, t, toast, dispatch, myUuid],
    );

    const removeUser = useCallback(
        async (userUuid: string) => {
            if (!projectUuid) return;

            if (projectUsers.length <= 1) {
                toast.error(t("projectSettings.oneUserRequired"));
                return;
            }

            await removeProjectUser(projectUuid, userUuid).then(() =>
                toast.success(t("projectSettings.api.deleteUserSuccess")),
            );
            await fetchData();
        },
        [fetchData, projectUsers, t, toast, projectUuid],
    );

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return {
        addUser,
        availableRoles,
        companyUsers,
        editUser,
        getProjectUsers: fetchData,
        projectInformation,
        projectUsers,
        removeUser,
        setSearchText,
    };
};

export default useProjectSettings;
