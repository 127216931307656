import { LoadingScreen, useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { setScorerScoringComplete } from "api/questions/tenderCriteriaQuestionsApi";
import ProjectPreviewDrawer from "features/project/components/ProjectPreviewDrawer";
import useNavTabs from "features/project/hooks/useNavTabs";
import useProject from "features/project/hooks/useProject";
import useProjectFiles from "features/project/hooks/useProjectFiles";
import { useTenderCriteria } from "features/project/hooks/useTenderCriteria";
import { useLayout } from "features/project/tenderCriteria/hooks/useLayout";
import { useTenderPermissions } from "features/project/tenderCriteria/hooks/useTenderPermissions";
import ScoreTenderQuestionDisplay from "features/project/tenderCriteria/scoring/ScoreTenderQuestionDisplay";
import useAppDispatch from "hooks/useAppDispatch";
import useCurrentUser from "hooks/useCurrentUser";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { resetLayout } from "store/layoutSlice";
import { setTenderScoringComplete } from "store/projectSlice";
import { ScoreResponseDto } from "types/dtos/questions/ScoreResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

interface ITenderQuestionProps {
    questionUuid: string;
}

const useDownloadHandler = (downloadFunction: () => Promise<void>) => {
    const [isDownloading, setIsDownloading] = useState(false);

    const handleDownload = useCallback(() => {
        setIsDownloading(true);
        downloadFunction().finally(() => setIsDownloading(false));
    }, [downloadFunction]);

    return { isDownloading, handleDownload };
};

const LoadingOverlay: React.FC<{ isVisible: boolean; title: string; message: string }> = ({
    isVisible,
    title,
    message,
}) => {
    if (!isVisible) return null;

    return <LoadingScreen title={title} message={message} duration={35000} testid="loading-screen" />;
};

const ScoreTenderQuestionContainer: React.FC = () => {
    const params = useParams();
    const { questionUuid } = params as unknown as ITenderQuestionProps;

    const [isSubmittingScores, setIsSubmittingScores] = useState(false);
    const [isProjectPreviewOpen, setIsProjectPreviewOpen] = useState(false);

    const { t } = useTranslation();
    const toast = useToaster();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { myUuid: userUuid, userHasProjectPermission } = useCurrentUser();
    const { projectInformation } = useProject();
    const userIsProjectManager = userUuid === projectInformation.managerUuid;
    const { projectFiles } = useProjectFiles(projectInformation.uuid);
    const {
        questions,
        setQuestions,
        sections,
        completedContributorScorers,
        totalContributorScorers,
        isScoringCompleteForUser,
        isScoringCompleteForProject,
        downloadTenderResponsesReport,
    } = useTenderCriteria({
        projectUuid: projectInformation.uuid,
    });
    const isScoringComplete = isScoringCompleteForUser || isScoringCompleteForProject;
    const { canAskQuestion, canScoreAnswers, canModerateScores } = useTenderPermissions({
        isScoringComplete,
        projectInformation,
        userHasProjectPermission,
    });
    const { buyerTabItems } = useNavTabs();
    const { isDownloading, handleDownload } = useDownloadHandler(downloadTenderResponsesReport);

    const { setLayout } = useLayout({
        canModerateScores,
        canAskQuestion,
        projectInformation,
        handleDownload,
        setIsProjectPreviewOpen,
        backTo: "tenderSummary",
    });

    useEffect(() => {
        setLayout();
        return () => {
            dispatch(resetLayout());
        };
    }, [buyerTabItems, canAskQuestion, canModerateScores, dispatch, handleDownload, projectInformation, setLayout, t]);

    const setScore = useCallback(
        (score: ScoreResponseDto) => {
            setQuestions(score, questionUuid);
        },
        [questionUuid, setQuestions],
    );

    const submitScores = async () => {
        setIsSubmittingScores(true);
        const response = await setScorerScoringComplete(projectInformation.uuid, userUuid);

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("tenderCriteria.score.api.submitScoresError"));
            setIsSubmittingScores(false);
            return;
        }

        dispatch(
            setTenderScoringComplete({
                isProjectManager: userIsProjectManager,
            }),
        );
        toast.success(t("tenderCriteria.score.api.submitScoresSuccess"));
        navigate(buildPath(routes.projects.tenderSummary, { projectUuid: projectInformation.uuid }));
    };

    return (
        <>
            <LoadingOverlay
                isVisible={isDownloading}
                title={t("tenderCriteria.score.export.title")}
                message={t("tenderCriteria.score.export.message")}
            />
            <ScoreTenderQuestionDisplay
                projectUuid={projectInformation.uuid}
                questionUuid={questionUuid}
                questions={questions}
                sections={sections}
                completedContributorScorers={completedContributorScorers}
                totalContributorScorers={totalContributorScorers}
                isProjectManager={userIsProjectManager}
                setScore={setScore}
                submitScores={submitScores}
                userUuid={userUuid}
                isSubmittingScores={isSubmittingScores}
                canScoreAnswers={canScoreAnswers}
                canModerateScores={canModerateScores}
            />
            <ProjectPreviewDrawer
                projectFiles={projectFiles}
                isOpen={isProjectPreviewOpen}
                close={() => setIsProjectPreviewOpen(false)}
            />
        </>
    );
};

export default ScoreTenderQuestionContainer;
