import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Button, ICommonProps } from "@maistro/components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Draggable, Droppable } from "@hello-pangea/dnd";
import QuestionRow from "features/project/components/tender-questions/QuestionRow";
import { containsMandatoryQuestions } from "features/project/helpers/questionHelpers";
import { getSectionTitle } from "features/project/helpers/sectionHelpers";
import useQuestionStyles from "features/project/shared/tender-questions/useQuestionStyles";
import SectionRow from "features/project/tenderCriteria/components/SectionRow/SectionRow";
import ReactGA from "react-ga4";
import { ISectionQuestions } from "types/dtos/questions/ISectionQuestions";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

interface ISectionRowContainerProps extends ICommonProps {
    section: SectionDto;
    questions?: ISectionQuestions;
    startExpanded: boolean;
    isDnDActive?: boolean;
    addQuestion: (sectionId: string) => void;
    editQuestion: (sectionId: string, question: QuestionResponseDto) => void;
    deleteQuestion: (sectionId: string, question: QuestionResponseDto) => void;
    isActionButtonsRendering?: boolean;
}

const SectionRowContainer: React.FC<ISectionRowContainerProps> = (props) => {
    const questionClasses = useQuestionStyles();

    const { section, questions, addQuestion, editQuestion, deleteQuestion, isActionButtonsRendering } = props;

    const [isExpanded, setIsExpanded] = useState(false);

    const { t } = useTranslation();

    const sectionId = section.id;
    useEffect(() => {
        setIsExpanded(props.startExpanded);
    }, [props.startExpanded]);

    const areQuestionsLoading = () => {
        if (!questions) {
            return true;
        }
        return questions.isLoading;
    };

    const renderQuestionRow = (q: QuestionResponseDto) => (
        <QuestionRow
            testid={`question-${q.questionUuid}`}
            className={questionClasses.questionRow}
            questionText={q.questionText}
            disabled={areQuestionsLoading()}
            hasAttachments={q.hasAttachments}
            onDelete={() => {
                ReactGA.event({
                    category: ga4Category.Button,
                    action: ga4Action.ButtonClick,
                    label: `Tender Questions - Delete Question - ${q.questionText}`,
                });
                deleteQuestion(sectionId, q);
            }}
            onEdit={() => {
                ReactGA.event({
                    category: ga4Category.Button,
                    action: ga4Action.ButtonClick,
                    label: `Tender Questions - Edit Question - ${q.questionText}`,
                });
                editQuestion(sectionId, q);
            }}
            hideActionIcons={isActionButtonsRendering}
        />
    );

    const renderDnDQuestionsForSection = () => {
        const sectionQuestions = questions ? questions.items : [];
        return sectionQuestions.map((q, index) => (
            <Draggable key={q.questionUuid} draggableId={q.questionUuid} index={index}>
                {(draggableProvided) => (
                    <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        className={questionClasses.questionContainer}
                    >
                        {renderQuestionRow(q)}
                    </div>
                )}
            </Draggable>
        ));
    };

    return (
        <div className={questionClasses.flexContainer}>
            <SectionRow
                testid={`section-${sectionId}`}
                className={questionClasses.sectionRow}
                title={getSectionTitle(section)}
                totalQuestions={questions?.items.length ?? 0}
                weighting={section.weighting ?? 0}
                hasMandatoryQuestions={containsMandatoryQuestions(questions)}
                isLoading={areQuestionsLoading()}
                expanded={isExpanded}
                onToggleExpand={setIsExpanded}
            >
                <Droppable droppableId={sectionId} type="question">
                    {(droppableProvided) => (
                        <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                            {isExpanded && renderDnDQuestionsForSection()}
                            {droppableProvided.placeholder}
                        </div>
                    )}
                </Droppable>
            </SectionRow>
            {!isActionButtonsRendering && (
                <Button
                    className={questionClasses.button}
                    color="dark"
                    label={t("tenderQuestions.addQuestion")}
                    icon={faPlus}
                    onClick={() => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: `Tender Questions - Add Question - ${getSectionTitle(section)}`,
                        });
                        setIsExpanded(true);
                        addQuestion(sectionId);
                    }}
                    testid={`add-question-to-${sectionId}`}
                />
            )}
        </div>
    );
};

export default SectionRowContainer;
