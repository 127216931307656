import { Copy, Heading, LoadingScreen, Popup, useToaster } from "@maistro/components";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate, useParams } from "react-router-dom";

import { generateProjectBrief } from "api/aiAssistApi";
import loadingImage from "assets/images/aiAssistLoadingScreen.png";
import { Loader } from "components";
import TitleCtaType from "components/Layout/types/TitleCtaType";
import GenerateProjectDescriptionForm from "features/project/generateProjectDescription/GenerateProjectDescriptionForm";
import useGuidedQuestions from "features/project/hooks/useGuidedQuestions";
import useProjectBrief from "features/project/projectBrief/hooks/useProjectBrief";
import useAppDispatch from "hooks/useAppDispatch";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { resetLayout, setBack, setPageTitle, setTitleCtas } from "store/layoutSlice";
import { ITheme } from "styles/themes/types";
import { GuidedQuestionAnswersDto } from "types/dtos/projects/aiAssist/GuidedQuestionAnswersDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    xSmallPaddingTop: {
        margin: `${theme.spacing.xSmall}px 0`,
    },
    smallPaddingTop: {
        margin: `${theme.spacing.small}px 0`,
    },
    xLargePaddingTop: {
        margin: `${theme.spacing.xLarge}px 0`,
    },
    bold: {
        fontWeight: "bold",
    },
}));

const GenerateProjectDescriptionContainer: React.FC = () => {
    const classes = useStyles();

    const { projectUuid } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const toast = useToaster();

    const [isDisclaimerPopupOpen, setIsDisclaimerPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isGenerating, setIsGenerating] = useState(false);
    const [generatingFinished, setGeneratingFinished] = useState(false);
    const [duration, setDuration] = useState(40000);
    const [answers, setAnswers] = useState<GuidedQuestionAnswersDto[]>([]);

    const { guidedQuestions } = useGuidedQuestions(projectUuid, setIsLoading);
    const { createOrUpdateProject, projectInformation } = useProjectBrief({
        projectUuid,
        includeRemovedCategories: true,
        setIsLoading,
    });

    useEffect(() => {
        dispatch(resetLayout());
        dispatch(setPageTitle(t("generateProjectDescription.pageTitle")));
        dispatch(setBack(null));
        dispatch(
            setTitleCtas([
                {
                    type: TitleCtaType.Cancel,
                    onClick: () => navigate(buildPath(routes.projects.projectBrief, { projectUuid })),
                },
            ]),
        );

        return () => {
            dispatch(setTitleCtas(null));
        };
    }, [t, dispatch, projectUuid, navigate]);

    useEffect(() => {
        if (!isGenerating && generatingFinished) {
            navigate(buildPath(routes.projects.projectBrief, { projectUuid }));
            toast.success(t("generateProjectDescription.api.generateDescriptionSuccess"));
        }
    }, [generatingFinished, isGenerating, navigate, projectUuid, t, toast]);

    const generateBrief = () => {
        if (projectUuid) {
            setIsGenerating(true);
            setIsDisclaimerPopupOpen(false);
            generateProjectBrief({ projectUuid, answers })
                .then((response) => {
                    if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                        setIsGenerating(false);
                        toast.error(t("generateProjectDescription.api.generateDescriptionError"));
                        return;
                    }

                    const { generatedText } = response.data;
                    createOrUpdateProject({ ...projectInformation, description: generatedText }, { description: true })
                        .then(() => {
                            // Finishes progress bar and *should* display final message
                            setDuration(0);
                            // UX pause to prevent instant navigation
                            setTimeout(() => {
                                setIsGenerating(false);
                                setGeneratingFinished(true);
                            }, 1000);
                        })
                        .catch(() => {
                            setIsGenerating(false);
                            toast.error(t("generateProjectDescription.api.updateDescriptionError"));
                        });
                })
                .catch(() => {
                    setIsGenerating(false);
                    toast.error(t("generateProjectDescription.api.generateDescriptionError"));
                });
        }
    };

    if (isLoading) return <Loader />;
    if (!guidedQuestions) return null;

    return (
        <React.Fragment>
            {isGenerating && (
                <LoadingScreen
                    title={t("generateProjectDescription.loadingTitle")}
                    message={[
                        t("generateProjectDescription.loadingMessages.messageOne"),
                        t("generateProjectDescription.loadingMessages.messageTwo"),
                        t("generateProjectDescription.loadingMessages.messageThree"),
                        t("generateProjectDescription.loadingMessages.messageFour"),
                        t("generateProjectDescription.loadingMessages.messageFive"),
                    ]}
                    duration={duration}
                    image={loadingImage}
                    hold
                    continuous
                    testid="loading-screen"
                />
            )}

            <Heading className={classNames(classes.xSmallPaddingTop, classes.bold)} variant="h3">
                {t("generateProjectDescription.subTitle")}
            </Heading>
            <Copy className={classes.smallPaddingTop}>{t("generateProjectDescription.subText")}</Copy>
            <div className={classes.xLargePaddingTop}>
                <GenerateProjectDescriptionForm
                    setIsDisclaimerPopupOpen={setIsDisclaimerPopupOpen}
                    guidedQuestions={guidedQuestions}
                    setAnswers={setAnswers}
                />
            </div>
            <Popup
                title={t("popups.generateProjectDescription.title")}
                message={
                    <>
                        <span className={classes.bold}>{t("popups.generateProjectDescription.message1")}</span>{" "}
                        {t("popups.generateProjectDescription.message2")}
                    </>
                }
                isOpen={isDisclaimerPopupOpen}
                primaryActionText={t("popups.generateProjectDescription.cta.primary")}
                onPrimaryAction={() => {
                    generateBrief();
                }}
                disabled={isGenerating}
                secondaryActionText={t("popups.generateProjectDescription.cta.secondary")}
                onSecondaryAction={() => setIsDisclaimerPopupOpen(false)}
                onClose={() => setIsDisclaimerPopupOpen(false)}
                testid="generate-project-description-popup"
            />
        </React.Fragment>
    );
};

export default GenerateProjectDescriptionContainer;
