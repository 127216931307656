import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { SearchBar } from "components";
import useDebouncedEffect from "hooks/useDebouncedEffect";
import { ITheme } from "styles/themes/types";
import IAdminUserSearchFilters from "features/settings/admin-users/filter/IAdminUserSearchFilters";

const useStyles = createUseStyles((theme: ITheme) => ({
    searchBar: {
        display: "flex",
    },
    searchBarContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        gap: theme.spacing.medium,
        marginBottom: theme.spacing.medium,
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.small,
    },
    marginTop: {
        marginTop: theme.spacing.small,
    },
}));

interface IAdminUsersSearchFiltersProps {
    currentFilters: IAdminUserSearchFilters;
    setFilterValues: (filterValues: IAdminUserSearchFilters) => void;
    isSearching: boolean;
    resetPage: () => void;
}

const AdminUserSearchFilters: React.FC<IAdminUsersSearchFiltersProps> = ({
    currentFilters,
    setFilterValues,
    isSearching,
    resetPage,
}) => {
    const classes = useStyles();

    const [currentSearchText, setCurrentSearchText] = useState("");

    const { t } = useTranslation();

    useDebouncedEffect(
        () => {
            if (currentFilters.searchText !== currentSearchText) {
                resetPage();
                setFilterValues({ ...currentFilters, searchText: currentSearchText });
            }
        },
        [currentSearchText],
        500,
    );

    useEffect(() => {
        setCurrentSearchText(currentFilters.searchText ?? "");
    }, [currentFilters.searchText]);

    return (
        <div className={classes.searchBarContainer}>
            <SearchBar
                isLoading={isSearching}
                className={classes.searchBar}
                placeholder={t("adminUsers.search")}
                value={currentSearchText}
                onChange={(e) => setCurrentSearchText(e.target.value)}
            />
        </div>
    );
};

export default AdminUserSearchFilters;
