enum CompanyBrandingType {
    AccentColour = "AccentColour",
    Logo = "Logo",
    BaseUri = "BaseUri",
    AccentBarWidth = "AccentBarWidth",
    LogoBackgroundColour = "LogoBackgroundColour",
    SquareLogo = "SquareLogo",
    SecondaryColour = "SecondaryColour",
    JoditLicence = "JoditLicence",
}

export default CompanyBrandingType;
