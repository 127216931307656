import { Route } from "routes/helpers/RoutesHelper";

interface ProjectParams {
    projectUuid: string;
}

const activateUser: Route = { path: "/account/activate" };
const supplierFeedback: Route = { path: "/feedback/email-surveys/supplier-feedback" };
const expressionOfInterest: Route<ProjectParams> = {
    path: "/expression-of-interest/:projectUuid",
};
const expressionOfInterestSuccess: Route<ProjectParams> = {
    path: "/expression-of-interest/:projectUuid/success",
};
const systemStatus: Route = { path: "/system-status" };
const callbackError: Route = { path: "/error?code=callback" };
const error: Route = { path: "/error" };
const callback: Route = { path: "/callback" };
const notFound: Route = { path: "*" };
const userGuide: Route = { path: "/user-guide" }; // used by invite user email

export const publicRoute: Route = { path: "/*", fullPath: "/*" };
export default {
    activateUser,
    supplierFeedback,
    expressionOfInterest,
    expressionOfInterestSuccess,
    systemStatus,
    callbackError,
    error,
    callback,
    notFound,
    userGuide,
};
