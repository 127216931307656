import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Copy, DirtyValues, Heading, Table, TextButton, Tooltip } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import { MaistroRating } from "components";
import { ICommonProps } from "components/shared";
import ProjectSummaryForm from "features/project/projectSummary/ProjectSummaryForm";
import { IProjectInformation } from "features/project/types";
import ReactGA from "react-ga4";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { ITheme } from "styles/themes/types";
import { ProjectSupplierDto } from "types/dtos/projectSuppliers/ProjectSupplierDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import ProjectType from "types/enums/projects/ProjectType";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
    tableContainer: {
        marginBottom: theme.spacing.small,
    },
    block: {
        display: "block",
    },
    alignRight: {
        textAlign: "right",
    },
    positionRelative: {
        position: "relative",
    },
    editIcon: {
        position: "absolute",
        right: theme.spacing.xLarge,
        zIndex: 1,
        color: theme.colors.primary,
    },
    previewContainer: {
        right: "10px",
        maxWidth: "200px",
    },
    previewIcon: {
        position: "absolute",
        right: "130px",
        color: theme.colors.primary,
    },
    previewText: {
        position: "absolute",
        right: theme.spacing.xLarge,
        color: theme.colors.primary,
    },
    paddingTop: {
        paddingTop: theme.spacing.small,
    },
}));

interface IProjectSummaryDisplayProps extends ICommonProps {
    projectInformation: IProjectInformation;
    identifiedSuppliers: Array<ProjectSupplierDto>;
    save: (values: IProjectInformation, dirtyValues: DirtyValues) => Promise<void>;
    submit: (values: IProjectInformation) => void;
    cancel?: () => void;
    disableSubmit?: boolean;
    projectUuid: string;
    removeSupplier: (supplierUuid: string) => void;
}

const ProjectSummaryDisplay: React.FC<IProjectSummaryDisplayProps> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        projectInformation,
        identifiedSuppliers,
        save,
        submit,
        cancel,
        disableSubmit,
        projectUuid,
        removeSupplier,
    } = props;

    const tProjType = projectInformation.type === ProjectType.Quote ? "quote" : "tender";

    const backToMatching = () => {
        navigate(buildPath(routes.projects.matching, { projectUuid }));
    };

    const columns = [
        {
            label: t("projectSummary.table.supplierName"),
            name: "companyName",
            priorityLevel: 1,
            position: 1,
            minWidth: 200,
            contentWrap: true,
            render: (supplier: ProjectSupplierDto) => (
                <>
                    <TextButton
                        label={supplier.companyName}
                        className={classes.block}
                        onClick={() => {
                            ReactGA.event({
                                category: ga4Category.Button,
                                action: ga4Action.ButtonClick,
                                label: `Tender Summary - Supplier - `.concat(supplier.companyName),
                            });
                            navigate(
                                buildPath(routes.company.supplierCompanyProfile, { companyUuid: supplier.companyUuid }),
                                {
                                    state: {
                                        route: buildRoute(routes.projects.summary, { projectUuid }),
                                    },
                                },
                            );
                        }}
                        testid={`${supplier.companyUuid}-name`}
                    />
                    <Tooltip
                        content={t("projectMatchingScreen.tooltips.matchingStars")}
                        testid={`${supplier.companyUuid}-rating-tooltip`}
                        hideIcon
                    >
                        <MaistroRating
                            currentRating={Math.ceil(supplier.matchScore * 5)}
                            testid={`${supplier.companyUuid}-match-rating`}
                        />
                    </Tooltip>
                </>
            ),
        },
        {
            name: "remove",
            priorityLevel: 2,
            position: 2,
            minWidth: 100,
            render: (supplier: ProjectSupplierDto) => (
                <div className={classes.alignRight}>
                    <TextButton
                        label={t("common.remove")}
                        onClick={() => {
                            ReactGA.event({
                                category: ga4Category.Button,
                                action: ga4Action.ButtonClick,
                                label: `Tender Summary - Remove Supplier - `.concat(supplier.companyName),
                            });
                            removeSupplier(supplier.companyUuid);
                        }}
                        testid={`${supplier.companyUuid}-remove-button`}
                        icon={faTimes}
                    />
                </div>
            ),
        },
    ];

    const noDataDisplay = () => (
        <Heading className={classes.paddingTop} variant="h5" testid="no-suppliers-text">
            <>
                {t("projectSummary.table.noSuppliers")}
                &nbsp;
                <TextButton
                    label={t("projectSummary.table.backToMatching")}
                    onClick={backToMatching}
                    testid="back-to-matching-button"
                />
            </>
        </Heading>
    );

    return (
        <div className={classes.container}>
            <Copy>{t(`projectSummary.${tProjType}.subTitle`)}</Copy>
            <Copy>{t(`projectSummary.${tProjType}.tableTitle`)}</Copy>
            <div className={classes.tableContainer}>
                <Table
                    columns={columns}
                    data={identifiedSuppliers}
                    noDataDisplay={noDataDisplay()}
                    testid="suppliers-table"
                />
            </div>

            <ProjectSummaryForm
                projectInformation={projectInformation}
                save={save}
                submit={submit}
                cancel={cancel}
                disableSubmit={disableSubmit}
            />
        </div>
    );
};

export default ProjectSummaryDisplay;
