import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { NotFoundScreen } from "components";
import useAppDispatch from "hooks/useAppDispatch";
import useContactSupport from "hooks/useContactSupport";
import routes from "routes/routePaths/RoutePaths";
import { resetLayout, setBack } from "store/layoutSlice";

const ProjectNotFound: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { sendEmail } = useContactSupport();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(resetLayout());
        dispatch(setBack(null));
    }, [dispatch]);

    return (
        <NotFoundScreen
            testid="project-not-found"
            title={t("projectBrief.cancelled.title")}
            message={t("projectBrief.cancelled.message")}
            primaryActionText={t("common.viewDashboard")}
            primaryAction={() => navigate(routes.common.dashboard.path)}
            secondaryActionText={t("common.contactSupport")}
            secondaryAction={sendEmail}
        />
    );
};

export default ProjectNotFound;
