import useCurrentUser from "hooks/useCurrentUser";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { ITheme } from "styles/themes/types";
import { AnswerDto } from "types/dtos/questions/AnswerResponseDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    scoreSummary: {
        fontSize: "14px",
        fontWeight: "bold",
        color: theme.colors.primary,
        paddingRight: theme.spacing.small,
    },
    sectionsComplete: {
        color: theme.colors.primary,
        paddingRight: theme.spacing.medium,
    },
    answersComplete: {
        color: theme.colors.primary,
        paddingRight: theme.spacing.medium,
    },
    contributorsComplete: {
        color: theme.colors.primary,
    },
}));

interface ISupplierScoreSummaryProps {
    sections: SectionDto[];
    questions: QuestionResponseDto[];
    completedContributorScorers: number;
    totalContributorScorers: number;
    isProjectManager: boolean;
}

const SupplierScoreSummary: React.FC<ISupplierScoreSummaryProps> = (props) => {
    const classes = useStyles();

    const { myUuid } = useCurrentUser();
    const { t } = useTranslation();
    const { sections, questions, completedContributorScorers, totalContributorScorers, isProjectManager } = props;
    const haveScores = (answer: AnswerDto) => {
        return (
            !!answer.scores &&
            answer.scores.length > 0 &&
            answer.scores.some((score) => score.updatedByUserUuid === myUuid)
        );
    };

    const questionInSection = (section: SectionDto, question: QuestionResponseDto) =>
        question.entityUuid.toLowerCase() === section.id.toLowerCase();

    const isSectionComplete = (section: SectionDto) => {
        return questions
            .filter((question) => questionInSection(section, question))
            .every((question) => question.answers?.some(haveScores));
    };

    const answers = questions.map((question) => question.answers).flat();
    const scoredAnswersCount = answers.filter(haveScores).length;
    const scoredSectionsCount = sections.filter(isSectionComplete).length;

    return (
        <div>
            <span>
                <span className={classes.scoreSummary}>{t("tenderSummaryScreen.sectionsComplete")}</span>
                <span className={classes.sectionsComplete}>
                    {scoredSectionsCount}/{sections.length}
                </span>
            </span>
            <span>
                <span className={classes.scoreSummary}>{t("tenderSummaryScreen.answersScored")}</span>
                <span className={classes.answersComplete}>
                    {scoredAnswersCount}/{answers.length}
                </span>
            </span>
            {isProjectManager && (
                <span>
                    <span className={classes.scoreSummary}>{t("tenderSummaryScreen.contributorsScored")}</span>
                    <span className={classes.contributorsComplete}>
                        {completedContributorScorers}/{totalContributorScorers}
                    </span>
                </span>
            )}
        </div>
    );
};
export default SupplierScoreSummary;
