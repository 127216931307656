/* eslint-disable no-console */
import { HubConnectionBuilder, LogLevel, HubConnection } from "@microsoft/signalr";
import { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";

import env from "config/EnvironmentVariables";
import {
    connectionEstablished,
    connectionFailed,
    connectToNotificationsHub,
    processNotification,
    setNotificationStatus,
    setUnreadCount,
} from "store/notificationsSlice";
import type { AppDispatch, AppStore } from "store/store";
import { NotificationDto } from "types/dtos/notifications/NotificationDto";

const signalRMiddleware: Middleware = (api: MiddlewareAPI<AppDispatch, AppStore>) => {
    let connection: HubConnection | undefined;

    return (next) => async (action) => {
        const state = api.getState();
        const { isConnected, isEstablishingConnection } = state.notificationsState;

        if (connectToNotificationsHub.match(action) && !isEstablishingConnection && !isConnected) {
            const accessToken = state.oidcState.user?.access_token;
            if (!accessToken || accessToken === "") return;

            connection = new HubConnectionBuilder()
                .withUrl(`${env.SignalRUrl}/notifications`, {
                    accessTokenFactory: () => accessToken,
                    logMessageContent: true,
                })
                .configureLogging(LogLevel.Information)
                .withAutomaticReconnect()
                .build();

            connection.on("ReceiveNotification", (notification: NotificationDto) => {
                api.dispatch(processNotification(notification));
            });

            connection.on("ReceiveUnreadCount", (unreadCount: number) => {
                api.dispatch(setUnreadCount(unreadCount));
            });

            connection.onclose((error) => {
                api.dispatch(connectionFailed());
                console.error("SignalR closed with the following error: ", error);
            });

            connection
                .start()
                .then(() => api.dispatch(connectionEstablished()))
                .catch((error) => {
                    api.dispatch(connectionFailed());
                    console.error("Failed to connect to the SignalR hub: ", error);
                });
        }

        if (setNotificationStatus.match(action) && !!connection && isConnected) {
            connection.invoke("SetNotificationStatus", action);
        }

        next(action);
    };
};

export default signalRMiddleware;
