import { Popup, useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { linkProjectFiles } from "api/fileApi";
import { updateSupplierSubmission } from "api/project/projectSubmissionsApi";
import { Loader } from "components";
import TitleCtaType from "components/Layout/types/TitleCtaType";
import useFileUpload from "features/files/useFileUpload";
import ProjectPreviewDrawer from "features/project/components/ProjectPreviewDrawer";
import useNavTabs from "features/project/hooks/useNavTabs";
import useProjectResponse from "features/project/hooks/useProjectResponse";
import SupplierResponseDisplay from "features/project/supplierQuoteResponse/SupplierResponseDisplay";
import useAppDispatch from "hooks/useAppDispatch";
import useCurrentUser from "hooks/useCurrentUser";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import validationService from "services/validationService";
import { resetLayout, setBack, setPageTitle, setSecondaryNavItems, setTitleCtas } from "store/layoutSlice";
import { setProjectResponseInformation } from "store/projectResponseSlice";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";
import SupplierSubmissionStatus from "types/enums/supplierSubmissions/SupplierSubmissionStatus";

const SupplierResponseContainer: React.FC = () => {
    const [isCancelResponsePopupOpen, setIsCancelResponsePopupOpen] = useState(false);
    const [isWithdrawResponsePopupOpen, setIsWithdrawResponsePopupOpen] = useState(false);
    const [isSubmitQuotePopupOpen, setIsSubmitQuotePopupOpen] = useState(false);
    const [quotePrice, setQuotePrice] = useState(0);
    const [additionalInformation, setAdditionalInformation] = useState<string>();
    const [isSubmittingQuote, setIsSubmittingQuote] = useState(false);
    const [isProjectPreviewOpen, setIsProjectPreviewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const toast = useToaster();
    const navigate = useNavigate();

    const { projectResponseInformation, updateSupplierSubmissionStatus, isFetching, isSubmitting } =
        useProjectResponse();
    const { projectUuid, responseUuid } = projectResponseInformation;
    const { supplierTabItems } = useNavTabs();
    const { projectFiles, setProjectFiles, fileIsUploading, setFileIsUploading, removeFile } =
        useFileUpload(projectUuid);
    const { userHasProjectPermission, userIsSupplier } = useCurrentUser();

    const canAskQuestion = useMemo(() => {
        return userHasProjectPermission(PermissionsEnum.CanAskClarificationQuestion, projectUuid);
    }, [projectUuid, userHasProjectPermission]);

    if (!userIsSupplier) {
        navigate(buildPath(routes.projects.monitor, { projectUuid }));
    }

    useEffect(() => {
        dispatch(setPageTitle(t("supplierResponse.title")));
        dispatch(setSecondaryNavItems(supplierTabItems));
        dispatch(
            setBack({
                route: buildRoute(routes.projectResponses.brief, { projectUuid, responseUuid }),
            }),
        );
        const titleCtas = [
            {
                type: TitleCtaType.ProjectPreview,
                onClick: () => setIsProjectPreviewOpen(true),
            },
        ];

        if (canAskQuestion) {
            titleCtas.push({
                type: TitleCtaType.AskQuestion,
                onClick: () =>
                    navigate(
                        buildPath(routes.projectResponses.askClarificationQuestion, { projectUuid, responseUuid }),
                    ),
            });
        }
        dispatch(setTitleCtas(titleCtas));

        return () => {
            dispatch(resetLayout());
        };
    }, [
        dispatch,
        projectUuid,
        responseUuid,
        t,
        projectResponseInformation,
        projectFiles,
        supplierTabItems,
        canAskQuestion,
        navigate,
    ]);

    const submitForm = (supplierPrice: number, supplierNotes?: string) => {
        if (
            projectResponseInformation.supplierPrice === supplierPrice &&
            projectFiles.filter((file) => file.newUpload).length === 0 &&
            projectResponseInformation.supplierNotes === supplierNotes
        ) {
            return;
        }

        setQuotePrice(supplierPrice);
        setAdditionalInformation(supplierNotes);
        setIsSubmitQuotePopupOpen(true);
    };

    const submitQuote = useCallback(async () => {
        if (
            !(
                projectUuid &&
                validationService.isValidUuid(projectUuid) &&
                responseUuid &&
                validationService.isValidUuid(responseUuid)
            )
        ) {
            setIsSubmitQuotePopupOpen(false);
            return;
        }
        setIsSubmittingQuote(true);
        const response = await updateSupplierSubmission({
            responseUuid,
            projectUuid,
            supplierPricing: {
                simplePrice: {
                    price: quotePrice,
                    ccy: projectResponseInformation.budgetCurrency,
                },
            },
            supplierNotes: additionalInformation,
            status: SupplierSubmissionStatus.Submitted,
        });

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("supplierResponse.api.updateSupplierResponseError"));
            setIsSubmittingQuote(false);
            return;
        }

        const newFiles = projectFiles.filter((file) => file.newUpload);
        if (newFiles.length) {
            const fileUuids = newFiles.map((file) => file.fileId);
            await linkProjectFiles(projectUuid, fileUuids);
        }

        dispatch(setProjectResponseInformation(response.data));

        navigate(buildPath(routes.projectResponses.thankYou, { projectUuid, responseUuid }));
    }, [
        projectUuid,
        responseUuid,
        quotePrice,
        projectResponseInformation.budgetCurrency,
        additionalInformation,
        projectFiles,
        dispatch,
        navigate,
        toast,
        t,
    ]);

    useEffect(() => {
        setIsLoading(isFetching);
    }, [isFetching]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <React.Fragment>
            <SupplierResponseDisplay
                supplierSubmission={projectResponseInformation}
                projectFiles={projectFiles}
                setProjectFiles={setProjectFiles}
                submitForm={submitForm}
                cancelProjectResponse={() => setIsCancelResponsePopupOpen(true)}
                withdrawProjectResponse={() => setIsWithdrawResponsePopupOpen(true)}
                removeFile={removeFile}
                fileIsUploading={fileIsUploading}
                setFileIsUploading={setFileIsUploading}
            />
            <Popup
                title={t("popups.supplierResponse.submitQuote.title")}
                message={t("popups.supplierResponse.submitQuote.message", {
                    projectName: projectResponseInformation.projectName,
                })}
                isOpen={isSubmitQuotePopupOpen}
                primaryActionText={t("popups.supplierResponse.submitQuote.cta.primary")}
                onPrimaryAction={submitQuote}
                secondaryActionText={t("popups.supplierResponse.submitQuote.cta.secondary")}
                onSecondaryAction={() => setIsSubmitQuotePopupOpen(false)}
                onClose={() => setIsSubmitQuotePopupOpen(false)}
                testid="submit-quote-popup"
                disabled={isSubmittingQuote}
            />
            <Popup
                title={t("popups.projectResponse.title")}
                message={t("popups.projectResponse.message")}
                isOpen={isCancelResponsePopupOpen}
                primaryActionText={t("popups.projectResponse.cta.primary")}
                onPrimaryAction={() =>
                    updateSupplierSubmissionStatus(
                        SupplierSubmissionStatus.NotInterested,
                        routes.common.dashboard.path,
                        t("projectResponse.api.failedToCancelQuote"),
                    )
                }
                secondaryActionText={t("popups.projectResponse.cta.secondary")}
                onSecondaryAction={() => setIsCancelResponsePopupOpen(false)}
                onClose={() => setIsCancelResponsePopupOpen(false)}
                testid="not-interested-popup"
                disabled={isSubmitting}
            />
            <Popup
                title={t("popups.supplierResponse.withdrawResponse.title")}
                message={t("popups.supplierResponse.withdrawResponse.message")}
                isOpen={isWithdrawResponsePopupOpen}
                primaryActionText={t("popups.supplierResponse.withdrawResponse.cta.primary")}
                onPrimaryAction={() =>
                    updateSupplierSubmissionStatus(
                        SupplierSubmissionStatus.Withdrawn,
                        routes.common.dashboard.path,
                        t("projectResponse.api.failedToCancelQuote"),
                        t("projectResponse.api.withdrawSuccessful"),
                    )
                }
                secondaryActionText={t("common.cancel")}
                onSecondaryAction={() => setIsWithdrawResponsePopupOpen(false)}
                onClose={() => setIsWithdrawResponsePopupOpen(false)}
                testid="cancel-quote-popup"
                disabled={isSubmitting}
            />
            <ProjectPreviewDrawer
                projectResponseInformation={projectResponseInformation}
                projectFiles={projectFiles.filter(
                    (file) =>
                        file.linkedCompanyUuid === projectResponseInformation.buyerCompanyUuid ||
                        file.linkedCompanyUuid == null,
                )}
                isOpen={isProjectPreviewOpen}
                close={() => setIsProjectPreviewOpen(false)}
            />
        </React.Fragment>
    );
};

export default SupplierResponseContainer;
