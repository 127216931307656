import { IPaginationState, Popup, useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { deleteUserGroup, listUserGroups } from "api/company/userGroupApi";
import { Loader } from "components";
import UserGroupsDisplay from "features/company/buyer/userGroups/UserGroupsDisplay";
import useAppDispatch from "hooks/useAppDispatch";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import validationService from "services/validationService";
import { resetLayout, setBack, setPageTitle, setTooltip } from "store/layoutSlice";
import { UserGroupDto } from "types/dtos/company/userGroups/UserGroupDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

interface ICompanyProps {
    companyUuid: string;
}

const UserGroupsContainer: React.FC = () => {
    const [userGroups, setUserGroups] = useState<UserGroupDto[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<UserGroupDto>();
    const [selectedUserGroupUuid, setSelectedUserGroupUuid] = useState<string>();
    const [isEditUserGroupPopupOpen, setIsEditUserGroupPopupOpen] = useState(false);
    const [isDeleteUserGroupPopupOpen, setIsDeleteUserGroupPopupOpen] = useState(false);
    const [paginationState, setPaginationState] = useState<IPaginationState>({
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0,
    });
    const [isLoading, setIsLoading] = useState(true);

    const params = useParams();
    const { companyUuid } = params as unknown as ICompanyProps;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const toast = useToaster();
    const navigate = useNavigate();

    const upsertGroup = (usergroupUuid?: string) => {
        navigate(
            buildPath(routes.company.userGroup, {
                companyUuid,
                groupUuid: usergroupUuid?.length ? usergroupUuid : "new",
            }),
        );
    };

    const closeDeletePopup = () => {
        setSelectedUserGroupUuid(undefined);
        setIsDeleteUserGroupPopupOpen(false);
    };

    const navigateToPage = (page: number) => {
        setPaginationState({ ...paginationState, currentPage: page });
    };

    const fetchData = useCallback(() => {
        listUserGroups({
            companyUuid,
            skip: (paginationState.currentPage - 1) * paginationState.itemsPerPage,
            take: paginationState.itemsPerPage,
        }).then((response) => {
            if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                toast.error(t("userGroups.api.listUserGroupsError"));
                setUserGroups([]);
            } else {
                setUserGroups(response.data.userGroups);
                setPaginationState({
                    currentPage: paginationState.currentPage,
                    itemsPerPage: paginationState.itemsPerPage,
                    totalItems: response.data.totalCount,
                });
            }

            setIsLoading(false);
        });
    }, [companyUuid, setIsLoading, t, toast, paginationState.currentPage, paginationState.itemsPerPage]);

    const deleteGroup = (userGroupUuid?: string) => {
        if (
            !(
                validationService.isValidUuid(companyUuid) &&
                !!userGroupUuid &&
                validationService.isValidUuid(userGroupUuid)
            )
        ) {
            return;
        }

        deleteUserGroup(companyUuid, userGroupUuid).then((response) => {
            if (response.data instanceof TransactionErrorDto || response.status !== 204) {
                toast.error(t("userGroups.api.deleteUserGroupError"));
                closeDeletePopup();
                return;
            }

            toast.success(t("userGroups.api.deleteUserGroupSuccess"));
            closeDeletePopup();
            if (paginationState.currentPage > 1) {
                navigateToPage(1);
            } else {
                fetchData();
            }
        });
    };

    const openEditPopup = (row: UserGroupDto) => {
        setSelectedGroup(row);
        if (row.associatedProjectCount && row.associatedProjectCount > 0) {
            setIsEditUserGroupPopupOpen(true);
        } else {
            upsertGroup(row.id);
        }
    };

    const closeEditPopup = () => {
        setSelectedUserGroupUuid(undefined);
        setIsEditUserGroupPopupOpen(false);
    };

    const openDeletePopup = (row: UserGroupDto) => {
        setSelectedUserGroupUuid(row.id);
        setSelectedGroup(row);
        if (row.associatedProjectCount && row.associatedProjectCount > 0) {
            setIsDeleteUserGroupPopupOpen(true);
        } else {
            deleteGroup(row.id);
        }
    };

    useEffect(() => {
        dispatch(setPageTitle(t("userGroups.title")));
        dispatch(setTooltip(t("userGroups.titleTooltip")));
        dispatch(
            setBack({
                route: buildRoute(routes.common.settings),
            }),
        );

        return () => {
            dispatch(resetLayout());
        };
    }, [dispatch, t]);

    useEffect(() => {
        if (!validationService.isValidUuid(companyUuid)) return;

        fetchData();
    }, [companyUuid, fetchData]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <UserGroupsDisplay
                userGroups={userGroups}
                onCreate={upsertGroup}
                onEdit={openEditPopup}
                onDelete={openDeletePopup}
                paginationState={paginationState}
                navigateToPage={navigateToPage}
                setSelectedUserGroupUuid={setSelectedUserGroupUuid}
            />
            <Popup
                title={t("popups.userGroups.edit.title")}
                message={t("popups.userGroups.edit.message", { projectCount: selectedGroup?.associatedProjectCount })}
                isOpen={isEditUserGroupPopupOpen}
                primaryActionText={t("popups.userGroups.edit.cta.primary")}
                onPrimaryAction={() => upsertGroup(selectedGroup?.id)}
                secondaryActionText={t("popups.userGroups.edit.cta.secondary")}
                onSecondaryAction={closeEditPopup}
                onClose={closeEditPopup}
                testid="edit-user-group-popup"
            />
            <Popup
                title={t("popups.userGroups.delete.title")}
                message={t("popups.userGroups.delete.message", { projectCount: selectedGroup?.associatedProjectCount })}
                isOpen={isDeleteUserGroupPopupOpen}
                primaryActionText={t("popups.userGroups.delete.cta.primary")}
                onPrimaryAction={() => deleteGroup(selectedUserGroupUuid)}
                secondaryActionText={t("popups.userGroups.delete.cta.secondary")}
                onSecondaryAction={closeDeletePopup}
                onClose={closeDeletePopup}
                testid="edit-user-group-popup"
            />
        </>
    );
};

export default UserGroupsContainer;
