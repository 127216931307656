import { createUseStyles } from "react-jss";

import { ITheme } from "styles/themes/types";

const useEmptyScreenStyles = createUseStyles((theme: ITheme) => ({
    wrapper: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    },

    content: {
        width: "100%",
        padding: theme.spacing.small,
        maxWidth: 450,
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.xLarge,
        marginTop: theme.spacing.unit,
    },
    copy: {
        fontWeight: "bold",
    },

    emptyImage: {
        width: "50%",
        height: "50%",
    },

    hide: {
        display: "none",
    },
}));

export default useEmptyScreenStyles;
