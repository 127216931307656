import { ISortBy, ITableColumn, Table } from "@maistro/components";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ICompanyListInformation } from "features/company/list/interfaces/ICompanyListInformation";
import ReactGA from "react-ga4";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { CompanyResultDto } from "types/dtos/company/CompanyResultDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

interface ICompanyListDisplayProps {
    filteredCompanies: Array<CompanyResultDto>;
    setSortBy?: (sort: ISortBy) => void;
    sortBy: ISortBy;
    columns: ITableColumn[];
}

const CompanyListDisplay: React.FC<ICompanyListDisplayProps> = ({ filteredCompanies, setSortBy, sortBy, columns }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onRowClick = useCallback(
        (row: ICompanyListInformation) => {
            ReactGA.event({
                category: ga4Category.Element,
                action: ga4Action.Element,
                label: `Companies - `.concat(row.registeredName),
            });
            navigate(buildPath(routes.company.companyProfile, { companyUuid: row.companyUuid }));
        },
        [navigate],
    );

    return (
        <Table
            columns={columns}
            data={filteredCompanies}
            noDataDisplay={t("common.noResults")}
            onSort={setSortBy}
            sortBy={sortBy}
            rowAction={(row: ICompanyListInformation) => onRowClick(row)}
            testid="companies-list"
        />
    );
};

export default CompanyListDisplay;
