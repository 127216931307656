import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { Icon, Popup } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";

import useHelp from "components/Layout/Help/hooks/useHelp";
import useWhitelabelSettings from "hooks/useWhitelabelSettings";

const Help: React.FC = () => {
    const { t } = useTranslation();

    const { isOpen, closeModal, downloadModal, openModal, confirm } = useHelp();
    const { whitelabelEmailSettings } = useWhitelabelSettings();

    return (
        <>
            <Icon icon={faQuestionCircle} onClick={openModal} />
            <Popup
                isOpen={isOpen}
                title={t("help.title")}
                message={t("help.content", { options: { emailAddress: whitelabelEmailSettings.defaultFromAddress } })}
                primaryActionText={t("help.confirm")}
                secondaryActionText={t("help.download")}
                onPrimaryAction={() => confirm(whitelabelEmailSettings.defaultFromAddress)}
                onSecondaryAction={downloadModal}
                onClose={closeModal}
                testid="help-popup"
            />
        </>
    );
};

export default Help;
