import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { Copy, ITableColumn, Icon, Table, useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate, useParams } from "react-router-dom";

import { Loader } from "components";
import CompanyProfileActionPopup from "features/company/profile/CompanyProfileActionPopup";
import useCompanyStepperNavigation from "features/company/profile/hooks/useCompanyStepperNavigation";
import useAppDispatch from "hooks/useAppDispatch";
import ReactGA from "react-ga4";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import validationService from "services/validationService";
import { useGetCompanyDetailsQuery, useLazyGetCompanySupplierListMembershipsQuery } from "store/api/companyApi";
import { resetLayout, setBack, setPageTitle } from "store/layoutSlice";
import { ITheme } from "styles/themes/types";
import { ISupplierListDto } from "types/dtos/company/supplier-list-memberships/ISupplierListDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    copy: {
        marginBottom: theme.spacing.xLarge,
    },
}));

const BuyerListsContainer: React.FC = () => {
    const classes = useStyles();

    const [buyerLists, setBuyerLists] = useState<ISupplierListDto[]>();

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { companyUuid } = useParams();
    const navigate = useNavigate();
    const toast = useToaster();

    const { data: companyDetails } = useGetCompanyDetailsQuery(companyUuid ?? "");
    const [getBuyerLists, { isLoading }] = useLazyGetCompanySupplierListMembershipsQuery();

    const { isCompanyProfileActionPopupOpen, setIsCompanyProfileActionPopupOpen } = useCompanyStepperNavigation({
        activeStep: "buyerLists",
        title: companyDetails?.registeredName,
        subTitle: companyDetails?.netPromoterScore
            ? t("companyProfile.stepperNavigation.netPromoterScore", {
                  netPromoterScore: companyDetails?.netPromoterScore,
              })
            : "",
        companyUuid: companyUuid ?? "",
        isActive: companyDetails?.isActive,
    });

    useEffect(() => {
        dispatch(setPageTitle(t("buyerLists.title")));
        dispatch(
            setBack({
                route: buildRoute(routes.company.companyProfile, { companyUuid }),
            }),
        );

        return () => {
            dispatch(resetLayout());
        };
    }, [companyUuid, dispatch, t]);

    const fetchBuyerLists = useCallback(() => {
        if (!companyUuid || !validationService.isValidUuid(companyUuid)) {
            return;
        }
        getBuyerLists(companyUuid, false)
            .unwrap()
            .then((response) => {
                setBuyerLists(response.items);
            })
            .catch(() => {
                toast.error(t("buyerLists.api.getBuyerListsError"));
                setBuyerLists(undefined);
            });
    }, [companyUuid, getBuyerLists, t, toast]);

    useEffect(() => {
        fetchBuyerLists();
    }, [fetchBuyerLists]);

    const onRowClick = useCallback(
        (row: ISupplierListDto) => {
            if (row.ownerCompanyUuid) {
                navigate(buildPath(routes.company.companyProfile, { companyUuid: row.ownerCompanyUuid }));
            }
        },
        [navigate],
    );

    const columns: ITableColumn[] = useMemo(
        () => [
            {
                minWidth: 100,
                name: "ownerCompanyName",
                position: 1,
                priorityLevel: 1,
            },
            {
                position: 2,
                priorityLevel: 0,
                name: "view",
                minWidth: 50,
                render: (row: ISupplierListDto) => (
                    <Icon icon={faEye} alt={t("common.view")} onClick={() => onRowClick(row)} />
                ),
            },
        ],
        [onRowClick, t],
    );

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <Copy className={classes.copy}>{t("buyerLists.subtitle")}</Copy>

            {buyerLists ? (
                <Table
                    testid="buyers-list-table"
                    columns={columns}
                    data={buyerLists}
                    rowAction={(row: ISupplierListDto) => {
                        ReactGA.event({
                            category: ga4Category.Element,
                            action: ga4Action.Element,
                            label: `Company Buyers - Buyers - `.concat(row.ownerCompanyName),
                        });
                        onRowClick(row);
                    }}
                    noDataDisplay={t("buyerLists.noBuyerLists")}
                />
            ) : (
                <Copy className={classes.copy}>{t("buyerLists.noBuyerLists")}</Copy>
            )}

            <CompanyProfileActionPopup
                isCompanyProfileActionPopupOpen={isCompanyProfileActionPopupOpen}
                setIsCompanyProfileActionPopupOpen={setIsCompanyProfileActionPopupOpen}
                isActive={companyDetails?.isActive}
                isBuyer={companyDetails?.allowBuyerUsers}
                companyUuid={companyDetails?.uuid}
                registeredName={companyDetails?.registeredName}
            />
        </>
    );
};

export default BuyerListsContainer;
