import { ExpansionPanel, ReadOnlyField, TextButton } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";

import { ICommonProps } from "components/shared";
import useProfileStyles from "features/company/hooks/useProfileStyles";
import validationService from "services/validationService";
import { CompanySummaryDto } from "types/dtos/company/CompanySummaryDto";
import { PostalAddressDto } from "types/dtos/company/postal-addresses/PostalAddressDto";
import { ListCountryTypesResponseDto } from "types/dtos/company/referenceData/ListCountryTypesResponseDto";

interface IContactDetailsSectionProps extends ICommonProps {
    data: CompanySummaryDto;
    countryReferenceData?: ListCountryTypesResponseDto;
}

const ContactDetailsSection: React.FC<IContactDetailsSectionProps> = ({ data, testid, countryReferenceData }) => {
    const classes = useProfileStyles();

    const { t } = useTranslation();

    const fullAddress = (address?: PostalAddressDto) => {
        if (!address) return t("common.notKnown");
        return [
            address.streetAddress,
            address.city,
            address.county,
            address.postcode,
            countryReferenceData?.countryTypes.find((ct) => ct.id.toString() === address.countryTypeId)?.name,
        ]
            .filter((line) => line?.length)
            .join(", ");
    };

    const linkForUrl = (url: string) => {
        return validationService.isValidUrl(url) ? (
            <TextButton label={url} onClick={() => window.open(url, "_blank")} testid="supplier-website-link" />
        ) : (
            url
        );
    };

    return (
        <ExpansionPanel label={t("companyInformation.contactDetails.title")} testid={testid}>
            <div className={classes.column}>
                <ReadOnlyField
                    label={t("companyInformation.contactDetails.website")}
                    value={data.websiteUrl ? linkForUrl(data.websiteUrl) : t("common.notKnown")}
                    testid="website"
                    useHyphenForEmptyValues
                />
                <ReadOnlyField
                    label={t("companyInformation.contactDetails.primaryAddress")}
                    value={fullAddress(data.postalAddresses.find((pa) => pa.isPrimaryAddress))}
                    testid="postal-address"
                    useHyphenForEmptyValues
                />
                <ReadOnlyField
                    label={t("companyInformation.contactDetails.emailAddress")}
                    value={data.contactEmail}
                    testid="supplier-email-address"
                    useHyphenForEmptyValues
                />
            </div>
        </ExpansionPanel>
    );
};

export default ContactDetailsSection;
