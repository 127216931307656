import { CheckboxField, CheckboxesField, Copy } from "@maistro/components";
import { Form, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { ITheme } from "styles/themes/types";
import SupplierTieringStatus from "types/enums/companies/SupplierTieringStatus";

const useStyles = createUseStyles((theme: ITheme) => ({
    form: {
        marginTop: theme.spacing.large,
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.large,
    },

    tieringOptions: {
        paddingLeft: theme.spacing.large,
    },

    message: {
        fontSize: 13,
        fontWeight: 500,
    },
}));

const SupplierTieringForm: React.FC = () => {
    const classes = useStyles();

    const { t } = useTranslation();

    const { setFieldValue, values } = useFormikContext<{ hasTieringPolicy: boolean; supplierTierings: string[] }>();

    useEffect(() => {
        if (!values.hasTieringPolicy) {
            setFieldValue("supplierTierings", []);
        }
    }, [setFieldValue, values.hasTieringPolicy]);

    return (
        <Form className={classes.form} id="supplier-tiering-form">
            <div>
                <CheckboxField
                    name="hasTieringPolicy"
                    label={t("userGroups.policies.form.supplierTiering.label")}
                    tooltip={t("userGroups.policies.form.supplierTiering.tooltip")}
                    testid="supplier-tiering-checkbox"
                />
                {values.hasTieringPolicy && (
                    <CheckboxesField
                        className={classes.tieringOptions}
                        name="supplierTierings"
                        options={[
                            {
                                label: t("companySuppliers.tieringStatus.strategic.label"),
                                value: SupplierTieringStatus.Strategic,
                            },
                            {
                                label: t("companySuppliers.tieringStatus.preferred.label"),
                                value: SupplierTieringStatus.Preferred,
                            },
                            {
                                label: t("companySuppliers.tieringStatus.occasional.label"),
                                value: SupplierTieringStatus.Occasional,
                            },
                            {
                                label: t("companySuppliers.tieringStatus.undefined.label"),
                                value: SupplierTieringStatus.Undefined,
                            },
                        ]}
                        testid="tiering-status"
                    />
                )}
            </div>
            {values.supplierTierings.length > 0 && (
                <Copy className={classes.message}>{t("userGroups.policies.tieringMessage")}</Copy>
            )}
        </Form>
    );
};

export default SupplierTieringForm;
