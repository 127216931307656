import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { IButtonProps } from "@maistro/components/dist/esm/types/components/Button/Button";
import { IPaginationState, ISortBy } from "@maistro/components/dist/esm/types/types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { searchCompanies } from "api/company/companyApi";
import useCompanyListFilters from "features/company/list/filter/hooks/useCompanyListFilters";
import useAppDispatch from "hooks/useAppDispatch";
import routes from "routes/routePaths/RoutePaths";
import { setBack, setCtas, setMobileFooterCtas, setPageTitle } from "store/layoutSlice";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { CompanyListFilterDto } from "types/dtos/company/CompanyListFilterDto";
import { CompanyResultDto } from "types/dtos/company/CompanyResultDto";

const useCompanyListContainer = (initialSort: ISortBy, setLoading: (isLoading: boolean) => void) => {
    const [sortBy, setSortBy] = useState<ISortBy>(initialSort);
    const [paginationState, setPaginationState] = useState<IPaginationState>({
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0,
    });
    const [filteredCompanies, setFilteredCompanies] = useState<Array<CompanyResultDto>>([]);
    const [isSearching, setIsSearching] = useState(false);
    const [disableSorting, setDisableSorting] = useState(false);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { currentFilters, setCurrentFilter, options, resetCurrentFilter } = useCompanyListFilters();

    const createCompany = useCallback(() => {
        navigate(routes.company.createCompany.path);
    }, [navigate]);

    useEffect(() => {
        dispatch(
            setBack({
                route: routes.common.settings,
            }),
        );
        dispatch(setPageTitle(t("companyList.title")));
        const createCompanyButtonProps: IButtonProps = {
            onClick: createCompany,
            label: t("companyList.cta"),
            testid: "new-company-button",
            icon: faPlus,
            iconPosition: "left",
        };
        dispatch(setCtas([createCompanyButtonProps]));
        dispatch(setMobileFooterCtas([createCompanyButtonProps]));
    }, [createCompany, dispatch, t]);

    const filteredCompanyCount = filteredCompanies.length;

    const searchAllCompanies = useCallback(async () => {
        if (filteredCompanyCount === 0) {
            setLoading(true);
        }
        setIsSearching(true);

        const searchResponse = await searchCompanies(
            paginationState.currentPage,
            paginationState.itemsPerPage,
            sortBy,
            currentFilters,
        );
        if (searchResponse.status === 200 && !(searchResponse.data instanceof TransactionErrorDto)) {
            setFilteredCompanies(
                searchResponse.data.items.map((companyDto) => ({
                    ...companyDto,
                })),
            );

            setPaginationState({
                currentPage: paginationState.currentPage,
                itemsPerPage: paginationState.itemsPerPage,
                totalItems: searchResponse.data.totalCount,
            });
        }
        setLoading(false);
        setIsSearching(false);
    }, [
        currentFilters,
        filteredCompanyCount,
        paginationState.currentPage,
        paginationState.itemsPerPage,
        setLoading,
        sortBy,
    ]);

    const fetchData = useCallback(async () => {
        await searchAllCompanies();
    }, [searchAllCompanies]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const navigateToPage = (page: number) => {
        setPaginationState({ ...paginationState, currentPage: page });
    };

    const setFilters = (filters?: CompanyListFilterDto) => {
        if (!filters) {
            resetCurrentFilter();
            return;
        }
        if (filters.searchTerm && filters.searchTerm.length > 0) {
            setDisableSorting(true);
        } else {
            setDisableSorting(false);
        }
        setCurrentFilter(filters);
    };

    return {
        currentFilters,
        setFilters,
        options,
        filteredCompanies,
        sortBy,
        setSortBy,
        paginationState,
        navigateToPage,
        createCompany,
        isSearching,
        disableSorting,
    };
};

export default useCompanyListContainer;
