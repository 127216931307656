import { faArrowToBottom, faMessageQuestion } from "@fortawesome/pro-regular-svg-icons";
import { Button } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate, useParams } from "react-router-dom";

import { ICommonProps } from "components/shared";
import ClarificationQuestionsTable from "features/project/clarificationQuestions/ClarificationQuestionsTable";
import NoClarificationQuestions from "features/project/clarificationQuestions/NoClarificationQuestions";
import ProjectInformationDisplay from "features/project/clarificationQuestions/ProjectInformationDisplay";
import { IProjectInformation } from "features/project/types";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { ITheme } from "styles/themes/types";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

const useStyles = createUseStyles((theme: ITheme) => ({
    button: {
        margin: `${theme.spacing.medium}px 0`,
    },
    buttonContainer: {
        display: "flex",
        gap: theme.spacing.small,
    },
}));

interface IBuyerClarificationQuestionsDisplayProps extends ICommonProps {
    projectInformation: IProjectInformation;
    downloadReport: () => void;
    clarificationQuestions: Array<QuestionResponseDto>;
    sections: Array<SectionDto>;
}

interface IBuyerClarificationQuestionsRoute {
    projectUuid: string;
}

const BuyerClarificationQuestionsDisplay: React.FC<IBuyerClarificationQuestionsDisplayProps> = (props) => {
    const classes = useStyles();
    const params = useParams();
    const { projectUuid } = params as unknown as IBuyerClarificationQuestionsRoute;
    const { projectInformation, clarificationQuestions, downloadReport, sections } = props;
    const { userHasProjectPermission } = useCurrentUser();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateToQuestion = (questionUuid: string) => {
        navigate(
            buildPath(routes.projects.viewClarificationQuestion, {
                projectUuid: projectInformation.uuid,
                questionUuid,
            }),
            {
                state: {
                    route: buildRoute(routes.projects.clarificationQuestions, { projectUuid: projectInformation.uuid }),
                },
            },
        );
    };

    const navigateToAskAQuestion = () => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: "Clarification Questions - Ask Question",
        });
        navigate(buildPath(routes.projects.askClarificationQuestion, { projectUuid: projectInformation.uuid }));
    };

    const canAskQuestion = userHasProjectPermission(PermissionsEnum.CanAskClarificationQuestion, projectUuid);

    return (
        <>
            <ProjectInformationDisplay
                projectName={projectInformation.projectName}
                responseDate={projectInformation.tenderResponseDeadline}
            />
            {clarificationQuestions.length < 1 && (
                <NoClarificationQuestions>
                    <Button
                        className={classes.button}
                        label={t("clarificationQuestions.buttons.askAQuestion")}
                        onClick={navigateToAskAQuestion}
                        testid="ask-question-button"
                        icon={faMessageQuestion}
                        iconPosition="left"
                    />
                </NoClarificationQuestions>
            )}
            {clarificationQuestions.length >= 1 && (
                <>
                    <div className={classes.buttonContainer}>
                        {canAskQuestion && (
                            <Button
                                className={classes.button}
                                label={t("clarificationQuestions.buttons.askAQuestion")}
                                onClick={navigateToAskAQuestion}
                                testid="ask-question-button"
                                icon={faMessageQuestion}
                                iconPosition="left"
                            />
                        )}
                        <Button
                            className={classes.button}
                            label={t("clarificationQuestions.buttons.downloadReport")}
                            icon={faArrowToBottom}
                            iconPosition="left"
                            variant="outline"
                            onClick={() => {
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "Clarification Questions - Download Report",
                                });
                                downloadReport();
                            }}
                            testid="download-report-button"
                        />
                    </div>
                    <ClarificationQuestionsTable
                        clarificationQuestions={clarificationQuestions}
                        navigateToQuestion={navigateToQuestion}
                        testid="buyer-clarification-questions-table"
                        buyerCompanyUuid={projectInformation.companyUuid}
                        projectUuid={projectInformation.uuid}
                        sections={sections}
                        projectType={projectInformation.type}
                    />
                </>
            )}
        </>
    );
};

export default BuyerClarificationQuestionsDisplay;
