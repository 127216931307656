import { AxiosResponse } from "axios";
import { createAxiosInstance } from "api/commonApi";
import env from "config/EnvironmentVariables";
import { CreateClarificationQuestionForSubmissionRequestDto } from "types/dtos/questions/CreateClarificationQuestionForSubmissionRequestDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { ListQuestionsResponseDto } from "types/dtos/questions/ListQuestionsResponseDto";
import { AnswerDto } from "types/dtos/questions/AnswerResponseDto";
import { CreateOrUpdateClarificationAnswerForSubmissionRequestDto } from "types/dtos/questions/CreateOrUpdateClarificationAnswerForSubmissionRequestDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/supplier-submissions";
const questionType = "clarification-questions";

export const createQuestion = (
    submissionUuid: string,
    request: CreateClarificationQuestionForSubmissionRequestDto,
): Promise<AxiosResponse<QuestionResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${submissionUuid}/${questionType}`;
    return api.post(url, request);
};

export const getQuestion = (
    submissionUuid: string,
    questionUuid: string,
    includeAnswers = false,
    includeCompanyNames = false,
): Promise<AxiosResponse<QuestionResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${submissionUuid}/${questionType}/${questionUuid}`;
    return api.get(url, {
        params: {
            IncludeAnswers: includeAnswers,
            IncludeCompanyNames: includeCompanyNames,
        },
    });
};

export const listQuestions = (
    submissionUuid: string,
): Promise<AxiosResponse<ListQuestionsResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${submissionUuid}/${questionType}`;
    return api.get(url, {
        params: {
            IncludeAnswers: true,
        },
    });
};

export const createOrUpdateAnswer = (
    submissionUuid: string,
    questionUuid: string,
    answer: CreateOrUpdateClarificationAnswerForSubmissionRequestDto,
): Promise<AxiosResponse<AnswerDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${submissionUuid}/${questionType}/${questionUuid}/answers`;
    return api.put(url, answer);
};
