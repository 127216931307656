import { IPaginationState, useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { actionPolicyApproval, listApprovalsForUser } from "api/company/approvalsApi";
import { Loader } from "components";
import ApprovalsDisplay from "features/company/buyer/approvals/ApprovalsDisplay";
import EmptyApprovals from "features/company/buyer/approvals/EmptyApprovals";
import PolicyDecisionDrawer from "features/company/buyer/approvals/PolicyDecisionDrawer";
import PolicyInformationDrawer from "features/company/buyer/approvals/PolicyInformationDrawer";
import useAppDispatch from "hooks/useAppDispatch";
import routes from "routes/routePaths/RoutePaths";
import apiErrorService from "services/apiErrorService";
import validationService from "services/validationService";
import { resetLayout, setBack, setPageTitle, setTooltip } from "store/layoutSlice";
import { UserApprovalDto } from "types/dtos/company/approvals/UserApprovalDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import PolicyApprovalStatusType from "types/enums/companies/Approvals/PolicyApprovalStatusType";

const ApprovalsContainer: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isPolicyInfoDrawerOpen, setIsPolicyInfoDrawerOpen] = useState(false);
    const [isPolicyDecisionDrawerOpen, setIsPolicyDecisionDrawerOpen] = useState(false);
    const [isAcceptDecision, setIsAcceptDecision] = useState<boolean | undefined>();
    const [selectedApproval, setSelectedApproval] = useState<UserApprovalDto>();
    const [approvals, setApprovals] = useState<UserApprovalDto[]>([]);
    const [paginationState, setPaginationState] = useState<IPaginationState>({
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0,
    });

    const { companyUuid } = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const toast = useToaster();

    const openPolicyInfoDrawer = (approval: UserApprovalDto) => {
        setSelectedApproval(approval);
        setIsPolicyInfoDrawerOpen(true);
    };

    const closePolicyInfoDrawer = () => {
        setIsPolicyInfoDrawerOpen(false);
        setTimeout(() => setSelectedApproval(undefined), 500);
    };

    const onApproval = (approval: UserApprovalDto) => {
        setSelectedApproval(approval);
        setIsAcceptDecision(true);
        setIsPolicyDecisionDrawerOpen(true);
    };

    const onRejection = (approval: UserApprovalDto) => {
        setSelectedApproval(approval);
        setIsAcceptDecision(false);
        setIsPolicyDecisionDrawerOpen(true);
    };

    const closePolicyDecisionDrawer = () => {
        setIsPolicyDecisionDrawerOpen(false);
        setTimeout(() => setIsAcceptDecision(undefined), 500);
    };

    const navigateToPage = (page: number) => {
        setPaginationState({ ...paginationState, currentPage: page });
    };

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        const response = await listApprovalsForUser({
            skip: (paginationState.currentPage - 1) * paginationState.itemsPerPage,
            take: paginationState.itemsPerPage,
        });

        if (response.data instanceof TransactionErrorDto) {
            toast.error(apiErrorService.getFirstErrorFromResponse(response.data));
            setApprovals([]);
            return;
        }

        if (response.status !== 200) {
            toast.error(t("approvals.api.error"));
            setApprovals([]);
            return;
        }

        setApprovals(response.data.approvals);
        setPaginationState({
            currentPage: paginationState.currentPage,
            itemsPerPage: paginationState.itemsPerPage,
            totalItems: response.data.totalCount,
        });
    }, [paginationState.currentPage, paginationState.itemsPerPage, t, toast]);

    const onDecisionSubmit = async (reason: string) => {
        if (
            !(
                companyUuid &&
                validationService.isValidUuid(companyUuid) &&
                selectedApproval &&
                isAcceptDecision !== undefined
            )
        ) {
            return;
        }
        const response = await actionPolicyApproval({
            companyUuid,
            projectUuid: selectedApproval.projectUuid,
            userGroupUuid: selectedApproval.policy.groupUuid,
            policyUuid: selectedApproval.policy.id,
            approvalUuid: selectedApproval.uuid,
            message: reason,
            status: isAcceptDecision ? PolicyApprovalStatusType.Approved : PolicyApprovalStatusType.Rejected,
        });

        if (response.data instanceof TransactionErrorDto) {
            toast.error(apiErrorService.getFirstErrorFromResponse(response.data));
            setIsPolicyDecisionDrawerOpen(false);
            return;
        }

        if (response.status !== 200) {
            toast.error(t("approvals.api.error"));
            setIsPolicyDecisionDrawerOpen(false);
            return;
        }
        toast.success(t("approvals.api.success"));
        fetchData()
            .then(() => setIsLoading(false))
            .catch(() => toast.error(t("approvals.api.error")));

        setIsPolicyDecisionDrawerOpen(false);
    };

    useEffect(() => {
        dispatch(setPageTitle(t("approvals.title")));
        dispatch(setTooltip(t("approvals.titleTooltip")));
        dispatch(
            setBack({
                route: routes.common.settings,
            }),
        );

        return () => {
            dispatch(resetLayout());
        };
    }, [dispatch, t]);

    useEffect(() => {
        fetchData().then(() => setIsLoading(false));
    }, [fetchData]);

    if (isLoading) {
        return <Loader />;
    }

    if (approvals.length === 0) {
        return <EmptyApprovals />;
    }

    return (
        <>
            <ApprovalsDisplay
                approvals={approvals}
                paginationState={paginationState}
                navigateToPage={navigateToPage}
                onInformation={openPolicyInfoDrawer}
                onApproval={onApproval}
                onRejection={onRejection}
            />
            <PolicyInformationDrawer
                approval={selectedApproval}
                isOpen={isPolicyInfoDrawerOpen}
                onClose={closePolicyInfoDrawer}
            />
            <PolicyDecisionDrawer
                isApproving={isAcceptDecision}
                isOpen={isPolicyDecisionDrawerOpen}
                onClose={closePolicyDecisionDrawer}
                onSubmit={onDecisionSubmit}
                testid="decision-drawer"
            />
        </>
    );
};

export default ApprovalsContainer;
