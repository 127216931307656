import { Copy } from "@maistro/components";
import React, { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";

import useProjectPublicData from "features/project/hooks/useProjectPublicData";
import { setPageTitle } from "store/layoutSlice";
import { ITheme } from "styles/themes/types";
import useAppDispatch from "hooks/useAppDispatch";

const useStyles = createUseStyles((theme: ITheme) => ({
    copy: {
        marginBottom: theme.spacing.xLarge,
    },
}));

const ExpressionOfInterestSuccess: React.FC = () => {
    const classes = useStyles();

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { projectUuid } = useParams();
    const { fetchProjectPublicData } = useProjectPublicData();

    const fetchData = useCallback(async () => {
        const projectPublicResponse = await fetchProjectPublicData(projectUuid);
        if (projectPublicResponse) {
            dispatch(
                setPageTitle(
                    t("expressionOfInterest.success.pageTitle", {
                        projectName: projectPublicResponse.projectName,
                        projectFriendlyId: projectPublicResponse.friendlyId,
                    }),
                ),
            );
        }
    }, [fetchProjectPublicData, projectUuid, dispatch, t]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return <Copy className={classes.copy}>{t("expressionOfInterest.success.copy")}</Copy>;
};

export default ExpressionOfInterestSuccess;
