import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Loader } from "components";
import SupplierClarificationQuestionsDisplay from "features/project/clarificationQuestions/supplierQuestions/SupplierClarificationQuestionsDisplay";
import useNavTabs from "features/project/hooks/useNavTabs";
import useProjectResponse from "features/project/hooks/useProjectResponse";
import useSupplierSubmissionClarificationQuestions from "features/project/hooks/useSupplierSubmissionClarificationQuestion";
import useAppDispatch from "hooks/useAppDispatch";
import useReduxSelector from "hooks/useReduxSelector";
import { buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { setBack, setPageTitle, setSecondaryNavItems, setTooltip } from "store/layoutSlice";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";

const sortDesc = (a: QuestionResponseDto, b: QuestionResponseDto) =>
    new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();

const SupplierClarificationQuestionsContainer: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const { projectResponseInformation } = useProjectResponse();
    const { projectUuid, responseUuid } = projectResponseInformation;
    const { supplierTabItems } = useNavTabs();
    const { clarificationQuestions, fetchQuestions } = useSupplierSubmissionClarificationQuestions(
        responseUuid,
        setIsLoading,
    );

    const { sections } = useReduxSelector((state) => state.sectionState);

    useEffect(() => {
        dispatch(setPageTitle(t("clarificationQuestions.titles.list")));
        dispatch(setTooltip(t("clarificationQuestions.tooltips.all")));
        dispatch(setSecondaryNavItems(supplierTabItems));
        dispatch(
            setBack({
                route: buildRoute(routes.projectResponses.brief, { projectUuid, responseUuid }),
            }),
        );

        return () => {
            dispatch(setSecondaryNavItems(null));
            dispatch(setPageTitle(null));
            dispatch(setTooltip(null));
            dispatch(setBack(null));
        };
    }, [dispatch, projectUuid, responseUuid, supplierTabItems, t]);

    useEffect(() => {
        fetchQuestions();
    }, [fetchQuestions]);

    if (isLoading) return <Loader />;
    if (!clarificationQuestions) return null;

    clarificationQuestions.sort(sortDesc);
    return (
        <SupplierClarificationQuestionsDisplay
            projectResponseInformation={projectResponseInformation}
            clarificationQuestions={clarificationQuestions}
            sections={sections.items}
        />
    );
};

export default SupplierClarificationQuestionsContainer;
