import { Popup, useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { searchCompanySuppliers } from "api/company/companyApi";
import { addIdentifiedSupplier, addInvitedSuppliers, removeProjectSupplier } from "api/project/projectSubmissionsApi";
import { Loader } from "components";
import { IOptionProps } from "components/shared";
import ProjectPreviewDrawer from "features/project/components/ProjectPreviewDrawer";
import useProject from "features/project/hooks/useProject";
import useProjectFiles from "features/project/hooks/useProjectFiles";
import { useTenderCriteria } from "features/project/hooks/useTenderCriteria";
import AddSupplierDrawer from "features/project/monitor/AddSupplierDrawer";
import MonitorDisplay from "features/project/monitor/MonitorDisplay";
import SubmissionAdditionalInformationDrawer from "features/project/monitor/SubmissionAdditionalInformationDrawer";
import SubmissionDocumentsDrawer from "features/project/monitor/SubmissionDocumentsDrawer";
import useMonitorLayout from "features/project/monitor/hooks/useMonitorLayout";
import useCurrentUser from "hooks/useCurrentUser";
import useDebouncedEffect from "hooks/useDebouncedEffect";
import useReduxSelector from "hooks/useReduxSelector";
import dateTimeService from "services/dateTimeService";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { BasicCompanyDto } from "types/dtos/company/BasicCompanyDto";
import { SubmissionAdditionalInformationDto } from "types/dtos/projects/supplierSubmissions/SubmissionAdditionalInformationDto";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import SupplierRelationshipStatus from "types/enums/companies/SupplierRelationshipStatus";
import SupplierSubmissionStatus from "types/enums/supplierSubmissions/SupplierSubmissionStatus";

const MonitorContainer: React.FC = () => {
    const [projectSuppliers, setProjectSuppliers] = useState<Array<SupplierSubmissionResponseDto>>([]);
    const [companySuppliers, setCompanySuppliers] = useState<Array<IOptionProps>>([]);
    const [amendedDeadline, setAmendedDeadline] = useState<Date>();
    const [documentSupplier, setDocumentSupplier] = useState<BasicCompanyDto>();
    const [additionalInformation, setAdditionalInformation] = useState<SubmissionAdditionalInformationDto>();
    const [isDocumentDrawerOpen, setIsDocumentDrawerOpen] = useState(false);
    const [isAdditionalInformationDrawerOpen, setIsAdditionalInformationDrawerOpen] = useState(false);
    const [isProjectPreviewOpen, setIsProjectPreviewOpen] = useState(false);
    const [isAddSupplierDrawerOpen, setIsAddSupplierDrawerOpen] = useState(false);
    const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
    const [isDeadlinePopupOpen, setIsDeadlinePopupOpen] = useState<boolean>(false);
    const [supplierSearchText, setSupplierSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();
    const toast = useToaster();

    const {
        projectInformation,
        cancelProject,
        updateProjectDeadline,
        isQuote,
        canUpdateProject,
        canAskQuestion,
        deadlineHasPassed,
        fetchProjectSuppliers,
        checkSuppliersResponded,
    } = useProject();
    const { projectFiles, fetchProjectFiles } = useProjectFiles(projectInformation.uuid);
    const { myUuid } = useCurrentUser();
    const { hasAnswers, isScoringCompleteForProject, getTenderCriteria, isScoringCompleteForUser } = useTenderCriteria({
        projectUuid: projectInformation.uuid,
    });

    const identifiedSuppliers = useMemo(
        () => projectSuppliers.filter((supplier) => supplier.status === SupplierSubmissionStatus.Identified),
        [projectSuppliers],
    );

    const { user_uuid: userUuid } = useReduxSelector((state) => state.authenticationState.accessToken);

    useMonitorLayout({
        isQuote,
        isLoading,
        setIsProjectPreviewOpen,
        setIsDocumentDrawerOpen,
        setIsAdditionalInformationDrawerOpen,
        canAskQuestion,
        projectUuid: projectInformation.uuid,
        documentSupplier,
        additionalInformation,
        projectStatus: projectInformation.status,
    });

    const fetchSuppliers = useCallback(async () => {
        const suppliersInformation = await fetchProjectSuppliers();
        setProjectSuppliers(suppliersInformation);
    }, [fetchProjectSuppliers]);

    const isTenderScoringAvailable = useMemo(() => {
        return hasAnswers && deadlineHasPassed;
    }, [deadlineHasPassed, hasAnswers]);

    const refreshSuppliersData = useCallback(async () => {
        setIsLoading(true);
        getTenderCriteria(myUuid);
        await fetchSuppliers();
        setIsLoading(false);
    }, [fetchSuppliers, getTenderCriteria, myUuid]);

    const hasSubmittedResponse = useMemo(
        () => checkSuppliersResponded(projectSuppliers),
        [checkSuppliersResponded, projectSuppliers],
    );

    const fetchData = useCallback(async () => {
        if (!projectInformation.uuid) return;

        setIsLoading(true);
        await fetchSuppliers();
        setIsLoading(false);
    }, [projectInformation.uuid, fetchSuppliers]);

    const updateResponseDeadline = useCallback(
        async (deadline: Date) => {
            setIsLoading(true);
            await updateProjectDeadline(deadline);
            setIsLoading(false);
        },
        [updateProjectDeadline],
    );

    const confirmAndUpdateResponseDeadline = useCallback(
        async (deadline: Date) => {
            if (identifiedSuppliers.length > 0 && dateTimeService.dateIsInPast(deadline)) {
                setAmendedDeadline(deadline);
                setIsDeadlinePopupOpen(true);
                return;
            }

            await updateResponseDeadline(deadline);
        },
        [identifiedSuppliers.length, updateResponseDeadline],
    );

    const continueDeadlineClosure = useCallback(async () => {
        setIsDeadlinePopupOpen(false);

        if (amendedDeadline) {
            await updateResponseDeadline(amendedDeadline);
        }
    }, [amendedDeadline, updateResponseDeadline]);

    const inviteSuppliers = useCallback(async () => {
        const response = await addInvitedSuppliers(projectInformation.uuid, {
            changedByUserUuid: userUuid,
            supplierUuids: identifiedSuppliers.map((supplier) => supplier.supplierCompanyUuid),
        });

        if (response.status !== 200 && response.status !== 404) {
            toast.error(t("monitorScreen.api.inviteSuppliersError"));
        }

        toast.success(t("monitorScreen.api.inviteSuppliersSuccess"));
        setIsAddSupplierDrawerOpen(false);
        fetchData();
    }, [fetchData, identifiedSuppliers, projectInformation.uuid, t, toast, userUuid]);

    const selectSupplier = async (supplierUuid: string) => {
        if (!projectInformation.uuid) return;

        const response = await addIdentifiedSupplier(projectInformation.uuid, supplierUuid);
        if (response instanceof TransactionErrorDto) {
            toast.error(t("projectMatchingScreen.api.addSupplierError"));
            return;
        }

        toast.success(t("projectMatchingScreen.api.addSupplierSuccess"));

        fetchSuppliers();
    };

    const unselectSupplier = async (supplierUuid: string) => {
        if (!projectInformation.uuid) return;

        await removeProjectSupplier(projectInformation.uuid, supplierUuid);

        fetchSuppliers();
    };

    const fetchAddableSuppliers = useCallback(
        (searchText: string) => {
            const projectCompanyUuid = projectInformation.companyUuid;
            if (!(projectCompanyUuid && projectSuppliers.length > 0)) return;

            searchCompanySuppliers({
                companyUuid: projectCompanyUuid,
                textSearch: searchText,
                relationshipStatuses: [SupplierRelationshipStatus.Approved, SupplierRelationshipStatus.Trial],
                includeInactive: false,
            }).then((response) => {
                if (response.data instanceof TransactionErrorDto) {
                    toast.error(t("projectMatchingScreen.api.supplierLoadingError"));
                    return;
                }

                if (response.data.companySuppliers) {
                    const projectSupplierUuids = projectSuppliers.map((supplier) => supplier.supplierCompanyUuid);
                    const addSupplierOptions = response.data.companySuppliers
                        .filter((supplier) => !projectSupplierUuids.includes(supplier.id))
                        .map((supplier) => ({ value: supplier.id, label: supplier.value }));

                    setCompanySuppliers(addSupplierOptions);
                }
            });
        },
        [projectInformation.companyUuid, projectSuppliers, t, toast],
    );

    useEffect(() => {
        fetchAddableSuppliers("");
    }, [fetchAddableSuppliers]);

    useDebouncedEffect(
        () => {
            fetchAddableSuppliers(supplierSearchText);
        },
        [supplierSearchText],
        500,
    );

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (isLoading) {
        return <Loader />;
    }
    return (
        <>
            <MonitorDisplay
                suppliers={projectSuppliers.filter(
                    (supplier) => supplier.status !== SupplierSubmissionStatus.Identified,
                )}
                project={projectInformation}
                refreshSuppliers={refreshSuppliersData}
                updateResponseDeadline={confirmAndUpdateResponseDeadline}
                setAdditionalInformation={setAdditionalInformation}
                setDocumentSupplier={setDocumentSupplier}
                setIsAddSupplierDrawerOpen={setIsAddSupplierDrawerOpen}
                fetchProjectFiles={fetchProjectFiles}
                conditions={{
                    isQuote,
                    isScoringAvailable: isTenderScoringAvailable && !isScoringCompleteForProject,
                    hasDeadlinePassed: deadlineHasPassed,
                    hasSubmittedResponse,
                    canUpdateProject,
                    isScoringCompleteForUser,
                }}
            />
            <Popup
                title={t("popups.cancelProject.title")}
                message={t("popups.cancelProject.message")}
                isOpen={isCancelPopupOpen}
                primaryActionText={t("popups.cancelProject.cta.primary")}
                onPrimaryAction={cancelProject}
                onClose={() => setIsCancelPopupOpen(false)}
                testid="cancel-project-popup"
            />
            <Popup
                title={t("popups.uninvitedSuppliers.title")}
                message={t("popups.uninvitedSuppliers.message")}
                isOpen={isDeadlinePopupOpen}
                primaryActionText={t("popups.uninvitedSuppliers.cta.primary")}
                onPrimaryAction={continueDeadlineClosure}
                secondaryActionText={t("popups.uninvitedSuppliers.cta.secondary")}
                onSecondaryAction={() => setIsDeadlinePopupOpen(false)}
                onClose={() => setIsDeadlinePopupOpen(false)}
                testid="uninvited-suppliers-popup"
            />

            <SubmissionDocumentsDrawer
                projectFiles={projectFiles}
                isOpen={isDocumentDrawerOpen}
                supplier={documentSupplier}
                setSupplier={setDocumentSupplier}
            />
            <SubmissionAdditionalInformationDrawer
                isOpen={isAdditionalInformationDrawerOpen}
                additionalInformation={additionalInformation}
                setAdditionalInformation={setAdditionalInformation}
            />
            <ProjectPreviewDrawer
                projectFiles={projectFiles}
                isOpen={isProjectPreviewOpen}
                close={() => setIsProjectPreviewOpen(false)}
            />
            <AddSupplierDrawer
                project={projectInformation}
                isOpen={isAddSupplierDrawerOpen}
                setIsAddSupplierDrawerOpen={setIsAddSupplierDrawerOpen}
                supplierOptions={companySuppliers}
                identifiedSuppliers={identifiedSuppliers}
                selectSupplier={selectSupplier}
                unselectSupplier={unselectSupplier}
                inviteSuppliers={inviteSuppliers}
                searchText={supplierSearchText}
                setSearchText={setSupplierSearchText}
            />
        </>
    );
};

export default MonitorContainer;
