import { LoadingScreen } from "@maistro/components";
import { Loader } from "components";
import ProjectPreviewDrawer from "features/project/components/ProjectPreviewDrawer";
import useNavTabs from "features/project/hooks/useNavTabs";
import useProject from "features/project/hooks/useProject";
import useProjectFiles from "features/project/hooks/useProjectFiles";
import { useTenderCriteria } from "features/project/hooks/useTenderCriteria";
import { useLayout } from "features/project/tenderCriteria/hooks/useLayout";
import { useTenderPermissions } from "features/project/tenderCriteria/hooks/useTenderPermissions";
import ScoreTenderSummaryBySupplierDisplay from "features/project/tenderCriteria/scoringSummaryBySupplier/ScoreTenderSummaryBySupplierDisplay";
import useAppDispatch from "hooks/useAppDispatch";
import useCurrentUser from "hooks/useCurrentUser";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { resetLayout } from "store/layoutSlice";
import { ScoreResponseDto } from "types/dtos/questions/ScoreResponseDto";

const ScoreTenderSummaryBySupplierContainer: React.FC = () => {
    const { supplierUuid } = useParams();
    const [isDownloading, setIsDownloading] = useState(false);
    const [isProjectPreviewOpen, setIsProjectPreviewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { buyerTabItems } = useNavTabs();
    const { myUuid: userUuid, userHasProjectPermission } = useCurrentUser();
    const { projectInformation, isFetching: isFetchingProject } = useProject();

    const {
        sections,
        totalContributorScorers,
        isScoringCompleteForUser,
        isScoringCompleteForProject,
        setQuestions,
        downloadTenderResponsesReport,
        isFetching: isFetchingTender,
        contributorsForSuppliers,
        filteredQuestions,
    } = useTenderCriteria({
        projectUuid: projectInformation.uuid,
        supplierUuid,
    });
    const isScoringComplete = isScoringCompleteForUser || isScoringCompleteForProject;
    const { canAskQuestion, canModerateScores } = useTenderPermissions({
        isScoringComplete,
        projectInformation,
        userHasProjectPermission,
    });
    const userIsProjectManager = userUuid === projectInformation.managerUuid;
    const { projectFiles, projectHasFilesOfAnyKind, isFetchingFiles } = useProjectFiles(projectInformation.uuid);
    const displayScoringClosedMessage = !isScoringCompleteForUser && isScoringCompleteForProject;

    const contributorsForSupplier = contributorsForSuppliers.find(
        (contributors) => contributors.supplierUuid === supplierUuid,
    );

    const completedContributorScorers = contributorsForSupplier ? contributorsForSupplier.contributorsUuids.length : 0;

    const handleDownload = useCallback(() => {
        setIsDownloading(true);
        downloadTenderResponsesReport().finally(() => setIsDownloading(false));
    }, [downloadTenderResponsesReport]);

    const setScore = useCallback(
        (score: ScoreResponseDto, questionUuid: string) => {
            setQuestions(score, questionUuid);
        },
        [setQuestions],
    );

    useEffect(() => {
        setIsLoading(isFetchingTender || isFetchingProject || isFetchingFiles);
    }, [isFetchingProject, isFetchingTender, isFetchingFiles]);

    const { setLayout } = useLayout({
        canModerateScores,
        canAskQuestion,
        projectInformation,
        handleDownload,
        setIsProjectPreviewOpen,
    });

    useEffect(() => {
        setLayout();
        return () => {
            dispatch(resetLayout());
        };
    }, [buyerTabItems, canAskQuestion, canModerateScores, dispatch, handleDownload, projectInformation, setLayout, t]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            {isDownloading && (
                <LoadingScreen
                    title={t("tenderCriteria.score.export.title")}
                    message={t("tenderCriteria.score.export.message")}
                    duration={35000}
                    testid="loading-screen"
                />
            )}
            <ScoreTenderSummaryBySupplierDisplay
                projectInformation={projectInformation}
                completedContributorScorers={completedContributorScorers}
                totalContributorScorers={totalContributorScorers}
                questions={filteredQuestions}
                sections={sections}
                isProjectManager={userIsProjectManager}
                isScoringComplete={isScoringComplete}
                displayScoringClosedMessage={displayScoringClosedMessage}
                projectHasFiles={projectHasFilesOfAnyKind}
                setScore={setScore}
            />
            <ProjectPreviewDrawer
                projectFiles={projectFiles}
                isOpen={isProjectPreviewOpen}
                close={() => setIsProjectPreviewOpen(false)}
            />
        </>
    );
};

export default ScoreTenderSummaryBySupplierContainer;
