import { Copy, useToaster } from "@maistro/components";
import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import {
    getProjectSections,
    getScoringSectionImportanceLevels,
    updateProjectSectionWeightings,
} from "api/questions/tenderCriteriaQuestionsApi";
import { Loader } from "components";
import TitleCtaType from "components/Layout/types/TitleCtaType";
import ProjectPreviewDrawer from "features/project/components/ProjectPreviewDrawer";
import useProject from "features/project/hooks/useProject";
import useProjectFiles from "features/project/hooks/useProjectFiles";
import SectionWeightingForm from "features/project/tenderCriteria/weighting/SectionWeightingForm";
import useAppDispatch from "hooks/useAppDispatch";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import validationService from "services/validationService";
import { resetLayout, setBack, setPageTitle, setTitleCtas, setTooltip } from "store/layoutSlice";
import { ITheme } from "styles/themes/types";
import { ProjectSectionWeightings } from "types/dtos/questions/sections/ProjectSectionWeightings";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";
import { SectionImportanceLevel } from "types/dtos/questions/sections/SectionImportanceLevel";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import ImportanceLevel from "types/enums/questions/sections/ImportanceLevel";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        maxWidth: 1200,
    },

    error: {
        marginTop: theme.spacing.medium,
    },
}));

interface IProjectParams {
    projectUuid: string;
}

const SectionWeighting: React.FC = () => {
    const classes = useStyles();

    const [importanceLevels, setImportanceLevels] = useState<SectionImportanceLevel[]>([]);
    const [sections, setSections] = useState<SectionDto[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isProjectPreviewOpen, setIsProjectPreviewOpen] = useState(false);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const toast = useToaster();
    const navigate = useNavigate();
    const params = useParams();
    const { projectUuid } = params as unknown as IProjectParams;

    const { projectInformation } = useProject();
    const { projectFiles } = useProjectFiles(projectUuid);

    useEffect(() => {
        dispatch(setPageTitle(t("tenderCriteria.weighting.title")));
        dispatch(setTooltip(t("tenderCriteria.weighting.titleTooltip")));
        dispatch(
            setBack({
                route: buildRoute(routes.projects.sections, { projectUuid }),
            }),
        );

        dispatch(
            setTitleCtas([
                {
                    type: TitleCtaType.ProjectPreview,
                    onClick: () => setIsProjectPreviewOpen(true),
                },
            ]),
        );

        return () => {
            dispatch(resetLayout());
        };
    }, [dispatch, projectUuid, t]);

    const byId = (a: SectionImportanceLevel, b: SectionImportanceLevel) => {
        return b.id - a.id;
    };

    const fetchData = useCallback(async () => {
        if (!(projectUuid && validationService.isValidUuid(projectUuid))) return;

        const importanceLevelsResponse = await getScoringSectionImportanceLevels();
        if (importanceLevelsResponse.data instanceof TransactionErrorDto || importanceLevelsResponse.status !== 200) {
            toast.error(t("tenderCriteria.weighting.api.listImportanceLevelsError"));
            setIsLoading(false);
            return;
        }
        const sortedImportanceLevels = [...importanceLevelsResponse.data.sectionImportanceLevels].sort(byId);
        setImportanceLevels(sortedImportanceLevels);

        const projectSectionsResponse = await getProjectSections(projectUuid);
        if (projectSectionsResponse.data instanceof TransactionErrorDto || projectSectionsResponse.status !== 200) {
            toast.error(t("tenderCriteria.weighting.api.getProjectSectionsError"));
            setIsLoading(false);
            return;
        }
        setSections(projectSectionsResponse.data.sections);
        setIsLoading(false);
    }, [projectUuid, t, toast]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const submitWeightings = useCallback(
        async (values: ProjectSectionWeightings, goToNextPage: boolean) => {
            setIsSubmitting(true);

            const response = await updateProjectSectionWeightings({
                projectUuid,
                ...values,
            });

            if (response.data instanceof TransactionErrorDto || response.status !== 204) {
                toast.error(t("tenderCriteria.weighting.api.updateProjectSectionWeightingsError"));
                setIsSubmitting(false);
                return;
            }

            toast.success(t("tenderCriteria.weighting.api.updateProjectSectionWeightingsSuccess"));
            setIsSubmitting(false);

            if (goToNextPage) {
                navigate(buildPath(routes.projects.sectionQuestions, { projectUuid }));
            }
        },
        [projectUuid, t, toast, navigate],
    );

    const initialValues: ProjectSectionWeightings = {
        sectionWeightings: sections.map((section) => ({
            id: section.id,
            importanceLevel: section.importanceLevel,
            weighting: section.weighting,
        })),
    };

    const validationSchema = Yup.object({
        sectionWeightings: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.string().required(),
                    importanceLevel: Yup.mixed<ImportanceLevel>().optional().nullable(),
                    weighting: Yup.number().optional().nullable().min(0).max(100),
                }),
            )
            .required()
            .test("total", t("tenderCriteria.weighting.totalError"), (values) => {
                if (!values) return false;

                const percentage = values.reduce((total, section) => total + (section.weighting || 0), 0);
                return percentage === 100;
            }),
    }).required();

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className={classes.container}>
            <Copy testid="weighting-copy">{t("tenderCriteria.weighting.copy")}</Copy>
            {sections.length === 0 && (
                <Copy className={classes.error} variant="error" testid="no-sections-error">
                    {t("tenderCriteria.weighting.noSections")}
                </Copy>
            )}
            {sections.length > 0 && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    validateOnMount
                    onSubmit={(values) => submitWeightings(values, true)}
                >
                    <SectionWeightingForm
                        sections={sections}
                        importanceLevels={importanceLevels}
                        submit={submitWeightings}
                        isSubmitting={isSubmitting}
                        projectUuid={projectUuid}
                        setSections={setSections}
                    />
                </Formik>
            )}
            <ProjectPreviewDrawer
                projectFiles={projectFiles}
                isOpen={isProjectPreviewOpen}
                close={() => setIsProjectPreviewOpen(false)}
                allowProjectEdit
                navigationState={{
                    route: buildRoute(routes.projects.weighting, { projectUuid: projectInformation.uuid }),
                }}
            />
        </div>
    );
};

export default SectionWeighting;
