import { createUseStyles } from "react-jss";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    row: {
        display: "flex",
        flexDirection: "row",
        columnGap: theme.spacing.medium,
    },

    column: {
        display: "flex",
        flexDirection: "column",
        flexBasis: "100%",
        flex: 1,

        "& > div:not(:last-child)": {
            marginBottom: theme.spacing.medium,
        },
    },

    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        row: {
            flexDirection: "column",
            gap: theme.spacing.medium,
        },
    },
}));

const useProfileStyles = () => useStyles();

export default useProfileStyles;
