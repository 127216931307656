import { Button } from "@maistro/components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import { faMessageQuestion } from "@fortawesome/pro-regular-svg-icons";
import { ICommonProps } from "components/shared";
import ClarificationQuestionsTable from "features/project/clarificationQuestions/ClarificationQuestionsTable";
import NoClarificationQuestions from "features/project/clarificationQuestions/NoClarificationQuestions";
import ProjectInformationDisplay from "features/project/clarificationQuestions/ProjectInformationDisplay";
import { IProjectResponseInformation } from "features/project/types";
import ReactGA from "react-ga4";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { ITheme } from "styles/themes/types";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import ProjectStatus from "types/enums/projects/ProjectStatus";

interface ISupplierClarificationQuestionsDisplayProps extends ICommonProps {
    projectResponseInformation: IProjectResponseInformation;
    clarificationQuestions: Array<QuestionResponseDto>;
    sections: Array<SectionDto>;
}

const useStyles = createUseStyles((theme: ITheme) => ({
    button: {
        margin: `${theme.spacing.medium}px 0`,
    },
}));

const SupplierClarificationQuestionsDisplay: React.FC<ISupplierClarificationQuestionsDisplayProps> = (props) => {
    const classes = useStyles();

    const { projectResponseInformation, clarificationQuestions, sections } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateToQuestion = (questionUuid: string) => {
        navigate(
            buildPath(routes.projectResponses.viewClarificationQuestion, {
                projectUuid: projectResponseInformation.projectUuid,
                questionUuid,
                responseUuid: projectResponseInformation.responseUuid,
            }),
            {
                state: {
                    route: buildRoute(routes.projectResponses.brief, {
                        projectUuid: projectResponseInformation.projectUuid,
                        responseUuid: projectResponseInformation.responseUuid,
                    }),
                },
            },
        );
    };

    const navigateToAskAQuestion = () => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: "Clarification Questions - Ask Question",
        });
        navigate(
            buildPath(routes.projectResponses.askClarificationQuestion, {
                projectUuid: projectResponseInformation.projectUuid,
                responseUuid: projectResponseInformation.responseUuid,
            }),
        );
    };

    const questionsLockedStatuses = useMemo(
        () => [ProjectStatus.Complete.toString(), ProjectStatus.CompleteAtReview.toString()],
        [],
    );

    return (
        <>
            <ProjectInformationDisplay
                projectName={projectResponseInformation.projectName}
                responseDate={projectResponseInformation.tenderResponseDeadline}
            />
            {clarificationQuestions.length < 1 && (
                <NoClarificationQuestions>
                    {!questionsLockedStatuses.includes(projectResponseInformation.projectStatus) && (
                        <Button
                            className={classes.button}
                            label={t("clarificationQuestions.buttons.askAQuestion")}
                            onClick={navigateToAskAQuestion}
                            testid="ask-question-button"
                            icon={faMessageQuestion}
                            iconPosition="left"
                        />
                    )}
                </NoClarificationQuestions>
            )}
            {clarificationQuestions.length >= 1 && (
                <>
                    {!questionsLockedStatuses.includes(projectResponseInformation.projectStatus) && (
                        <Button
                            className={classes.button}
                            label={t("clarificationQuestions.buttons.askAQuestion")}
                            onClick={navigateToAskAQuestion}
                            testid="ask-question-button"
                            icon={faMessageQuestion}
                            iconPosition="left"
                        />
                    )}
                    <ClarificationQuestionsTable
                        clarificationQuestions={clarificationQuestions}
                        navigateToQuestion={navigateToQuestion}
                        testid="supplier-clarification-questions-table"
                        buyerCompanyUuid={projectResponseInformation.buyerCompanyUuid}
                        projectUuid={projectResponseInformation.projectUuid}
                        sections={sections}
                        projectType={projectResponseInformation.projectType}
                    />
                </>
            )}
        </>
    );
};

export default SupplierClarificationQuestionsDisplay;
