import { useToaster } from "@maistro/components";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { getUserProfile, updateUserProfile } from "api/usersApi";
import useAppDispatch from "hooks/useAppDispatch";
import useReduxSelector from "hooks/useReduxSelector";
import apiErrorService from "services/apiErrorService";
import { setUserName } from "store/authenticationSlice";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { PersonalProfileDto } from "types/dtos/users/PersonalProfileDto";
import { UpdateUserProfileRequestDto } from "types/dtos/users/UpdateUserProfileRequestDto";

const useUserProfile = () => {
    const [userProfile, setUserProfile] = useState<PersonalProfileDto>();
    const [isOpen, setIsOpen] = useState(false);
    const { user_uuid: userUuid } = useReduxSelector((state) => state.authenticationState.accessToken);

    const { t } = useTranslation();
    const toast = useToaster();
    const dispatch = useAppDispatch();

    const getProfile = useCallback(async () => {
        if (userUuid) {
            const profileResponse = await getUserProfile(userUuid);
            if (profileResponse.data instanceof TransactionErrorDto || profileResponse.status !== 200) {
                return;
            }

            setUserProfile(profileResponse.data);
        }
    }, [userUuid]);

    const updateProfile = useCallback(
        async (dto: UpdateUserProfileRequestDto) => {
            const response = await updateUserProfile(dto);
            if (response.data instanceof TransactionErrorDto) {
                toast.error(apiErrorService.getFirstErrorFromResponse(response.data));
            }

            dispatch(setUserName(`${dto.firstName} ${dto.lastName}`));
            toast.success(t("users.profile.success"));
            setIsOpen(false);
        },
        [t, toast, dispatch],
    );

    return { getProfile, isProfileOpen: isOpen, setIsProfileOpen: setIsOpen, updateProfile, userProfile };
};

export default useUserProfile;
