/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button, Copy, Label, Popup, useToaster } from "@maistro/components";
import React, { useCallback, useRef, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { uploadToTempStorage } from "api/fileApi";
import { ICommonProps } from "components/shared";
import apiErrorService from "services/apiErrorService";
import fileService from "services/fileService";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "block",
        flexDirection: "column",
    },
    inputContainer: {
        display: "block",
        gap: theme.spacing.small,
        alignItems: "center",
    },
    instructionsContainer: {
        marginTop: theme.spacing.xSmall,
        marginLeft: theme.spacing.xSmall,
    },
    instructions: {
        fontSize: 11,
        fontWeight: 500,
    },
    button: {
        minWidth: 90,
    },
    content: {
        display: "block",
        flexDirection: "row",
        marginTop: theme.spacing.small,
    },
}));

export interface IFileUploadFieldProps extends ICommonProps {
    setFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    label?: string;
    name: string;
    buttonLabel?: string;
    multiple?: boolean;
    disabled?: boolean;
    required?: boolean;
    acceptedExtensions?: string[];
    fileSizeLimitInMb?: number;
    fileIsUploading: boolean;
    setFileIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
    files?: Array<FileDto>;
}

const FileUploadField: React.FC<IFileUploadFieldProps> = (props) => {
    const classes = useStyles();

    const {
        label,
        name,
        buttonLabel,
        multiple,
        disabled,
        required,
        setFiles,
        fileIsUploading,
        setFileIsUploading,
        fileSizeLimitInMb,
        testid,
        files,
    } = props;

    const { t } = useTranslation();
    const toast = useToaster();
    const fileInput = useRef<HTMLInputElement>(null);
    const [isSingleFilePopupOpen, setIsSingleFilePopupOpen] = useState(false);

    const acceptedExtensions = useMemo(() => {
        return props.acceptedExtensions ?? [""];
    }, [props.acceptedExtensions]);

    const openFileDialog = () => {
        setIsSingleFilePopupOpen(false);
        if (fileInput.current) {
            fileInput.current.value = "";
            fileInput.current.click();
        }
    };

    const uploadFile = useCallback(
        async (file: File) => {
            if (file.size > fileService.toBytesBinary(fileSizeLimitInMb!)) {
                toast.error(t("fileUpload.maxFileSizeExceeded", { fileName: file.name }));
                return;
            }

            const fileType = fileService.getExtensionFromName(file.name);
            if (!acceptedExtensions.includes(fileType.toLowerCase())) {
                toast.error(t("fileUpload.fileTypeNotSupported", { fileType }));
                return;
            }

            const response = await uploadToTempStorage(file);
            if (response.data instanceof TransactionErrorDto) {
                const errorResponse = apiErrorService.getFirstErrorFromResponse(response.data);
                if (errorResponse.length < 25) {
                    toast.error(
                        t(`fileUpload.errors.${apiErrorService.getFirstErrorFromResponse(response.data)}`, {
                            fileName: file.name,
                        }),
                    );
                } else {
                    toast.error(errorResponse);
                }
                return;
            }

            if (response.status !== 200) {
                toast.error(t("fileUpload.individualUploadError", { fileName: file.name }));
                return;
            }

            toast.success(t("fileUpload.individualUploadSuccess", { fileName: file.name }));
            if (multiple) {
                setFiles((prevState) => [...prevState, { ...response.data, newUpload: true } as FileDto]);
            } else {
                setFiles([{ ...response.data, newUpload: true } as FileDto]);
            }
        },
        [acceptedExtensions, fileSizeLimitInMb, multiple, setFiles, t, toast],
    );

    const uploadFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files: selectedFiles } = event.target;

        if (!selectedFiles || selectedFiles.length === 0) {
            setFiles([]);
            return;
        }

        try {
            setFileIsUploading(true);
            const fileUploads = Array.from(selectedFiles).map((file) => uploadFile(file));
            await Promise.all(fileUploads);
        } catch {
            toast.error(t("fileUpload.generalUploadError"));
        } finally {
            setFileIsUploading(false);
        }
    };

    const handleUploadFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
        uploadFiles(event).catch(() => toast.error(t("fileUpload.generalUploadError")));
    };

    return (
        <div className={classes.container} data-component="FileUploadField" data-testid={testid}>
            {label && <Label content={label} name={name} required={required} />}
            <div className={classes.inputContainer}>
                <input
                    type="file"
                    accept={acceptedExtensions.join(",")}
                    multiple={multiple}
                    hidden
                    ref={fileInput}
                    onChange={handleUploadFiles}
                    data-testid={`${testid}-input`}
                />
                <div className={classes.content}>
                    <Button
                        className={classes.button}
                        label={fileIsUploading ? t("fileUpload.uploading") : buttonLabel!}
                        variant="outline"
                        size="small"
                        disabled={disabled}
                        onClick={
                            multiple || !files || files.length < 1
                                ? openFileDialog
                                : () => setIsSingleFilePopupOpen(true)
                        }
                        loading={fileIsUploading}
                        testid={`${testid}-button`}
                    />
                    <div className={classes.instructionsContainer}>
                        <Copy className={classes.instructions}>
                            <>
                                {t("fileUpload.headings.maximumFileSize")} <span>{fileSizeLimitInMb}MB</span>
                            </>
                        </Copy>
                        <Copy className={classes.instructions}>
                            <>
                                {t("fileUpload.headings.acceptedFileTypes")}{" "}
                                <span>
                                    {acceptedExtensions
                                        .map((extension) => fileService.toFileType(extension))
                                        .join(", ")}
                                </span>
                            </>
                        </Copy>
                    </div>
                </div>
            </div>
            <Popup
                testid="confirm-file-upload-popup"
                isOpen={isSingleFilePopupOpen}
                onPrimaryAction={openFileDialog}
                primaryActionText={t("common.continue")}
                onClose={() => setIsSingleFilePopupOpen(false)}
                onSecondaryAction={() => setIsSingleFilePopupOpen(false)}
                secondaryActionText={t("common.cancel")}
                title={t("fileUpload.popup.singleFileTitle")}
                message={t("fileUpload.popup.singleFileMessage")}
            />
        </div>
    );
};

FileUploadField.defaultProps = {
    buttonLabel: "Select File",
    multiple: true,
    disabled: false,
    required: false,
    acceptedExtensions: [""],
    fileSizeLimitInMb: 50,
} as IFileUploadFieldProps;

export default FileUploadField;
