import { faEdit, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { ITableColumn, Icon, Pill, Table } from "@maistro/components";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ICommonProps } from "components/shared";
import { ProjectUserDto } from "types/dtos/projects/users/ProjectUserDto";

interface IProjectUsersGridProps extends ICommonProps {
    projectUsers: ProjectUserDto[];
    onEdit?: (row: ProjectUserDto) => void;
    onDelete?: (row: ProjectUserDto) => void;
}

const ProjectUsersGrid: React.FC<IProjectUsersGridProps> = (props) => {
    const { t } = useTranslation();

    const { projectUsers, onEdit, onDelete, className } = props;

    const pillColorForStatus = useCallback((status: ProjectUserDto["status"]) => {
        switch (status) {
            case "Active":
                return "positive";
            case "Locked":
                return "negative";
            case "Suspended":
                return "gray";
            case "Invited":
            default:
                return "primary";
        }
    }, []);

    const columns = useMemo(() => {
        const baseColumns: ITableColumn[] = [
            {
                position: 1,
                priorityLevel: 1,
                label: t("common.users.firstName"),
                name: "firstName",
                minWidth: 150,
            },
            {
                position: 2,
                priorityLevel: 2,
                label: t("common.users.lastName"),
                name: "lastName",
                minWidth: 150,
            },
            {
                position: 3,
                priorityLevel: 3,
                label: t("common.users.emailAddress"),
                name: "emailAddress",
                minWidth: 200,
                contentWrap: true,
            },
            {
                position: 4,
                priorityLevel: 4,
                label: t("common.users.roleType"),
                name: "roleName",
                minWidth: 150,
                render: (user: ProjectUserDto) => t(`roles.names.${user.role.name}`),
            },
            {
                position: 5,
                priorityLevel: 5,
                label: t("common.status"),
                name: "status",
                minWidth: 100,
                render: (user: ProjectUserDto) => <Pill value={user.status} color={pillColorForStatus(user.status)} />,
            },
        ];
        if (onEdit) {
            baseColumns.push({
                position: 6,
                priorityLevel: 0,
                name: "edit",
                minWidth: 50,
                render: (user: ProjectUserDto) => (
                    <Icon icon={faEdit} alt={t("common.edit")} onClick={() => onEdit(user)} />
                ),
            });
        }
        if (onDelete) {
            baseColumns.push({
                position: 7,
                priorityLevel: 0,
                name: "delete",
                minWidth: 50,
                render: (user: ProjectUserDto) => (
                    <Icon icon={faTrashCan} alt={t("common.delete")} onClick={() => onDelete(user)} />
                ),
            });
        }

        return baseColumns;
    }, [onDelete, onEdit, pillColorForStatus, t]);

    return (
        <Table
            className={className}
            columns={columns}
            data={projectUsers}
            noDataDisplay={t("common.noResults")}
            testid={props.testid}
        />
    );
};

export default ProjectUsersGrid;
