import { Button, Copy } from "@maistro/components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import useFeedback from "features/feedback/hooks/useFeedback";
import useAppDispatch from "hooks/useAppDispatch";
import useQuery from "hooks/useQuery";
import routes from "routes/routePaths/RoutePaths";
import { setBack, setPageTitle } from "store/layoutSlice";
import { ITheme } from "styles/themes/types";
import EmailFeedbackStatus from "types/enums/feedback/EmailFeedbackStatus";

const useStyles = createUseStyles((theme: ITheme) => ({
    button: {
        marginTop: theme.spacing.large,
    },
}));

const SupplierFeedback = () => {
    const classes = useStyles();

    const urlSearchParameters = useQuery();
    const emailToken = urlSearchParameters.get("token");
    const score = urlSearchParameters.get("score");
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { feedbackResponse } = useFeedback(emailToken, score);
    const [copyText, setCopyText] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(
            setBack({
                route: routes.common.dashboard,
            }),
        );

        switch (feedbackResponse?.status) {
            case EmailFeedbackStatus.Ok:
                dispatch(setPageTitle(t("supplierFeedback.success.title")));
                setCopyText(t("supplierFeedback.success.copy"));
                break;
            case EmailFeedbackStatus.Duplicate:
                dispatch(setPageTitle(t("supplierFeedback.duplicate.title")));
                setCopyText(t("supplierFeedback.duplicate.copy"));
                break;
            case EmailFeedbackStatus.TokenExpired:
                dispatch(setPageTitle(t("supplierFeedback.expired.title")));
                setCopyText(t("supplierFeedback.expired.copy"));
                break;
            case EmailFeedbackStatus.TokenNotRecognised:
                dispatch(setPageTitle(t("supplierFeedback.invalid.title")));
                setCopyText(t("supplierFeedback.invalid.copy"));
                break;
            default:
                break;
        }
    }, [dispatch, t, feedbackResponse]);

    return (
        <React.Fragment>
            <Copy>{copyText}</Copy>
            <Button
                className={classes.button}
                testid="back-to-dashboard-button"
                label={t("common.backTo", {
                    options: {
                        page: t("common.pages.dashboard"),
                    },
                })}
                onClick={() => {
                    navigate(routes.common.dashboard.path);
                }}
                type="button"
            />
        </React.Fragment>
    );
};

export default SupplierFeedback;
