import { ITableColumn, Table } from "@maistro/components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";

interface DisplayData extends SupplierSubmissionResponseDto {
    id?: string;
    supplierCompanyName: string;
    matchRating: number;
    expandRowSummary?: boolean;
    extraContent?: JSX.Element;
}

interface ISupplierTableProps {
    suppliers?: Array<SupplierSubmissionResponseDto>;
    displayData?: Array<DisplayData>;
    columns: ITableColumn[];
    canAwardSupplier: boolean;
    sortingAlgorithm: (a: SupplierSubmissionResponseDto, b: SupplierSubmissionResponseDto) => number;
}

const SupplierTable: React.FC<ISupplierTableProps> = (props) => {
    const { suppliers, columns, canAwardSupplier, displayData, sortingAlgorithm } = props;

    const { t } = useTranslation();

    const [data, setData] = useState<DisplayData[]>([]);
    const [sortedData, setSortedData] = useState<DisplayData[]>([]);

    useEffect(() => {
        if (suppliers) {
            const initialData = suppliers
                .filter((supplier) => !!supplier.scoring || !canAwardSupplier)
                .map((supplier) => ({
                    ...supplier,
                    supplierCompanyName: supplier._embedded.supplierCompanyName,
                    matchRating: supplier._included.matchRating * 5,
                }));
            setData(initialData);
        }
    }, [suppliers, canAwardSupplier]);

    useEffect(() => {
        if (displayData) {
            setData(displayData);
        }
    }, [displayData]);

    useEffect(() => {
        if (sortingAlgorithm) {
            const sorted = [...data].sort(sortingAlgorithm);
            setSortedData(sorted);
        }
    }, [data, sortingAlgorithm]);

    return <Table columns={columns} data={sortedData} noDataDisplay={t("common.noResults")} testid="suppliers-table" />;
};

export default SupplierTable;
