import React from "react";
import { Route, Routes } from "react-router-dom";

import GuardedRoute from "features/auth/GuardedRoute";
import ApprovalsContainer from "features/company/buyer/approvals/ApprovalsContainer";
import CreateUserGroupContainer from "features/company/buyer/userGroups/CreateUserGroupContainer";
import UserGroupsContainer from "features/company/buyer/userGroups/UserGroupsContainer";
import CompanyAddressesContainer from "features/company/profile/addresses/CompanyAddressesContainer";
import BuyerListsContainer from "features/company/profile/buyer-lists/BuyerListsContainer";
import EditCompanyProfileContainer from "features/company/profile/edit/EditCompanyProfileContainer";
import CompanyServicesContainer from "features/company/profile/services/CompanyServicesContainer";
import SupplierProfileContainer from "features/company/profile/supplier/SupplierProfileContainer";
import BuyerSupplierContracts from "features/company/profile/suppliers/BuyerSupplierContracts";
import CompanySupplierListContainer from "features/company/profile/suppliers/CompanySupplierListContainer";
import CompanyUsersContainer from "features/company/profile/users/CompanyUsersContainer";
import CompanyVettingQuestionnaireContainer from "features/company/profile/vetting/CompanyVettingQuestionnaireContainer";
import routes from "routes/routePaths/RoutePaths";
import FeatureToggle from "types/enums/companies/FeatureToggle";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

const CompaniesRouter: React.FC = () => {
    return (
        <Routes>
            <Route path="" element={<GuardedRoute />}>
                <Route path="" element={<GuardedRoute permission={PermissionsEnum.UpdateCompany} />}>
                    <Route path="" element={<EditCompanyProfileContainer />} />
                </Route>
                <Route path={routes.company.supplierCompanyProfile.path} element={<SupplierProfileContainer />} />
                <Route
                    path={routes.company.companySuppliers.path}
                    element={<GuardedRoute permission={PermissionsEnum.ReadSupplierRelationshipStatus} />}
                >
                    <Route path="" element={<CompanySupplierListContainer />} />
                </Route>
                <Route
                    path={routes.company.companyContracts.path}
                    element={<GuardedRoute permission={PermissionsEnum.ReadSupplierRelationshipStatus} />}
                >
                    <Route path="" element={<BuyerSupplierContracts />} />
                </Route>
                <Route
                    path={routes.company.approvals.path}
                    element={<GuardedRoute feature={FeatureToggle.Approvals} />}
                >
                    <Route path="" element={<ApprovalsContainer />} />
                </Route>
                <Route
                    path={routes.company.userGroups.path}
                    element={
                        <GuardedRoute permission={PermissionsEnum.UpdateCompany} feature={FeatureToggle.Approvals} />
                    }
                >
                    <Route path="" element={<UserGroupsContainer />} />
                </Route>
                <Route
                    path={routes.company.userGroup.path}
                    element={
                        <GuardedRoute permission={PermissionsEnum.UpdateCompany} feature={FeatureToggle.Approvals} />
                    }
                >
                    <Route path="" element={<CreateUserGroupContainer />} />
                </Route>
                <Route
                    path={routes.company.companyAddresses.path}
                    element={<GuardedRoute permission={PermissionsEnum.UpdateCompany} />}
                >
                    <Route path="" element={<CompanyAddressesContainer />} />
                </Route>
                <Route
                    path={routes.company.companyUsers.path}
                    element={<GuardedRoute permission={PermissionsEnum.UpdateCompany} />}
                >
                    <Route path="" element={<CompanyUsersContainer />} />
                </Route>
                <Route
                    path={routes.company.companyServices.path}
                    element={<GuardedRoute permission={PermissionsEnum.UpdateCompany} />}
                >
                    <Route path="" element={<CompanyServicesContainer />} />
                </Route>
                <Route
                    path={routes.company.companyVetting.path}
                    element={<GuardedRoute permission={PermissionsEnum.UpdateCompany} />}
                >
                    <Route path="" element={<CompanyVettingQuestionnaireContainer />} />
                </Route>
                <Route
                    path={routes.company.companyBuyerLists.path}
                    element={<GuardedRoute permission={PermissionsEnum.UpdateCompany} />}
                >
                    <Route path="" element={<BuyerListsContainer />} />
                </Route>
            </Route>
        </Routes>
    );
};

export default CompaniesRouter;
