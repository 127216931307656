import { getCompanyEmailSettings } from "api/company/companyApi";
import useReduxSelector from "hooks/useReduxSelector";
import { useCallback, useEffect, useState } from "react";
import { CompanyEmailSettingsDto } from "types/dtos/company/CompanyEmailSettingsDto";

const useContactSupport = () => {
    const [emailSettings, setEmailSettings] = useState<CompanyEmailSettingsDto>({
        companyUuid: "",
        defaultFromAddress: "",
    });

    const accessToken = useReduxSelector((state) => state.authenticationState?.accessToken);

    useEffect(() => {
        if (accessToken?.company_uuid) {
            getCompanyEmailSettings(accessToken.company_uuid).then((response) => {
                if (response.status === 200) {
                    setEmailSettings(response.data);
                }
            });
        }
    }, [accessToken]);

    const sendEmail = useCallback(() => {
        window.open(`mailto:${emailSettings.defaultFromAddress}?subject=${encodeURIComponent("Support Request")}`);
    }, [emailSettings.defaultFromAddress]);

    return {
        sendEmail,
    };
};

export default useContactSupport;
