import { AxiosResponse } from "axios";

import { createAxiosInstance } from "api/commonApi";
import env from "config/EnvironmentVariables";
import { GenerateProjectBriefRequestDto } from "types/dtos/projects/aiAssist/GenerateProjectBriefRequestDto";
import { GenerateProjectBriefResponseDto } from "types/dtos/projects/aiAssist/GenerateProjectBriefResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { GenerateTenderQuestionsResponseDto } from "types/dtos/projects/aiAssist/GenerateTenderQuestionsResponseDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);

export const generateProjectBrief = (
    request: GenerateProjectBriefRequestDto,
): Promise<AxiosResponse<GenerateProjectBriefResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `/projects/${request.projectUuid}/generate/brief`;
    return api.post(url, request);
};

export const generateTenderQuestions = (
    projectUuid: string,
): Promise<AxiosResponse<GenerateTenderQuestionsResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `/projects/${projectUuid}/generate/questions`;
    return api.post(url, {});
};
