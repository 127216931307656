import { Popup, useToaster } from "@maistro/components";
import React, { Dispatch, SetStateAction } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { useUpdateCompanyStatusMutation } from "store/api/companyApi";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

interface ICompanyProfileActionPopup {
    isCompanyProfileActionPopupOpen: boolean;
    setIsCompanyProfileActionPopupOpen: Dispatch<SetStateAction<boolean>>;
    isBuyer?: boolean;
    isActive?: boolean;
    companyUuid?: string;
    registeredName?: string;
}

const CompanyProfileActionPopup: React.FC<ICompanyProfileActionPopup> = ({
    isCompanyProfileActionPopupOpen,
    setIsCompanyProfileActionPopupOpen,
    isBuyer,
    isActive,
    companyUuid,
    registeredName,
}: ICompanyProfileActionPopup) => {
    const { t } = useTranslation();
    const toast = useToaster();
    const [updateCompanyStatus, { isLoading: isUpdating }] = useUpdateCompanyStatusMutation();

    const handleActionCompanyProfile = () => {
        if (!companyUuid) {
            toast.error(t("companyProfile.api.updateCompanyStatusError"));
            setIsCompanyProfileActionPopupOpen(false);
            return;
        }
        updateCompanyStatus({
            companyUuid,
            isActive: !isActive,
        })
            .unwrap()
            .then(() => {
                toast.success(t("companyProfile.api.updateCompanyStatusSuccess"));
            })
            .catch(() => {
                toast.error(t("companyProfile.api.updateCompanyStatusError"));
            })
            .finally(() => {
                setIsCompanyProfileActionPopupOpen(false);
            });
    };

    if (isActive) {
        return (
            <Popup
                isOpen={isCompanyProfileActionPopupOpen}
                onClose={() => setIsCompanyProfileActionPopupOpen(false)}
                title={t("companyProfile.popups.deactivateCompany.title")}
                message={
                    isBuyer
                        ? t("companyProfile.popups.deactivateCompany.buyerMessage")
                        : t("companyProfile.popups.deactivateCompany.supplierMessage")
                }
                secondaryActionText={t("companyProfile.popups.deactivateCompany.secondaryActionText")}
                onSecondaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: "Company - Deactivate Profile Cancel - ".concat(registeredName || "No Naame"),
                    });
                    setIsCompanyProfileActionPopupOpen(false);
                }}
                primaryActionText={t("companyProfile.popups.deactivateCompany.primaryActionText")}
                onPrimaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: "Company - Deactivate Profile Submit - ".concat(registeredName || "No Naame"),
                    });
                    handleActionCompanyProfile();
                }}
                testid="deactivate-company-popup"
                disabled={isUpdating}
            />
        );
    }

    return (
        <Popup
            isOpen={isCompanyProfileActionPopupOpen}
            onClose={() => setIsCompanyProfileActionPopupOpen(false)}
            title={t("companyProfile.popups.activateCompany.title")}
            message={
                isBuyer
                    ? t("companyProfile.popups.activateCompany.buyerMessage")
                    : t("companyProfile.popups.activateCompany.supplierMessage")
            }
            primaryActionText={t("companyProfile.popups.activateCompany.primaryActionText")}
            secondaryActionText={t("companyProfile.popups.activateCompany.secondaryActionText")}
            onSecondaryAction={() => setIsCompanyProfileActionPopupOpen(false)}
            onPrimaryAction={handleActionCompanyProfile}
            testid="activate-company-popup"
            disabled={isUpdating}
        />
    );
};

export default CompanyProfileActionPopup;
