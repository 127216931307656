import { Button } from "@maistro/components";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import noAccessError from "assets/images/noAccessError.png";
import useAppDispatch from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import routes from "routes/routePaths/RoutePaths";
import { resetLayout, setBack } from "store/layoutSlice";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        content: {
            marginTop: theme.spacing.xLarge,
        },
    },
    headerText: {
        ...theme.typography.content.label,
    },
    mainDiv: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: theme.spacing.large,
    },
    pictureTextDiv: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: theme.spacing.small,
    },
    mainPictureDiv: {
        width: "20%",
    },
    mainPicture: {
        height: "100%",
        width: "100%",
    },
    supportText: {
        fontSize: "11px",
        color: theme.colors.gray,
    },
}));

const ProjectBriefError: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(resetLayout());
        dispatch(setBack(null));
    }, [dispatch]);

    return (
        <div className={classes.mainDiv}>
            <div className={classes.pictureTextDiv}>
                <div className={classes.mainPictureDiv}>
                    <img src={noAccessError} alt={t("projectForbidden.altPicture")} className={classes.mainPicture} />
                </div>
                <p className={classes.headerText}>{t("projectForbidden.headerText")}</p>
                <p className={classes.supportText}>{t("projectForbidden.supportText")}</p>
            </div>
            <div>
                <Button
                    chevron={false}
                    testid="ProjectBriefError-to-dashboard-button"
                    label={t("projectForbidden.button")}
                    onClick={() => navigate(routes.common.dashboard.path)}
                    className={classes.hide}
                    size="medium"
                />
            </div>
        </div>
    );
};

export default ProjectBriefError;
