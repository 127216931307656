import { Button, Copy, LoadingScreen, Popup, SelectField, useToaster } from "@maistro/components";
import { generateTenderQuestions } from "api/aiAssistApi";
import { updateSelectedProjectSections } from "api/questions/tenderCriteriaQuestionsApi";
import loadingImage from "assets/images/aiAssistLoadingScreen.png";
import { Loader } from "components";
import TitleCtaType from "components/Layout/types/TitleCtaType";
import ProjectPreviewDrawer from "features/project/components/ProjectPreviewDrawer";
import useProject from "features/project/hooks/useProject";
import useProjectFiles from "features/project/hooks/useProjectFiles";
import { useTenderQuestions } from "features/project/tenderCriteria/hooks/useTenderQuestions";
import TenderQuestionsDisplay from "features/project/tenderCriteria/questions/TenderQuestionsDisplay";
import { Form, Formik } from "formik";
import useAppDispatch from "hooks/useAppDispatch";
import useCompany from "hooks/useCompany";
import useCurrentUser from "hooks/useCurrentUser";
import usePlatformFeatures from "hooks/usePlatformFeatures";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import stringService from "services/stringService";
import { resetLayout, setBack, setPageTitle, setTitleCtas, setTooltip } from "store/layoutSlice";
import { ITheme } from "styles/themes/types";
import { ISectionQuestions } from "types/dtos/questions/ISectionQuestions";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import FeatureToggle from "types/enums/companies/FeatureToggle";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

const useStyles = createUseStyles((theme: ITheme) => ({
    actions: {
        display: "flex",
        gap: theme.spacing.medium,
        margin: `${theme.spacing.small}px 0`,
        alignItems: "flex-end",
        width: "100%",
        position: "relative",
    },
    actionsCopy: {
        display: "flex",
        alignSelf: "flex-end",
        paddingBottom: "10px",
    },
    select: {
        display: "flex",
        gap: theme.spacing.medium,
        flex: 1,
    },
    actionButtons: {
        display: "flex",
        gap: theme.spacing.small,
        marginLeft: "auto",
        "& > *": {
            flexBasis: "50%",
        },
        alignSelf: "flex-end",
        paddingBottom: "5px",
    },
    continue: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing.units(6),
    },
    flexGrow: {
        flexGrow: 1,
    },
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        toggle: {
            "& > *": {
                flexBasis: "auto",
                minWidth: 150,
            },
        },
    },
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
        showMd: {
            display: "none !important",
        },
    },
    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        actions: {
            flexDirection: "row",
            alignItems: "center",
        },
        hideMd: {
            display: "none !important",
        },
    },
}));

const TenderQuestionsContainer: React.FC = () => {
    const { t } = useTranslation();
    const toast = useToaster();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const { projectInformation } = useProject();
    const { projectFiles } = useProjectFiles(projectInformation.uuid);
    const {
        sectionsResponse,
        sectionQuestions,
        sectionsWithoutOrdering,
        submitQuestion,
        editQuestion,
        deleteQuestionFromSection,
        orderdByAlphabetSectionQuestions,
        orderedByWeightSections,
        orderedByAlphabetSections,
        reloadAll,
        editQuestions,
    } = useTenderQuestions({ projectUuid: projectInformation.uuid, setIsLoading });

    const [isProjectPreviewOpen, setIsProjectPreviewOpen] = useState(false);
    const [isDisclaimerPopupOpen, setIsDisclaimerPopupOpen] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [duration, setDuration] = useState(0);
    const defaultLoaderDuration = 40000;

    const sectionsOrderings = useMemo(
        () => ({
            automatic: t("tenderQuestions.sortingOrder.automatic"),
            alphabetical: t("tenderQuestions.sortingOrder.alphabetical"),
            byWeight: t("tenderQuestions.sortingOrder.byWeight"),
        }),
        [t],
    );

    const questionsOrderings = useMemo(
        () => ({
            automatic: t("tenderQuestions.sortingOrder.automatic"),
            alphabetical: t("tenderQuestions.sortingOrder.alphabetical"),
        }),
        [t],
    );

    const { isAIAssistAvailable } = usePlatformFeatures();
    const { companyHasFeature } = useCompany();
    const { userHasProjectPermission } = useCurrentUser();
    const [sectionsQuestionsReordered, setSectionsQuestionsReordered] = useState<QuestionResponseDto[]>([]);
    const [sectionsReordered, setSectionsReordered] = useState<SectionDto[]>([]);
    const [activeSectionsOrdering, setActiveSectionsOrdering] = useState(sectionsOrderings.automatic);
    const [activeQuestionsOrdering, setActiveQuestionsOrdering] = useState(questionsOrderings.automatic);
    const [isActionButtonsRendering, setIsActionButtonsRendering] = useState(false);
    const [sections, setSections] = useState<SectionDto[]>([]);
    const [questions, setQuestions] = useState<ISectionQuestions[]>([]);
    const classes = useStyles();

    useEffect(() => {
        if (sectionsReordered.length > 0 || sectionsQuestionsReordered.length > 0) {
            setIsActionButtonsRendering(true);
        } else {
            setIsActionButtonsRendering(false);
        }
    }, [sections, sectionsQuestionsReordered.length, sectionsReordered.length, sectionsWithoutOrdering]);

    const updateOrdering = useCallback(async () => {
        if (!projectInformation.uuid) return;
        if (sectionsReordered.length > 0) {
            setIsLoading(true);
            const response = await updateSelectedProjectSections({
                projectUuid: projectInformation.uuid,
                selectedSections: sectionsReordered.map((section) => ({
                    sectionType: section.sectionType,
                    previousDescription: "",
                    description: section.description ? section.description : "",
                    orderIndex: section.orderIndex,
                })),
            });

            const isError = response.data instanceof TransactionErrorDto || response.status !== 204;
            toast[isError ? "error" : "success"](
                t(`tenderCriteria.sections.selection.sectionTypesSave${isError ? "Error" : "Success"}`),
            );
            setIsLoading(false);
            if (!isError) reloadAll();
        }
        if (sectionsQuestionsReordered.length > 0) {
            editQuestions(sectionsQuestionsReordered);
        }
        setSectionsQuestionsReordered([]);
        setSectionsReordered([]);
    }, [projectInformation.uuid, sectionsQuestionsReordered, sectionsReordered, toast, t, reloadAll, editQuestions]);

    const onCancelOrdering = () => {
        setSections(sectionsWithoutOrdering);
        setQuestions(sectionQuestions);
        setSectionsReordered([]);
        setSectionsQuestionsReordered([]);
        reloadAll();
    };

    const questionDelete = useCallback(
        (sectionUuid: string, questionUuid: string) => {
            setQuestions((q) =>
                q.map((section) =>
                    section.id === sectionUuid
                        ? {
                              ...section,
                              items: section.items.filter((item) => item.questionUuid !== questionUuid),
                          }
                        : section,
                ),
            );
            deleteQuestionFromSection(sectionUuid, questionUuid, isActionButtonsRendering);
        },
        [deleteQuestionFromSection, isActionButtonsRendering],
    );

    const enableAIAssist = useMemo(() => {
        return (
            userHasProjectPermission(PermissionsEnum.UpdateScoringCriteria, projectInformation.uuid) &&
            isAIAssistAvailable &&
            companyHasFeature(FeatureToggle.AIAssist, true) &&
            stringService.getWordCount(projectInformation.description) <= 2000 &&
            !sectionQuestions.some((sq) => sq.items.some((q) => q.wasAIGenerated))
        );
    }, [
        userHasProjectPermission,
        projectInformation.uuid,
        projectInformation.description,
        isAIAssistAvailable,
        companyHasFeature,
        sectionQuestions,
    ]);

    if (sectionsResponse) {
        const percentage = sectionsResponse.sections.reduce((total, section) => total + (section.weighting ?? 0), 0);
        if (percentage !== 100) {
            navigate(buildPath(routes.projects.weighting, { projectUuid: projectInformation.uuid }));
        }
    }

    const inviteSuppliers = () => {
        navigate(buildPath(routes.projects.summary, { projectUuid: projectInformation.uuid }));
    };

    const generateQuestions = (projectUuid: string) => {
        setIsGenerating(true);
        setDuration(defaultLoaderDuration);
        generateTenderQuestions(projectUuid)
            .then((response) => {
                let wasSuccessful = false;
                if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                    toast.error(t("tenderQuestions.aiAssist.error"));
                } else {
                    wasSuccessful = response.data.wasSuccessful;
                }
                setDuration(0);
                setTimeout(() => {
                    setIsGenerating(false);
                    reloadAll();
                    if (wasSuccessful) {
                        toast.success(t("tenderQuestions.aiAssist.success"));
                    } else {
                        toast.warning(t("tenderQuestions.aiAssist.partiallySuccessful"));
                    }
                }, 1000);
            })
            .catch(() => {
                toast.error(t("tenderQuestions.aiAssist.error"));
                setIsGenerating(false);
            });
    };

    useEffect(() => {
        dispatch(resetLayout());
        dispatch(setPageTitle(t("tenderQuestions.pageTitle")));
        dispatch(
            enableAIAssist
                ? setTooltip([
                      { text: t("tenderQuestions.tooltip.aiParagraph1") },
                      { text: t("tenderQuestions.tooltip.paragraph2") },
                  ])
                : setTooltip([
                      { text: t("tenderQuestions.tooltip.paragraph1") },
                      { text: t("tenderQuestions.tooltip.paragraph2") },
                  ]),
        );

        dispatch(
            setBack({
                route: buildRoute(routes.projects.weighting, { projectUuid: projectInformation.uuid }),
            }),
        );

        dispatch(
            setTitleCtas([
                {
                    type: TitleCtaType.ProjectPreview,
                    onClick: () => setIsProjectPreviewOpen(true),
                },
            ]),
        );
        return () => {
            dispatch(resetLayout());
        };
    }, [t, dispatch, projectInformation.uuid, enableAIAssist]);

    const selectSectionsOrdering = (value: string) => {
        setActiveSectionsOrdering(value);
        if (value === sectionsOrderings.byWeight && sections) {
            setSections(orderedByWeightSections);
            const updatedSections = orderedByWeightSections.map((section, index) => ({
                ...section,
                orderIndex: index,
            }));

            setSectionsReordered(updatedSections);
        }
        if (value === sectionsOrderings.alphabetical && sections) {
            setSections(orderedByAlphabetSections);
            const updatedSections = orderedByAlphabetSections.map((section, index) => ({
                ...section,
                orderIndex: index,
            }));

            setSectionsReordered(updatedSections);
        }
        if (value === sectionsOrderings.automatic && sections) {
            setSections(sectionsWithoutOrdering);
            setSectionsReordered([]);
        }
    };

    const selectQuestionsOrdering = (value: string) => {
        setActiveQuestionsOrdering(value);
        if (value === questionsOrderings.alphabetical && sectionQuestions) {
            setQuestions(orderdByAlphabetSectionQuestions);
            const updatedQuestions: QuestionResponseDto[] = orderdByAlphabetSectionQuestions.flatMap(
                (section) => section.items,
            );
            setSectionsQuestionsReordered(updatedQuestions);
        }
        if (value === questionsOrderings.automatic && sectionQuestions) {
            setQuestions(sectionQuestions);
            setSectionsQuestionsReordered([]);
        }
    };

    useEffect(() => {
        if (activeSectionsOrdering === sectionsOrderings.automatic && sectionsWithoutOrdering?.length > 0) {
            setSections(sectionsWithoutOrdering);
        }
    }, [sectionsWithoutOrdering, activeSectionsOrdering, sectionsOrderings.automatic]);

    useEffect(() => {
        if (
            activeQuestionsOrdering === questionsOrderings.automatic &&
            sectionQuestions?.length > 0 &&
            sectionsQuestionsReordered.length === 0
        ) {
            setQuestions(sectionQuestions);
        }
    }, [
        activeQuestionsOrdering,
        questions,
        questionsOrderings.automatic,
        sectionQuestions,
        sectionsQuestionsReordered,
    ]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            {isGenerating && (
                <LoadingScreen
                    title={t("tenderQuestions.aiAssist.loadingTitle")}
                    message={[
                        t("tenderQuestions.aiAssist.loadingMessages.messageOne"),
                        t("tenderQuestions.aiAssist.loadingMessages.messageTwo"),
                        t("tenderQuestions.aiAssist.loadingMessages.messageThree"),
                        t("tenderQuestions.aiAssist.loadingMessages.messageFour"),
                    ]}
                    duration={duration}
                    image={loadingImage}
                    hold
                    continuous
                    testid="loading-screen"
                />
            )}

            {sectionsResponse?.sections && sectionsResponse?.sections?.length > 0 ? (
                <div>
                    <div className={classes.actions}>
                        <Copy className={classes.actionsCopy}>{t("tenderQuestions.sortingTitle")}</Copy>
                        <Formik
                            initialValues={{
                                sectionsSelect: sectionsOrderings.automatic,
                                questionsSelect: questionsOrderings.automatic,
                            }}
                            onSubmit={() => {}}
                        >
                            {({ handleSubmit }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className={classes.select}>
                                        <SelectField
                                            name="sectionsSelect"
                                            label={t("tenderQuestions.sectionsSelectLabel")}
                                            options={Object.values(sectionsOrderings).map((ordering) => ({
                                                label: ordering,
                                                value: ordering,
                                            }))}
                                            multiple={false}
                                            disableSearch
                                            onChange={(value) => {
                                                selectSectionsOrdering(value);
                                            }}
                                            withoutValidation
                                            testid="sections=select"
                                        />
                                        <SelectField
                                            name="questionsSelect"
                                            label={t("tenderQuestions.questionsSelectLabel")}
                                            options={Object.values(questionsOrderings).map((ordering) => ({
                                                label: ordering,
                                                value: ordering,
                                            }))}
                                            multiple={false}
                                            disableSearch
                                            onChange={(value) => {
                                                selectQuestionsOrdering(value);
                                            }}
                                            withoutValidation
                                            testid="questions-select"
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        {isActionButtonsRendering && (
                            <div className={classes.actionButtons}>
                                <Button
                                    label={`${t("tenderQuestions.cancelOrdering")}`}
                                    color="dark"
                                    size="small"
                                    onClick={onCancelOrdering}
                                    testid="cancel-ordering"
                                />
                                <Button
                                    label={`${t("tenderQuestions.saveOrdering")}`}
                                    size="small"
                                    onClick={updateOrdering}
                                    testid="save-ordering"
                                />
                            </div>
                        )}
                    </div>
                    <TenderQuestionsDisplay
                        deleteQuestionFromSection={(section, question) =>
                            questionDelete(section, question.questionUuid)
                        }
                        addQuestionToSection={(question) => submitQuestion(question, isActionButtonsRendering)}
                        editQuestionFromSection={(section, question) =>
                            editQuestion(section, question, isActionButtonsRendering)
                        }
                        sections={sections}
                        questions={questions}
                        inviteSuppliers={inviteSuppliers}
                        projectUuid={projectInformation.uuid}
                        projectInformation={projectInformation}
                        setSectionsReordered={setSectionsReordered}
                        setSectionsQuestionsReordered={setSectionsQuestionsReordered}
                        generateQuestions={() => {
                            setIsDisclaimerPopupOpen(true);
                        }}
                        enableAIAssist={enableAIAssist}
                        setQuestions={setQuestions}
                        setSections={setSections}
                        sectionsQuestionsReordered={sectionsQuestionsReordered}
                        isActionButtonsRendering={isActionButtonsRendering}
                    />
                </div>
            ) : (
                <Copy variant="error" testid="no-sections-error">
                    {t("tenderQuestions.noSections")}
                </Copy>
            )}

            <ProjectPreviewDrawer
                projectFiles={projectFiles}
                isOpen={isProjectPreviewOpen}
                close={() => setIsProjectPreviewOpen(false)}
                allowProjectEdit
                navigationState={{
                    route: buildRoute(routes.projects.sectionQuestions, {
                        projectUuid: projectInformation?.uuid,
                    }),
                }}
            />
            <Popup
                title={t("popups.tenderQuestionsDisclaimer.title")}
                message={
                    <>
                        <b>{t("popups.tenderQuestionsDisclaimer.message1")}</b>&nbsp;
                        {t("popups.tenderQuestionsDisclaimer.message2")}
                    </>
                }
                isOpen={isDisclaimerPopupOpen}
                primaryActionText={t("popups.tenderQuestionsDisclaimer.cta.primary")}
                onPrimaryAction={() => {
                    generateQuestions(projectInformation.uuid);
                    setIsDisclaimerPopupOpen(false);
                }}
                secondaryActionText={t("popups.tenderQuestionsDisclaimer.cta.secondary")}
                onSecondaryAction={() => setIsDisclaimerPopupOpen(false)}
                onClose={() => setIsDisclaimerPopupOpen(false)}
                testid="tender-questions-disclaimer-popup"
            />
        </div>
    );
};

export default TenderQuestionsContainer;
