import { Button, Heading, PhoneNumberTextField, ReadOnlyField, TextButton, TextField } from "@maistro/components";
import { Form, Formik } from "formik";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import { ICommonProps } from "components/shared";
import useUserProfile from "features/user/hooks/useUserProfile";
import ReactGA from "react-ga4";
import { ITheme } from "styles/themes/types";
import { UpdateUserProfileRequestDto } from "types/dtos/users/UpdateUserProfileRequestDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
    buttonControl: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.small,
    },
}));

interface IPersonalProfileProps extends ICommonProps {
    submit: (dto: UpdateUserProfileRequestDto) => void;
    cancel: () => void;
}

const UserProfile: React.FC<IPersonalProfileProps> = (props) => {
    const classes = useStyles();

    const { cancel, submit } = props;

    const { t } = useTranslation();

    let isValidDialCode = true;
    let isValidLength = true;

    const { getProfile, userProfile } = useUserProfile();

    useEffect(() => {
        getProfile();
    }, [getProfile]);

    if (!userProfile) return null;

    const validationSchema = () =>
        Yup.object({
            phoneNumber: Yup.string()
                .test("validDialCode", t("companyProfileUsers.form.phoneNumber.invalid"), () => isValidDialCode)
                .test("validLength", t("companyProfileUsers.form.phoneNumber.min"), () => isValidLength),
        });

    return (
        <Formik
            initialValues={{
                userUuid: userProfile.userUuid,
                company: userProfile.companyName ?? "",
                email: userProfile.email,
                firstName: userProfile.firstName,
                lastName: userProfile.lastName,
                phoneNumber: userProfile.phoneNumber ?? "",
                jobTitle: userProfile.jobTitle ?? "",
                roleNames: userProfile.roleName,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => submit(values)}
        >
            {({ errors, initialValues, values }) => (
                <Form className={classes.container}>
                    <Heading variant="h2">{t("users.profile.title")}</Heading>
                    <ReadOnlyField
                        testid="user-profile-company"
                        label={t("users.profile.company")}
                        value={values.company}
                        useHyphenForEmptyValues
                    />
                    <ReadOnlyField
                        testid="user-profile-role"
                        label={
                            values.roleNames.split(",").length > 1
                                ? `${t("users.profile.roleName")}s`
                                : t("users.profile.roleName")
                        }
                        value={values.roleNames
                            .split(", ")
                            .map((roleName) => t(`roles.names.${roleName}`))
                            .join(", ")}
                    />
                    <TextField
                        testid="user-profile-email-address"
                        label={t("users.profile.email")}
                        name="email"
                        disabled
                    />
                    <TextField
                        testid="user-profile-first-name"
                        label={t("users.profile.firstName")}
                        name="firstName"
                        required
                    />
                    <TextField
                        testid="user-profile-last-name"
                        label={t("users.profile.lastName")}
                        name="lastName"
                        required
                    />
                    <TextField testid="user-profile-job-title" label={t("users.profile.jobTitle")} name="jobTitle" />
                    <PhoneNumberTextField
                        name="phoneNumber"
                        label={t("users.profile.phoneNumber.label")}
                        testid="user-profile-phone-number"
                        onValidationComplete={(isDialCode: boolean, isLength: boolean) => {
                            isValidDialCode = isDialCode;
                            isValidLength = isLength;
                        }}
                    />
                    <div className={classes.buttonControl}>
                        <Button
                            label={t("users.submit")}
                            size="large"
                            type="submit"
                            disabled={!isEmpty(errors) || values === initialValues}
                            testid="user-profile-submit-button"
                            onClick={() =>
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "Profile - Submit button",
                                })
                            }
                        />
                        <TextButton
                            label={t("common.cancel")}
                            onClick={() => {
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "Profile - Cancel button",
                                });
                                cancel();
                            }}
                            testid="user-profile-cancel-button"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default UserProfile;
