import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { Drawer, TextButton } from "@maistro/components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { SearchBar } from "components";
import { ISupplierListFilters } from "features/company/profile/suppliers/filter/hooks/useSupplierListFilter";
import SupplierListFilterForm from "features/company/profile/suppliers/filter/supplierListFilterForm";
import useDebouncedEffect from "hooks/useDebouncedEffect";
import ReactGA from "react-ga4";
import { ITheme } from "styles/themes/types";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    searchBar: {
        display: "flex",
    },
    searchBarContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        gap: theme.spacing.medium,
        marginBottom: theme.spacing.medium,
    },
}));

interface ISupplierListFiltersProps {
    currentFilters: ISupplierListFilters;
    setFilterValues: (filterValues: ISupplierListFilters) => void;
    isSearching: boolean;
    resetPage: () => void;
    resetFilters: () => void;
    isFilterDrawerOpen: boolean;
    setIsFilterDrawerOpen: (isOpen: boolean) => void;
}

const SupplierListFilters: React.FC<ISupplierListFiltersProps> = ({
    currentFilters,
    setFilterValues,
    isSearching,
    resetPage,
    resetFilters,
    isFilterDrawerOpen,
    setIsFilterDrawerOpen,
}) => {
    const classes = useStyles();

    const [currentSearchText, setCurrentSearchText] = useState("");

    const { t } = useTranslation();

    const onSubmitFilters = (filters: ISupplierListFilters) => {
        resetPage();
        setFilterValues(filters);
        setIsFilterDrawerOpen(false);
    };

    useDebouncedEffect(
        () => {
            if (currentFilters.searchTerm !== currentSearchText) {
                resetPage();
                setFilterValues({ ...currentFilters, searchTerm: currentSearchText });
            }
        },
        [currentSearchText],
        500,
    );

    useEffect(() => {
        setCurrentSearchText(currentFilters.searchTerm ?? "");
    }, [currentFilters.searchTerm]);

    const onResetFilters = () => {
        resetPage();
        resetFilters();
        setIsFilterDrawerOpen(false);
    };

    return (
        <React.Fragment>
            <Drawer
                isOpen={isFilterDrawerOpen}
                onClose={() => setIsFilterDrawerOpen(false)}
                testid="company-list-filter-drawer"
            >
                <SupplierListFilterForm
                    currentFilters={currentFilters}
                    resetFilters={onResetFilters}
                    submitFilters={onSubmitFilters}
                />
            </Drawer>

            <div className={classes.searchBarContainer}>
                <SearchBar
                    isLoading={isSearching}
                    className={classes.searchBar}
                    placeholder={t("companySuppliers.search")}
                    value={currentSearchText}
                    onChange={(e) => setCurrentSearchText(e.target.value)}
                />
                <TextButton
                    label={t("common.filter")}
                    icon={faFilter}
                    onClick={() => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: `Company Suppliers - Open Filter Drawer`,
                        });
                        setIsFilterDrawerOpen(true);
                    }}
                    disabled={isSearching}
                    testid="open-filter-drawer-button"
                />
            </div>
        </React.Fragment>
    );
};

export default SupplierListFilters;
