import { AxiosResponse } from "axios";

import { createAxiosInstance } from "api/commonApi";
import env from "config/EnvironmentVariables";
import { CreatePolicyApprovalRequestDto } from "types/dtos/company/policies/CreatePolicyApprovalRequestDto";
import { CreatePolicyRequestDto } from "types/dtos/company/policies/CreatePolicyRequestDto";
import { PoliciesResponseDto } from "types/dtos/company/policies/PoliciesResponseDto";
import { PolicyDto } from "types/dtos/company/policies/PolicyDto";
import { UpdatePolicyRequestDto } from "types/dtos/company/policies/UpdatePolicyRequestDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { ListPoliciesForUserResponseDto } from "types/dtos/company/policies/ListPoliciesForUserResponseDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/companies";

export const createPolicy = (
    request: CreatePolicyRequestDto,
): Promise<AxiosResponse<PolicyDto | TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${request.companyUuid}/user-groups/${request.groupUuid}/policies`;
    return api.post(url, request);
};

export const getPolicy = (
    companyUuid: string,
    groupUuid: string,
    policyUuid: string,
): Promise<AxiosResponse<PolicyDto | TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${companyUuid}/user-groups/${groupUuid}/policies/${policyUuid}`;
    return api.get(url);
};

export const listPoliciesForGroup = (
    companyUuid: string,
    groupUuid: string,
): Promise<AxiosResponse<PoliciesResponseDto | TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${companyUuid}/user-groups/${groupUuid}/policies`;
    return api.get(url);
};

export const updatePolicy = (
    request: UpdatePolicyRequestDto,
): Promise<AxiosResponse<PolicyDto | TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${request.companyUuid}/user-groups/${request.groupUuid}/policies/${request.id}`;
    return api.put(url, request);
};

export const deletePolicy = (companyUuid: string, groupUuid: string, policyUuid: string): Promise<AxiosResponse> => {
    const url = `${baseApiUrl}/${companyUuid}/user-groups/${groupUuid}/policies/${policyUuid}`;
    return api.delete(url);
};

export const listPoliciesForUser = (
    companyUuid: string,
    userUuid: string,
): Promise<AxiosResponse<ListPoliciesForUserResponseDto | TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${companyUuid}/users/${userUuid}/policies`;
    return api.get(url);
};

export const createPolicyApprovals = (request: CreatePolicyApprovalRequestDto): Promise<AxiosResponse> => {
    const url = `${baseApiUrl}/${request.companyUuid}/user-groups/approvals`;
    return api.post(url, request);
};
