import { ICommonProps, Popup, useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { linkProjectFiles } from "api/fileApi";
import { createOrUpdateAnswer } from "api/supplierSubmission/submissionClarificationQuestionsApi";
import useFileUpload from "features/files/useFileUpload";
import SupplierAnswerQuestionDisplay from "features/project/clarificationQuestions/answerQuestion/SupplierAnswerQuestionDisplay";
import useNavTabs from "features/project/hooks/useNavTabs";
import useProjectResponse from "features/project/hooks/useProjectResponse";
import useSupplierSubmissionClarificationQuestions from "features/project/hooks/useSupplierSubmissionClarificationQuestion";
import useAppDispatch from "hooks/useAppDispatch";
import useReduxSelector from "hooks/useReduxSelector";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import validationService from "services/validationService";
import { setBack, setPageTitle, setSecondaryNavItems, setTooltip } from "store/layoutSlice";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import EntityType from "types/enums/questions/EntityType";

interface IAnswerQuestionContainerProps extends ICommonProps {}

const SupplierAnswerQuestionContainer: React.FC<IAnswerQuestionContainerProps> = () => {
    const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
    const { sections } = useReduxSelector((state) => state.sectionState);
    const userUuid = useReduxSelector((state) => state.authenticationState.accessToken.user_uuid);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const toast = useToaster();
    const { questionUuid, responseUuid } = useParams();

    const { projectResponseInformation } = useProjectResponse();
    const { clarificationQuestionDetail, fetchQuestion } = useSupplierSubmissionClarificationQuestions(responseUuid);
    const { supplierTabItems } = useNavTabs();
    const { fileIsUploading, setFileIsUploading, projectFiles, setProjectFiles, removeFile } = useFileUpload(
        projectResponseInformation.projectUuid,
        EntityType.Answer,
    );

    const pageTitle = useMemo(() => {
        const hasAnswer = clarificationQuestionDetail?.answersCount;
        return t(`clarificationQuestions.titles.${hasAnswer ? "view" : "answer"}`);
    }, [clarificationQuestionDetail?.answersCount, t]);

    useEffect(() => {
        dispatch(setPageTitle(pageTitle));
        dispatch(setTooltip(t("clarificationQuestions.tooltips.all")));
        dispatch(
            setBack({
                route: buildRoute(routes.projectResponses.clarificationQuestions, {
                    projectUuid: projectResponseInformation.projectUuid,
                    responseUuid: projectResponseInformation.responseUuid,
                }),
            }),
        );
        dispatch(setSecondaryNavItems(supplierTabItems));

        fetchQuestion(questionUuid);

        return () => {
            dispatch(setSecondaryNavItems(null));
        };
    }, [
        dispatch,
        fetchQuestion,
        pageTitle,
        projectResponseInformation.projectUuid,
        projectResponseInformation.responseUuid,
        questionUuid,
        supplierTabItems,
        t,
    ]);

    const submitAnswer = useCallback(
        async (answer: string) => {
            if (
                !(
                    projectResponseInformation.projectUuid &&
                    questionUuid &&
                    userUuid &&
                    validationService.isValidUuid(questionUuid)
                )
            )
                return;

            const response = await createOrUpdateAnswer(projectResponseInformation.responseUuid, questionUuid, {
                answerText: answer,
            });

            if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                toast.error(t("clarificationQuestions.api.answerQuestionError"));
                return;
            }

            const newFiles = projectFiles.filter((file) => file.newUpload);
            if (newFiles.length) {
                const fileUuids = newFiles.map((file) => file.fileId);
                await linkProjectFiles(
                    projectResponseInformation.projectUuid,
                    fileUuids,
                    EntityType.Answer,
                    response.data.answerUuid,
                );
            }

            navigate(
                buildPath(routes.projectResponses.clarificationQuestions, {
                    projectUuid: projectResponseInformation.projectUuid,
                    responseUuid: projectResponseInformation.responseUuid,
                }),
            );
            toast.success(t("clarificationQuestions.api.answerQuestionSuccess"));
        },
        [
            navigate,
            projectFiles,
            projectResponseInformation.projectUuid,
            projectResponseInformation.responseUuid,
            questionUuid,
            t,
            toast,
            userUuid,
        ],
    );

    const cancelAnswer = useCallback(() => {
        navigate(
            buildPath(routes.projectResponses.clarificationQuestions, {
                projectUuid: projectResponseInformation.projectUuid,
                responseUuid: projectResponseInformation.responseUuid,
            }),
        );
    }, [navigate, projectResponseInformation.projectUuid, projectResponseInformation.responseUuid]);

    if (!clarificationQuestionDetail) return null;

    const setSectionType = () => {
        const section = sections.items.find((x) => x.id === clarificationQuestionDetail?.entityUuid);
        return section?.description ? section.description : section?.sectionType;
    };

    return (
        <React.Fragment>
            <SupplierAnswerQuestionDisplay
                project={projectResponseInformation}
                question={clarificationQuestionDetail}
                submitAnswer={submitAnswer}
                cancelAnswer={() => setIsCancelPopupOpen(true)}
                sectionType={setSectionType()}
                files={projectFiles}
                setFiles={setProjectFiles}
                removeFile={removeFile}
                fileIsUploading={fileIsUploading}
                setFileIsUploading={setFileIsUploading}
            />
            <Popup
                title={t("popups.cancelAnswer.title")}
                message={t("popups.cancelAnswer.message")}
                isOpen={isCancelPopupOpen}
                primaryActionText={t("popups.cancelAnswer.cta.primary")}
                onPrimaryAction={cancelAnswer}
                onClose={() => setIsCancelPopupOpen(false)}
                testid="cancel-answer-popup"
            />
        </React.Fragment>
    );
};

export default SupplierAnswerQuestionContainer;
