import { ISortBy, SortOrder } from "@maistro/components";
import { AxiosResponse } from "axios";

import { createAxiosInstance } from "api/commonApi";
import env from "config/EnvironmentVariables";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { CompanyDetailsDto } from "types/dtos/company/CompanyDetailsDto";
import { CompanyEmailSettingsDto } from "types/dtos/company/CompanyEmailSettingsDto";
import { CompanyListFilterDto } from "types/dtos/company/CompanyListFilterDto";
import { ListBrandingSettingsResponseDto } from "types/dtos/company/ListBrandingSettingsResponseDto";
import { SearchCompaniesRequestDto } from "types/dtos/company/SearchCompaniesRequestDto";
import { SearchCompaniesResponseDto } from "types/dtos/company/SearchCompaniesResponseDto";
import { SearchCompanySuppliersRequestDto } from "types/dtos/company/SearchCompanySuppliersRequestDto";
import { SearchCompanySuppliersResponseDto } from "types/dtos/company/SearchCompanySuppliersResponseDto";
import { CompanyDetailDto } from "types/dtos/company/profile/CompanyDetailDto";
import { CreateCompanyProfileRequestDto } from "types/dtos/company/profile/CreateCompanyProfileRequestDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/companies";

export const createCompany = (
    request: CreateCompanyProfileRequestDto,
): Promise<AxiosResponse<CompanyDetailDto | TransactionErrorDto>> => {
    return api.post(baseApiUrl, request);
};

export const searchCompanySuppliers = (
    request: SearchCompanySuppliersRequestDto,
): Promise<AxiosResponse<SearchCompanySuppliersResponseDto | TransactionErrorDto>> => {
    let url = `${baseApiUrl}/${request.companyUuid}/search-company-suppliers?textSearch=${request.textSearch}`;
    if (request.relationshipStatuses) {
        request.relationshipStatuses.forEach((v) => {
            url += `&includedSupplierRelationshipStatuses=${encodeURI(v)}`;
        });
    }
    url += `&includeInactive=${request.includeInactive}`;
    return api.get(url);
};

export const getBrandingSettingsForDomain = (
    domainName: string,
): Promise<AxiosResponse<ListBrandingSettingsResponseDto>> => {
    return api.get(`${baseApiUrl}/branding-settings?domainName=${domainName}&application=portal`);
};

export const getBrandingSettingsForCompany = (
    companyUuid: string,
): Promise<AxiosResponse<ListBrandingSettingsResponseDto>> => {
    return api.get(`${baseApiUrl}/${companyUuid}/branding-settings?application=portal`);
};

export const getCompanyEmailSettings = (companyUuid: string): Promise<AxiosResponse<CompanyEmailSettingsDto>> => {
    return api.get(`${baseApiUrl}/email-settings?companyUuid=${companyUuid}`);
};

export const getCompanyDetails = (companyUuid: string): Promise<AxiosResponse<CompanyDetailsDto>> => {
    return api.get(`${baseApiUrl}/${companyUuid}`);
};

export const deleteCompanyDetails = (companyUuid: string): Promise<AxiosResponse<CompanyDetailsDto>> => {
    return api.delete(`${baseApiUrl}/${companyUuid}`);
};

export const reinviteCompanyUser = (companyUuid: string, userUuid: string): Promise<AxiosResponse> => {
    return api.post(`${baseApiUrl}/${companyUuid}/users/${userUuid}/reinvite`, {});
};

const buildUrlForCompanySearch = (request: SearchCompaniesRequestDto) => {
    let url = `${baseApiUrl}/search?skip=${request.skip}&take=${request.take}`;

    if (request.sortColumn) {
        url += `&orderByColumn=${encodeURI(request.sortColumn)}&sortDescending=${
            request.direction === SortOrder.Descending
        }`;
    }

    if (request.searchQuery && request.searchQuery.length > 0) {
        url += `&searchQuery=${encodeURI(request.searchQuery)}`;
    }

    if (request.excludeSupplierListBuyerUuid && request.excludeSupplierListBuyerUuid.length > 0) {
        url += `&excludeSupplierListBuyerUuid=${request.excludeSupplierListBuyerUuid}`;
    }

    if (request.industryTypeIds) {
        request.industryTypeIds.forEach((it) => {
            url += `&industryTypeIds=${it}`;
        });
    }

    if (request.searchByEmail && request.searchByEmail.length > 0) {
        url += `&searchByEmail=${encodeURI(request.searchByEmail)}`;
    }

    if (request.companyTypes) {
        request.companyTypes.forEach((ct) => {
            url += `&companyTypes=${encodeURI(ct)}`;
        });
    }

    if (request.statuses) {
        request.statuses.forEach((s) => {
            url += `&statuses=${encodeURI(s)}`;
        });
    }

    return url;
};

export const searchCompanies = (
    currentPage: number,
    itemsPerPage: number,
    sortParameters: ISortBy,
    filterParameters: CompanyListFilterDto,
): Promise<AxiosResponse<SearchCompaniesResponseDto>> => {
    const request: SearchCompaniesRequestDto = {
        skip: (currentPage - 1) * itemsPerPage,
        take: itemsPerPage,
        sortColumn: sortParameters.column,
        direction: sortParameters.order ?? SortOrder.Descending,
        industryTypeIds: filterParameters.industryTypes?.map((it) => parseInt(it.value, 10)),
        searchByEmail: filterParameters.searchByEmail,
        companyTypes: filterParameters.companyTypes,
        searchQuery: filterParameters.searchTerm,
        statuses: filterParameters.statuses,
        excludeSupplierListBuyerUuid: filterParameters.excludeSupplierListBuyerUuid,
    };

    const url = buildUrlForCompanySearch(request);
    return api.get(url);
};
