import React from "react";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import EmbeddedReportResponseDto from "types/dtos/reports/EmbeddedReportResponseDto";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    reportContainer: {
        width: "100%",
        height: "80vh",
    },
});
interface IEmbedContainerProps {
    report: EmbeddedReportResponseDto;
    isMobileLayoutScreensize: boolean;
}

const EmbedContainer: React.FC<IEmbedContainerProps> = ({ report, isMobileLayoutScreensize }: IEmbedContainerProps) => {
    const classes = useStyles();
    const keyString = [
        report.reportName.replace(/\s+/g, ""),
        isMobileLayoutScreensize ? "mobileView" : "desktopView",
    ].join("");
    return (
        <PowerBIEmbed
            key={keyString}
            embedConfig={{
                type: "report",
                id: report.reportId,
                embedUrl: report.embedUrl,
                accessToken: report.token,
                tokenType: models.TokenType.Embed,
                settings: {
                    panes: {
                        filters: {
                            expanded: false,
                            visible: false,
                        },
                    },
                    layoutType: isMobileLayoutScreensize ? models.LayoutType.MobilePortrait : models.LayoutType.Master,
                },
            }}
            cssClassName={classes.reportContainer}
        />
    );
};

export default EmbedContainer;
