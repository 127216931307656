import { ICommonProps, Pill } from "@maistro/components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface IBuyerSupplierRelationshipPillProps extends ICommonProps {
    status:
        | "Active"
        | "Inactive"
        | "Approved"
        | "Deactivated"
        | "Pending"
        | "Trial"
        | "Strategic"
        | "Preferred"
        | "Occasional"
        | "Undefined";
    companyUuid: string;
    type: "relationship" | "active" | "tiering";
}

const BuyerSupplierRelationshipPill: React.FC<IBuyerSupplierRelationshipPillProps> = ({
    companyUuid,
    status,
    type,
    className,
}) => {
    const { t } = useTranslation();

    const pillColor = useMemo(() => {
        switch (status) {
            case "Approved":
                return "positive";
            case "Deactivated":
                return "negative";
            case "Pending":
            case "Inactive":
                return "gray";
            case "Trial":
            case "Active":
            case "Strategic":
            case "Preferred":
            case "Occasional":
            case "Undefined":
            default:
                return "primary";
        }
    }, [status]);

    return (
        <Pill
            value={t(`companySuppliers.${type}Status.${status.toLowerCase()}.label`)}
            color={pillColor}
            testid={`${companyUuid}-${type}-status`}
            className={className}
        />
    );
};

export default BuyerSupplierRelationshipPill;
