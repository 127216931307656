import { createAxiosInstance } from "api/commonApi";
import { AxiosResponse } from "axios";
import env from "config/EnvironmentVariables";
import { SupplierTenderScoresDto } from "types/dtos/projects/SupplierTenderScoresDto";
import { GetIdentifiedSuppliersRequestDto } from "types/dtos/projectSuppliers/GetIdentifiedSuppliersRequestDto";
import { GetPotentialSuppliersRequestDto } from "types/dtos/projectSuppliers/GetPotentialSuppliersRequestDto";
import { ListMatchedSuppliersRequestDto } from "types/dtos/projectSuppliers/ListMatchedSuppliersRequestDto";
import { ListMatchedSuppliersResponseDto } from "types/dtos/projectSuppliers/ListMatchedSuppliersResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/projects";

export const getPotentialSuppliers = (
    projectUuid: string,
): Promise<AxiosResponse<GetPotentialSuppliersRequestDto> | AxiosResponse<TransactionErrorDto>> => {
    return api.get(`${baseApiUrl}/${projectUuid}/suppliers/potential`);
};

export const listMatchedSuppliers = (
    request: ListMatchedSuppliersRequestDto,
): Promise<AxiosResponse<ListMatchedSuppliersResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    let url = `${baseApiUrl}/${request.projectUuid}/suppliers/matched`;

    url += `?buyerCompanyUuid=${request.buyerCompanyUuid}`;

    return api.get(url);
};

export const getIdentifiedSuppliers = (
    projectUuid: string,
): Promise<AxiosResponse<GetIdentifiedSuppliersRequestDto> | AxiosResponse<TransactionErrorDto>> => {
    return api.get(`${baseApiUrl}/${projectUuid}/suppliers/identified`);
};

export const reinviteSuppliers = (
    projectUuid: string,
    supplierUuid: string,
    changedByUserUuid: string,
): Promise<AxiosResponse> => {
    return api.put(`${baseApiUrl}/${projectUuid}/suppliers/${supplierUuid}/re-invite`, { changedByUserUuid });
};

export const getSuppliersTenderScores = (
    projectUuid: string,
    companyUuid?: string,
): Promise<AxiosResponse<SupplierTenderScoresDto>> => {
    return api.get(`${baseApiUrl}/${projectUuid}/calculate-scores?companyUuid=${companyUuid || ""}`);
};
