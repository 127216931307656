import { Button, RadioButtonsField, RichTextEditorField, TextButton } from "@maistro/components";
import { Form, Formik } from "formik";
import { TFunction } from "i18next";
import { isEmpty } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import { ICommonProps } from "components/shared";
import DisplayFileUploadField from "features/files/DisplayFileUploadFields";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";
import useReduxSelector from "hooks/useReduxSelector";

const useStyles = createUseStyles((theme: ITheme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.large,

        "& input[type=radio] + label": {
            ...theme.typography.base,
            fontSize: 13,
        },
    },
    buttonControl: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.large,
    },
}));

interface IBuyerAnswerQuestionFormProps extends ICommonProps {
    submit: (answer: string, isPrivate: string) => void;
    cancel?: () => void;
    canAnswerQuestion: boolean;
    files?: FileDto[];
    setFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    removeFile: (file: FileDto | null) => void;
    fileIsUploading: boolean;
    setFileIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
}

const validationSchema = (t: TFunction<"translation", undefined>) =>
    Yup.object({
        answer: Yup.string().required(t("clarificationQuestions.answer.required")),
        isPrivate: Yup.boolean().required(t("clarificationQuestions.isPrivate.required")),
    });

const BuyerAnswerQuestionForm: React.FC<IBuyerAnswerQuestionFormProps> = (props) => {
    const { canAnswerQuestion, files, setFiles, removeFile, fileIsUploading, setFileIsUploading } = props;
    const classes = useStyles();
    const { theme } = useReduxSelector((state) => state.themeState);

    const { t } = useTranslation();

    return (
        <Formik
            enableReinitialize
            initialValues={{
                answer: "",
                isPrivate: "true",
            }}
            validationSchema={validationSchema(t)}
            onSubmit={(values) => props.submit(values.answer, values.isPrivate)}
        >
            {({ errors, isValid, isSubmitting }) => (
                <Form className={classes.form}>
                    <RichTextEditorField
                        testid="clarification-answer-answer"
                        label={t("clarificationQuestions.answer.label")}
                        name="answer"
                        required
                        joditLicense={theme.joditLicence}
                    />
                    <DisplayFileUploadField
                        testid="clarification-question"
                        files={files}
                        canManageFiles={canAnswerQuestion}
                        removeFile={removeFile}
                        setFiles={setFiles}
                        fileIsUploading={fileIsUploading}
                        setFileIsUploading={setFileIsUploading}
                    />
                    <RadioButtonsField
                        label={t("clarificationQuestions.isPrivate.label")}
                        name="isPrivate"
                        options={[
                            { label: t("clarificationQuestions.isPrivate.options.private"), value: "true" },
                            { label: t("clarificationQuestions.isPrivate.options.all"), value: "false" },
                        ]}
                        required
                        testid="clarification-answer-is-private"
                    />
                    <div className={classes.buttonControl}>
                        <Button
                            testid="clarification-answer-submit-button"
                            label={t("clarificationQuestions.submitAnswer")}
                            type="submit"
                            size="large"
                            disabled={!isEmpty(errors) || !isValid || isSubmitting || fileIsUploading}
                        />
                        <TextButton
                            label={t("clarificationQuestions.cancelAnswer")}
                            onClick={props.cancel}
                            testid="clarification-answer-cancel-button"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default BuyerAnswerQuestionForm;
