import { Popup, useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { linkProjectFiles } from "api/fileApi";
import { answerQuestion, getQuestion } from "api/questions/clarificationQuestionsApi";
import { ICommonProps } from "components/shared";
import useFileUpload from "features/files/useFileUpload";
import BuyerAnswerQuestionDisplay from "features/project/clarificationQuestions/answerQuestion/BuyerAnswerQuestionDisplay";
import useNavTabs from "features/project/hooks/useNavTabs";
import useProject from "features/project/hooks/useProject";
import useAppDispatch from "hooks/useAppDispatch";
import useReduxSelector from "hooks/useReduxSelector";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import validationService from "services/validationService";
import { setBack, setPageTitle, setSecondaryNavItems, setTooltip } from "store/layoutSlice";
import { GetClarificationQuestionRequestDto } from "types/dtos/questions/GetClarificationQuestionRequestDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import EntityType from "types/enums/questions/EntityType";

interface IBuyerAnswerQuestionContainerProps extends ICommonProps {}

const BuyerAnswerQuestionContainer: React.FC<IBuyerAnswerQuestionContainerProps> = () => {
    const [questionInformation, setQuestionInformation] = useState<QuestionResponseDto>();
    const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
    const { sections } = useReduxSelector((state) => state.sectionState);
    const userUuid = useReduxSelector((state) => state.authenticationState.accessToken.user_uuid);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const toast = useToaster();
    const { questionUuid } = useParams();

    const { projectInformation } = useProject();
    const { buyerTabItems } = useNavTabs();
    const { fileIsUploading, setFileIsUploading, projectFiles, setProjectFiles, removeFile } = useFileUpload(
        projectInformation.uuid,
        EntityType.Answer,
    );

    const pageTitle = useMemo(() => {
        const hasAnswer = questionInformation?.answersCount;
        const shouldAnswer = questionInformation?.companyUuid === projectInformation.companyUuid;
        return t(`clarificationQuestions.titles.${hasAnswer || shouldAnswer ? "view" : "answer"}`);
    }, [projectInformation.companyUuid, questionInformation?.answersCount, questionInformation?.companyUuid, t]);

    const fetchData = useCallback(async () => {
        if (!(projectInformation.uuid && questionUuid && validationService.isValidUuid(questionUuid))) return;

        const response = await getQuestion(projectInformation.uuid, questionUuid, {
            includeAnswers: true,
            includeCompanyNames: true,
        } as GetClarificationQuestionRequestDto);

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("clarificationQuestions.api.getQuestionError"));
            return;
        }

        setQuestionInformation(response.data);
    }, [projectInformation.uuid, questionUuid, t, toast]);

    useEffect(() => {
        dispatch(setPageTitle(pageTitle));
        dispatch(setTooltip(t("clarificationQuestions.tooltips.all")));
        dispatch(
            setBack({
                route: buildRoute(routes.projects.clarificationQuestions, { projectUuid: projectInformation.uuid }),
            }),
        );
        dispatch(setSecondaryNavItems(buyerTabItems));

        fetchData();

        return () => {
            dispatch(setSecondaryNavItems(null));
        };
    }, [dispatch, fetchData, projectInformation.uuid, t, buyerTabItems, pageTitle]);

    const submitAnswer = useCallback(
        async (answer: string, isPrivate: string) => {
            if (!(projectInformation.uuid && questionUuid && userUuid && validationService.isValidUuid(questionUuid)))
                return;

            const response = await answerQuestion(projectInformation.uuid, questionUuid, {
                answeringText: answer,
                makeAvailableToAllSuppliers: isPrivate.toLocaleLowerCase() === "false",
            });

            if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                toast.error(t("clarificationQuestions.api.answerQuestionError"));
                return;
            }

            const newFiles = projectFiles.filter((file) => file.newUpload);
            if (newFiles.length) {
                const fileUuids = newFiles.map((file) => file.fileId);
                await linkProjectFiles(projectInformation.uuid, fileUuids, EntityType.Answer, response.data.answerUuid);
            }

            navigate(buildPath(routes.projects.clarificationQuestions, { projectUuid: projectInformation.uuid }));
            toast.success(t("clarificationQuestions.api.answerQuestionSuccess"));
        },
        [navigate, projectFiles, projectInformation.uuid, questionUuid, t, toast, userUuid],
    );

    const cancelAnswer = useCallback(() => {
        navigate(buildPath(routes.projects.clarificationQuestions, { projectUuid: projectInformation.uuid }));
    }, [navigate, projectInformation.uuid]);

    const setSectionType = () => {
        const section = sections.items.find((x) => x.id === questionInformation?.entityUuid);
        return section?.description ? section.description : section?.sectionType;
    };

    return (
        <React.Fragment>
            <BuyerAnswerQuestionDisplay
                project={projectInformation}
                question={questionInformation}
                submitAnswer={submitAnswer}
                cancelAnswer={() => setIsCancelPopupOpen(true)}
                sectionType={setSectionType()}
                files={projectFiles}
                removeFile={removeFile}
                setFiles={setProjectFiles}
                fileIsUploading={fileIsUploading}
                setFileIsUploading={setFileIsUploading}
            />
            <Popup
                title={t("popups.cancelAnswer.title")}
                message={t("popups.cancelAnswer.message")}
                isOpen={isCancelPopupOpen}
                primaryActionText={t("popups.cancelAnswer.cta.primary")}
                onPrimaryAction={cancelAnswer}
                onClose={() => setIsCancelPopupOpen(false)}
                testid="cancel-answer-popup"
            />
        </React.Fragment>
    );
};

export default BuyerAnswerQuestionContainer;
