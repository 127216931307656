import { faLinkHorizontal } from "@fortawesome/pro-regular-svg-icons";
import { Button, Copy, DateField, Tooltip, useToaster } from "@maistro/components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import useQuery from "hooks/useQuery";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        background: theme.colors.white,
        borderRadius: theme.spacing.xSmall,
        padding: theme.spacing.large,
    },
    title: {
        ...theme.typography.headings.h4,
    },
    copy: {
        marginTop: theme.spacing.xSmall,
        marginBottom: theme.spacing.small,
    },
    datePicker: {
        width: 300,
    },
    linkContainer: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: theme.spacing.small,
        marginTop: theme.spacing.medium,
        ...theme.typography.base,
        fontSize: 13,
    },
    bold: {
        fontWeight: 600,
    },
}));

interface IPublishToPortalProps {
    uuidValue: string;
    expressionOfInterestDeadline?: Date;
}

const PublishToPortal: React.FC<IPublishToPortalProps> = ({ uuidValue, expressionOfInterestDeadline }) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const urlSearchParameters = useQuery();
    const toast = useToaster();

    const projectUuid = useMemo(() => {
        if (uuidValue === "new") {
            return urlSearchParameters.get("projectUuid");
        }

        return uuidValue;
    }, [urlSearchParameters, uuidValue]);

    const link = `${window.location.origin}/expression-of-interest/${projectUuid}`;
    const copyToClipboard = () => {
        navigator.clipboard.writeText(link);
        toast.success(t("projectBrief.expressionOfInterest.linkCopied"));
    };

    const showLink = projectUuid && expressionOfInterestDeadline;

    return (
        <div className={classes.container}>
            <Tooltip
                className={classes.title}
                content={t("projectBrief.expressionOfInterest.tooltip")}
                start
                testid="publish-to-portal-tooltip"
            >
                {t("projectBrief.expressionOfInterest.publishToPortal")}
            </Tooltip>
            <Copy className={classes.copy} variant="sublabel">
                {t("projectBrief.expressionOfInterest.copy")}
            </Copy>
            <DateField
                wrapperClassName={classes.datePicker}
                name="expressionOfInterestDeadline"
                label={t("projectBrief.expressionOfInterest.label")}
                required
                withTime
                testid="project-brief-eoi-deadline"
            />
            {showLink && (
                <div className={classes.linkContainer}>
                    <Copy variant="sublabel">
                        <>
                            <span className={classes.bold}>{t("projectBrief.expressionOfInterest.linkText")}</span>{" "}
                            <span>{link}</span>
                        </>
                    </Copy>
                    <Button
                        label={t("projectBrief.expressionOfInterest.copyLink")}
                        type="button"
                        color="dark"
                        icon={faLinkHorizontal}
                        onClick={copyToClipboard}
                        testid="copy-link-button"
                    />
                </div>
            )}
        </div>
    );
};

export default PublishToPortal;
