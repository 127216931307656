import { Button, Copy, PasswordField, TextField, useToaster } from "@maistro/components";
import { Form, Formik } from "formik";
import { TFunction } from "i18next";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { getEmailDataForInvitationToken } from "api/emailApi";
import { activateUser } from "api/usersApi";
import { Loading } from "components";
import useAppDispatch from "hooks/useAppDispatch";
import useQuery from "hooks/useQuery";
import routes from "routes/routePaths/RoutePaths";
import apiErrorService from "services/apiErrorService";
import { setPageTitle } from "store/layoutSlice";
import { ITheme } from "styles/themes/types";
import { RegexPassword } from "types/consts/regexConstants";
import { EmailDataForInvitationTokenResponseDto } from "types/dtos/email/EmailDataForInvitationTokenResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import ValidationErrorsDto from "types/dtos/ValidationErrorsDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "raw",
        gap: theme.spacing.small,
    },
    copy: {
        marginBottom: theme.spacing.xLarge,
        maxWidth: "100%",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.large,
    },
    url: {
        fontFamily: "Sora",
        color: theme.colors.highlight,
        lineHeight: "22px",
        fontSize: "14px",
        fontWeight: 400,
    },
}));

interface IActivateUserData {
    emailAddress: string;
    companyName: string;
    firstName: string;
    lastName: string;
    password: string;
    confirmPassword: string;
}

const validationSchema = (t: TFunction<"translation", undefined>) =>
    Yup.object({
        firstName: Yup.string().required(t("activateUser.form.firstName.required")),
        lastName: Yup.string().required(t("activateUser.form.lastName.required")),
        password: Yup.string()
            .required(t("activateUser.form.password.required"))
            .matches(new RegExp(RegexPassword), t("activateUser.form.password.invalid")),
        confirmPassword: Yup.string()
            .required(t("activateUser.form.confirmPassword.required"))
            .oneOf([Yup.ref("password")], t("activateUser.form.confirmPassword.mismatch")),
    });

const ActivateUser: React.FC = () => {
    const classes = useStyles();

    const [emailData, setEmailData] = useState<EmailDataForInvitationTokenResponseDto>();
    const [errorType, setErrorType] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const { protocol, hostname, port } = window.location;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const toast = useToaster();
    const queryParams = useQuery();
    const token = queryParams.get("token");

    useEffect(() => {
        dispatch(setPageTitle(t("activateUser.title")));
    }, [dispatch, t]);

    const handleTokenError = (errorMessage: string): string | null => {
        const normalizedError = errorMessage.toLowerCase();
        switch (true) {
            case normalizedError.includes("token has been used previously"):
                return "used";
            case normalizedError.includes("token has expired"):
                return "expired";
            default:
                return null;
        }
    };

    const fetchData = useCallback(async () => {
        if (!token) return;

        const response = await getEmailDataForInvitationToken(token);

        if (response.data instanceof TransactionErrorDto) {
            const errorMessages = response.data.errors.error || [];

            if (errorMessages.length === 0) {
                setErrorType(null);
                setIsLoading(false);
                return;
            }

            const firstErrorMessage = errorMessages[0];

            const responseErrorType = handleTokenError(firstErrorMessage);
            setErrorType(responseErrorType);

            toast.error(firstErrorMessage);
            setIsLoading(false);
            return;
        }

        if (response.data instanceof ValidationErrorsDto) {
            const firstValidationError =
                response.data.validationErrors.length > 0
                    ? response.data.validationErrors[0].errorText
                    : "Validation error occurred";

            toast.error(firstValidationError);
            setIsLoading(false);
            return;
        }

        if (response.status !== 200) {
            toast.error(t("activateUser.api.getDataForTokenError"));
            setIsLoading(false);
            return;
        }

        setEmailData(response.data);
        setIsLoading(false);
    }, [t, toast, token]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onSubmit = async (values: IActivateUserData) => {
        if (!(token && emailData?.companyUserUuid)) return;

        const response = await activateUser({
            encodedToken: token,
            userUuid: emailData.companyUserUuid,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            confirmPassword: values.confirmPassword,
        });

        if (response.data instanceof TransactionErrorDto || response.data instanceof ValidationErrorsDto) {
            toast.error(apiErrorService.getFirstErrorFromResponse(response.data));
            return;
        }

        if (response.status !== 200) {
            toast.error(t("activateUser.api.activateUserError"));
            return;
        }

        navigate(routes.common.dashboard.path, { replace: true });
    };

    let formattedUrl = `${protocol}//${hostname}`;
    if (port) {
        formattedUrl += `:${port}`;
    }

    if (isLoading) {
        return <Loading />;
    }

    if (errorType === "expired") {
        return (
            <div className={classes.container}>
                <Copy>{t("activateUser.error.tokenExpired")}</Copy>
                <a className={classes.url} href={formattedUrl}>
                    {formattedUrl}
                </a>
            </div>
        );
    }

    if (errorType === "used") {
        return (
            <div className={classes.container}>
                <Copy>{t("activateUser.error.tokenUsed")}</Copy>
                <a className={classes.url} href={formattedUrl}>
                    {formattedUrl}
                </a>
            </div>
        );
    }

    if (!emailData?.companyUserUuid && errorType === null) {
        return <Copy>{t("activateUser.invalidLink")}</Copy>;
    }

    return (
        <>
            <Copy className={classes.copy}>{t("activateUser.copy")}</Copy>
            <Formik
                initialValues={{
                    emailAddress: emailData?.emailAddress || "",
                    companyName: emailData?.companyName || "",
                    firstName: emailData?.firstName || "",
                    lastName: emailData?.lastName || "",
                    password: "",
                    confirmPassword: "",
                }}
                validationSchema={validationSchema(t)}
                enableReinitialize
                onSubmit={(values: IActivateUserData) => onSubmit(values)}
            >
                {({ errors }) => (
                    <Form className={classes.form} autoComplete="false">
                        <TextField
                            testid="activate-user-email-address"
                            label={t("activateUser.form.emailAddress.label")}
                            type="email"
                            name="emailAddress"
                            disabled
                        />
                        <TextField
                            testid="activate-user-company-name"
                            label={t("activateUser.form.companyName.label")}
                            name="companyName"
                            disabled
                        />
                        <TextField
                            testid="activate-user-first-name"
                            label={t("activateUser.form.firstName.label")}
                            name="firstName"
                            required
                        />
                        <TextField
                            testid="activate-user-last-name"
                            label={t("activateUser.form.lastName.label")}
                            name="lastName"
                            required
                        />
                        <PasswordField
                            testid="activate-user-password"
                            label={t("activateUser.form.password.label")}
                            copy={t("activateUser.form.password.copy")}
                            name="password"
                            required
                        />
                        <PasswordField
                            testid="activate-user-confirm-password"
                            label={t("activateUser.form.confirmPassword.label")}
                            name="confirmPassword"
                            required
                        />
                        <Button
                            label={t("activateUser.submitButton")}
                            type="submit"
                            size="large"
                            disabled={!isEmpty(errors)}
                            testid="activate-user-submit-button"
                        />
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ActivateUser;
