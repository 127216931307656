import { IMeatballMenuItem, ITableColumn, MeatballMenu } from "@maistro/components";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { BuyerSupplierRelationshipPill } from "components";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { BuyerSupplierRelationshipDto } from "types/dtos/company/buyer-supplier-relationships/BuyerSupplierRelationshipDto";
import { getCompanySizeRange } from "types/enums/companies/CompanySizeV2";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

type UseSupplierListConfigurationType = {
    setSelectedSupplier: (supplier: BuyerSupplierRelationshipDto) => void;
    setIsRelationshipStatusDrawerOpen: (isOpen: boolean) => void;
    setIsTieringDrawerOpen: (isOpen: boolean) => void;
    setIsExternalIdDrawerOpen: (isOpen: boolean) => void;
    setShowRemoveSupplierPopup: (show: boolean) => void;
    buyerCompanyUuid: string;
};

const useSupplierListConfiguration = ({
    setSelectedSupplier,
    setIsRelationshipStatusDrawerOpen,
    setIsTieringDrawerOpen,
    setIsExternalIdDrawerOpen,
    setShowRemoveSupplierPopup,
    buyerCompanyUuid,
}: UseSupplierListConfigurationType) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { userHasCompanyPermission, userIsMaistro } = useCurrentUser();

    const navigateToSupplierUrl = useCallback(
        (companyUuid: string) => {
            const path = userIsMaistro
                ? buildPath(routes.company.companyProfile, { companyUuid })
                : buildPath(routes.company.supplierCompanyProfile, { companyUuid });

            navigate(path, {
                state: {
                    route: buildRoute(routes.company.companySuppliers, { companyUuid: buyerCompanyUuid }),
                },
            });
        },
        [buyerCompanyUuid, navigate, userIsMaistro],
    );

    const navigateToSupplierContracts = useCallback(
        (companyUuid: string) => {
            const path = buildPath(routes.company.companyContracts, {
                supplierUuid: companyUuid,
                companyUuid: buyerCompanyUuid,
            });

            navigate(path, {
                state: {
                    route: buildRoute(routes.company.companySuppliers, { companyUuid: buyerCompanyUuid }),
                },
            });
        },
        [buyerCompanyUuid, navigate],
    );

    const onRowClick = useCallback(
        (row: BuyerSupplierRelationshipDto) => {
            navigateToSupplierUrl(row.companyUuid);
        },
        [navigateToSupplierUrl],
    );

    const menuItemsForSupplier = useCallback(
        (supplier: BuyerSupplierRelationshipDto) => {
            const actions: IMeatballMenuItem[] = [
                {
                    label: t("companySuppliers.labels.supplierDetails"),
                    action: () => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: `Company Suppliers - Details - `.concat(supplier.companyName),
                        });
                        navigateToSupplierUrl(supplier.companyUuid);
                    },
                },
            ];
            if (userHasCompanyPermission(PermissionsEnum.UpdateSupplierRelationshipStatus)) {
                actions.push({
                    label: t("companySuppliers.labels.relationshipStatus"),
                    action: () => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: `Company Suppliers - Relationship Status - `.concat(supplier.companyName),
                        });
                        setSelectedSupplier(supplier);
                        setIsRelationshipStatusDrawerOpen(true);
                    },
                });

                actions.push({
                    label: t("companySuppliers.labels.tieringStatus"),
                    action: () => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: `Company Suppliers - Tiering - `.concat(supplier.companyName),
                        });
                        setSelectedSupplier(supplier);
                        setIsTieringDrawerOpen(true);
                    },
                });

                actions.push({
                    label: t("companySuppliers.labels.externalId"),
                    action: () => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: `Company Suppliers - External Id - `.concat(supplier.companyName),
                        });
                        setSelectedSupplier(supplier);
                        setIsExternalIdDrawerOpen(true);
                    },
                });

                actions.push({
                    label: t("companySuppliers.labels.contracts"),
                    action: () => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: `Company Suppliers - Contracts - `.concat(supplier.companyName),
                        });
                        navigateToSupplierContracts(supplier.companyUuid);
                    },
                });
            }
            if (userIsMaistro && supplier.isPreferredSupplier) {
                actions.push({
                    label: t("companySuppliers.labels.removeSupplier"),
                    action: () => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: `Company Suppliers - Remove - `.concat(supplier.companyName),
                        });
                        setSelectedSupplier(supplier);
                        setShowRemoveSupplierPopup(true);
                    },
                });
            }

            return actions;
        },
        [
            navigateToSupplierContracts,
            navigateToSupplierUrl,
            setIsExternalIdDrawerOpen,
            setIsRelationshipStatusDrawerOpen,
            setIsTieringDrawerOpen,
            setSelectedSupplier,
            setShowRemoveSupplierPopup,
            t,
            userHasCompanyPermission,
            userIsMaistro,
        ],
    );

    const columns: ITableColumn[] = useMemo(() => {
        return [
            {
                position: 1,
                priorityLevel: 1,
                label: t("companySuppliers.table.companyName"),
                name: "companyName",
                minWidth: 200,
                contentWrap: true,
                sortable: true,
            },
            {
                position: 2,
                priorityLevel: 4,
                label: t("companySuppliers.table.companySize"),
                name: "size",
                minWidth: 100,
                render: (supplier: BuyerSupplierRelationshipDto) => getCompanySizeRange(supplier.companySize),
            },
            {
                position: 3,
                priorityLevel: 3,
                label: t("companySuppliers.table.activeStatus"),
                name: "status",
                minWidth: 100,
                tooltip: t("companySuppliers.tooltips.activeStatus"),
                render: (supplier: BuyerSupplierRelationshipDto) => (
                    <BuyerSupplierRelationshipPill
                        status={supplier.isActive ? "Active" : "Inactive"}
                        companyUuid={supplier.companyUuid}
                        type="active"
                    />
                ),
            },
            {
                position: 4,
                priorityLevel: 2,
                label: t("companySuppliers.table.relationshipStatus"),
                name: "relationshipStatus",
                minWidth: 100,
                tooltip: t("companySuppliers.tooltips.relationshipStatus"),
                render: (supplier: BuyerSupplierRelationshipDto) => (
                    <BuyerSupplierRelationshipPill
                        status={supplier.supplierRelationshipStatus}
                        companyUuid={supplier.companyUuid}
                        type="relationship"
                    />
                ),
            },
            {
                position: 5,
                priorityLevel: 5,
                label: t("companySuppliers.table.tieringStatus"),
                name: "tiering",
                minWidth: 100,
                tooltip: t("companySuppliers.tooltips.tieringStatus"),
                render: (supplier: BuyerSupplierRelationshipDto) => (
                    <BuyerSupplierRelationshipPill
                        status={supplier.supplierTieringStatus}
                        companyUuid={supplier.companyUuid}
                        type="tiering"
                    />
                ),
            },
            {
                position: 6,
                priorityLevel: 6,
                label: t("companySuppliers.table.externalId"),
                name: "externalId",
                minWidth: 100,
                contentWrap: true,
                tooltip: t("companySuppliers.tooltips.externalId"),
            },
            {
                position: 7,
                priorityLevel: 0,
                name: "menu",
                minWidth: 50,
                render: (supplier: BuyerSupplierRelationshipDto) => (
                    <MeatballMenu menuItems={menuItemsForSupplier(supplier)} />
                ),
            },
        ];
    }, [menuItemsForSupplier, t]);

    return {
        columnConfiguration: columns,
        onRowClick,
    };
};

export default useSupplierListConfiguration;
