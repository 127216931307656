import { AxiosResponse } from "axios";

import { createAxiosInstance } from "api/commonApi";
import env from "config/EnvironmentVariables";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { ListPlatformFeaturesDto } from "types/dtos/platform/ListPlatformFeaturesDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);

// eslint-disable-next-line import/prefer-default-export
export const listPlatformFeatures = (): Promise<
    AxiosResponse<ListPlatformFeaturesDto> | AxiosResponse<TransactionErrorDto>
> => {
    return api.get(`/platform-features`);
};
