import { from, Route } from "routes/helpers/RoutesHelper";

export interface ProjectParams {
    projectUuid: string;
}

interface QuestionParams {
    questionUuid: string;
}

interface SupplierParams {
    supplierUuid: string;
}

interface SupplierQuestionParams {
    questionUuid: string;
    supplierUuid: string;
}

const projectRoot: Route<ProjectParams> = { path: "/projects/:projectUuid" };

const viewClarificationQuestion = from(projectRoot, {
    path: "/question/:questionUuid",
} as Route<QuestionParams>);

const scoreTenderQuestion = from(projectRoot, {
    path: "/tender-summary/questions/:questionUuid",
    translationKey: "scoreTenderQuestions",
} as Route<QuestionParams>);

const scoreTenderQuestionsBySupplier = from(projectRoot, {
    path: "/tender-summary/by-supplier/:supplierUuid/questions/:questionUuid",
    translationKey: "scoreTenderQuestionsBySupplier",
} as Route<SupplierQuestionParams>);

const askClarificationQuestion = from(projectRoot, {
    path: "/questions/ask",
});
const award = from(projectRoot, { path: "/award", translationKey: "awardSummary" });
const awardSupplier = from(projectRoot, {
    path: "/award-supplier",
    translationKey: "awardSupplier",
});
const complete = from(projectRoot, { path: "/complete" });
const completeWithoutAward = from(projectRoot, {
    path: "/complete-without-award",
});
const matching = from(projectRoot, { path: "/matching", translationKey: "matching" });
const monitor = from(projectRoot, { path: "/monitor", translationKey: "monitor" });
const monitorApprovals = from(projectRoot, {
    path: "/monitor-approvals",
    translationKey: "monitorApprovals",
});
const monitorExpressionOfInterest = from(projectRoot, { path: "/monitor-eoi" });
const notFound = from(projectRoot, { path: "*" });
const clarificationQuestions = from(projectRoot, {
    path: "/questions",
    translationKey: "clarificationQuestions",
});
const results = from(projectRoot, { path: "/results", translationKey: "results" });
const scoreSummaryReport = from(projectRoot, {
    path: "/tender-summary/score-summary-report",
    translationKey: "scoreSummaryReport",
});
const sections = from(projectRoot, {
    path: "/tender-criteria/sections",
    translationKey: "tenderSections",
});
const sectionQuestions = from(projectRoot, {
    path: "/tender-criteria/questions",
    translationKey: "tenderQuestions",
});
const sectionQuestionsView = from(projectRoot, {
    path: "/tender-criteria/questions-view",
    translationKey: "tenderQuestions",
});
const settings = from(projectRoot, { path: "/settings", translationKey: "settings" });
const summary = from(projectRoot, { path: "/summary", translationKey: "projectSummary" });
const tenderDocuments = from(projectRoot, {
    path: "/tender-documents",
});
const tenderSummary = from(projectRoot, {
    path: "/tender-summary",
    translationKey: "tenderSummary",
});
const tenderSummaryBySupplier = from(projectRoot, {
    path: "/tender-summary/:supplierUuid",
} as Route<SupplierParams>);
const tenderSummaryQuestions = from(projectRoot, {
    path: "/tender-summary/questions-view",
    translationKey: "tenderQuestions",
});
const weighting = from(projectRoot, {
    path: "/tender-criteria/sections/weighting",
    translationKey: "tenderSectionWeighting",
});

const projectBrief: Route<ProjectParams> = {
    path: "/project-brief/:projectUuid",
    translationKey: "projectBrief",
};

const generateProjectDescription: Route<ProjectParams> = {
    path: "/project-brief/:projectUuid/generate-description",
    translationKey: "generateProjectDescription",
};

const projectNotFound: Route = { path: "/projects/not-found" };

export const projectsRoute = from(projectRoot, { path: "/*" });
export default {
    askClarificationQuestion,
    viewClarificationQuestion,
    award,
    awardSupplier,
    complete,
    completeWithoutAward,
    matching,
    monitor,
    monitorApprovals,
    monitorExpressionOfInterest,
    notFound,
    clarificationQuestions,
    results,
    scoreSummaryReport,
    scoreTenderQuestion,
    sections,
    sectionQuestions,
    sectionQuestionsView,
    settings,
    summary,
    tenderDocuments,
    tenderSummary,
    tenderSummaryQuestions,
    weighting,
    projectBrief,
    generateProjectDescription,
    projectNotFound,
    tenderSummaryBySupplier,
    scoreTenderQuestionsBySupplier,
};
