import { faCheck, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { Button, Copy, Pill } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import ApprovalsRow from "features/project/monitorApprovals/components/ApprovalsRow";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { ITheme } from "styles/themes/types";
import { EntityApprovalDto } from "types/dtos/company/approvals/EntityApprovalDto";
import PolicyApprovalStatusType from "types/enums/companies/Approvals/PolicyApprovalStatusType";

const useStyles = createUseStyles((theme: ITheme) => ({
    copy: {
        marginBottom: theme.spacing.xLarge,
    },
    subTitleBold: {
        fontWeight: 600,
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
        gap: theme.spacing.small,
        alignItems: "center",
    },
}));

interface IMonitorApprovalsDisplayProps {
    approvals: EntityApprovalDto[];
    isAwardingSupplier: boolean;
    projectName: string;
    supplierName: string | undefined;
    projectUuid: string;
    setIsAwardProjectPopupOpen: (isOpen: boolean) => void;
}

const MonitorApprovalsDisplay: React.FC<IMonitorApprovalsDisplayProps> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { approvals, isAwardingSupplier, projectName, projectUuid, setIsAwardProjectPopupOpen, supplierName } = props;

    const approvalsComplete = approvals.length > 0 && approvals.filter((p) => !p.isActioned).length === 0;
    const projectRejected =
        approvals.filter(
            (p) => p.isActioned && p.approvers.filter((a) => a.status === PolicyApprovalStatusType.Rejected).length > 0,
        ).length > 0;

    const handleButtonClick = () => {
        if (projectRejected) {
            navigate(buildPath(routes.projects.awardSupplier, { projectUuid }));
            return;
        }

        setIsAwardProjectPopupOpen(true);
    };

    return (
        <div>
            <Copy className={classes.copy}>
                <>
                    {t("monitorApprovals.subTitle1")}
                    <span className={classes.subTitleBold} data-testid="project-name">
                        {projectName}
                    </span>
                </>
            </Copy>
            {supplierName && (
                <Copy className={classes.copy}>
                    <>
                        {t("monitorApprovals.subTitle2")}
                        <span className={classes.subTitleBold} data-testid="supplier-name">
                            {supplierName}
                        </span>
                    </>
                </Copy>
            )}
            {(approvalsComplete || projectRejected) && (
                <div className={classes.row}>
                    {projectRejected && (
                        <Pill
                            value={t("monitorApprovals.projectRejected")}
                            color="error"
                            icon={faXmark}
                            testid="project-rejected-pill"
                        />
                    )}
                    {!projectRejected && (
                        <Pill
                            value={t("monitorApprovals.projectApproved")}
                            color="success"
                            icon={faCheck}
                            testid="project-approved-pill"
                        />
                    )}

                    <Button
                        label={
                            projectRejected
                                ? t("monitorApprovals.backToResults")
                                : t("monitorApprovals.awardSelectedSupplier")
                        }
                        variant="filled"
                        disabled={isAwardingSupplier}
                        onClick={handleButtonClick}
                        testid="continue-button"
                    />
                </div>
            )}
            {approvals.map((approval) => (
                <ApprovalsRow key={approval.uuid} approval={approval} />
            ))}
        </div>
    );
};

export default MonitorApprovalsDisplay;
