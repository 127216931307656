import { faAngleDown, faAngleUp, faBars } from "@fortawesome/pro-regular-svg-icons";
import { Drawer, Icon } from "@maistro/components";
import React, { useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import Avatar from "components/Avatar/Avatar";
import SecondaryOptions from "components/Layout/SecondaryOptions";
import { ICommonProps } from "components/shared";
import ChangePassword from "features/user/ChangePassword";
import UserProfile from "features/user/UserProfile";
import useChangePassword from "features/user/hooks/useChangePassword";
import useUserProfile from "features/user/hooks/useUserProfile";
import useProfileBar from "hooks/useProfileBar";
import useReduxSelector from "hooks/useReduxSelector";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import stringService from "services/stringService";
import { ITheme } from "styles/themes/types";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    component: {
        height: theme.layout.navBarHeight,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: theme.colors.primary,
        position: "relative",
    },

    profileBarBlock: {
        height: "100%",
        display: "flex",
        alignItems: "center",
    },

    menu: {
        position: "absolute",
        top: theme.layout.navBarHeight,
        left: 0,
        listStyleType: "none",
        backgroundColor: theme.colors.white,
        border: `1px solid ${theme.colors.lightGray}`,
        color: theme.colors.primary,
        borderRadius: theme.spacing.xSmall,
        overflow: "hidden",
    },

    link: {
        padding: `${theme.spacing.small}px ${theme.spacing.medium}px`,
        width: "100%",
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
        minWidth: 100,
        gap: theme.spacing.xSmall,
        "& span": {
            // icon
            fontSize: 18,
        },
        "&:hover": {
            backgroundColor: theme.colors.highlight,
            color: theme.colors.white,
            cursor: "pointer",
        },
    },

    logo: {
        backgroundColor: theme.colors.logoBackground,
        display: "flex",
        "& img": {
            maxHeight: theme.layout.navBarHeight - theme.spacing.medium,
            padding: 2,
            margin: theme.spacing.xSmall,
        },
    },

    avatarGroup: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        gap: theme.spacing.small,
        padding: `0 ${theme.spacing.small}px`,
        position: "relative",
        fontSize: 12,
        color: theme.colors.white,
    },

    profileBarMenuIcon: {
        padding: theme.spacing.small,
        fontSize: 20,
        color: theme.colors.white,
        height: "100%",
        display: "inline-flex",
        alignItems: "center",
        "&:hover": {
            backgroundColor: theme.colors.highlight,
            color: theme.colors.white,
            cursor: "pointer",
        },
    },

    secondaryOptions: {
        marginLeft: "auto",
    },

    [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        component: {
            backgroundColor: theme.colors.white,
            justifyContent: "right",
        },
        menu: {
            right: 0,
            left: "auto",
        },
        avatarGroup: {
            ...theme.typography.base,
            "&:hover": {
                backgroundColor: theme.colors.highlight,
                color: theme.colors.white,
                cursor: "pointer",
            },
        },
        profileBarBlock: {
            borderLeft: `1px solid ${theme.colors.lightGray}`,
        },
    },
}));

interface IProfileBarProps extends ICommonProps {
    onOpenMenu: () => void;
    user?: {
        name: string;
        avatar?: string;
    };
}

const ProfileBar: React.FC<IProfileBarProps> = (props) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles();

    const { user_uuid: userUuid } = useReduxSelector((state) => state.authenticationState.accessToken);

    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

    const { changePassword, isChangePasswordOpen, setIsChangePasswordOpen } = useChangePassword();
    const { isProfileOpen, setIsProfileOpen, updateProfile } = useUserProfile();
    const { visibleItems } = useProfileBar(setIsProfileOpen, setIsChangePasswordOpen);

    return (
        <div className={props.className} data-component="ProfileBar" data-testid={props.testid}>
            <div className={classes.component} onMouseLeave={() => setIsProfileMenuOpen(false)}>
                <div className={classes.profileBarBlock}>
                    <span className={classes.logo}>
                        <img className="hidden-desktop" alt="Logo" src={theme.logos.squareLogo} />
                    </span>
                    {props.user && (
                        <div
                            className={classes.avatarGroup}
                            onClick={() => {
                                if (!isProfileMenuOpen) {
                                    ReactGA.event({
                                        category: ga4Category.Menu,
                                        action: ga4Action.MenuOpen,
                                        label: "Profile",
                                    });
                                }
                                setIsProfileMenuOpen(!isProfileMenuOpen);
                            }}
                            data-testid={`${props.testid}-dropdown`}
                        >
                            <ul hidden={!isProfileMenuOpen} className={classes.menu}>
                                {visibleItems?.map((item) => (
                                    <li key={item.label}>
                                        <Link
                                            className={classes.link}
                                            to={item.route ?? ""}
                                            onClick={() => {
                                                ReactGA.event({
                                                    category: ga4Category.Menu,
                                                    action: ga4Action.MenuElement,
                                                    label: "Profile - ".concat(item.label),
                                                });
                                                item.onClick?.();
                                            }}
                                            data-testid={`${stringService.toKebabCase(item.label)}-link`}
                                        >
                                            <Icon icon={item.icon} centered />
                                            {item.label}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                            <Avatar name={props.user.name} round size="medium" />
                            <span className="hidden-mobile">{props.user.name}</span>
                            <Icon size="small" icon={isProfileMenuOpen ? faAngleUp : faAngleDown} />
                        </div>
                    )}
                </div>
                {props.user && <SecondaryOptions className={`hidden-desktop ${classes.secondaryOptions}`} />}
                <span
                    className={`${classes.profileBarMenuIcon} hidden-desktop`}
                    onClick={props.onOpenMenu}
                    data-testid="profile-bar-menu"
                >
                    <Icon icon={faBars} />
                </span>
            </div>
            <Drawer
                isOpen={isChangePasswordOpen}
                onClose={() => setIsChangePasswordOpen(false)}
                testid="change-password-drawer"
            >
                <ChangePassword
                    userUuid={userUuid}
                    submit={changePassword}
                    cancel={() => setIsChangePasswordOpen(false)}
                />
            </Drawer>
            <Drawer isOpen={isProfileOpen} onClose={() => setIsProfileOpen(false)} testid="user-profile-drawer">
                <UserProfile submit={updateProfile} cancel={() => setIsProfileOpen(false)} />
            </Drawer>
        </div>
    );
};

export default ProfileBar;
