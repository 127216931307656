import jwtDecode from "jwt-decode";
import { User } from "oidc-client-ts";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { LoadingApplication } from "components";
import { IRedirectState } from "features/auth/GuardedRoute";
import useCallbackHook from "features/auth/oidc/useCallbackHook";
import useAppDispatch from "hooks/useAppDispatch";
import routes from "routes/routePaths/RoutePaths";
import { useLazyGetCompanyFeaturesQuery } from "store/api/companyApi";
import { IAccessToken, setAccessToken, setAccessTokenRaw } from "store/authenticationSlice";
import { fetchCompanyDetails } from "store/companySlice";
import { fetchPlatformFeatures } from "store/platformSlice";
import { fetchUserPermissions } from "store/rolesPermissionsSlice";
import { unloadTheme } from "store/themeSlice";

const decodeJwt = (jwt: string): IAccessToken => {
    const accessToken: IAccessToken = jwtDecode(jwt);
    return accessToken;
};

const Callback: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [fetchCompanyFeatures] = useLazyGetCompanyFeaturesQuery();

    const successCallback = useCallback(
        (user: User) => {
            if (user) {
                const accessToken: IAccessToken = decodeJwt(user.access_token);
                dispatch(setAccessToken(accessToken));
                dispatch(setAccessTokenRaw(user.access_token));
                dispatch(fetchUserPermissions({ userUuid: accessToken.user_uuid }));
                dispatch(fetchPlatformFeatures({}));
                if (accessToken.company_uuid) {
                    fetchCompanyFeatures(accessToken.company_uuid);
                    dispatch(fetchCompanyDetails(accessToken.company_uuid));
                }
            }
            dispatch(unloadTheme());
            const redirectState = user.state as IRedirectState;
            const redirectPath = redirectState.path;
            navigate(redirectPath ?? routes.common.dashboard.path, { replace: true });
        },
        [dispatch, navigate, fetchCompanyFeatures],
    );

    const errorCallback = (error: Error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        navigate(routes.public.callbackError.path, { replace: true });
    };

    useCallbackHook({
        successCallback,
        errorCallback,
    });

    return <LoadingApplication testid="loading-application-screen" />;
};

export default Callback;
