import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import type { AppStore } from "store/store";

let store: ToolkitStore<AppStore>;

export const injectStore = (_store: ToolkitStore<AppStore>) => {
    store = _store;
};

const createErrorResponse = (error: AxiosError) => {
    let genericErrorResponse: AxiosResponse | undefined = error.response;
    if (error.config) {
        genericErrorResponse = {
            config: error.config,
            data: undefined,
            headers: error.request?.headers,
            status: 500,
            statusText: "Internal Server Error",
        };
    }
    if (genericErrorResponse) {
        if (error.code === "ETIMEDOUT") {
            genericErrorResponse.status = 504;
            genericErrorResponse.statusText = "Gateway Timeout";
        } else {
            genericErrorResponse.status = 503;
            genericErrorResponse.statusText = "Service Unavailable";
        }
    }

    return genericErrorResponse;
};

function convertDatesToUTC(data: unknown): unknown {
    const convert = (value: unknown): unknown => {
        if (value instanceof Date) {
            return value.toISOString();
        }
        if (Array.isArray(value)) {
            return value.map(convert);
        }
        if (typeof value === "object" && value !== null) {
            return Object.fromEntries(Object.entries(value).map(([k, v]) => [k, convert(v)]));
        }
        return value;
    };

    return convert(data);
}

export const createAxiosInstance = (baseUrl: string) => {
    const instance = axios.create({
        baseURL: baseUrl,
        headers: {
            accept: "application/json",
            "content-type": "application/json",
        },
        timeout: 60000,
        transitional: { clarifyTimeoutError: true },
    });

    instance.interceptors.request.use((config) => {
        const accessToken = store.getState().oidcState.user?.access_token;
        if (config.headers) {
            config.headers.authorization = accessToken ? `Bearer ${accessToken}` : "";
        }
        if (config.data && !(config.data instanceof FormData)) {
            config.data = convertDatesToUTC(config.data);
        }
        return config;
    });

    instance.interceptors.response.use(
        (response: AxiosResponse) => response,
        (error: AxiosError) => error.response ?? createErrorResponse(error),
    );

    return instance;
};

export type BaseAxiosErrorResponse = {
    error: {
        status?: number;
        data?: unknown;
    };
};

export const axiosBaseQuery =
    (
        { baseUrl }: { baseUrl: string } = { baseUrl: "" },
    ): BaseQueryFn<
        {
            url: string;
            method: AxiosRequestConfig["method"];
            data?: AxiosRequestConfig["data"];
            params?: AxiosRequestConfig["params"];
        },
        unknown,
        unknown
    > =>
    async ({ url, method, data, params }) => {
        try {
            const accessToken = store.getState().oidcState.user?.access_token;
            const result = await axios({
                url: baseUrl + url,
                method,
                data,
                params,
                headers: {
                    accept: "application/json",
                    "content-type": "application/json",
                    authorization: accessToken ? `Bearer ${accessToken}` : "",
                },
                timeout: 60000,
                transitional: { clarifyTimeoutError: true },
            });
            return { data: result.data };
        } catch (axiosError) {
            const err = axiosError as AxiosError;
            const error = err.response ?? createErrorResponse(err);
            return {
                error: {
                    status: error?.status,
                    data: error?.data || err.message,
                },
            } as BaseAxiosErrorResponse;
        }
    };
