import { faPaperclip, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { BooleanOptionsField, Button, ITableColumn, Icon, Label, MeatballMenu, Table } from "@maistro/components";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import AddOrEditMembershipDrawer from "features/company/profile/vetting/sections/dataAndSecuritySubSections/AddOrEditMembershipDrawer";
import dateTimeService from "services/dateTimeService";
import { ITheme } from "styles/themes/types";
import { MembershipDto } from "types/dtos/company/vettingInformation/MembershipDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    memberships: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
        marginTop: theme.spacing.small,
    },
}));

interface ProfessionalBodyMembershipsProps {
    hasTradeAssociationMembership?: boolean;
    memberships: MembershipDto[];
    setMemberships: React.Dispatch<React.SetStateAction<MembershipDto[]>>;
}

const ProfessionalBodyMemberships: React.FC<ProfessionalBodyMembershipsProps> = ({
    hasTradeAssociationMembership,
    memberships,
    setMemberships,
}) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const [isMembershipDrawerOpen, setIsMembershipDrawerOpen] = useState(false);
    const [selectedMembership, setSelectedMembership] = useState<MembershipDto>();

    const onSave = (membership: MembershipDto) => {
        if (!selectedMembership) {
            setMemberships((prevState) => {
                return [...prevState, membership];
            });
            setIsMembershipDrawerOpen(false);
            setSelectedMembership(undefined);
            return;
        }
        setMemberships((prevState) => {
            const currentMemberships = [...prevState];
            const selectedMembershipIndex = currentMemberships.indexOf(selectedMembership);
            if (selectedMembershipIndex > -1) {
                currentMemberships.splice(selectedMembershipIndex, 1);
            }
            currentMemberships.push(membership);
            return currentMemberships;
        });
        setIsMembershipDrawerOpen(false);
        setSelectedMembership(undefined);
    };

    const onEdit = useCallback((membership: MembershipDto) => {
        setSelectedMembership(membership);
        setIsMembershipDrawerOpen(true);
    }, []);

    const onDelete = useCallback(
        (membership: MembershipDto) => {
            setMemberships((prevState) => {
                const currentMemberships = [...prevState];
                const selectedMembershipIndex = currentMemberships.indexOf(membership);
                if (selectedMembershipIndex > -1) {
                    currentMemberships.splice(selectedMembershipIndex, 1);
                }
                return currentMemberships;
            });
        },
        [setMemberships],
    );

    const onClose = () => {
        setIsMembershipDrawerOpen(false);
        setSelectedMembership(undefined);
    };

    const columns: ITableColumn[] = useMemo(
        () => [
            {
                position: 1,
                priorityLevel: 1,
                label: t(
                    "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.memberships.form.membershipType.label",
                ),
                name: "membershipType",
                minWidth: 150,
            },
            {
                position: 2,
                priorityLevel: 4,
                label: t(
                    "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.memberships.form.membershipNumber.label",
                ),
                name: "membershipNumber",
                minWidth: 150,
            },
            {
                position: 3,
                priorityLevel: 2,
                label: t(
                    "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.memberships.form.expiryDate.label",
                ),
                name: "expiryDate",
                render: (membership: MembershipDto) => {
                    if (dateTimeService.dateIsInPast(membership.expiryDate)) {
                        return t("common.expired");
                    }
                    return dateTimeService.toShortDateFormat(membership.expiryDate);
                },
                minWidth: 125,
            },
            {
                position: 4,
                priorityLevel: 3,
                name: "files",
                render: () => <Icon icon={faPaperclip} size="small" />,
                minWidth: 50,
            },
            {
                position: 5,
                priorityLevel: 0,
                name: "menu",
                minWidth: 50,
                render: (membership: MembershipDto) => (
                    <MeatballMenu
                        menuItems={[
                            {
                                label: t("common.edit"),
                                action: () => onEdit(membership),
                            },
                            {
                                label: t("common.delete"),
                                action: () => onDelete(membership),
                            },
                        ]}
                    />
                ),
            },
        ],
        [onDelete, onEdit, t],
    );

    return (
        <>
            <BooleanOptionsField
                name="hasTradeAssociationMembership"
                falseOptionLabel={t("common.no")}
                trueOptionLabel={t("common.yes")}
                required
                label={t("companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.memberships.label")}
                testid="has-staff-training-options-field"
            />
            {hasTradeAssociationMembership && (
                <div className={classes.memberships}>
                    <Label
                        name=""
                        content={t(
                            "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.memberships.field",
                        )}
                        required
                        testid="membership-label"
                    />
                    <Button
                        label={t(
                            "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.memberships.addMembership",
                        )}
                        icon={faPlus}
                        size="small"
                        onClick={() => setIsMembershipDrawerOpen(true)}
                        testid="add-membership-button"
                    />
                    <Table
                        columns={columns}
                        data={memberships}
                        noDataDisplay={t(
                            "companyVettingQuestionnaire.sections.dataAndSecurity.dataProtection.memberships.noData",
                        )}
                        testid="membership-list"
                    />
                    <AddOrEditMembershipDrawer
                        testid="add-or-edit-membership-drawer"
                        isOpen={isMembershipDrawerOpen}
                        onClose={onClose}
                        membership={selectedMembership}
                        onSave={onSave}
                    />
                </div>
            )}
        </>
    );
};

export default ProfessionalBodyMemberships;
