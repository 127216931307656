import { faSync } from "@fortawesome/pro-regular-svg-icons";
import { Alert, Button, Copy, Popup, Table, TextButton } from "@maistro/components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import AmendDeadlineForm from "features/project/components/AmendDeadline/AmendDeadlineForm";
import useSuppliersConfiguration from "features/project/expressionOfInterest/hooks/useSuppliersConfiguration";
import useMonitorStyles from "features/project/monitor/hooks/useMonitorStyles";
import { IProjectInformation } from "features/project/types";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import { ITheme } from "styles/themes/types";
import { ExpressionOfInterestResponseDto } from "types/dtos/projects/expressionOfInterest/ExpressionOfInterestResponseDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

const useStyles = createUseStyles((theme: ITheme) => ({
    stack: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.large,
    },
}));

interface IMonitorDisplayProps {
    project: IProjectInformation;
    suppliers: ExpressionOfInterestResponseDto[];
    selectSupplier: (supplier: ExpressionOfInterestResponseDto) => void;
    refreshSuppliers: () => void;
    isSelectingSupplier: boolean;
    completeProject: () => void;
    updateEoiDeadline: (deadline: Date) => void;
    deadlineHasPassed: boolean;
}

const MonitorDisplay: React.FC<IMonitorDisplayProps> = (props) => {
    const classes = useStyles();
    const sharedClasses = useMonitorStyles();

    const {
        project,
        suppliers,
        selectSupplier,
        refreshSuppliers,
        isSelectingSupplier,
        completeProject,
        updateEoiDeadline,
        deadlineHasPassed,
    } = props;

    const [isCompletePopupOpen, setIsCompletePopupOpen] = useState(false);

    const { t } = useTranslation();
    const { userHasProjectPermission } = useCurrentUser();

    const link = `${window.location.origin}/expression-of-interest/${project.uuid}`;

    const canUpdateProject = userHasProjectPermission(PermissionsEnum.UpdateProject, project.uuid);
    const canCompleteProject = canUpdateProject && suppliers.some((supplier) => supplier.isSelected);

    const { columns, industryTypes, companySizes } = useSuppliersConfiguration(
        deadlineHasPassed,
        selectSupplier,
        isSelectingSupplier,
    );

    const supplierData = suppliers.map((supplier) => {
        return {
            ...supplier,
            industryType: industryTypes.find((type) => type.value === supplier.industryTypeId.toString())?.label,
            companySize: companySizes.find((size) => size.value === supplier.companySizeId.toString())?.label,
        };
    });

    return (
        <>
            <div className={classes.stack}>
                {deadlineHasPassed && (
                    <Alert
                        label={t("expressionOfInterest.monitor.deadlineExpired")}
                        type="error"
                        testid="deadline-expired"
                    />
                )}
                <Copy>
                    <>
                        {t("expressionOfInterest.monitor.subtitle")}
                        <br />
                        {t("expressionOfInterest.monitor.url")} {link}
                    </>
                </Copy>
                <AmendDeadlineForm
                    label={t("expressionOfInterest.monitor.deadlineLabel")}
                    deadline={project.expressionOfInterestDeadline}
                    deadlineHasPassed={deadlineHasPassed}
                    canAmendDate={canUpdateProject && !deadlineHasPassed}
                    updateDeadline={(deadline: Date) => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: "Project Responses - Amend Date",
                        });
                        updateEoiDeadline(deadline);
                    }}
                />
                <Table
                    columns={columns}
                    data={supplierData}
                    noDataDisplay={t("common.noResults")}
                    testid="suppliers-table"
                />
                {deadlineHasPassed && (
                    <div className={sharedClasses.buttons}>
                        <Button
                            label={t("expressionOfInterest.monitor.completeProject")}
                            onClick={() => setIsCompletePopupOpen(true)}
                            size="large"
                            disabled={!canCompleteProject}
                            testid="complete-project-button"
                        />
                    </div>
                )}
                <div className={sharedClasses.refresh}>
                    <TextButton
                        label={t("monitorScreen.refreshTable")}
                        icon={faSync}
                        onClick={() => {
                            ReactGA.event({
                                category: ga4Category.Button,
                                action: ga4Action.ButtonClick,
                                label: "Expression of Interest - Refresh Table",
                            });
                            refreshSuppliers();
                        }}
                        testid="refresh-table-button"
                    />
                </div>
            </div>
            <Popup
                title={t("popups.completeEoi.title")}
                message={
                    <>
                        {t("popups.completeEoi.message")}
                        <br />
                        <br />
                        {t("popups.completeEoi.secondaryMessage")}
                    </>
                }
                isOpen={isCompletePopupOpen}
                primaryActionText={t("popups.completeEoi.cta.primary")}
                onPrimaryAction={completeProject}
                onClose={() => setIsCompletePopupOpen(false)}
                testid="complete-eoi-popup"
            />
        </>
    );
};

export default MonitorDisplay;
