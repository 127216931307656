import { faCheck, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { Button, Copy, Drawer, Heading, ICommonProps, Pill, TextAreaField, useToaster } from "@maistro/components";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import useDrawerStyles from "features/company/buyer/approvals/styles/useDrawerStyles";

interface IPolicyDecisionDrawer extends ICommonProps {
    isOpen: boolean;
    isApproving?: boolean;
    onClose: () => void;
    onSubmit: (reason: string) => void;
}

const PolicyDecisionDrawer: React.FC<IPolicyDecisionDrawer> = ({ onSubmit, isOpen, isApproving, onClose, testid }) => {
    const classes = useDrawerStyles();

    const { t } = useTranslation();
    const toast = useToaster();

    const validationSchema = () =>
        Yup.object({
            reason: Yup.string().required(t("approvals.policyDecisionDrawer.error")),
        });

    const onCloseDrawer = (withWarning: boolean): void => {
        if (withWarning) {
            toast.warning(t("approvals.policyDecisionDrawer.warning"));
        }
        onClose();
    };

    return (
        <Drawer
            contentClassName={classes.container}
            isOpen={isOpen}
            onClose={() => onCloseDrawer(true)}
            testid="policy-decision-drawer"
        >
            <Heading variant="h2">{t("approvals.policyDecisionDrawer.title")}</Heading>
            <Pill
                value={
                    isApproving
                        ? t("approvals.policyDecisionDrawer.accept")
                        : t("approvals.policyDecisionDrawer.reject")
                }
                icon={isApproving ? faCheck : faXmark}
                testid={`${testid}-pill`}
                color={isApproving ? "success" : "error"}
            />
            <Copy testid={`${testid}-information`}>{t("approvals.policyDecisionDrawer.information")}</Copy>
            <Formik
                initialValues={{
                    reason: "",
                }}
                onSubmit={(values) => {
                    onSubmit(values.reason);
                    onCloseDrawer(false);
                }}
                validationSchema={validationSchema()}
            >
                {({ dirty, isValid }) => (
                    <Form className={classes.container}>
                        <TextAreaField
                            testid="reason-input-text"
                            label={t("approvals.policyDecisionDrawer.reason")}
                            name="reason"
                            required
                        />
                        <div className={classes.buttons}>
                            <Button
                                testid="submit"
                                type="submit"
                                label={t("common.submit")}
                                disabled={!dirty || !isValid}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </Drawer>
    );
};

export default PolicyDecisionDrawer;
