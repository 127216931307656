import { faArrowLeft, faArrowRight, faFileChartColumn } from "@fortawesome/pro-regular-svg-icons";
import { Alert, Button, Copy, Drawer, Icon, Popup, ToggleButton, useToaster } from "@maistro/components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import { getProjectUsers } from "api/projectSettingsApi";
import { getSectionTitle } from "features/project/helpers/sectionHelpers";
import AnswerAccordion from "features/project/tenderCriteria/scoring/AnswerAccordion";
import QuestionDisplay from "features/project/tenderCriteria/scoring/QuestionDisplay";
import ScoreBreakdownDisplay from "features/project/tenderCriteria/scoring/ScoreBreakdownDisplay";
import ErrorDisplay from "features/shared/ErrorDisplay";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import validationService from "services/validationService";
import { ITheme } from "styles/themes/types";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { AnswerDto } from "types/dtos/questions/AnswerResponseDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { ScoreResponseDto } from "types/dtos/questions/ScoreResponseDto";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";
import { UserRoleDto } from "types/dtos/rolesPermissions/UserRoleDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    navigation: {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "flex-start",
        marginTop: theme.spacing.small,
        gap: theme.spacing.small,
    },
    navigationButton: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing.xSmall,
    },
    sectionInformation: {
        ...theme.typography.headings.h4,
        lineHeight: "22px",
        background: theme.colors.medLightGray,
        borderRadius: theme.spacing.xSmall,
        padding: `${theme.spacing.xSmall}px ${theme.spacing.small}px`,
        width: "fit-content",
    },
    submit: {
        fontSize: 14,
        lineHeight: "22px",
        padding: `${theme.spacing.xSmall}px ${theme.spacing.medium}px`,
    },
    flexGrow: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.small,
    },
    regular: {
        fontWeight: 400,
    },
    marginBottom: {
        marginBottom: theme.spacing.small,
    },
    scoreBreakdownDrawer: {},

    [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        scoreBreakdownDrawer: {
            width: 500,
        },
    },

    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        navigation: {
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
        },
        flexGrow: {
            flexGrow: 1,
            flexDirection: "row",
            gap: theme.spacing.medium,
        },
        marginBottom: {
            marginBottom: 0,
        },
    },
}));

interface IScoreTenderQuestionDisplayProps {
    projectUuid: string;
    questionUuid: string;
    questions: QuestionResponseDto[];
    sections: SectionDto[];
    completedContributorScorers: number;
    totalContributorScorers: number;
    isProjectManager: boolean;
    setScore: (score: ScoreResponseDto) => void;
    submitScores: () => Promise<void>;
    userUuid: string;
    isSubmittingScores: boolean;
    canScoreAnswers: boolean;
    canModerateScores: boolean;
}

const ScoreTenderQuestionDisplay: React.FC<IScoreTenderQuestionDisplayProps> = (props) => {
    const classes = useStyles();

    const {
        projectUuid,
        questionUuid,
        questions,
        sections,
        completedContributorScorers,
        totalContributorScorers,
        isProjectManager,
        setScore,
        submitScores,
        userUuid,
        isSubmittingScores,
        canScoreAnswers,
        canModerateScores,
    } = props;

    const [scoringCompletePopupVisible, setScoringCompletePopupVisible] = useState(false);
    const [isScoreBreakdownDrawerOpen, setIsScoreBreakdownDrawerOpen] = useState(false);
    const [answerForBreakdown, setAnswerForBreakdown] = useState<AnswerDto>();
    const [userProjectRoles, setUserProjectRoles] = useState<UserRoleDto[]>([]);
    const [popupMessage, setPopupMessage] = useState("popups.tenderCriteria.scoringComplete.message");
    const [popupTitleMessage, setPopupTitleMessage] = useState("popups.tenderCriteria.scoringComplete.title");

    const { t } = useTranslation();
    const navigate = useNavigate();
    const toast = useToaster();

    useEffect(() => {
        if (isProjectManager) {
            if (completedContributorScorers < totalContributorScorers) {
                setPopupTitleMessage("popups.tenderCriteria.scoringComplete.pmTitleWarning");
                setPopupMessage("popups.tenderCriteria.scoringComplete.pmWarningContributorsCompletion");
            } else {
                setPopupMessage("popups.tenderCriteria.scoringComplete.pmMessage");
            }
        }
    }, [completedContributorScorers, totalContributorScorers, isProjectManager]);

    useEffect(() => {
        const getProjectUsersAsync = async () => {
            const projectUsersResponse = await getProjectUsers(projectUuid);
            if (projectUsersResponse.data instanceof TransactionErrorDto || projectUsersResponse.status !== 200) {
                return;
            }
            setUserProjectRoles(
                projectUsersResponse.data.projectUsers.map((pu) => ({ ...pu.role, userUuid: pu.userUuid })),
            );
        };

        if (canModerateScores) {
            getProjectUsersAsync();
        }
    }, [canModerateScores, projectUuid]);

    if (
        !(projectUuid && validationService.isValidUuid(projectUuid)) ||
        !(questionUuid && validationService.isValidUuid(questionUuid))
    ) {
        return <Copy>{t("tenderCriteria.score.error.invalidUrl")}</Copy>;
    }

    const question = questions.find((q) => q.questionUuid.toLowerCase() === questionUuid.toLowerCase());
    if (!question) {
        return null;
    }

    const section = sections.find((s) => s.id.toLowerCase() === question.entityUuid.toLowerCase());
    if (!section) {
        return null;
    }

    if (!canModerateScores && !canScoreAnswers) {
        return <ErrorDisplay />;
    }

    const questionIndex = questions.findIndex((q) => q.questionUuid.toLowerCase() === questionUuid.toLowerCase());
    const questionInSection = (q: QuestionResponseDto) => q.entityUuid.toLowerCase() === section.id.toLowerCase();
    const sectionQuestionIndex = questionIndex - questions.findIndex(questionInSection);

    const answerNotScored = (answer: AnswerDto) => !answer.scores?.some((score) => score.scoredByUserUuid === userUuid);
    const unscoredProjectAnswers = questions.map((q) => q.answers.filter(answerNotScored)).flat();
    const unscoredSectionAnswers = questions
        .filter(questionInSection)
        .map((q) => q.answers.filter(answerNotScored))
        .flat();

    const handleSetScore = (score: ScoreResponseDto) => {
        if (unscoredProjectAnswers.length === 1 && score.answerUuid === unscoredProjectAnswers[0].answerUuid) {
            toast.success(t("tenderCriteria.score.scoringComplete"), { duration: 6000 });
        } else if (unscoredSectionAnswers.length === 1 && score.answerUuid === unscoredSectionAnswers[0].answerUuid) {
            toast.informative(
                t("tenderCriteria.score.sectionComplete", {
                    sectionName: getSectionTitle(section),
                }),
                { duration: 6000 },
            );
        }

        setScore(score);
    };

    const questionNavigate = (direction: "next" | "previous") => {
        const indexChange = direction === "next" ? 1 : -1;
        const destinationQuestionUuid = questions[questionIndex + indexChange].questionUuid;
        navigate(
            buildPath(routes.projects.scoreTenderQuestion, { projectUuid, questionUuid: destinationQuestionUuid }),
        );
    };

    const amendScores = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setScoringCompletePopupVisible(false);
    };

    const showScoreBreakdown = (answer: AnswerDto) => {
        setAnswerForBreakdown(answer);
        setIsScoreBreakdownDrawerOpen(true);
    };

    return (
        <React.Fragment>
            <div className={classes.navigation}>
                <div className={classes.flexGrow}>
                    <div className={classes.sectionInformation}>
                        <span>{getSectionTitle(section)}</span>{" "}
                        <span className={classes.regular}>{section.weighting}% </span>
                        <span>| {t("common.question")}</span>{" "}
                        <span className={classes.regular}>{sectionQuestionIndex + 1}</span>{" "}
                    </div>
                    {canModerateScores && (
                        <Button
                            label={t("reports.scoreSummaryReport")}
                            size="small"
                            variant="outline"
                            icon={faFileChartColumn}
                            testid="score-summary-report-button"
                            onClick={() =>
                                window.open(`/projects/${projectUuid}/tender-summary/score-summary-report`, "_blank")
                            }
                        />
                    )}
                </div>
                {canScoreAnswers && (
                    <>
                        {unscoredProjectAnswers.length === 0 && (
                            <Alert type="success" label={t("tenderSummaryScreen.scoringComplete")} />
                        )}
                        <Button
                            className={classes.submit}
                            size="small"
                            label={t("popups.tenderCriteria.scoringComplete.cta.primary")}
                            onClick={() => setScoringCompletePopupVisible(true)}
                            disabled={unscoredProjectAnswers.length > 0}
                            testid="complete-scoring-button"
                        />
                    </>
                )}
                <ToggleButton
                    options={[
                        {
                            value: "previous",
                            label: (
                                <span className={classes.navigationButton}>
                                    <Icon icon={faArrowLeft} size="xSmall" />
                                    {t("common.previous")}
                                </span>
                            ),
                            active: questionIndex !== 0,
                            disabled: questionIndex === 0,
                        },
                        {
                            value: "next",
                            label: (
                                <span className={classes.navigationButton}>
                                    {t("common.next")}
                                    <Icon icon={faArrowRight} size="xSmall" />
                                </span>
                            ),
                            active: questionIndex < questions.length - 1,
                            disabled: questionIndex >= questions.length - 1,
                        },
                    ]}
                    onClick={questionNavigate}
                    className={classes.marginBottom}
                />
            </div>
            <QuestionDisplay question={question} testid={`question-${questionIndex + 1}`} />
            {question.answers.map((answer) => (
                <AnswerAccordion
                    key={answer.answerUuid}
                    projectUuid={projectUuid}
                    questionUuid={questionUuid}
                    answer={answer}
                    setScore={handleSetScore}
                    expandedByDefault
                    canScore={canScoreAnswers}
                    canModerateScore={canModerateScores}
                    showScoreBreakdown={showScoreBreakdown}
                />
            ))}
            <Popup
                title={t(popupTitleMessage)}
                message={t(popupMessage)}
                isOpen={scoringCompletePopupVisible}
                primaryActionText={t("popups.tenderCriteria.scoringComplete.cta.primary")}
                onPrimaryAction={submitScores}
                secondaryActionText={t("popups.tenderCriteria.scoringComplete.cta.secondary")}
                onSecondaryAction={amendScores}
                onClose={() => setScoringCompletePopupVisible(false)}
                testid="scoring-complete-popup"
                disabled={isSubmittingScores}
            />
            <Drawer
                className={classes.scoreBreakdownDrawer}
                isOpen={isScoreBreakdownDrawerOpen}
                onClose={() => setIsScoreBreakdownDrawerOpen(false)}
                withCloseFooter
                testid="score-breakdown-drawer"
            >
                {answerForBreakdown && (
                    <ScoreBreakdownDisplay
                        scores={answerForBreakdown.scores ?? []}
                        supplierName={answerForBreakdown.respondingCompanyName}
                        userProjectRoles={userProjectRoles}
                    />
                )}
            </Drawer>
        </React.Fragment>
    );
};

export default ScoreTenderQuestionDisplay;
