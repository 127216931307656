import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useToaster } from "@maistro/components";
import { linkProjectFiles } from "api/fileApi";
import { getProjectSections } from "api/questions/tenderCriteriaQuestionsApi";
import {
    createOrUpdateAnswer,
    listSectionQuestions,
} from "api/supplierSubmission/submissionTenderCriteriaQuestionsApi";
import { getSectionTitle } from "features/project/helpers/sectionHelpers";
import useProjectResponse from "features/project/hooks/useProjectResponse";
import {
    sortByConditionalThenAlphabetically,
    sortByWeightingThenAlphabetically,
} from "features/project/shared/tender-questions/useQuestionSorters";
import routes from "routes/routePaths/RoutePaths";
import { CreateOrUpdateTenderCriteriaAnswerForSubmissionRequestDto } from "types/dtos/questions/CreateOrUpdateTenderCriteriaAnswerForSubmissionRequestDto";
import { ISectionQuestions } from "types/dtos/questions/ISectionQuestions";
import { ListQuestionsResponseDto } from "types/dtos/questions/ListQuestionsResponseDto";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import EntityType from "types/enums/questions/EntityType";
import SupplierSubmissionStatus from "types/enums/supplierSubmissions/SupplierSubmissionStatus";

export interface IUseSupplierTenderQuestionsConfig {
    projectUuid?: string;
    responseUuid?: string;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useSupplierTenderQuestions = (props: IUseSupplierTenderQuestionsConfig) => {
    const { projectUuid, setIsLoading, responseUuid } = props;

    const [sections, setSections] = useState<SectionDto[]>([]);
    const [sectionQuestions, setSectionQuestions] = useState<ISectionQuestions[]>([]);

    const { t } = useTranslation();
    const toast = useToaster();

    const { updateSupplierSubmissionStatus, isSubmitting } = useProjectResponse();

    const sortSameAsSections = useCallback(
        (a: ISectionQuestions, b: ISectionQuestions) => {
            return sections.findIndex((s) => s.id === a.id) - sections.findIndex((s) => s.id === b.id);
        },
        [sections],
    );

    useEffect(() => {
        if (projectUuid) {
            getProjectSections(projectUuid).then((response) => {
                if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                    toast.error(t("tenderQuestions.getProjectSectionsError"));
                    setIsLoading(false);
                    return;
                }
                const sectionsResponse = response?.data.sections ?? [];
                const sectionsOrdered = sectionsResponse.some(
                    (section) => section.orderIndex === null || section.orderIndex === undefined,
                )
                    ? sectionsResponse.toSorted(sortByWeightingThenAlphabetically)
                    : sectionsResponse;
                setSections(sectionsOrdered);
                if (sectionsOrdered.length === 0) {
                    setIsLoading(false);
                }
            });
        }
    }, [projectUuid, setIsLoading, toast, t]);

    useEffect(() => {
        const fetchAndProcessSections = async () => {
            if (responseUuid && sections?.length > 0) {
                const allQuestions: ListQuestionsResponseDto["items"] = [];
                const loadedSections: ISectionQuestions[] = [];

                try {
                    const sectionsToLoad = sections.map(async (section) => {
                        try {
                            const response = await listSectionQuestions(responseUuid, section.id);
                            if (response.status === 200) {
                                const sectionQuestionsResponse = response.data as ListQuestionsResponseDto;
                                const sectionItems = sectionQuestionsResponse.items;

                                sectionItems.forEach((question) => {
                                    allQuestions.push(question);
                                });

                                loadedSections.push({ id: section.id, items: sectionItems } as ISectionQuestions);
                            } else {
                                toast.error(
                                    t("tenderQuestions.getProjectSectionsError", {
                                        sectionName: getSectionTitle(section),
                                    }),
                                );
                            }
                        } catch (error) {
                            toast.error(
                                t("tenderQuestions.getProjectSectionsError", {
                                    sectionName: getSectionTitle(section),
                                }),
                            );
                        }
                    });

                    await Promise.all(sectionsToLoad);

                    const hasOrderIndexIssues = allQuestions.some(
                        (question) => question.orderIndex === null || question.orderIndex === undefined,
                    );

                    if (hasOrderIndexIssues) {
                        allQuestions.sort(sortByConditionalThenAlphabetically);

                        loadedSections.forEach((section) => {
                            section.items = allQuestions.filter(
                                (question) => question.entityUuid.toLowerCase() === section.id.toLowerCase(),
                            );
                        });
                    }

                    loadedSections.sort(sortSameAsSections);
                    setSectionQuestions(loadedSections);
                } catch (error) {
                    toast.error(t("tenderQuestions.generalError"));
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchAndProcessSections();
    }, [sections, responseUuid, setIsLoading, toast, t, sortSameAsSections]);

    const reloadSection = useCallback(
        async (sectionId: string) => {
            if (responseUuid) {
                const updatedSections = sectionQuestions.filter((sq) => sectionId !== sq.id);
                const allQuestions: ListQuestionsResponseDto["items"] = [];

                try {
                    const response = await listSectionQuestions(responseUuid, sectionId);
                    if (response.status === 200) {
                        const questionResponse = response.data as ListQuestionsResponseDto;
                        const sectionItems = questionResponse.items;

                        sectionItems.forEach((question) => {
                            allQuestions.push(question);
                        });

                        const hasOrderIndexIssues = allQuestions.some(
                            (question) => question.orderIndex === null || question.orderIndex === undefined,
                        );

                        if (hasOrderIndexIssues) {
                            allQuestions.sort(sortByConditionalThenAlphabetically);
                        }

                        updatedSections.push({ id: sectionId, items: allQuestions } as ISectionQuestions);
                        updatedSections.sort(sortSameAsSections);
                        setSectionQuestions(updatedSections);
                    } else {
                        const section = sections.find((s) => s.id === sectionId);
                        toast.error(
                            t("tenderQuestions.getProjectSectionsError", {
                                sectionName: section ? getSectionTitle(section) : sectionId,
                            }),
                        );
                    }
                } catch (error) {
                    toast.error(t("tenderQuestions.generalError"));
                }
            }
        },
        [responseUuid, sortSameAsSections, sectionQuestions, toast, t, sections],
    );

    const calculateNextQuestionUuid = (currentQuestionUuid: string) => {
        const currentSectionQuestion = sectionQuestions.find((sq) =>
            sq.items.some((q) => q.questionUuid === currentQuestionUuid),
        );
        if (!currentSectionQuestion) {
            return null;
        }

        const currentQuestionindex = currentSectionQuestion.items.findIndex(
            (q) => q.questionUuid === currentQuestionUuid,
        );
        const atLastQuestionInSection = currentQuestionindex === currentSectionQuestion.items.length - 1;
        if (atLastQuestionInSection) {
            const currentSectionIndex = sectionQuestions.indexOf(currentSectionQuestion);
            const atLastSection = currentSectionIndex === sectionQuestions.length - 1;
            if (atLastSection) {
                return null;
            }

            const nextSectionQuestion = sectionQuestions[currentSectionIndex + 1];
            if (!nextSectionQuestion) {
                return null;
            }

            return nextSectionQuestion.items[0].questionUuid;
        }

        return currentSectionQuestion.items[currentQuestionindex + 1]?.questionUuid;
    };

    const linkFilesToAnswer = useCallback(
        async (fileUuids: Array<string>, answerUuid: string) => {
            if (!projectUuid) {
                return;
            }
            const fileLinkResponse = await linkProjectFiles(projectUuid, fileUuids, EntityType.Answer, answerUuid);

            if (fileLinkResponse.status !== 204) {
                toast.error(t("supplierTenderQuestions.linkFilesToAnswerError"));
            }
        },
        [projectUuid, t, toast],
    );

    const answerQuestion = useCallback(
        async (
            questionUuid: string,
            answer: CreateOrUpdateTenderCriteriaAnswerForSubmissionRequestDto,
            fileUuids: Array<string>,
        ) => {
            if (responseUuid) {
                return createOrUpdateAnswer(responseUuid, questionUuid, answer).then(async (response) => {
                    if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                        toast.error(t("supplierTenderQuestions.answerQuestionError"));
                        return null;
                    }

                    await linkFilesToAnswer(fileUuids, response.data.answerUuid);

                    if (answer.answerUuid !== "") {
                        toast.success(t("supplierTenderQuestions.editQuestionSuccess"));
                    } else {
                        toast.success(t("supplierTenderQuestions.answerQuestionSuccess"));
                    }
                    const sectionId = sectionQuestions.find((sq) =>
                        sq.items.some((q) => q.questionUuid === questionUuid),
                    )?.id;
                    if (!sectionId) {
                        return response.data.answerUuid;
                    }
                    reloadSection(sectionId);
                    return response.data.answerUuid;
                });
            }
            return null;
        },
        [responseUuid, linkFilesToAnswer, sectionQuestions, reloadSection, toast, t],
    );

    const withdrawTender = useCallback(() => {
        updateSupplierSubmissionStatus(
            SupplierSubmissionStatus.Withdrawn,
            routes.common.dashboard.path,
            t("projectResponse.api.failedToCancelQuote"),
            t("projectResponse.api.withdrawSuccessful"),
        );
    }, [t, updateSupplierSubmissionStatus]);

    const cancelProjectResponse = useCallback(() => {
        updateSupplierSubmissionStatus(
            SupplierSubmissionStatus.NotInterested,
            routes.common.dashboard.path,
            t("projectResponse.api.failedToCancelQuote"),
        );
    }, [t, updateSupplierSubmissionStatus]);

    return {
        answerQuestion,
        withdrawTender,
        cancelProjectResponse,
        sections,
        sectionQuestions,
        isSubmitting,
        calculateNextQuestionUuid,
    };
};

export default useSupplierTenderQuestions;
