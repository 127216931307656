import { IProjectInformation } from "features/project/types";
import { ProjectBudgetDto } from "types/dtos/projects/ProjectBudgetDto";
import { ITaxonomyItemRequestDto } from "types/dtos/taxonomy/ITaxonomyItemRequestDto";
import ProjectType from "types/enums/projects/ProjectType";

export interface CreateProjectRequestDto {
    projectName: string;

    companyUuid: string;

    managerUserUuid: string;

    description: string;

    timescale: string;

    budget: ProjectBudgetDto;

    sponsorUserUuid?: string;

    projectType?: ProjectType;

    contributorsUuid: Array<string>;

    categories: Array<ITaxonomyItemRequestDto>;

    expressionOfInterestDeadline?: Date;
}

export const constructCreateProjectRequestDto = (values: IProjectInformation): CreateProjectRequestDto => {
    return {
        projectName: values.projectName,
        companyUuid: values.clientCompanyUuid ?? "",
        managerUserUuid: values.managerUuid,
        description: values.description,
        timescale: values.timescale,
        budget: {
            currencyOnly: {
                ccy: values.budgetCurrency,
            },
            buyerBudget: values.buyerBudget,
            budgetType: values.budgetType,
            showBudgetToSupplier: values.showBudgetToSupplier,
        },
        sponsorUserUuid: values.sponsorUserUuid,
        projectType: values.type,
        contributorsUuid: values.contributors.map((contributor) => contributor.userUuid),
        categories: values.categories.map((category) => ({
            itemUuid: category.value,
            selectionStatusType: category.status,
        })),
        expressionOfInterestDeadline: values.expressionOfInterestDeadline,
    };
};
