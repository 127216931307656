import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ITextButtonProps } from "@maistro/components/dist/esm/types/components/TextButton/TextButton";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INavItem } from "components/Layout/Nav";
import { Route } from "routes/helpers/RoutesHelper";

export interface IStepperNavigationItem {
    label: string;
    icon: IconProp;
    route: Route;
    isActive?: boolean;
}

export interface IStepperChecklistItem {
    label: string;
    hideLabel?: boolean;
    children: Array<IStepperChecklistChild>;
}

interface IStepperChecklistChild {
    label: string;
    id: string;
    isActive?: boolean;
    isComplete?: boolean;
}

interface IStepperNavState {
    desktopWidth: number;
    stepperTitle?: string;
    stepperSubTitle?: string;
    stepperNavigationItems?: IStepperNavigationItem[];
    stepperChecklistItems?: IStepperChecklistItem[];
    stepperActions?: ITextButtonProps[];
}

export interface INavigationState {
    navigationItems?: INavItem[];
    isCurrentPagePublic: boolean;
    stepperNavigation: IStepperNavState;
}

const initialState: INavigationState = {
    navigationItems: [] as INavItem[],
    isCurrentPagePublic: false,
    stepperNavigation: {
        desktopWidth: 200,
    },
};

export const navigationSlice = createSlice({
    name: "navigation",
    initialState,
    reducers: {
        setNavigationItems: (state, action: PayloadAction<INavItem[]>) => {
            state.navigationItems = action.payload;
        },
        setIsCurrentPagePublic: (state, action: PayloadAction<boolean>) => {
            state.isCurrentPagePublic = action.payload;
        },
        setStepperNavigationDesktopWidth: (state, action: PayloadAction<number>) => {
            state.stepperNavigation.desktopWidth = action.payload;
        },
        setStepperNavigationItems: (state, action: PayloadAction<IStepperNavigationItem[] | undefined>) => {
            state.stepperNavigation.stepperNavigationItems = action.payload;
        },
        setStepperChecklistItems: (state, action: PayloadAction<IStepperChecklistItem[] | undefined>) => {
            state.stepperNavigation.stepperChecklistItems = action.payload;
        },
        setStepperTitle: (state, action: PayloadAction<string | undefined>) => {
            state.stepperNavigation.stepperTitle = action.payload;
        },
        setStepperSubTitle: (state, action: PayloadAction<string | undefined>) => {
            state.stepperNavigation.stepperSubTitle = action.payload;
        },
        setStepperActions: (state, action: PayloadAction<ITextButtonProps[] | undefined>) => {
            state.stepperNavigation.stepperActions = action.payload;
        },
    },
});

const { actions, reducer } = navigationSlice;

export const {
    setNavigationItems,
    setIsCurrentPagePublic,
    setStepperNavigationDesktopWidth,
    setStepperTitle,
    setStepperSubTitle,
    setStepperNavigationItems,
    setStepperChecklistItems,
    setStepperActions,
} = actions;

export default reducer;
