import React from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import * as Yup from "yup";

import { ICommonProps } from "components/shared";
import SupplierTieringStatus from "types/enums/companies/SupplierTieringStatus";
import SupplierStatusForm from "features/company/profile/suppliers/SupplierStatusForm";
import { BuyerSupplierRelationshipDto } from "types/dtos/company/buyer-supplier-relationships/BuyerSupplierRelationshipDto";

const validationSchema = (t: TFunction<"translation", undefined>) =>
    Yup.object({
        supplierTieringStatus: Yup.string().required(t("companySuppliers.changeTieringStatus.required")),
    });

interface ISupplierTieringStatusFormProps extends ICommonProps {
    values: BuyerSupplierRelationshipDto;
    submit: (values: BuyerSupplierRelationshipDto) => void;
}

const SupplierTieringStatusForm: React.FC<ISupplierTieringStatusFormProps> = (props) => {
    const { t } = useTranslation();

    const tieringStatusOptions = [
        {
            value: SupplierTieringStatus.Strategic,
            label: t("companySuppliers.tieringStatus.strategic.label"),
        },
        {
            value: SupplierTieringStatus.Preferred,
            label: t("companySuppliers.tieringStatus.preferred.label"),
        },
        {
            value: SupplierTieringStatus.Occasional,
            label: t("companySuppliers.tieringStatus.occasional.label"),
        },
        {
            value: SupplierTieringStatus.Undefined,
            label: t("companySuppliers.tieringStatus.undefined.label"),
        },
    ];

    return (
        <SupplierStatusForm
            copy={t("companySuppliers.changeTieringStatus.text")}
            heading={t("companySuppliers.table.tieringStatus")}
            label={t("companySuppliers.changeTieringStatus.label")}
            name="supplierTieringStatus"
            options={tieringStatusOptions}
            submit={(values) => props.submit(values)}
            tooltips={[
                {
                    text: t("companySuppliers.tieringStatus.strategic.tooltip"),
                    fieldName: t("companySuppliers.tieringStatus.strategic.label"),
                },
                {
                    text: t("companySuppliers.tieringStatus.preferred.tooltip"),
                    fieldName: t("companySuppliers.tieringStatus.preferred.label"),
                },
                {
                    text: t("companySuppliers.tieringStatus.occasional.tooltip"),
                    fieldName: t("companySuppliers.tieringStatus.occasional.label"),
                },
                {
                    text: t("companySuppliers.tieringStatus.undefined.tooltip"),
                    fieldName: t("companySuppliers.tieringStatus.undefined.label"),
                },
            ]}
            values={props.values}
            validationSchema={validationSchema(t)}
        />
    );
};

export default SupplierTieringStatusForm;
