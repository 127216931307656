import { AccreditationDto } from "types/dtos/company/vettingInformation/AccreditationDto";
import { CompanyFinanceDto } from "types/dtos/company/vettingInformation/CompanyFinanceDto";
import { DataProtectionDto } from "types/dtos/company/vettingInformation/DataProtectionDto";
import { DisasterRecoveryDto } from "types/dtos/company/vettingInformation/DisasterRecoveryDto";
import { EnvironmentDto } from "types/dtos/company/vettingInformation/EnvironmentDto";
import { EqualityDto } from "types/dtos/company/vettingInformation/EqualityDto";
import { HealthAndSafetyDto } from "types/dtos/company/vettingInformation/HealthAndSafetyDto";
import { InsuranceDto } from "types/dtos/company/vettingInformation/InsuranceDto";
import { MembershipDto } from "types/dtos/company/vettingInformation/MembershipDto";
import { ParentCompanyDto } from "types/dtos/company/vettingInformation/ParentCompanyDto";
import { PublicSectorDto } from "types/dtos/company/vettingInformation/PublicSectorDto";
import { QualityDto } from "types/dtos/company/vettingInformation/QualityDto";
import { StandardsDto } from "types/dtos/company/vettingInformation/StandardsDto";

export enum VettingInformationKeys {
    insurances = "insurances",
    accreditations = "accreditations",
    companyFinance = "companyFinance",
    dataProtection = "dataProtection",
    disasterRecovery = "disasterRecovery",
    environment = "environment",
    equality = "equality",
    geographicLocationsTypeIds = "geographicLocationsTypeIds",
    healthAndSafety = "healthAndSafety",
    memberships = "memberships",
    quality = "quality",
    parent = "parent",
    publicSector = "publicSector",
    standards = "standards",
}

export interface VettingInformationDto {
    companyUuid: string;

    insurances?: InsuranceDto[];

    accreditations?: AccreditationDto[];

    companyFinance?: CompanyFinanceDto;

    dataProtection?: DataProtectionDto;

    disasterRecovery?: DisasterRecoveryDto;

    environment?: EnvironmentDto;

    equality?: EqualityDto;

    geographicLocationsTypeIds?: Array<number>;

    healthAndSafety?: HealthAndSafetyDto;

    memberships?: MembershipDto[];

    quality?: QualityDto;

    parent?: ParentCompanyDto;

    publicSector?: PublicSectorDto;

    standards?: StandardsDto;

    isVettingComplete?: boolean;
}
