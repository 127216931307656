import { AxiosResponse } from "axios";

import env from "config/EnvironmentVariables";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { createAxiosInstance } from "api/commonApi";
import { NotificationListDto } from "types/dtos/notifications/NotificationListDto";
import { ListNotificationsRequestDto } from "types/dtos/notifications/ListNotificationsRequestDto";
import { UpdateNotificationsRequestDto } from "types/dtos/notifications/UpdateNotificationsRequestDto";
import { UpdateNotificationRequestDto } from "types/dtos/notifications/UpdateNotificationRequestDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/notifications";

export const listNotifications = (
    request: ListNotificationsRequestDto,
): Promise<AxiosResponse<NotificationListDto> | AxiosResponse<TransactionErrorDto>> => {
    return api.get(baseApiUrl, {
        params: { ...request.queryParameters },
    });
};

export const updateNotifications = (
    request: UpdateNotificationsRequestDto,
): Promise<AxiosResponse<TransactionErrorDto>> => {
    return api.put(baseApiUrl, request);
};

export const updateNotification = (
    request: UpdateNotificationRequestDto,
): Promise<AxiosResponse<TransactionErrorDto>> => {
    return api.put(`${baseApiUrl}/${request.notificationId}`, request);
};
