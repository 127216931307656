import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Drawer, Popup } from "@maistro/components";
import { useTranslation } from "react-i18next";

import { Loader } from "components";
import BuyerSupplierContractsForm from "features/company/profile/suppliers/BuyerSupplierContracts/BuyerSupplierContractsForm";
import BuyerSupplierContractsTable from "features/company/profile/suppliers/BuyerSupplierContracts/BuyerSupplierContractsTable";
import useContractsControl from "features/company/profile/suppliers/BuyerSupplierContracts/hooks/useContractsControls";
import useDeleteContract from "features/company/profile/suppliers/BuyerSupplierContracts/hooks/useDeleteContract";
import useSaveContract from "features/company/profile/suppliers/BuyerSupplierContracts/hooks/useSaveContract";
import useAppDispatch from "hooks/useAppDispatch";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation, useParams } from "react-router-dom";
import { useGetCompanySummaryQuery } from "store/api/companyApi";
import { resetLayout, setBack, setCtas, setPageTitle } from "store/layoutSlice";
import BuyerSupplierContractDto from "types/dtos/company/buyer-supplier-relationships/BuyerSupplierContractDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

type BuyerSupplierContractsProps = {
    supplierUuid: string;
    companyUuid: string;
};

const BuyerSupplierContracts: React.FC = () => {
    const { t } = useTranslation();
    const params = useParams();
    const { state } = useLocation();
    const dispatch = useAppDispatch();

    const { supplierUuid, companyUuid: buyerUuid } = params as unknown as BuyerSupplierContractsProps;

    const { data: supplier, isLoading } = useGetCompanySummaryQuery({
        buyerCompanyUuid: buyerUuid,
        supplierCompanyUuid: supplierUuid,
    });

    const { isFormVisible, handleAddContractButtonClick, selectedContract, setSelectedContract, setIsFormVisible } =
        useContractsControl();

    useEffect(() => {
        dispatch(
            setCtas([
                {
                    testid: "add-contract-button",
                    label: t("companySuppliers.contracts.addContract"),
                    icon: faPlus,
                    onClick: handleAddContractButtonClick,
                },
            ]),
        );
        dispatch(
            setPageTitle(
                t("companySuppliers.contracts.title", {
                    supplierName: supplier?.registeredName,
                }),
            ),
        );
        if (state) {
            dispatch(
                setBack({
                    route: state.route,
                }),
            );
        }

        return () => {
            dispatch(resetLayout());
        };
    }, [dispatch, handleAddContractButtonClick, state, supplier?.registeredName, t]);

    const { isDeleteContractPopupOpen, setIsDeleteContractPopupOpen, handleDeleteSelectedContract } = useDeleteContract(
        selectedContract?.uuid,
        buyerUuid,
        supplierUuid,
    );

    const { handleSaveContract, isSaving } = useSaveContract(
        buyerUuid,
        supplierUuid,
        setIsFormVisible,
        setSelectedContract,
    );

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <BuyerSupplierContractsTable
                setSelectedContract={setSelectedContract}
                setIsFormVisible={setIsFormVisible}
                setIsDeleteContractPopupOpen={setIsDeleteContractPopupOpen}
                buyerUuid={buyerUuid}
                supplierUuid={supplierUuid}
            />
            <Drawer isOpen={isFormVisible} onClose={() => setIsFormVisible(false)} testid="contract-form">
                <BuyerSupplierContractsForm
                    initialContract={selectedContract}
                    onCancel={() => {
                        setIsFormVisible(false);
                        setSelectedContract(undefined);
                    }}
                    onSubmit={(contract: BuyerSupplierContractDto) => handleSaveContract(contract)}
                    isSaving={isSaving}
                    buyerUuid={buyerUuid}
                />
            </Drawer>
            <Popup
                title={t("companySuppliers.contracts.deletePopup.title")}
                message={t("companySuppliers.contracts.deletePopup.message")}
                primaryActionText={t("companySuppliers.contracts.deletePopup.cta.primary")}
                secondaryActionText={t("companySuppliers.contracts.deletePopup.cta.secondary")}
                isOpen={isDeleteContractPopupOpen}
                onPrimaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: `Contracts - Delete Contract - Submit`,
                    });
                    handleDeleteSelectedContract();
                }}
                onSecondaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: `Contracts - Delete Contract - Cancel`,
                    });
                    setIsDeleteContractPopupOpen(false);
                }}
                onClose={() => setIsDeleteContractPopupOpen(false)}
                testid="delete-contract-popup"
            />
        </>
    );
};

export default BuyerSupplierContracts;
