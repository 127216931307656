import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import ISecondaryNavItem from "components/Layout/types/SecondaryNavItem";
import useProject from "features/project/hooks/useProject";
import useProjectResponse from "features/project/hooks/useProjectResponse";
import { useTenderCriteria } from "features/project/hooks/useTenderCriteria";
import dateTimeService from "services/dateTimeService";
import ProjectStatus from "types/enums/projects/ProjectStatus";
import ProjectType from "types/enums/projects/ProjectType";
import SupplierSubmissionStatus from "types/enums/supplierSubmissions/SupplierSubmissionStatus";

interface ITabItem extends ISecondaryNavItem {
    additionalActiveUrls?: string[];
    position: number;
}

const useNavTabs = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { projectUuid, responseUuid, questionUuid } = useParams();

    const { projectInformation, deadlineHasPassed } = useProject();
    const { isTenderResponseAvailable, projectResponseInformation } = useProjectResponse();
    const { hasAnswers } = useTenderCriteria({ projectUuid: projectInformation.uuid });

    const isTenderScoringAvailable = useMemo(() => {
        return hasAnswers && dateTimeService.dateIsInPast(projectInformation.tenderResponseDeadline);
    }, [hasAnswers, projectInformation.tenderResponseDeadline]);

    const notInExcludedStatuses = (currentStatus: SupplierSubmissionStatus): boolean => {
        const excludedQuoteResponseStatuses = [
            SupplierSubmissionStatus.Invited,
            SupplierSubmissionStatus.NotInterested,
            SupplierSubmissionStatus.Withdrawn,
        ];

        return excludedQuoteResponseStatuses.find((status) => status === currentStatus) === undefined;
    };

    const byPosition = (a: ITabItem, b: ITabItem) => a.position - b.position;

    const supplierTabItems = useMemo(() => {
        const supplierTabs: ITabItem[] = [
            {
                position: 1,
                label: t("secondaryNavItems.projectSummary"),
                linkTo: `/projects/${projectUuid}/response/${responseUuid}/brief`,
            },
            {
                position: 2,
                label: t("secondaryNavItems.clarificationQuestions"),
                linkTo: `/projects/${projectUuid}/response/${responseUuid}/questions`,
                additionalActiveUrls: [
                    `/projects/${projectUuid}/response/${responseUuid}/questions/ask`,
                    `/projects/${projectUuid}/response/${responseUuid}/questions/${questionUuid}`,
                ],
            },
        ];

        if (
            projectResponseInformation.projectType === ProjectType.Quote &&
            notInExcludedStatuses(
                SupplierSubmissionStatus[projectResponseInformation.status as keyof typeof SupplierSubmissionStatus],
            )
        ) {
            supplierTabs.push({
                position: 3,
                label: t("secondaryNavItems.quoteResponse"),
                linkTo: `/projects/${projectUuid}/response/${responseUuid}`,
            });
        }

        if (isTenderResponseAvailable) {
            supplierTabs.push({
                position: 3,
                label: t("secondaryNavItems.tenderResponse"),
                linkTo: `/projects/${projectUuid}/response/${responseUuid}/tender-questions`,
            });
        }

        supplierTabs.forEach((tab) => {
            tab.isActive =
                tab.linkTo === pathname || (tab.additionalActiveUrls && tab.additionalActiveUrls.includes(pathname));
        });

        return supplierTabs.sort(byPosition);
    }, [
        t,
        projectUuid,
        responseUuid,
        questionUuid,
        projectResponseInformation.projectType,
        projectResponseInformation.status,
        isTenderResponseAvailable,
        pathname,
    ]);

    const buyerTabItems = useMemo(() => {
        const buyerTabs: ITabItem[] = [
            {
                position: 1,
                label: t("secondaryNavItems.dashboard"),
                linkTo: `/dashboard`,
            },
            {
                position: 3,
                label: t("secondaryNavItems.clarificationQuestions"),
                linkTo: `/projects/${projectUuid}/questions`,
                additionalActiveUrls: [`/projects/${projectUuid}/question/${questionUuid}`],
            },
            {
                position: 4,
                label: t("secondaryNavItems.monitor"),
                linkTo: `/projects/${projectUuid}/monitor`,
                isDisabled: projectInformation.status === ProjectStatus.Results,
            },
        ];

        if (!deadlineHasPassed && projectInformation.type === ProjectType.Tender) {
            buyerTabs.push({
                position: 2,
                label: t("secondaryNavItems.tenderQuestions"),
                linkTo: `/projects/${projectUuid}/tender-criteria/questions-view`,
            });
        }

        if (projectInformation.status === ProjectStatus.Results) {
            buyerTabs.push({
                position: 5,
                label: t("secondaryNavItems.award"),
                linkTo: `/projects/${projectUuid}/award-supplier`,
            });
        }

        if (isTenderScoringAvailable) {
            buyerTabs.push({
                position: 4,
                label: t("secondaryNavItems.tenderSummary"),
                linkTo: `/projects/${projectUuid}/tender-summary`,
                additionalActiveUrls: [`/projects/${projectUuid}/tender-summary/questions/${questionUuid}`],
            });
        }

        buyerTabs.forEach((tab) => {
            tab.isActive =
                tab.linkTo === pathname || (tab.additionalActiveUrls && tab.additionalActiveUrls.includes(pathname));
        });

        return buyerTabs.sort(byPosition);
    }, [
        t,
        projectUuid,
        questionUuid,
        projectInformation.status,
        projectInformation.type,
        deadlineHasPassed,
        isTenderScoringAvailable,
        pathname,
    ]);

    return { supplierTabItems, buyerTabItems };
};

export default useNavTabs;
