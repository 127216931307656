import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { IPaginationState, Popup, useToaster } from "@maistro/components";
import { IButtonProps } from "@maistro/components/dist/esm/types/components/Button/Button";
import { AxiosResponse } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Loader } from "components";
import AdminUsersDisplay from "features/settings/admin-users/AdminUsersDisplay";
import EditAdminUserDrawer from "features/settings/admin-users/EditAdminUserDrawer";
import AdminUserSearchFilters from "features/settings/admin-users/filter/AdminUserSearchFilters";
import IAdminUserSearchFilters from "features/settings/admin-users/filter/IAdminUserSearchFilters";
import useAppDispatch from "hooks/useAppDispatch";
import ReactGA from "react-ga4";
import routes from "routes/routePaths/RoutePaths";
import validationService from "services/validationService";
import {
    useCreateAdminUserApiMutation,
    useReinviteAdminUserApiMutation,
    useSearchAdminUsersApiQuery,
    useUpdateAdminUserApiMutation,
} from "store/api/adminApi";
import { resetLayout, setBack, setCtas, setMobileFooterCtas, setPageTitle } from "store/layoutSlice";
import { TransactionErrorException } from "types/consts/apiConstants";
import { AdminUserDto } from "types/dtos/admin/AdminUserDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const AdminUsersContainer: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const toast = useToaster();

    const defaultPageState = {
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0,
    };

    const defaultFilters: IAdminUserSearchFilters = {
        searchText: "",
    };

    const [paginationState, setPaginationState] = useState<IPaginationState>(defaultPageState);
    const [searchFilters, setSearchFilters] = useState<IAdminUserSearchFilters>(defaultFilters);
    const [selectedUser, setSelectedUser] = useState<AdminUserDto | null>(null);
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
    const [isReinviteUserPopupOpen, setIsReinviteUserPopupOpen] = useState(false);
    const {
        data: adminUsers,
        isLoading: isSearching,
        isError: isSearchingUsersError,
    } = useSearchAdminUsersApiQuery({
        searchText: searchFilters.searchText,
        skip: (paginationState.currentPage - 1) * paginationState.itemsPerPage,
        take: paginationState.itemsPerPage,
        orderByColumn: "",
        sortDescending: false,
    });

    const [updateAdminUser, { isLoading: isSavingUser }] = useUpdateAdminUserApiMutation();
    const [createAdminUser, { isLoading: isCreatingUser }] = useCreateAdminUserApiMutation();
    const [reinviteAdminUser, { isLoading: isReinvitingUser, isError: isReinvitingUserError }] =
        useReinviteAdminUserApiMutation();

    const openEditUserDrawer = useCallback((user: AdminUserDto | null) => {
        setSelectedUser(user);
        setIsEditDrawerOpen(true);
    }, []);

    const closeEditUserDrawer = useCallback(() => {
        setIsEditDrawerOpen(false);
        setSelectedUser(null);
    }, []);

    const showReinviteUserPopup = (user: AdminUserDto) => {
        setSelectedUser(user);
        setIsReinviteUserPopupOpen(true);
    };

    const hideReinviteUserPopup = () => {
        setSelectedUser(null);
        setIsReinviteUserPopupOpen(false);
    };

    const reinviteUser = () => {
        if (selectedUser !== null) {
            reinviteAdminUser({ userUuid: selectedUser.userUuid }).then(() => {
                toast.success(t("adminUsers.api.reinviteUserSuccess"));
                hideReinviteUserPopup();
            });
        }
    };

    const processSaveUserResponse = (successMessage: string) => {
        closeEditUserDrawer();
        toast.success(successMessage);
        setPaginationState(defaultPageState);
    };

    const processErrorResponse = (response: AxiosResponse) => {
        if (response.data.type === TransactionErrorException) {
            toast.error(response.data.errors.error[0]);
            return;
        }
        toast.error(t("adminUsers.api.createUserError"));
    };

    const saveUser = (user: AdminUserDto) => {
        if (validationService.isValidUuid(user.userUuid)) {
            updateAdminUser(user)
                .unwrap()
                .then(() => {
                    processSaveUserResponse(t("adminUsers.api.updateUserSuccess"));
                })
                .catch((response) => {
                    processErrorResponse(response);
                });
        } else {
            createAdminUser(user)
                .unwrap()
                .then(() => {
                    processSaveUserResponse(t("adminUsers.api.createUserSuccess"));
                })
                .catch((response) => {
                    processErrorResponse(response);
                });
        }
    };

    useEffect(() => {
        dispatch(setPageTitle(t("adminUsers.title")));
        dispatch(
            setBack({
                route: routes.common.settings,
            }),
        );

        const addUserButtonProps = {
            onClick: () => {
                ReactGA.event({
                    category: ga4Category.Button,
                    action: ga4Action.ButtonClick,
                    label: `Admin Users - Add User`,
                });
                openEditUserDrawer(null);
            },
            label: t("adminUsers.addUser"),
            testid: "add-user-button",
            icon: faPlus,
        } as IButtonProps;
        dispatch(setCtas([addUserButtonProps]));
        dispatch(setMobileFooterCtas([addUserButtonProps]));

        return () => {
            dispatch(resetLayout());
        };
    }, [dispatch, t, openEditUserDrawer]);

    useEffect(() => {
        if (adminUsers) {
            setPaginationState((prevState) => ({
                ...prevState,
                totalItems: adminUsers.totalCount,
            }));
        }
    }, [adminUsers]);

    useEffect(() => {
        if (isSearchingUsersError) {
            toast.error(t("adminUsers.api.searchAdminUsersError"));
        }
    }, [isSearchingUsersError, t, toast]);

    useEffect(() => {
        if (isReinvitingUserError) {
            toast.error(t("adminUsers.api.reinviteAdminUserError"));
        }
    }, [isReinvitingUserError, t, toast]);

    if (isSearching) {
        return <Loader />;
    }

    return (
        <>
            <AdminUserSearchFilters
                currentFilters={searchFilters}
                setFilterValues={setSearchFilters}
                isSearching={isSearching}
                resetPage={() => setPaginationState({ ...paginationState, currentPage: 1 })}
            />
            <AdminUsersDisplay
                users={adminUsers?.items ?? []}
                paginationState={paginationState}
                navigateToPage={(page: number) => setPaginationState({ ...paginationState, currentPage: page })}
                onEditClick={openEditUserDrawer}
                onReInviteClick={showReinviteUserPopup}
            />
            <EditAdminUserDrawer
                isOpen={isEditDrawerOpen}
                onClose={closeEditUserDrawer}
                selectedUser={selectedUser}
                onSubmit={saveUser}
                isCreateMode={!validationService.isValidUuid(selectedUser?.userUuid)}
                isSaving={isSavingUser || isCreatingUser}
            />
            <Popup
                isOpen={isReinviteUserPopupOpen}
                onClose={hideReinviteUserPopup}
                testid="reinvite-user-popup"
                title={t("adminUsers.popups.reinviteUser.title")}
                message={t("adminUsers.popups.reinviteUser.message", {
                    firstName: selectedUser?.firstName,
                    lastName: selectedUser?.lastName,
                })}
                primaryActionText={t("adminUsers.popups.reinviteUser.primaryActionText")}
                secondaryActionText={t("adminUsers.popups.reinviteUser.secondaryActionText")}
                onPrimaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: `Admin Users - Reinvite User - Submit`,
                    });
                    reinviteUser();
                }}
                onSecondaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: `Admin Users - Reinvite User - Cancel`,
                    });
                    hideReinviteUserPopup();
                }}
                disabled={isReinvitingUser}
            ></Popup>
        </>
    );
};

export default AdminUsersContainer;
