import { Table, Copy } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import AwardSummaryForm from "features/project/awardSummary/AwardSummaryForm";
import useAwardSummaryConfiguration from "features/project/awardSummary/hooks/useAwardSummaryConfiguration";
import { IProjectInformation } from "features/project/types";
import { ITheme } from "styles/themes/types";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import ProjectStatus from "types/enums/projects/ProjectStatus";

const useStyles = createUseStyles((theme: ITheme) => ({
    table: {
        marginTop: theme.spacing.medium,
        marginBottom: theme.spacing.medium,
    },
}));

interface IAwardSummaryDisplayProps extends ICommonProps {
    projectInformation: IProjectInformation;
    supplier?: SupplierSubmissionResponseDto;
    completeProject: (values: IProjectInformation) => void;
}

const AwardSummaryDisplay: React.FC<IAwardSummaryDisplayProps> = (props) => {
    const classes = useStyles();

    const { projectInformation, supplier, completeProject } = props;

    const { t } = useTranslation();

    const { columns } = useAwardSummaryConfiguration(projectInformation.uuid, projectInformation.type);

    const allowedStatuses = [ProjectStatus.Results, ProjectStatus.Approval];

    if (allowedStatuses.find((s) => s === projectInformation.status) == null) {
        return null;
    }

    return (
        <React.Fragment>
            <div className={classes.table}>
                {supplier && (
                    <React.Fragment>
                        <Copy>{t("awardSummary.preferredSupplier")}</Copy>
                        <Table
                            columns={columns}
                            data={[supplier]}
                            noDataDisplay={t("common.noResults")}
                            testid="supplier-table"
                        />
                    </React.Fragment>
                )}
            </div>
            <AwardSummaryForm projectInformation={projectInformation} onSubmit={completeProject} />
        </React.Fragment>
    );
};

export default AwardSummaryDisplay;
