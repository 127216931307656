enum ProjectStatus {
    Cancelled = "Cancelled",
    Archived = "Archived",
    Create = "Create",
    TenderQuestions = "TenderQuestions",
    Invite = "Invite",
    AwaitingResponses = "AwaitingResponses",
    Review = "Review",
    CompleteAtReview = "CompleteAtReview",
    Complete = "Complete",
    Results = "Results",
    Approval = "Approval",
}

export default ProjectStatus;
