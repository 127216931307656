import env from "config/EnvironmentVariables";
import { useCallback, useState } from "react";
import ReactGA from "react-ga4";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useHelp = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    const downloadModal = useCallback(() => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: "Download guide",
        });
        window.open(env.HelpUrl);
        setIsOpen(false);
    }, []);

    const openModal = useCallback(() => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: "Help",
        });
        setIsOpen(true);
    }, []);

    const confirm = useCallback((emailAddress: string) => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: "Email support",
        });
        window.open(`mailto:${emailAddress}?subject=${encodeURIComponent("Support Request")}`);
        setIsOpen(false);
    }, []);

    return {
        isOpen,
        closeModal,
        downloadModal,
        openModal,
        confirm,
    };
};

export default useHelp;
