import { faChevronDown, faChevronUp, faEdit, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { Heading, Icon, ReadOnlyField } from "@maistro/components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { ICommonProps, IOptionProps } from "components/shared";
import ReactGA from "react-ga4";
import colorService from "services/colorService";
import currencyService from "services/currencyService";
import { ITheme } from "styles/themes/types";
import { PolicyDto } from "types/dtos/company/policies/PolicyDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    policy: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: theme.spacing.medium,

        background: theme.colors.white,
        boxShadow: `${colorService.toHexWithOpacity(theme.colors.primary, 0.1)} 0 3px 6px`,
        borderRadius: theme.spacing.xSmall,
        padding: theme.spacing.medium,
    },

    heading: {
        wordBreak: "break-all",
    },

    details: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
        padding: theme.spacing.medium,
    },

    actions: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing.medium,
    },

    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        details: {
            flexDirection: "row",
            justifyContent: "space-between",
        },
    },
}));

interface IPolicyRowProps extends ICommonProps {
    policy: PolicyDto;
    companyUsers: IOptionProps[];
    onDeletePolicy: (policy: PolicyDto) => void;
    onEditPolicy: (policy: PolicyDto) => void;
}

const PolicyRow: React.FC<IPolicyRowProps> = ({ policy, companyUsers, onDeletePolicy, onEditPolicy, testid }) => {
    const classes = useStyles();

    const [isExpanded, setIsExpanded] = useState(false);

    const { t } = useTranslation();

    return (
        <div data-testid={testid}>
            <div className={classes.policy}>
                <Heading className={classes.heading} variant="h4">
                    {`${t("userGroups.policies.spendLimit")}: ${policy.name}`}
                </Heading>
                <Icon
                    icon={isExpanded ? faChevronUp : faChevronDown}
                    onClick={() => setIsExpanded(!isExpanded)}
                    data-testid={`${testid}-expand-button`}
                />
            </div>
            {isExpanded && (
                <div className={classes.details}>
                    <ReadOnlyField
                        label={`${t("userGroups.policies.form.maxSpend.label")}:`}
                        value={currencyService.toDisplayFormat(policy.spendLimit?.currency, policy.spendLimit?.limit)}
                        testid={`${testid}-max-spend`}
                    />
                    <ReadOnlyField
                        label={`${t("userGroups.policies.form.projectType.label")}:`}
                        value={policy.projectTypes
                            .map((projectType) => {
                                return projectType;
                            })
                            .join(", ")}
                        testid={`${testid}-max-spend`}
                    />
                    <ReadOnlyField
                        label={`${t("userGroups.policies.form.approvers.label")}:`}
                        value={
                            <ul>
                                {policy.approverUuids.map((approverUuid) => {
                                    const user = companyUsers.find((companyUser) => companyUser.value === approverUuid);
                                    const name = user?.label ?? "Unknown";
                                    return <li key={name}>{name}</li>;
                                })}
                            </ul>
                        }
                        testid={`${testid}-approvers`}
                    />
                    <div className={classes.actions}>
                        <Icon
                            icon={faEdit}
                            onClick={() => {
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "Pilicy Row - Edit - ".concat(policy.name),
                                });
                                onEditPolicy(policy);
                            }}
                            testid={`${testid}-edit`}
                        />
                        <Icon
                            icon={faTrashCan}
                            onClick={() => {
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "Pilicy Row - Delete - ".concat(policy.name),
                                });
                                onDeletePolicy(policy);
                            }}
                            testid={`${testid}-delete`}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default PolicyRow;
