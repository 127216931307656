import { faArrowLeft, faArrowRight, faFolderArrowDown } from "@fortawesome/pro-regular-svg-icons";
import { Alert, Button, Icon, ToggleButton } from "@maistro/components";
import SupplierScoreSummary from "features/project/components/supplier-score-summary/SupplierScoreSummary";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { ITheme } from "styles/themes/types";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    navigationButton: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing.xSmall,
    },
    subBannerButtons: {
        gap: theme.spacing.small,
    },
    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        subBannerButtons: {
            gap: theme.spacing.small,
            display: "flex",
            justifyContent: "right",
        },
    },
}));

interface IScoringDisplayHeaderProps {
    projectUuid: string;
    questions: QuestionResponseDto[];
    canModerateScores?: boolean;
    sections: SectionDto[];
    completedContributorScorers: number;
    totalContributorScorers: number;
    isProjectManager: boolean;
    projectHasFiles: boolean;
    isScoringComplete: boolean;
    canCompleteScoring?: boolean;
    setScoringCompletePopupVisible?: (visible: boolean) => void;
    supplierIndex?: number;
    projectSuppliers?: Array<SupplierSubmissionResponseDto>;
    supplierNavigate?: (direction: "next" | "previous") => void;
    onViewResults?: () => void;
    hasNavigarion?: boolean;
}
const ScoringDisplayHeader: React.FC<IScoringDisplayHeaderProps> = (props) => {
    const {
        projectUuid,
        questions,
        sections,
        completedContributorScorers,
        totalContributorScorers,
        isProjectManager,
        projectHasFiles,
        isScoringComplete,
        canCompleteScoring,
        setScoringCompletePopupVisible,
        supplierIndex,
        projectSuppliers,
        supplierNavigate,
        onViewResults,
        hasNavigarion,
    } = props;

    const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <>
            <div className={classes.completion}>
                <SupplierScoreSummary
                    sections={sections}
                    questions={questions}
                    completedContributorScorers={completedContributorScorers}
                    totalContributorScorers={totalContributorScorers}
                    isProjectManager={isProjectManager}
                />
            </div>
            <div className={classes.subBannerButtons}>
                {projectHasFiles && (
                    <Button
                        className="hidden-tablet"
                        label={t("fileUpload.archive.documents")}
                        icon={faFolderArrowDown}
                        variant="outline"
                        onClick={() => {
                            navigate(buildPath(routes.projects.tenderDocuments, { projectUuid }));
                        }}
                        testid="documents"
                    />
                )}
                {isScoringComplete && (
                    <Button
                        className={classes.viewResults}
                        type="submit"
                        label={t("tenderSummaryScreen.viewResults")}
                        onClick={onViewResults}
                        chevron
                        testid="view-results"
                    />
                )}
                {canCompleteScoring && setScoringCompletePopupVisible && (
                    <>
                        <Alert type="success" label={t("tenderSummaryScreen.scoringComplete")} />
                        <Button
                            type="submit"
                            label={t("tenderSummaryScreen.submitScores")}
                            onClick={() => setScoringCompletePopupVisible(true)}
                            testid="complete-scoring"
                        />
                    </>
                )}
                {hasNavigarion &&
                    supplierNavigate &&
                    Array.isArray(projectSuppliers) &&
                    projectSuppliers.length > 0 &&
                    supplierIndex !== undefined && (
                        <ToggleButton
                            options={[
                                {
                                    value: "previous",
                                    label: (
                                        <span className={classes.navigationButton}>
                                            <Icon icon={faArrowLeft} size="xSmall" />
                                            {t("common.previous")}
                                        </span>
                                    ),
                                    active: supplierIndex !== 0,
                                    disabled: supplierIndex === 0,
                                },
                                {
                                    value: "next",
                                    label: (
                                        <span className={classes.navigationButton}>
                                            {t("common.next")}
                                            <Icon icon={faArrowRight} size="xSmall" />
                                        </span>
                                    ),
                                    active: supplierIndex < projectSuppliers.length - 1,
                                    disabled: supplierIndex >= projectSuppliers.length - 1,
                                },
                            ]}
                            onClick={supplierNavigate}
                            className={classes.marginBottom}
                        />
                    )}
            </div>
        </>
    );
};
export default ScoringDisplayHeader;
