import { useToaster } from "@maistro/components";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { updateSupplierSubmission } from "api/project/projectSubmissionsApi";
import useAppDispatch from "hooks/useAppDispatch";
import useReduxSelector from "hooks/useReduxSelector";
import { fetchProjectResponse, setProjectResponseInformation } from "store/projectResponseSlice";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import ProjectType from "types/enums/projects/ProjectType";
import SupplierSubmissionStatus from "types/enums/supplierSubmissions/SupplierSubmissionStatus";

const useProjectResponse = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { projectResponseInformation, isFetching } = useReduxSelector((state) => state.projectResponseState);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const toast = useToaster();

    const updateSupplierSubmissionStatus = useCallback(
        async (status: SupplierSubmissionStatus, redirectUrl: string, errorText: string, successText?: string) => {
            if (!(projectResponseInformation.projectUuid && projectResponseInformation.responseUuid)) return;

            setIsSubmitting(true);
            const response = await updateSupplierSubmission({
                projectUuid: projectResponseInformation.projectUuid,
                responseUuid: projectResponseInformation.responseUuid,
                status,
            });

            if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                setIsSubmitting(false);
                toast.error(errorText);
                return;
            }

            if (successText) {
                toast.success(successText);
            }

            dispatch(setProjectResponseInformation(response.data));

            navigate(redirectUrl);
        },
        [dispatch, navigate, projectResponseInformation.projectUuid, projectResponseInformation.responseUuid, toast],
    );

    const isTenderResponseAvailable = useMemo(() => {
        const unavailableStatus = [
            SupplierSubmissionStatus.Invited,
            SupplierSubmissionStatus.NotInterested,
            SupplierSubmissionStatus.Withdrawn,
        ];

        const currentStatus = projectResponseInformation.status as SupplierSubmissionStatus;
        return (
            currentStatus &&
            !unavailableStatus.includes(currentStatus) &&
            projectResponseInformation.projectType === ProjectType.Tender
        );
    }, [projectResponseInformation.projectType, projectResponseInformation.status]);

    const getProjectResponse = () => {
        dispatch(fetchProjectResponse());
    };

    return {
        projectResponseInformation,
        isTenderResponseAvailable,
        getProjectResponse,
        updateSupplierSubmissionStatus,
        isFetching,
        isSubmitting,
    };
};

export default useProjectResponse;
