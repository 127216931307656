import { ISortBy, ITableColumn, MeatballMenu, Pill, SortOrder } from "@maistro/components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CompanyResultDto } from "types/dtos/company/CompanyResultDto";

const useCompanyListConfiguration = () => {
    const { t } = useTranslation();

    const initialSort: ISortBy = useMemo(
        () => ({
            column: "registeredName",
            order: SortOrder.Ascending,
        }),
        [],
    );

    const columns: ITableColumn[] = useMemo(() => {
        return [
            {
                position: 1,
                priorityLevel: 1,
                label: t("companyList.companies.header.registeredName"),
                name: "registeredName",
                minWidth: 200,
                contentWrap: true,
                sortable: true,
            },
            {
                position: 2,
                priorityLevel: 2,
                label: t("companyList.companies.header.industryType"),
                name: "industryType",
                minWidth: 200,
                contentWrap: true,
                sortable: true,
                render: (company: CompanyResultDto) =>
                    t(`companyList.companies.displayValues.industryTypes.${company.industryType}`),
            },
            {
                position: 3,
                priorityLevel: 3,
                label: t("companyList.companies.header.companyType"),
                name: "companyTypes",
                minWidth: 200,
                render: (company: CompanyResultDto) => company.companyType.join(", "),
            },
            {
                position: 4,
                priorityLevel: 4,
                label: t("companyList.companies.header.status"),
                name: "status",
                minWidth: 200,
                tooltip: t("companyList.companies.tooltips.status"),
                render: (company: CompanyResultDto) => (
                    <Pill
                        value={
                            company.isActive
                                ? t("companyList.companies.displayValues.status.active")
                                : t("companyList.companies.displayValues.status.inactive")
                        }
                        color={company.isActive ? "primary" : "gray"}
                    />
                ),
            },
            {
                position: 5,
                priorityLevel: 0,
                name: "menu",
                minWidth: 50,
                render: (company: CompanyResultDto) => (
                    <MeatballMenu
                        menuItems={[
                            {
                                label: t("companyList.companies.actions.delete"),
                                // eslint-disable-next-line no-console
                                action: () => console.log("Delete", company),
                            },
                        ]}
                    />
                ),
            },
        ];
    }, [t]);

    return {
        sortConfiguration: initialSort,
        columnConfiguration: columns,
    };
};

export default useCompanyListConfiguration;
