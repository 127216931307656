/* eslint-disable @typescript-eslint/no-explicit-any */
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { Button, Copy, Icon, SelectField, TextAreaField, TextButton, Tooltip, useToaster } from "@maistro/components";
import { Form, Formik } from "formik";
import { isEmpty, range } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import { overrideAnswerScore } from "api/questions/tenderCriteriaQuestionsApi";
import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";
import { ScoreResponseDto } from "types/dtos/questions/ScoreResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing.small,
        gap: theme.spacing.small,
    },
    overrideTitle: {
        fontWeight: "bold",
    },
    scoreContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: theme.spacing.large,
    },
    scoreOverriddenIcon: {
        color: theme.colors.success,
    },
    score: {
        width: 100,
    },
}));

interface IModerateScoringFormProps extends ICommonProps {
    projectUuid: string;
    questionUuid: string;
    answerUuid: string;
    score: ScoreResponseDto | undefined;
    average: number;
    setScore: (score: ScoreResponseDto) => void;
    showScoreBreakdown: () => void;
}

const ModerateScoringForm: React.FC<IModerateScoringFormProps> = (props) => {
    const classes = useStyles();

    const { projectUuid, questionUuid, answerUuid, score, setScore, testid, average, showScoreBreakdown } = props;

    const [isReasonEnabled, setIsReasonEnabled] = useState(false);

    const toast = useToaster();
    const { t } = useTranslation();

    const isOverridden = score?.score !== undefined && score.score !== average;

    useEffect(() => {
        setIsReasonEnabled(score?.score !== undefined);
    }, [score, setIsReasonEnabled]);

    const saveScore = async (values: any): Promise<void> => {
        const scoreToSave = {
            scoreUuid: score?.scoreUuid,
            score: parseInt(values.score, 10),
            comment: values.comment,
            isOverride: true,
        } as ScoreResponseDto;

        return overrideAnswerScore(projectUuid, questionUuid, answerUuid, scoreToSave).then((response) => {
            if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                toast.error(t("tenderCriteria.score.api.saveScoreError"));
                return Promise.reject(new Error(t("tenderCriteria.score.api.saveScoreError")));
            }

            setScore(response.data);
            toast.success(t("tenderCriteria.score.api.saveScoreSuccess"));
            return Promise.resolve();
        });
    };

    return (
        <Formik
            initialValues={{
                score: score ? score.score.toString() : average.toString(),
                comment: score ? score.comment : "",
            }}
            validationSchema={Yup.object({
                score: Yup.number(),
                comment: Yup.string().when("score", {
                    is: (value: number) => {
                        return value !== undefined;
                    },
                    then: (schema) => schema.required(t("tenderCriteria.moderateScore.reason.required")),
                }),
            })}
            onSubmit={(values) => saveScore(values)}
        >
            {({ values, errors }) => (
                <Form className={classes.form}>
                    <div>
                        <Tooltip
                            content={[
                                { text: t("tenderCriteria.moderateScore.overrideTooltip") },
                                {
                                    fieldName: t("tenderCriteria.score.score.tooltip.0.key"),
                                    text: t("tenderCriteria.score.score.tooltip.0.text"),
                                },
                                {
                                    fieldName: t("tenderCriteria.score.score.tooltip.1-3.key"),
                                    text: t("tenderCriteria.score.score.tooltip.1-3.text"),
                                },
                                {
                                    fieldName: t("tenderCriteria.score.score.tooltip.4-6.key"),
                                    text: t("tenderCriteria.score.score.tooltip.4-6.text"),
                                },
                                {
                                    fieldName: t("tenderCriteria.score.score.tooltip.7-9.key"),
                                    text: t("tenderCriteria.score.score.tooltip.7-9.text"),
                                },
                                {
                                    fieldName: t("tenderCriteria.score.score.tooltip.10.key"),
                                    text: t("tenderCriteria.score.score.tooltip.10.text"),
                                },
                            ]}
                            start
                            testid={`${testid}-override-score-tooltip`}
                        >
                            <Copy className={classes.overrideTitle}>
                                {t("tenderCriteria.moderateScore.overrideTitle")}
                            </Copy>
                        </Tooltip>
                    </div>
                    <div className={classes.scoreContainer}>
                        <SelectField
                            testid={`${testid}-score`}
                            disableSearch
                            withoutValidation
                            className={classes.score}
                            name="score"
                            options={range(0, 11).map((n) => ({
                                value: `${n}`,
                                label: `${n}`,
                            }))}
                            onChange={(value) => {
                                setIsReasonEnabled(value !== undefined);
                            }}
                        />
                        {isOverridden && (
                            <Icon
                                className={classes.scoreOverriddenIcon}
                                testid={`${testid}-score-overridden-icon`}
                                size="large"
                                icon={faCheckCircle}
                                alt={t("tenderCriteria.moderateScore.overriddenIcon")}
                            />
                        )}
                        <TextButton
                            label={t("tenderCriteria.moderateScore.scoreBreakdown")}
                            testid={`${testid}-score-breakdown`}
                            onClick={() => showScoreBreakdown()}
                        />
                    </div>
                    <TextAreaField
                        name="comment"
                        label={t("tenderCriteria.moderateScore.reason.label")}
                        withPassIcon={false}
                        testid={`${testid}-reason`}
                        required
                        disabled={!isReasonEnabled}
                    />
                    <Button
                        color="dark"
                        label={t("common.save")}
                        onClick={() => saveScore(values)}
                        testid={`${testid}-save`}
                        disabled={!isReasonEnabled || !isEmpty(errors)}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default ModerateScoringForm;
