import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import enGbTranslations from "translations/enGB/enGbTranslations";

declare module "i18next" {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

i18n.use(LanguageDetector).init({
    returnNull: false,
    interpolation: { escapeValue: false },
    lng: "enGB",
    resources: {
        enGB: {
            translations: enGbTranslations,
        },
    },
    ns: ["translations"],
    defaultNS: "translations",
});

export default i18n;
