import { Copy } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";

import emptyApprovalsImage from "assets/images/noApprovals.svg";
import useEmptyScreenStyles from "features/shared/styles/useEmptyScreenStyles";

const EmptyApprovals: React.FC = () => {
    const { t } = useTranslation();
    const classes = useEmptyScreenStyles();

    return (
        <>
            <Copy>{t("approvals.subtitle")}</Copy>
            <div className={classes.wrapper}>
                <div className={classes.content}>
                    <div>
                        <img
                            src={emptyApprovalsImage}
                            className={classes.emptyImage}
                            alt={t("approvals.emptyApprovals.message")}
                        />
                    </div>
                    <Copy testid="empty-approvals-message" className={classes.copy}>
                        {t("approvals.emptyApprovals.message")}
                    </Copy>
                </div>
            </div>
        </>
    );
};

export default EmptyApprovals;
