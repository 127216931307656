interface IFileService {
    toBytesBinary: (megabytes: number) => number;
    toFileType: (extension: string) => string;
    getExtensionFromName: (name: string) => string;
}
const fileService: IFileService = {
    toBytesBinary: (megabytes) => megabytes * 1048576,
    toFileType: (extension) => extension.replaceAll(".", "").toUpperCase(),
    getExtensionFromName: (name) => {
        const extensionIndex = name.lastIndexOf(".");
        if (extensionIndex) {
            return name.substring(extensionIndex);
        }
        return name;
    },
};

export default fileService;
