import { createUseStyles } from "react-jss";

import { ITheme } from "styles/themes/types";

const useSectionStyles = createUseStyles((theme: ITheme) => ({
    section: {
        display: "flex",
        flexDirection: "column",
    },
    subSection: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
        marginLeft: theme.spacing.xSmall,
        marginTop: theme.spacing.small,
        marginBottom: theme.spacing.units(8),
    },
    listStyle: {
        listStyle: "auto",
        ...theme.typography.base,
        fontWeight: 500,
        marginLeft: theme.spacing.medium,
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.units(6),
        marginTop: theme.spacing.small,
        marginBottom: theme.spacing.units(8),

        "& > li": {
            paddingLeft: theme.spacing.xSmall,
        },
    },
    heading: {
        fontWeight: 600,
        marginBottom: theme.spacing.small,
    },
    hr: {
        marginBottom: theme.spacing.large,
    },
    selectContainer: {
        width: 120,
    },
    marginTop: {
        marginTop: theme.spacing.small,
    },

    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        subSection: {
            marginLeft: theme.spacing.large,
        },
        listStyle: {
            marginLeft: theme.spacing.units(7),

            "& > li": {
                paddingLeft: theme.spacing.large,
            },
        },
    },
}));

export default useSectionStyles;
