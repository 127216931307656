import { useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { listExpressionOfInterest, selectExpressionOfInterest } from "api/expressionOfInterestApi";
import { updateProject } from "api/projectApi";
import { Loader } from "components";
import TitleCtaType from "components/Layout/types/TitleCtaType";
import ProjectPreviewDrawer from "features/project/components/ProjectPreviewDrawer";
import MonitorDisplay from "features/project/expressionOfInterest/monitor/MonitorDisplay";
import useProject from "features/project/hooks/useProject";
import useAppDispatch from "hooks/useAppDispatch";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import dateTimeService from "services/dateTimeService";
import validationService from "services/validationService";
import { resetLayout, setBack, setCtas, setPageTitle, setTitleCtas, setTooltip } from "store/layoutSlice";
import { setExpressionOfInterestDeadline } from "store/projectSlice";
import { ExpressionOfInterestResponseDto } from "types/dtos/projects/expressionOfInterest/ExpressionOfInterestResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import ProjectStatus from "types/enums/projects/ProjectStatus";

const MonitorContainer: React.FC = () => {
    const [suppliers, setSuppliers] = useState<ExpressionOfInterestResponseDto[]>([]);
    const [isFetchingSuppliers, setIsFetchingSuppliers] = useState(true);
    const [isSelectingSupplier, setIsSelectingSupplier] = useState(false);
    const [deadlineHasPassed, setDeadlineHasPassed] = useState(false);
    const [isProjectPreviewOpen, setIsProjectPreviewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const toast = useToaster();

    const { projectInformation, isFetching: isFetchingProject } = useProject();

    const fetchData = useCallback(async () => {
        if (!validationService.isValidUuid(projectInformation.uuid)) {
            return;
        }

        setIsFetchingSuppliers(true);
        const response = await listExpressionOfInterest(projectInformation.uuid);
        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("expressionOfInterest.monitor.api.fetchSubmissionsError"));
            return;
        }

        setDeadlineHasPassed(dateTimeService.dateIsInPast(projectInformation.expressionOfInterestDeadline));
        setSuppliers(response.data.responses);
        setIsFetchingSuppliers(false);
    }, [projectInformation.expressionOfInterestDeadline, projectInformation.uuid, t, toast]);

    useEffect(() => {
        dispatch(setBack(null));
        dispatch(setCtas(null));
        dispatch(setPageTitle(t("expressionOfInterest.monitor.title")));
        dispatch(setTooltip(t("expressionOfInterest.monitor.tooltip")));
        dispatch(
            setTitleCtas([
                {
                    type: TitleCtaType.ProjectPreview,
                    onClick: () => setIsProjectPreviewOpen(true),
                },
            ]),
        );

        return () => {
            dispatch(resetLayout());
        };
    }, [dispatch, projectInformation, t]);

    const completeProject = useCallback(async () => {
        const response = await updateProject(projectInformation.uuid, {
            status: ProjectStatus.Complete,
            dirtyValues: { status: true },
        });

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("expressionOfInterest.monitor.api.completeProjectError"));
            return;
        }

        toast.success(t("expressionOfInterest.monitor.api.completeProjectSuccess"));
        navigate(buildPath(routes.projects.results, { projectUuid: projectInformation.uuid }));
    }, [navigate, projectInformation.uuid, t, toast]);

    const updateEoiDeadline = useCallback(
        async (deadline: Date) => {
            setIsLoading(true);

            if (projectInformation.expressionOfInterestDeadline === deadline) return;
            const response = await updateProject(projectInformation.uuid, {
                expressionOfInterestDeadline: deadline,
                dirtyValues: { expressionOfInterestDeadline: true },
            });

            if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                toast.error(t("expressionOfInterest.monitor.api.updateDeadlineError"));
                setIsLoading(false);
                return;
            }

            dispatch(setExpressionOfInterestDeadline(response.data));

            toast.success(t("expressionOfInterest.monitor.api.updateDeadlineSuccess"));
            setIsLoading(false);
        },
        [dispatch, projectInformation.expressionOfInterestDeadline, projectInformation.uuid, setIsLoading, t, toast],
    );

    const setSupplierSelection = useCallback((supplier: ExpressionOfInterestResponseDto, isSelected: boolean) => {
        setSuppliers((prevState) => {
            const suppliersCopy = [...prevState];
            const matchingSupplier = suppliersCopy.find((s) => s.projectInterestUuid === supplier.projectInterestUuid);
            if (matchingSupplier) {
                matchingSupplier.isSelected = isSelected;
            }
            return suppliersCopy;
        });
    }, []);

    const selectSupplier = useCallback(
        async (supplier: ExpressionOfInterestResponseDto) => {
            setIsSelectingSupplier(true);

            const response = await selectExpressionOfInterest({
                projectUuid: supplier.projectUuid,
                projectInterestUuid: supplier.projectInterestUuid,
                isSelected: !supplier.isSelected,
            });
            if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                toast.error(t("expressionOfInterest.monitor.api.selectSupplierError"));
                setIsSelectingSupplier(false);
                return;
            }

            const { isSelected } = response.data;
            setSupplierSelection(supplier, isSelected);
            toast.success(t(`expressionOfInterest.monitor.api.${isSelected ? "select" : "unselect"}SupplierSuccess`));
            setIsSelectingSupplier(false);
        },
        [setSupplierSelection, t, toast],
    );

    const refreshSuppliersData = useCallback(async () => {
        setIsLoading(true);
        await fetchData();
        setIsLoading(false);
    }, [fetchData]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (!isFetchingProject && !isFetchingSuppliers) {
            setIsLoading(false);
        }
    }, [isFetchingProject, isFetchingSuppliers]);

    if (isLoading) return <Loader />;

    return (
        <React.Fragment>
            <MonitorDisplay
                project={projectInformation}
                suppliers={suppliers}
                selectSupplier={selectSupplier}
                isSelectingSupplier={isSelectingSupplier}
                completeProject={completeProject}
                updateEoiDeadline={updateEoiDeadline}
                deadlineHasPassed={deadlineHasPassed}
                refreshSuppliers={refreshSuppliersData}
            />
            <ProjectPreviewDrawer isOpen={isProjectPreviewOpen} close={() => setIsProjectPreviewOpen(false)} />
        </React.Fragment>
    );
};

export default MonitorContainer;
