import { LoadingScreen } from "@maistro/components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useTimeout from "hooks/useTimeout";

interface IWithLoadingScreenProps {
    titleKey: string;
    messageKey: string | string[];
    duration: number;
    image?: string;
}

const WithLoadingScreen = <T,>(WrappedComponent: React.ComponentType<T>, loadingProps: IWithLoadingScreenProps) => {
    const HOC: React.FC<T> = (componentProps) => {
        const [loading, setLoading] = useState(true);

        const { t } = useTranslation();
        const { beginTimer, clearTimer } = useTimeout(() => setLoading(false), loadingProps.duration);

        useEffect(() => {
            beginTimer();
            return () => clearTimer();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return (
            <>
                {loading && (
                    <LoadingScreen
                        {...loadingProps}
                        title={t(loadingProps.titleKey)}
                        message={
                            Array.isArray(loadingProps.messageKey)
                                ? loadingProps.messageKey.map((x) => t(x))
                                : t(loadingProps.messageKey)
                        }
                        testid="loading-screen"
                    />
                )}
                <WrappedComponent {...componentProps} setLoading={setLoading} />
            </>
        );
    };
    return HOC;
};

export default WithLoadingScreen;
