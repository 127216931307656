import { IOption } from "@maistro/components";

import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import { IScoreBreakdownDto } from "types/dtos/questions/IScoreBreakdownDto";
import RolesEnum from "types/enums/rolesPermissions/RolesEnum";
import SupplierSubmissionStatus from "types/enums/supplierSubmissions/SupplierSubmissionStatus";

const sortingAlgorithms = () => {
    const compareStatus = (a: SupplierSubmissionResponseDto, b: SupplierSubmissionResponseDto) => {
        const map = {
            [SupplierSubmissionStatus.Successful]: 1,
            [SupplierSubmissionStatus.Shortlisted]: 2,
            [SupplierSubmissionStatus.Submitted]: 3,
            [SupplierSubmissionStatus.Responding]: 4,
            [SupplierSubmissionStatus.Interested]: 5,
            [SupplierSubmissionStatus.Invited]: 6,
            [SupplierSubmissionStatus.Identified]: 7,
            [SupplierSubmissionStatus.Failed]: 8,
            [SupplierSubmissionStatus.Withdrawn]: 9,
            [SupplierSubmissionStatus.NotInterested]: 10,
            [SupplierSubmissionStatus.Unsuccessful]: 11,
            [SupplierSubmissionStatus.Unset]: 12,
        };

        if (map[a.status] > map[b.status]) {
            return 1;
        }

        if (map[a.status] < map[b.status]) {
            return -1;
        }

        return a._embedded.supplierCompanyName.localeCompare(b._embedded.supplierCompanyName);
    };

    const sortSuppliersByScore = (a: SupplierSubmissionResponseDto, b: SupplierSubmissionResponseDto) => {
        if (a.scoring !== b.scoring) {
            return parseInt(b.scoring.slice(0, -1), 10) - parseInt(a.scoring.slice(0, -1), 10);
        }

        return compareStatus(a, b);
    };

    const sortSuppliersByMatchRatingDesc = (a: SupplierSubmissionResponseDto, b: SupplierSubmissionResponseDto) => {
        if (a._included.matchRating !== b._included.matchRating) {
            return b._included.matchRating - a._included.matchRating;
        }

        return compareStatus(a, b);
    };

    const sortScorersByRoleThenName = (a: IScoreBreakdownDto, b: IScoreBreakdownDto) => {
        const sortedRoleNames = [
            RolesEnum.ProjectManager,
            RolesEnum.ProjectContributor,
            RolesEnum.CompanyAdmin,
            RolesEnum.GlobalAdmin,
            RolesEnum.User,
            RolesEnum.BuyerReadOnly,
            "",
        ];

        if (sortedRoleNames.indexOf(a.role) > sortedRoleNames.indexOf(b.role)) {
            return 1;
        }

        if (sortedRoleNames.indexOf(a.role) < sortedRoleNames.indexOf(b.role)) {
            return -1;
        }

        return a.name.localeCompare(b.name);
    };

    const sortSelectOptionsByLabel = (a: IOption, b: IOption) => {
        return a.label.localeCompare(b.label);
    };

    return {
        sortSuppliersByMatchRatingDesc,
        sortSuppliersByScore,
        sortScorersByRoleThenName,
        sortSelectOptionsByLabel,
    };
};
export default sortingAlgorithms;
