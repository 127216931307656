import { Alert, Copy, Heading } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";

import ScoreTenderBySupplierSectionRow from "features/project/tenderCriteria/components/scoring/ScoreTenderBySupplierSectionRow";
import ScoringDisplayHeader from "features/project/tenderCriteria/components/scoring/ScoringDisplayHeader";
import { useTenderPermissions } from "features/project/tenderCriteria/hooks/useTenderPermissions";
import useTenderStyles from "features/project/tenderCriteria/hooks/useTenderStyles";
import { IProjectInformation } from "features/project/types";
import useCurrentUser from "hooks/useCurrentUser";
import { AnswerDto } from "types/dtos/questions/AnswerResponseDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { ScoreResponseDto } from "types/dtos/questions/ScoreResponseDto";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";

interface IScoreTenderSummaryBySupplierDisplayProps {
    sections: SectionDto[];
    questions: QuestionResponseDto[];
    completedContributorScorers: number;
    totalContributorScorers: number;
    projectInformation: IProjectInformation;
    isProjectManager: boolean;
    isScoringComplete: boolean;
    displayScoringClosedMessage: boolean;
    projectHasFiles: boolean;
    setScore: (score: ScoreResponseDto, questionUuid: string) => void;
}

const ScoreTenderSummaryBySupplierDisplay: React.FC<IScoreTenderSummaryBySupplierDisplayProps> = (props) => {
    const classes = useTenderStyles();

    const {
        sections,
        questions,
        isScoringComplete,
        displayScoringClosedMessage,
        projectHasFiles,
        setScore,
        completedContributorScorers,
        totalContributorScorers,
        isProjectManager,
        projectInformation,
    } = props;

    const { t } = useTranslation();

    const { userHasProjectPermission, myUuid } = useCurrentUser();

    const { canScoreAnswers, canModerateScores } = useTenderPermissions({
        isScoringComplete,
        projectInformation,
        userHasProjectPermission,
    });

    const haveScores = (answer: AnswerDto) => {
        return (
            !!answer.scores &&
            answer.scores.length > 0 &&
            answer.scores.some((score) => score.updatedByUserUuid === myUuid)
        );
    };

    const respondingCompanyName =
        questions.length > 0 && questions[0].answers.length > 0 ? questions[0].answers[0].respondingCompanyName : null;

    const questionInSection = (section: SectionDto, question: QuestionResponseDto) =>
        question.entityUuid.toLowerCase() === section.id.toLowerCase();

    return (
        <>
            {displayScoringClosedMessage ? (
                <Alert type="error" label={t("tenderSummaryScreen.scoringClosedMessage")} testid="scoring-closed" />
            ) : (
                <Copy className={classes.copy}>{t("tenderSummaryScreen.subtitle")}</Copy>
            )}
            <div className={classes.subBanner}>
                <ScoringDisplayHeader
                    projectUuid={projectInformation.uuid}
                    questions={questions}
                    canModerateScores={false}
                    sections={sections}
                    completedContributorScorers={completedContributorScorers}
                    totalContributorScorers={totalContributorScorers}
                    isProjectManager={isProjectManager}
                    projectHasFiles={projectHasFiles}
                    isScoringComplete={isScoringComplete}
                />
            </div>
            {respondingCompanyName && (
                <div className={classes.supplierDropdown}>
                    <Heading className={classes.supplierName} variant="h4">
                        {respondingCompanyName}
                    </Heading>
                </div>
            )}
            {sections.map((section, index) => (
                <ScoreTenderBySupplierSectionRow
                    key={section.id}
                    projectUuid={projectInformation.uuid}
                    section={section}
                    questions={questions.filter((question) => questionInSection(section, question))}
                    haveScores={haveScores}
                    canScoreAnswers={canScoreAnswers}
                    canModerateScores={canModerateScores}
                    expandedByDefault={index === 0}
                    testid={`section-${section.id}`}
                    setScore={setScore}
                />
            ))}
        </>
    );
};
export default ScoreTenderSummaryBySupplierDisplay;
