import { Heading } from "@maistro/components";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

import EnvironmentSubSection, {
    environmentValidationSchema,
} from "features/company/profile/vetting/sections/esgSubSections/EnvironmentSubSection";
import HealthAndSafetySubSection, {
    healthAndSafetyValidationSchema,
} from "features/company/profile/vetting/sections/esgSubSections/HealthAndSafetySubSection";
import useSectionStyles from "features/company/profile/vetting/sections/useSectionStyles";
import FileDto from "types/dtos/files/FileDto";

export const esgValidationSchema = (t: TFunction<"translation", undefined>) =>
    healthAndSafetyValidationSchema().concat(environmentValidationSchema(t));

interface IEnvironmentalSocialAndGovernanceSectionProps {
    environmentPolicyFiles: FileDto[];
    healthAndSafetyPolicyFiles: FileDto[];
    drugAndAlcoholPolicyFiles: FileDto[];
    setEnvironmentPolicyFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    setHealthAndSafetyPolicyFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    setDrugAndAlcoholPolicyFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
}

const EnvironmentalSocialAndGovernanceSection: React.FC<IEnvironmentalSocialAndGovernanceSectionProps> = ({
    drugAndAlcoholPolicyFiles,
    environmentPolicyFiles,
    healthAndSafetyPolicyFiles,
    setEnvironmentPolicyFiles,
    setDrugAndAlcoholPolicyFiles,
    setHealthAndSafetyPolicyFiles,
}) => {
    const classes = useSectionStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.section}>
            <Heading className={classes.heading} variant="h3">
                {t("companyVettingQuestionnaire.sections.environmentalSocialAndGovernance.heading")}
            </Heading>
            <hr className={classes.hr} />
            <HealthAndSafetySubSection
                drugAndAlcoholPolicyFiles={drugAndAlcoholPolicyFiles}
                healthAndSafetyPolicyFiles={healthAndSafetyPolicyFiles}
                setDrugAndAlcoholPolicyFiles={setDrugAndAlcoholPolicyFiles}
                setHealthAndSafetyPolicyFiles={setHealthAndSafetyPolicyFiles}
            />
            <EnvironmentSubSection
                environmentPolicyFiles={environmentPolicyFiles}
                setEnvironmentPolicyFiles={setEnvironmentPolicyFiles}
            />
        </div>
    );
};

export default EnvironmentalSocialAndGovernanceSection;
