import React from "react";

import useCurrentUser from "hooks/useCurrentUser";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";
import ProjectsDashboardContainer from "features/dashboard/projects/ProjectsDashboardContainer";
import SupplierSubmissionsDashboardContainer from "features/dashboard/supplierSubmissions/SupplierSubmissionsDashboardContainer";

const Dashboard: React.FC = () => {
    const { userHasPermission, userIsSupplier } = useCurrentUser();

    const showSupplierDashboard =
        userHasPermission(PermissionsEnum.UpdateProjectResponse) && !userHasPermission(PermissionsEnum.UpdateProject);

    if (showSupplierDashboard && userIsSupplier) return <SupplierSubmissionsDashboardContainer />;

    return <ProjectsDashboardContainer />;
};

export default Dashboard;
