export const RegexPassword = "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W]).{8,}$";
export const RegexPostCode = "^[A-Za-z]{1,2}[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}$";
export const RegexWebUrl = "^(https?://)?([a-zA-Z0-9.-]+.[a-zA-Z.]{2,6}|[0-9.]+)([/:?=&#]{1}[a-zA-Z0-9.-]+)*[/?]?$";
export const RegexLinkedInProfileUrl =
    /^(http(s)?:\/\/)?(\w+\.)?linkedin\.com\/(pub|in|profile|company)\/([-a-zA-Z0-9]+)\/*/;
export const RegexTelephoneCharacters = /^[0-9+() ]+$/;
export const RegexDigits = /\d+/;
export const RegexHTMLTag = /<\/?[a-z][\s\S]*>/i;
export const RegexVatRegistrationNumber = /^([a-zA-Z]){2}\d{9}$/;
export const RegexEmail = /\b[\w-.]+@[\w-.]+\.\w{2,8}\b/;
