import { Pill, ReadOnlyField } from "@maistro/components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import FileList from "components/FileList/FileList";
import { IProjectInformation } from "features/project/types";
import useCurrentUser from "hooks/useCurrentUser";
import currencyService from "services/currencyService";
import dateTimeService from "services/dateTimeService";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";
import CategorySelectionStatus from "types/enums/CategorySelectionStatus";
import BudgetType from "types/enums/projects/BudgetType";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
    categories: {
        margin: `${theme.spacing.xSmall / 3}px ${theme.spacing.xSmall / 3}px`,
    },
    content: {
        overflow: "auto",
    },
}));

interface IProjectBriefDisplay {
    projectInformation: IProjectInformation;
    projectFiles?: FileDto[];
}

const ProjectBriefDisplay: React.FC<IProjectBriefDisplay> = (props) => {
    const classes = useStyles();

    const { projectInformation, projectFiles } = props;

    const { t } = useTranslation();

    const projectUuid = useMemo(() => {
        if ("uuid" in projectInformation) return projectInformation.uuid;

        return undefined;
    }, [projectInformation]);

    const companyUuid = useMemo(() => {
        if ("clientCompanyUuid" in projectInformation) return projectInformation.clientCompanyUuid;

        return undefined;
    }, [projectInformation]);

    const { userIsMaistro } = useCurrentUser();

    return (
        <div className={classes.container}>
            {userIsMaistro && projectInformation.clientCompanyUuid && (
                <ReadOnlyField
                    testid="project-client"
                    label={t("projectBrief.client.label")}
                    value={projectInformation.clientName}
                />
            )}
            <ReadOnlyField
                testid="project-name"
                label={t("projectBrief.name.label")}
                value={projectInformation.projectName}
            />
            <ReadOnlyField
                testid="project-manager"
                label={t("projectBrief.manager.label")}
                value={projectInformation.manager}
            />
            {projectInformation.sponsorName && (
                <ReadOnlyField
                    testid="project-manager"
                    label={t("projectBrief.sponsor.label")}
                    value={projectInformation.sponsorName}
                />
            )}
            {projectInformation.contributors.length > 0 && (
                <ReadOnlyField
                    testid="project-contributors"
                    label={t("projectBrief.contributors.label")}
                    value={projectInformation.contributors
                        .map((contributor) => `<div>${contributor.firstName} ${contributor.lastName}</div>`)
                        .join("")}
                />
            )}
            {projectInformation.budgetType === BudgetType.Budget ? (
                <ReadOnlyField
                    testid="project-budget"
                    label={t("projectBrief.buyerBudget.label")}
                    value={
                        currencyService.toCurrencyIcon(projectInformation.budgetCurrency) +
                        Number(projectInformation.buyerBudget).toLocaleString()
                    }
                />
            ) : (
                <ReadOnlyField
                    testid="project-budget"
                    label={t("projectBrief.buyerBudget.label")}
                    value={t(`budgetTypes.names.${projectInformation.budgetType}`)}
                />
            )}
            {projectInformation.tenderResponseDeadline && (
                <ReadOnlyField
                    testid="project-response-date"
                    label={t("projectSummary.quote.fields.projectResponseDate.label")}
                    value={dateTimeService.toShortDateTimeFormat(projectInformation.tenderResponseDeadline)}
                />
            )}
            {projectInformation.clarificationQuestionDeadline && (
                <ReadOnlyField
                    testid="clarification-question-deadline"
                    label={t("projectSummary.tender.fields.clarificationQuestionDeadline.label")}
                    value={dateTimeService.toShortDateFormat(projectInformation.clarificationQuestionDeadline)}
                />
            )}
            {projectInformation.targetProjectStartDate && (
                <ReadOnlyField
                    testid="contract-start-date"
                    label={t("projectSummary.tender.fields.contractStartDate.label")}
                    value={dateTimeService.toShortDateFormat(projectInformation.targetProjectStartDate)}
                />
            )}
            {projectInformation.targetProjectCompleteDate && (
                <ReadOnlyField
                    testid="contract-end-date"
                    label={t("projectSummary.tender.fields.contractEndDate.label")}
                    value={dateTimeService.toShortDateFormat(projectInformation.targetProjectCompleteDate)}
                />
            )}
            {projectInformation.timescale && (
                <ReadOnlyField
                    testid="project-timescales"
                    className={classes.content}
                    label={t("projectBrief.timescale.label")}
                    value={projectInformation.timescale}
                />
            )}
            <ReadOnlyField
                testid="project-description"
                className={classes.content}
                label={t("projectBrief.description.label")}
                value={projectInformation.description}
            />
            {projectInformation.categories.length > 0 && (
                <ReadOnlyField
                    testid="project-categories"
                    label={t("projectBrief.categories.label")}
                    value={projectInformation.categories
                        .filter((category) => category.status !== CategorySelectionStatus.Removed)
                        .map((category) => (
                            <Pill
                                key={`category-${category.value}`}
                                className={classes.categories}
                                value={category.label}
                                testid={`category-${category.value}`}
                            />
                        ))}
                />
            )}
            {projectUuid && projectFiles && projectFiles.length > 0 && (
                <ReadOnlyField
                    testid="project-files"
                    label={t("projectBrief.fileList.label")}
                    value={
                        <FileList
                            files={projectFiles.filter(
                                (file) => file.linkedCompanyUuid === companyUuid || file.linkedCompanyUuid == null,
                            )}
                            canDownload
                            testid="project-documents"
                        />
                    }
                />
            )}
        </div>
    );
};

export default ProjectBriefDisplay;
