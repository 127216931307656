import { IPaginationState, ISortBy, useToaster } from "@maistro/components";
import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { listApprovalsForUser } from "api/company/approvalsApi";
import { duplicateProject, searchProjects } from "api/projectApi";
import useDashboardFilters, {
    IDashboardFilters,
    emptyFilters,
} from "features/dashboard/filter/hooks/useDashboardFilters";
import { IDuplicateProjectInformation } from "features/dashboard/interfaces/IDuplicateProjectInformation";
import { IProjectDashboardInformation } from "features/dashboard/interfaces/IProjectDashboardInformation";
import useAppDispatch from "hooks/useAppDispatch";
import useCompany from "hooks/useCompany";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import apiErrorService from "services/apiErrorService";
import { resetLayout, setBack, setBanner, setCtas, setMobileFooterCtas, setPageTitle } from "store/layoutSlice";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import FeatureToggle from "types/enums/companies/FeatureToggle";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useDashboardContainer = (
    initialSort: ISortBy,
    initialFilters: IDashboardFilters,
    setLoading: (loadingValue: boolean) => void,
) => {
    const [sortBy, setSortBy] = useState<ISortBy>(initialSort);
    const [paginationState, setPaginationState] = useState<IPaginationState>({
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0,
    });
    const [filteredProjects, setFilteredProjects] = useState<Array<IProjectDashboardInformation>>([]);
    const [isSearching, setIsSearching] = useState(false);
    const [isDuplicating, setIsDuplicating] = useState(false);
    const [hasUnactionedApprovals, setHasUnactionedApprovals] = useState(false);
    const [disableSorting, setDisableSorting] = useState(false);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const toast = useToaster();

    const { myCompanyUuid, userCanCreateProjects } = useCurrentUser();
    const { currentFilters, setCurrentFilters, options, getAvailableProjectManagers, getAvailableCompanies } =
        useDashboardFilters(initialFilters);
    const { companyHasFeature } = useCompany();

    const createProject = useCallback(() => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: "Create Project",
        });
        navigate(buildPath(routes.projects.projectBrief, { projectUuid: "new" }));
    }, [navigate]);

    useEffect(() => {
        dispatch(setBack(null));
        dispatch(setPageTitle(t("dashboard.projects.title")));

        if (userCanCreateProjects) {
            const createProjectButtonProps = {
                onClick: createProject,
                label: t("dashboard.projects.cta"),
                testid: "new-project-button",
                chevron: true,
            };
            dispatch(setCtas([createProjectButtonProps]));
            dispatch(setMobileFooterCtas([createProjectButtonProps]));
        }

        return () => {
            dispatch(resetLayout());
        };
    }, [dispatch, createProject, t, userCanCreateProjects]);

    const filteredProjectsCount = filteredProjects.length;
    const shouldDisplayEmptyDashboard = filteredProjectsCount === 0 && isEqual(currentFilters, emptyFilters);
    const shouldDisplayNoResultsDashboard = filteredProjectsCount === 0 && !isEqual(currentFilters, emptyFilters);

    const searchMyProjects = useCallback(async () => {
        if (filteredProjectsCount === 0) {
            setLoading(true);
        }
        setIsSearching(true);

        const searchProjectsResponse = await searchProjects(
            paginationState.currentPage,
            paginationState.itemsPerPage,
            sortBy,
            currentFilters,
        );
        if (searchProjectsResponse.status === 200 && !(searchProjectsResponse.data instanceof TransactionErrorDto)) {
            setFilteredProjects(
                searchProjectsResponse.data._embedded.items.map((projectDto) => ({
                    ...projectDto,
                })),
            );
            setPaginationState({
                currentPage: paginationState.currentPage,
                itemsPerPage: paginationState.itemsPerPage,
                totalItems: searchProjectsResponse.data._embedded.totalCount,
            });
        }
        setLoading(false);
        setIsSearching(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationState.currentPage, paginationState.itemsPerPage, sortBy, currentFilters]);

    useEffect(() => {
        if (myCompanyUuid && hasUnactionedApprovals) {
            dispatch(
                setBanner({
                    text: t("approvals.banner.text"),
                    desktopText: t("approvals.banner.desktopText"),
                    onClick: () => navigate(buildPath(routes.company.approvals, { companyUuid: myCompanyUuid ?? "" })),
                }),
            );
        }

        return () => {
            dispatch(setBanner(null));
        };
    }, [dispatch, hasUnactionedApprovals, myCompanyUuid, navigate, t]);

    const fetchUnactionedApprovals = useCallback(async () => {
        if (!myCompanyUuid || !companyHasFeature(FeatureToggle.Approvals)) return;

        const response = await listApprovalsForUser({ skip: 0, take: 1 });
        if (response.data instanceof TransactionErrorDto) {
            toast.error(apiErrorService.getFirstErrorFromResponse(response.data));
            return;
        }

        if (response.status !== 200) {
            return;
        }

        setHasUnactionedApprovals(response.data.approvals?.some((approval) => !approval.isActioned));
    }, [myCompanyUuid, toast, companyHasFeature]);

    const fetchData = useCallback(async () => {
        await searchMyProjects();
    }, [searchMyProjects]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        fetchUnactionedApprovals();
    }, [fetchUnactionedApprovals]);

    const navigateToPage = (page: number) => {
        setPaginationState({ ...paginationState, currentPage: page });
    };

    const setFilters = (filters: IDashboardFilters) => {
        if (filters.searchTerm && filters.searchTerm.length > 0) {
            setDisableSorting(true);
        } else {
            setDisableSorting(false);
        }
        setCurrentFilters(filters);
    };

    const createDuplicateProject = (projectToDuplicate: IDuplicateProjectInformation) => {
        setIsDuplicating(true);
        duplicateProject(projectToDuplicate.projectUuid, {
            ...projectToDuplicate,
            client: projectToDuplicate.clientCompanyUuid ?? null,
            sponsorUserUuid: projectToDuplicate.sponsorUserUuid ?? null,
        }).then((response) => {
            if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                toast.error(
                    t("dashboard.projects.duplicate.duplicationError", {
                        previousName: projectToDuplicate.previousName,
                    }),
                );
                setIsDuplicating(false);
                return;
            }
            toast.success(
                t("dashboard.projects.duplicate.duplicationSuccess", {
                    previousName: projectToDuplicate.previousName,
                    name: projectToDuplicate.name,
                }),
            );

            if (projectToDuplicate.isTender) {
                setIsDuplicating(false);
                navigate(buildPath(routes.projects.projectBrief, { projectUuid: response.data.projectUuid }));
                return;
            }

            fetchData();
            setIsDuplicating(false);
        });
    };

    return {
        currentFilters,
        setFilters,
        options,
        getAvailableProjectManagers,
        getAvailableCompanies,
        filteredProjects,
        sortBy,
        setSortBy,
        paginationState,
        navigateToPage,
        createProject,
        userCanCreateProjects,
        isDuplicating,
        isSearching,
        shouldDisplayEmptyDashboard,
        shouldDisplayNoResultsDashboard,
        createDuplicateProject,
        disableSorting,
    };
};

export default useDashboardContainer;
