import { useToaster } from "@maistro/components";
import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { listQuestions, getQuestion } from "api/questions/clarificationQuestionsApi";
import { getClarificationQuestionsReport } from "api/reportingApi";
import validationService from "services/validationService";
import { GetClarificationQuestionRequestDto } from "types/dtos/questions/GetClarificationQuestionRequestDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const useProjectClarificationQuestions = (
    projectUuid?: string,
    questionUuid?: string,
    setLoading?: (isLoading: boolean) => void,
) => {
    const [clarificationQuestions, setClarificationQuestions] = useState<Array<QuestionResponseDto> | null>(null);
    const [clarificationQuestion, setClarificationQuestion] = useState<QuestionResponseDto | null>(null);

    const { t } = useTranslation();
    const toast = useToaster();

    const fetchQuestions = useCallback(async () => {
        if (!(projectUuid && validationService.isValidUuid(projectUuid))) return;

        const response = await listQuestions(projectUuid);

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("clarificationQuestions.api.getQuestionsError"));
            setClarificationQuestions([]);
            return;
        }

        setClarificationQuestions(response.data.items);

        if (setLoading) {
            setLoading(false);
        }
    }, [projectUuid, setLoading, t, toast]);

    const fetchQuestion = useCallback(async () => {
        if (!(questionUuid && validationService.isValidUuid(questionUuid))) return;
        if (!(projectUuid && validationService.isValidUuid(projectUuid))) return;

        const response = await getQuestion(projectUuid, questionUuid, {
            includeAnswers: true,
            includeCompanyNames: true,
        } as GetClarificationQuestionRequestDto);

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("clarificationQuestions.api.getQuestionError"));
            setClarificationQuestion(null);
            return;
        }

        setClarificationQuestion(response.data);
    }, [projectUuid, questionUuid, t, toast]);

    const downloadClarificationsReport = async () => {
        if (!(projectUuid && validationService.isValidUuid(projectUuid))) return;
        const response = await getClarificationQuestionsReport(projectUuid);

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("clarificationQuestions.api.getClarificationQuestionsReport"));
            return;
        }

        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.download = t("clarificationQuestions.clarificationQuestionsReportFileName");
        link.click();
    };

    useEffect(() => {
        if (questionUuid) {
            fetchQuestion();
        } else {
            fetchQuestions();
        }
    }, [fetchQuestions, fetchQuestion, questionUuid]);

    return {
        clarificationQuestions,
        clarificationQuestion,
        downloadClarificationsReport,
    };
};

export default useProjectClarificationQuestions;
