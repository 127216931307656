import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBan, faEye, faFileExport, faMessageQuestion, faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { TextButton } from "@maistro/components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useLaunchBarStyles from "components/Layout/hooks/useLaunchBarStyles";
import TitleCtaType from "components/Layout/types/TitleCtaType";
import ReactGA from "react-ga4";
import stringService from "services/stringService";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

interface ITitleCtaProps {
    type: TitleCtaType;
    onClick?: () => void;
}

const TitleCta: React.FC<ITitleCtaProps> = (props) => {
    const classes = useLaunchBarStyles();

    const { type, onClick } = props;

    const { t } = useTranslation();

    const icon: IconProp = useMemo(() => {
        switch (type) {
            case TitleCtaType.AskQuestion:
                return faMessageQuestion;
            case TitleCtaType.CancelProject:
            case TitleCtaType.Cancel:
                return faBan;
            case TitleCtaType.Export:
                return faFileExport;
            case TitleCtaType.Refresh:
                return faRefresh;
            case TitleCtaType.ProjectPreview:
            default:
                return faEye;
        }
    }, [type]);

    const label: string = useMemo(() => {
        switch (type) {
            case TitleCtaType.AskQuestion:
                return t("common.askQuestion");
            case TitleCtaType.CancelProject:
                return t("projectBrief.cancel");
            case TitleCtaType.Cancel:
                return t("common.cancel");
            case TitleCtaType.Export:
                return t("common.export");
            case TitleCtaType.Refresh:
                return t("common.refresh");
            case TitleCtaType.ProjectPreview:
            default:
                return t("common.projectPreview");
        }
    }, [t, type]);

    const handleClick = () => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label,
        });
        if (onClick) onClick();
    };

    return (
        <TextButton
            className={classes.titleNav}
            label={label}
            icon={icon}
            onClick={handleClick}
            testid={`${stringService.toKebabCase(label)}-button`}
        />
    );
};

export default TitleCta;
