import { ICommonProps, Label, Popup } from "@maistro/components";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import FileList from "components/FileList/FileList";
import FileUploadField from "components/FileUploadField/FileUploadField";
import env from "config/EnvironmentVariables";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    fileListContainer: {
        marginTop: theme.spacing.small,
    },
    label: {
        display: "inline-block",
    },
}));

interface IDisplayVettingFileUploadField extends ICommonProps {
    setFileIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
    fileIsUploading: boolean;
    setFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    files?: Array<FileDto>;
    name: string;
    multiple?: boolean;
    required?: boolean;
    label?: string | ReactElement;
}

const DisplayVettingFileUploadField: React.FC<IDisplayVettingFileUploadField> = ({
    setFileIsUploading,
    setFiles,
    fileIsUploading,
    files,
    testid,
    name,
    multiple,
    required,
    label,
}) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const [isRemoveFilePopupOpen, setIsRemoveFilePopupOpen] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<FileDto | null>(null);

    const displayConfirmDeleteDialog = (file: FileDto): void => {
        setSelectedFile(file);
        setIsRemoveFilePopupOpen(true);
    };

    const removeVettingFile = () => {
        if (selectedFile) {
            setFiles((prevState) => prevState.filter((prevFile) => prevFile.fileId !== selectedFile.fileId));
        }
        setIsRemoveFilePopupOpen(false);
    };

    return (
        <div>
            <Label
                className={classes.label}
                content={label || t("companyVettingQuestionnaire.fileUpload.default")}
                name={name}
                required={required}
            />
            {files && files.length > 0 && (
                <div className={classes.fileListContainer}>
                    <FileList
                        files={files}
                        setFiles={setFiles}
                        canDelete
                        canDownload
                        testid={`${testid}-documents`}
                        removeFile={displayConfirmDeleteDialog}
                    />
                </div>
            )}
            <FileUploadField
                setFiles={setFiles}
                name={name}
                acceptedExtensions={env.SupportedProjectFileExtensions}
                testid={`${testid}-upload-file`}
                fileIsUploading={fileIsUploading}
                setFileIsUploading={setFileIsUploading}
                multiple={multiple}
                files={files}
            />
            <Popup
                title={t("popups.removeFile.title")}
                message={t("popups.removeFile.message", { fileName: selectedFile?.fileName })}
                isOpen={isRemoveFilePopupOpen}
                primaryActionText={t("popups.removeFile.cta.primary")}
                onPrimaryAction={removeVettingFile}
                onClose={() => setIsRemoveFilePopupOpen(false)}
                testid="confirm-remove-file-popup"
            />
        </div>
    );
};

export default DisplayVettingFileUploadField;
