import { useToaster } from "@maistro/components";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { changeUserPassword } from "api/usersApi";
import apiErrorService from "services/apiErrorService";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { ChangePasswordReqestDto } from "types/dtos/users/ChangePasswordReqestDto";

const useChangePassword = () => {
    const [isOpen, setIsOpen] = useState(false);

    const { t } = useTranslation();
    const toast = useToaster();

    const changePassword = useCallback(
        async (dto: ChangePasswordReqestDto) => {
            const response = await changeUserPassword(dto);
            if (response.data instanceof TransactionErrorDto || response.status !== 204) {
                if (response.status === 403) {
                    toast.error(t("users.changePassword.invalidPassword"));
                    return;
                }

                toast.error(apiErrorService.getFirstErrorFromResponse(response.data));
                return;
            }

            toast.success(t("users.changePassword.success"));
            setIsOpen(false);
        },
        [t, toast],
    );

    return { changePassword, isChangePasswordOpen: isOpen, setIsChangePasswordOpen: setIsOpen };
};

export default useChangePassword;
