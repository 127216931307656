import { Copy, Popup } from "@maistro/components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Loader } from "components";
import TitleCtaType from "components/Layout/types/TitleCtaType";
import ProjectPreviewDrawer from "features/project/components/ProjectPreviewDrawer";
import useNavTabs from "features/project/hooks/useNavTabs";
import useProjectFiles from "features/project/hooks/useProjectFiles";
import useProjectResponse from "features/project/hooks/useProjectResponse";
import { useSupplierTenderQuestions } from "features/project/supplierTenderResponse/hooks/useSupplierTenderQuestions";
import SupplierTenderQuestionsDisplay from "features/project/supplierTenderResponse/SupplierTenderQuestionsDisplay";
import useAppDispatch from "hooks/useAppDispatch";
import useCurrentUser from "hooks/useCurrentUser";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import dateTimeService from "services/dateTimeService";
import { resetLayout, setBack, setPageTitle, setSecondaryNavItems, setTitleCtas, setTooltip } from "store/layoutSlice";
import ProjectType from "types/enums/projects/ProjectType";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";
import SupplierSubmissionStatus from "types/enums/supplierSubmissions/SupplierSubmissionStatus";

const SupplierTenderQuestionsContainer: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [isCancelResponsePopupOpen, setIsCancelResponsePopupOpen] = useState(false);
    const [isWithdrawTenderPopupOpen, setIsWithdrawTenderPopupOpen] = useState(false);
    const [isUpdatingProjectStatus, setIsUpdatingProjectStatus] = useState(false);
    const [isProjectPreviewOpen, setIsProjectPreviewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { userHasPermission, userHasProjectPermission } = useCurrentUser();
    const { isFetching, projectResponseInformation, updateSupplierSubmissionStatus } = useProjectResponse();
    const { projectUuid, responseUuid } = projectResponseInformation;
    const { projectFiles } = useProjectFiles(projectUuid);
    const { supplierTabItems } = useNavTabs();
    const {
        answerQuestion,
        withdrawTender,
        cancelProjectResponse,
        sectionQuestions,
        sections,
        isSubmitting,
        calculateNextQuestionUuid,
    } = useSupplierTenderQuestions({
        projectUuid,
        responseUuid,
        setIsLoading,
    });

    const canAskQuestion = useMemo(() => {
        return userHasProjectPermission(PermissionsEnum.CanAskClarificationQuestion, projectUuid);
    }, [projectUuid, userHasProjectPermission]);

    if (!userHasPermission(PermissionsEnum.CanSupply)) {
        navigate(routes.common.dashboard.path);
    }

    useEffect(() => {
        dispatch(setPageTitle(t("supplierTenderQuestions.title")));
        dispatch(
            setTooltip([
                { text: t("supplierTenderQuestions.tooltipParagraph1") },
                { text: t("supplierTenderQuestions.tooltipParagraph2") },
                { text: t("supplierTenderQuestions.tooltipParagraph3") },
                { text: t("supplierTenderQuestions.tooltipParagraph4") },
            ]),
        );
        dispatch(setSecondaryNavItems(supplierTabItems));
        dispatch(
            setBack({
                route: buildRoute(routes.projectResponses.brief, { projectUuid, responseUuid }),
            }),
        );

        const titleCtas = [
            {
                type: TitleCtaType.ProjectPreview,
                onClick: () => setIsProjectPreviewOpen(true),
            },
        ];
        if (canAskQuestion) {
            titleCtas.push({
                type: TitleCtaType.AskQuestion,
                onClick: () =>
                    navigate(
                        buildPath(routes.projectResponses.askClarificationQuestion, { projectUuid, responseUuid }),
                    ),
            });
        }

        dispatch(setTitleCtas(titleCtas));

        return () => {
            dispatch(resetLayout());
        };
    }, [
        dispatch,
        projectUuid,
        responseUuid,
        t,
        supplierTabItems,
        projectFiles,
        projectResponseInformation,
        navigate,
        canAskQuestion,
    ]);

    useEffect(() => {
        if (isLoading || isFetching) {
            return;
        }

        if (projectResponseInformation.projectType !== ProjectType.Tender) {
            navigate(routes.common.dashboard.path);
        }
    });

    const canUpdateAnswers = !dateTimeService.dateIsInPast(projectResponseInformation.tenderResponseDeadline);

    const closeNotInterestedPopup = useCallback(() => {
        setIsCancelResponsePopupOpen(false);
    }, []);

    const closeWithdrawPopup = useCallback(() => {
        setIsWithdrawTenderPopupOpen(false);
    }, []);

    const openNotInterestedPopup = useCallback(() => {
        setIsCancelResponsePopupOpen(true);
    }, []);

    const openWithdrawPopup = useCallback(() => {
        setIsWithdrawTenderPopupOpen(true);
    }, []);

    const submitTender = useCallback(async () => {
        setIsUpdatingProjectStatus(true);
        await updateSupplierSubmissionStatus(
            SupplierSubmissionStatus.Submitted,
            buildPath(routes.projectResponses.thankYou, { projectUuid, responseUuid }),
            t("supplierTenderQuestions.submitTenderError"),
        );
        setIsUpdatingProjectStatus(false);
    }, [projectUuid, responseUuid, t, updateSupplierSubmissionStatus]);

    if (isLoading) {
        return <Loader />;
    }

    return sections.length > 0 ? (
        <React.Fragment>
            <SupplierTenderQuestionsDisplay
                answerQuestion={answerQuestion}
                sections={sections}
                sectionQuestions={sectionQuestions}
                submitTender={submitTender}
                cancelTender={openNotInterestedPopup}
                withdrawTender={openWithdrawPopup}
                canUpdate={canUpdateAnswers}
                isTenderSubmitting={isSubmitting}
                calculateNextQuestionUuid={calculateNextQuestionUuid}
                projectUuid={projectUuid}
                buyerCompanyUuid={projectResponseInformation.buyerCompanyUuid}
                projectResponseInformation={projectResponseInformation}
                isUpdatingProjectStatus={isUpdatingProjectStatus}
            />
            <Popup
                title={t("popups.projectResponse.title")}
                message={t("popups.projectResponse.message")}
                isOpen={isCancelResponsePopupOpen}
                primaryActionText={t("popups.projectResponse.cta.primary")}
                onPrimaryAction={cancelProjectResponse}
                secondaryActionText={t("popups.projectResponse.cta.secondary")}
                onSecondaryAction={closeNotInterestedPopup}
                onClose={closeNotInterestedPopup}
                testid="not-interested-popup"
                disabled={isSubmitting}
            />
            <Popup
                title={t("popups.supplierTenderQuestions.withdrawTender.title")}
                message={t("popups.supplierTenderQuestions.withdrawTender.message")}
                isOpen={isWithdrawTenderPopupOpen}
                primaryActionText={t("popups.supplierTenderQuestions.withdrawTender.cta.primary")}
                onPrimaryAction={withdrawTender}
                secondaryActionText={t("common.cancel")}
                onSecondaryAction={closeWithdrawPopup}
                onClose={closeWithdrawPopup}
                testid="withdraw-tender-popup"
                disabled={isSubmitting}
            />
            <ProjectPreviewDrawer
                projectResponseInformation={projectResponseInformation}
                projectFiles={projectFiles.filter(
                    (file) =>
                        file.linkedCompanyUuid === projectResponseInformation.buyerCompanyUuid ||
                        file.linkedCompanyUuid == null,
                )}
                isOpen={isProjectPreviewOpen}
                close={() => setIsProjectPreviewOpen(false)}
            />
        </React.Fragment>
    ) : (
        <Copy variant="error" testid="no-sections-error">
            {t("supplierTenderQuestions.noSections")}
        </Copy>
    );
};

export default SupplierTenderQuestionsContainer;
