import { useToaster } from "@maistro/components";
import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { listGuidedQuestions } from "api/questions/guidedQuestionsApi";
import validationService from "services/validationService";
import { GuidedQuestionResponseDto } from "types/dtos/questions/GuidedQuestionResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const useGuidedQuestionQuestions = (projectUuid?: string, setLoading?: (isLoading: boolean) => void) => {
    const [guidedQuestions, setGuidedQuestions] = useState<Array<GuidedQuestionResponseDto>>([]);

    const { t } = useTranslation();
    const toast = useToaster();

    const fetchQuestions = useCallback(async () => {
        if (!(projectUuid && validationService.isValidUuid(projectUuid))) return;

        const response = await listGuidedQuestions(projectUuid);

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("generateProjectDescription.api.fetchQuestionsError"));
            setGuidedQuestions([]);
            return;
        }

        setGuidedQuestions(response.data.items);

        if (setLoading) {
            setLoading(false);
        }
    }, [projectUuid, setLoading, t, toast]);

    useEffect(() => {
        fetchQuestions();
    }, [fetchQuestions]);

    return {
        guidedQuestions,
    };
};

export default useGuidedQuestionQuestions;
