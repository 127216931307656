import company from "routes/routePaths/CompanyRoutePaths";
import common from "routes/routePaths/CommonRoutePaths";
import projectResponses from "routes/routePaths/ProjectResponseRoutePaths";
import projects from "routes/routePaths/ProjectRoutePaths";
import publicRoutes from "routes/routePaths/PublicRoutePaths";
import users from "routes/routePaths/UserRoutePaths";

const routes = { common, company, projectResponses, projects, public: publicRoutes, users };

export default routes;
