import {
    Button,
    CheckboxField,
    DateField,
    Heading,
    ICommonProps,
    SelectField,
    TextAreaField,
    TextField,
} from "@maistro/components";
import BuyerSupplierContractUploadField from "features/company/profile/suppliers/BuyerSupplierContracts/BuyerSupplierContractUploadField";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { ITheme } from "styles/themes/types";
import * as Yup from "yup";

import useContractsForm from "features/company/profile/suppliers/BuyerSupplierContracts/hooks/useContractsForm";
import ReactGA from "react-ga4";
import BuyerSupplierContractDto from "types/dtos/company/buyer-supplier-relationships/BuyerSupplierContractDto";
import CurrencyType from "types/enums/CurrencyType";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
        marginTop: theme.spacing.large,
    },
    buttons: {
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing.medium,
        marginTop: theme.spacing.medium,
    },
}));

type BuyerSupplierContractsFormProps = ICommonProps & {
    initialContract?: BuyerSupplierContractDto;
    onSubmit: (value: BuyerSupplierContractDto) => Promise<void>;
    onCancel: () => void;
    isSaving: boolean;
    buyerUuid: string;
};

const minDate = new Date();

const BuyerSupplierContractsForm: React.FC<BuyerSupplierContractsFormProps> = ({
    className,
    initialContract,
    onSubmit,
    onCancel,
    isSaving,
    buyerUuid,
}) => {
    const styles = useStyles();
    const { t } = useTranslation();

    const { contractFiles, setContractFiles, companyUsers, setCompanyUsersSearchText } = useContractsForm(
        buyerUuid,
        initialContract,
    );

    const validationSchema = Yup.object({
        title: Yup.string().required(t("companySuppliers.contracts.required")),
        contractType: Yup.string(),
        contractOwnerUuid: Yup.string(),
        department: Yup.string(),
        startDate: Yup.date().nullable(),
        expiryDate: Yup.date().nullable(),
        nextPossibleTerminationDate: Yup.date().nullable(),
        terminationForConvenience: Yup.boolean(),
        isTerminated: Yup.boolean(),
        contractValue: Yup.number(),
        liabilitiesCap: Yup.number(),
        currencyType: Yup.string(),
        notes: Yup.string(),
    });

    return (
        <div className={className}>
            <Heading variant="h2">
                {initialContract
                    ? t("companySuppliers.contracts.editContract")
                    : t("companySuppliers.contracts.addContract")}
            </Heading>
            <Formik
                initialValues={
                    {
                        uuid: initialContract?.uuid,
                        title: initialContract?.title ?? "",
                        contractType: initialContract?.contractType ?? "",
                        startDate: initialContract?.startDate ?? undefined,
                        expiryDate: initialContract?.expiryDate ?? undefined,
                        contractOwnerUuid: initialContract?.contractOwnerUuid ?? "",
                        department: initialContract?.department ?? "",
                        nextPossibleTerminationDate: initialContract?.nextPossibleTerminationDate ?? undefined,
                        terminationForConvenience: initialContract?.terminationForConvenience ?? false,
                        isTerminated: initialContract?.isTerminated ?? false,
                        contractValue: initialContract?.contractValue ?? "",
                        liabilitiesCap: initialContract?.liabilitiesCap ?? "",
                        currencyType: initialContract?.currencyType ?? CurrencyType.GBP,
                        notes: initialContract?.notes ?? "",
                    } as BuyerSupplierContractDto
                }
                onSubmit={(values, { resetForm }) =>
                    onSubmit({
                        ...values,
                        files: contractFiles,
                    }).then(() => {
                        resetForm();
                        setContractFiles([]);
                    })
                }
                validationSchema={validationSchema}
                enableReinitialize
            >
                {({ dirty, isValid, values }) => (
                    <Form className={styles.form}>
                        <TextField
                            testid="contract-title-field"
                            label={t("companySuppliers.contracts.labels.title")}
                            name="title"
                            required
                        />
                        <TextField
                            testid="contract-type-field"
                            label={t("companySuppliers.contracts.labels.contractType")}
                            name="contractType"
                        />
                        <DateField
                            testid="start-date-field"
                            label={t("companySuppliers.contracts.labels.startDate")}
                            name="startDate"
                            fullWidth
                        />
                        <DateField
                            testid="expiry-date-field"
                            label={t("companySuppliers.contracts.labels.expiryDate")}
                            name="expiryDate"
                            fullWidth
                        />
                        <SelectField
                            testid="contract-owner-field"
                            name="contractOwnerUuid"
                            label={t("companySuppliers.contracts.labels.contractOwner")}
                            options={companyUsers.map((user) => ({
                                value: user.userUuid,
                                label: `${user.firstName} ${user.lastName}`,
                            }))}
                            onInputChange={(value) => setCompanyUsersSearchText(value)}
                            withoutValidation
                        />
                        <TextField
                            testid="contract-department-field"
                            label={t("companySuppliers.contracts.labels.department")}
                            name="department"
                        />
                        <DateField
                            testid="next-possible-termination-date-field"
                            label={t("companySuppliers.contracts.labels.nextPossibleTerminationDate")}
                            name="nextPossibleTerminationDate"
                            minDate={minDate}
                            fullWidth
                        />
                        <CheckboxField
                            testid="termination-for-convenience-field"
                            name="terminationForConvenience"
                            label={t("companySuppliers.contracts.labels.terminationForConvenience")}
                        />
                        <CheckboxField
                            testid="is-terminated-field"
                            name="isTerminated"
                            label={t("companySuppliers.contracts.labels.isTerminated")}
                        />
                        <SelectField
                            testid="currency-selector"
                            label={t("common.currency")}
                            name="currencyType"
                            options={[
                                {
                                    value: CurrencyType.GBP.toString(),
                                    label: "£ (GBP)",
                                },
                                {
                                    value: CurrencyType.EUR.toString(),
                                    label: "€ (EUR)",
                                },
                                {
                                    value: CurrencyType.USD.toString(),
                                    label: "$ (USD)",
                                },
                            ]}
                        />
                        <TextField
                            testid="contract-value-field"
                            label={t("companySuppliers.contracts.labels.contractValue")}
                            name="contractValue"
                            type="price"
                            currencyType={values.currencyType}
                        />
                        <TextField
                            testid="liabilities-cap-field"
                            label={t("companySuppliers.contracts.labels.liabilitiesCap")}
                            name="liabilitiesCap"
                            type="price"
                            currencyType={values.currencyType}
                        />
                        <TextAreaField
                            name="notes"
                            label={t("companySuppliers.contracts.labels.notes")}
                            testid="notes-field"
                            rows={2}
                        />
                        <BuyerSupplierContractUploadField
                            files={contractFiles}
                            setFiles={setContractFiles}
                            name="files"
                            testid="contract"
                        />
                        <div className={styles.buttons}>
                            <Button
                                testid="cancel-button"
                                onClick={() => {
                                    ReactGA.event({
                                        category: ga4Category.Button,
                                        action: ga4Action.ButtonClick,
                                        label: `Contracts - Add Contract - Cancel`,
                                    });
                                    onCancel();
                                }}
                                variant="outline"
                                label={t("common.cancel")}
                            />
                            <Button
                                testid="save-contract-button"
                                type="submit"
                                label={t("companySuppliers.contracts.saveContract")}
                                disabled={!dirty || !(isValid && contractFiles.length > 0) || isSaving}
                                onClick={() =>
                                    ReactGA.event({
                                        category: ga4Category.Button,
                                        action: ga4Action.ButtonClick,
                                        label: `Contracts - Add Contract - Submit`,
                                    })
                                }
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default BuyerSupplierContractsForm;
