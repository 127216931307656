import { ISectionQuestions } from "types/dtos/questions/ISectionQuestions";

export const containsMandatoryQuestions = (questions?: ISectionQuestions) => {
    return !!questions?.items?.some((i) => i.isConditional);
};

export const isSectionWithMandatoryQuestions = (section: string, sectionQuestions: ISectionQuestions[]) => {
    const questions = sectionQuestions.find((el) => el.id === section);
    if (questions === undefined) {
        return false;
    }
    return containsMandatoryQuestions(questions);
};

export const listTenderCriteriaQuestions = (sectionId: string, sectionQuestions: ISectionQuestions[]) => {
    const questions = sectionQuestions.find((el) => el.id === sectionId);
    if (!questions?.items || questions.items.length === 0) {
        return [];
    }
    return questions.items;
};
