import { AxiosResponse } from "axios";

import env from "config/EnvironmentVariables";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { CreateFeedbackDto } from "types/dtos/feedback/CreateFeedbackDto";
import { CreateFeedbackResponseDto } from "types/dtos/feedback/CreateFeedbackResponseDto";
import { createAxiosInstance } from "api/commonApi";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/feedback";

// eslint-disable-next-line import/prefer-default-export
export const createNpsFeedback = (
    request: CreateFeedbackDto,
): Promise<AxiosResponse<CreateFeedbackResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/emailsurveys/nps`;
    return api.post(url, request);
};
