import { LoadingScreen, useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { getProjectShortlistingReport } from "api/reportingApi";
import CompleteProjectWithoutAwardDisplay from "features/project/completeWithoutAward/CompleteProjectWithoutAwardDisplay";
import useAppDispatch from "hooks/useAppDispatch";
import validationService from "services/validationService";
import { resetLayout, setPageTitle } from "store/layoutSlice";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const CompleteProjectWithoutAwardContainer: React.FC = () => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [loadingTitle, setLoadingTitle] = useState("");
    const [loadingMessage, setLoadingMessage] = useState("");

    const { projectUuid } = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const toast = useToaster();

    useEffect(() => {
        dispatch(resetLayout());
        dispatch(setPageTitle(t("completeProject.withoutAward.title")));
    }, [dispatch, t]);

    const downloadShortlisting = useCallback(async () => {
        if (!(projectUuid && validationService.isValidUuid(projectUuid))) return;
        const response = await getProjectShortlistingReport(projectUuid);

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("projectBrief.api.errorDownloadProject"));
            return;
        }

        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.download = t("completeProject.withoutAward.projectWithoutAwardReportFileName");
        link.click();
    }, [projectUuid, t, toast]);

    const handleDownloadShortlistingReport = () => {
        setLoadingTitle(t("awardSummary.projectSummary.title"));
        setLoadingMessage(t("awardSummary.projectSummary.text"));
        setIsDownloading(true);
        downloadShortlisting().finally(() => setIsDownloading(false));
    };

    return (
        <>
            {isDownloading && (
                <LoadingScreen title={loadingTitle} message={loadingMessage} duration={35000} testid="loading-screen" />
            )}
            <CompleteProjectWithoutAwardDisplay downloadShortlistingReport={handleDownloadShortlistingReport} />
        </>
    );
};

export default CompleteProjectWithoutAwardContainer;
