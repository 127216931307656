import { IPaginationState, ISortBy, SortOrder, useToaster } from "@maistro/components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { searchCompanies } from "api/company/companyApi";
import useSupplierListFilters, {
    ISupplierListFilters,
} from "features/company/profile/suppliers/filter/hooks/useSupplierListFilter";
import validationService from "services/validationService";
import {
    useAddSuppliersToSupplierListApiMutation,
    useLazySearchBuyerSupplierRelationshipsApiQuery,
    useRemoveSupplierFromSupplierListApiMutation,
    useUpdateSupplierExternalIdApiMutation,
    useUpdateSupplierRelationshipStatusApiMutation,
    useUpdateSupplierTieringStatusApiMutation,
} from "store/api/buyerSupplierRelationshipsApi";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { CompanyListFilterDto } from "types/dtos/company/CompanyListFilterDto";
import { AddSuppliersToSupplierListRequestDto } from "types/dtos/company/buyer-supplier-relationships/AddSuppliersToSupplierListRequestDto";
import { BuyerSupplierRelationshipDto } from "types/dtos/company/buyer-supplier-relationships/BuyerSupplierRelationshipDto";
import { RemoveSupplierFromSupplierListRequestDto } from "types/dtos/company/buyer-supplier-relationships/RemoveSupplierFromSupplierListRequestDto";
import { SearchBuyerSupplierRelationshipsRequestDto } from "types/dtos/company/buyer-supplier-relationships/SearchBuyerSupplierRelationshipsRequestDto";
import { UpdateSupplierExternalIdRequestDto } from "types/dtos/company/buyer-supplier-relationships/UpdateSupplierExternalIdRequestDto";
import { UpdateSupplierRelationshipStatusRequestDto } from "types/dtos/company/buyer-supplier-relationships/UpdateSupplierRelationshipStatusRequestDto";
import { UpdateSupplierTieringStatusRequestDto } from "types/dtos/company/buyer-supplier-relationships/UpdateSupplierTieringStatusRequestDto";

const useSupplierList = (setIsLoading: (isLoading: boolean) => void, companyUuid?: string) => {
    const [supplierList, setSupplierList] = useState<Array<BuyerSupplierRelationshipDto>>([]);
    const [sortBy, setSortBy] = useState<ISortBy>({
        column: "companyName",
        order: SortOrder.Ascending,
    });
    const [disableSorting, setDisableSorting] = useState(false);
    const { currentFilters, setCurrentFilters } = useSupplierListFilters();

    const [searchBuyerSupplierRelationshipsApi, { isLoading: isSearching }] =
        useLazySearchBuyerSupplierRelationshipsApiQuery();
    const [updateSupplierRelationshipStatusApi] = useUpdateSupplierRelationshipStatusApiMutation();
    const [updateSupplierExternalIdApi] = useUpdateSupplierExternalIdApiMutation();
    const [updateSupplierTieringStatusApi] = useUpdateSupplierTieringStatusApiMutation();
    const [removeSupplierFromSupplierListApi] = useRemoveSupplierFromSupplierListApiMutation();
    const [addSuppliersToSupplierListApi] = useAddSuppliersToSupplierListApiMutation();

    const initialPageState = useMemo(
        () => ({
            currentPage: 1,
            itemsPerPage: 10,
            totalItems: 0,
        }),
        [],
    );

    const [paginationState, setPaginationState] = useState<IPaginationState>(initialPageState);

    const { t } = useTranslation();
    const toast = useToaster();

    const fetchSuppliers = useCallback(async () => {
        if (!(companyUuid && validationService.isValidUuid(companyUuid))) return;

        if (supplierList.length === 0) {
            setIsLoading(true);
        }

        searchBuyerSupplierRelationshipsApi({
            companyUuid,
            textSearch: currentFilters.searchTerm,
            supplierRelationshipStatusesSearch: currentFilters.relationshipStatuses.join(","),
            supplierTieringStatusesSearch: currentFilters.tieringStatuses.join(","),
            supplierActiveStatusesSearch: currentFilters.activeStatuses.join(","),
            skip: (paginationState.currentPage - 1) * paginationState.itemsPerPage,
            take: paginationState.itemsPerPage,
            orderByColumn: sortBy.column,
            sortDescending: sortBy.order === SortOrder.Descending,
        } as SearchBuyerSupplierRelationshipsRequestDto)
            .unwrap()
            .then((response) => {
                setSupplierList(response.items);
                setPaginationState({
                    currentPage: paginationState.currentPage,
                    itemsPerPage: paginationState.itemsPerPage,
                    totalItems: response.totalCount,
                });

                setIsLoading(false);
            })
            .catch(() => {
                toast.error(t("companySuppliers.api.searchSuppliersError"));
                setSupplierList([]);
                setIsLoading(false);
            });
    }, [
        companyUuid,
        currentFilters,
        paginationState.currentPage,
        paginationState.itemsPerPage,
        setIsLoading,
        sortBy,
        supplierList.length,
        searchBuyerSupplierRelationshipsApi,
        t,
        toast,
    ]);

    const navigateToPage = (page: number) => {
        setPaginationState({ ...paginationState, currentPage: page });
    };

    const setFilters = (filters: ISupplierListFilters) => {
        if (filters.searchTerm && filters.searchTerm.length > 0) {
            setDisableSorting(true);
        } else {
            setDisableSorting(false);
        }
        setCurrentFilters(filters);
    };

    useEffect(() => {
        fetchSuppliers();
    }, [fetchSuppliers]);

    const updateSupplierRelationshipStatus = useCallback(
        async (supplier: BuyerSupplierRelationshipDto) => {
            if (!(companyUuid && validationService.isValidUuid(companyUuid))) return;

            updateSupplierRelationshipStatusApi({
                buyerUuid: companyUuid,
                supplierUuid: supplier.companyUuid,
                supplierRelationshipStatus: supplier.supplierRelationshipStatus,
            } as UpdateSupplierRelationshipStatusRequestDto)
                .unwrap()
                .then(() => {
                    toast.success(t("companySuppliers.api.updateRelationshipStatusSuccess"));
                    fetchSuppliers();
                })
                .catch(() => {
                    toast.error(t("companySuppliers.api.updateRelationshipStatusError"));
                });
        },
        [companyUuid, fetchSuppliers, t, toast, updateSupplierRelationshipStatusApi],
    );

    const updateSupplierExternalId = useCallback(
        async (supplier: BuyerSupplierRelationshipDto) => {
            if (!(companyUuid && validationService.isValidUuid(companyUuid))) return;

            updateSupplierExternalIdApi({
                buyerUuid: companyUuid,
                supplierUuid: supplier.companyUuid,
                externalId: supplier.externalId,
            } as UpdateSupplierExternalIdRequestDto)
                .unwrap()
                .then(() => {
                    toast.success(t("companySuppliers.api.updateExternalIdSuccess"));
                    fetchSuppliers();
                })
                .catch(() => {
                    toast.error(t("companySuppliers.api.updateExternalIdError"));
                });
        },
        [companyUuid, fetchSuppliers, t, toast, updateSupplierExternalIdApi],
    );

    const updateSupplierTieringStatus = useCallback(
        async (supplier: BuyerSupplierRelationshipDto) => {
            if (!(companyUuid && validationService.isValidUuid(companyUuid))) return;

            updateSupplierTieringStatusApi({
                buyerUuid: companyUuid,
                supplierUuid: supplier.companyUuid,
                supplierTieringStatus: supplier.supplierTieringStatus,
            } as UpdateSupplierTieringStatusRequestDto)
                .unwrap()
                .then(() => {
                    toast.success(t("companySuppliers.api.updateTieringStatusSuccess"));
                    fetchSuppliers();
                })
                .catch(() => {
                    toast.error(t("companySuppliers.api.updateTieringStatusError"));
                });
        },
        [companyUuid, fetchSuppliers, t, toast, updateSupplierTieringStatusApi],
    );

    const removeSupplierFromSupplierList = useCallback(
        async (supplier: BuyerSupplierRelationshipDto) => {
            if (!(companyUuid && validationService.isValidUuid(companyUuid))) return;

            removeSupplierFromSupplierListApi({
                buyerUuid: companyUuid,
                supplierUuid: supplier.companyUuid,
            } as RemoveSupplierFromSupplierListRequestDto)
                .unwrap()
                .then(() => {
                    toast.success(
                        t("companySuppliers.api.removeSupplierSuccess", { SupplierName: supplier.companyName }),
                    );
                    fetchSuppliers();
                })
                .catch(() => {
                    toast.error(t("companySuppliers.api.removeSupplierError"));
                });
        },
        [companyUuid, fetchSuppliers, t, toast, removeSupplierFromSupplierListApi],
    );

    const addSuppliersToSupplierList = useCallback(
        async (supplierUuids: string[]) => {
            if (!(companyUuid && validationService.isValidUuid(companyUuid))) return;

            addSuppliersToSupplierListApi({
                buyerUuid: companyUuid,
                supplierUuids,
            } as AddSuppliersToSupplierListRequestDto)
                .unwrap()
                .then(() => {
                    toast.success(
                        t("companySuppliers.api.addSupplierSuccess", { SupplierCount: supplierUuids.length }),
                    );
                    setPaginationState(initialPageState);
                    fetchSuppliers();
                })
                .catch(() => {
                    toast.error(t("companySuppliers.api.addSupplierError"));
                });
        },
        [companyUuid, fetchSuppliers, t, toast, addSuppliersToSupplierListApi, initialPageState],
    );

    const searchForUnlistedSuppliers = useCallback(
        async (searchQuery: string) => {
            const searchResponse = await searchCompanies(
                0,
                50,
                { column: "CompanyName", order: SortOrder.Descending } as ISortBy,
                {
                    searchTerm: searchQuery,
                    excludeSupplierListBuyerUuid: companyUuid,
                    companyTypes: ["Supplier"],
                } as CompanyListFilterDto,
            );
            if (searchResponse.status === 200 && !(searchResponse.data instanceof TransactionErrorDto)) {
                return searchResponse.data.items;
            }
            toast.error(t("companySuppliers.api.searchCompaniesError"));
            return [];
        },
        [companyUuid, t, toast],
    );

    return {
        isSearching,
        navigateToPage,
        paginationState,
        supplierList,
        updateSupplierRelationshipStatus,
        updateSupplierTieringStatus,
        updateSupplierExternalId,
        addSuppliersToSupplierList,
        removeSupplierFromSupplierList,
        searchForUnlistedSuppliers,
        setSortBy,
        sortBy,
        currentFilters,
        setFilters,
        disableSorting,
    };
};

export default useSupplierList;
