import { generatePath } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/ban-types
export interface Route<T = {}> {
    path: string;
    fullPath?: string;
    params?: T;
    translationKey?: string;
}

export function from<A, B>(parent: Route<A>, child: Route<B>) {
    const route: Route<A & B> = {
        ...child,
        fullPath: `${parent.fullPath ?? parent.path}${child.path}`,
        params: { ...parent.params, ...child.params } as A & B,
    };

    return route;
}

export function buildPath<T>(route: Route<T>, params?: T) {
    return generatePath(route.fullPath ?? route.path, params && Object.fromEntries(Object.entries(params)));
}

export function buildRoute<T>(route: Route<T>, params?: T) {
    return {
        ...route,
        params,
    };
}
