import { createUseStyles } from "react-jss";

import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    launchBar: {
        display: "grid",
        gridTemplateAreas: '"secondaryNav" "actions" "header"',
        gridTemplateColumns: "minmax(0, 1fr)",

        backgroundColor: theme.colors.primary,
        fontSize: 12,
    },

    header: {
        backgroundColor: theme.colors.lightGray,
        borderRadius: `${theme.spacing.large}px ${theme.spacing.large}px 0 0`,
        padding: theme.spacing.small,

        gridArea: "header",
        display: "grid",
        gridTemplateAreas: '"banner" "title" "searchBar"',
        gridTemplateColumns: "minmax(0, 1fr)",
    },

    title: {
        gridArea: "title",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: theme.spacing.large,
    },

    titleNav: {
        margin: `${theme.spacing.small}px 0`,
    },

    titleNavContainer: {
        display: "flex",
        gap: theme.spacing.large,
    },

    actions: {
        gridArea: "actions",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: theme.spacing.small,
        color: theme.colors.white,

        "&.noContent": {
            paddingLeft: 0,
            margin: 0,
        },
    },

    banner: {
        gridArea: "banner",
        marginBottom: theme.spacing.small,
    },

    secondaryNav: {
        gridArea: "secondaryNav",
    },

    searchBar: {
        gridArea: "searchBar",
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.colors.lightGray,
        padding: `0 ${theme.spacing.small}px ${theme.spacing.small}px`,
    },

    link: {
        fontWeight: 500,
        cursor: "pointer",
    },

    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        actions: {
            "&.onlyCTA": {
                paddingLeft: 0,
                margin: 0,
            },
        },
    },

    [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        launchBar: {
            gridTemplateAreas: '"banner" "secondaryNav" "header"',
            marginTop: theme.layout.navBarHeight,
            paddingTop: theme.spacing.small,
            backgroundColor: theme.colors.lightGray,
        },
        header: {
            gridTemplateAreas: '"actions" "title" "searchBar"',
            borderRadius: 0,
        },
        titleNav: {
            color: theme.colors.primary,
        },
        actions: {
            color: theme.colors.primary,
            margin: 0,
            marginBottom: theme.spacing.small,
        },
        secondaryNav: {
            marginBottom: theme.spacing.small,
        },
        link: {
            display: "flex",
            alignItems: "center",
            gap: theme.spacing.xSmall,

            "&:hover": {
                color: theme.colors.highlight,
            },
        },
    },

    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        header: {
            gridTemplateAreas: '"title searchBar actions"',
            columnGap: theme.spacing.small,
        },
        actions: {
            justifyContent: "right",
            gap: theme.spacing.medium,
            marginBottom: 0,
        },
        searchBar: {
            padding: 0,
        },
    },
}));

const useLaunchBarStyles = () => useStyles();

export default useLaunchBarStyles;
