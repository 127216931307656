import { useToaster } from "@maistro/components";
import { saveAs } from "file-saver";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import XLSX from "sheetjs-style";

import { listExpressionOfInterest } from "api/expressionOfInterestApi";
import { Loader } from "components";
import TitleCtaType from "components/Layout/types/TitleCtaType";
import ProjectPreviewDrawer from "features/project/components/ProjectPreviewDrawer";
import useSuppliersConfiguration from "features/project/expressionOfInterest/hooks/useSuppliersConfiguration";
import ResultsDisplay from "features/project/expressionOfInterest/results/ResultsDisplay";
import useProject from "features/project/hooks/useProject";
import useAppDispatch from "hooks/useAppDispatch";
import dateTimeService from "services/dateTimeService";
import { resetLayout, setBack, setCtas, setPageTitle, setTitleCtas } from "store/layoutSlice";
import { ExpressionOfInterestResponseDto } from "types/dtos/projects/expressionOfInterest/ExpressionOfInterestResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const ResultsContainer: React.FC = () => {
    const { industryTypes, companySizes } = useSuppliersConfiguration(false);
    const [suppliers, setSuppliers] = useState<ExpressionOfInterestResponseDto[]>([]);
    const [isFetchingSuppliers, setIsFetchingSuppliers] = useState(false);
    const [isProjectPreviewOpen, setIsProjectPreviewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const toast = useToaster();

    const { projectInformation, isFetching: isFetchingProject } = useProject();

    const fetchData = useCallback(async () => {
        if (!projectInformation.uuid) {
            return;
        }

        setIsFetchingSuppliers(true);
        const response = await listExpressionOfInterest(projectInformation.uuid);
        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("expressionOfInterest.monitor.api.fetchSubmissionsError"));
            return;
        }

        setSuppliers(
            response.data.responses.map((supplier) => {
                return {
                    ...supplier,
                    industryType: industryTypes.find((type) => type.value === supplier.industryTypeId.toString())
                        ?.label,
                    companySize: companySizes.find((size) => size.value === supplier.companySizeId.toString())?.label,
                };
            }),
        );

        setIsFetchingSuppliers(false);
    }, [companySizes, industryTypes, projectInformation.uuid, t, toast]);

    useEffect(() => {
        dispatch(setBack(null));
        dispatch(setCtas(null));
        dispatch(setPageTitle(t("expressionOfInterest.results.pageTitle")));
        dispatch(
            setTitleCtas([
                {
                    type: TitleCtaType.ProjectPreview,
                    onClick: () => setIsProjectPreviewOpen(true),
                },
            ]),
        );

        return () => {
            dispatch(resetLayout());
        };
    }, [dispatch, projectInformation, t]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (!isFetchingProject && !isFetchingSuppliers) {
            setIsLoading(false);
        }
    }, [isFetchingProject, isFetchingSuppliers]);

    const exportSupplierData = (supplierExportData: ExpressionOfInterestResponseDto[]) => {
        const dataToExport = supplierExportData.map((x) => {
            return {
                [t("expressionOfInterest.monitor.table.companyName")]: x.companyName,
                [t("expressionOfInterest.monitor.table.createdOn")]: dateTimeService.toShortDateTimeFormat(x.createdOn),
                [t("expressionOfInterest.monitor.table.industryType")]: industryTypes.filter(
                    (y) => y.value === x.industryTypeId.toString(),
                )[0].label,
                [t("expressionOfInterest.monitor.table.website")]: x.website,
                [t("expressionOfInterest.monitor.table.companyRegistrationNumber")]: x.companyRegistrationNumber,
                [t("expressionOfInterest.monitor.table.vatRegistrationNumber")]: x.vatRegistrationNumber,
                [t("expressionOfInterest.monitor.table.emailAddress")]: x.emailAddress,
                [t("expressionOfInterest.monitor.table.companyAddress")]: x.companyAddress,
                [t("expressionOfInterest.monitor.table.postCode")]: x.postCode,
                [t("expressionOfInterest.monitor.table.townCity")]: x.townCity,
                [t("expressionOfInterest.monitor.table.telephoneNumber")]: x.telephoneNumber,
                [t("expressionOfInterest.monitor.table.companySummary")]: x.companySummary,
                [t("expressionOfInterest.monitor.table.companySize")]: companySizes.filter(
                    (y) => y.value === x.companySizeId.toString(),
                )[0].label,
            };
        });

        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheethtml.sheet;charset=UTF-8";
        const workSheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = { Sheets: { "Shortlisted Suppliers": workSheet }, SheetNames: ["Shortlisted Suppliers"] };
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        saveAs(data, `${projectInformation.projectName}-ShortlistedSuppliers.xlsx`);
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <React.Fragment>
            <ResultsDisplay suppliers={suppliers.filter((x) => x.isSelected)} exportSupplierData={exportSupplierData} />
            <ProjectPreviewDrawer isOpen={isProjectPreviewOpen} close={() => setIsProjectPreviewOpen(false)} />
        </React.Fragment>
    );
};

export default ResultsContainer;
