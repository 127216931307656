import { ReadOnlyField } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { Paper } from "components";
import { ICommonProps } from "components/shared";
import dateTimeService from "services/dateTimeService";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    grid: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
}));

interface IProjectInformationDisplayProps extends ICommonProps {
    projectName: string;
    responseDate?: Date;
}

const ProjectInformationDisplay: React.FC<IProjectInformationDisplayProps> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const { projectName, responseDate } = props;

    return (
        <Paper>
            <div className={classes.grid}>
                <ReadOnlyField
                    label={t("clarificationQuestions.projectName")}
                    value={projectName}
                    testid="project-information-name"
                />
                <ReadOnlyField
                    label={t("clarificationQuestions.responseDate")}
                    value={
                        <span>
                            {dateTimeService.toShortDateFormat(responseDate)}&emsp;|&emsp;
                            {dateTimeService.timeLeftUntil(responseDate)}
                        </span>
                    }
                    testid="project-information-response-date"
                />
            </div>
        </Paper>
    );
};

export default ProjectInformationDisplay;
