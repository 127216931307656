import { AxiosResponse } from "axios";

import { createAxiosInstance } from "api/commonApi";
import env from "config/EnvironmentVariables";
import { CreateScoreRequestDto } from "types/dtos/questions/CreateScoreRequestDto";
import { CreateTenderCriteriaQuestionRequestDto } from "types/dtos/questions/CreateTenderCriteriaQuestionRequestDto";
import { IsEntityScoringCompleteResponseDto } from "types/dtos/questions/IsEntityScoringCompleteResponseDto";
import { ListQuestionsResponseDto } from "types/dtos/questions/ListQuestionsResponseDto";
import { OverrideScoreRequestDto } from "types/dtos/questions/OverrideScoreRequestDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { ScoreResponseDto } from "types/dtos/questions/ScoreResponseDto";
import { ScorerResponseDto } from "types/dtos/questions/ScorerResponseDto";
import { DeleteProjectSectionRequestDto } from "types/dtos/questions/sections/DeleteProjectSectionRequestDto";
import { GetProjectSectionsResponseDto } from "types/dtos/questions/sections/GetProjectSectionsResponseDto";
import { ListSectionImportanceLevelsResponseDto } from "types/dtos/questions/sections/ListSectionImportanceLevelsResponseDto";
import { ListSectionTypesResponseDto } from "types/dtos/questions/sections/ListSectionTypesResponseDto";
import { UpdateProjectSectionWeightingsRequestDto } from "types/dtos/questions/sections/UpdateProjectSectionWeightingsRequestDto";
import { UpdateSelectedProjectSectionsRequestDto } from "types/dtos/questions/sections/UpdateSelectedProjectSectionsRequestDto";
import { UpdateScoreRequestDto } from "types/dtos/questions/UpdateScoreRequestDto";
import { UpdateTenderCriteriaQuestionRequestDto } from "types/dtos/questions/UpdateTenderCriteriaQuestionRequestDto";
import { UpdateTenderCriteriaQuestionsRequestDto } from "types/dtos/questions/UpdateTenderCriteriaQuestionsRequestDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import EntityType from "types/enums/questions/EntityType";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/projects";
const questionType = "tender-criteria-questions";

export const createQuestion = (
    projectUuid: string,
    request: CreateTenderCriteriaQuestionRequestDto,
): Promise<AxiosResponse<QuestionResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/${questionType}`;
    return api.post(url, request);
};

export const updateQuestion = (
    projectUuid: string,
    questionUuid: string,
    questionDto: UpdateTenderCriteriaQuestionRequestDto,
) => {
    const url = `${baseApiUrl}/${projectUuid}/${questionType}/${questionUuid}`;
    return api.patch(url, questionDto);
};

export const updateQuestions = (projectUuid: string, items: UpdateTenderCriteriaQuestionsRequestDto) => {
    const url = `${baseApiUrl}/${projectUuid}/${questionType}`;
    return api.patch(url, items);
};

export const deleteQuestion = (projectUuid: string, questionUuid: string) => {
    const url = `${baseApiUrl}/${projectUuid}/${questionType}/${questionUuid}`;
    return api.delete(url);
};

export const listQuestions = (
    projectUuid: string,
): Promise<AxiosResponse<ListQuestionsResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/${questionType}`;
    return api.get(url, {
        params: {
            includeAnswers: true,
            includeScores: true,
            IncludeScoreOverrides: true,
            IncludeCompany: true,
        },
    });
};

export const listQuestionsForSection = (
    projectUuid: string,
    sectionUuid: string,
): Promise<AxiosResponse<ListQuestionsResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/${questionType}`;
    return api.get(url, {
        params: {
            EntityType: EntityType.Section.toString(),
            EntityUuid: sectionUuid,
            IncludeAnswers: false,
        },
    });
};

// Sections
export const getProjectSections = (
    projectUuid: string,
): Promise<AxiosResponse<GetProjectSectionsResponseDto | TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/scoring-sections`;
    return api.get(url);
};

export const getScoringSectionTypes = (): Promise<AxiosResponse<ListSectionTypesResponseDto | TransactionErrorDto>> => {
    const url = `${baseApiUrl}/scoring-section-types`;
    return api.get(url);
};

export const getScoringSectionImportanceLevels = (): Promise<
    AxiosResponse<ListSectionImportanceLevelsResponseDto | TransactionErrorDto>
> => {
    const url = `${baseApiUrl}/scoring-section-importance-levels`;
    return api.get(url);
};

export const updateProjectSectionWeightings = (
    request: UpdateProjectSectionWeightingsRequestDto,
): Promise<AxiosResponse | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${request.projectUuid}/scoring-sections/weightings`;
    return api.put(url, request);
};

export const updateSelectedProjectSections = (
    request: UpdateSelectedProjectSectionsRequestDto,
): Promise<AxiosResponse | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${request.projectUuid}/scoring-sections`;
    return api.put(url, request);
};

export const deleteProjectSection = (
    request: DeleteProjectSectionRequestDto,
): Promise<AxiosResponse | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${request.projectUuid}/scoring-sections/${request.sectionUuid}`;
    return api.delete(url);
};

// Scoring
export const createAnswerScore = (
    projectUuid: string,
    questionUuid: string,
    answerUuid: string,
    request: CreateScoreRequestDto,
): Promise<AxiosResponse<ScoreResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/${questionType}/${questionUuid}/answers/${answerUuid}/scores`;
    return api.post(url, request);
};

export const updateAnswerScore = (
    projectUuid: string,
    questionUuid: string,
    answerUuid: string,
    request: UpdateScoreRequestDto,
): Promise<AxiosResponse<ScoreResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/${questionType}/${questionUuid}/answers/${answerUuid}/scores/${request.scoreUuid}`;
    return api.put(url, request);
};

export const setScorerScoringComplete = (
    projectUuid: string,
    userUuid: string,
): Promise<AxiosResponse<ScorerResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/scorers/${userUuid}/is-complete`;
    return api.put(url);
};

export const getIsScoringCompleteForScorer = (
    projectUuid: string,
    userUuid: string,
): Promise<AxiosResponse<IsEntityScoringCompleteResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/scorers/${userUuid}/is-complete`;
    return api.get(url);
};

export const getIsScoringCompleteForProject = (
    projectUuid: string,
): Promise<AxiosResponse<IsEntityScoringCompleteResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/scorer-completions/is-complete`;
    return api.get(url);
};

export const overrideAnswerScore = (
    projectUuid: string,
    questionUuid: string,
    answerUuid: string,
    request: OverrideScoreRequestDto,
): Promise<AxiosResponse<ScoreResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/${questionType}/${questionUuid}/answers/${answerUuid}/scores-override`;
    return api.put(url, request);
};
