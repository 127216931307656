import { Copy, Heading, ITableColumn, Table } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import sortingAlgorithms from "features/helpers/sortingAlgorithms";
import stringService from "services/stringService";
import { ITheme } from "styles/themes/types";
import { IScoreBreakdownDto } from "types/dtos/questions/IScoreBreakdownDto";
import { ScoreResponseDto } from "types/dtos/questions/ScoreResponseDto";
import { UserRoleDto } from "types/dtos/rolesPermissions/UserRoleDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
    supplierName: {
        fontWeight: "bold",
        marginTop: theme.spacing.small,
    },
    averageScoreContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingTop: theme.spacing.xSmall,
        paddingBottom: theme.spacing.xSmall,
    },
    averageScore: {
        fontWeight: "bold",
        paddingRight: theme.spacing.medium,
    },
    hr: {
        color: theme.colors.gray,
    },
    alignRight: {
        textAlign: "right",
    },
}));

interface IScoreBreakdownProps {
    scores: ScoreResponseDto[];
    supplierName: string;
    userProjectRoles: UserRoleDto[];
}

const ScoreBreakdownDisplay: React.FC<IScoreBreakdownProps> = (props) => {
    const classes = useStyles();

    const { supplierName, userProjectRoles } = props;

    const { t } = useTranslation();

    const { sortScorersByRoleThenName } = sortingAlgorithms();

    const scores = props.scores.filter((x) => x.isOverride === false);
    const tableData = scores
        .map(
            (s) =>
                ({
                    name: s.scoredByUserFullname,
                    originalScore: s.score,
                    role: userProjectRoles.find((r) => r.userUuid === s.scoredByUserUuid)?.name ?? "",
                }) as IScoreBreakdownDto,
        )
        .sort(sortScorersByRoleThenName);
    const average = Math.round(scores.map((x) => x.score).reduce((a, b) => a + b, 0) / scores.length);

    const formattedSupplierName = stringService.toKebabCase(supplierName);

    const columns: ITableColumn[] = [
        {
            position: 1,
            priorityLevel: 1,
            name: "name",
            minWidth: 150,
            contentWrap: true,
        },
        {
            position: 2,
            priorityLevel: 3,
            name: "role",
            minWidth: 200,
            render: (score: IScoreBreakdownDto) => (
                <div>
                    {score.role === ""
                        ? t("tenderCriteria.moderateScore.scoreBreakdownDrawer.nonProjectRole")
                        : t(`roles.names.${score.role}`)}
                </div>
            ),
        },
        {
            position: 3,
            priorityLevel: 2,
            name: "originalScore",
            minWidth: 20,
            render: (score: IScoreBreakdownDto) => <div className={classes.alignRight}>{score.originalScore}</div>,
        },
    ];

    return (
        <div className={classes.container}>
            <Heading variant="h2">{t("tenderCriteria.moderateScore.scoreBreakdownDrawer.title")}</Heading>
            <Copy testid={formattedSupplierName} className={classes.supplierName}>
                {supplierName}
            </Copy>
            <Table
                data={tableData}
                columns={columns}
                noDataDisplay={t("common.noResults")}
                testid="score-breakdown-table"
            />
            <div>
                <hr />
                <div className={classes.averageScoreContainer}>
                    <Copy className={classes.averageScore}>
                        {t("tenderCriteria.moderateScore.scoreBreakdownDrawer.averageScore")}
                    </Copy>
                    <Copy className={classes.averageScore} testid={`${formattedSupplierName}-average`}>
                        {average.toString()}
                    </Copy>
                </div>
                <hr />
            </div>
        </div>
    );
};

export default ScoreBreakdownDisplay;
