import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { ICommonProps, IOption, Icon } from "@maistro/components";
import classNames from "classnames";
import React, { useRef } from "react";
import { createUseStyles } from "react-jss";

import baseSearchStyles from "components/sharedStyles/baseSearchStyles";

const useStyles = createUseStyles(baseSearchStyles);

interface ISearchSelectProps extends ICommonProps {
    placeholder: string;
    name: string;
    options: Array<IOption>;
    onSelect: (value?: IOption) => void;
    searchText: string;
    setSearchText: (value: string) => void;
    updateSearchTextOnSelect?: boolean;
}

const SearchSelect: React.FC<ISearchSelectProps> = (props) => {
    const classes = useStyles();

    const { className, onSelect, options, placeholder, setSearchText, searchText, testid } = props;
    const textInputRef = useRef<HTMLInputElement>(null);

    const addIfMatchedOnKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            const selectedOption = options.find(
                (option) => option.label.toUpperCase() === textInputRef.current?.value.toUpperCase(),
            );
            if (selectedOption) {
                onSelect(selectedOption);
            }
        }
    };

    const handleValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText("");
        const selectedOption = options.find((option) => option.label === event.target.value);
        if (selectedOption) {
            if (props.updateSearchTextOnSelect) {
                setSearchText(selectedOption.label);
            }
            onSelect(selectedOption);
        } else {
            setSearchText(event.target.value);
            onSelect(undefined);
        }
    };

    return (
        <div className={classNames(classes.container, className)} data-component="SearchBar" data-testid={testid}>
            <input
                type="text"
                className={classes.input}
                placeholder={placeholder}
                ref={textInputRef}
                list={`${props.name}-search-list`}
                data-testid={`${testid}-input`}
                value={searchText}
                onChange={(event) => {
                    handleValueChanged(event);
                }}
                onKeyDown={(event) => {
                    addIfMatchedOnKeyPress(event);
                }}
            />
            <Icon className={classes.icon} icon={faSearch} />
            <datalist id={`${props.name}-search-list`}>
                {options.map((option) => (
                    <option value={option.label} label={option.label} key={option.value} />
                ))}
            </datalist>
        </div>
    );
};

SearchSelect.defaultProps = {
    updateSearchTextOnSelect: true,
};

export default SearchSelect;
