import { Copy, DateField, ReadOnlyField } from "@maistro/components";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import MaistroFormikLabel from "components/FormikFields/MaistroFormikLabel";
import AmendDeadlineButtons from "features/project/components/AmendDeadline/AmendDeadlineButtons";
import dateTimeService from "services/dateTimeService";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    form: {
        display: "inline-flex",
        alignItems: "end",
        flexWrap: "wrap",
        rowGap: theme.spacing.xSmall,
        columnGap: theme.spacing.small,
        minHeight: 40, // Ensure button change has no jiggle
    },
    inlineFlex: {
        display: "inline-flex",
        flexDirection: "column",
        gap: theme.spacing.xSmall,
    },
    labelGroup: {
        width: "100%",
    },
    label: {
        ...theme.typography.base,
        fontWeight: 600,
    },
    copy: {
        ...theme.typography.base,
    },
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        inlineFlex: {
            flexDirection: "row",
            alignItems: "center",
        },
    },
}));

interface IAmendDeadlineFormProps {
    label: string;
    deadline?: Date;
    deadlineHasPassed: boolean;
    canAmendDate: boolean;
    canAmendToPast?: boolean;
    updateDeadline: (deadline: Date) => void;
}

const AmendDeadlineForm: React.FC<IAmendDeadlineFormProps> = (props) => {
    const classes = useStyles();

    const { label, deadline, deadlineHasPassed, canAmendDate, canAmendToPast, updateDeadline } = props;

    const [isAmendingDate, setIsAmendingDate] = useState(false);

    const { t } = useTranslation();

    const extendOnly = !canAmendToPast || deadlineHasPassed;

    return (
        <Formik
            enableReinitialize
            initialValues={{
                deadline,
            }}
            validationSchema={Yup.object({
                deadline: Yup.date().test(
                    "validate-deadline",
                    t("components.amendDeadline.extendValidation"),
                    (value) =>
                        !(extendOnly && value && dateTimeService.getTime(value) < dateTimeService.getTime(new Date())),
                ),
            })}
            onReset={() => setIsAmendingDate(false)}
            onSubmit={(values, { resetForm }) => {
                if (values.deadline) {
                    updateDeadline(values.deadline);
                }
                resetForm();
            }}
        >
            {({ dirty, isValid, values }) => (
                <Form className={classes.form}>
                    {!isAmendingDate && (
                        <ReadOnlyField
                            label={label}
                            value={dateTimeService.toShortDateTimeFormat(values.deadline)}
                            testid="current-deadline"
                        />
                    )}
                    {isAmendingDate && (
                        <div className={classes.labelGroup}>
                            <MaistroFormikLabel
                                className={classes.label}
                                label={label}
                                name="deadline"
                                testid="amend-deadline-label"
                            />
                            <Copy className={classes.copy}>
                                {extendOnly
                                    ? t("components.amendDeadline.extendCopy")
                                    : t("components.amendDeadline.copy")}
                            </Copy>
                        </div>
                    )}
                    <div className={classes.inlineFlex}>
                        {isAmendingDate && (
                            <DateField name="deadline" withTime withPassIcon={false} testid="amend-deadline" />
                        )}
                        <AmendDeadlineButtons
                            canAmendDate={canAmendDate}
                            deadlineHasPassed={deadlineHasPassed}
                            isAmendingDate={isAmendingDate}
                            setIsAmendingDate={setIsAmendingDate}
                            dirty={dirty}
                            isValid={isValid}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

AmendDeadlineForm.defaultProps = {
    canAmendToPast: false,
};

export default AmendDeadlineForm;
