import { Heading } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";

import DataProtectionSubSection, {
    dataProtectionValidationSchema,
} from "features/company/profile/vetting/sections/dataAndSecuritySubSections/DataProtectionSubSection";
import DisasterRecoverySubSection, {
    disasterRecoveryValidationSchema,
} from "features/company/profile/vetting/sections/dataAndSecuritySubSections/DisasterRecoverySubSection";
import useSectionStyles from "features/company/profile/vetting/sections/useSectionStyles";
import { MembershipDto } from "types/dtos/company/vettingInformation/MembershipDto";
import FileDto from "types/dtos/files/FileDto";
import { TFunction } from "i18next";

export const dataAndSecurityValidationSchema = (t: TFunction<"translation", undefined>) =>
    disasterRecoveryValidationSchema().concat(dataProtectionValidationSchema(t));

interface IDataAndSecuritySectionProps {
    cyberSecurityPlanFiles: FileDto[];
    dataProtectionBreachesFiles?: FileDto[];
    dataProtectionPolicyFiles?: FileDto[];
    disasterRecoveryPlanFiles: FileDto[];
    externalAuditFiles?: FileDto[];
    setCyberSecurityPlanFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    setDataProtectionBreachesFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    setDataProtectionPolicyFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    setDisasterRecoveryPlanFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    setExternalAuditFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    memberships: MembershipDto[];
    setMemberships: React.Dispatch<React.SetStateAction<MembershipDto[]>>;
}

const DataAndSecuritySection: React.FC<IDataAndSecuritySectionProps> = ({
    cyberSecurityPlanFiles,
    dataProtectionBreachesFiles,
    dataProtectionPolicyFiles,
    disasterRecoveryPlanFiles,
    externalAuditFiles,
    setCyberSecurityPlanFiles,
    setDataProtectionBreachesFiles,
    setDataProtectionPolicyFiles,
    setDisasterRecoveryPlanFiles,
    setExternalAuditFiles,
    memberships,
    setMemberships,
}) => {
    const classes = useSectionStyles();

    const { t } = useTranslation();

    return (
        <div className={classes.section}>
            <Heading className={classes.heading} variant="h3">
                {t("companyVettingQuestionnaire.sections.dataAndSecurity.heading")}
            </Heading>
            <hr className={classes.hr} />
            <DisasterRecoverySubSection
                cyberSecurityPlanFiles={cyberSecurityPlanFiles}
                disasterRecoveryPlanFiles={disasterRecoveryPlanFiles}
                setCyberSecurityPlanFiles={setCyberSecurityPlanFiles}
                setDisasterRecoveryPlanFiles={setDisasterRecoveryPlanFiles}
            />
            <DataProtectionSubSection
                dataProtectionBreachesFiles={dataProtectionBreachesFiles}
                dataProtectionPolicyFiles={dataProtectionPolicyFiles}
                externalAuditFiles={externalAuditFiles}
                setDataProtectionBreachesFiles={setDataProtectionBreachesFiles}
                setDataProtectionPolicyFiles={setDataProtectionPolicyFiles}
                setExternalAuditFiles={setExternalAuditFiles}
                memberships={memberships}
                setMemberships={setMemberships}
            />
        </div>
    );
};

export default DataAndSecuritySection;
