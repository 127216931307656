/* eslint-disable babel/camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import { useToaster } from "@maistro/components";
import type { AxiosResponse } from "axios";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getSupplierSubmissionsForProject } from "api/project/projectSubmissionsApi";
import { createProject, updateProject } from "api/projectApi";
import { getSuppliersTenderScores } from "api/projectSuppliersApi";
import { getProjectSummaryReport, getStatementOfWorkReport } from "api/reportingApi";
import { IProjectInformation } from "features/project/types";
import useAppDispatch from "hooks/useAppDispatch";
import useCurrentUser from "hooks/useCurrentUser";
import useReduxSelector from "hooks/useReduxSelector";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import dateTimeService from "services/dateTimeService";
import validationService from "services/validationService";
import { fetchProject, setProjectInformation, setTenderResponseDeadline } from "store/projectSlice";
import { DirtyValues } from "types/dtos/DirtyValues";
import { constructCreateProjectRequestDto } from "types/dtos/projects/CreateProjectRequestDto";
import { ProjectResponseDto } from "types/dtos/projects/ProjectResponseDto";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import { constructUpdateProjectRequestDto } from "types/dtos/projects/UpdateProjectRequestDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import ProjectStatus from "types/enums/projects/ProjectStatus";
import ProjectType from "types/enums/projects/ProjectType";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";
import SupplierSubmissionStatus from "types/enums/supplierSubmissions/SupplierSubmissionStatus";

const useProject = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { projectInformation, isFetchingProject, isFetchingTender } = useReduxSelector((state) => state.projectState);
    const { user_uuid, company_uuid } = useReduxSelector((state) => state.authenticationState.accessToken);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const toast = useToaster();
    const dispatch = useAppDispatch();

    const { userHasProjectPermission } = useCurrentUser();

    const canUpdateProject = useMemo(
        () =>
            userHasProjectPermission(
                PermissionsEnum.UpdateProject,
                projectInformation.uuid,
                projectInformation.companyUuid,
            ),
        [projectInformation.uuid, projectInformation.companyUuid, userHasProjectPermission],
    );

    const canAskQuestion = useMemo(
        () =>
            userHasProjectPermission(
                PermissionsEnum.CanAskClarificationQuestion,
                projectInformation.uuid,
                projectInformation.companyUuid,
            ),
        [projectInformation.companyUuid, projectInformation.uuid, userHasProjectPermission],
    );

    const deadlineHasPassed = useMemo(
        () => dateTimeService.dateIsInPast(projectInformation?.tenderResponseDeadline),
        [projectInformation?.tenderResponseDeadline],
    );
    const isProjectCompleted = useMemo(
        () =>
            projectInformation.status === ProjectStatus.CompleteAtReview ||
            projectInformation.status === ProjectStatus.Complete,
        [projectInformation.status],
    );
    const isQuote = useMemo(() => projectInformation?.type === ProjectType.Quote, [projectInformation?.type]);
    const isTender = useMemo(() => projectInformation?.type === ProjectType.Tender, [projectInformation?.type]);

    const cancelProject = async () => {
        if (projectInformation.uuid && validationService.isValidUuid(projectInformation.uuid)) {
            setIsSubmitting(true);

            const response = await updateProject(projectInformation.uuid, {
                status: ProjectStatus.Cancelled,
                companyUuid: projectInformation.companyUuid,
                dirtyValues: { status: true },
            });
            if (response.data instanceof TransactionErrorDto || response.data.status !== ProjectStatus.Cancelled) {
                toast.error(t("projectBrief.api.failCancelProject"));
                setIsSubmitting(false);
                return;
            }
        }
        navigate(routes.common.dashboard.path);
    };

    const createOrUpdateProject = async (
        values: IProjectInformation,
        dirtyValues: DirtyValues,
        withToast = true,
    ): Promise<string | null> => {
        setIsSubmitting(true);
        let projectToSave: IProjectInformation = values;
        let response: AxiosResponse<ProjectResponseDto | TransactionErrorDto>;
        if (projectInformation.uuid && validationService.isValidUuid(projectInformation.uuid)) {
            projectToSave = {
                ...values,
                type: projectInformation.type,
                companyUuid: company_uuid ?? projectInformation.companyUuid,
            };
            response = await updateProject(
                projectInformation.uuid,
                constructUpdateProjectRequestDto(projectToSave, dirtyValues),
            );
        } else {
            projectToSave = {
                ...values,
                managerUuid: user_uuid,
                clientCompanyUuid: values.clientCompanyUuid ?? company_uuid ?? "",
                type: projectInformation.type,
            };
            response = await createProject(constructCreateProjectRequestDto(projectToSave));
        }

        if (response.status === 403) {
            setIsSubmitting(false);
            toast.error(t("common.api.forbiddenText"));
            return null;
        }
        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            setIsSubmitting(false);
            if (withToast) {
                toast.error(t("projectBrief.api.failProjectSave"));
            }
            return null;
        }

        projectToSave.clientCompanyUuid = response.data._embedded.buyerCompanyUuid;
        projectToSave.clientName = response.data._embedded.buyerCompanyName;
        projectToSave.companyUuid = response.data._embedded.buyerCompanyUuid;
        projectToSave.managerUuid = response.data.managerUserUuid;

        dispatch(setProjectInformation(projectToSave));

        setIsSubmitting(false);
        if (withToast) {
            toast.success(t("projectBrief.api.successProjectSave"));
        }

        return response.data._embedded.projectUuid;
    };

    const downloadSummary = useCallback(async () => {
        if (!(projectInformation.uuid && validationService.isValidUuid(projectInformation.uuid))) return;
        const response = await getProjectSummaryReport(projectInformation.uuid);

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("projectBrief.api.errorDownloadSummary"));
            return;
        }

        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.download = t("completeProject.projectSummaryFileName");
        link.click();
    }, [projectInformation.uuid, t, toast]);

    const downloadStatementOfWork = useCallback(async () => {
        if (!(projectInformation.uuid && validationService.isValidUuid(projectInformation.uuid))) return;

        const response = await getStatementOfWorkReport(projectInformation.uuid);

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("projectBrief.api.errorDownloadStatementOfWork"));
            return;
        }

        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.download = t("completeProject.statementOfWorkFileName");
        link.click();
    }, [projectInformation.uuid, t, toast]);

    const updateProjectDeadline = useCallback(
        async (deadline: Date) => {
            if (!projectInformation?.uuid || !projectInformation.budgetCurrency) {
                toast.error(t("monitorScreen.api.projectUpdateError"));
                return;
            }

            if (projectInformation.tenderResponseDeadline === deadline) return;

            const newDeadlineHasPassed = dateTimeService.dateIsInPast(deadline);
            let projectStatus;
            if (newDeadlineHasPassed) {
                projectStatus =
                    projectInformation.type === ProjectType.Tender ? ProjectStatus.Review : ProjectStatus.Results;
            } else {
                projectStatus = ProjectStatus.AwaitingResponses;
            }
            const response = await updateProject(projectInformation.uuid, {
                tenderResponseDeadline: deadline,
                status: projectStatus,
                dirtyValues: { tenderResponseDeadline: true, status: true },
            });

            if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                toast.error(t("monitorScreen.api.updateDeadlineError"));
                return;
            }

            dispatch(setTenderResponseDeadline(response.data));
            if (projectStatus === ProjectStatus.Results) {
                navigate(buildPath(routes.projects.awardSupplier, { projectUuid: projectInformation.uuid }));
            } else {
                navigate(buildPath(routes.projects.monitor, { projectUuid: projectInformation.uuid }));
            }

            toast.success(t("monitorScreen.api.updateDeadlineSuccess"));
        },
        [dispatch, navigate, projectInformation, t, toast],
    );

    const completeAtReview = useCallback(async () => {
        const response = await updateProject(projectInformation.uuid, {
            companyUuid: projectInformation.companyUuid,
            status: ProjectStatus.CompleteAtReview,
            dirtyValues: { status: true },
        });
        if (response.status === 200) {
            toast.success(t("monitorScreen.api.completeWithoutAwardSuccess"));
            navigate(buildPath(routes.projects.completeWithoutAward, { projectUuid: projectInformation.uuid }));
        }
    }, [navigate, projectInformation.companyUuid, projectInformation.uuid, t, toast]);

    const getProject = () => {
        dispatch(fetchProject());
    };

    const fetchProjectSuppliers = useCallback(async () => {
        if (!projectInformation.uuid) {
            return [];
        }
        const supplierResponse = await getSupplierSubmissionsForProject(projectInformation.uuid);

        if (supplierResponse.data instanceof TransactionErrorDto || supplierResponse.status !== 200) {
            toast.error(t("monitorScreen.api.supplierLoadingError"));
            return [];
        }

        let suppliersInformation = supplierResponse.data._embedded.items;
        if (suppliersInformation.some((supplier) => supplier.status === SupplierSubmissionStatus.Successful)) {
            navigate(buildPath(routes.projects.award, { projectUuid: projectInformation.uuid }), { replace: true });
        }

        if (isTender) {
            const suppliersScores = await getSuppliersTenderScores(projectInformation.uuid);
            if (supplierResponse.status !== 200) {
                toast.error(t("monitorScreen.api.supplierScoresError"));
            } else {
                suppliersInformation = suppliersInformation.map((supplier) => ({
                    ...supplier,
                    scoring:
                        suppliersScores.data.submissionScores.find(
                            (score) => score.companyUuid === supplier.supplierCompanyUuid,
                        )?.calculatedSubmissionScore ?? "",
                }));
            }
        }
        return suppliersInformation;
    }, [navigate, isTender, projectInformation.uuid, t, toast]);

    const checkSuppliersResponded = useCallback((suppliers: Array<SupplierSubmissionResponseDto>): boolean => {
        return (
            suppliers.filter(
                (supplier) =>
                    supplier.status === SupplierSubmissionStatus.Submitted ||
                    supplier.status === SupplierSubmissionStatus.Successful,
            ).length > 0
        );
    }, []);

    return {
        getProject,
        projectInformation,
        isFetching: isFetchingProject || isFetchingTender,
        isSubmitting,
        cancelProject,
        createOrUpdateProject,
        downloadSummary,
        downloadStatementOfWork,
        setIsSubmitting,
        updateProjectDeadline,
        completeAtReview,
        canUpdateProject,
        canAskQuestion,
        deadlineHasPassed,
        isProjectCompleted,
        isQuote,
        isTender,
        fetchProjectSuppliers,
        checkSuppliersResponded,
    };
};

export default useProject;
