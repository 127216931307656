import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { Icon, TextButton } from "@maistro/components";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    banner: {
        width: "100%",
        display: "flex",
        gap: theme.spacing.small,
        justifyContent: "center",
        alignItems: "center",

        backgroundColor: theme.colors.primary,
        padding: theme.spacing.small,
        borderRadius: theme.spacing.small,
        color: theme.colors.white,
    },
    view: {
        "&:hover:not([disabled])": {
            color: theme.colors.white,
            textDecoration: "none",
        },
    },
    fontSize: {
        fontSize: 14,
    },
}));

interface IBannerProps extends ICommonProps {
    text?: string;
    onClick?: () => void;
}

const Banner: React.FC<IBannerProps> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation();

    return (
        <div className={classNames(classes.banner, classes.fontSize, props.className)} data-testid={props.testid}>
            <Icon icon={faInfoCircle} />
            {props.text}
            <TextButton
                className={classNames(classes.view, classes.fontSize)}
                label={t("common.view")}
                onClick={props.onClick}
                testid={`${props.testid}-button`}
            />
        </div>
    );
};

export default Banner;
