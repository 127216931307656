import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { Copy, Heading, Icon } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { Table, TableRow } from "components";
import { ICommonProps } from "components/shared";
import colorService from "services/colorService";
import { ITheme } from "styles/themes/types";
import ConnectivityStatusDto from "types/dtos/systemStatus/ConnectivityStatusDto";
import { SystemStatusCollectionDto } from "types/dtos/systemStatus/SystemStatusCollectionDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    heading: {
        paddingTop: theme.spacing.medium,
        paddingBottom: theme.spacing.xSmall,
    },
    connectionsContainer: {
        display: "flex",
        padding: `${theme.spacing.medium}px ${theme.spacing.large}px`,
        background: theme.colors.white,
        boxShadow: `${colorService.toHexWithOpacity(theme.colors.primary, 0.1)} 0 3px 6px`,
        borderRadius: theme.spacing.xSmall,
        justifyContent: "space-between",
        marginTop: theme.spacing.small,
        marginBottom: theme.spacing.small,
        alignItems: "center",
    },
    servicesTable: {
        width: "100%",
        paddingBottom: theme.spacing.medium,
    },
    alignRight: {
        textAlign: "right",
    },
    passIcon: {
        color: theme.colors.success,
    },
    serviceText: {
        fontSize: 14,
    },
    failIcon: {
        color: theme.colors.error,
    },
    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        connectionsContainer: {
            width: theme.breakpoints.md,
        },
        servicesTable: {
            width: theme.breakpoints.md,
        },
    },
}));

interface ISystemStatusProps extends ICommonProps {
    client: string;
    connectivity: Array<ConnectivityStatusDto>;
    services: SystemStatusCollectionDto | null;
}

const SystemStatusDisplay: React.FC<ISystemStatusProps> = ({ client, connectivity, services }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const getStatusIcon = (pass: boolean) => {
        return (
            <Icon
                className={pass ? classes.passIcon : classes.failIcon}
                icon={pass ? faCheckCircle : faTimesCircle}
                size="large"
            />
        );
    };

    return (
        <div>
            <Heading className={classes.heading} variant="h3">
                {t("systemStatus.servicesTitle")}
            </Heading>
            <Copy>{t("systemStatus.servicesText")}</Copy>
            {services && (
                <Table data-testid="system-status-table" className={classes.servicesTable}>
                    <thead>
                        <TableRow tHead>
                            <th>Name</th>
                            <th>Version</th>
                            <th className={classes.alignRight}>Server</th>
                            <th className={classes.alignRight}>Database</th>
                        </TableRow>
                    </thead>
                    <tbody>
                        <TableRow>
                            <td>Orchestration</td>
                            <td>{services.orchestration.version}</td>
                            <td className={classes.alignRight}>{getStatusIcon(services.orchestration.service)}</td>
                            <td className={classes.alignRight}>{getStatusIcon(services.orchestration.database)}</td>
                        </TableRow>
                        <TableRow>
                            <td>Identity Service</td>
                            <td>{services.idpService.version}</td>
                            <td className={classes.alignRight}>{getStatusIcon(services.idpService.service)}</td>
                            <td className={classes.alignRight}>{getStatusIcon(services.idpService.database)}</td>
                        </TableRow>
                        <TableRow>
                            <td>Roles & Permissions Service</td>
                            <td>{services.rolesPermissionsService.version}</td>
                            <td className={classes.alignRight}>
                                {getStatusIcon(services.rolesPermissionsService.service)}
                            </td>
                            <td className={classes.alignRight}>
                                {getStatusIcon(services.rolesPermissionsService.database)}
                            </td>
                        </TableRow>
                        <TableRow>
                            <td>Email Service</td>
                            <td>{services.emailService.version}</td>
                            <td className={classes.alignRight}>{getStatusIcon(services.emailService.service)}</td>
                            <td className={classes.alignRight}>{getStatusIcon(services.emailService.database)}</td>
                        </TableRow>
                        <TableRow>
                            <td>Projects Service</td>
                            <td>{services.projectsService.version}</td>
                            <td className={classes.alignRight}>{getStatusIcon(services.projectsService.service)}</td>
                            <td className={classes.alignRight}>{getStatusIcon(services.projectsService.database)}</td>
                        </TableRow>
                        <TableRow>
                            <td>Questions Service</td>
                            <td>{services.questionsService.version}</td>
                            <td className={classes.alignRight}>{getStatusIcon(services.questionsService.service)}</td>
                            <td className={classes.alignRight}>{getStatusIcon(services.questionsService.database)}</td>
                        </TableRow>
                        <TableRow>
                            <td>Taxonomy Service</td>
                            <td>{services.taxonomyService.version}</td>
                            <td className={classes.alignRight}>{getStatusIcon(services.taxonomyService.service)}</td>
                            <td className={classes.alignRight}>{getStatusIcon(services.taxonomyService.database)}</td>
                        </TableRow>
                        <TableRow>
                            <td>Company Service</td>
                            <td>{services.companyService.version}</td>
                            <td className={classes.alignRight}>{getStatusIcon(services.companyService.service)}</td>
                            <td className={classes.alignRight}>{getStatusIcon(services.companyService.database)}</td>
                        </TableRow>
                    </tbody>
                </Table>
            )}

            <Heading className={classes.heading} variant="h3">
                {t("systemStatus.connectivityTitle")}
            </Heading>
            <Copy>{t("systemStatus.connectivityText")}</Copy>

            {connectivity.map((connection) => (
                <div key={connection.url} className={classes.connectionsContainer}>
                    <div className={classes.serviceText} title={connection.name}>
                        {connection.url}
                    </div>
                    <div>{getStatusIcon(connection.connected)}</div>
                </div>
            ))}

            <Heading className={classes.heading} variant="h3">
                {t("systemStatus.supportTitle")}
            </Heading>
            <Copy>{`Client Version: ${client}`}</Copy>
            {!services && <div>{t("systemStatus.servicesError")}</div>}
        </div>
    );
};

export default SystemStatusDisplay;
