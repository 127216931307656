import { faCheckCircle, faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { Icon } from "@maistro/components";
import classNames from "classnames";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

import { ITheme } from "styles/themes/types";
import { ProjectSectionWeightings } from "types/dtos/questions/sections/ProjectSectionWeightings";

const useStyles = createUseStyles((theme: ITheme) => ({
    total: {
        display: "inline-block",
        ...theme.typography.headings.h3,
    },

    success: {
        color: theme.colors.success,
    },

    error: {
        color: theme.colors.error,
    },

    icon: {
        display: "inline-block",
        marginRight: theme.spacing.xSmall,
    },
}));

const PercentageTotal: React.FC = () => {
    const classes = useStyles();

    const [totalPercentage, setTotalPercentage] = useState(0);

    const { values } = useFormikContext<ProjectSectionWeightings>();

    useEffect(() => {
        const percentage = values.sectionWeightings.reduce((total, section) => total + (section.weighting || 0), 0);
        setTotalPercentage(percentage);
    }, [values]);

    return (
        <div
            className={classNames(
                classes.total,
                { [classes.success]: totalPercentage === 100 },
                { [classes.error]: totalPercentage > 100 },
            )}
        >
            {totalPercentage === 100 && <Icon className={classes.icon} icon={faCheckCircle} size="small" />}
            {totalPercentage > 100 && <Icon className={classes.icon} icon={faExclamationTriangle} size="small" />}
            <span data-testid="total-percentage">Total {totalPercentage}%</span>
        </div>
    );
};

export default PercentageTotal;
