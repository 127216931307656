import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import ProjectStatus from "types/enums/projects/ProjectStatus";

const calculateProjectUrl = (projectUuid: string, projectStatus: ProjectStatus) => {
    switch (projectStatus) {
        case ProjectStatus.CompleteAtReview:
            return buildPath(routes.projects.completeWithoutAward, { projectUuid });
        case ProjectStatus.Complete:
            return buildPath(routes.projects.complete, { projectUuid });
        case ProjectStatus.AwaitingResponses:
        case ProjectStatus.Review:
            return buildPath(routes.projects.monitor, { projectUuid });
        case ProjectStatus.Results:
            return buildPath(routes.projects.awardSupplier, { projectUuid });
        case ProjectStatus.Approval:
            return buildPath(routes.projects.monitorApprovals, { projectUuid });
        case ProjectStatus.Invite:
            return buildPath(routes.projects.summary, { projectUuid });
        case ProjectStatus.Create:
        default:
            return `/project-brief/${projectUuid}`;
    }
};

export default calculateProjectUrl;
