import { useCallback, useState } from "react";

import ReactGA from "react-ga4";
import BuyerSupplierContractDto from "types/dtos/company/buyer-supplier-relationships/BuyerSupplierContractDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useContractsControl = () => {
    const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
    const [selectedContract, setSelectedContract] = useState<BuyerSupplierContractDto>();

    const handleAddContractButtonClick = useCallback(() => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Contracts - Add Contract`,
        });
        setSelectedContract(undefined);
        setIsFormVisible(true);
    }, []);

    return {
        handleAddContractButtonClick,
        isFormVisible,
        setIsFormVisible,
        selectedContract,
        setSelectedContract,
    };
};

export default useContractsControl;
