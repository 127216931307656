import { Popup, useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { listApprovalsForProject } from "api/company/approvalsApi";
import { awardSupplier } from "api/projectApi";
import loadingImage from "assets/images/approvalLoadingScreen.svg";
import { Loader } from "components";
import TitleCtaType from "components/Layout/types/TitleCtaType";
import useProject from "features/project/hooks/useProject";
import MonitorApprovalsDisplay from "features/project/monitorApprovals/MonitorApprovalsDisplay";
import WithLoadingScreen from "hoc/WithLoadingScreen";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import validationService from "services/validationService";
import { resetLayout, setBack, setPageTitle, setTitleCtas, setTooltip } from "store/layoutSlice";
import { EntityApprovalDto } from "types/dtos/company/approvals/EntityApprovalDto";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import ProjectStatus from "types/enums/projects/ProjectStatus";

const MonitorApprovalsContainer: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [approvals, setApprovals] = useState<EntityApprovalDto[]>([]);
    const [isAwardingSupplier, setIsAwardingSupplier] = useState(false);
    const [isAwardProjectPopupOpen, setIsAwardProjectPopupOpen] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState<SupplierSubmissionResponseDto>();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toast = useToaster();
    const navigate = useNavigate();

    const { fetchProjectSuppliers, projectInformation } = useProject();

    const fetchData = useCallback(async () => {
        if (
            !validationService.isValidUuid(projectInformation.companyUuid) ||
            !validationService.isValidUuid(projectInformation.uuid)
        ) {
            return;
        }

        const response = await listApprovalsForProject({
            companyUuid: projectInformation.companyUuid,
            projectUuid: projectInformation.uuid,
        });

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("monitorApprovals.api.listApprovalsError"));
            setApprovals([]);
            return;
        }

        const approvalsResponse = response.data.approvals;
        const projectSuppliers = await fetchProjectSuppliers();
        const selected = projectSuppliers.find((ps) => ps._embedded.responseUuid === approvalsResponse[0].entityUuid);
        setSelectedSupplier(selected);
        setApprovals(approvalsResponse);
    }, [fetchProjectSuppliers, projectInformation.companyUuid, projectInformation.uuid, t, toast]);

    const refreshData = useCallback(() => {
        setIsLoading(true);
        fetchData()
            .then(() => setIsLoading(false))
            .catch(() => {
                toast.error(t("monitorApprovals.api.listApprovalsError"));
                setApprovals([]);
            });
    }, [fetchData, t, toast]);

    const awardProjectToSupplier = useCallback(async () => {
        if (!validationService.isValidUuid(projectInformation.uuid) || !selectedSupplier) {
            return;
        }
        setIsAwardingSupplier(true);

        const response = await awardSupplier(projectInformation.uuid, {
            supplierUuid: selectedSupplier.supplierCompanyUuid,
        });
        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("monitorScreen.api.awardSupplierError"));
            setIsAwardingSupplier(false);
            return;
        }

        navigate(buildPath(routes.projects.award, { projectUuid: projectInformation.uuid }));
    }, [navigate, projectInformation.uuid, t, toast, selectedSupplier]);

    useEffect(() => {
        dispatch(setBack(null));
        dispatch(setPageTitle(t("monitorApprovals.title")));
        dispatch(setTooltip(t("monitorApprovals.tooltip")));

        const titleCtas = [
            {
                type: TitleCtaType.Refresh,
                onClick: () => refreshData(),
            },
            {
                type: TitleCtaType.ProjectPreview,
                onClick: () => navigate(buildPath(routes.projects.monitor, { projectUuid: projectInformation.uuid })),
            },
        ];
        dispatch(setTitleCtas(titleCtas));

        return () => {
            dispatch(resetLayout());
        };
    }, [dispatch, navigate, projectInformation.uuid, refreshData, t]);

    useEffect(() => {
        if (!validationService.isValidUuid(projectInformation.uuid)) return;

        if (projectInformation.status !== ProjectStatus.Approval) {
            navigate(buildPath(routes.projects.awardSupplier, { projectUuid: projectInformation.uuid }));
        }

        fetchData()
            .then(() => setIsLoading(false))
            .catch(() => {
                toast.error(t("monitorApprovals.api.listApprovalsError"));
                setIsLoading(false);
                setApprovals([]);
            });
    }, [fetchData, navigate, projectInformation.status, projectInformation.uuid, t, toast]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <MonitorApprovalsDisplay
                approvals={approvals}
                isAwardingSupplier={isAwardingSupplier}
                projectName={projectInformation.projectName}
                supplierName={selectedSupplier?._embedded.supplierCompanyName}
                projectUuid={projectInformation.uuid}
                setIsAwardProjectPopupOpen={setIsAwardProjectPopupOpen}
            />
            <Popup
                title={t("popups.awardProject.title")}
                message={t("popups.awardProject.message", {
                    selectedSupplierName: selectedSupplier?._embedded.supplierCompanyName,
                })}
                isOpen={isAwardProjectPopupOpen}
                primaryActionText={t("popups.awardProject.cta.primary")}
                onPrimaryAction={awardProjectToSupplier}
                secondaryActionText={t("popups.awardProject.cta.secondary")}
                onSecondaryAction={() => setIsAwardProjectPopupOpen(false)}
                onClose={() => setIsAwardProjectPopupOpen(false)}
                disabled={isAwardingSupplier}
                testid="award-project-to-supplier-popup"
            />
        </>
    );
};

export default WithLoadingScreen(MonitorApprovalsContainer, {
    titleKey: "monitorApprovals.loading.title",
    messageKey: "monitorApprovals.loading.text",
    duration: 5000,
    image: loadingImage,
});
