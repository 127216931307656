import { Heading } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import noClarificationQuestions from "assets/images/noClarificationQuestions.svg";
import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    imageContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: theme.spacing.large,
        alignItems: "center",
    },

    img: {
        width: "100%",
        maxWidth: 300,
    },

    heading: {
        marginTop: theme.spacing.xLarge,
    },
}));

interface INoClarificationQuestionsProps extends ICommonProps {
    children?: React.ReactNode;
}

const NoClarificationQuestions: React.FC<INoClarificationQuestionsProps> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation();

    return (
        <div className={classes.imageContent}>
            <img className={classes.img} src={noClarificationQuestions} alt="No clarification questions" />
            <Heading className={classes.heading} variant="h2" testid="no-clarification-questions-title">
                {t("clarificationQuestions.noClarificationQuestions")}
            </Heading>
            {props.children}
        </div>
    );
};

export default NoClarificationQuestions;
