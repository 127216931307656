import React from "react";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";
import { IProjectResponseInformation } from "features/project/types";
import SupplierAskQuestionForm from "features/project/clarificationQuestions/askQuestion/SupplierAskQuestionForm";
import ProjectInformationDisplay from "features/project/clarificationQuestions/ProjectInformationDisplay";
import FileDto from "types/dtos/files/FileDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    grid: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.large,
    },
}));

interface ISupplierAskQuestionDisplayProps extends ICommonProps {
    projectResponseInformation: IProjectResponseInformation;
    submitQuestion: (question: string, section: string) => void;
    cancelQuestion: () => void;
    submittingQuestion: boolean;
    files?: FileDto[];
    setFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    removeFile: (file: FileDto | null) => void;
    fileIsUploading: boolean;
    setFileIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
}

const SupplierAskQuestionDisplay: React.FC<ISupplierAskQuestionDisplayProps> = (props) => {
    const classes = useStyles();

    const {
        projectResponseInformation,
        submitQuestion,
        cancelQuestion,
        files,
        setFiles,
        removeFile,
        fileIsUploading,
        setFileIsUploading,
    } = props;

    return (
        <div className={classes.grid}>
            <ProjectInformationDisplay
                projectName={projectResponseInformation.projectName}
                responseDate={projectResponseInformation.tenderResponseDeadline}
            />
            <SupplierAskQuestionForm
                submit={submitQuestion}
                cancel={cancelQuestion}
                submittingQuestion={props.submittingQuestion}
                files={files}
                removeFile={removeFile}
                setFiles={setFiles}
                fileIsUploading={fileIsUploading}
                setFileIsUploading={setFileIsUploading}
                projectUuid={props.projectResponseInformation.projectUuid}
                projectType={props.projectResponseInformation.projectType}
            />
        </div>
    );
};

export default SupplierAskQuestionDisplay;
