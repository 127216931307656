import { Button, ITableColumn, Pill, Table, htmlService } from "@maistro/components";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import colorService from "services/colorService";
import stringService from "services/stringService";
import { ITheme } from "styles/themes/types";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import ProjectType from "types/enums/projects/ProjectType";
import ClarificationQuestionStatus from "types/enums/questions/ClarificationQuestionStatus";

const useStyles = createUseStyles((theme: ITheme) => ({
    hidden: {
        display: "none",
    },

    question: {
        maxWidth: theme.breakpoints.xs,
        display: "-webkit-box",
        overflow: "hidden",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": 2,
    },

    tableHead: {
        "& th": {
            ...theme.typography.base,
            color: colorService.toHexWithOpacity(theme.colors.primary, 0.6),
            fontWeight: 600,
        },
    },

    viewCell: {
        display: "flex",
        justifyContent: "end",
    },

    tableRow: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: colorService.toHexWithOpacity(theme.colors.gray, 0.3),
        },
    },

    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        question: {
            "-webkit-line-clamp": 1,
        },
    },
}));

interface IClarificationQuestionsTableProps extends ICommonProps {
    clarificationQuestions: Array<QuestionResponseDto>;
    navigateToQuestion: (questionUuid: string) => void;
    buyerCompanyUuid: string;
    projectUuid: string;
    sections: Array<SectionDto>;
    projectType?: ProjectType;
}

const ClarificationQuestionsTable: React.FC<IClarificationQuestionsTableProps> = (props) => {
    const { clarificationQuestions, navigateToQuestion, testid, buyerCompanyUuid, projectUuid, sections, projectType } =
        props;

    const classes = useStyles();

    const { myCompanyUuid } = useCurrentUser();

    const { t } = useTranslation();

    const pillColorForStatus = useCallback((status: ClarificationQuestionStatus) => {
        switch (status) {
            case ClarificationQuestionStatus.Answered:
                return "success";
            case ClarificationQuestionStatus.ResponseRequired:
                return "negative";
            case ClarificationQuestionStatus.Information:
                return "gray";
            case ClarificationQuestionStatus.AwaitingResponse:
            default:
                return "primary";
        }
    }, []);

    const toStatus = useCallback(
        (question: QuestionResponseDto) => {
            if (question.isRhetorical) {
                return ClarificationQuestionStatus.Information;
            }

            if (question.answersCount > 0) {
                return ClarificationQuestionStatus.Answered;
            }

            if (question.companyUuid === myCompanyUuid) {
                return ClarificationQuestionStatus.AwaitingResponse;
            }
            return ClarificationQuestionStatus.ResponseRequired;
        },
        [myCompanyUuid],
    );

    const getSectionText = useCallback(
        (question: QuestionResponseDto) => {
            if (question.entityUuid !== projectUuid) {
                const section = sections?.find((y) => y.id === question.entityUuid);
                const sectionText = section?.description ?? section?.sectionType;

                return stringService.insertSpacesInTitleCase(sectionText || "-");
            }
            return "-";
        },
        [projectUuid, sections],
    );

    const renderFromInformation = useCallback(
        (clarificationQuestion: QuestionResponseDto) => {
            if (clarificationQuestion.companyUuid === myCompanyUuid) {
                return t("common.you");
            }
            if (!clarificationQuestion.askingCompanyName) {
                return clarificationQuestion.companyUuid === buyerCompanyUuid
                    ? t("common.buyer")
                    : t("common.otherSupplier");
            }
            return clarificationQuestion.askingCompanyName;
        },
        [buyerCompanyUuid, myCompanyUuid, t],
    );

    const columns: ITableColumn[] = useMemo(() => {
        let defaultColumns = [
            {
                position: 2,
                priorityLevel: 2,
                label: t("clarificationQuestions.table.question"),
                name: "questionText",
                render: (clarificationQuestion: QuestionResponseDto) => (
                    <p className={classes.question} data-testid={`${clarificationQuestion.questionUuid}-question`}>
                        {htmlService.convertHtmlToString(clarificationQuestion.questionText)}
                    </p>
                ),
                minWidth: 150,
            },
            {
                position: 3,
                priorityLevel: 3,
                label: t("clarificationQuestions.table.from"),
                name: "askingCompanyName",
                render: (clarificationQuestion: QuestionResponseDto) => (
                    <p className={classes.question} data-testid={`${clarificationQuestion.questionUuid}-from`}>
                        {renderFromInformation(clarificationQuestion)}
                    </p>
                ),
                minWidth: 250,
            },
            {
                position: 4,
                priorityLevel: 4,
                label: t("clarificationQuestions.table.status"),
                name: "answersCount",
                render: (clarificationQuestion: QuestionResponseDto) => (
                    <Pill
                        value={toStatus(clarificationQuestion)}
                        color={pillColorForStatus(toStatus(clarificationQuestion))}
                        testid={`${clarificationQuestion.questionUuid}-status`}
                    />
                ),
                minWidth: 100,
            },
            {
                position: 5,
                priorityLevel: 1,
                name: "actions",
                render: (clarificationQuestion: QuestionResponseDto) => (
                    <div className={classes.viewCell}>
                        <Button
                            label={t("clarificationQuestions.buttons.view")}
                            size="small"
                            onClick={() => {
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: `Clarification Questions - View - ${clarificationQuestion.askingCompanyName} ${clarificationQuestion.questionText}`,
                                });
                                navigateToQuestion(clarificationQuestion.questionUuid);
                            }}
                            testid={`${clarificationQuestion.questionUuid}-view-button`}
                        />
                    </div>
                ),
                minWidth: 100,
            },
        ];

        if (projectType === ProjectType.Tender) {
            defaultColumns = [
                ...defaultColumns,
                {
                    position: 1,
                    priorityLevel: 5,
                    label: t("clarificationQuestions.table.section"),
                    name: "section",
                    render: (clarificationQuestion: QuestionResponseDto) => (
                        <p className={classes.question} data-testid={`${clarificationQuestion.questionUuid}-section`}>
                            {getSectionText(clarificationQuestion)}
                        </p>
                    ),
                    minWidth: 100,
                },
            ];
        }

        return defaultColumns;
    }, [
        classes.question,
        classes.viewCell,
        getSectionText,
        navigateToQuestion,
        pillColorForStatus,
        projectType,
        renderFromInformation,
        t,
        toStatus,
    ]);

    return (
        <Table
            columns={columns}
            data={clarificationQuestions}
            noDataDisplay={t("common.noResults")}
            rowAction={(row: QuestionResponseDto) => navigateToQuestion(row.questionUuid)}
            testid={testid}
        />
    );
};

export default ClarificationQuestionsTable;
