import { parseISO } from "date-fns";
import { format as fnsTzFormat } from "date-fns-tz";
import { DATETIME, DATETIME_SHORT, DATE_SHORT } from "types/consts/formattingConstants";

interface IDateTimeService {
    formatDate: (format: string, dateString?: Date | string, defaultToNow?: boolean) => string;
    toShortDateTimeFormat: (dateString?: Date | string, defaultToNow?: boolean) => string;
    toShortDateTimeFormatWithoutTz: (dateString?: Date | string, defaultToNow?: boolean) => string;
    toShortDateFormat: (dateString?: Date | string, defaultToNow?: boolean) => string;
    parseShortDate: (dateString?: Date | string) => Date | undefined;
    getTime: (date?: Date | string) => number;
    dateIsInPast: (date?: Date) => boolean;
    timeLeftUntil: (date?: Date) => string;
}

const dateTimeService: IDateTimeService = {
    formatDate: (format, dateString?, defaultToNow = false) => {
        if (!dateString && !defaultToNow) {
            return "";
        }
        const date = dateString ? new Date(dateString) : new Date();
        return fnsTzFormat(date, format, { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    },

    toShortDateTimeFormat: (dateString?, defaultToNow?) => {
        return dateTimeService.formatDate(DATETIME, dateString, defaultToNow);
    },

    toShortDateTimeFormatWithoutTz: (dateString?, defaultToNow?) => {
        return dateTimeService.formatDate(DATETIME_SHORT, dateString, defaultToNow);
    },

    toShortDateFormat: (dateString?, defaultToNow?) => {
        return dateTimeService.formatDate(DATE_SHORT, dateString, defaultToNow);
    },

    parseShortDate: (dateString) => {
        if (!dateString) {
            return undefined;
        }
        const isoDate = new Date(dateString).toISOString();
        return parseISO(isoDate);
    },

    dateIsInPast: (date) => {
        if (!date) return false;
        return new Date(date) < new Date();
    },

    getTime: (date) => {
        if (!date) return 0;
        return new Date(date).getTime();
    },

    timeLeftUntil: (date) => {
        if (!date || dateTimeService.dateIsInPast(date)) {
            return "0 days left";
        }

        const minutesLeft = Math.ceil((new Date(date).getTime() - new Date().getTime()) / 60000);
        if (minutesLeft < 60) {
            return `${minutesLeft} ${minutesLeft > 1 ? "minutes" : "minute"} left`;
        }

        const hoursLeft = Math.floor(minutesLeft / 60);
        if (hoursLeft < 24) {
            return `${hoursLeft} ${hoursLeft > 1 ? "hours" : "hour"} left`;
        }

        const daysLeft = Math.floor(hoursLeft / 24);
        return `${daysLeft} ${daysLeft > 1 ? "days" : "day"} left`;
    },
};

export default dateTimeService;
