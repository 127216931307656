import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { Heading, Icon } from "@maistro/components";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

import MaistroFormikPassIcon from "components/FormikFields/MaistroFormikPassIcon";
import { ICommonProps } from "components/shared";
import colorService from "services/colorService";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        padding: `${theme.spacing.medium}px ${theme.spacing.large}px`,
        background: theme.colors.medLightGray,
        boxShadow: `${colorService.toHexWithOpacity(theme.colors.primary, 0.1)} 0 3px 6px`,
        borderRadius: theme.spacing.xSmall,
        width: "100%",
    },
    content: {
        display: "grid",
        gridTemplateAreas: '"completed title chevron"',
        gridTemplateColumns: "auto 1fr auto",
        alignItems: "center",
        gap: `${theme.spacing.large}px ${theme.spacing.xSmall}px`,
    },
    completedIconContainer: {
        gridArea: "completed",
    },
    title: {
        gridArea: "title",
        wordBreak: "break-word",
        fontWeight: 600,
    },
    chevron: {
        gridArea: "chevron",
        display: "flex",
        justifyContent: "space-between",
    },
    button: {
        background: "inherit",
        border: "none",
        cursor: "pointer",
    },
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        content: {
            gap: theme.spacing.medium,
        },
        chevron: {
            width: 30,
        },
    },
}));

interface IQuestionRowForScoringBySupplierProps extends ICommonProps {
    title: string;
    onToggleExpand?: (expanded: boolean) => void;
    isComplete?: boolean;
    expandedByDefault: boolean;
}

const QuestionRowForScoringBySupplier: React.FC<IQuestionRowForScoringBySupplierProps> = (props) => {
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        setIsExpanded(props.expandedByDefault);
    }, [props.expandedByDefault]);

    return (
        <div className={props.className} data-testid={props.testid} data-component="SectionRow">
            <div
                className={classNames(classes.container, { expanded: isExpanded })}
                data-testid={`${props.testid}-expansion`}
            >
                <div className={classes.content}>
                    <div className={classNames(classes.completedIconContainer)}>
                        <MaistroFormikPassIcon
                            className={classes.completedIcon}
                            isVisible={!!props.isComplete}
                            testid={`${props.testid}-complete`}
                        />
                    </div>
                    <Heading className={classNames(classes.bold, classes.title)} variant="h3">
                        {props.title}
                    </Heading>
                    <div className={classNames(classes.icons)}>
                        <button
                            className={classes.button}
                            onClick={() => {
                                setIsExpanded((currentState) => {
                                    if (props.onToggleExpand) {
                                        props.onToggleExpand(!currentState);
                                    }
                                    return !currentState;
                                });
                            }}
                            type="button"
                            aria-label="expand-button"
                            data-testid={`${props.testid}-expand-button`}
                        >
                            <Icon className={classes.chevronPosition} icon={isExpanded ? faChevronUp : faChevronDown} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuestionRowForScoringBySupplier;
