import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { CardButton, Heading, MeatballMenu } from "@maistro/components";
import classNames from "classnames";
import { useField } from "formik";
import React from "react";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import ScoringMode from "features/project/tenderCriteria/weighting/types/ScoringMode";
import useScreenLayout from "hooks/useScreenLayout";
import ReactGA from "react-ga4";
import { ITheme } from "styles/themes/types";
import { SectionImportanceLevel } from "types/dtos/questions/sections/SectionImportanceLevel";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import ImportanceLevel from "types/enums/questions/sections/ImportanceLevel";

const useStyles = createUseStyles((theme: ITheme) => ({
    h3: {
        ...theme.typography.headings.h3,
    },

    section: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
        padding: `${theme.spacing.small}px 0`,
    },

    keylines: {},

    title: {
        display: "flex",
        alignItems: "center",
    },

    heading: {
        wordBreak: "break-word",
    },

    greedy: {
        flexGrow: 1,
    },

    weightings: {
        display: "flex",
        gap: theme.spacing.xSmall,

        "& > *": {
            flexBasis: "33.3%",
        },
    },

    input: {
        borderRadius: theme.spacing.xSmall,
        border: `1px solid ${theme.colors.gray}`,
        padding: `calc(${theme.spacing.medium}px - 1px)`,
        width: 85,

        ...theme.typography.content.input,
        fontSize: 16,
        fontWeight: 500,
        lineHeight: "20px",

        "&:active": {
            border: `2px solid ${theme.colors.primary}`,
            padding: `calc(${theme.spacing.medium}px - 2px)`,
        },
    },

    readonly: {
        textAlign: "right",
        whiteSpace: "nowrap",
    },

    values: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing.xSmall,
    },

    meatball: {
        marginLeft: theme.spacing.small,
        marginRight: theme.spacing.small,
    },

    showXl: { display: "none" },
    hideXl: { display: "inline-block" },

    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        weightings: {
            gap: theme.spacing.medium,
        },

        keylines: {
            "&:not(:last-child)": {
                borderBottom: `1px solid ${theme.colors.gray}`,
            },
        },
    },

    [`@media (min-width: ${theme.breakpoints.xl}px)`]: {
        readonly: {
            display: "inline-block",
            width: 50,
        },

        section: {
            flexDirection: "row",
            alignItems: "center",
        },

        showXl: { display: "inline-block" },
        hideXl: { display: "none" },
    },
}));

interface ISectionWeightingRowProps extends ICommonProps {
    index: number;
    uuid: string;
    title: string;
    scoringMode: ScoringMode;
    importanceLevels: SectionImportanceLevel[];
    disabled: boolean;
    setSectionToRemove: (uuid: string) => void;
    setIsRemoveSectionWarningOpen: (open: boolean) => void;
    deleteSection: (uuid: string) => void;
}

const SectionWeightingRow: React.FC<ISectionWeightingRowProps> = ({
    index,
    uuid,
    title,
    scoringMode,
    importanceLevels,
    disabled,
    testid,
    setSectionToRemove,
    setIsRemoveSectionWarningOpen,
    deleteSection,
}) => {
    const classes = useStyles();
    const { onDesktop } = useScreenLayout();

    const [importanceLevelField, , importanceLevelHelpers] = useField(`sectionWeightings.${index}.importanceLevel`);
    const [weightingField, , weightingHelpers] = useField({
        name: `sectionWeightings.${index}.weighting`,
        type: "number",
    });

    const handleClick = (importanceLevel: ImportanceLevel) => {
        importanceLevelHelpers.setValue(importanceLevel);
    };

    const onRemoveClick = () => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Sections Weighting - Remove - `.concat(title),
        });
        setSectionToRemove(uuid);

        if (scoringMode === "manual") {
            setIsRemoveSectionWarningOpen(true);
            return;
        }
        deleteSection(uuid);
    };

    const rowMenuItems = [
        {
            label: "Remove",
            action: () => onRemoveClick(),
        },
    ];

    return (
        <div
            className={classNames(classes.section, { [classes.keylines]: scoringMode === "manual" })}
            data-component="SectionWeightingRow"
            data-testid={testid}
        >
            {scoringMode === "automatic" && (
                <>
                    <div className={classNames(classes.title, classes.greedy)}>
                        <Heading variant="h2" className={classes.heading}>
                            {title}
                        </Heading>
                        <MeatballMenu
                            className={classes.meatball}
                            menuItems={rowMenuItems}
                            icon={faChevronDown}
                            position="bottom"
                            openOnHover={onDesktop}
                            testid="section-options"
                        />
                        <span className={classNames(classes.h3, classes.readonly, classes.hideXl, classes.greedy)}>
                            {weightingField.value} %
                        </span>
                    </div>
                    <div className={classes.weightings}>
                        {importanceLevels.map((importanceLevel) => (
                            <CardButton
                                key={importanceLevel.id}
                                label={importanceLevel.description}
                                isSelected={importanceLevel.id === importanceLevelField.value}
                                disabled={disabled}
                                onClick={() => {
                                    ReactGA.event({
                                        category: ga4Category.Button,
                                        action: ga4Action.ButtonClick,
                                        label: `Sections Weighting - ${importanceLevel.description} For ${title}`,
                                    });
                                    handleClick(importanceLevel.id);
                                }}
                                testid={`${testid}-${importanceLevel.id}-button`}
                            />
                        ))}
                    </div>
                    <input {...weightingField} value={weightingField.value || 0} type="hidden" />
                    <span
                        className={classNames(classes.h3, classes.readonly, classes.showXl)}
                        data-testid={`${testid}-weighting`}
                    >
                        {weightingField.value || 0} %
                    </span>
                </>
            )}
            {scoringMode === "manual" && (
                <>
                    <div className={classNames(classes.title, classes.greedy)}>
                        <Heading variant="h3" className={classes.heading}>
                            {title}
                        </Heading>
                        <MeatballMenu
                            className={classes.meatball}
                            menuItems={rowMenuItems}
                            icon={faChevronDown}
                            position="bottom"
                            openOnHover={onDesktop}
                            testid="section-options"
                        />
                    </div>

                    <div className={classes.values}>
                        <input
                            className={classNames(classes.input)}
                            {...weightingField}
                            value={(weightingField.value ?? 0).toString()}
                            type="number"
                            onFocus={() => weightingHelpers.setValue(parseInt(weightingField.value, 10) || "")}
                            onBlur={() => {
                                const attemptedValue = parseInt(weightingField.value, 10) || 0;
                                const newValue = Math.min(parseInt(weightingField.value, 10) || 0, 100);
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: `Sections Weighting - Weight Set - ${newValue} For ${title}`,
                                    value: attemptedValue,
                                });
                                weightingHelpers.setValue(newValue);
                            }}
                            step="1"
                            min="0"
                            max="100"
                            data-testid={`${testid}-input`}
                        />
                        <span className={classes.h3} data-testid={`${testid}-weighting`}>
                            %
                        </span>
                    </div>
                </>
            )}
        </div>
    );
};

export default SectionWeightingRow;
