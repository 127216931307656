import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { Drawer, Heading, Icon } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate, useParams } from "react-router-dom";

import { ICommonProps } from "components/shared";
import ProjectBriefDisplay from "features/project/projectBrief/ProjectBriefDisplay";
import ProjectResponseBriefDisplay from "features/project/projectBrief/ProjectResponseBriefDisplay";
import useProjectBrief from "features/project/projectBrief/hooks/useProjectBrief";
import { IProjectResponseInformation } from "features/project/types";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { IBackLayoutState } from "store/layoutSlice";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    heading: {
        marginTop: theme.spacing.medium,
        marginBottom: theme.spacing.medium,
    },

    editIcon: {
        position: "absolute",
        right: theme.spacing.xLarge,
        paddingRight: theme.spacing.medium,
        zIndex: 1,
        color: theme.colors.primary,
    },
}));

export interface IProjectPreviewLayoutState {}

interface IProjectPreviewDrawerProps extends ICommonProps {
    projectResponseInformation?: IProjectResponseInformation;
    projectFiles?: FileDto[];
    allowProjectEdit?: boolean;
    isOpen: boolean;
    close: () => void;
    navigationState?: IBackLayoutState;
}

const ProjectPreviewDrawer: React.FC<IProjectPreviewDrawerProps> = (props) => {
    const classes = useStyles();

    const { projectResponseInformation, projectFiles, allowProjectEdit, isOpen, close } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { projectUuid } = useParams();
    const { userIsBuyer, userIsSupplier } = useCurrentUser();

    const { projectInformation } = useProjectBrief({ projectUuid, includeRemovedCategories: false });

    const state = props.navigationState ?? {
        route: buildRoute(routes.projects.summary, { projectUuid: projectResponseInformation?.projectUuid }),
    };

    return (
        <Drawer isOpen={isOpen} onClose={close} fullScreen testid="project-preview-drawer">
            <Heading className={classes.heading} variant="h2">
                {t("common.pages.projectBrief")}
            </Heading>
            {allowProjectEdit && (
                <Icon
                    className={classes.editIcon}
                    icon={faEdit}
                    onClick={() => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: `Edit Project Preview`,
                        });
                        close();
                        navigate(
                            buildPath(routes.projects.projectBrief, {
                                projectUuid: projectResponseInformation?.projectUuid,
                            }),
                            {
                                state,
                            },
                        );
                    }}
                    testid="edit-project-preview-button"
                />
            )}
            {userIsBuyer && projectInformation && (
                <ProjectBriefDisplay projectInformation={projectInformation} projectFiles={projectFiles} />
            )}
            {userIsSupplier && projectResponseInformation && (
                <ProjectResponseBriefDisplay
                    projectResponseInformation={projectResponseInformation}
                    projectFiles={projectFiles}
                />
            )}
        </Drawer>
    );
};

ProjectPreviewDrawer.defaultProps = {
    allowProjectEdit: false,
};

export default ProjectPreviewDrawer;
