import { AxiosResponse } from "axios";
import apiErrorService, { ApiResponse } from "services/apiErrorService";

import env from "config/EnvironmentVariables";
import { ProjectUsersResponseDto } from "types/dtos/projects/users/ProjectUsersResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { createAxiosInstance } from "api/commonApi";
import { ProjectUserDto } from "types/dtos/projects/users/ProjectUserDto";
import { UpdateProjectUserDto } from "types/dtos/projects/users/UpdateProjectUserDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/projects";

export const getProjectUsers = (
    projectUuid: string,
): Promise<AxiosResponse<ProjectUsersResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/users`;
    return api.get(url);
};

export const addProjectUser = (
    projectUuid: string,
    userUuid: string,
): Promise<AxiosResponse<ProjectUserDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/users/${userUuid}`;
    return api.put(url);
};

export const updateProjectUser = (request: UpdateProjectUserDto): Promise<ApiResponse<ProjectUserDto>> => {
    const url = `${baseApiUrl}/${request.projectUuid}/users/${request.userUuid}/role`;
    return api.put(url, request).then((response) => {
        return apiErrorService.transformResponseTypeOnError(response);
    });
};

export const removeProjectUser = (
    projectUuid: string,
    userUuid: string,
): Promise<AxiosResponse | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/users/${userUuid}`;
    return api.delete(url);
};
