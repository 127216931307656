import { Drawer, Heading, ReadOnlyField } from "@maistro/components";
import React from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";

import FileList from "components/FileList/FileList";
import { ITheme } from "styles/themes/types";
import { BasicCompanyDto } from "types/dtos/company/BasicCompanyDto";
import FileDto from "types/dtos/files/FileDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
    supplierName: {
        fontSize: 14,
    },
}));

interface ISubmissionDocumentsDrawerProps {
    projectFiles: FileDto[];
    isOpen: boolean;
    supplier: BasicCompanyDto | undefined;
    setSupplier: React.Dispatch<React.SetStateAction<BasicCompanyDto | undefined>>;
}

const SubmissionDocumentsDrawer: React.FC<ISubmissionDocumentsDrawerProps> = (props) => {
    const classes = useStyles();

    const { projectFiles, isOpen, supplier, setSupplier } = props;

    const { t } = useTranslation();

    return (
        <Drawer
            isOpen={isOpen}
            onClose={() => setSupplier(undefined)}
            withCloseFooter
            testid="supplier-documents-drawer"
        >
            <div className={classes.container}>
                <Heading variant="h2">{t("common.documents")}</Heading>
                <ReadOnlyField
                    label={t("common.supplier")}
                    value={<span className={classes.supplierName}>{supplier?.companyName}</span>}
                    testid="supplier-name"
                />
                <ReadOnlyField
                    label={t("common.documents")}
                    value={
                        <FileList
                            files={projectFiles.filter((file) => file.linkedCompanyUuid === supplier?.companyUuid)}
                            canDownload
                        />
                    }
                    testid="supplier-documents"
                />
            </div>
        </Drawer>
    );
};

export default SubmissionDocumentsDrawer;
