import { useToaster } from "@maistro/components";
import React, { useEffect } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";

import GuardedRoute from "features/auth/GuardedRoute";
import SupplierAnswerQuestionContainer from "features/project/clarificationQuestions/answerQuestion/SupplierAnswerQuestionContainer";
import SupplierAskQuestionContainer from "features/project/clarificationQuestions/askQuestion/SupplierAskQuestionContainer";
import SupplierClarificationQuestionsContainer from "features/project/clarificationQuestions/supplierQuestions/SupplierClarificationQuestionsContainer";
import ProjectNotFound from "features/project/ProjectNotFound";
import ProjectResponseContainer from "features/project/projectResponse/ProjectResponseContainer";
import SupplierResponseContainer from "features/project/supplierQuoteResponse/SupplierResponseContainer";
import SupplierResponseThankYou from "features/project/supplierQuoteResponse/SupplierResponseThankYou";
import SupplierTenderQuestionsContainer from "features/project/supplierTenderResponse/SupplierTenderQuestionsContainer";
import useAppDispatch from "hooks/useAppDispatch";
import useReduxSelector from "hooks/useReduxSelector";
import routes from "routes/routePaths/RoutePaths";
import validationService from "services/validationService";
import { fetchProjectResponse } from "store/projectResponseSlice";
import { fetchSections } from "store/sectionsSlice";
import ProjectStatus from "types/enums/projects/ProjectStatus";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

const ProjectsResponseRouter: React.FC = () => {
    const { projectResponseInformation, fetchingErrorMessage } = useReduxSelector(
        (state) => state.projectResponseState,
    );

    const dispatch = useAppDispatch();
    const toaster = useToaster();
    const { projectUuid, responseUuid } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (
            projectUuid &&
            validationService.isValidUuid(projectUuid) &&
            projectUuid !== projectResponseInformation.projectUuid &&
            responseUuid &&
            validationService.isValidUuid(responseUuid) &&
            responseUuid !== projectResponseInformation.responseUuid
        ) {
            dispatch(fetchProjectResponse({ projectUuid, responseUuid }));
            dispatch(fetchSections({ projectUuid }));
        }
    }, [
        dispatch,
        projectResponseInformation.projectUuid,
        projectResponseInformation.responseUuid,
        projectUuid,
        responseUuid,
    ]);

    useEffect(() => {
        if (fetchingErrorMessage.length > 0) {
            toaster.error(fetchingErrorMessage);
        }
    }, [fetchingErrorMessage, toaster]);

    useEffect(() => {
        if (projectResponseInformation.status) {
            if (
                projectResponseInformation.status === ProjectStatus.Archived ||
                projectResponseInformation.status === ProjectStatus.Cancelled
            ) {
                navigate(routes.projectResponses.projectNotFound.path);
            }
        }
    }, [projectResponseInformation.status, navigate]);

    return (
        <Routes>
            <Route path="" element={<GuardedRoute />}>
                <Route path={routes.projectResponses.brief.path} element={<ProjectResponseContainer />} />
                <Route path={routes.projectResponses.thankYou.path} element={<SupplierResponseThankYou />} />
                <Route path="" element={<SupplierResponseContainer />} />
                <Route
                    path={routes.projectResponses.tenderQuestions.path}
                    element={<SupplierTenderQuestionsContainer />}
                />
                <Route
                    path={routes.projectResponses.clarificationQuestions.path}
                    element={<SupplierClarificationQuestionsContainer />}
                />
                <Route path={routes.projectResponses.projectNotFound.path} element={<ProjectNotFound />} />
            </Route>
            <Route
                path={routes.projectResponses.askClarificationQuestion.path}
                element={<GuardedRoute permission={PermissionsEnum.CanAskClarificationQuestion} />}
            >
                <Route path="" element={<SupplierAskQuestionContainer />} />
            </Route>
            <Route
                path={routes.projectResponses.viewClarificationQuestion.path}
                element={<GuardedRoute permission={PermissionsEnum.CanAnswerClarificationQuestion} />}
            >
                <Route path="" element={<SupplierAnswerQuestionContainer />} />
            </Route>
        </Routes>
    );
};

export default ProjectsResponseRouter;
