import { IProjectInformation } from "features/project/types";
import { DirtyPatchRequest } from "types/dtos/DirtyPatchRequest";
import { DirtyValues } from "types/dtos/DirtyValues";
import { ProjectBudgetDto } from "types/dtos/projects/ProjectBudgetDto";
import { ITaxonomyItemRequestDto } from "types/dtos/taxonomy/ITaxonomyItemRequestDto";
import ProjectType from "types/enums/projects/ProjectType";

export interface UpdateProjectRequestDto extends DirtyPatchRequest {
    projectName?: string;

    companyUuid?: string;

    description?: string;

    timescale?: string;

    budget?: ProjectBudgetDto;

    sponsorUserUuid?: string;

    tenderResponseDeadline?: Date;

    status?: string;

    decisionSummary?: string;

    projectType?: ProjectType;

    contributorsUuid?: Array<string>;

    categories?: Array<ITaxonomyItemRequestDto>;

    clarificationQuestionDeadline?: Date;

    targetProjectStartDate?: Date;

    targetProjectCompleteDate?: Date;

    expressionOfInterestDeadline?: Date;
}

export const constructUpdateProjectRequestDto = (
    values: IProjectInformation,
    dirtyValues: DirtyValues,
): UpdateProjectRequestDto => {
    return {
        projectName: values.projectName,
        description: values.description,
        timescale: values.timescale,
        budget: {
            currencyOnly: {
                ccy: values.budgetCurrency,
            },
            buyerBudget: values.buyerBudget || 0,
            budgetType: values.budgetType,
            showBudgetToSupplier: values.showBudgetToSupplier,
        },
        sponsorUserUuid: values.sponsorUserUuid,
        tenderResponseDeadline: values.tenderResponseDeadline,
        clarificationQuestionDeadline: values.clarificationQuestionDeadline,
        targetProjectStartDate: values.targetProjectStartDate,
        targetProjectCompleteDate: values.targetProjectCompleteDate,
        status: values.status,
        decisionSummary: values.decisionSummary,
        projectType: values.type,
        contributorsUuid: values.contributors.map((contributor) => contributor.userUuid),
        categories: values.categories.map((category) => ({
            itemUuid: category.value,
            selectionStatusType: category.status,
        })),
        expressionOfInterestDeadline: values.expressionOfInterestDeadline,
        companyUuid: values.clientCompanyUuid ?? values.companyUuid,
        dirtyValues: {
            ...dirtyValues,
            contributorsUuid: dirtyValues.contributors,
            companyUuid: dirtyValues.clientCompanyUuid,
        },
    };
};
