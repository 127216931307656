import { uniqBy } from "lodash";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { searchUsers } from "api/usersApi";
import { IOptionProps } from "components/shared";
import useCurrentUser from "hooks/useCurrentUser";
import stringService from "services/stringService";
import { useLazySearchCompaniesQuery } from "store/api/companiesApi";
import ProjectStatus from "types/enums/projects/ProjectStatus";
import ProjectType from "types/enums/projects/ProjectType";
import SupplierSubmissionStatus from "types/enums/supplierSubmissions/SupplierSubmissionStatus";

export interface IDashboardOptions {
    projectTypes: IOptionProps[];
    statuses: IOptionProps[];
    responseStatuses: IOptionProps[];
    projectManagers: IOptionProps[];
    companies: IOptionProps[];
}

export interface IDashboardFilters {
    projectManagers: IOptionProps[];
    companies: IOptionProps[];
    statuses: IOptionProps[];
    projectTypes: IOptionProps[];
    estimatedDeliveryDeadlineStart?: Date | null;
    estimatedDeliveryDeadlineEnd?: Date | null;
    searchTerm?: string;
    tenderResponseDeadlineStart?: Date | null;
    tenderResponseDeadlineEnd?: Date | null;
}

export interface IOptionalFilterConfiguration {
    projectManagers?: boolean;
    companies?: boolean;
    projectStatuses?: boolean;
    supplierSubmissionStatuses?: boolean;
    estimatedDeliveryDeadline?: boolean;
    tenderResponseDeadline?: boolean;
}

export const emptyFilters: IDashboardFilters = {
    projectManagers: [],
    companies: [],
    statuses: [],
    projectTypes: [],
    estimatedDeliveryDeadlineStart: null,
    estimatedDeliveryDeadlineEnd: null,
    tenderResponseDeadlineStart: null,
    tenderResponseDeadlineEnd: null,
    searchTerm: "",
};

const useDashboardFilters = (initialFilters?: IDashboardFilters) => {
    const [currentFilters, setCurrentFilters] = useState<IDashboardFilters>({ ...emptyFilters, ...initialFilters });
    const [availableProjectManagers, setAvailableProjectManagers] = useState<IOptionProps[]>([]);
    const [availableCompanies, setAvailableCompanies] = useState<IOptionProps[]>([]);

    const { t } = useTranslation();

    const { userIsMaistro } = useCurrentUser();

    const [searchCompanies] = useLazySearchCompaniesQuery();

    const availableProjectTypes: IOptionProps[] = [
        { value: ProjectType.Tender.toString(), label: t("common.tender") },
        { value: ProjectType.Quote.toString(), label: t("common.quote") },
        {
            value: ProjectType.ExpressionOfInterest.toString(),
            label: t("common.expressionOfInterest"),
        },
    ];

    const availableStatuses: IOptionProps[] = [
        ProjectStatus.Create,
        ProjectStatus.Invite,
        ProjectStatus.AwaitingResponses,
        ProjectStatus.TenderQuestions,
        ProjectStatus.Review,
        ProjectStatus.Approval,
        ProjectStatus.Results,
        ProjectStatus.Complete,
        ProjectStatus.CompleteAtReview,
        ProjectStatus.Archived,
        ProjectStatus.Cancelled,
    ].map((status) => {
        return { value: status, label: stringService.insertSpacesInTitleCase(status) };
    });

    const availableResponseStatuses: IOptionProps[] = Object.values(SupplierSubmissionStatus)
        .filter((status) => status !== SupplierSubmissionStatus.Unset && status !== SupplierSubmissionStatus.Identified)
        .map((status) => {
            return { value: status, label: stringService.insertSpacesInTitleCase(status) };
        });

    const getAvailableProjectManagers = useCallback(
        (searchText?: string) => {
            searchUsers(searchText).then((response) => {
                if (response.status !== 200) return;

                const projectManagers: IOptionProps[] = response.data.users.map((u) => ({
                    value: u.userUuid,
                    label: u.fullName ?? "",
                }));
                setAvailableProjectManagers(uniqBy([...currentFilters.projectManagers, ...projectManagers], "value"));
            });
        },
        [currentFilters.projectManagers],
    );

    const getAvailableCompanies = useCallback(
        (searchQuery: string) => {
            if (!userIsMaistro) return;

            searchCompanies({ companyTypes: ["Buyer"], searchQuery }, true)
                .unwrap()
                .then((response) => {
                    const companies: IOptionProps[] = response.items
                        .toSorted((a, b) => a.registeredName.localeCompare(b.registeredName))
                        .map((company) => ({
                            value: company.companyUuid,
                            label: company.registeredName,
                        }));
                    setAvailableCompanies(uniqBy([...currentFilters.companies, ...companies], "value"));
                });
        },
        [currentFilters.companies, searchCompanies, userIsMaistro],
    );

    return {
        currentFilters,
        setCurrentFilters,
        options: {
            projectTypes: availableProjectTypes,
            statuses: availableStatuses,
            responseStatuses: availableResponseStatuses,
            projectManagers: availableProjectManagers,
            companies: availableCompanies,
        },
        getAvailableProjectManagers,
        getAvailableCompanies,
    };
};

export default useDashboardFilters;
