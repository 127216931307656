import { AxiosResponse } from "axios";

import { createAxiosInstance } from "api/commonApi";
import env from "config/EnvironmentVariables";
import { CreateUserGroupRequestDto } from "types/dtos/company/userGroups/CreateUserGroupRequestDto";
import { ListUserGroupsRequestDto } from "types/dtos/company/userGroups/ListUserGroupsRequestDto";
import { UserGroupDto } from "types/dtos/company/userGroups/UserGroupDto";
import { UserGroupsResponseDto } from "types/dtos/company/userGroups/UserGroupsResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/companies";

export const listUserGroups = (
    request: ListUserGroupsRequestDto,
): Promise<AxiosResponse<UserGroupsResponseDto | TransactionErrorDto>> => {
    return api.get(`${baseApiUrl}/${request.companyUuid}/user-groups`, {
        params: {
            skip: request.skip,
            take: request.take,
        },
    });
};

export const createUserGroup = (
    companyUuid: string,
    request: CreateUserGroupRequestDto,
): Promise<AxiosResponse<UserGroupDto | TransactionErrorDto>> => {
    return api.post(`${baseApiUrl}/${companyUuid}/user-groups`, request);
};

export const updateUserGroup = (
    companyUuid: string,
    request: UserGroupDto,
): Promise<AxiosResponse<UserGroupDto | TransactionErrorDto>> => {
    return api.put(`${baseApiUrl}/${companyUuid}/user-groups/${request.id}`, request);
};

export const getUserGroup = (
    companyUuid: string,
    groupUuid: string,
): Promise<AxiosResponse<UserGroupDto | TransactionErrorDto>> => {
    return api.get(`${baseApiUrl}/${companyUuid}/user-groups/${groupUuid}`);
};

export const deleteUserGroup = (companyUuid: string, groupUuid: string): Promise<AxiosResponse> => {
    return api.delete(`${baseApiUrl}/${companyUuid}/user-groups/${groupUuid}`);
};
