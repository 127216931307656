import { Route } from "routes/helpers/RoutesHelper";

interface CompanyParams {
    companyUuid: string;
}

const adminUsers: Route = { path: "/settings/admin-users", translationKey: "adminUsers" };
const companies: Route = { path: "/companies", translationKey: "companiesList" };
const dashboard: Route = { path: "/dashboard", translationKey: "dashboard" };
const reports: Route = { path: "/reports" };
const settings: Route = { path: "/settings", translationKey: "settings" };
const vettingReport: Route<CompanyParams> = { path: "/vetting-report/:companyUuid" };

export default {
    adminUsers,
    companies,
    dashboard,
    reports,
    settings,
    vettingReport,
};
