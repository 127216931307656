import { ISortBy, SortOrder } from "@maistro/components";
import { AxiosResponse } from "axios";
import { isValid } from "date-fns";

import { createAxiosInstance } from "api/commonApi";
import env from "config/EnvironmentVariables";
import { IDashboardFilters } from "features/dashboard/filter/hooks/useDashboardFilters";
import { EmbeddedCollectionDto } from "types/dtos/EmbeddedCollectionDto";
import { CreateProjectRequestDto } from "types/dtos/projects/CreateProjectRequestDto";
import { DuplicateProjectRequestDto } from "types/dtos/projects/DuplicateProjectRequestDto";
import { ProjectPublicDataDto } from "types/dtos/projects/ProjectPublicDataDto";
import { ProjectResponseDto } from "types/dtos/projects/ProjectResponseDto";
import ProjectSearchRequestDto from "types/dtos/projects/search/ProjectSearchRequestDto";
import ProjectSearchResponseDto from "types/dtos/projects/search/ProjectSearchResponseDto";
import { UpdateProjectRequestDto } from "types/dtos/projects/UpdateProjectRequestDto";
import { AwardSupplierRequestDto } from "types/dtos/projectSuppliers/AwardSupplierRequestDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/projects";

export const archiveProject = (projectUuid: string): Promise<AxiosResponse> => {
    const url = `${baseApiUrl}/${projectUuid}/archive`;
    return api.patch(url);
};

export const unarchiveProject = (projectUuid: string): Promise<AxiosResponse> => {
    const url = `${baseApiUrl}/${projectUuid}/unarchive`;
    return api.patch(url);
};

export const createProject = (
    request: CreateProjectRequestDto,
): Promise<AxiosResponse<ProjectResponseDto | TransactionErrorDto>> => {
    const url = `${baseApiUrl}`;
    return api.post(url, request);
};

export const getProject = (
    projectUuid: string,
): Promise<AxiosResponse<ProjectResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}`;
    return api.get(url);
};

export const updateProject = (
    projectUuid: string,
    request: UpdateProjectRequestDto,
): Promise<AxiosResponse<ProjectResponseDto | TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}`;
    return api.patch(url, request);
};

export const awardSupplier = (projectUuid: string, request: AwardSupplierRequestDto): Promise<AxiosResponse> => {
    return api.post(`${baseApiUrl}/${projectUuid}/award`, request);
};

export const getProjectPublicData = (
    projectUuid: string,
): Promise<AxiosResponse<ProjectPublicDataDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/public`;
    return api.get(url);
};

const buildUrlForProjectSearch = (request: ProjectSearchRequestDto) => {
    let url = `${baseApiUrl}/search?skip=${request.skip}&take=${request.take}`;

    if (request.sortColumn) {
        url += `&orderByColumn=${encodeURI(request.sortColumn)}&sortDesc=${request.direction === SortOrder.Descending}`;
    }

    if (request.searchTerm && request.searchTerm.length > 0) {
        url += `&searchQuery=${encodeURI(request.searchTerm)}`;
    }

    if (request.projectStatus) {
        request.projectStatus.forEach((status) => {
            url += `&selectedProjectStatuses=${encodeURI(status)}`;
        });
    }
    if (request.selectedProjectTypes) {
        request.selectedProjectTypes.forEach((v) => {
            url += `&selectedProjectTypes=${encodeURI(v)}`;
        });
    }
    if (request.tenderResponseDeadlineStart && isValid(request.tenderResponseDeadlineStart)) {
        url += `&tenderResponseDeadlineStart=${encodeURI(request.tenderResponseDeadlineStart.toISOString())}`;
    }

    if (request.tenderResponseDeadlineEnd && isValid(request.tenderResponseDeadlineEnd)) {
        url += `&TenderResponseDeadlineEnd=${encodeURI(request.tenderResponseDeadlineEnd.toISOString())}`;
    }

    if (request.selectedProjectManagers && request.selectedProjectManagers.length > 0) {
        request.selectedProjectManagers.forEach((projectManagerId) => {
            url += `&selectedProjectManagers=${encodeURI(projectManagerId)}`;
        });
    }

    if (request.selectedCompanies && request.selectedCompanies.length > 0) {
        request.selectedCompanies.forEach((companyUuid) => {
            url += `&selectedCompanies=${companyUuid}`;
        });
    }

    return url;
};

export const searchProjects = (
    currentPage: number,
    itemsPerPage: number,
    sortParameters: ISortBy,
    filterParameters: IDashboardFilters,
): Promise<AxiosResponse<EmbeddedCollectionDto<ProjectSearchResponseDto>> | AxiosResponse<TransactionErrorDto>> => {
    const request: ProjectSearchRequestDto = {
        skip: (currentPage - 1) * itemsPerPage,
        take: itemsPerPage,
        sortColumn: sortParameters.column,
        direction: sortParameters.order || SortOrder.Descending,
        selectedProjectManagers: filterParameters.projectManagers.map((pm) => pm.value),
        selectedCompanies: filterParameters.companies.map((c) => c.value),
        projectStatus: filterParameters.statuses.map((s) => s.value),
        tenderResponseDeadlineStart: filterParameters.tenderResponseDeadlineStart
            ? new Date(filterParameters.tenderResponseDeadlineStart)
            : undefined,
        tenderResponseDeadlineEnd: filterParameters.tenderResponseDeadlineEnd
            ? new Date(filterParameters.tenderResponseDeadlineEnd)
            : undefined,
        searchTerm: filterParameters.searchTerm,
        selectedProjectTypes: filterParameters.projectTypes.map((pt) => pt.value),
    };

    const url = buildUrlForProjectSearch(request);
    return api.get(url);
};

export const duplicateProject = (
    projectUuid: string,
    request: DuplicateProjectRequestDto,
): Promise<AxiosResponse<ProjectResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/duplicate`;
    return api.post(url, request);
};
