import React from "react";
import { createUseStyles } from "react-jss";

import { ICommonProps } from "components/shared";
import { IProjectInformation } from "features/project/types";
import { ITheme } from "styles/themes/types";
import ProjectInformationDisplay from "features/project/clarificationQuestions/ProjectInformationDisplay";
import BuyerAskQuestionForm from "features/project/clarificationQuestions/askQuestion/BuyerAskQuestionForm";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import FileDto from "types/dtos/files/FileDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    grid: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.large,
    },
}));

interface IBuyerAskQuestionDisplayProps extends ICommonProps {
    projectInformation: IProjectInformation;
    submitQuestion: (question: string, isRequired: boolean, supplierUuids: Array<string>, section: string) => void;
    cancelQuestion: () => void;
    submittingQuestion: boolean;
    suppliers: Array<SupplierSubmissionResponseDto>;
    files?: FileDto[];
    setFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    removeFile: (file: FileDto | null) => void;
    fileIsUploading: boolean;
    setFileIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
}

const BuyerAskQuestionDisplay: React.FC<IBuyerAskQuestionDisplayProps> = ({
    projectInformation,
    submitQuestion,
    cancelQuestion,
    submittingQuestion,
    suppliers,
    files,
    removeFile,
    setFiles,
    fileIsUploading,
    setFileIsUploading,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.grid}>
            <ProjectInformationDisplay
                projectName={projectInformation.projectName}
                responseDate={projectInformation.tenderResponseDeadline}
            />
            <BuyerAskQuestionForm
                submit={submitQuestion}
                cancel={cancelQuestion}
                submittingQuestion={submittingQuestion}
                suppliers={suppliers}
                files={files}
                removeFile={removeFile}
                setFiles={setFiles}
                fileIsUploading={fileIsUploading}
                setFileIsUploading={setFileIsUploading}
                projectType={projectInformation.type}
            />
        </div>
    );
};

export default BuyerAskQuestionDisplay;
