import { Button, CheckboxesField, Heading, Pill, TextButton } from "@maistro/components";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import MaistroFormikLabel from "components/FormikFields/MaistroFormikLabel";
import { ICommonProps } from "components/shared";
import { ISupplierListFilters } from "features/company/profile/suppliers/filter/hooks/useSupplierListFilter";
import ReactGA from "react-ga4";
import { ITheme } from "styles/themes/types";
import SupplierRelationshipStatus from "types/enums/companies/SupplierRelationshipStatus";
import SupplierTieringStatus from "types/enums/companies/SupplierTieringStatus";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        color: theme.typography.base.color,
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },

    h2: {
        ...theme.typography.headings.h2,
    },

    buttonControl: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.small,
    },
    appliedFilters: {
        display: "flex",
        flexDirection: "column",
    },

    appliedFiltersContainer: {
        marginTop: theme.spacing.small,
        display: "inline-flex",
        gap: theme.spacing.xSmall,
        flexWrap: "wrap",
    },
    supplierListCheckbox: {
        marginTop: theme.spacing.small,
    },
}));
interface ISupplierListFilter extends ICommonProps {
    submitFilters: (values: ISupplierListFilters) => void;
    currentFilters: ISupplierListFilters;
    resetFilters: () => void;
}

const SupplierListFilter: React.FC<ISupplierListFilter> = (props) => {
    const classes = useStyles();

    const { currentFilters, submitFilters, resetFilters } = props;

    const { t } = useTranslation();

    const displayAppliedFilters = (
        appliedFilters: ISupplierListFilters,
        setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void,
    ) => {
        if (!appliedFilters.relationshipStatuses && !appliedFilters.activeStatuses && !appliedFilters.tieringStatuses) {
            return null;
        }
        let noOfAppliedFilters = appliedFilters.activeStatuses?.length ?? 0;
        noOfAppliedFilters += appliedFilters.relationshipStatuses?.length ?? 0;
        noOfAppliedFilters += appliedFilters.tieringStatuses?.length ?? 0;

        return (
            <div className={classes.appliedFilters}>
                <legend>
                    <MaistroFormikLabel
                        label={t("companySuppliers.appliedFilters", {
                            options: {
                                noOfAppliedFilters,
                            },
                        })}
                        name="appliedFilters"
                        testid="applied-filters-label"
                    />
                </legend>
                <div className={classes.appliedFiltersContainer}>
                    {appliedFilters.activeStatuses?.map((activeStatus) => (
                        <Pill
                            key={activeStatus}
                            value={`${t("companySuppliers.table.activeStatus")} - ${
                                activeStatus === "true"
                                    ? t("companySuppliers.activeStatus.active.label")
                                    : t("companySuppliers.activeStatus.inactive.label")
                            }`}
                            canRemove
                            onRemove={() =>
                                setFieldValue(
                                    "activeStatuses",
                                    appliedFilters.activeStatuses?.filter((as) => as !== activeStatus),
                                )
                            }
                            testid={`applied-filter-${activeStatus}`}
                        />
                    ))}
                    {appliedFilters.relationshipStatuses?.map((relationshipStatus) => (
                        <Pill
                            key={relationshipStatus}
                            value={`${t("companySuppliers.table.relationshipStatus")} - ${relationshipStatus}`}
                            canRemove
                            onRemove={() =>
                                setFieldValue(
                                    "relationshipStatuses",
                                    appliedFilters.relationshipStatuses?.filter((as) => as !== relationshipStatus),
                                )
                            }
                            testid={`applied-filter-${relationshipStatus}`}
                        />
                    ))}
                    {appliedFilters.tieringStatuses?.map((tieringStatus) => (
                        <Pill
                            key={tieringStatus}
                            value={`${t("companySuppliers.table.tieringStatus")} - ${tieringStatus}`}
                            canRemove
                            onRemove={() =>
                                setFieldValue(
                                    "tieringStatuses",
                                    appliedFilters.tieringStatuses?.filter((ts) => ts !== tieringStatus),
                                )
                            }
                            testid={`applied-filter-${tieringStatus}`}
                        />
                    ))}
                </div>
            </div>
        );
    };

    return (
        <Formik
            initialValues={{
                ...currentFilters,
            }}
            onSubmit={(values) => submitFilters(values)}
            onReset={() => resetFilters()}
        >
            {(formik) => (
                <Form className={classes.container}>
                    <Heading variant="h2">{t("common.filter")}</Heading>
                    {displayAppliedFilters(formik.values, formik.setFieldValue)}
                    <CheckboxesField
                        label={t("companySuppliers.table.activeStatus")}
                        name="activeStatuses"
                        options={[
                            {
                                label: t("companySuppliers.activeStatus.active.label"),
                                value: true.toString(),
                            },
                            {
                                label: t("companySuppliers.activeStatus.inactive.label"),
                                value: false.toString(),
                            },
                        ]}
                        tooltip={t("companySuppliers.tooltips.activeStatus")}
                        testid="active-status-options"
                    />
                    <CheckboxesField
                        label={t("companySuppliers.table.relationshipStatus")}
                        name="relationshipStatuses"
                        options={[
                            {
                                label: t("companySuppliers.relationshipStatus.approved.label"),
                                value: SupplierRelationshipStatus.Approved,
                            },
                            {
                                label: t("companySuppliers.relationshipStatus.deactivated.label"),
                                value: SupplierRelationshipStatus.Deactivated,
                            },
                            {
                                label: t("companySuppliers.relationshipStatus.pending.label"),
                                value: SupplierRelationshipStatus.Pending,
                            },
                            {
                                label: t("companySuppliers.relationshipStatus.trial.label"),
                                value: SupplierRelationshipStatus.Trial,
                            },
                        ]}
                        tooltip={[
                            {
                                text: t("companySuppliers.relationshipStatus.approved.tooltip"),
                                fieldName: t("companySuppliers.relationshipStatus.approved.label"),
                            },
                            {
                                text: t("companySuppliers.relationshipStatus.pending.tooltip"),
                                fieldName: t("companySuppliers.relationshipStatus.pending.label"),
                            },
                            {
                                text: t("companySuppliers.relationshipStatus.trial.tooltip"),
                                fieldName: t("companySuppliers.relationshipStatus.trial.label"),
                            },
                            {
                                text: t("companySuppliers.relationshipStatus.deactivated.tooltip"),
                                fieldName: t("companySuppliers.relationshipStatus.deactivated.label"),
                            },
                        ]}
                        testid="approval-status-options"
                    />
                    <CheckboxesField
                        label={t("companySuppliers.table.tieringStatus")}
                        name="tieringStatuses"
                        options={[
                            {
                                label: t("companySuppliers.tieringStatus.strategic.label"),
                                value: SupplierTieringStatus.Strategic,
                            },
                            {
                                label: t("companySuppliers.tieringStatus.preferred.label"),
                                value: SupplierTieringStatus.Preferred,
                            },
                            {
                                label: t("companySuppliers.tieringStatus.occasional.label"),
                                value: SupplierTieringStatus.Occasional,
                            },
                            {
                                label: t("companySuppliers.tieringStatus.undefined.label"),
                                value: SupplierTieringStatus.Undefined,
                            },
                        ]}
                        tooltip={[
                            {
                                text: t("companySuppliers.tieringStatus.strategic.tooltip"),
                                fieldName: t("companySuppliers.tieringStatus.strategic.label"),
                            },
                            {
                                text: t("companySuppliers.tieringStatus.preferred.tooltip"),
                                fieldName: t("companySuppliers.tieringStatus.preferred.label"),
                            },
                            {
                                text: t("companySuppliers.tieringStatus.occasional.tooltip"),
                                fieldName: t("companySuppliers.tieringStatus.occasional.label"),
                            },
                            {
                                text: t("companySuppliers.tieringStatus.undefined.tooltip"),
                                fieldName: t("companySuppliers.tieringStatus.undefined.label"),
                            },
                        ]}
                        testid="tiering-status-options"
                    />
                    <div className={classes.buttonControl}>
                        <Button
                            testid="filter-submit-button"
                            type="submit"
                            label={t("common.viewResults")}
                            onClick={() =>
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: `Company Suppliers - Filter Drawer - Submit`,
                                })
                            }
                        />
                        <TextButton
                            testid="filter-reset-button"
                            type="reset"
                            label={t("common.resetFilters")}
                            onClick={() =>
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: `Company Suppliers - Filter Drawer - Reset`,
                                })
                            }
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default SupplierListFilter;
