/* eslint-disable babel/camelcase */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum SignedInState {
    unknown,
    signedOut,
    signedIn,
}

export interface IAccessToken {
    nbf: number;
    exp: number;
    iss: string;
    aud: string[];
    client_id: string;
    user_uuid: string;
    company_uuid: string | undefined;
    sub: string;
    auth_time: number;
    idp: string;
    membership_permissions: string[];
    roles: string;
    scope: string[];
    amr: string[];
    name: string;
}

export interface IAuthenticationState {
    accessToken: IAccessToken;
    signedInState: SignedInState;
    accessTokenRaw: string;
    previousAccessTokenRaw: string;
}

export const authenticationSlice = createSlice({
    name: "authentication",
    initialState: {
        accessToken: {} as IAccessToken,
        signedInState: SignedInState.unknown,
        accessTokenRaw: "",
        previousAccessTokenRaw: "",
    },
    reducers: {
        setAccessToken: (state, action: PayloadAction<IAccessToken>) => {
            state.accessToken = action.payload;
            state.signedInState = action.payload ? SignedInState.signedIn : SignedInState.signedOut;
        },
        setUserName: (state, action: PayloadAction<string>) => {
            state.accessToken.name = action.payload;
        },
        setAccessTokenRaw: (state, action: PayloadAction<string>) => {
            state.previousAccessTokenRaw = state.accessTokenRaw;
            state.accessTokenRaw = action.payload;
        },
    },
});

export const { setAccessToken, setUserName, setAccessTokenRaw } = authenticationSlice.actions;

export default authenticationSlice.reducer;
