import baseApi from "store/api/baseApi";
import AddBuyerSupplierContractRequestDto from "types/dtos/company/buyer-supplier-relationships/AddBuyerSupplierContractRequestDto";
import { AddSuppliersToSupplierListRequestDto } from "types/dtos/company/buyer-supplier-relationships/AddSuppliersToSupplierListRequestDto";
import BuyerSupplierContractDto from "types/dtos/company/buyer-supplier-relationships/BuyerSupplierContractDto";
import { BuyerSupplierRelationshipDto } from "types/dtos/company/buyer-supplier-relationships/BuyerSupplierRelationshipDto";
import DeleteBuyerSupplierContractRequestDto from "types/dtos/company/buyer-supplier-relationships/DeleteBuyerSupplierContractRequestDto";
import EditBuyerSupplierContractRequestDto from "types/dtos/company/buyer-supplier-relationships/EditBuyerSupplierContractRequestDto";
import { GetBuyerSupplierRelationshipRequestDto } from "types/dtos/company/buyer-supplier-relationships/GetBuyerSupplierRelationshipRequestDto";
import ListBuyerSupplierContractsRequestDto from "types/dtos/company/buyer-supplier-relationships/ListBuyerSupplierContractsRequestDto";
import ListBuyerSupplierContractsResponseDto from "types/dtos/company/buyer-supplier-relationships/ListBuyerSupplierContractsResponseDto";
import { RemoveSupplierFromSupplierListRequestDto } from "types/dtos/company/buyer-supplier-relationships/RemoveSupplierFromSupplierListRequestDto";
import { SearchBuyerSupplierRelationshipsRequestDto } from "types/dtos/company/buyer-supplier-relationships/SearchBuyerSupplierRelationshipsRequestDto";
import { SearchBuyerSupplierRelationshipsResponseDto } from "types/dtos/company/buyer-supplier-relationships/SearchBuyerSupplierRelationshipsResponseDto";
import { UpdateSupplierExternalIdRequestDto } from "types/dtos/company/buyer-supplier-relationships/UpdateSupplierExternalIdRequestDto";
import { UpdateSupplierRelationshipStatusRequestDto } from "types/dtos/company/buyer-supplier-relationships/UpdateSupplierRelationshipStatusRequestDto";
import { UpdateSupplierTieringStatusRequestDto } from "types/dtos/company/buyer-supplier-relationships/UpdateSupplierTieringStatusRequestDto";

const buyerSupplierRelationshipsApi = baseApi
    .enhanceEndpoints({
        addTagTypes: ["Contracts"],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            searchBuyerSupplierRelationshipsApi: build.query<
                SearchBuyerSupplierRelationshipsResponseDto,
                SearchBuyerSupplierRelationshipsRequestDto
            >({
                query: (request) => ({
                    url: `/companies/${request.companyUuid}/supplier-relationships`,
                    method: "GET",
                    params: {
                        textSearch: request.textSearch,
                        skip: request.skip,
                        take: request.take,
                        supplierRelationshipStatusesSearch: request.supplierRelationshipStatusesSearch,
                        supplierTieringStatusesSearch: request.supplierTieringStatusesSearch,
                        supplierActiveStatusesSearch: request.supplierActiveStatusesSearch,
                        orderByColumn: request.orderByColumn,
                        sortDescending: request.sortDescending,
                    },
                }),
            }),
            getBuyerSupplierRelationshipApi: build.query<
                BuyerSupplierRelationshipDto,
                GetBuyerSupplierRelationshipRequestDto
            >({
                query: (request) => ({
                    url: `/companies/${request.buyerUuid}/supplier-relationships/${request.supplierUuid}`,
                    method: "GET",
                }),
            }),
            updateSupplierRelationshipStatusApi: build.mutation<
                BuyerSupplierRelationshipDto,
                UpdateSupplierRelationshipStatusRequestDto
            >({
                query: (request) => ({
                    url: `/companies/${request.buyerUuid}/supplier-relationship-status`,
                    method: "PUT",
                    data: request,
                }),
            }),
            updateSupplierExternalIdApi: build.mutation<
                BuyerSupplierRelationshipDto,
                UpdateSupplierExternalIdRequestDto
            >({
                query: (request) => ({
                    url: `/companies/${request.buyerUuid}/supplier-external-id`,
                    method: "PUT",
                    data: request,
                }),
            }),
            updateSupplierTieringStatusApi: build.mutation<
                BuyerSupplierRelationshipDto,
                UpdateSupplierTieringStatusRequestDto
            >({
                query: (request) => ({
                    url: `/companies/${request.buyerUuid}/supplier-tiering-status`,
                    method: "PUT",
                    data: request,
                }),
            }),
            addSuppliersToSupplierListApi: build.mutation<null, AddSuppliersToSupplierListRequestDto>({
                query: (request) => ({
                    url: `/companies/${request.buyerUuid}/add-supplier-relationships`,
                    method: "POST",
                    data: request,
                }),
            }),
            removeSupplierFromSupplierListApi: build.mutation<null, RemoveSupplierFromSupplierListRequestDto>({
                query: (request) => ({
                    url: `/companies/${request.buyerUuid}/supplier-relationships/${request.supplierUuid}`,
                    method: "DELETE",
                    data: request,
                }),
            }),
            listBuyerSupplierContractsApi: build.query<
                Array<BuyerSupplierContractDto>,
                ListBuyerSupplierContractsRequestDto
            >({
                query: (request) => ({
                    url: `/companies/${request.buyerUuid}/supplier/${request.supplierUuid}/contracts`,
                    method: "GET",
                }),
                transformResponse: (response: ListBuyerSupplierContractsResponseDto) => response.items,
                providesTags: ["Contracts"],
            }),
            addBuyerSupplierContractApi: build.mutation<BuyerSupplierContractDto, AddBuyerSupplierContractRequestDto>({
                query: (request) => ({
                    url: `/companies/${request.buyerUuid}/supplier/${request.supplierUuid}/contracts`,
                    method: "POST",
                    data: request,
                }),
                invalidatesTags: ["Contracts"],
            }),
            editBuyerSupplierContractApi: build.mutation<BuyerSupplierContractDto, EditBuyerSupplierContractRequestDto>(
                {
                    query: (request) => ({
                        url: `/companies/${request.buyerUuid}/supplier/${request.supplierUuid}/contracts/${request.uuid}`,
                        method: "PUT",
                        data: request,
                    }),
                    invalidatesTags: ["Contracts"],
                },
            ),
            deleteBuyerSupplierContractApi: build.mutation<null, DeleteBuyerSupplierContractRequestDto>({
                query: (request) => ({
                    url: `/companies/${request.buyerUuid}/supplier/${request.supplierUuid}/contracts/${request.uuid}`,
                    method: "DELETE",
                }),
                invalidatesTags: ["Contracts"],
            }),
        }),
        overrideExisting: false,
    });

export const {
    useLazySearchBuyerSupplierRelationshipsApiQuery,
    useUpdateSupplierRelationshipStatusApiMutation,
    useUpdateSupplierExternalIdApiMutation,
    useUpdateSupplierTieringStatusApiMutation,
    useLazyGetBuyerSupplierRelationshipApiQuery,
    useAddSuppliersToSupplierListApiMutation,
    useRemoveSupplierFromSupplierListApiMutation,
    useListBuyerSupplierContractsApiQuery,
    useAddBuyerSupplierContractApiMutation,
    useEditBuyerSupplierContractApiMutation,
    useDeleteBuyerSupplierContractApiMutation,
} = buyerSupplierRelationshipsApi;
