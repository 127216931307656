import React, { useContext, useEffect } from "react";
import { Button, PhoneNumberTextField, RadioButtonsField, TextField } from "@maistro/components";
import { Form, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import RolesEnum from "types/enums/rolesPermissions/RolesEnum";
import { createUseStyles } from "react-jss";
import { ITheme } from "styles/themes/types";
import UserSavingContext from "features/company/profile/users/contexts/UserSavingContext";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
    buttons: {
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing.medium,
        marginTop: theme.spacing.medium,
    },
}));

interface IEditUserForm {
    availableRoles: RolesEnum[];
    setIsValidPhoneNumber: (isDialCode: boolean, isLength: boolean) => void;
    isCreateMode: boolean;
}

const EditUserForm: React.FC<IEditUserForm> = ({ availableRoles, setIsValidPhoneNumber, isCreateMode }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const isSaving = useContext(UserSavingContext);

    const roleOptions = availableRoles.map((role) => ({
        label: t(`roles.names.${role}`),
        tooltip: t(`roles.tooltips.${role}`),
        value: role,
    }));

    const isOnlyRole = availableRoles.length < 2;
    const { dirty, isValid, setTouched, errors } = useFormikContext();

    useEffect(() => {
        if (!isCreateMode) {
            setTouched(errors);
        }
    }, [setTouched, errors, isCreateMode]);

    return (
        <Form className={classes.container}>
            <TextField
                name="firstName"
                label={t("companyProfileUsers.form.firstName.label")}
                required
                testid="user-first-name"
            />
            <TextField
                name="lastName"
                label={t("companyProfileUsers.form.lastName.label")}
                required
                testid="user-last-name"
            />
            <TextField
                name="emailAddress"
                label={t("companyProfileUsers.form.emailAddress.label")}
                required
                testid="user-email-address"
                disabled={!isCreateMode}
            />
            <TextField
                name="jobTitle"
                label={t("companyProfileUsers.form.jobTitle.label")}
                tooltip={t("companyProfileUsers.form.jobTitle.toolTip")}
                testid="user-job-title"
            />
            <PhoneNumberTextField
                name="phoneNumber"
                label={t("companyProfileUsers.form.phoneNumber.label")}
                testid="user-phone-number"
                onValidationComplete={(isValidDialCode: boolean, isValidLength: boolean) =>
                    setIsValidPhoneNumber(isValidDialCode, isValidLength)
                }
            />
            <RadioButtonsField
                name="roleName"
                label={t("companyProfileUsers.form.roleName.label")}
                options={roleOptions}
                required
                testid="user-role"
                disabled={isOnlyRole}
            />
            <div className={classes.buttons}>
                <Button testid="cancel-button" type="reset" variant="outline" label={t("common.cancel")} />
                <Button
                    testid="save-user-button"
                    type="submit"
                    label={t("companyProfileUsers.form.save.label")}
                    disabled={!dirty || !isValid || isSaving}
                />
            </div>
        </Form>
    );
};

export default EditUserForm;
