import { Button, Copy, Heading, PasswordField, TextButton } from "@maistro/components";
import { Form, Formik } from "formik";
import { TFunction } from "i18next";
import { isEmpty } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import { ICommonProps } from "components/shared";
import ReactGA from "react-ga4";
import { ITheme } from "styles/themes/types";
import { RegexPassword } from "types/consts/regexConstants";
import { ChangePasswordReqestDto } from "types/dtos/users/ChangePasswordReqestDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
    buttonControl: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.small,
    },
}));

interface IChangePasswordProps extends ICommonProps {
    userUuid?: string;
    submit: (dto: ChangePasswordReqestDto) => void;
    cancel: () => void;
}

const validationSchema = (t: TFunction<"translation", undefined>) =>
    Yup.object({
        currentPassword: Yup.string().required(t("users.changePassword.currentPassword.required")),
        newPassword: Yup.string()
            .required(t("users.changePassword.newPassword.required"))
            .matches(new RegExp(RegexPassword), t("users.changePassword.newPassword.invalid-requirements"))
            .notOneOf([Yup.ref("currentPassword")], t("users.changePassword.newPassword.invalid-matching")),
        confirmPassword: Yup.string()
            .required(t("users.changePassword.confirmPassword.required"))
            .oneOf([Yup.ref("newPassword")], t("users.changePassword.confirmPassword.mismatch")),
    });

const ChangePassword: React.FC<IChangePasswordProps> = (props) => {
    const classes = useStyles();

    const { cancel, submit } = props;

    const { t } = useTranslation();

    if (!props.userUuid) return null;

    return (
        <Formik
            initialValues={{ userUuid: props.userUuid, currentPassword: "", newPassword: "", confirmPassword: "" }}
            onSubmit={(values) => submit(values)}
            validationSchema={validationSchema(t)}
        >
            {({ errors, initialValues, values }) => (
                <Form className={classes.container}>
                    <Heading variant="h2">{t("users.changePassword.title")}</Heading>
                    <PasswordField
                        testid="change-password-password"
                        label={t("users.changePassword.currentPassword.label")}
                        name="currentPassword"
                        required
                    />
                    <PasswordField
                        testid="change-password-password"
                        label={t("users.changePassword.newPassword.label")}
                        name="newPassword"
                        required
                    />
                    <Copy variant="sublabel">{t("users.changePassword.copy")}</Copy>
                    <PasswordField
                        testid="change-password-confirm-password"
                        label={t("users.changePassword.confirmPassword.label")}
                        name="confirmPassword"
                        required
                    />
                    <div className={classes.buttonControl}>
                        <Button
                            label={t("users.submit")}
                            type="submit"
                            size="large"
                            disabled={!isEmpty(errors) || values === initialValues}
                            testid="change-password-submit-button"
                            onClick={() =>
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "Change password - Submit",
                                })
                            }
                        />
                        <TextButton
                            label={t("common.cancel")}
                            onClick={() => {
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "Change password - Cancel",
                                });
                                cancel();
                            }}
                            testid="change-password-cancel-button"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default ChangePassword;
