import { Pill } from "@maistro/components";
import React, { useCallback } from "react";

import { ICommonProps } from "components/shared";
import SupplierSubmissionDisplayStatus from "types/enums/supplierSubmissions/SupplierSubmissionDisplayStatus";

interface ISubmissionStatusColumnProps extends ICommonProps {
    status: SupplierSubmissionDisplayStatus;
}

const SubmissionStatusColumn: React.FC<ISubmissionStatusColumnProps> = (props) => {
    const pillColorForStatus = useCallback((status: SupplierSubmissionDisplayStatus) => {
        switch (status) {
            case SupplierSubmissionDisplayStatus.Successful:
            case SupplierSubmissionDisplayStatus.Received:
                return "success";
            case SupplierSubmissionDisplayStatus.NotInterested:
            case SupplierSubmissionDisplayStatus.Withdrawn:
                return "gray";
            case SupplierSubmissionDisplayStatus.Invited:
            case SupplierSubmissionDisplayStatus.Waiting:
            default:
                return "primary";
        }
    }, []);

    return <Pill value={props.status} color={pillColorForStatus(props.status)} testid={props.testid} />;
};

export default SubmissionStatusColumn;
