import React, { useState } from "react";

import { ICommonProps } from "components/shared";
import { getSectionTitle } from "features/project/helpers/sectionHelpers";
import useQuestionStyles from "features/project/shared/tender-questions/useQuestionStyles";
import SectionRow from "features/project/tenderCriteria/components/SectionRow/SectionRow";
import ScoreTenderSummaryQuestionRow from "features/project/tenderCriteria/scoringSummary/ScoreTenderSummaryQuestionRow";
import { AnswerDto } from "types/dtos/questions/AnswerResponseDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";

interface IScoreTenderSummarySectionRowProps extends ICommonProps {
    projectUuid: string;
    section: SectionDto;
    questions: QuestionResponseDto[];
    haveScores: (answer: AnswerDto) => boolean;
    canScoreAnswers: boolean;
    canModerateScores: boolean;
    expandedByDefault: boolean;
    scoringType: string;
    selectedSupplierUuid?: string;
}

const ScoreTenderSummarySectionRow: React.FC<IScoreTenderSummarySectionRowProps> = (props) => {
    const questionClasses = useQuestionStyles();

    const {
        projectUuid,
        section,
        questions,
        haveScores,
        canScoreAnswers,
        expandedByDefault,
        testid,
        canModerateScores,
    } = props;
    const [isExpanded, setIsExpanded] = useState<boolean>(expandedByDefault);
    const containsConditionalQuestion = questions.some((question) => question.isConditional);
    const isSectionComplete = questions.every((question) => question.answers?.every(haveScores));

    return (
        <SectionRow
            className={questionClasses.sectionRow}
            title={getSectionTitle(section)}
            totalQuestions={questions.length}
            weighting={section.weighting ?? 0}
            hasMandatoryQuestions={containsConditionalQuestion}
            expanded={expandedByDefault}
            isComplete={isSectionComplete}
            testid={testid}
            onToggleExpand={setIsExpanded}
        >
            <div>
                {isExpanded &&
                    questions.map((question) => (
                        <ScoreTenderSummaryQuestionRow
                            key={question.questionUuid}
                            projectUuid={projectUuid}
                            question={question}
                            haveScores={haveScores}
                            canScoreAnswers={canScoreAnswers}
                            canModerateScores={canModerateScores}
                            testid={`question-${question.questionUuid}`}
                            scoringType={props.scoringType}
                            selectedSupplierUuid={props.selectedSupplierUuid}
                        />
                    ))}
            </div>
        </SectionRow>
    );
};
export default ScoreTenderSummarySectionRow;
