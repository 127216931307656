import { Button, Copy } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";

import emptyDashboardImage from "assets/images/EmptyDashboard.svg";
import useEmptyScreenStyles from "features/shared/styles/useEmptyScreenStyles";

interface IEmptyDashboardProps {
    canCreateProject?: boolean;
    createProject?: () => void;
}

const EmptyDashboard: React.FC<IEmptyDashboardProps> = (props) => {
    const { t } = useTranslation();
    const classes = useEmptyScreenStyles();
    const { createProject, canCreateProject } = props;

    return (
        <div className={classes.wrapper}>
            <div className={classes.content}>
                <div>
                    <img
                        src={emptyDashboardImage}
                        className={classes.emptyImage}
                        alt={t("dashboard.emptyDashboard.message")}
                    />
                </div>
                <Copy className={classes.copy}>{t("dashboard.emptyDashboard.message")}</Copy>
                {canCreateProject && createProject && (
                    <div>
                        <Button
                            chevron
                            testid="empty-dashboard-create-project-button"
                            label={t("dashboard.emptyDashboard.createProject")}
                            onClick={createProject}
                            className={classes.hide}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default EmptyDashboard;
