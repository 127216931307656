import { useState } from "react";

import { deleteProjectFile } from "api/fileApi";
import useProjectFiles from "features/project/hooks/useProjectFiles";
import FileDto from "types/dtos/files/FileDto";
import EntityType from "types/enums/questions/EntityType";

const useFileUpload = (projectUuid?: string, entityType?: EntityType, entityUuid?: string) => {
    const [fileIsUploading, setFileIsUploading] = useState(false);
    const { projectFiles, setProjectFiles } = useProjectFiles(projectUuid, entityType, entityUuid);

    const removeFile = (file: FileDto | null) => {
        if (!setProjectFiles || !file || !projectUuid) {
            return;
        }

        if (file.newUpload) {
            setProjectFiles((prevState) => prevState.filter((prevFile) => prevFile.fileId !== file.fileId));
            return;
        }

        deleteProjectFile(projectUuid, file.fileId).then(() => {
            setProjectFiles((prevState) => prevState.filter((prevFile) => prevFile.fileId !== file.fileId));
        });
    };

    return {
        fileIsUploading,
        setFileIsUploading,
        projectFiles,
        setProjectFiles,
        removeFile,
    };
};

export default useFileUpload;
